export const INCIDENTDETECTOR_I18N_KEY = "incident_detectors.";

export const INCIDENTDETECTOR_LIST = {
    "ID_INCIDENTDETECTOR_INDIVIDUAL": {
      "name": "INCIDENTDETECTOR_INDIVIDUAL_LABEL",
      "description": "INCIDENTDETECTOR_INDIVIDUAL_DESCRIPTION"
    },
    "ID_INCIDENTDETECTOR_GDPROFFICER": {
      "name": "INCIDENTDETECTOR_GDPROFFICER_LABEL",
      "description": "INCIDENTDETECTOR_GDPROFFICER_DESCRIPTION"
    },
    "ID_INCIDENTDETECTOR_EMPLOYEE": {
      "name": "INCIDENTDETECTOR_EMPLOYEE_LABEL",
      "description": "INCIDENTDETECTOR_EMPLOYEE_DESCRIPTION"
    },
    "ID_INCIDENTDETECTOR_AGENCY": {
      "name": "INCIDENTDETECTOR_AGENCY_LABEL",
      "description": "INCIDENTDETECTOR_AGENCY_DESCRIPTION"
    },
    "ID_INCIDENTDETECTOR_PRINCIPAL": {
      "name": "INCIDENTDETECTOR_PRINCIPAL_LABEL",
      "description": "INCIDENTDETECTOR_PRINCIPAL_DESCRIPTION"
    },      
    "ID_INCIDENTDETECTOR_DATAPROCESSOR": {
      "name": "INCIDENTDETECTOR_DATAPROCESSOR_LABEL",
      "description": "INCIDENTDETECTOR_DATAPROCESSOR_DESCRIPTION"
    }, 
    "ID_INCIDENTDETECTOR_JOINTCONTROLLER": {
      "name": "INCIDENTDETECTOR_JOINTCONTROLLER_LABEL",
      "description": "INCIDENTDETECTOR_JOINTCONTROLLER_DESCRIPTION"
    } 
};
  