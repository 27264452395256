import backendDataApi from "@/services/backendDataApi.service.js";

import {INCIDENTSTAGE_LIST} from "@/lookup/incident_stages";

import {IMPACTLEVEL_LIST} from "@/lookup/impactlevels";

import merge from 'lodash.merge';

import {
  INCIDENT_STAGEHISTORY_RECORD,
  INCIDENT_RECORD,
  INCIDENT_RECORD_TYPE,
  INCIDENT_SCHEMA_VERSION
} from "@/config/config.couchdb";

export default {
  namespaced: true,
  state: {},
  mutations: {
    INCIDENT_SAVED(state, payload) {},
    INCIDENT_REMOVED(state, payload) {},
    INCIDENT_ASSIGNED(state, payload) {},
  },
  actions: {
    //
    // private - protected action functions
    //
    async deleteIncidentByIncidentIdFromBackend({state}, incident_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({
        designdoc: "incidents/list",
        keyid: incident_id,
      });
    },
    //find incidents  with limited content in the couchdb and return them
    async readIncidentOverviewlistFromBackend(
      {state},
      {startfilter, endfilter}
    ) {
      return await backendDataApi.readOverviewlistFromBackendByComplexFilter({
        designdoc: "incidents/overviewlistByStage",
        startkey: startfilter,
        endkey: endfilter,
      });
    },
    //find a incident by id in the couchdb and return it
    async readIncidentByIdFromBackend({state}, incident_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({
        designdoc: "incidents/list",
        keyid: incident_id,
      });
    },
    //create a new incident in the couchdb and return incident_id or false
    async createIncidentAndStoreInBackend({rootGetters, commit}, data) {
      // Make sure an incident_id is assigned on save for a new record
      const currentdatetime = +new Date();

      // console.debug("createIncidentAndStoreInBackend - data", data);

      if (!data.incident_id || data.incident_id === undefined)
        data.incident_id = backendDataApi.generateNewCustomKeyId("inc");

      // Safe guard to ensure a valid id
      if (!data.incident_id || 0 === data.incident_id.length) return false;

      data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
      data.created_at = currentdatetime;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_DRAFTCREATED";

        data.stagehistory.push({
          ...INCIDENT_STAGEHISTORY_RECORD,
          changetype_id: data.changetype_id,
          stage: data.stage,
          created_at: data.created_at,
          created_by: data.created_by,
          finished_at: +new Date(),
          finished_by: rootGetters["SessionManager/getterCurrentUsername"],
        });
      }

      if (!data.assigned_to) {
        data.assigned_to = rootGetters["SessionManager/getterCurrentUsername"];
      }

      const record = {
        username: rootGetters["SessionManager/getterCurrentUsername"],

        type: INCIDENT_RECORD_TYPE,
        keyid: data.incident_id,

        timeline: [data],
      };

      // console.debug("createIncidentAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a incident in the couchdb and return incident_id or false
    async updateIncidentAndStoreInBackend(
      {rootGetters, dispatch, commit},
      data
    ) {
      try {
        const currentdatetime = +new Date();

        data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
        data.created_at = currentdatetime;

        if (
          !data.changetype_id ||
          data.changetype_id == "ID_TIMELINE_CHANGETYPE_CREATED"
        ) {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        let record = await dispatch(
          "readIncidentByIdFromBackend",
          data.incident_id
        );

        // console.debug("updateIncidentAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        let doc = record[0].value;

        // console.debug("updateIncidentAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      } catch (err) {
        console.error("updateIncidentAndStoreInBackend - Error: ", err);
        return false;
      }
    },

    // Data object upgrade function & migration based on version
    async actionUpgradeDataObject(
      {state, getters, rootGetters, commit, dispatch},
      incidentObject
    ) {
      // Safe guard unnecessary migration based on schema version
      if((Object.prototype.hasOwnProperty.call(incidentObject, "version"))) {
        if(incidentObject.version === INCIDENT_SCHEMA_VERSION)
          return incidentObject;
      }

      var incidentObj = {};
      merge (incidentObj, getters.getterEmptyIncidentRecord, incidentObject);

      // console.debug("actionUpgradeDataObject - incidentObject", incidentObject);
      // console.debug("actionUpgradeDataObject - incidentObj", incidentObj);

      return incidentObj;
    },

    //
    // Public action functions
    //
    //find all incidents with limited content in the couchdb and return them
    async actionReceiveIncidentOverviewAsArray({dispatch}, filterarray) {
      try {
        var filterObj = {
          startfilter: [],
          endfilter: [{}],
        };

        if (Array.isArray(filterarray) && filterarray.length == 2) {
          filterObj.startfilter = [filterarray[0]];
          filterObj.endfilter = [filterarray[1], {}];
        }

        const result = await dispatch(
          "readIncidentOverviewlistFromBackend",
          filterObj
        );

        if (result.length) return result.map((o) => o.value);
        else return [];
      } catch (err) {
        console.error("actionReceiveIncidentOverviewAsArray Error: ", err);
        return false;
      }
    },
    async actionReceiveIncidentById({commit, dispatch}, incident_id) {
      try {
        const result = await dispatch( "readIncidentByIdFromBackend", incident_id);

        if (result.length != 1)
          throw "Reading unique incident_id returns more than one record";

        return {...result[0].value};
      } catch (err) {
        console.error("actionReceiveIncidentById - Error: ", err);
        return false;
      }
    },
    //find an incident by id in the couchdb and return the last data element
    async actionReceiveCurrentIncidentDataById(
      {state, getters, rootGetters, commit, dispatch},
      incident_id
    ) {
      try {
        const result = await dispatch("actionReceiveIncidentById", incident_id);

        if (result == undefined || !result) throw "Error incident not found";

        // console.debug("actionReceiveCurrentIncidentDataById result", result);

        if (result.constructor !== Object || Object.keys(result).length === 0)
          throw "Error reading incident by unquie id";

        return {...result.timeline[result.timeline.length - 1]};
      } catch (err) {
        console.error("actionReceiveCurrentIncidentDataById - Error : ", err);
        return false;
      }
    },
    //save or update a new incident into the couchdb
    async actionSaveIncident(
      {state, getters, rootGetters, dispatch, commit},
      data
    ) {
      try {
        // console.debug("actionSaveIncident", data);

        var result = false;

        if (!data.incident_id) {
          // console.debug("actionSaveIncident - Create Mode", data);
          result = await dispatch("createIncidentAndStoreInBackend", data);

          if (result === false) throw "Error creating incident";

          commit("INCIDENT_SAVED", data);

          return result;
        }

        // console.debug("actionSaveIncident - Update Mode", data);

        result = await dispatch("updateIncidentAndStoreInBackend", data);
        // result = true;
        // console.debug('actionSaveIncident - Update result', result);

        if (result == false) throw "Error saving Incident";

        commit("INCIDENT_SAVED", data);

        if (getters.getterIncidentIsClosed(data)) {
          // console.debug("actionSaveIncident - Closed Incident", data);

          const report = await dispatch("actionGenerateIncidentReport", data);

          if (report == false) {
            throw "Report Document Generation Error";
          }

          // set attachment object
          var docobj = {
            name: "Incident Report",

            doccategory_id: "ID_DOCUMENTCATEGORY_INCIDENT",
            doctype_id: "ID_DOCUMENTTYPE_INCIDENTREPORT",

            filename: rootGetters["HelperManager/getterCurrentDateTimeCompact"] + "_incident_report_closed.pdf",
            mimetype: "application/pdf",
            notes: "Auto Generated by GDPR App",

            can_deleted: false,

            reference: {
              refid: data.incident_id,
              scope: "incident",
              stage: data.stage,
            },
          };

          // console.debug("actionSaveIncident - docobj", docobj);

          await dispatch(
            "AttachmentManager/savePrintDocumentAsAttachment",
            {metadata: docobj, printdata: report},
            {root: true}
          );
        }

        return result;
      } catch (err) {
        console.error("actionSaveIncident Error: ", err);
        return false;
      }
    },
    //delete a incident from the couchdb
    async actionDeleteIncidentById(
      {state, getters, rootGetters, dispatch, commit},
      incident_id
    ) {
      try {
        // console.debug("actionDeleteIncidentById", incident_id);

        let result = await dispatch(
          "deleteIncidentByIncidentIdFromBackend",
          incident_id
        );

        if (result !== false) {
          // console.debug('actionDeleteIncidentById - After Update');
          commit("INCIDENT_REMOVED", incident_id);

          // Delete attachments
          await dispatch(
            "AttachmentManager/actionDeleteAttachmentsByReference",
            {refid: incident_id, scope: "incident"},
            {root: true}
          );
        }

        return result;
      } catch (err) {
        console.error("actionDeleteIncidentById - Error : ", err);
        return false;
      }
    },
    async actionGenerateIncidentReport(
      {state, getters, rootGetters, dispatch, commit},
      incidentObj
    ) {
      try {
        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "incident_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

        printjobInfoObj.data.incident = {...incidentObj};

        // console.debug("actionGenerateIncidentReport - printjobInfoObj", printjobInfoObj);


        if (printjobInfoObj.data.incident.incident_id) {
          var keyid = printjobInfoObj.data.incident.incident_id;
          printjobInfoObj.data.incident.incident_id = keyid.lastIndexOf("_") > 0 ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;
        }

        if (printjobInfoObj.data.incident.risk.level && 0 !== printjobInfoObj.data.incident.risk.level.length) {
          printjobInfoObj.data.incident.risk.level_display = rootGetters["LookupManager/getterIncidentImpactLevelsAsArrayByLc"](printjobInfoObj.lang).filter(
            (item) => item.id === printjobInfoObj.data.incident.risk.level)[0].display_name;
        }

        printjobInfoObj.data.incident.risk.categories_display = [];
        printjobInfoObj.data.incident.risk.categories.forEach(function (item) {
          printjobInfoObj.data.incident.risk.categories_display.push(
            rootGetters["LookupManager/getterIncidentRiskCategoriesAsArrayByLc"](printjobInfoObj.lang)
              .filter((category) => category.id === item)[0].display_name
          );
        });

        if (printjobInfoObj.data.incident.notification.thirdparty_involved && 0 !== printjobInfoObj.data.incident.notification.thirdparties.length) {
          printjobInfoObj.data.incident.notification.thirdparties
            .forEach(function (item) {
              item.contract_type_display = rootGetters["LookupManager/getterContractPartnerTypesAsArrayByLc"](printjobInfoObj.lang)
                .filter((type) => type.id === item.contract_type)[0].display_name;

              item.country_display = rootGetters["LookupManager/getterCountryList"]
                .filter((country) => country.value === item.country)[0].text;
            });
        }

        printjobInfoObj.data.incident.risk.consequences_display = [];
        printjobInfoObj.data.incident.risk.consequences.forEach(function (item) {
          printjobInfoObj.data.incident.risk.consequences_display.push(
            rootGetters["LookupManager/getterIncidentRiskConsequencesAsArrayByLc"](printjobInfoObj.lang)
              .filter((risk) => risk.id === item)[0].display_name
          );
        });

        printjobInfoObj.data.incident.impact.individual_categories_display = [];
        printjobInfoObj.data.incident.impact.individual_categories.forEach(
          function (item) {
            printjobInfoObj.data.incident.impact.individual_categories_display.push(
              rootGetters["DataSubjectManager/getterDataSubjectsAsArrayByLc"](
                printjobInfoObj.lang
              ).filter((subject) => subject.id === item)[0].display_translation.name
            );
          }
        );

        printjobInfoObj.data.incident.impact.data_categories_display = [];
        printjobInfoObj.data.incident.impact.data_categories.forEach(function (item) {
          printjobInfoObj.data.incident.impact.data_categories_display.push(
            rootGetters["DataCategoryManager/getterDataCategoriesAsArrayByLc"](printjobInfoObj.lang)
              .filter((category) => category.id === item)[0].display_name
          );
        });

        printjobInfoObj.data.incident.implementation.reason_display = "";
        const array = rootGetters["LookupManager/getterIncidentReasonsAsArray"].filter((el) => el.id === printjobInfoObj.data.incident.implementation.reason_id);

        if (array.length) {
          printjobInfoObj.data.incident.implementation.reason_display = array[0].display_name;
        }

        printjobInfoObj.data.incident.notification.individuals_notify_state_display = "";
        const notifystatearray = rootGetters["LookupManager/getterIncidentNotificationStatesAsArray"]
          .filter((el) => el.id === printjobInfoObj.data.incident.notification.individuals_notify_state_id);

        if (notifystatearray.length) {
          printjobInfoObj.data.incident.notification.individuals_notify_state_display = notifystatearray[0].display_name;
        }

        printjobInfoObj.data.incident.report.agency_report_state_display = "";
        const reportstatearray = rootGetters["LookupManager/getterIncidentReportStatesAsArray"]
          .filter((el) => el.id === printjobInfoObj.data.incident.report.agency_report_state_id);

        if (reportstatearray.length) {
          printjobInfoObj.data.incident.report.agency_report_state_display = reportstatearray[0].display_name;
        }

        // History data
        if (printjobInfoObj.data.incident.stagehistory.length) {
          printjobInfoObj.data.incident.stagehistory.forEach(function (item) {
            if (Object.prototype.hasOwnProperty.call(item, "changetype_id"))
              item.change_display = rootGetters["LookupManager/getterTimelineChangeTypesAsArrayByLc"](printjobInfoObj.lang)
                .filter((el) => el.id === item.changetype_id)[0].display_name;
            else
              item.change_display = rootGetters["LookupManager/getterTimelineChangeTypesAsArrayByLc"](printjobInfoObj.lang)
              .filter((el) => el.id === "ID_TIMELINE_CHANGETYPE_STAGECHANGED")[0].display_name;

            item.finished_by = rootGetters["AccountManager/getterUserFullnameFromUsername"](item.finished_by);
            item.finished_at_display = rootGetters["HelperManager/getterFormatDate"](item.finished_at);

            item.stage_display = rootGetters["LookupManager/getterIncidentStagesAsArrayByLc"](printjobInfoObj.lang)
              .filter((el) => el.stage === item.stage)[0].display_name;
          });
        }

        // console.debug("actionGenerateIncidentReport - printjobInfoObj", printjobInfoObj);

        return await dispatch("PrintManager/actionGeneratePrintDocument", printjobInfoObj, {root: true});
      } catch (err) {
        console.error("actionGenerateIncidentReport", err);
        return false;
      }
    },

    //assign incident
    async actionAssignIncident(
      {state, getters, rootGetters, dispatch, commit},
      assigndataObj
    ) {
      try {
        // console.debug("actionAssignIncident - assigndataObj", assigndataObj);
        var incidentObj = await dispatch(
          "actionReceiveCurrentIncidentDataById",
          assigndataObj.key_id
        );

        if (incidentObj === false) throw "Error reading incident by id";

        incidentObj.assigned_to = assigndataObj.newassigned_to;
        incidentObj.changenotes = assigndataObj.newnotes;
        incidentObj.changetype_id = assigndataObj.changetype_id;

        const result = await dispatch("actionSaveIncident", incidentObj);

        if (result === false) throw "Error saving incident assignto change";

        commit("INCIDENT_ASSIGNED", incidentObj);

        return result;
      } catch (err) {
        console.error("actionAssignIncident - Error : ", err);
        return false;
      }
    },
  },
  getters: {
    getterIncidentStageRecorded: () => {
      return INCIDENTSTAGE_LIST["ID_INCIDENTSTAGE_RECORDED"].stage;
    },
    getterIncidentStageClosed: () => {
      return INCIDENTSTAGE_LIST["ID_INCIDENTSTAGE_CLOSED"].stage;
    },
    getterIncidentStageEvaluated: () => {
      return INCIDENTSTAGE_LIST["ID_INCIDENTSTAGE_EVALUATED"].stage;
    },
    getterIncidentStageChecked: () => {
      return INCIDENTSTAGE_LIST["ID_INCIDENTSTAGE_CHECKED"].stage;
    },
    getterIncidentStagePrepared: () => {
      return INCIDENTSTAGE_LIST["ID_INCIDENTSTAGE_PREPARED"].stage;
    },
    getterIncidentStageInformed: () => {
      return INCIDENTSTAGE_LIST["ID_INCIDENTSTAGE_INFORMED"].stage;
    },
    getterIncidentStageImplemented: () => {
      return INCIDENTSTAGE_LIST["ID_INCIDENTSTAGE_IMPLEMENTED"].stage;
    },
    getterCalculateDeadlineDateTime: (state, getters, rootState, rootGetters) => (date, time) => {
      return rootGetters["HelperManager/getterCalculateDeadlineDateTime"](
        date,
        time,
        72,
        "hours"
      );
    },
    getterDefaultIncidentReasonId: (state) => {
      return "ID_INCIDENTREASON_OTHER";
    },
    getterDefaultIncidentNotificationStateId: (state) => {
      return "ID_INCIDENTNOTIFICATIONSTATE_NOTNEEDED";
    },
    getterIncidentNotificationStateSuccess: (state) => {
      return "ID_INCIDENTNOTIFICATIONSTATE_SUCCESS";
    },
    getterDefaultIncidentNotificationNotNeeded: (state) => {
      return "ID_INCIDENTNOTIFICATIONSTATE_NOTNEEDED";
    },

    getterDefaultIncidentReportStateId: (state) => {
      return "ID_INCIDENTREPORTSTATE_NOTNEEDED";
    },
    getterIncidentReportStateSuccess: (state) => {
      return "ID_INCIDENTREPORTSTATE_SUCCESS";
    },
    getterIncidentReportStateNotNeeded: (state) => {
      return "ID_INCIDENTREPORTSTATE_NOTNEEDED";
    },

    getterIncidentImpactLevelNoById: (state) => (impact_id) => {
      try {
        if (!impact_id || 0 === impact_id.length) throw "Empty impact_id";

        return IMPACTLEVEL_LIST[impact_id].level;
      } catch (err) {
        console.error("getterIncidentImpactLevelNoById : ", err);
        return 0;
      }
    },
    getterIncidentReportRequiredImpactLevel: (state, getters) => {
      return getters.getterIncidentImpactLevelNoById("ID_IMPACTLEVEL_MEDIUMIMPACT");
    },
    getterIncidentNotificationRequiredImpactLevel: (state, getters) => {
      return getters.getterIncidentImpactLevelNoById("ID_IMPACTLEVEL_HIGHIMPACT");
    },
    getterEmptyIncidentRecord: () => {
      return {...INCIDENT_RECORD};
    },
    getterIncidentToNextStage:
      (state, getters, rootState, rootGetters) => (incidentDataObj) => {
        try {
          if (incidentDataObj === undefined) return undefined;

          let created_at;
          let created_by;

          if (incidentDataObj.stagehistory.length) {
            created_at =
              incidentDataObj.stagehistory[
                incidentDataObj.stagehistory.length - 1
              ].finished_at;
            created_by =
              incidentDataObj.stagehistory[
                incidentDataObj.stagehistory.length - 1
              ].finished_by;
          } else {
            created_at = incidentDataObj.created_at;
            created_by = incidentDataObj.created_by;
          }

          incidentDataObj.stagehistory.push({
            ...INCIDENT_STAGEHISTORY_RECORD,
            stage: incidentDataObj.stage,
            created_at: created_at,
            created_by: created_by,
            finished_at: +new Date(),
            finished_by: rootGetters["SessionManager/getterCurrentUsername"],
          });

          // Increase stage by one
          if (incidentDataObj.stage < getters.getterIncidentStageClosed) {
            incidentDataObj.stage++;
            incidentDataObj.active = true;

            incidentDataObj.changetype_id =
              "ID_TIMELINE_CHANGETYPE_STAGECHANGED";
          } else {
            incidentDataObj.active = false;
            incidentDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_CLOSED";
          }

          return incidentDataObj;
        } catch (error) {
          console.error(error);
          incidentDataObj = undefined;
        }

        return incidentDataObj;
      },
    getterIncidentNextStageAllowed: () => (incidentDataObj) => {
      var ballowstate = false;

      if (incidentDataObj === undefined) return ballowstate;

      if (!Object.prototype.hasOwnProperty.call(incidentDataObj, "stage")) return ballowstate;

      switch (incidentDataObj.stage) {
        case 1: // ID_INCIDENTSTAGE_RECORDED
          ballowstate = incidentDataObj.core.valid;
          break;
        case 2: // ID_INCIDENTSTAGE_CHECKED
          ballowstate =
            incidentDataObj.core.valid &&
            incidentDataObj.impact.valid;
          break;
        case 3: // ID_INCIDENTSTAGE_EVALUATED
          ballowstate =
            incidentDataObj.core.valid &&
            incidentDataObj.impact.valid &&
            incidentDataObj.risk.valid;
          break;
        case 4: // ID_INCIDENTSTAGE_PREPARED
          ballowstate =
            incidentDataObj.core.valid &&
            incidentDataObj.impact.valid &&
            incidentDataObj.risk.valid &&
            incidentDataObj.implementation.valid;
          break;
        case 5: // ID_INCIDENTSTAGE_INFORMED
          ballowstate =
            incidentDataObj.core.valid &&
            incidentDataObj.impact.valid &&
            incidentDataObj.risk.valid &&
            incidentDataObj.implementation.valid &&
            incidentDataObj.notification.valid;
          break;
        case 6: // ID_INCIDENTSTAGE_IMPLEMENTED
          ballowstate =
            incidentDataObj.core.valid &&
            incidentDataObj.impact.valid &&
            incidentDataObj.risk.valid &&
            incidentDataObj.implementation.valid &&
            incidentDataObj.notification.valid &&
            incidentDataObj.report.valid;
          break;
        case 7: // ID_INCIDENTSTAGE_CLOSED
          ballowstate =
            incidentDataObj.core.valid &&
            incidentDataObj.impact.valid &&
            incidentDataObj.risk.valid &&
            incidentDataObj.implementation.valid &&
            incidentDataObj.notification.valid &&
            incidentDataObj.report.valid &&
            incidentDataObj.report.agency_report_incomplete == false &&
            incidentDataObj.completion.valid;
          break;
        default:
          ballowstate = false;
          break;
      }

      return ballowstate;
    },
    getterIncidentReportData: (state, getters, rootState, rootGetters) => {
      const printjobInfoObj = {
        file: "incident_report.odt",
        convertTo: "pdf",
        lang: rootGetters["TranslationManager/lang"].lc,
      };

      return printjobInfoObj;
    },
    getterIncidentIsClosed: (state, getters, rootState, rootGetters) => (incident) => {
      try {
        if (incident.stage == getters.getterIncidentStageClosed && incident.active === false)
          return true;
        else
          return false;
      } catch (error) {
        console.error("getterIncidentIsClosed", error);
        return false;
      }
    },
    getterIncidentEditAllowed:
      (state, getters, rootState, rootGetters) => (incidentDataObj) => {
        try {
          if (
            incidentDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(incidentDataObj, "stage") ||
            !Object.prototype.hasOwnProperty.call(
              incidentDataObj,
              "assigned_to"
            )
          )
            throw "Incident object is undefined or incomplete";

          if (
            rootGetters["SessionManager/getterCurrentUsername"] !=
            incidentDataObj.assigned_to
          )
            return false;

          return (
            incidentDataObj.stage <= getters.getterIncidentStageClosed &&
            incidentDataObj.active
          );
        } catch (error) {
          console.error("getterIncidentEditAllowed - Error : ", error);
          return false;
        }
      },
    getterIncidentDeleteAllowed:
      (state, getters, rootState, rootGetters) => (incidentDataObj) => {
        try {
          if (
            incidentDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(
              incidentDataObj,
              "assigned_to"
            ) ||
            !Object.prototype.hasOwnProperty.call(incidentDataObj, "stage")
          )
            throw "Incident object is undefined";

          if (
            rootGetters["SessionManager/getterCurrentUsername"] !=
            incidentDataObj.assigned_to
          )
            return false;

          return incidentDataObj.stage == getters.getterIncidentStageRecorded;
        } catch (error) {
          console.error("getterIncidentDeleteAllowed - Error : ", error);
          return false;
        }
      },
  },
};
