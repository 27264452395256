  export const DSFATARGET_I18N_KEY = "dsfa_targets.";

export const DSFATARGET_LIST = {
  "ID_DSFATARGET_AREA": {
    "name": "DSFATARGET_AREA_LABEL",
    "description": "DSFATARGET_AREA_DESCRIPTION",
    "scope": "area"
  },

  "ID_DSFATARGET_PROCESS": {
    "name": "DSFATARGET_PROCESS_LABEL",
    "description": "DSFATARGET_PROCESS_DESCRIPTION",
    "scope": "process"
  },

  "ID_DSFATARGET_ACTIVITYDOC": {
    "name": "DSFATARGET_ACTIVITYDOC_LABEL",
    "description": "DSFATARGET_ACTIVITYDOC_DESCRIPTION",
    "scope": "activitydoc"
  },

  "ID_DSFATARGET_CUSTOM": {
    "name": "DSFATARGET_CUSTOM_LABEL",
    "description": "DSFATARGET_CUSTOM_DESCRIPTION",
    "scope": "custom"
  }
};