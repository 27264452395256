export const TIMELINE_CHANGETYPE_I18N_KEY = "timeline_changetypes.";

export const TIMELINE_CHANGETYPE_LIST = {
  ID_TIMELINE_CHANGETYPE_CREATED: {
    name: "TIMELINE_CHANGETYPE_CREATED_LABEL",
    description: "TIMELINE_CHANGETYPE_CREATED_DESCRIPTION",
  },
  ID_TIMELINE_CHANGETYPE_CONTENTCHANGED: {
    name: "TIMELINE_CHANGETYPE_CONTENTCHANGED_LABEL",
    description: "TIMELINE_CHANGETYPE_CONTENTCHANGED_DESCRIPTION",
  },
  ID_TIMELINE_CHANGETYPE_STAGECHANGED: {
    name: "TIMELINE_CHANGETYPE_STAGECHANGED_LABEL",
    description: "TIMELINE_CHANGETYPE_STAGECHANGED_DESCRIPTION",
  },
  ID_TIMELINE_CHANGETYPE_CLOSED: {
    name: "TIMELINE_CHANGETYPE_CLOSED_LABEL",
    description: "TIMELINE_CHANGETYPE_CLOSED_DESCRIPTION",
  },
  ID_TIMELINE_CHANGETYPE_USERASSIGNED: {
    name: "TIMELINE_CHANGETYPE_USERASSIGNED_LABEL",
    description: "TIMELINE_CHANGETYPE_USERASSIGNED_DESCRIPTION",
  },
  ID_TIMELINE_CHANGETYPE_DRAFTCREATED: {
    name: "TIMELINE_CHANGETYPE_DRAFTCREATED_LABEL",
    description: "TIMELINE_CHANGETYPE_DRAFTCREATED_DESCRIPTION",
  },
  ID_TIMELINE_CHANGETYPE_INACTIVATE: {
    name: "TIMELINE_CHANGETYPE_INACTIVATE_LABEL",
    description: "TIMELINE_CHANGETYPE_INACTIVATE_DESCRIPTION",
  },
  ID_TIMELINE_CHANGETYPE_ACTIVATE: {
    name: "TIMELINE_CHANGETYPE_ACTIVATE_LABEL",
    description: "TIMELINE_CHANGETYPE_ACTIVATE_DESCRIPTION",
  },
  ID_TIMELINE_CHANGETYPE_ARCHIVED: {
    name: "TIMELINE_CHANGETYPE_ARCHIVED_LABEL",
    description: "TIMELINE_CHANGETYPE_ARCHIVED_DESCRIPTION",
  },
  ID_TIMELINE_CHANGETYPE_ASSIGNRECLAIMED: {
    name: "TIMELINE_CHANGETYPE_ASSIGNRECLAIMED_LABEL",
    description: "TIMELINE_CHANGETYPE_ASSIGNRECLAIMED_DESCRIPTION",
  },
  ID_TIMELINE_CHANGETYPE_ATTACHMENTCHANGED: {
    name: "TIMELINE_CHANGETYPE_ATTACHMENTCHANGED_LABEL",
    description: "TIMELINE_CHANGETYPE_ATTACHMENTCHANGED_DESCRIPTION",
  },
};
