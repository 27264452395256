<template>
  <v-app-bar app>
    <v-toolbar-title>
      {{ $t("collectinitialdata.header_title") }}
    </v-toolbar-title>

    <div class="flex-grow-1"></div>

    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" icon @click="toggleTheme"
          ><v-icon>mdi-invert-colors</v-icon>
        </v-btn>
      </template>
      <span>{{$t('collectinitialdata.btn_theme_tooltip')}}</span>
    </v-tooltip>

    <div class="mt-4">
      <LanguageSelector/>
    </div>

    <v-menu bottom offset-y>
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <img class="menu-profile-icon ml-4" v-on="on" src="@/assets/profile.png" />
            </template>
            <span>{{$t('home.headerbar_btn_profile_tooltip')}}</span>
          </v-tooltip>
        </div>
      </template>

      <v-list>
        <v-list-item>{{$t('collectinitialdata.menu_title')}}</v-list-item>

        <v-list-item class="readonly">
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          <v-list-item-content>
            <template v-if="getterCurrentUserDisplayname">
                <v-list-item-title>{{getterCurrentUserDisplayname}}</v-list-item-title>
                <v-list-item-subtitle>{{getterCurrentUsername}}</v-list-item-subtitle>
            </template>
            <template v-else>
              <v-list-item-title>{{getterCurrentUsername}}</v-list-item-title>
            </template>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item @click="$emit('performLogout')">
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-title>{{$t('collectinitialdata.menuitem_logout')}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

import { ToggleTheme } from "@/mixins/toggleTheme";
import LanguageSelector from "@/components/_common/LanguageSelector";

export default {
  components: {LanguageSelector},
  mixins: [ToggleTheme],
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {
    ...mapGetters({
      getterCurrentUsername: "SessionManager/getterCurrentUsername",
      getterCurrentUserDisplayname: "SessionManager/getterCurrentUserDisplayname",
    })
  }
};
</script>
