//
// Entity classification
//

export const ENTITYSECTOR_DOCUMENT_NAME = "entity_sectors";

export const ENTITYSECTOR_RECORD_TYPE = "lookup";
export const ENTITYSECTOR_RECORD_SUBTYPE = "entity";

export const ENTITYSECTOR_RECORD = {
    name: "",
    description: "",
    countries: []
};

