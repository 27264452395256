import {ADDRESS_DATA, CONTACT_DATA} from "@/config/config.couchdb";

//
// Assets
//
export const ASSET_RECORD_TYPE = "asset";
export const ASSET_SCHEMA_VERSION = 1;

export const ASSET_RECORD = {

  asset_id: undefined,

  active: true,
  assigned_to: "",

  version: ASSET_SCHEMA_VERSION,

  core: {
    category_id: false,

    name: "",
    vendor: "",
    version: "",
    count: 1,
    purpose: "",

    external_ref: "",
    source: "",

    description: "",

    activation_date: false,
    decommission_date: false,

    decommission_notes: "",

    valid: false
  },

  usage: {
    type_id: false,
    target_id: false,

    notes: "",

    valid: false
  },

  location: {
    installation_site: "",

    location_id: false,

    external_site: false,

    ...ADDRESS_DATA,

    notes: "",
    valid: false
  },

  responsible: {
    name: '',

    role_id: "ID_ENTITYCONTACT_ROLE_ASSETRESPONSIBLE",

    location_id: false,

    ...ADDRESS_DATA,
    ...CONTACT_DATA,
    valid: false
  },

  created_at: false,
  created_by: "",

  valid: false
};
