export const THRESHOLDCRITERIA_I18N_KEY = "threshold_criteria.";

export const THRESHOLDCRITERIA_LIST = {
    "ID_THRESHOLDCRITERIA_SCORING": {
        "name": "THRESHOLDCRITERIA_SCORING_LABEL",
        "description": "THRESHOLDCRITERIA_SCORING_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_SCORING_PRINTTEXT",
        "sequence": 10
    },
    "ID_THRESHOLDCRITERIA_PROFILING": {
        "name": "THRESHOLDCRITERIA_PROFILING_LABEL",
        "description": "THRESHOLDCRITERIA_PROFILING_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_PROFILING_PRINTTEXT",
        "sequence": 20
    },
    "ID_THRESHOLDCRITERIA_MONITIORING": {
        "name": "THRESHOLDCRITERIA_MONITIORING_LABEL",
        "description": "THRESHOLDCRITERIA_MONITIORING_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_MONITIORING_PRINTTEXT",
        "sequence": 30
    },
    "ID_THRESHOLDCRITERIA_MASSDATAPROCESING": {
        "name": "THRESHOLDCRITERIA_MASSDATAPROCESING_LABEL",
        "description": "THRESHOLDCRITERIA_MASSDATAPROCESING_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_MASSDATAPROCESING_PRINTTEXT",
        "sequence": 40
    },
    "ID_THRESHOLDCRITERIA_DATAMATCHING": {
        "name": "THRESHOLDCRITERIA_DATAMATCHING_LABEL",
        "description": "THRESHOLDCRITERIA_DATAMATCHING_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_DATAMATCHING_PRINTTEXT",
        "sequence": 50
    },
    "ID_THRESHOLDCRITERIA_VULNERABLESUBJECTS": {
        "name": "THRESHOLDCRITERIA_VULNERABLESUBJECTS_LABEL",
        "description": "THRESHOLDCRITERIA_VULNERABLESUBJECTS_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_VULNERABLESUBJECTS_PRINTTEXT",
        "sequence": 60
    },
    "ID_THRESHOLDCRITERIA_SENSITIVEDATA": {
        "name": "THRESHOLDCRITERIA_SENSITIVEDATA_LABEL",
        "description": "THRESHOLDCRITERIA_SENSITIVEDATA_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_SENSITIVEDATA_PRINTTEXT",
        "sequence": 70
    },
    "ID_THRESHOLDCRITERIA_INNOVATIVEMETHODS": {
        "name": "THRESHOLDCRITERIA_INNOVATIVEMETHODS_LABEL",
        "description": "THRESHOLDCRITERIA_INNOVATIVEMETHODS_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_INNOVATIVEMETHODS_PRINTTEXT",
        "sequence": 80
    },
    "ID_THRESHOLDCRITERIA_LIMITRIGHTS": {
        "name": "THRESHOLDCRITERIA_LIMITRIGHTS_LABEL",
        "description": "THRESHOLDCRITERIA_LIMITRIGHTS_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_LIMITRIGHTS_PRINTTEXT",
        "sequence": 90
    },
    "ID_THRESHOLDCRITERIA_EXTERNALDATATRANSFER": {
        "name": "THRESHOLDCRITERIA_EXTERNALDATATRANSFER_LABEL",
        "description": "THRESHOLDCRITERIA_EXTERNALDATATRANSFER_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_EXTERNALDATATRANSFER_PRINTTEXT",
        "sequence": 100
    },
    "ID_THRESHOLDCRITERIA_SIMILARITY": {
        "name": "THRESHOLDCRITERIA_SIMILARITY_LABEL",
        "description": "THRESHOLDCRITERIA_SIMILARITY_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_SIMILARITY_PRINTTEXT",
        "sequence": 110
    },
    "ID_THRESHOLDCRITERIA_ANTICIPATION": {
        "name": "THRESHOLDCRITERIA_ANTICIPATION_LABEL",
        "description": "THRESHOLDCRITERIA_ANTICIPATION_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_ANTICIPATION_PRINTTEXT",
        "sequence": 120
    },
    "ID_THRESHOLDCRITERIA_PROCESSING_BY_CONTRACTOR": {
        "name": "THRESHOLDCRITERIA_PROCESSING_BY_CONTRACTOR_LABEL",
        "description": "THRESHOLDCRITERIA_PROCESSING_BY_CONTRACTOR_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_PROCESSING_BY_CONTRACTOR_PRINTTEXT",
        "sequence": 130
    },
    "ID_THRESHOLDCRITERIA_WHITELIST": {
        "name": "THRESHOLDCRITERIA_WHITELIST_LABEL",
        "description": "THRESHOLDCRITERIA_WHITELIST_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_WHITELIST_PRINTTEXT",
        "sequence": 140
    },
    "ID_THRESHOLDCRITERIA_BLACKLIST": {
        "name": "THRESHOLDCRITERIA_BLACKLIST_LABEL",
        "description": "THRESHOLDCRITERIA_BLACKLIST_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_BLACKLIST_PRINTTEXT",
        "sequence": 150
    },
    "ID_THRESHOLDCRITERIA_OTHER": {
        "name": "THRESHOLDCRITERIA_OTHER_LABEL",
        "description": "THRESHOLDCRITERIA_OTHER_DESCRIPTION",
        "printtext": "THRESHOLDCRITERIA_OTHER_PRINTTEXT",
        "sequence": 200
    }
};