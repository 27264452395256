export const CONTRACTPARTNERTYPE_I18N_KEY = "contractpartner_types.";

export const CONTRACTPARTNERTYPE_LIST = {
	"ID_CONTRACTPARTNERTYPE_EXTERNALPARTY": {
		"name": "CONTRACTPARTNERTYPE_EXTERNALPARTY_LABEL",
		"description": "CONTRACTPARTNERTYPE_EXTERNALPARTY_DESCRIPTION",
		"gdprrole": "external"
	},
	"ID_CONTRACTPARTNERTYPE_ITSERVICEPROVIDER": {
		"name": "CONTRACTPARTNERTYPE_ITSERVICEPROVIDER_LABEL",
		"description": "CONTRACTPARTNERTYPE_ITSERVICEPROVIDER_DESCRIPTION",
		"gdprrole": "dataprocessor"		
	},
	"ID_CONTRACTPARTNERTYPE_JOINTCONTROLLER": {
		"name": "CONTRACTPARTNERTYPE_JOINTCONTROLLER_LABEL",
		"description": "CONTRACTPARTNERTYPE_JOINTCONTROLLER_DESCRIPTION",
		"gdprrole": "jointcontroller"		
	},
	"ID_CONTRACTPARTNERTYPE_DATAPROCESSOR": {
		"name": "CONTRACTPARTNERTYPE_DATAPROCESSOR_LABEL",
		"description": "CONTRACTPARTNERTYPE_DATAPROCESSOR_DESCRIPTION",
		"gdprrole": "dataprocessor"		
	},
	"ID_CONTRACTPARTNERTYPE_PRINCIPALDATACONTRACTOR": {
		"name": "CONTRACTPARTNERTYPE_PRINCIPALDATACONTRACTOR_LABEL",
		"description": "CONTRACTPARTNERTYPE_PRINCIPALDATACONTRACTOR_DESCRIPTION",
		"gdprrole": "principaldatacontractor"		
	},
	"ID_CONTRACTPARTNERTYPE_CUSTOMER": {
		"name": "CONTRACTPARTNERTYPE_CUSTOMER_LABEL",
		"description": "CONTRACTPARTNERTYPE_CUSTOMER_DESCRIPTION",
		"gdprrole": "external"		
	},
	"ID_CONTRACTPARTNERTYPE_OTHER": {
		"name": "CONTRACTPARTNERTYPE_OTHER_LABEL",
		"description": "CONTRACTPARTNERTYPE_OTHER_DESCRIPTION",
		"gdprrole": "external"
	}
};
