<template>
  <v-container justify="center">
    <v-dialog v-if="dialog"
      id="modal-container"
      :value="dialog"
      :width="width"
      :max-width="maxWidth"
      :persistent="persistent"
    >
      <v-card :class="contentClass">
        <v-card-title class="card-title" id="boxTitle" ref="cardTitle">
          <div class="card-header--text">
            <slot name="title"></slot>
          </div>

          <template v-if="showClose">
            <div class="flex-grow-1"></div>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <div class="card-header--btn">
                  <div v-on="on">
                    <v-btn
                      icon
                      class="mr-2"
                      v-on="on"
                      @click="closeModalClick">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
              <span v-text="$t('modaldialoglayout.tooltip_close')"></span>
            </v-tooltip>
          </template>
        </v-card-title>
        <v-card-text class="card-body">

          <slot name="subtitle"></slot>

          <slot name="content"></slot>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  name: "ModalDialogLayout",
  props: {
    width: {
      required: false,
      default: "auto"
    },
    maxWidth: {
      required: false,
      default: "75%"
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false
    },
    persistent:  {
      type: Boolean,
      required: false,
      default: true
    },
    contentClass: {
      type: String,
      required: false,
      default: "no-scroll"
    },
    showClose: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  methods: {
    closeModalClick() {
      this.$emit('closeModal');
    }
  }
};
</script>

<style scoped>
.no-scroll {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
