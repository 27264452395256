export const DOCUMENTCATEGORY_I18N_KEY = "document_category.";

export const DOCUMENTCATEGORY_LIST = {
  "ID_DOCUMENTCATEGORY_CONTRACT": {
    "name": "DOCUMENTCATEGORY_CONTRACT_LABEL",
    "description": "DOCUMENTCATEGORY_CONTRACT_DESCRIPTION"
  },
  "ID_DOCUMENTCATEGORY_GUIDELINE": {
    "name": "DOCUMENTCATEGORY_GUIDELINE_LABEL",
    "description": "DOCUMENTCATEGORY_GUIDELINE_DESCRIPTION"
  },
  "ID_DOCUMENTCATEGORY_PROOF": {
    "name": "DOCUMENTCATEGORY_PROOF_LABEL",
    "description": "DOCUMENTCATEGORY_PROOF_DESCRIPTION"
  },
  "ID_DOCUMENTCATEGORY_DSFA": {
    "name": "DOCUMENTCATEGORY_DSFA_LABEL",
    "description": "DOCUMENTCATEGORY_DSFA_DESCRIPTION"
  },
  "ID_DOCUMENTCATEGORY_INFORMATION": {
    "name": "DOCUMENTCATEGORY_INFORMATION_LABEL",
    "description": "DOCUMENTCATEGORY_INFORMATION_DESCRIPTION"
  },
  "ID_DOCUMENTCATEGORY_AUDIT": {
    "name": "DOCUMENTCATEGORY_AUDIT_LABEL",
    "description": "DOCUMENTCATEGORY_AUDIT_DESCRIPTION"
  },
  "ID_DOCUMENTCATEGORY_PROCESS": {
    "name": "DOCUMENTCATEGORY_PROCESS_LABEL",
    "description": "DOCUMENTCATEGORY_PROCESS_DESCRIPTION"
  }, 
  "ID_DOCUMENTCATEGORY_RISK": {
    "name": "DOCUMENTCATEGORY_RISK_LABEL",
    "description": "DOCUMENTCATEGORY_RISK_DESCRIPTION"
  }, 
  "ID_DOCUMENTCATEGORY_PROCEDURE": {
    "name": "DOCUMENTCATEGORY_PROCEDURE_LABEL",
    "description": "DOCUMENTCATEGORY_PROCEDURE_DESCRIPTION"
  },     
  "ID_DOCUMENTCATEGORY_ITSECURITY": {
    "name": "DOCUMENTCATEGORY_ITSECURITY_LABEL",
    "description": "DOCUMENTCATEGORY_ITSECURITY_DESCRIPTION"
  },
  "ID_DOCUMENTCATEGORY_REQUEST": {
    "name": "DOCUMENTCATEGORY_REQUEST_LABEL",
    "description": "DOCUMENTCATEGORY_REQUEST_DESCRIPTION"
  },  
  "ID_DOCUMENTCATEGORY_INCIDENT": {
    "name": "DOCUMENTCATEGORY_INCIDENT_LABEL",
    "description": "DOCUMENTCATEGORY_INCIDENT_DESCRIPTION"
  },
  "ID_DOCUMENTCATEGORY_TRAINING": {
    "name": "DOCUMENTCATEGORY_TRAINING_LABEL",
    "description": "DOCUMENTCATEGORY_TRAINING_DESCRIPTION"
  },
  "ID_DOCUMENTCATEGORY_LAW": {
    "name": "DOCUMENTCATEGORY_LAW_LABEL",
    "description": "DOCUMENTCATEGORY_LAW_DESCRIPTION"
  },
  "ID_DOCUMENTCATEGORY_TASK": {
    "name": "DOCUMENTCATEGORY_TASK_LABEL",
    "description": "DOCUMENTCATEGORY_TASK_DESCRIPTION"
  },
  "ID_DOCUMENTCATEGORY_OTHER": {
    "name": "DOCUMENTCATEGORY_OTHER_LABEL",
    "description": "DOCUMENTCATEGORY_OTHER_DESCRIPTION"
  }  
};
