import { mapGetters, mapActions } from "vuex";

export const ToggleTheme = {
  created() {
    this.$vuetify.theme.dark = this.getterIsDarkThemeMode;
  },  
  methods: {
     toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.actionSetDarkThemeMode(this.$vuetify.theme.dark);
    },    
    ...mapActions(["actionSetDarkThemeMode"]) 
  },
  computed: {
    ...mapGetters(["getterIsDarkThemeMode"])
  },
};
