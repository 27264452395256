<template>
  <v-alert v-if="show"
    ref="alert"
    :dismissible="true"
    :type="type"
    @click="localShow = false"
    elevation="2"
    class="alert--component-custom"
  >
    {{$t(message)}}
  </v-alert>
</template>

<script>
export default {
  name: "AlertNotifyMessager",
  props: {
    type: {
      // Specify a success, info, warning or error alert. Uses the contextual color and has a pre-defined icon.
      // we are using success and warning
      required: false,
      default: "success"
    },
    message: {
      type: String,
      required: false,
    },
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    duration: {
      type: Number,
      required: false,
      default: 4000
    }
  },
  data() {
    return {
      localShow: false,
    };
  },
  mounted() {
    this.localShow = this.show;
  },
  methods: {
  },
  computed: {},
  watch: {
    show(n) {
      this.localShow = this.show;
      if (!n) {
        return;
      }

      window.scrollTo(0, 0);

      var refreshIntervalId = false;

      // dismiss the alert after seconds
      if(this.localShow) {
         refreshIntervalId = setInterval(() => {
          this.localShow = false;
          this.$emit("alertClosed");
          clearInterval(refreshIntervalId);
        }, this.duration);
      }
    }
  }
};
</script>

<style scoped>
.alert--component-custom {
  position: fixed;
  top: 70px;
  right: 15px;
  z-index: 10;
}
</style>