export const LAWBASIS_CONTENT_DOCUMENT_NAME = 'lawbasis_content';

export const LAWBASIS_CONTENT_DOCUMENT_TYPE = "lawbasis";
export const LAWBASIS_CONTENT_DOCUMENT_SUBTYPE = "content";

export const LAWBASIS_SHORTNAME_LENGTH = 90;

//
// lawbasis content list entry
//

export const LAWBASIS_CONTENT_RECORD = {
  keyid: "",
  contenttype: "",  // primary / secondary / addon
  documenttype: "", // multi / single
  active: true,
  document_id: "",
  name: "",
  justice_areas: [],
  entity_types: [],
  details : {
    lawtype: "",    // gdpr / common
    lawcodes: [],
    agencylistcode: "",
    datacategory_overrides: [],
    region_override: true
  }
};
