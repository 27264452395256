import { Http } from './http.init';
import { ResponseWrapper, ErrorWrapper } from './response.util';

export class RestBaseService {
  static get entity () {
    throw new Error('entity getter not defined');
  }

  static get prefix () {
    throw new Error('prefix getter not defined');
  }   

  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request (status = { auth: false }) {
    return new Http(status);
  }

  static responseWrapper (...rest) {
    return new ResponseWrapper(...rest);
  }

  static errorWrapper (...rest) {
    return new ErrorWrapper(...rest);
  }

  /**
   * ------------------------------
   * @API_CALLS_PUBLIC
   * ------------------------------
   */

  static async getListPublic (parameters = {}) {
    const params = { ...parameters };

    try {
      const response = await this.request().get(`${this.entity}`, { params });
      const data = {
        content: response.data.data,
        total: Number(response.headers['x-total-count'])
      };

      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getByIdPublic (id) {
    try {
      const response = await this.request().get(`${this.entity}/${id}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  /**
   * ------------------------------
   * @API_CALLS_PRIVATE
   * ------------------------------
   */

  static async _getById (id) {
    try {
      const response = await this.request({ auth: true }).get(`${this.entity}/${id}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async _create (data = {}) {
    try {
      const response = await this.request({ auth: true }).post(`${this.entity}`, data);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async _update (id, data = {}) {
    try {
      const response = await this.request({ auth: true }).patch(`${this.entity}/${id}`, data);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async _remove (id) {
    try {
      const response = await this.request({ auth: true }).delete(`${this.entity}/${id}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
