//
// Entity
//

export const ENTITY_RECORD_TYPE = "entity";

export const ENTITY_DOCUMENT_NAME = 'entity';

export const DEFAULT_ENTITY_CLASS = "ID_ENTITYCLASS_OTHERSERVICES";
export const DEFAULT_ENTITY_PATTERN = "ID_PATTERN_MINIMALDEFAULT";

export const ENTITYDATA_SCHEMA_VERSION = 1;

export const ENTITYDATA_RECORD = {
  EntityInfo: {
    entity_name: '',
    country:"",
    valid: false
  },
  Responsible: {
    responsible_name: '',
    valid: false
  },
  EntityDetails: {
    entity_type: '',
    entity_class: '',

    // legacy
    business_sector: '',

    foundation_date: "",
    initialdefault_date: "",

    num_employees_fulltime: 1,
    num_employees_parttime: 0,
    num_employees_volunteer: 0,
    num_employees_external: 0,

    valid: false
  },
  EntityInitOptions: {
    entity_pattern: "",
    gdprlawbasis: "",
    countrycode: "",
    regioncode: "",

    valid: false
  },
  EntityAgreement: {
    terms_confirmed: false,
    privacypolicy_confirmed: false,
    b2bstatus_confirmed: false,
    processorcontract_confirmed: false,
    confirmation_date: '',
    valid: false
  },

  valid: false
};
