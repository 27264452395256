import { mapGetters } from "vuex";

export const AccessFunctions = {
  computed: {
        ...mapGetters({
            getterCurrentUserCanManageUsers: "AccessManager/getterCurrentUserCanManageUsers",
            getterCurrentUserCanManageDocuments: "AccessManager/getterCurrentUserCanManageDocuments",

            getterCheckLicensedModuleUsrmgmt: "LicenseManager/getterCheckLicensedModuleUsrmgmt",
            getterCheckLicensedModuleProof: "LicenseManager/getterCheckLicensedModuleProof",
            getterCheckLicensedModuleLocation: "LicenseManager/getterCheckLicensedModuleLocation",
            getterCheckLicensedModuleIncident: "LicenseManager/getterCheckLicensedModuleIncident",
            getterCheckLicensedModuleRequest: "LicenseManager/getterCheckLicensedModuleRequest",
            getterCheckLicensedModuleProcess: "LicenseManager/getterCheckLicensedModuleProcess",
            getterCheckLicensedModuleRisk: "LicenseManager/getterCheckLicensedModuleRisk",
            getterCheckLicensedModuleAsset: "LicenseManager/getterCheckLicensedModuleAsset",
            getterCheckLicensedModuleProcedure: "LicenseManager/getterCheckLicensedModuleProcedure",
            getterCheckLicensedModuleDsfa: "LicenseManager/getterCheckLicensedModuleDsfa",
            getterCheckLicensedModuleTask: "LicenseManager/getterCheckLicensedModuleTask",
            getterCheckLicensedModuleOrgChart: "LicenseManager/getterCheckLicensedModuleOrgChart",
            getterCheckLicensedModuleContactBook: "LicenseManager/getterCheckLicensedModuleContactBook",
            getterCheckLicensedModuleArea: "LicenseManager/getterCheckLicensedModuleArea",
            getterCheckLicensedModuleEntity: "LicenseManager/getterCheckLicensedModuleEntity",
            getterCheckLicensedModuleDocumentArchive: "LicenseManager/getterCheckLicensedModuleDocumentArchive",
            getterCheckLicensedModuleActivityDoc: "LicenseManager/getterCheckLicensedModuleActivityDoc",
            getterCheckLicensedModuleContractPartner: "LicenseManager/getterCheckLicensedModuleContractPartner",

            getterRegulationListLimit: "LicenseManager/getterRegulationListLimit",
            getterSecurityMeasureListLimit: "LicenseManager/getterSecurityMeasureListLimit",
            getterArtefactListLimit: "LicenseManager/getterArtefactListLimit",
            getterDataSubjectListLimit: "LicenseManager/getterDataSubjectListLimit",
            getterDataCategoryListLimit: "LicenseManager/getterDataCategoryListLimit",
            getterAuthorityListLimit: "LicenseManager/getterAuthorityListLimit",
            getterFineCalculatorLimit: "LicenseManager/getterFineCalculatorLimit",
            getterRepositoryLimit: "LicenseManager/getterRepositoryLimit",

            getterHasLocationModulePrivelege: "AccessManager/getterHasLocationModulePrivelege",
            getterHasIncidentPrivelege: "AccessManager/getterHasIncidentModulePrivelege",
            getterHasRequestPrivelege: "AccessManager/getterHasRequestModulePrivelege",
            getterHasProcessPrivelege: "AccessManager/getterHasProcessModulePrivelege",
            getterHasActivityDocPrivelege: "AccessManager/getterHasActivityDocModulePrivelege",
            getterHasTaskPrivelege: "AccessManager/getterHasTaskModulePrivelege",
            getterHasRiskPrivelege: "AccessManager/getterHasRiskModulePrivelege",
            getterHasProofPrivelege: "AccessManager/getterHasProofModulePrivelege",
            getterHasProcedurePrivelege: "AccessManager/getterHasProcedureModulePrivelege",
            getterHasDsfaPrivelege: "AccessManager/getterHasDsfaModulePrivelege",
            getterHasAreaPrivelege: "AccessManager/getterHasAreaModulePrivelege",
            getterHasEntityPrivelege: "AccessManager/getterHasEntityModulePrivelege",
            getterHasContractPartnerPrivelege: "AccessManager/getterHasContractPartnerModulePrivelege",
            getterHasDocumentArchivePrivelege: "AccessManager/getterHasDocumentArchiveModulePrivelege",
            getterHasContactBookPrivelege: "AccessManager/getterHasContactBookModulePrivelege",
            getterHasOrgChartPrivelege: "AccessManager/getterHasOrgChartModulePrivelege",
            getterHasAssetPrivelege: "AccessManager/getterHasAssetModulePrivelege",
            getterHasDfsaPrivelege: "AccessManager/getterHasDfsaPrivelege"
        })
    }
};