import { RestBaseService } from './restbase.service';
import { ErrorWrapper, ResponseWrapper } from './response.util';
import { BACKENDAPI_APPID } from '@/config/config.constants.js';

import $store from '../store';

export class UserProfileService extends RestBaseService {
  static get entity () {
    return '';
  }

  static get prefix () {
    return 'profile';
  }  

  static async getCurrentProfile() {
    try {
      // console.debug("UserProfileService - getCurrentProfile", $store.getters['AuthTokenManager/getterAccessTokenSubject']());
      const response = await this.request({ auth: true }).get(`${this.prefix}/current`);

      return new ResponseWrapper(response, response.data);
    } 
    catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updateCurrentProfile(profileData) {
    try {
      // console.debug("UserProfileService - updateCurrentProfile", $store.getters['AuthTokenManager/getterAccessTokenSubject']());

      // Add appid to profile data
      profileData.appconfig.appid = BACKENDAPI_APPID;

      const response = await this.request({ auth: true }).post(`${this.prefix}/current`, profileData);

      return new ResponseWrapper(response, response.data);
    } 
    catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }  

  static async changeCurrentPassword(newpassword) {
    try {
      // console.debug("UserProfileService - changeCurrentAccountPassword", $store.getters['AuthTokenManager/getterAccessTokenSubject']());

      // Add appid to profile data
      const newPassswordData = {
        password: newpassword,
        appid: BACKENDAPI_APPID
      };

      const response = await this.request({ auth: true }).post(`${this.prefix}/passwordchange`, newPassswordData);

      return new ResponseWrapper(response, response.data);
    } 
    catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }    
}
