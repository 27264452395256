import CommonUtils from '@/utils/common.utils.js';

import {GDPR_COUNTRYLIST} from "@/lookup/countries";

export default {
  namespaced: true,
  state: {
    GDPR_LAWTYPE: "gdpr",
    COMMON_LAWTYPE: "common"
  },
  mutations: {
    LAWBOOKS_GDPR_INIT(state, payload) {
      // console.debug("LAWBOOKS_GDPR_INIT - payload", payload);
      state.LAWBOOKS_GDPR_ARRAY = [];

      // console.debug("LAWBOOKS_GDPR_INIT - payload", state);
    },
    LAWBOOKS_GDPR_ADD(state, payload) {
      // console.debug("LAWBOOKS_GDPR_ARRAY - payload", payload);
      state.LAWBOOKS_GDPR_ARRAY.push(payload);

      // console.debug("LAWBOOKS_GDPR_ARRAY - payload", state);
    },
  },
  actions: {
    async actionRetrieveAllLawbasisDocs({
      state,
      getters,
      rootGetters,
      dispatch,
      commit,
    }) {
      // await dispatch("GdprManager/actionRetrieveGdprDoc", false, {root: true});
      return await dispatch("LawbasisContentManager/actionRetrieveAllLawbasisDocs", false, {root: true});
    },
  },
  getters: {
    getterIsLawbasisCountrySwiss: (state, getters, rootState, rootGetters) => {
      try
      {
        return (rootGetters["GdprManager/getterGdprData"].lawbasis.countrycode == "CH");
      }
      catch(err)
      {
        console.error("getterIsLawbasisCountrySwiss - Error: ", err);
        return false;
      }
    },
    getterIsLawbasisCountryGerman: (state, getters, rootState, rootGetters) => {
      try
      {
        return (rootGetters["GdprManager/getterGdprData"].lawbasis.countrycode == "DE");
      }
      catch(err)
      {
        console.error("getterIsLawbasisCountryGerman - Error: ", err);
        return false;
      }
    },
    getterIsCountryInGDPRJusticeArea: (state, getters, rootState, rootGetters) => (countrycode) => {
      try {
        return GDPR_COUNTRYLIST.indexOf(countrycode) !== -1;
      } catch (err) {
        console.error("getterIsCountryInGDPRJusticeArea - Error: ", err);
        return false;
      }
    },
    getterHasEntityGermanSpecialChurchLawbasis: (state, getters, rootState, rootGetters) => {
      var isGermanChurchLawbasis = false;

      try {
        const gdprObj = rootGetters["GdprManager/getterGdprData"];

        if (gdprObj.lawbasis.countrycode == "DE" &&
            gdprObj.lawbasis.gdprlawcodes.some(item => ["KDG", "KDR-OG", "DSK-EKD"].includes(item))) {
              isGermanChurchLawbasis = true;
        }
      }
      catch (error) {
        console.error("getterHasEntityGermanSpecialChurchLawbasis - Error", error);
      }

      // console.debug("getterHasEntityGermanSpecialChurchLawbasis - isGermanChurchLawbasis", isGermanChurchLawbasis);

      return isGermanChurchLawbasis;
    },
    getterHasEntityGermanEvangelicalChurchLawbasis: (state, getters, rootState, rootGetters) => {
      var isGermanEvangelicalChurchLawbasis = false;

      try {
        const gdprObj = rootGetters["GdprManager/getterGdprData"];

        if (gdprObj.lawbasis.countrycode == "DE" &&
            gdprObj.lawbasis.gdprlawcodes.some(item => item == "DSK-EKD")) {
              isGermanEvangelicalChurchLawbasis = true;
        }
      }
      catch (error) {
        console.error("getterHasEntityGermanEvangelicalChurchLawbasis - Error", error);
      }

      return isGermanEvangelicalChurchLawbasis;
    },
    getterHasEntityGermanCatholicChurchLawbasis: (state, getters, rootState, rootGetters) => {
      var isGermanCatholicChurchLawbasis = false;

      try {
        const gdprObj = rootGetters["GdprManager/getterGdprData"];

        if (gdprObj.lawbasis.countrycode == "DE" &&
            gdprObj.lawbasis.gdprlawcodes.some(item => ["KDG", "KDR-OG"].includes(item))) {
              isGermanCatholicChurchLawbasis = true;
        }
      }
      catch (error) {
        console.error("getterHasEntityGermanCatholicChurchLawbasis - Error", error);
      }

      return isGermanCatholicChurchLawbasis;
    },
    getterHasEntityGermanKDGLawbasis: (state, getters, rootState, rootGetters) => {
      var isGermanCatholicChurchLawbasis = false;

      try {
        const gdprObj = rootGetters["GdprManager/getterGdprData"];

        if (gdprObj.lawbasis.countrycode == "DE" &&
            gdprObj.lawbasis.gdprlawcodes.some(item => item == "KDG")) {
              isGermanCatholicChurchLawbasis = true;
        }
      }
      catch (error) {
        console.error("getterHasEntityGermanKDGLawbasis - Error", error);
      }

      return isGermanCatholicChurchLawbasis;
    },
    getterHasEntityGermanKDROGLawbasis: (state, getters, rootState, rootGetters) => {
      var isGermanCatholicChurchLawbasis = false;

      try {
        const gdprObj = rootGetters["GdprManager/getterGdprData"];

        if (gdprObj.lawbasis.countrycode == "DE" &&
            gdprObj.lawbasis.gdprlawcodes.some(item => item == "KDR-OG")) {
              isGermanCatholicChurchLawbasis = true;
        }
      }
      catch (error) {
        console.error("getterHasEntityGermanKDROGLawbasis - Error", error);
      }

      return isGermanCatholicChurchLawbasis;
    },
    getterIsDataCategoryTypeSpecialById: (state, getters, rootState, rootGetters) => (datacategory_id) => {
      return getters.getterIsDataCategorySpecial(rootGetters["DataCategoryManager/getterDataCategoryById"](datacategory_id));
    },
    getterIsDataCategorySpecial: (state, getters, rootState, rootGetters) => (datacategoryObj) => {
      // console.debug("getterIsDataCategorySpecial - datacategoryObj", datacategoryObj);

      try {
        // This checks the custom created data categories by property "type"
        if (!Object.prototype.hasOwnProperty.call(datacategoryObj, "type")) {
          // console.debug("getterIsDataCategorySpecial - GDPR", (datacategoryObj.type > 6));
          return false;
        }

        var categorytype = datacategoryObj.type

        const gdprDataObj = rootGetters["GdprManager/getterGdprData"];

        // This checks the custom created data categories by property "type"
        if (!Object.prototype.hasOwnProperty.call(gdprDataObj, "lawbasis")) {
          return false;
        }

        // This checks the custom created data categories by property "type"
        if ( Object.prototype.hasOwnProperty.call(gdprDataObj.lawbasis, "lawbasis_id") == false ||
             CommonUtils.isStringEmpty(gdprDataObj.lawbasis.lawbasis_id)) {
          return false;
        }

        const contentdataObj = rootGetters["LawbasisContentManager/getterLawbasisContentById"](gdprDataObj.lawbasis.lawbasis_id)

        // This checks the custom created data categories by property "type"
        if (Object.prototype.hasOwnProperty.call(contentdataObj.details, "datacategory_overrides") &&
            Object.prototype.hasOwnProperty.call(datacategoryObj, "id")) {
          const overrides = contentdataObj.details.datacategory_overrides.filter(el => el.id == datacategoryObj.id);

          if(!CommonUtils.isArrayEmpty(overrides)) {
            categorytype = overrides[0].type;
          }
        }

        return (categorytype > 6);
      } catch (err) {
        console.error("getterIsDataCategorySpecial - Error", err);
        return false;
      }
    },
    getterLawbasisGDPRCountryCodesAsArray: (state, getters, rootState, rootGetters) => {
      return [...GDPR_COUNTRYLIST];
    },

    // GDPR Lawbasis regluation functions
    // getterLawbasisGDPRAsArray: (state, getters, rootState, rootGetters) => {
    //   return getters.getterLawbasisSourceAsArrayByLawcodeByCountryByLc(state.GDPR_LAWTYPE, "", "");
    // },
    getterLawbasisGDPRAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterLawbasisSourceAsArrayByLawcodeByCountryByLc(state.GDPR_LAWTYPE, "", lc);
    },
    getterLawbasisGDPRAsArrayByCountryByLc: (state, getters, rootState, rootGetters) => (alpha2, lc) => {
      return getters.getterLawbasisSourceAsArrayByLawcodeByCountryByLc(state.GDPR_LAWTYPE, alpha2, lc);
    },
    /*
    getterLawbasisSpecialAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterLawbasisSourceAsArrayByLawcodeByCountryByLc(state.COMMON_LAWTYPE, "", "");
    },
    */
    getterLawbasisSpecialAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterLawbasisSourceAsArrayByLawcodeByCountryByLc(state.COMMON_LAWTYPE, "", lc);
    },
    getterLawbasisSourceAsArrayByLawcodeByCountryByLc: (state, getters, rootState, rootGetters) => (lawtype, alpha2, lc) => {
      try {
        const gdprDataObj = rootGetters["GdprManager/getterGdprData"];

        // const regary = rootGetters["LawbasisContentManager/getterLawBookRegulationsAsArrayByCountryByLc"](alpha2, lc);

        // console.debug("getterLawbasisSourceAsArrayByLawcodeByCountryByLc - lawtype", lawtype);
        // console.debug("getterLawbasisSourceAsArrayByLawcodeByCountryByLc - alpha2", alpha2);
        // console.debug("getterLawbasisSourceAsArrayByLawcodeByCountryByLc - lc", lc);
        // console.debug("getterLawbasisSourceAsArrayByLawcodeByCountryByLc - regary", regary);

        return rootGetters["LawbasisContentManager/getterLawBookRegulationsAsArrayByCountryByLc"](alpha2, lc)
          .filter(item => gdprDataObj.lawbasis.gdprlawcodes.includes(item.code))
          .filter(item => item.lawtype == lawtype);
      } catch (err) {
        console.error("getterLawbasisSourceAsArrayByLawcodeByCountryByLc - Error", err);
        return [];
      }
    },
    getterLawbasisRegulationById: (state, getters, rootState, rootGetters) => (regulation_id) => {
      return getters.getterLawbasisRegulationByIdByLc(regulation_id, "");
    },
    getterLawbasisRegulationByIdByLc: (state, getters, rootState, rootGetters) => (regulation_id, lc) => {
      if (CommonUtils.isStringEmpty(regulation_id))
        return false;

      const array = rootGetters["LawbasisContentManager/getterLawBookRegulationsAsArrayByCountryByLc"]("", lc).filter((item) => item.id === regulation_id);

      if (array.length != 1)
        return false;

      return {...array[0]};
    },
    getterTransformGdprLawbasisIdArrayToCustomGdprIdArray: (state, getters, rootState, rootGetters) => (gdpr_ids) => {
      try {
        // console.debug("getterTransformGdprLawbasisIdArrayToCustomGdprIdArray - gdpr_ids,", gdpr_ids);

        if (getters.getterIsLawbasisCountryGerman == false || CommonUtils.isArrayEmpty(gdpr_ids))
          return gdpr_ids;

        var lawbasis_array = getters.getterLawbasisGDPRAsArrayByLc("").filter(item => item.lawbasisid == rootGetters["GdprManager/getterGdprData"].lawbasis.lawbasis_id);

        // console.debug("getterTransformGdprLawbasisIdArrayToCustomGdprIdArray - lawbasis_array", lawbasis_array);

        // If no custom case is valid then return the orignal gdprid array
        if (lawbasis_array.length == 0) {
          return gdpr_ids;
        }

        // custom case is valid then map the orignal gdprid array to custom id

        var customGdprIdArray = [];

        gdpr_ids.forEach((item) => {
          // console.debug("getterTransformGdprLawbasisIdArrayToCustomGdprIdArray - item", item);

          const array = lawbasis_array.filter((lawitem) => lawitem.gdpr_id == item);

          // console.debug("getterTransformGdprLawbasisIdArrayToCustomGdprIdArray - array", array);

          if (array.length) {
            customGdprIdArray.push(array[0].id);
          }
        });

        // console.debug("getterTransformGdprLawbasisIdArrayToCustomGdprIdArray - customGdprIdArray", customGdprIdArray);

        return customGdprIdArray;
      } catch (error) {
        console.error("getterTransformGdprLawbasisIdArrayToCustomGdprIdArray", error);
        return gdpr_ids;
      }
    },

    ////////////////////////////////////////////////////////////

    getterInternalLookupTranslateArrayByLc: (state, getters, rootState, rootGetters) => (srcarray, i18nobj, lc, sortflag, sortcolumn = "display_name") => {
      // returns an array of data objects with translations
      var array = [];
      var sortColumnExits = true;

      try {
        if (Object.keys(i18nobj).length === 0 && i18nobj.constructor === Object)
          return array;

        if (!Object.prototype.hasOwnProperty.call(i18nobj, "languages"))
          return array;

        // console.debug("getterInternalLookupTranslateArrayByLc - lc", lc);

        if (i18nobj.languages.indexOf(lc) === -1)
          lc = i18nobj.defaultlang;

        // console.debug("getterInternalLookupTranslateArrayByLc", i18nobj.translations);

        for (const item in srcarray) {
          var obj = {
            ...srcarray[item],
            id: item,
            display_name: i18nobj['translations'][lc][srcarray[item]['name']],
          };

          array.push(obj);

          if(sortColumnExits === true)
            sortColumnExits = Object.prototype.hasOwnProperty.call(obj, sortcolumn);
        }

        // Sort A-Z based on sortcolumn
        if (sortflag && sortColumnExits) {
          array.sort(rootGetters["HelperManager/getterArrayOfObjectDynamicSort"](sortcolumn));
        }

      } catch (err) {
        console.error("getterInternalLookupTranslateArrayByLc", err);
        array = [];
      }

      return array;
    },
  },
};
