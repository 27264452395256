import CryptoJS from 'crypto-js';

import {
  APP_CONFIG_RECORD,
  APP_SESSION_DATA_ENCRYPT,
  APP_SESSION_DATA_ENCRYPT_KEY
} from '@/config/config.constants.js';

import Vue from 'vue';
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';

// VUEX Store Modules
import TranslationManager from './modules/TranslationManager';
import SessionManager from './modules/SessionManager';
import AuthTokenManager from './modules/AuthTokenManager';
import ProfileManager from './modules/ProfileManager';
import AccountManager from './modules/AccountManager';
import TemplateManager from './modules/TemplateManager';
import LocationManager from './modules/LocationManager';
import OfficerManager from './modules/OfficerManager';
import LicenseManager from './modules/LicenseManager';
import ContactBookManager from './modules/ContactBookManager';
import AccessManager from './modules/AccessManager';
import AssetManager from './modules/AssetManager';
import RiskManager from './modules/RiskManager';
import AttachmentManager from "./modules/AttachmentManager";
import ActivityDocManager from "./modules/ActivityDocManager";
import DsfaManager from "./modules/DsfaManager";
import ProcedureManager from "./modules/ProcedureManager";
import RequestManager from "./modules/RequestManager";
import ProofManager from "./modules/ProofManager";
import IncidentManager from "./modules/IncidentManager";
import PrintManager from "./modules/PrintManager";
import HelperManager from "./modules/HelperManager";
import ContractPartnerManager from "./modules/ContractPartnerManager";
import LookupManager from "./modules/LookupManager";
import DBLookupManager from "./modules/DBLookupManager";
import LawbasisGdprManager from "./modules/LawbasisGdprManager";
import EntityManager from './modules/EntityManager';
import AreaManager from './modules/AreaManager';
import ProcessManager from './modules/ProcessManager';
import RepositoryManager from './modules/RepositoryManager';
import UsertaskManager from './modules/UsertaskManager';
import AgencyManager from './modules/AgencyManager';
import GdprManager from './modules/GdprManager';
import ArtefactManager from './modules/ArtefactManager';
import ExportManager from "./modules/ExportManager";
import DataSubjectManager from './modules/DataSubjectManager';
import DataCategoryManager from './modules/DataCategoryManager';
import LawbasisContentManager from './modules/LawbasisContentManager';

// Root components
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

// Plugins
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const SessionPersistedState = createPersistedState({
  key: 'buzb_session',
  storage:
  {
    getItem: (key) => {
      // console.debug("SessionPersistedState getItem - Key :", key);

      if (APP_SESSION_DATA_ENCRYPT === true) {
        const encrypted = window.sessionStorage.getItem(key);

        if (encrypted) {
          return JSON.parse(CryptoJS.AES.decrypt(encrypted, APP_SESSION_DATA_ENCRYPT_KEY).toString(CryptoJS.enc.Utf8));
        }
        else
          return encrypted;
      }
      else {
        // console.debug("SessionPersistedState getItem - Key :", window.sessionStorage.getItem(key));
        return window.sessionStorage.getItem(key);
      }
    },
    setItem: (key, value) => {
      if (APP_SESSION_DATA_ENCRYPT === true)
        return window.sessionStorage.setItem(key, CryptoJS.AES.encrypt(JSON.stringify(value), APP_SESSION_DATA_ENCRYPT_KEY).toString());
      else
        return window.sessionStorage.setItem(key, value);
    },
    removeItem: (key) => window.sessionStorage.removeItem(key)
  },

  paths: [
    'Application.Runtime.is_loading', 'AuthTokenManager.refreshToken']
});

const store = createStore(Vuex.Store, {
  plugins: [SessionPersistedState],
  modules: {
    RepositoryManager,
    LicenseManager,
    SessionManager,
    AuthTokenManager,
    ProfileManager,
    AccountManager,
    AccessManager,
    EntityManager,
    AreaManager,
    ProcessManager,
    UsertaskManager,
    ArtefactManager,
    DataSubjectManager,
    DataCategoryManager,
    TranslationManager,
    TemplateManager,
    LocationManager,
    OfficerManager,
    ContactBookManager,
    AssetManager,
    RiskManager,
    AttachmentManager,
    ActivityDocManager,
    RequestManager,
    ProofManager,
    HelperManager,
    IncidentManager,
    ProcedureManager,
    DsfaManager,
    PrintManager,
    LookupManager,
    DBLookupManager,
    LawbasisGdprManager,
    AgencyManager,
    ContractPartnerManager,
    GdprManager,
    ExportManager,
    LawbasisContentManager
  },
  state: {
    Application: {
      ...APP_CONFIG_RECORD
    }
  },
  mutations,
  actions,
  getters
});

export default store;