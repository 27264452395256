export const INCIDENTTITLE_I18N_KEY = "incident_titles.";

export const INCIDENTTITLE_LIST = {

    "ID_INCIDENTTITLE_LOSSITASSET": {
      "name": "INCIDENTTITLE_LOSSITASSET_LABEL", 
      "description": "INCIDENTTITLE_LOSSITASSET_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_THEFTITASSET": {
      "name": "INCIDENTTITLE_THEFTITASSET_LABEL", 
      "description": "INCIDENTTITLE_THEFTITASSET_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_THEFTASSET": {
      "name": "INCIDENTTITLE_THEFTASSET_LABEL", 
      "description": "INCIDENTTITLE_THEFTASSET_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_LOSSASSET": {
      "name": "INCIDENTTITLE_LOSSASSET_LABEL", 
      "description": "INCIDENTTITLE_LOSSASSET_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_THEFTINTRANSFER": {
      "name": "INCIDENTTITLE_THEFTINTRANSFER_LABEL", 
      "description": "INCIDENTTITLE_THEFTINTRANSFER_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_HACKING": {
      "name": "INCIDENTTITLE_HACKING_LABEL", 
      "description": "INCIDENTTITLE_HACKING_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_INFECTION": {
      "name": "INCIDENTTITLE_INFECTION_LABEL", 
      "description": "INCIDENTTITLE_INFECTION_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_INCOMPLETEDESTRUCTIONASSET": {
      "name": "INCIDENTTITLE_INCOMPLETEDESTRUCTIONASSET_LABEL", 
      "description": "INCIDENTTITLE_INCOMPLETEDESTRUCTIONASSET_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_INCOMPLETEDESTRUCTIONITASSET": {
      "name": "INCIDENTTITLE_INCOMPLETEDESTRUCTIONITASSET_LABEL", 
      "description": "INCIDENTTITLE_INCOMPLETEDESTRUCTIONITASSET_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_UNINTENTEDPUBLICATION": {
      "name": "INCIDENTTITLE_UNINTENTEDPUBLICATION_LABEL", 
      "description": "INCIDENTTITLE_UNINTENTEDPUBLICATION_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_WRONGRECIPIENT": {
      "name": "INCIDENTTITLE_WRONGRECIPIENT_LABEL", 
      "description": "INCIDENTTITLE_WRONGRECIPIENT_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_UNINTENTEDPUBLICATIONPHONE": {
      "name": "INCIDENTTITLE_UNINTENTEDPUBLICATIONPHONE_LABEL", 
      "description": "INCIDENTTITLE_UNINTENTEDPUBLICATIONPHONE_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_BOOKINGERROR": {
      "name": "INCIDENTTITLE_BOOKINGERROR_LABEL", 
      "description": "INCIDENTTITLE_BOOKINGERROR_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_EWASTEISSUE": {
      "name": "INCIDENTTITLE_EWASTEISSUE_LABEL", 
      "description": "INCIDENTTITLE_EWASTEISSUE_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_SOFTWAREERROR": {
      "name": "INCIDENTTITLE_SOFTWAREERROR_LABEL", 
      "description": "INCIDENTTITLE_SOFTWAREERROR_DESCRIPTION"

  }, 

    "ID_INCIDENTTITLE_WRONGDISPLAY": {
      "name": "INCIDENTTITLE_WRONGDISPLAY_LABEL", 
      "description": "INCIDENTTITLE_WRONGDISPLAY_DESCRIPTION"

  }  
};
  