export const PROOFSTAGE_I18N_KEY = "proof_stages.";

export const PROOFSTAGE_LIST = {
    "ID_PROOFSTAGE_PLANED": {
      "stage": 1,
      "name": "PROOFSTAGE_PLANED_LABEL",
      "description": "PROOFSTAGE_PLANED_DESCRIPTION",
      "printtext": "PROOFSTAGE_PLANED_PRINTTEXT"
    },
    "ID_PROOFSTAGE_REVIEW": {
      "stage": 2,
      "name": "PROOFSTAGE_REVIEW_LABEL",
      "description": "PROOFSTAGE_REVIEW_DESCRIPTION",
      "printtext": "PROOFSTAGE_REVIEW_PRINTTEXT"
    },
    "ID_PROOFSTAGE_COMPLETED": {
      "stage": 3,
      "name": "PROOFSTAGE_COMPLETED_LABEL",
      "description": "PROOFSTAGE_COMPLETED_DESCRIPTION",
      "printtext": "PROOFSTAGE_COMPLETED_PRINTTEXT"
    }
};
 