import {LicenseService} from '@/services/license.service';

import {LICENSE_GRACEPERIOD} from '@/config/config.constants.js';

import * as LicenseModules from '@/config/config.licmodules.js';

import merge from "lodash.merge";

const LICENSE_LIMITS = {
  location: {
    count_max: 0
  },
  usrmgmt: {
    count_max: 0
  },
  entity: {
    countries: [],
    no_gdpr_officer: false
  },
  tools: {
    no_regulation_list: false,
    no_authority_list: false,
    no_finecalculator: false,
    no_repository: false,
    no_mailnotification: false,
    no_securitymeasure_list: false,
    no_datasubject_list: false,
    no_datacategory_list: false,
    no_artefact_list: false,
  },
  features: {
    listmenu_visible: true,
    datacategory_editable: false,
    securitymeasure_editable: false,
    datasubject_editable: false,
    artefact_editable: false,
    activitydocthreshold_visible: false,
  }
};

const LICENSE_RECORD = {
  "license_id": "",
  "tenant_id": "",
  "license_no": "",
  "licensee": "",

  "version": 0,

  "vendor": "",
  "plan": "",
  "expiry": "",
  "modules": [],
  "limits": { ...LICENSE_LIMITS},
  "codes": []
};

export default {
  namespaced: true,
  state: {
    LicenseData: {}
  },
  mutations: {
    LICENSE_LOAD(state, licensedata)
    {
      state.LicenseData = {
        ...licensedata};
    },
    LICENSE_RESET(state, payload)
    {
      state.LicenseData = { ...LICENSE_RECORD};
    }
  },
  actions: {
    async actionRetrieveLicenseDoc({
      state,
      rootGetters,
      dispatch,
      commit
    }) {
      try
      {
        const response = await LicenseService.getLicense(rootGetters['SessionManager/getterCurrentUserTenant']);

        // console.debug("actionRetrieveLicenseDoc - response", response);

        if(response.success)
        {
          commit('LICENSE_LOAD', merge({}, LICENSE_RECORD, response.data));
        }
        else
        {
          commit('LICENSE_RESET', {});
        }

        return response.success;
      }
      catch(err)
      {
        console.error('actionRetrieveLicenseDoc - Error : ', err);

        dispatch('actionSetAlertMessage', { type: "error", active: true, message: 'common_alert.msg_license_error'}, {root:true});

        return false;
      }
    },
  },
  getters: {
    getterLicenseData: (state, getters, rootState, rootGetters) => state.LicenseData,
    getterCheckIsLicenseExpired: (state, getters, rootState, rootGetters) => () => {
      try {
        if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'expiry'))
          throw "Invalid license data - Missing expiry data";

        return !rootGetters['HelperManager/getterCompareDateValuesIsBefore'](
          rootGetters['HelperManager/getterCurrentDate'],
          state.LicenseData.expiry);
      } catch (err) {
        console.error("getterCheckLicenseExpired - Error :", err);
        return true;
      }
    },
    getterCheckIsLicenseInGracePeriod: (state, getters, rootState, rootGetters) => () => {
      try {
        if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'expiry'))
          throw "Invalid license data - Missing expiry data";

        // console.debug("getterCheckIsLicenseInGracePeriod - LicenseData.expiry", state.LicenseData.expiry);
        // console.debug("getterCheckIsLicenseInGracePeriod - LICENSE_GRACEPERIOD", LICENSE_GRACEPERIOD);

        const result = rootGetters['HelperManager/getterIsCurrentDateBetweenDeadlineDateAndDayOffset'](
            state.LicenseData.expiry,
            (-LICENSE_GRACEPERIOD)) && !getters.getterCheckIsLicenseExpired();

        //console.debug("getterCheckIsLicenseInGracePeriod - result", result);

        return result;
      } catch (err) {
        console.error("getterCheckIsLicenseInGracePeriod - Error :", err);
        return true;
      }
    },
    getterCheckIsTestingLicense: (state, getters, rootState, rootGetters) => () => {
      try {
        if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'codes'))
          throw "Invalid license data - Missing codes data";

        return state.LicenseData.codes.includes("VGVzdGluZw=="); //Base64 encoded "Testing"
      } catch (err) {
        console.error("getterCheckLicenseExpired - Error :", err);
        return false;
      }
    },
    getterLicensedModulesAsArray: (state, getters, rootState, rootGetters) => {
      // console.debug("getterLicensedModulesAsArray - LicenseData", state.LicenseData);
      return [...state.LicenseData.modules];
    },
    getterLicensedPlan: (state, getters, rootState, rootGetters) => {
      // console.debug("getterLicensedPlan - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'plan'))
        throw "Invalid license data - Missing plan data";

      return state.LicenseData.plan;
    },
    getterLicensedPlanIsStarter: (state, getters, rootState, rootGetters) => {
      try
      {
        return (getters.getterLicensedPlan == LicenseModules.LICENSE_PLAN_STARTER_NAME);
      }
      catch(error)
      {
        console.error("getterLicensedPlanIsStarter - Error :", error);
        return true;
      }
    },
    getterLicenseExpiryDate: (state, getters, rootState, rootGetters) => {
      // console.debug("getterLicenseExpiryDate - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'expiry'))
        throw "Invalid license data - Missing expiry data";

      return state.LicenseData.expiry;
    },
    getterLocationCountLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterLocationCountLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'location'))
        return 1;

      return state.LicenseData.limits.location.count_max;
    },
    getterJusticeAreasLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterJusticeAreasLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'entity'))
        return "DE";

      return state.LicenseData.limits.entity.countries;
    },
    getterUserCountLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterUserCountLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'usrmgmt'))
        return 1;

      return state.LicenseData.limits.usrmgmt.count_max;
    },
    getterGDPROfficerLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterGDPROfficerLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'entity'))
        return true;

      return state.LicenseData.limits.entity.no_gdpr_officer;
    },
    //
    // Tools
    //
    getterRegulationListLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterRegulationListLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'tools'))
        return false;

      return state.LicenseData.limits.tools.no_regulation_list;
    },
    getterSecurityMeasureListLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterSecurityMeasureListLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'tools'))
        return false;

      return state.LicenseData.limits.tools.no_securitymeasure_list;
    },
    getterArtefactListLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterArtefactListLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'tools'))
        return false;

      return state.LicenseData.limits.tools.no_artefact_list;
    },
    getterDataSubjectListLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterDataSubjectListLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'tools'))
        return false;

      return state.LicenseData.limits.tools.no_datasubject_list;
    },
    getterDataCategoryListLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterDataCategoryListLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'tools'))
        return false;

      return state.LicenseData.limits.tools.no_datacategory_list;
    },
    getterAuthorityListLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterAuthorityListLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'tools'))
        return false;

      return state.LicenseData.limits.tools.no_authority_list;
    },
    getterFineCalculatorLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterFineCalculatorLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'tools'))
        return false;

      return state.LicenseData.limits.tools.no_finecalculator;
    },
    getterRepositoryLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterRepositoryLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'tools'))
        return false;

      return state.LicenseData.limits.tools.no_repository;
    },
    getterMailNotificationLimit: (state, getters, rootState, rootGetters) => {
      // console.debug("getterMailNotificationLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'tools'))
        return false;

      return state.LicenseData.limits.tools.no_mailnotification;
    },
    //
    // Features
    //
    getterListMenuVisibleFeature: (state, getters, rootState, rootGetters) => {
      // console.debug("getterUserCountLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'features'))
        return false;

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits.features, 'listmenu_visible'))
        return false;

      return state.LicenseData.limits.features.listmenu_visible;
    },
    getterActivityDocThresholdVisibleFeature: (state, getters, rootState, rootGetters) => {
      // console.debug("getterActivityDocThresholdVisibleFeature - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'features'))
        return false;

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits.features, 'activitydocthreshold_visible'))
        return false;

      return state.LicenseData.limits.features.activitydocthreshold_visible;
    },
    getterArtefactsEditableFeature: (state, getters, rootState, rootGetters) => {
      // console.debug("getterUserCountLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'features'))
        return false;

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits.features, 'artefact_editable'))
        return false;

      return state.LicenseData.limits.features.artefact_editable;
    },
    getterDataSubjectsEditableFeature: (state, getters, rootState, rootGetters) => {
      // console.debug("getterUserCountLimit - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'features'))
        return false;

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits.features, 'datasubject_editable'))
        return false;

      return state.LicenseData.limits.features.datasubject_editable;
    },
    getterDataCategoriesEditableFeature: (state, getters, rootState, rootGetters) => {
      // console.debug("getterDataCategoriesEditableFeature - LicenseData", state.LicenseData);
      if (!Object.prototype.hasOwnProperty.call(state.LicenseData, 'limits'))
        throw "Invalid license data - Missing limits data";

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits, 'features'))
        return false;

      if (!Object.prototype.hasOwnProperty.call(state.LicenseData.limits.features, 'datacategory_editable'))
        return false;

      return state.LicenseData.limits.features.datacategory_editable;
    },
    //
    // Helper
    //
    getterAllowGDPROfficerInformationByLicense: (state, getters, rootState, rootGetters) => {
      try {
        return !getters.getterGDPROfficerLimit;
      } catch (err) {
        console.error("getterAllowGDPROfficerInformationByLicense - Error: ", err);
        return true;
      }
    },
    getterAllowGDPRRepresentantByLicense: (state, getters, rootState, rootGetters) => {
      try {
        return !getters.getterGDPROfficerLimit;
      } catch (err) {
        console.error("getterAllowGDPROfficerInformationByLicense - Error: ", err);
        return true;
      }
    },
    getterAllowGDPRCoordinatorInformationByLicense: (state, getters, rootState, rootGetters) => {
      try {
        // console.debug("getterAllowGDPRCoordinatorInformationByLicense - Limits", rootGetters['LicenseManager/getterLicenseData'].limits);
        return !getters.getterGDPROfficerLimit;
      } catch (err) {
        console.error("getterAllowGDPRCoordinatorInformationByLicense - Error: ", err);
        return true;
      }
    },
    //
    // Modules
    //
    getterCheckLicensedModuleByName: (state, getters, rootState, rootGetters) => (modulename) => {
      try {
        // console.debug("LicenseManager - getterCheckLicensedModuleByName : modulename", modulename);

        return (getters.getterLicensedModulesAsArray.indexOf(modulename) !== -1);
      } catch (err) {
        console.error("getterCheckLicensedModuleByName - Error :", err);
        return false;
      }
    },
    getterCheckLicensedModuleProof: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_PROOF);
    },
    getterCheckLicensedModuleArea: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_AREA);
    },
    getterCheckLicensedModuleContractPartner: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_CONTRACTPARTNER);
    },
    getterCheckLicensedModuleEntity: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_ENTITY);
    },
    getterCheckLicensedModuleLocation: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_LOCATION);
    },
    getterCheckLicensedModuleTask: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_TASK);
    },
    getterCheckLicensedModuleIncident: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_INCIDENT);
    },
    getterCheckLicensedModuleRequest: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_REQUEST);
    },
    getterCheckLicensedModuleAsset: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_ASSET);
    },
    getterCheckLicensedModuleRisk: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_RISK);
    },
    getterCheckLicensedModuleProcess: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_PROCESS);
    },
    getterCheckLicensedModuleProcedure: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_PROCEDURE);
    },
    getterCheckLicensedModulePrint: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_PRINT);
    },
    getterCheckLicensedModuleUsrmgmt: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_USRMGMT);
    },
    getterCheckLicensedModuleContactBook: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_CONTACTBOOK);
    },
    getterCheckLicensedModuleOrgChart: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_ORGCHART);
    },
    getterCheckLicensedModuleDocumentArchive: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_DOCUMENTARCHIVE);
    },
    getterCheckLicensedModuleActivityDoc: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_ACTIVITYDOC);
    },
    getterCheckLicensedModuleDsfa: (state, getters, rootState, rootGetters) => () => {
      return getters.getterCheckLicensedModuleByName(LicenseModules.LICENSE_MODULE_DSFA);
    },
  },
};
