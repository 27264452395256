import { render, staticRenderFns } from "./MessageOkDialog.vue?vue&type=template&id=a14c5a72&scoped=true"
import script from "./MessageOkDialog.vue?vue&type=script&lang=js"
export * from "./MessageOkDialog.vue?vue&type=script&lang=js"
import style0 from "./MessageOkDialog.vue?vue&type=style&index=0&id=a14c5a72&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a14c5a72",
  null
  
)

export default component.exports