export const GDPR_DOCUMENT_NAME = 'gdpr';

//
// GDPR Entity setting
//
export const GDPR_LAWBASISDATA = {
  lawbasis_id: "",
  countrycode: "",
  regioncode: "",
  gdprlawcodes: [],
  agencylistcode: "",
  region_override: false
};

export const GDPR_RECORD_TYPE = "entity";
export const GDPR_RECORD_SUBTYPE = "gdpr";

export const GDPR_SCHEMA_VERSION = 1;

export const GDPR_RECORD = {
  version : GDPR_SCHEMA_VERSION,

  gdprofficer: {
    has_gdprofficer: false,
    ignore_gdprofficer: false,
    officer_id: false,
    valid: true
  },
  representant: {
    has_gdprrepresentant: false,

    officer_id: false,

    valid: true
  },
  agency: {
    agencycode: "",
    valid: false
  },
  lawbasis: {
    ...GDPR_LAWBASISDATA,
    valid: false
  },
  template: {
    subfolder: ""
  },

  valid: false
};