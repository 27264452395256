import backendDataApi from '@/services/backendDataApi.service.js';
import CommonUtils from '@/utils/common.utils.js';

import merge from "lodash.merge";

import {
  DATASUBJECT_RECORD_TYPE,
  DATASUBJECT_RECORD_SUBTYPE,
  DATASUBJECT_RECORD,
  DATASUBJECT_I18N_DISPLAY
} from '@/models/datasubject.couchdb';

export default {
  namespaced: true,
  state: {
    datasubjectlist: []
  },
  mutations: {
    DATASUBJECT_SAVED(state, payload) { },
    DATASUBJECT_REMOVED(state, payload) { },
    DATASUBJECT_CACHE_LOAD(state, payload) {
      state.datasubjectlist = [...payload];
    },
  },
  actions: {
    //
    // private - protected action functions
    //

    //find all datasubjects with limited content by filter in the couchdb and return them
    async readDataSubjectOverviewlistFromBackend({ state }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({
        designdoc: "datasubjects/list",
        keyid: filter,
      });
    },
    //find a datasubject by id in the couchdb and return it
    async readDataSubjectByIdFromBackend({
      state
    }, datasubject_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({ designdoc: 'datasubjects/list', keyid: datasubject_id });
    },
    //create a new datasubject in the couchdb and return id or false
    async createDataSubjectAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      // Make sure an id is assigned on save for a new incident
      const currentdatetime = +new Date();

      // console.debug("createDataSubjectAndStoreInBackend - data", data);

      if(data.standard == false)
        data.datasubject_id = backendDataApi.generateNewCustomKeyId('sbj');

      // Safe guard to ensure a valid id
      if (CommonUtils.isStringEmpty(data.datasubject_id))
        throw "Invalid record id generation";

      data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
      data.created_at = currentdatetime;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_CREATED";
      }

      // transform translations
      var new_record = {
        ...DATASUBJECT_RECORD,
        ...data
      };

      const lang = data.display_translation.lang;
      new_record.i18n.translations[lang] = {};

      new_record.i18n.translations[lang].name = data.display_translation.name;
      new_record.i18n.translations[lang].description = data.display_translation.description;

      delete new_record.name;
      delete new_record.description;

      delete new_record.display_name;
      delete new_record.display_description;

      delete new_record.display_translation;

      delete new_record.standard;
      delete new_record.force_create;
      delete new_record.keyid;

      const record = {
        type: DATASUBJECT_RECORD_TYPE,
        subtype: DATASUBJECT_RECORD_SUBTYPE,
        standard: data.standard,
        creator: rootGetters['SessionManager/getterCurrentUsername'],
        created_at: currentdatetime,
        replicate: false,
        keyid: data.datasubject_id,
        timeline: [new_record]
      };

      // console.debug("createDataSubjectAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a datasubject in the couchdb and return id or false
    async updateDataSubjectAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      try {
        const currentdatetime = +new Date();

        data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
        data.created_at = currentdatetime;

        if (!data.changetype_id || data.changetype_id == 'ID_TIMELINE_CHANGETYPE_CREATED') {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        const record = await dispatch('readDataSubjectByIdFromBackend', data.datasubject_id);

        // console.debug("updateDataSubjectAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        var doc = record[0].value;

        // console.debug("updateDataSubjectAndStoreInBackend - Update Mode DOC", doc);

        // transform translations
        var new_record = {
          ...DATASUBJECT_RECORD,
          ...data
        };

        const lang = data.display_translation.lang;
        new_record.i18n.translations[lang] = {};

        new_record.i18n.translations[lang].name = data.display_translation.name;
        new_record.i18n.translations[lang].description = data.display_translation.description;

        delete new_record.name;
        delete new_record.description;

        delete new_record.display_name;
        delete new_record.display_description;

        delete new_record.display_translation;
        delete new_record.standard;
        delete new_record.keyid;

        doc.timeline.push(new_record);

        return await backendDataApi.updateDocumentInBackend(doc);
      }
      catch (err) {
        console.error("updateDataSubjectAndStoreInBackend - Error: ", err);
        return false;
      }
    },
    async deleteDataSubjectByIdFromBackend({
      state
    }, datasubject_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({ designdoc: 'datasubjects/list', keyid: datasubject_id });
    },

    //
    // Public action functions
    //

    //delete a existing asset from the couchdb
    async actionDeleteDataSubjectById({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, datasubject_id) {
      try {
        const result = await dispatch('deleteDataSubjectByIdFromBackend', datasubject_id);

        if (result !== false) {
          // console.debug('actionDeleteDataSubjectById - After Update');
          commit('DATASUBJECT_REMOVED', datasubject_id);
        }

        return result;
      }
      catch (err) {
        console.error("actionDeleteDataSubjectById - Error : ", err);
        return false;
      }
    },
    //find a datasubject by id in the couchdb and return it
    async actionReceiveDataSubjectById({
      commit,
      dispatch
    }, datasubject_id) {
      return dispatch('readDataSubjectByIdFromBackend', datasubject_id).then(result => {
        if (result.length == 1)
          return { ...result[0].value };
        else
          return {};
      })
        .catch(e => {
          console.error(e);
          return false;
        });
    },
    //find the proof by id in the couchdb and return the last data element
    async actionReceiveCurrentDataSubjectDataById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, datasubject_id) {
      try {
        const result = await dispatch('actionReceiveDataSubjectById', datasubject_id);

        if (result == undefined || !result || CommonUtils.isObjectEmpty(result))
          throw "Error artefact not found";

        // console.debug("actionReceiveCurrentDataSubjectDataById result", result);

        const currentObj = {
          standard: result.standard,
          keyid: result.keyid,
          ...getters.getterEmptyDataSubjectRecord,
          ...result.timeline[result.timeline.length - 1]
        };

        return CommonUtils.translateI18nDataObjectByLc(currentObj,rootGetters["TranslationManager/getterLanguageAlpha2Code"]);
      }
      catch (err) {
        console.error("actionReceiveCurrentDataSubjectDataById - Error : ", err);
        return false;
      }
    },
    async actionReceiveActiveDataSubjectsAsArray({
      state,
      getters,
      rootGetters,
      commit,
      dispatch }) {
      try {
        const result = await backendDataApi.readViewFromBackendByOption("datasubjects/activeInActivityDocs", {reduce: true, group_level: 1, include_docs: false}) ;

        // console.debug("actionReceiveActiveDataSubjectsAsArray - result", result)

        if(result == false)
          throw "Error reading datasubject list";

        // console.debug("actionReceiveActiveDataSubjectsAsArray - keys", result.map((o) => o.key));

        return result.map((o) => o.key);
      }
      catch (err) {
        console.error("actionReceiveCurrentDataSubjectDataById - Error : ", err);
        return [];
      }
    },
    // read activity array cache from the couchdb
    async actionRefreshDataSubjectsCache({ getters, rootGetters, commit, dispatch }) {
      try {
        const result = await dispatch("readDataSubjectOverviewlistFromBackend");

        // console.debug("actionRefreshDataSubjectsCache - result", result);

        if (result === false)
          throw "Cannot read datasubject overviewlist from backend";

        var datasubjectlist = [];

        const lang = rootGetters["TranslationManager/getterLanguageAlpha2Code"];

        if (result.length) {
          result.forEach((item) => {
            const datasubjectObj = {
              keyid: item.value.keyid,
              ...item.value.timeline[item.value.timeline.length - 1],
              standard: item.value.standard
            };

            // console.debug("actionRefreshDataSubjectsCache- datasubjectObj", datasubjectObj);
             datasubjectlist.push(datasubjectObj);
          });
        }

        // console.debug("actionRefreshDataSubjectsCache - datasubjectlist", datasubjectlist);

        commit("DATASUBJECT_CACHE_LOAD", datasubjectlist);

        return true;
      } catch (err) {
        console.error("actionRefreshDataSubjectsCache - Error : ", err);
        return false;
      }
    },
    //save or update a new datasubject into the couchdb
    async actionSaveDataSubject({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, data) {
      try {
        // console.debug("actionSaveDataSubject - data", data);

        let result = false;

        if (CommonUtils.isStringEmpty(data.datasubject_id) || Object.prototype.hasOwnProperty.call(data, "force_create")) {
          delete data.force_create;
          // console.debug("actionSaveDataSubject - Create Mode", data);
          result = await dispatch('createDataSubjectAndStoreInBackend', data);
        }
        else {
          delete data.standard;
          // console.debug("actionSaveDataSubject - Update Mode", data);
          result = await dispatch('updateDataSubjectAndStoreInBackend', data);
        }

        // console.debug("actionSaveDataSubject - result", result);

        if (result == false)
          throw "Error saving DataSubject";

        commit("DATASUBJECT_SAVED", data);

        return result;
      }
      catch (err) {
        console.error("actionSaveDataSubject Error: ", err);
        return false;
      }
    },
  },
  getters: {
    getterEmptyDataSubjectRecord: (state) => {
      return { ...DATASUBJECT_RECORD, ...DATASUBJECT_I18N_DISPLAY };
    },
    getterDataSubjectsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDataSubjectsAsArrayByLc("");
    },
    getterDataSubjectById: (state, getters, rootState, rootGetters) => (id) => {
      try {
        var dataObj = {};

        if(CommonUtils.isStringEmpty(id))
          throw "Empty id not allowed";

        const tempary = getters.getterDataSubjectsAsArray.filter(item => item.datasubject_id == id);

        if(CommonUtils.isArrayEmpty(tempary) == false)
          dataObj = tempary[0];

        return dataObj;
      } catch (error) {
        console.error("getterDataSubjectById - Error :", error);
        return false;
      }
    },
    getterDataSubjectsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      try {
        var datasubjectlist = [];

        const lang = CommonUtils.isStringEmpty(lc) ? rootGetters["TranslationManager/getterLanguageAlpha2Code"] : lc;

        state.datasubjectlist.forEach((item) => {
          const datasubjectObj = merge({}, getters.getterEmptyDataSubjectRecord, item);

          // console.debug("getterDataSubjectsAsArrayByLc - datasubjectObj", datasubjectObj);

          const result = CommonUtils.translateI18nDataObjectByLc(datasubjectObj, lang, "datasubject_id");

          // console.debug("getterDataSubjectsAsArrayByLc - result", result);

          if (result == false)
            throw "Error in  getterDataSubjectsAsArrayByLc translation"

           datasubjectlist.push(result);
        });

        return datasubjectlist;
      } catch (error) {
        console.error("getterDataSubjectsAsArrayByLc - Error :", error);
        return [];
      }
    },
    getterCompactDataSubjectsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDataSubjectsAsArray.map(o => ({
        id: o.keyid,
        name: o.display_translation.name,
        description: o.display_translation.description
      }));
    },
  },
};