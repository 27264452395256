import {STAGEHISTORY_DATA, ADDRESS_DATA, CONTACT_DATA} from "@/config/config.couchdb";

//
// ActivityDoc
//
export const INITACTIVITYDOC_DOCUMENT_NAME = 'entity_area_activitydoc_map';
export const ACTIVITYDOC_RECORD_TYPE = "activitydoc";

export const ACTIVITYDOC_SCHEMA_VERSION = 1;

export const ACTIVITYDOC_STAGEHISTORY_RECORD = {
  stage: 1,
  revision: 0,
  changetype_id: false,
  ...STAGEHISTORY_DATA
};

export const ACTIVITYDOC_RECORD = {
  activitydoc_id: false,

  process_id: false,
  area_id: false,

  template_id: false,

  assigned_to: "",
  changetype_id: "",

  revision: 0,

  stage: 1,

  version: ACTIVITYDOC_SCHEMA_VERSION,

  active: true,
  approved: false,

  core: {
    role: '',
    name: '',
    purpose: '',
    scope: '',
    activation_date: false,
    decommission_date: false,
    notes: "",

    valid: false
  },

  risk: {
    profiling_used: false,
    profiling_description: '',
    impact_assessment_done: false,
    impact_assessment_notes: '',

    non_eu_transfer_used: false,
    transfermedium: '',
    non_eu_destination: '',
    grant_security_description: '',

    valid: false
  },

  responsible: {
    name: '',
    role: '',

    location_id: false,

    ...ADDRESS_DATA,
    ...CONTACT_DATA,

    valid: false
  },

  datainput: {
    sources: [],
    valid: false
  },

  datatransfer: {
    jointcontrollers: [],
    principaldatacontractor: '',
    dataprocessors: [],
    internal_areas: [],
    internal_areas_other: '',
    external_parties: [],
    external_parties_other: '',
    valid: false
  },

  regulation: {
    gdpr_law: [],
    gdpr_law_other: '',
    special_law: [],
    special_law_other: '',
    retention: [],
    retention_other: '',
    valid: false
  },

  threshold: {
    active: false,
    criteria: [],
    conclusion: "",
    valid: false
  },

  attachments: [],
  stagehistory: [],

  valid: false,

  created_at: false,
  created_by: "",
};

export const ACTIVITYDOC_DATAINPUT_RECORD = {
  id: false,
  individual_category: '',
  individual_category_other: '',
  data_categories: [],
  data_categories_other: [],
  artefacts: [],
  artefacts_other: [],
  origin: '',
  valid: false
};
