import axios from 'axios';
import path from 'path';

import { ResponseWrapper, ErrorWrapper } from './response.util';

import {AuthService} from '@/services/auth.service';

import { LICENSEAPI_HOST, LICENSEAPI_PATH, LICENSEAPI_APPID } from '@/config/config.constants.js';


export class LicenseService  {

  static async getLicense(tenant) {
      try {
        const response = await axios.post(
          this.getBackendUrlString("/license"),
          { tenant, appid: LICENSEAPI_APPID },
          { 
            headers: {
              'Authorization': `${AuthService.getAccessToken()}`
            }
          }
        );
  
        // console.debug("getLicense - response", response);
  
        return new ResponseWrapper(response, response.data.data);
      } catch (error) {
        throw new ErrorWrapper(error);
      }
  }

  /**
   ******************************
   * @METHODS
   ******************************
   */
   static getBackendUrlString(urlpath) {
    return new URL(path.join(LICENSEAPI_PATH, urlpath), LICENSEAPI_HOST).toString();
  }  
}
