export const OFFICERTYPE_I18N_KEY = "officer_types.";

export const OFFICERTYPE_LIST = {
	"ID_OFFICERTYPE_GDPROFFICER": {
		"name": "OFFICERTYPE_GDPROFFICER_LABEL",
		"description": "OFFICERTYPE_GDPROFFICER_DESCRIPTION"
	},
	"ID_OFFICERTYPE_GDPREUREPRESENTATIVE": {
		"name": "OFFICERTYPE_GDPREUREPRESENTATIVE_LABEL",
		"description": "OFFICERTYPE_GDPREUREPRESENTATIVE_DESCRIPTION"
	},
	"ID_OFFICERTYPE_GDPRCOORDINATOR": {
		"name": "OFFICERTYPE_GDPRCOORDINATOR_LABEL",
		"description": "OFFICERTYPE_GDPRCOORDINATOR_DESCRIPTION"
	},
	"ID_OFFICERTYPE_ITSECURITY": {
		"name": "OFFICERTYPE_ITSECURITY_LABEL",
		"description": "OFFICERTYPE_ITSECURITY_DESCRIPTION"
	},
	"ID_OFFICERTYPE_DANGEROUSGOODS": {
		"name": "OFFICERTYPE_DANGEROUSGOODS_LABEL",
		"description": "OFFICERTYPE_DANGEROUSGOODS_DESCRIPTION"
	},
	"ID_OFFICERTYPE_HUMANRIGHTS": {
		"name": "OFFICERTYPE_HUMANRIGHTS_LABEL",
		"description": "OFFICERTYPE_HUMANRIGHTS_DESCRIPTION"
	},
	"ID_OFFICERTYPE_SAFETYANDHEALTH": {
		"name": "OFFICERTYPE_SAFETYANDHEALTH_LABEL",
		"description": "OFFICERTYPE_SAFETYANDHEALTH_DESCRIPTION"
	},
	"ID_OFFICERTYPE_QUALITY": {
		"name": "OFFICERTYPE_QUALITY_LABEL",
		"description": "OFFICERTYPE_QUALITY_DESCRIPTION"
	},
	"ID_OFFICERTYPE_EQUALITY": {
		"name": "OFFICERTYPE_EQUALITY_LABEL",
		"description": "OFFICERTYPE_EQUALITY_DESCRIPTION"
	},	
	"ID_OFFICERTYPE_SECURITY": {
		"name": "OFFICERTYPE_SECURITY_LABEL",
		"description": "OFFICERTYPE_SECURITY_DESCRIPTION"
	},
	"ID_OFFICERTYPE_HYGIENE": {
		"name": "OFFICERTYPE_HYGIENE_LABEL",
		"description": "OFFICERTYPE_HYGIENE_DESCRIPTION"
	},
	"ID_OFFICERTYPE_RADIATION": {
		"name": "OFFICERTYPE_RADIATION_LABEL",
		"description": "OFFICERTYPE_RADIATION_DESCRIPTION"
	},
	"ID_OFFICERTYPE_FIRE": {
		"name": "OFFICERTYPE_FIRE_LABEL",
		"description": "OFFICERTYPE_FIRE_DESCRIPTION"
	},
	"ID_OFFICERTYPE_MEDICAL": {
		"name": "OFFICERTYPE_MEDICAL_LABEL",
		"description": "OFFICERTYPE_MEDICAL_DESCRIPTION"
	},	
	"ID_OFFICERTYPE_REACH": {
		"name": "OFFICERTYPE_REACH_LABEL",
		"description": "OFFICERTYPE_REACH_DESCRIPTION"
	},				
};