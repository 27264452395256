// Standard attachment modules for license and access management
export const ATTACHMENT_SCOPE_ENTITY = "entity";
export const ATTACHMENT_SCOPE_LOCATION = "location";
export const ATTACHMENT_SCOPE_AREA = "area";
export const ATTACHMENT_SCOPE_INCIDENT = "incident";
export const ATTACHMENT_SCOPE_REQUEST = "request";
export const ATTACHMENT_SCOPE_PROOF = "proof";
export const ATTACHMENT_SCOPE_CONTRACTPARTNER = "contractpartner";
export const ATTACHMENT_SCOPE_PROCESS = "process";
export const ATTACHMENT_SCOPE_PROCEDURE = "procedure";
export const ATTACHMENT_SCOPE_ACTIVITYDOC = "activitydoc";
export const ATTACHMENT_SCOPE_ASSET = "asset";
export const ATTACHMENT_SCOPE_RISK = "risk";
export const ATTACHMENT_SCOPE_OFFICER = "officer";
export const ATTACHMENT_SCOPE_USERTASK = "usertask";

export const ATTACHMENT_SCOPE_ARRAY = [
  ATTACHMENT_SCOPE_ENTITY,
  ATTACHMENT_SCOPE_LOCATION,
  ATTACHMENT_SCOPE_AREA,
  ATTACHMENT_SCOPE_INCIDENT,
  ATTACHMENT_SCOPE_REQUEST,
  ATTACHMENT_SCOPE_PROOF,
  ATTACHMENT_SCOPE_CONTRACTPARTNER,
  ATTACHMENT_SCOPE_PROCESS,
  ATTACHMENT_SCOPE_PROCEDURE, 
  ATTACHMENT_SCOPE_ACTIVITYDOC,
  ATTACHMENT_SCOPE_ASSET,
  ATTACHMENT_SCOPE_RISK,
  ATTACHMENT_SCOPE_USERTASK,
  ATTACHMENT_SCOPE_OFFICER  
];
