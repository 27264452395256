export const REQUESTORIGIN_I18N_KEY = "request_origins.";

export const REQUESTORIGIN_LIST = {
  "ID_REQUESTORIGIN_AGENCY": {
    "name": "REQUESTORIGIN_AGENCY_LABEL",
    "description": "REQUESTORIGIN_AGENCY_DESCRIPTION",
    "contact_type": "entity"
  },
  "ID_REQUESTORIGIN_GDPROFFICER": {
    "name": "REQUESTORIGIN_GDPROFFICER_LABEL",
    "description": "REQUESTORIGIN_GDPROFFICER_DESCRIPTION",
    "contact_type": "individual"
  },  
  "ID_REQUESTORIGIN_INDIVIDUAL": {
    "name": "REQUESTORIGIN_INDIVIDUAL_LABEL",
    "description": "REQUESTORIGIN_INDIVIDUAL_DESCRIPTION",
    "contact_type": "individual"
  },
  "ID_REQUESTORIGIN_EMPLOYEE": {
    "name": "REQUESTORIGIN_EMPLOYEE_LABEL",
    "description": "REQUESTORIGIN_EMPLOYEE_DESCRIPTION",
    "contact_type": "individual"
  },
  "ID_REQUESTORIGIN_CONTRACTPARTNER": {
    "name": "REQUESTORIGIN_CONTRACTPARTNER_LABEL",
    "description": "REQUESTORIGIN_CONTRACTPARTNER_DESCRIPTION",
    "contact_type": "entity"
  },
  "ID_REQUESTORIGIN_EXTERNALPARTY": {
    "name": "REQUESTORIGIN_EXTERNALPARTY_LABEL",
    "description": "REQUESTORIGIN_EXTERNALPARTY_DESCRIPTION",
    "contact_type": "entity"
  },
  "ID_REQUESTORIGIN_JOINTCONTROLLER": {
    "name": "REQUESTORIGIN_JOINTCONTROLLER_LABEL",
    "description": "REQUESTORIGIN_JOINTCONTROLLER_DESCRIPTION",
    "contact_type": "entity"
  },
  "ID_REQUESTORIGIN_DATAPROCESSOR": {
    "name": "REQUESTORIGIN_DATAPROCESSOR_LABEL",
    "description": "REQUESTORIGIN_DATAPROCESSOR_DESCRIPTION",
    "contact_type": "entity"
  },  
  "ID_REQUESTORIGIN_PRINCIPALDATACONTRACTOR": {
    "name": "REQUESTORIGIN_PRINCIPALDATACONTRACTOR_LABEL",
    "description": "REQUESTORIGIN_PRINCIPALDATACONTRACTOR_DESCRIPTION",
    "contact_type": "entity"    
  }   
};

export const REQUESTCATEGORY_I18N_KEY = "request_categories.";

export const REQUESTCATEGORY_LIST = {
  "ID_REQUESTCATEGORY_RIGHT_TO_BE_INFORMED": {
    "name": "REQUESTCATEGORY_RIGHT_TO_BE_INFORMED_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_BE_INFORMED_DESCRIPTION"
  },
  "ID_REQUESTCATEGORY_RIGHT_TO_RECTIFICATION": {
    "name": "REQUESTCATEGORY_RIGHT_TO_RECTIFICATION_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_RECTIFICATION_DESCRIPTION"
  },  
  "ID_REQUESTCATEGORY_RIGHT_TO_ERASURE": {
    "name": "REQUESTCATEGORY_RIGHT_TO_ERASURE_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_ERASURE_DESCRIPTION"
  },
  "ID_REQUESTCATEGORY_RIGHT_TO_RESTRICT": {
    "name": "REQUESTCATEGORY_RIGHT_TO_RESTRICT_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_RESTRICT_DESCRIPTION"
  },
  "ID_REQUESTCATEGORY_RIGHT_TO_DATA_PORTABILITY": {
    "name": "REQUESTCATEGORY_RIGHT_TO_DATA_PORTABILITY_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_DATA_PORTABILITY_DESCRIPTION"
  },
  "ID_REQUESTCATEGORY_RIGHT_TO_OBJECT": {
    "name": "REQUESTCATEGORY_RIGHT_TO_OBJECT_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_OBJECT_DESCRIPTION"
  }, 
  "ID_REQUESTCATEGORY_RIGHT_TO_PROFILING": {
    "name": "REQUESTCATEGORY_RIGHT_TO_PROFILING_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_PROFILING_DESCRIPTION"
  }, 
  "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_CONSENT": {
    "name": "REQUESTCATEGORY_RIGHT_TO_REVOKE_CONSENT_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_REVOKE_CONSENT_DESCRIPTION"
  }, 
  "ID_REQUESTCATEGORY_AUDIT": {
    "name": "REQUESTCATEGORY_AUDIT_LABEL",
    "description": "REQUESTCATEGORY_AUDIT_DESCRIPTION"
  }, 
  "ID_REQUESTCATEGORY_COMPLAINT": {
    "name": "REQUESTCATEGORY_COMPLAINT_LABEL",
    "description": "REQUESTCATEGORY_COMPLAINT_DESCRIPTION"
  }, 
  "ID_REQUESTCATEGORY_INFORMATION_REQUEST": {
    "name": "REQUESTCATEGORY_INFORMATION_REQUEST_LABEL",
    "description": "REQUESTCATEGORY_INFORMATION_REQUEST_DESCRIPTION"
  }, 
  "ID_REQUESTCATEGORY_RIGHT_TO_REMOVE_RESTRICT_LINKS": {
    "name": "REQUESTCATEGORY_RIGHT_TO_REMOVE_RESTRICT_LINKS_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_REMOVE_RESTRICT_LINKS_DESCRIPTION"
  }, 
  "ID_REQUESTCATEGORY_RIGHT_TO_REMOVE_PERSONALDATA": {
    "name": "REQUESTCATEGORY_RIGHT_TO_REMOVE_PERSONALDATA_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_REMOVE_PERSONALDATA_DESCRIPTION"
  }, 
  "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_DATA": {
    "name": "REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_DATA_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_DATA_DESCRIPTION"
  }, 
  "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_ADS_DATA": {
    "name": "REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_ADS_DATA_LABEL",
    "description": "REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_ADS_DATA_DESCRIPTION"
  }, 
  "ID_REQUESTCATEGORY_OTHER": {
    "name": "REQUESTCATEGORY_OTHER_LABEL",
    "description": "REQUESTCATEGORY_OTHER_DESCRIPTION"
  } 
};

export const REQUEST_ORIGIN_CATEGORY_MAP = {
	"ID_REQUESTORIGIN_AGENCY": [
    "ID_REQUESTCATEGORY_AUDIT",
    "ID_REQUESTCATEGORY_COMPLAINT",
    "ID_REQUESTCATEGORY_INFORMATION_REQUEST",
    "ID_REQUESTCATEGORY_OTHER"
  ], 
  "ID_REQUESTORIGIN_GDPROFFICER": [
    "ID_REQUESTCATEGORY_AUDIT",
    "ID_REQUESTCATEGORY_COMPLAINT",
    "ID_REQUESTCATEGORY_INFORMATION_REQUEST",
    "ID_REQUESTCATEGORY_OTHER"    
  ],  
  "ID_REQUESTORIGIN_INDIVIDUAL": [
    "ID_REQUESTCATEGORY_RIGHT_TO_BE_INFORMED",
    "ID_REQUESTCATEGORY_RIGHT_TO_RECTIFICATION",
    "ID_REQUESTCATEGORY_RIGHT_TO_ERASURE",
    "ID_REQUESTCATEGORY_RIGHT_TO_RESTRICT",
    "ID_REQUESTCATEGORY_RIGHT_TO_DATA_PORTABILITY",
    "ID_REQUESTCATEGORY_RIGHT_TO_OBJECT",
    "ID_REQUESTCATEGORY_RIGHT_TO_PROFILING",
    "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_CONSENT",
    "ID_REQUESTCATEGORY_RIGHT_TO_REMOVE_RESTRICT_LINKS", 
    "ID_REQUESTCATEGORY_RIGHT_TO_REMOVE_PERSONALDATA", 
    "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_DATA", 
    "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_ADS_DATA",  
    "ID_REQUESTCATEGORY_OTHER"    
  ],
  "ID_REQUESTORIGIN_EMPLOYEE": [
    "ID_REQUESTCATEGORY_RIGHT_TO_BE_INFORMED",
    "ID_REQUESTCATEGORY_RIGHT_TO_RECTIFICATION",
    "ID_REQUESTCATEGORY_RIGHT_TO_ERASURE",
    "ID_REQUESTCATEGORY_RIGHT_TO_RESTRICT",
    "ID_REQUESTCATEGORY_RIGHT_TO_DATA_PORTABILITY",
    "ID_REQUESTCATEGORY_RIGHT_TO_OBJECT",
    "ID_REQUESTCATEGORY_RIGHT_TO_PROFILING",
    "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_CONSENT",
    "ID_REQUESTCATEGORY_OTHER"    
  ],
  "ID_REQUESTORIGIN_CONTRACTPARTNER": [
    "ID_REQUESTCATEGORY_RIGHT_TO_BE_INFORMED",
    "ID_REQUESTCATEGORY_RIGHT_TO_RECTIFICATION",
    "ID_REQUESTCATEGORY_RIGHT_TO_ERASURE",
    "ID_REQUESTCATEGORY_RIGHT_TO_RESTRICT",
    "ID_REQUESTCATEGORY_RIGHT_TO_DATA_PORTABILITY",
    "ID_REQUESTCATEGORY_RIGHT_TO_OBJECT",
    "ID_REQUESTCATEGORY_RIGHT_TO_PROFILING",
    "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_CONSENT",
    "ID_REQUESTCATEGORY_RIGHT_TO_REMOVE_RESTRICT_LINKS", 
    "ID_REQUESTCATEGORY_RIGHT_TO_REMOVE_PERSONALDATA", 
    "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_DATA", 
    "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_ADS_DATA",      
    "ID_REQUESTCATEGORY_AUDIT",
    "ID_REQUESTCATEGORY_COMPLAINT",
    "ID_REQUESTCATEGORY_INFORMATION_REQUEST",
    "ID_REQUESTCATEGORY_OTHER",    
  ], 
  "ID_REQUESTORIGIN_JOINTCONTROLLER": [
    "ID_REQUESTCATEGORY_AUDIT",
    "ID_REQUESTCATEGORY_COMPLAINT",
    "ID_REQUESTCATEGORY_INFORMATION_REQUEST",
    "ID_REQUESTCATEGORY_OTHER",    
  ],
  "ID_REQUESTORIGIN_DATAPROCESSOR": [
    "ID_REQUESTCATEGORY_AUDIT",
    "ID_REQUESTCATEGORY_COMPLAINT",
    "ID_REQUESTCATEGORY_INFORMATION_REQUEST",
    "ID_REQUESTCATEGORY_OTHER",    
  ],
  "ID_REQUESTORIGIN_EXTERNALPARTY": [
    "ID_REQUESTCATEGORY_RIGHT_TO_BE_INFORMED",
    "ID_REQUESTCATEGORY_RIGHT_TO_RECTIFICATION",
    "ID_REQUESTCATEGORY_RIGHT_TO_ERASURE",
    "ID_REQUESTCATEGORY_RIGHT_TO_RESTRICT",
    "ID_REQUESTCATEGORY_RIGHT_TO_DATA_PORTABILITY",
    "ID_REQUESTCATEGORY_RIGHT_TO_OBJECT",
    "ID_REQUESTCATEGORY_RIGHT_TO_PROFILING",
    "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_CONSENT",
    "ID_REQUESTCATEGORY_RIGHT_TO_REMOVE_RESTRICT_LINKS", 
    "ID_REQUESTCATEGORY_RIGHT_TO_REMOVE_PERSONALDATA", 
    "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_DATA", 
    "ID_REQUESTCATEGORY_RIGHT_TO_REVOKE_REMOVE_ADS_DATA",      
    "ID_REQUESTCATEGORY_AUDIT",
    "ID_REQUESTCATEGORY_COMPLAINT",
    "ID_REQUESTCATEGORY_INFORMATION_REQUEST",
    "ID_REQUESTCATEGORY_OTHER",    
  ],
  "ID_REQUESTORIGIN_PRINCIPALDATACONTRACTOR": [
    "ID_REQUESTCATEGORY_AUDIT",
    "ID_REQUESTCATEGORY_COMPLAINT",
    "ID_REQUESTCATEGORY_INFORMATION_REQUEST",
    "ID_REQUESTCATEGORY_OTHER",    
  ],
  
};  
