<template>
  <v-card :class="[classListMain]" :height="height" class="card-box--layout">

    <v-card-title
      class="card-title bk--ctm"
      :class="{ 'no-bottom-line': hideBottomLine, 'header-no-background--color': headerNoBackground }"
      ref="cardTitle"
       v-if="showHeader">

      <slot name="header"></slot>

      <v-spacer></v-spacer>

      <slot name="headerbutton"></slot>
    </v-card-title>

    <div class="empty--box-inner"></div>

    <v-card-text :class="[classListBody]" class="card-body">
      <slot name="content"></slot>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    classListMain: {
      required: false,
      type: Object,
      default: () => ({})
    },
    classListBody: {
      required: false,
      type: Object,
      default: () => ({'fill-height' : true})
    },
    height: {
      required: false,
      type: String,
      default: "100%"
    },
    hideBottomLine: {
      type: Boolean,
      required: false,
      default: false
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    headerNoBackground: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>

.card-body {
  overflow-y: hidden;
  overflow-x: hidden;
}

.card-box--layout {
 overflow: auto;
 position: relative;
}

.card-box--layout .card-title {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}

.card-box--layout .card-body {
  position: relative;
  top: 0;
  padding: 0 .25rem;
  max-height: calc(100% - 56px);
}

.card-box--layout.empty--box-top .card-body {
  height: calc(100% - 156px);
}

.card-box--layout.empty--box-top .empty--box-inner {
   height: 94px;
}

.header--content-body {
  display: flex;
}

.no-bottom-line {
  border-bottom: none !important;
}

.header-no-background--color {
  background-color: transparent !important;
}

</style>
