//
// Processes
//

import { CONTACT_DATA, ADDRESS_DATA } from "../config/config.couchdb";

export const ENTITYPROCESS_DOCUMENT_NAME = 'entity_processes';

export const PROCESS_RECORD_TYPE = "process";
export const PROCESS_RECORD_SUBTYPE = "entity";

export const PROCESS_RECORD = {

  active: true,

  area_id: false,
  proc_id: false,

  process_id: false,

  is_custom: false,

  changetype_id: '',

  core: {
    name: '',
    description: '',
    function: '',
    notes: '',

    activation_date: "",
    decommission_date: "",

    decommission_notes: "",

    i18n_overwrites: {},

    valid: false
  },

  responsible: {
    name: '',

    role_id: "ID_ENTITYCONTACT_ROLE_PROCESSRESPONSIBLE",

    location_id: false,

    ...ADDRESS_DATA,
    ...CONTACT_DATA,

    valid: false
  },

  valid: false,

  created_at: false,
  created_by: "",
};
