  export const PROOFTARGET_I18N_KEY = "proof_targets.";

  export const PROOFTARGET_LIST = {
      "ID_PROOFTARGET_ENTITYPROCESSACTIVITY": {
        "name": "PROOFTARGET_ENTITYPROCESSACTIVITY_LABEL",
        "description": "PROOFTARGET_ENTITYPROCESSACTIVITY_DESCRIPTION",
        "scope": "activitydoc",
        "type": ""   
      },

      "ID_PROOFTARGET_ENTITYSECURITYPROCEDURE": {
        "name": "PROOFTARGET_ENTITYSECURITYPROCEDURE_LABEL",
        "description": "PROOFTARGET_ENTITYSECURITYPROCEDURE_DESCRIPTION",
        "scope": "procedure",
        "type": ""   
      },       

      "ID_PROOFTARGET_ENTITYGENERICPROOF": {
        "name": "PROOFTARGET_ENTITYGENERICPROOF_LABEL",
        "description": "PROOFTARGET_ENTITYGENERICPROOF_DESCRIPTION",
        "scope": "none",
        "type": ""       
      },

      "ID_PROOFTARGET_ENTITYGENERICDOCUMENT": {
        "name": "PROOFTARGET_ENTITYGENERICDOCUMENT_LABEL",
        "description": "PROOFTARGET_ENTITYGENERICDOCUMENT_DESCRIPTION",
        "scope": "attachment",
        "type": ""   
      }   
  };

  export const parked = {
    "ID_PROOFTARGET_ENTITYCONTRACTPARTNER": {
      "name": "PROOFTARGET_ENTITYCONTRACTPARTNER_LABEL",
      "description": "PROOFTARGET_ENTITYCONTRACTPARTNER_DESCRIPTION",
      "scope": "contractpartner",
      "type": ""   
    }      
  };