import backendDataApi from '@/services/backendDataApi.service.js';

import { downloadZip } from 'client-zip';

import merge from 'lodash.merge';

import {
  ATTACHMENT_RECORD,
  ATTACHMENT_RECORD_TYPE,
  ATTACHMENT_METADATA_RECORD
} from '@/config/config.couchdb';

import {ATTACHMENT_SCOPE_ARRAY} from '@/config/config.attachment';

const namespaced = true;
//
///////////////////////////////////////////////////////////////////////////////
//
const state = {
};
//
///////////////////////////////////////////////////////////////////////////////
//
const mutations = {
};
//
///////////////////////////////////////////////////////////////////////////////
//
const actions = {
  //------------------------------------------------------------------------------------
  // Private functions
  async deleteAttachmentByAttachmentIdFromBackend({
    state
  }, attachment_id){
    return await backendDataApi.deleteDocumentByKeyIdFromBackend({designdoc: 'attachments/list', keyid: attachment_id});
  },
  //find all attachments with limited content in the couchdb and return them
  async readAttachmentsFromBackend({
    state
  }, filter) {
    return await backendDataApi.readDocumentlistFromBackend({designdoc: 'attachments/attachment', keyid: filter, include_docs: true});
  },
  //find an attachments by id in the couchdb and return it
  async readAttachmentByIdFromBackend({
    state
  }, attachment_id){
    return await backendDataApi.readDocumentByKeyIdFromBackend({designdoc: 'attachments/list', keyid: attachment_id});
  },
  //find all attachments with limited content in the couchdb and return them
  async readAttachmentOverviewlistFromBackend({
    state
  }, filter) {
    return await backendDataApi.readOverviewlistFromBackend({designdoc: 'attachments/overviewlist', keyid: filter});
  },
  //find an attachments with active prooflink the couchdb and return it
  async readActiveProofLinkedAttachments({
    state
  }, filter){
    return await backendDataApi.readDocumentlistFromBackend({designdoc: 'attachments/prooflinklist', keyid: filter, include_docs: false});
  },
  //find an attachments by reference the couchdb and return it
  async readAttachmentsByReferenceFromBackend({
    state
  }, payload){
    return await backendDataApi.readDocumentlistFromBackend({designdoc: 'attachments/referencelist', keyid: [payload.scope, payload.refid], include_docs: payload.include_docs});
  },
  // get the attachment blob
  async actionReadAttachmentBlob({
    state
  }, doc) {
    try
    {
      // console.debug("actionReadAttachmentBlobAsURL", doc);
      // getting the first attachment in the list
      return await backendDataApi.readAttachmentBlobByDocFromBackend(doc);
    }
    catch(error)
    {
      console.error("actionReadAttachmentBlob", error);
      return false;
    }
  },
  // create a new attachment
  async createAttachmentAndStoreInBackend({
    commit,
    rootGetters,
    dispatch
  }, data) {
    // Make sure an area_id is assigned on save for a new incident
    const currentdatetime = +new Date();

    // console.debug("createAttachmentAndStoreInBackend - data", data);
    if(!data.attachment_id || data.attachment_id === undefined)
      data.attachment_id = backendDataApi.generateNewCustomKeyId('doc');

    // Safe guard to ensure a valid id
    if(!data.attachment_id || 0 === data.attachment_id.length)
      return false;

    data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
    data.created_at = currentdatetime;

    if(!data.changetype_id) {
      data.changetype_id = "ID_TIMELINE_CHANGETYPE_CREATED";
    }

    const record = {
      username: rootGetters['SessionManager/getterCurrentUsername'],

      type: ATTACHMENT_RECORD_TYPE,
      keyid: data.attachment_id,

      ...data
    };

    // console.debug("createAttachmentAndStoreInBackend - Create Mode", record);

    return await backendDataApi.createDocumentInBackend(record);
  },
  //update an attachment in the couchdb and return attachment_id or false
  async updateAttachmentAndStoreInBackend({
    rootGetters,
    dispatch,
    commit
  }, data) {
    try
    {
      const currentdatetime = +new Date();

      data.updated_by = rootGetters['SessionManager/getterCurrentUsername'];
      data.updated_at = currentdatetime;

      if(!data.changetype_id || data.changetype_id == 'ID_TIMELINE_CHANGETYPE_CREATED') {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
      }

      const record = await dispatch('readAttachmentByIdFromBackend', data.attachment_id);

      // console.debug("updateAttachmentAndStoreInBackend- Update Mode Result", record);

      if (record.length !== 1) {
        return false;
      }

      const newattachment = {
        ...record[0].value,
        ...data
      };

      //console.debug("updateAttachmentAndStoreInBackend - Update Mode Doc", newattachment);

      return await backendDataApi.updateDocumentInBackend(newattachment);
    }
    catch(err) {
      console.error("updateAttachmentAndStoreInBackend - Error: ", err);
      return false;
    }
  },

  //------------------------------------------------------------------------------------
  //------------------------------------------------------------------------------------

  // Private new functions
  async actionGetAttachments({
    commit,
    dispatch
  }) {
    // getting all attachments including the docs
    try
    {
      const result = await dispatch("readAttachmentsFromBackend");

      return result.map(x=> merge({}, ATTACHMENT_METADATA_RECORD, x.value));
    }
    catch(err)
    {
      console.error("actionGetAttachments", err);
      return false;
    }
  },
  // Private new functions
  async actionGetActiveProofLinkedAttachments({
    commit,
    dispatch
  }) {
    // getting all attachments excluding the docs
    try
    {
      return await dispatch("readActiveProofLinkedAttachments");
    }
    catch(err)
    {
      console.error("actionGetActiveProofLinkedAttachments", err);
      return false;
    }
  },
  //get the attachment
  async actionReadAttachmentBlobAsURL({
    commit,
    rootGetters,
    dispatch
  }, doc) {
    try
    {
      // console.debug("actionReadAttachmentBlobAsURL", doc);
      // getting the first attachment in the list
      const blob = await dispatch('actionReadAttachmentBlob', doc);

      // console.debug("attachmenturl", window.URL.createObjectURL(blob));
      return window.URL.createObjectURL(blob);
    }
    catch(error)
    {
      console.error("actionReadAttachmentBlobAsURL", error);
      return false;
    }
  },

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  //
  // Public new API functions
  //

  //delete a existing attachment from the couchdb
  async actionDeleteAttachmentById({
    state,
    getters,
    rootGetters,
    dispatch,
    commit
  }, attachment_id) {
    try
    {
      // console.debug("actionDeleteAttachmentById", attachment_id);

      return await dispatch('deleteAttachmentByAttachmentIdFromBackend', attachment_id);
    }
    catch(err)
    {
      console.error("actionDeleteAttachmentById - Error : ", err);
      return false;
    }
  },

  async actionReceiveAttachmentOverviewlistAsArray({
    dispatch
  }, filter ) {
    return dispatch('readAttachmentOverviewlistFromBackend').then(result => {
      var attachmentList = [];

      if (result.length) {
        result.forEach(attachment => {
          attachmentList.push(attachment.value);
        });
      }

      // console.debug(' actionReceiveAttachmentOverviewlistAsArray', attachmentList);

      return attachmentList;
    })
    .catch(e => {
      console.error(e);
      return false;
    });
  },

  async actionRemoveProofLinkByProofId({
    getters,
    commit,
    rootGetters,
    dispatch
  }, proof_id
  ) {
    try
    {
      if (!proof_id)
        return false;

      const result = await dispatch("actionGetActiveProofLinkedAttachments");

      // console.debug("actionRemoveProofLinkByProofId - result", result);

      if (result.length > 0)
      {
        for( const index in result) {
          if(result[index].value.prooflink.refid === proof_id)
          {
              await dispatch('updateAttachmentAndStoreInBackend', {
              ...result[index].value,
              proof_check_active: false,
              prooflink: {
                ...getters.getterEmptyAttachmentMetadataRecord.prooflink
              }
            });
          }
        }
      }

      return true;
    }
    catch(err)
    {
      console.error("actionRemoveProofLinkByProofId - Error:", err);
      return false;
    }
  },
  //find an attachment by attachment_id in the couchdb and return it
  actionReceiveAttachmentById({
    state,
    getters,
    rootGetters,
    commit,
    dispatch
  }, attachment_id) {
    return dispatch('readAttachmentByIdFromBackend', attachment_id).then(result => {
      var attachmentObj = {};

      if (result.length == 1) {
        result.forEach(item => {
          attachmentObj = merge({}, ATTACHMENT_METADATA_RECORD, item.value);
        });
      }

      return attachmentObj;
    })
    .catch(e => {
      console.error(e);
      return false;
    });
  },

  //find an attachment by attachment_id in the couchdb and return it
  async actionReceiveAttachmentBlobById({
    state,
    getters,
    rootGetters,
    commit,
    dispatch
  }, attachment_id) {
    // getting attachemnts
    try
    {
       const doc = await dispatch('actionReceiveAttachmentById', attachment_id);

       if(doc === false)
        throw "Error reading attachment";

      const blob =  await dispatch('actionReadAttachmentBlob', doc);

      if(doc === false)
        throw "Error reading attachment blob";

      return {mimetype: doc.mimetype, data: blob, name: doc.name, filename: doc.filename};
    }
    catch(err)
    {
      console.error("actionReceiveAttachmentBlobById", err);
      return false;
    }
  },

  async actionUpdateAttachmentMetaDataById({
    dispatch,
    commit
  }, {keyid, newdata}) {
    //update an existing attachment document meta in the couchdb
    try
    {
      // console.debug("actionUpdateAttachmentMetaDataById - newdata", newdata);

      const result = await dispatch('updateAttachmentAndStoreInBackend', {
        ...newdata,
        attachment_id: keyid
      });

      // console.debug("actionUpdateAttachmentMetaDataById - result", result);

      if(result === false)
        throw "Error Updating record with keyid failed";

      return result;
    }
    catch(error) {
      console.error("actionUpdateAttachmentMetaDataById", error);
      return false;
    }
  },

  // Create a new attachment with blob
  async actionSaveAttachmentUploadFile({
    getters,
    commit,
    rootGetters,
    dispatch
  }, {
    metadata,
    uploadfile
  }) {
    try
    {
      if (!uploadfile || metadata === undefined)
        return false;

      var attachmentObj = {
        ...getters.getterEmptyAttachmentRecord,
        ...metadata,

        upload_at: +new Date(),
        upload_by: rootGetters['SessionManager/getterCurrentUsername'],

        mimetype: uploadfile.type,
        filename: uploadfile.name,

        _attachments: {
          [uploadfile.name]: {
            content_type: uploadfile.type,
            data: uploadfile
          }
        }
      };

      var result = await dispatch('createAttachmentAndStoreInBackend', attachmentObj);

      if(result === false)
        throw "Error creating attachment upload in database";

      attachmentObj.attachment_id = result.id;

      if(metadata.proof_check_active)
      {
        result = await dispatch('actionUpdateAttachmentMetaData', attachmentObj);
      }

      return result;
    }
    catch(err)
    {
      console.error("actionSaveAttachmentUploadFile - Error:", err);
      return false;
    }
  },

  // Create a new attachment with blob
  async actionSaveAttachmentBlob({
    getters,
    commit,
    rootGetters,
    dispatch
  }, payload) {
    try
    {
      if (!payload.blob || payload.metadata === undefined)
        return false;

      var attachmentObj = {
        ...getters.getterEmptyAttachmentRecord,
        ...payload.metadata,

        upload_at: +new Date(),
        upload_by: rootGetters['SessionManager/getterCurrentUsername'],

        _attachments: {
          [payload.metadata.filename]: {
            content_type: payload.metadata.mimetype,
            data: payload.blob
          }
        }
      };

      console.debug("actionSaveAttachmentBlob - attachmentObj", attachmentObj);

      var result = await dispatch('createAttachmentAndStoreInBackend', attachmentObj);

      if(result === false)
        throw "Error creating attachment upload in database";

      attachmentObj.attachment_id = result.id;

      if(payload.metadata.proof_check_active)
      {
        result = await dispatch('actionUpdateAttachmentMetaData', attachmentObj);
      }

      return result;
    }
    catch(err)
    {
      console.error("actionSaveAttachmentBlob - Error:", err);
      return false;
    }
  },

  // get all attachments by designdoc and reference object
  async actionGetAttachmentsByReference({
    dispatch,
    commit
  }, payload) {
    // getting attachemnts
    try
    {
      return await dispatch("readAttachmentsByReferenceFromBackend", {scope: payload.scope, refid: payload.refid, include_docs: payload.include_docs});
    }
    catch(err)
    {
      console.error("actionGetAttachmentsByReference", err);
      return false;
    }
  },

  // get the attaqchment blob for the process model by process Pid
  async actionReceiveProcessModelAttachmentByProcessId({
    dispatch
  }, refid) {
    try
    {
      let result = await dispatch("actionGetAttachmentsByReference", {
          scope: 'process',
          refid: refid,
          include_docs: true
        });

      // console.debug("actionReceiveProcessModelAttachmentByProcessId - result", result);

      if(result === false)
        throw "Error reading attachments";

      if(result.rows.length < 1)
        return false;

      let array = result.rows.filter(item =>
        item.doc.reference !== undefined &&
        item.doc.reference.scope === 'process' &&
        item.doc.reference.refid === refid &&
        item.doc.doccategory_id === 'ID_DOCUMENTCATEGORY_PROCESS' &&
        item.doc.doctype_id === 'ID_DOCUMENTTYPE_PROCESSMODEL'
      );

      // console.debug("actionReceiveProcessModelAttachmentByProcessId - array", array);

      if(array.length < 1)
        return false;

      // get always the latest attachment
      return await dispatch('actionReadAttachmentBlobAsURL', array[array.length-1].doc);
    }
    catch(err)
    {
      return false;
    }
  },

  // get the attachment blob for the activity model by id
  async actionReceiveProcessModelAttachmentByActivityDocId({
    dispatch
  }, refid) {
    try
    {
      let result = await dispatch("actionGetAttachmentsByReference", {
        scope: 'activitydoc',
        refid: refid,
        include_docs: true
      });

      // console.debug("actionReceiveProcessModelAttachmentByProcessId - result", result);

      if(result === false)
        throw "Error reading attachments";

      if(result.rows.length < 1)
        return false;

      let array = result.rows.filter(item =>
        item.doc.reference !== undefined &&
        item.doc.reference.scope === 'activitydoc' &&
        item.doc.reference.refid === refid &&
        item.doc.doccategory_id === 'ID_DOCUMENTCATEGORY_PROCESS' &&
        item.doc.doctype_id === 'ID_DOCUMENTTYPE_PROCESSMODEL'
      );

      // console.debug("actionReceiveProcessModelAttachmentByProcessId - array", array);

      if(array.length < 1)
        return false;

      // get always the latest attachment
      return await dispatch('actionReadAttachmentBlobAsURL', array[array.length-1].doc);
    }
    catch(err)
    {
      return false;
    }
  },
  async savePrintDocumentAsAttachment({commit, getters, rootGetters, dispatch}, {metadata, printdata}) {
    try
    {
      let result = false;

      // console.debug("savePrintDocumentAsAttachment");
      // console.debug("savePrintDocumentAsAttachment - metadata", metadata);
      // console.debug("savePrintDocumentAsAttachment - printdata", printdata );

      const doc_record = {
        ...ATTACHMENT_RECORD,
        ...metadata,

        timestamp: +new Date(),
        username: rootGetters['SessionManager/getterCurrentUsername'],

        upload_at: +new Date(),
        upload_by: rootGetters['SessionManager/getterCurrentUsername'],

        _attachments: {
          [metadata.filename]: {
            content_type: metadata.mimetype,
            data: printdata.printout
          }
        }
      };

      // console.debug("savePrintDocumentAsAttachment - doc_record", doc_record );

      if(metadata._id !== undefined) {
        result = await dispatch('updateAttachmentAndStoreInBackend', doc_record);
      } else {
        //Create a new document in couchDB
        result = await dispatch('createAttachmentAndStoreInBackend', doc_record);
      }

      return result;
    }
    catch(err)
    {
      console.error("savePrintDocumentAsAttachment Error: ", err);
      return false;
    }
  },

  async actionDownloadAttachmentZipURLByRef({commit, getters, rootGetters, dispatch}, payload) {
    try {
      const blob = await dispatch("actionDownloadAttachmentZipByRefAsBlob", payload);

      // console.debug("actionDownloadAttachmentZipURLByRef - blob", blob);

      return URL.createObjectURL(blob);
    }
    catch(err) {
      console.error('actionDownloadAttachmentZipURLByRef - Error : ', err);
      return false;
     }
  },
  async actionDownloadAttachmentZipByRefAsBlob({commit, getters, rootGetters, dispatch}, payload) {
    try {
      /*
      payload object defined as

      {
        content: [],
        text: []
        attref: {}
      }
      */
      // console.debug("actionDownloadAttachmentZipByRefAsBlob - payload", payload);

      let zipcontent = [];          // Array for the file blobs
      let contentText = [];         // Array for the file content.txt lines per file

      const currentDate = new Date();

      // Header line of contect text file
      contentText.push("filename;stage;upload_at;upload_by;comment");

      // Prepend optional ZIP Content received by parameter i.e. Report pdf data
      if(payload.content.length > 0 && payload.text.length > 0)
      {
        // console.debug("actionDownloadAttachmentZipByRefAsBlob - payload 2", payload);
        contentText = [
          ...contentText,
          ...payload.text];
        zipcontent = [...payload.content];
      }

      // Get all related attachments
      const attachments = await dispatch('actionReadAttachmentsByReferenceAsArray', payload.attref);

      // console.debug("actionDownloadAttachmentZipByRefAsBlob - attachments", attachments);

      for(var i=0; i < attachments.length; i++ )
      {
        const attblob = await dispatch('actionReadAttachmentBlob', attachments[i]);

        // console.debug("actionDownloadAttachmentZipByRefAsBlob - attblob", attblob);

        zipcontent.push({
          name: attachments[i].filename,
          lastModified: attachments[i].upload_at,
          input: attblob
        });

        contentText.push(attachments[i].filename+";"+
                        attachments[i].reference.stage+";"+
                        rootGetters['HelperManager/getterFormatDateTime'](attachments[i].upload_at)+";"+
                        attachments[i].upload_by+";"+
                        attachments[i].name + " (Attachment)");
      }

      zipcontent.push({
        name: "_content.txt",
        lastModified: rootGetters['HelperManager/getterFormatDateTime'](currentDate),
        input: contentText.join('\r')
      });

      zipcontent.push({
        name: "_info.txt",
        lastModified: rootGetters['HelperManager/getterFormatDateTime'](currentDate),
        input: "GDPR App Zip archive export by " + rootGetters['SessionManager/getterCurrentUsername'] + " with timestamp " + rootGetters['HelperManager/getterFormatDateTime'](currentDate)
      });

      // console.debug("actionDownloadAttachmentZipByRefAsBlob - zipcontent", zipcontent);

      return await downloadZip(zipcontent).blob();
    }
    catch(err) {
      console.error('actionDownloadAttachmentZipByRefAsBlob - Error : ', err);
      return false;
     }
  },

  async actionDownloadAttachmentZipURLByIds({commit, getters, rootGetters, dispatch}, payload) {
    try {
      /*
      payload object defined as
        attachmentIds: []
      }
      */

      // console.debug("actionDownloadAttachmentZipURLByIds - payload", payload);

      var zipcontent = [];          // Array for the file blobs
      var contentText = [];         // Array for the file content.txt lines per file

      const currentDate = new Date();

      // Header line of contect text file
      contentText.push("filename;stage;upload_at;upload_by;comment");

      for (const attachmentId of payload.attachmentIds) {
        const attObj = await dispatch("actionReceiveAttachmentById", attachmentId);
        const attfile = await dispatch("actionReceiveAttachmentBlobById", attachmentId);

        zipcontent.push({
            name: attfile.filename,
            lastModified: attObj.upload_at,
            input: attfile.data
        });

        contentText.push(attObj.filename+";"+
                         attObj.reference.stage+";"+
                         rootGetters['HelperManager/getterFormatDateTime'](attObj.upload_at)+";"+
                         attObj.upload_by+";"+
                         attObj.name);
      }

      zipcontent.push({
        name: "_content.txt",
        lastModified: rootGetters['HelperManager/getterFormatDateTime'](currentDate),
        input: contentText.join('\r')
      });

      // console.debug("actionDownloadAttachmentZipURLByIds - zipcontent", zipcontent);

      const blob = await downloadZip(zipcontent).blob();

      // console.debug("actionDownloadAttachmentZipURLByIds - blob", blob);

      return URL.createObjectURL(blob);
    }
    catch(err) {
      console.error('actionDownloadAttachmentZipURLByRef - Error : ', err);
      return false;
     }
  },

  async actionRetrieveAttachmentBlobAsURL({
    getters,
    commit
  }, payload) {
    try
    {
      // console.debug("actionRetrieveAttachmentBlobAsURL - payload", payload);

      const blob = await backendDataApi.readAttachmentBlobByDocIdByFilenameFromBackend(payload.attachment.id, payload.attachment.name);

      if (blob == false)
          throw "Error Reading Attachment";

      // console.debug("viewAttachment - blob", blob);

      return URL.createObjectURL(blob);
    }
    catch(error) {
      console.error("actionRetrieveAttachmentBlobAsURL", error);
      return false;
    }
  },

  async actionDeleteAttachmentsByReference({
    dispatch
  }, payload) {
    try
    {
      // console.debug("actionDeleteAttachmentsByReference - payload", payload);

      const result = await dispatch("actionGetAttachmentsByReference", {
          scope: payload.scope,
          refid: payload.refid,
          include_docs: true
      });

      // console.debug("actionDeleteAttachmentsByReference - result", result);

      for(const index in result)
      {
        await backendDataApi.deleteDocumentFromBackend(result[index].doc);
      }

      return true;
    }
    catch(error) {
      console.error("actionDeleteAttachmentsByReference", error);
      return false;
    }
  },
  //find a attachments via reference object
  async actionReadAttachmentsByReferenceAsArray({
    dispatch
  }, payload) {
    // console.debug("actionReadAttachmentsByReferenceAsArray - payload", payload);

    try
    {
      // console.debug("actionReadAttachmentsByReferenceAsArray - payload", payload);

      const result = await dispatch("actionGetAttachmentsByReference", {
          scope: payload.reference.scope,
          refid: payload.reference.refid,
          include_docs: true
      });

      // console.debug("actionReadAttachmentsByReferenceAsArray - result", result);

      return result.map(x=> x.doc);
    }
    catch(error) {
      console.error("actionReadAttachmentsByReferenceAsArray", error);
      return false;
    }
  },

  async actionUpdateAttachmentMetaData({
    getters,
    rootGetters,
    commit,
    dispatch
  }, newdata) {
    //update an attachment document meta in the couchdb
    try
    {
      // console.debug("actionUpdateAttachmentMetaData - newdata", newdata);

      if(newdata.proof_check_active)
      {
        let proofresult = await dispatch("ProofManager/actionSaveProofFromAttachmentData", newdata, {root: true});

        if(proofresult === false)
          throw "Error creating attachment proof in database";

        // set new proof link data
        newdata.prooflink.active = newdata.proof_check_active;
        newdata.prooflink.refid = proofresult.id;
      }
      else {
        newdata.prooflink = {
          ...getters.getterEmptyAttachmentMetadataRecord.prooflink,
        };
      }

      let updatepayload = {
        keyid: newdata.attachment_id,
        newdata: {
          ...newdata,
        }
      };

      // Back coupling proof id to proof link in original attachment
      let metaresult = await dispatch('actionUpdateAttachmentMetaDataById', updatepayload);

      if(metaresult === false)
        throw "Update attachment metadata failed";

      return true;
    }
    catch(error) {
      console.error("actionUpdateAttachmentMetaData", error);
      return false;
    }
  },
};
//
///////////////////////////////////////////////////////////////////////////////
//
const getters = {
  getterEmptyAttachmentMetadataRecord: (state) => {
    return {...ATTACHMENT_METADATA_RECORD};
  },
  getterEmptyAttachmentRecord: (state) => {
    return {...ATTACHMENT_RECORD};
  },
  getterAttachmentDeleteAllowed: (state, getters, rootState, rootGetters) => (attachmentObj) => {
    try {
      if (
        attachmentObj === undefined ||
        !Object.prototype.hasOwnProperty.call(attachmentObj, "upload_by") ||
        !Object.prototype.hasOwnProperty.call(attachmentObj, "proof_check_active")

      )
        throw "attachmentObj object is undefined";

      if(rootGetters["SessionManager/getterCurrentUsername"] != attachmentObj.upload_by)
        return false;

      return (attachmentObj.proof_check_active === false);
    } catch (error) {
      console.error("getterActivityDocDeleteAllowed - Error : ", error);
      return false;
    }
  },
  getterAttachmentScopesAsArray: (state, getters, rootState, rootGetters) => {
    return [...ATTACHMENT_SCOPE_ARRAY];
  }
};

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
};