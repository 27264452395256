import backendDataApi from '@/services/backendDataApi.service.js';

import {
  LOCATION_RECORD,
  LOCATION_RECORD_TYPE
} from '@/config/config.couchdb';

import {
  CUSTOM_ID_PREFIX
} from '@/config/config.constants.js';

export default {
  namespaced: true,
  state: {
    LocationList: []
  },
  mutations: {
    LOCATION_SAVED(state, payload) {},
    LOCATION_REMOVED(state, payload) {},
    LOCATION_CACHE_LOAD(state, payload) {
      // console.debug('LocationManager - LOCATION_CACHE_LOAD - payload', payload); 
      state.LocationList = [...payload];
    }
  },
  actions: {
    // Private functions
    async deleteLocationByLocationIdFromBackend({
      state
    }, location_id){
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({designdoc: 'locations/list', keyid: location_id});
    },    

    //find all locations with limited content (activitydoc_id, process_pid) in the couchdb and return them
    async readLocationOverviewlistFromBackend({
      state
    }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({designdoc: 'locations/overviewlist', keyid: filter});
    },    
    //find a location by id in the couchdb and return it
    async readLocationByIdFromBackend({
      state
    }, location_id){
      return await backendDataApi.readDocumentByKeyIdFromBackend({designdoc: 'locations/list', keyid: location_id});
    },
    //create a new location in the couchdb and return location_id or false     
    async createLocationAndStoreInBackend({
      rootGetters,
      commit
    }, data) {
      // Make sure an location_id is assigned on save for a new incident
      let currentdatetime = +new Date();

      // console.debug("createLocationAndStoreInBackend - data", data);

      if(!data.location_id || data.location_id === undefined)
        data.location_id = backendDataApi.generateNewCustomKeyId('loc');

      // Safe guard to ensure a valid id
      if(!data.location_id || 0 === data.location_id.length)
        return false;

      data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
      data.created_at = currentdatetime;   
      
      if(!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_CREATED"; 
      }

      const record = {
        username: rootGetters['SessionManager/getterCurrentUsername'],
        
        type: LOCATION_RECORD_TYPE,
        keyid: data.location_id,
        
        timeline: [data]
      };

      // console.debug("createLocationAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },  
    //update a location in the couchdb and return location_id or false   
    async updateLocationAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      try
      {
        let currentdatetime = +new Date();

        data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
        data.created_at = currentdatetime;
        
        if(!data.changetype_id || data.changetype_id == 'ID_TIMELINE_CHANGETYPE_CREATED') {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED"; 
        }
        
        let record = await dispatch('readLocationByIdFromBackend', data.location_id);

        // console.debug("updateLocationAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }
        
        let doc = record[0].value;

        // console.debug("updateLocationAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      }
      catch(err) {
        console.error("updateLocationAndStoreInBackend - Error: ", err);
        return false;
      }
    },  
    
    //
    // Public
    // 

    // generate a new custom id for location     
    async actionRefreshLocationsCache({
      getters,      
      commit,
      dispatch
    }) {
      try
      {
        // console.debug("actionRefreshLocationsCache");

        let result = await dispatch('readLocationOverviewlistFromBackend');
        
        if(result === false)
          throw "Cannot read location overviewlist from backend";

        // console.debug("actionRefreshLocationsCache - result", result);

        let locationarray = [];

        if (result.length) {
          result.forEach(item => {
            locationarray.push(item.value);
          });
        }

        commit('LOCATION_CACHE_LOAD', locationarray);

        return true;
      }
      catch(err)
      {
        console.error("actionRefreshLocationsCache - Error : ", err);
        return false;        
      }      
    },     

    //save or update a new location into the couchdb
    async actionSaveLocation({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, data) {
      try
      {
        // console.debug("actionSaveLocation", data);

        let result = false;

        if(!data.location_id || Object.prototype.hasOwnProperty.call(data, "force_create")) {
          // console.debug("actionSaveLocation - Create Mode", data);
          result = await dispatch('createLocationAndStoreInBackend', data);        
        }
        else 
        {
          if(Object.prototype.hasOwnProperty.call(data, "create_if_needed"))
          {
            // console.debug("actionSaveLocation - create_if_needed Mode", data);
            delete data.create_if_needed;

            let locationObj = await dispatch('actionReceiveLocationById', data.location_id);
            
            if(Object.keys(locationObj).length === 0 && locationObj.constructor === Object) {
              result = await dispatch('createLocationAndStoreInBackend', data);
            }
          }

          if(!result)
          {
            // console.debug("actionSaveLocation - Update Mode", data);          
            result = await dispatch('updateLocationAndStoreInBackend', data);        
          }
        }

        if(result !== false ) {
          await dispatch('actionRefreshLocationsCache');  
          // console.debug('actionSaveLocation - After Update');
          commit("LOCATION_SAVED", data);
        }

        return result;
      }
      catch(err)
      {
        console.error("actionSaveLocation - Error : ", err);
        return false; 
      }
    },
    //delete a existing location from the couchdb
    async actionDeleteLocationById({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, location_id) {
      try
      {
        // console.debug("actionDeleteLocationById", location_id);

        let result = await dispatch('deleteLocationByLocationIdFromBackend', location_id);    

        if(result !== false ) {
          await dispatch('actionRefreshLocationsCache');
          commit("LOCATION_REMOVED", location_id);  
          
          // Delete attachments
          await dispatch('AttachmentManager/actionDeleteAttachmentsByReference', {refid: location_id, scope: "location"}, {root: true});    
        }

        return result;
      }
      catch(err)
      {
        console.error("actionDeleteLocationById - Error : ", err);
        return false; 
      }      
    },
    //find a location by location_id in the couchdb and return it
    actionReceiveLocationById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, location_id){
      return dispatch('readLocationByIdFromBackend', location_id).then(result => {
        let locationObj = {};

        if (result.length == 1) {
          result.forEach(item => {
            locationObj = {
              ...item.value
            };
          });
        }

        // console.debug("actionReceiveLocationById - locationObj", locationObj);

        return locationObj;
      })
      .catch(e => {
        console.error(e);
        return false;
      });
    }, 
    async actionReceiveCurrentLocationDataById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, location_id) {
      try
      {
        let result = await dispatch('actionReceiveLocationById', location_id);

        if(result == undefined || !result)
          throw "Error activityDoc not found";

        // console.debug("actionReceiveCurrentLocationDataById result", result);
        
        let locationObj = {};

        if(!(Object.keys(result).length === 0 && result.constructor === Object))
        {
          locationObj = {
            ...result.timeline[result.timeline.length-1]
          };
        }

        return locationObj;
      }
      catch(err)
      { 
        console.error("actionReceiveCurrentLocationDataById - Error : ", err);
        return false;
      }
    },          
    //find all locations with limited content from the couchdb and return them
    actionReceiveLocationOverviewlistAsArray({
      dispatch
    }, filter) {
      return dispatch('readLocationOverviewlistFromBackend', filter).then(result => {
        let array = [];

        if (result.length) {
          result.forEach(item => {
            array.push(item.value);
          });
        }

        return array;
      });
    },
    async actionUpdateLocationByEntityData({
      getters,      
      commit,
      dispatch
    }, entityData ) {
      try
      {
        // console.debug("actionUpdateLocationByEntityInfo - entityinfo", entityinfo);

        let locationObj = {
          ...getters.getterEmptyLocationRecord,
          core: {
            ...entityData.EntityInfo
          },
          responsible: {
            ...entityData.Responsible
          },
          create_if_needed: true
        };

        delete locationObj.core.entity_name;
        delete locationObj.responsible.responsible_name;

        locationObj.core.name = entityData.EntityInfo.entity_name;
        locationObj.core.type_id = getters.getterLocationTypeMain;
        locationObj.location_id = getters.getterLocationIdEntityMain;

        locationObj.responsible.name = entityData.Responsible.responsible_name;

        let result = await dispatch('actionSaveLocation', locationObj); 
            
        if(result == false)
        {
          console.error("actionUpdateLocationByEntityInfo - Error saving location : ", locationObj); 
        }
      }
      catch(err)
      {
        console.error("actionUpdateLocationByEntityInfo - Error : ", err);
      }      
    },     
  },
  getters: {
    // Public
    getterEmptyLocationRecord: () => {
      return Object.assign({}, LOCATION_RECORD);
    },
    getterEntityLocationsAsArray: (state) => {
      return state.LocationList;
    },
    getterLocationTypeMain: () => { return 'ID_LOCATIONTYPE_MAIN';},
    getterLocationIdEntityMain: () => { return 'ID_LOCATION_ENTITYMAIN';},
    getterEntityLocationIsEditable: (state, getters, rootState, rootGetters) => (locationObj) => {
      try
      {
        // console.debug("getterEntityLocationIsEditable - locationObj", locationObj);
        
        if (Object.prototype.hasOwnProperty.call(locationObj, "type_id"))
          return (locationObj.type_id !== getters.getterLocationTypeMain);

        if (Object.prototype.hasOwnProperty.call(locationObj, "core"))
          return (locationObj.core.type_id !== getters.getterLocationTypeMain);
        
        return false;        
      }
      catch(error)
      {
        console.error("getterEntityLocationIsEditable - Error : ", error);
        return false;
      }
    },
  }
};