<template>
    <div v-resize="onResize">
      <div ref="resizableDiv">
          <v-card :class="[classList]" :height="contentHeight" class="card-box--layout">
              <v-card-title
                :class="{ 'no-bottom-line': hideBottomLine }"
                class="card-title bk--ctm" ref="cardTitle"
              >

                <slot name="header"></slot>

                <v-spacer></v-spacer>

                <slot name="headerbutton"></slot>

              </v-card-title>

            <div class="empty--box-inner"></div>

            <v-card-text :class="classListText" class="card-body">

              <slot name="subtitle"></slot>

              <slot name="content"></slot>

            </v-card-text>

            <template v-if="enableCardActions">

              <v-card-actions>

                <slot name="actionbutton"></slot>

              </v-card-actions>
            </template>

          </v-card>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    classList: {
      required: false,
      //type: Object,
      default: () => ({ "fill-height ": true })
    },
    classListText: {
      required: false,
      type: Array,
      default: () => ([])
    },
    hideBottomLine : {
      required: false,
      type: Boolean,
      default: false
    },
    enableCardActions: {
      required: false,
      type: Boolean,
      default: false
    },
    correction: {
        type: Number,
        default: 0,
    }
  },
  data() {
    return {
      contentHeight: 0
    };
  },
  methods: {
    onResize() {
      const HeightFooterOffset = this.getDefaultCardTitlesize; //default v-data-table footer height

      this.contentHeight =
        window.innerHeight -
        this.$refs.resizableDiv.getBoundingClientRect().y -
        HeightFooterOffset -
        this.correction;
    }
  }
};
</script>

<style lang="scss" scoped>

.card-body {
  overflow-y: auto;
  overflow-x: hidden;
}

.card-box--layout {
 overflow: auto;
 position: relative;
}

.card-box--layout .card-body {
    position: relative;
    top: 0;
    padding: 0 .75rem;
    height: calc(100% - 56px);
}

.card-box--layout .card-title {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}

.card-box--layout.empty--box-top .card-body {
    height: calc(100% - 156px);
}

.card-box--layout.empty--box-top .empty--box-inner {
  height: 94px;
}

.header--content-body {
  display: flex;
}

.no-bottom-line {
  border-bottom: none !important;
}

</style>
