import backendDataApi from '@/services/backendDataApi.service.js';

import CommonUtils from '@/utils/common.utils.js';

import {
  AGENCIES_GDPR_DOCUMENT_NAME
} from '@/config/config.couchdb';

import {
  COUNTRYLIST,
} from '@/lookup/countries';

export default {
  namespaced: true,
  state: {
    AGENCYLIST_EU: {},
    AGENCYLIST_NON_EU: {},
    AGENCYLIST_KK_DE: {},
    AGENCYLIST_EKD_DE: {},
  },
  mutations: {
    AGENCIES_LOAD(state, payload) {
      // console.debug("AGENCIES_LOAD - payload", payload);

      state.AGENCYLIST_EU = {
        ...payload.agencies.AGENCYLIST_EU
      };

      state.AGENCYLIST_NON_EU = {
        ...payload.agencies.AGENCYLIST_NON_EU
      };

      state.AGENCYLIST_KK_DE = {
        ...payload.agencies.AGENCYLIST_KK_DE
      };

      state.AGENCYLIST_EKD_DE = {
        ...payload.agencies.AGENCYLIST_EKD_DE
      };

      // console.debug("AGENCIES_LOAD - payload", state);
    }
  },
  actions: {
    //
    // Public
    //
    async actionRetrieveAgenciesGDPRDoc({
      state,
      rootGetters,
      dispatch,
      commit
    }) {
      try {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(AGENCIES_GDPR_DOCUMENT_NAME);

        // console.debug("actionRetrieveAgenciesGDPRDoc - doc", doc);
        if (doc === false)
          throw "Error retrieving GDPR Agencies Data";

        commit('AGENCIES_LOAD', doc);
        return true;
      } catch (err) {
        console.error('actionRetrieveAgenciesGDPRDoc - Error : ', err);
        return false;
      }
    },
  },
  getters: {
    getterEmtpyAgencyRecord: (state, getters, rootState, rootGetters) => {
      return {
        name : "",
        address: "",
        phone_0: "",
        email_0: "",
        website : "",
        state: ""
      };
    },
    getterAgencyContactInfoAsArrayByAgencyCode: (state, getters, rootState, rootGetters) => (agencycode) => {
      var data = [];

      try {
        if(!agencycode || 0 === agencycode.length)
          return data;

        const contactarray = getters.getterAllAvailableAgenciesAsArray.filter(item => item.id == agencycode);

        if(contactarray.length)
        {
          data.push(contactarray[0].name);
          data.push(contactarray[0].address);
          data.push(contactarray[0].phone_0);
          data.push(contactarray[0].fax);
          data.push(contactarray[0].email_0);
          data.push(contactarray[0].website);
          data.push(contactarray[0].state);
          // data = Object.values(contactarray[0]).slice(4,Object.values(contactarray[0]).length);
        }
      } catch (err) {
        console.error("getterAgencyContactDataAsArray - Error : ", err);
      }

      return data;
    },
    getterAvailableAgenciesByCountryAsArray: (state, getters, rootState, rootGetters) => (countrycode) => {
      var data = [];

      try {
        if(!countrycode || 0 === countrycode.length)
          return data;

        data = getters.getterAllAvailableAgenciesAsArray.filter(item => item.countrycode == countrycode);

      }
      catch (err) {
        console.error("getterAvailableAgencyNameByCountryAsArray - Error : ", err);
      }

      return data;
    },
    getterAgenciesByCountryByListAsArray: (state, getters, rootState, rootGetters) => (countrycode, agencylist) => {
      let data = [];

      let lawbasis = "";

      if(!countrycode || 0 === countrycode.length || !agencylist || 0 === agencylist.length )
        return data;

      switch(agencylist) {
        case "AGENCYLIST_EU":
          lawbasis = "GDPR_EU";
          break;
        case "AGENCYLIST_NON_EU":
          lawbasis = "DSG_" + countrycode;
          break;
        case "AGENCYLIST_KK_DE":
          lawbasis = "KDR_DE";
          break;
        case "AGENCYLIST_EKD_DE":
          lawbasis = "EKD_DE";
          break;

        default:
          throw "Invalid agency list requested";
      }

      // console.debug("getterAgenciesByCountryByListAsArray - country, agencylist", countrycode, agencylist);

      try {
        Object.keys(state[agencylist][countrycode]).forEach(item => {
          // console.debug("getterAgenciesByCountryByListAsArray item ", item);
          data.push({
            id: agencylist + "#" + countrycode + "#" + item,
            countrycode: countrycode,
            regioncode: item,
            lawcode: lawbasis,
            display_name: state[agencylist][countrycode][item].name + " [" + state[agencylist][countrycode][item].state + "]",
            ...state[agencylist][countrycode][item]
          });
        });
      } catch (err) {
        console.error("getterAgenciesByCountryByListAsArray - Error : ", err);
      }

      return data;
    },
    getterAllAvailableAgenciesAsArray: (state, getters, rootState, rootGetters) => {
      var authorityArray = [];

      try
      {
        for(const countrycode of Object.keys(state.AGENCYLIST_EU))
        {
          authorityArray = authorityArray.concat(getters.getterAgenciesByCountryByListAsArray(countrycode, "AGENCYLIST_EU"));
        }

        for(const countrycode of Object.keys(state.AGENCYLIST_KK_DE))
        {
          authorityArray = authorityArray.concat(getters.getterAgenciesByCountryByListAsArray(countrycode, "AGENCYLIST_KK_DE"));
        }

        for(const countrycode of Object.keys(state.AGENCYLIST_EKD_DE))
        {
          authorityArray = authorityArray.concat(getters.getterAgenciesByCountryByListAsArray(countrycode, "AGENCYLIST_EKD_DE"));
        }

        for(const countrycode of Object.keys(state.AGENCYLIST_NON_EU))
        {
          authorityArray = authorityArray.concat(getters.getterAgenciesByCountryByListAsArray(countrycode, "AGENCYLIST_NON_EU"));
        }
      }
      catch (err) {
        console.error("getterAllAvailableAgenciesAsArray - Error : ", err);
      }

      authorityArray.sort(CommonUtils.getArrayOfObjectDynamicSort('countrycode'));

      // console.debug("getterAllAvailableAgenciesAsArray - authorityArray : ", authorityArray);

      return authorityArray;
    },
    getterAllAvailableAgencyCountriesAsArray: (state, getters, rootState, rootGetters) => {
      var countryArray = [];

      try
      {
        const codearray = getters.getterAllAvailableAgenciesAsArray.map(o => o.countrycode);

        for (const key in COUNTRYLIST) {
          if (codearray.indexOf(key) !== -1) {
            countryArray.push({
              value: key,
              text: COUNTRYLIST[key].name
            });
          }
        }
      }
      catch (err) {
        console.error("getterAllAvailableAgenciesAsArray - Error : ", err);
      }

      countryArray.sort(CommonUtils.getArrayOfObjectDynamicSort('countrycode'));

      // console.debug("getterAllAvailableAgenciesAsArray - authorityArray : ", authorityArray);

      return countryArray;
    },
  }
};