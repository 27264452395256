import backendDataApi from '@/services/backendDataApi.service.js';

import {INITIAL_DOCUMENTATION_START_DATE} from '@/config/config.constants';

import {
  PROCEDURE_RECORD,
  PROCEDURE_RECORD_TYPE,
  INITPROCEDURE_DOCUMENT_NAME,
  PROCEDURE_STAGEHISTORY_RECORD
} from '@/config/config.couchdb';

import {
  PROCEDURESTAGE_LIST
} from '@/lookup/procedure_stages';

import i18n from '@/translations';

export default {
  namespaced: true,
  state: {
    PROCEDUREASPECT_LIST: {},
    PROCEDUREASPECT_I18N_KEY: "",
    PROCEDUREASPECT_I18N: {},

    PROCEDURECONTROL_LIST: {},
    PROCEDURECONTROL_I18N_KEY: "",
    PROCEDURECONTROL_I18N: {},

    PROCEDUREGROUP_LIST: {},
    PROCEDUREGROUP_I18N_KEY: "",
    PROCEDUREGROUP_I18N: {},

    PROCEDUREMEASURE_LIST: {},
    PROCEDUREMEASURE_I18N_KEY: "",
    PROCEDUREMEASURE_I18N: {},
  },
  mutations: {
    SET_PASSPHRASE(state, passphrase) {
      state.passphrase = passphrase;
    },
    PROCEDURE_APPROVED(state, payload) { },
    PROCEDURE_VALID(state, payload) { },
    PROCEDURE_SAVED(state, payload) { },
    PROCEDURE_REMOVED(state, payload) { },
    INITPROCEDURE_LOAD(state, payload) {
      // console.debug("INITPROCEDURE_LOAD - payload", payload);

      state.PROCEDUREASPECT_I18N_KEY = payload.procedure_aspects.I18N_KEY;
      state.PROCEDUREASPECT_I18N = { ...payload.procedure_aspects.I18N };
      state.PROCEDUREASPECT_LIST = { ...payload.procedure_aspects.LIST };

      state.PROCEDURECONTROL_I18N_KEY = payload.procedure_controls.I18N_KEY;
      state.PROCEDURECONTROL_I18N = { ...payload.procedure_controls.I18N };
      state.PROCEDURECONTROL_LIST = { ...payload.procedure_controls.LIST };

      state.PROCEDUREGROUP_I18N_KEY = payload.procedure_groups.I18N_KEY;
      state.PROCEDUREGROUP_I18N = { ...payload.procedure_groups.I18N };
      state.PROCEDUREGROUP_LIST = { ...payload.procedure_groups.LIST };

      state.PROCEDUREMEASURE_I18N_KEY = payload.procedure_measures.I18N_KEY;
      state.PROCEDUREMEASURE_I18N = { ...payload.procedure_measures.I18N };
      state.PROCEDUREMEASURE_LIST = { ...payload.procedure_measures.LIST };

      // console.debug("INITPROCEDURE_LOAD - payload", state);
    },
    PROCEDURE_ASSIGNED(state, payload) { },
  },
  actions: {
    //
    // private - protected action functions
    //
    async deleteProcedureByProcedureIdFromBackend({ state }, procedure_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({
        designdoc: "procedures/list",
        keyid: procedure_id,
      });
    },
    //find all process activity with limited content (activitydoc_id, process_pid) in the couchdb and return them
    async readProcedureOverviewlistFromBackend({ state }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({
        designdoc: "procedures/overviewlist",
        keyid: filter,
      });
    },
    //find a procedure by id in the couchdb and return it
    async readProcedureByIdFromBackend({ state }, procedure_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({
        designdoc: "procedures/list",
        keyid: procedure_id,
      });
    },
    //create a new procedure in the couchdb and return procedure_id or false
    async createProcedureAndStoreInBackend({ rootGetters, commit }, data) {
      // Make sure an procedure_id is assigned on save for a new record
      let currentdatetime = +new Date();

      // console.debug("createProcedureAndStoreInBackend - data", data);

      if (!data.procedure_id || data.procedure_id === undefined)
        data.procedure_id = backendDataApi.generateNewCustomKeyId("tom");

      // Safe guard to ensure a valid id
      if (!data.procedure_id || 0 === data.procedure_id.length) return false;

      data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
      data.created_at = currentdatetime;
      data.revision = data.revision + 1;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_DRAFTCREATED";

        data.stagehistory.push({
          ...PROCEDURE_STAGEHISTORY_RECORD,
          changetype_id: data.changetype_id,
          stage: data.stage,
          revision: data.revision,
          created_at: data.created_at,
          created_by: data.created_by,
          finished_at: +new Date(),
          finished_by: rootGetters["SessionManager/getterCurrentUsername"],
        });
      }

      if (!data.assigned_to) {
        data.assigned_to = rootGetters["SessionManager/getterCurrentUsername"];
      }

      const record = {
        username: rootGetters["SessionManager/getterCurrentUsername"],

        type: PROCEDURE_RECORD_TYPE,
        keyid: data.procedure_id,

        timeline: [data],
      };

      // console.debug("createProcedureAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a procedure in the couchdb and return procedure_id or false
    async updateProcedureAndStoreInBackend(
      { rootGetters, dispatch, commit },
      data
    ) {
      try {
        let currentdatetime = +new Date();

        data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
        data.created_at = currentdatetime;

        if (
          !data.changetype_id ||
          data.changetype_id == "ID_TIMELINE_CHANGETYPE_CREATED"
        ) {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        const record = await dispatch("readProcedureByIdFromBackend", data.procedure_id);

        // console.debug("updateProcedureAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        var doc = record[0].value;

        if (doc.timeline[doc.timeline.length - 1].active != data.active)
          data.changetype_id = data.active ? "ID_TIMELINE_CHANGETYPE_ACTIVATE" : "ID_TIMELINE_CHANGETYPE_INACTIVATE";

        // console.debug("updateProcedureAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      } catch (err) {
        console.error("updateProcedureAndStoreInBackend - Error: ", err);
        return false;
      }
    },

    //
    // Public actions
    //

    //action get new custom measure id
    async actionGetCustomMeassureID({ rootGetters, dispatch, commit }, data) {
      try {
        return await backendDataApi.generateNewCustomKeyId("mea");
      } catch (err) {
        console.error("actionGetCustomMeassureID - Error: ", err);
        return false;
      }
    },
    // load procedure init doc from couchDB
    async actionRetrieveInitProcedureDoc({
      state,
      rootGetters,
      dispatch,
      commit,
    }) {
      try {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(
          INITPROCEDURE_DOCUMENT_NAME
        );

        // console.debug("actionRetrieveInitProcedureDoc - doc", doc);
        if (doc === false) throw "Error retrieving Procedure Data";

        commit("INITPROCEDURE_LOAD", doc);
        return true;
      } catch (err) {
        console.error("actionRetrieveInitProcedureDoc - Error : ", err);
        return false;
      }
    },
    async actionCreateProceduresOnFirstRun(
      { getters, commit, dispatch },
      companydetail
    ) {
      try {
        // console.debug("actionCreateProceduresOnFirstRun", companydetail);

        let data = {
          ...getters.getterEmptyProcedureRecord,
        };

        // prefill activation date
        if(Object.prototype.hasOwnProperty.call(companydetail, "initialdefault_date")) {
          data.core.activation_date = companydetail.initialdefault_date;
        }
        else {
          data.core.activation_date = INITIAL_DOCUMENTATION_START_DATE;
        }

        data.core.type_id = "ID_PROCEDURETARGET_ENTITY";
        data.common.procedure_required = false;
        data.responsibility.procedure_required = false;
        data.datainput.procedure_required = false;
        data.datatransfer.procedure_required = false;
        data.datastorage.procedure_required = false;
        data.datamanagement.procedure_required = false;

        data.common.valid = false;
        data.responsibility.valid = false;
        data.datainput.valid = false;
        data.datatransfer.valid = false;
        data.datastorage.valid = false;
        data.datamanagement.valid = false;

        // console.debug("actionCreateProceduresOnFirstRun - data", data);

        const result = await dispatch("createProcedureAndStoreInBackend", data);

        if (result === false) throw "Error creating procedure";

        commit("PROCEDURE_SAVED", data);

        return result;
      } catch (err) {
        console.error("actionCreateProceduresOnFirstRun Error: ", err);
        return false;
      }
    },
    //find all procedures with limited content in the couchdb and return them
    actionReceiveProcedureOverviewAsArray({ dispatch }, filter) {
      return dispatch("readProcedureOverviewlistFromBackend").then((result) => {
        let array = [];

        if (result.length) {
          result.forEach((item) => {
            array.push(item.value);
          });
        }

        return array;
      });
    },
    //save or update a new procedure into the couchdb
    async actionSaveProcedure(
      { state, getters, rootGetters, dispatch, commit },
      data
    ) {
      try {
        // console.debug("actionSaveProcedure", data);
        var result = false;

        if (!data.procedure_id) {
          // console.debug("actionSaveProcedure - Create Mode", data);
          result = await dispatch("createProcedureAndStoreInBackend", data);

          if (result === false) throw "Error creating procedure";

          commit("PROCEDURE_SAVED", data);

          return result;
        }

        // console.debug("actionSaveProcedure- Update Mode", data);
        // let result = false;
        result = await dispatch("updateProcedureAndStoreInBackend", data);

        // console.debug('actionSaveProcedure - Update result', result);

        if (result == false) throw "Error saving procedure update";

        commit("PROCEDURE_SAVED", data);

        if (getters.getterProcedureNeedsApproval(data))
          commit("PROCEDURE_VALID", data);

        if (getters.getterProcedureIsApproved(data)) {
          commit("PROCEDURE_APPROVED", data);

        let proofresult = await dispatch("ProofManager/actionSaveProofFromProcedureData", data, { root: true });

        if (proofresult === false)
          console.error("Error generating proof with procedure data - data", data);

          // console.debug("actionSaveProcedure - Approved Procedure", data);

          const report = await dispatch("actionGenerateProcedureReport", data);

          if (report == false) {
            throw "Report Document Generation Error";
          }

          // set attachment object
          const docobj = {
            name:
              i18n.t("common.procedure") +
              " v" +
              data.revision +
              " " +
              (data.active ? i18n.t("common.active") : i18n.t("common.inactive")),

            doccategory_id: "ID_DOCUMENTCATEGORY_PROCEDURE",
            doctype_id: "ID_DOCUMENTTYPE_TOM",

            filename:
              rootGetters["HelperManager/getterCurrentDateTimeCompact"] +
              "_procedure_rev_" +
              data.revision +
              "_approved.pdf",
            mimetype: "application/pdf",
            notes: "Auto Generated by GDPR App",

            can_deleted: false,

            reference: {
              refid: data.procedure_id,
              scope: "procedure",
              stage: data.stage,
              revision: data.revision,
            },
          };

          await dispatch(
            "AttachmentManager/savePrintDocumentAsAttachment",
            { metadata: docobj, printdata: report },
            { root: true }
          );
        }

        return result;
      } catch (err) {
        console.error("actionSaveProcedure Error: ", err);
        return false;
      }
    },
    actionReceiveProcedureById({ commit, dispatch }, procedure_id) {
      // console.debug('actionReceiveProcedureById - procedure_id', procedure_id);

      return dispatch("readProcedureByIdFromBackend", procedure_id)
        .then((result) => {
          if (result.length == 1)
            return { ...result[0].value };
          else
            return {};
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
    },
    //find a procedure revision by activitydoc_id in the couchdb and return the last data element
    async actionReceiveCurrentProcedureDataById(
      { state, getters, rootGetters, commit, dispatch },
      procedure_id
    ) {
      try {
        let result = await dispatch("actionReceiveProcedureById", procedure_id);

        if (result == undefined || !result) throw "Error procedure not found";

        // console.debug("actionReceiveCurrentProcedureDataById result", result);

        let procedureObj = {};

        if (
          !(Object.keys(result).length === 0 && result.constructor === Object)
        ) {
          procedureObj = {
            ...result.timeline[result.timeline.length - 1],
          };
        }

        return procedureObj;
      } catch (err) {
        console.error("actionReceiveCurrentProcedureDataById - Error : ", err);
        return false;
      }
    },
    //delete a incident from the couchdb
    async actionDeleteProcedureById(
      { state, getters, rootGetters, dispatch, commit },
      procedure_id
    ) {
      try {
        // console.debug("actionDeleteProcedureById", activitydoc_id);

        let result = await dispatch(
          "deleteProcedureByProcedureIdFromBackend",
          procedure_id
        );

        if (result !== false) {
          // console.debug('actionDeleteProcedureById - After Update');
          commit("PROCEDURE_REMOVED", procedure_id);

          // Delete attachments
          await dispatch(
            "AttachmentManager/actionDeleteAttachmentsByReference",
            { refid: procedure_id, scope: "procedure" },
            { root: true }
          );
        }

        return result;
      } catch (err) {
        console.error("actionDeleteProcedureById - Error : ", err);
        return false;
      }
    },
    async actionGenerateProcedureReport(
      { state, getters, rootGetters, dispatch, commit },
      procedureObj
    ) {
      try {
        // console.debug("actionGenerateProcedureReport - procedureObj", procedureObj);

        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "procedure_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

        printjobInfoObj.data.procedure = { ...procedureObj };

        if (printjobInfoObj.data.procedure.procedure_id) {
          const keyid = printjobInfoObj.data.procedure.procedure_id;
          printjobInfoObj.data.procedure.procedure_id =
            keyid.lastIndexOf("_") > 0 ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;
        }

        // console.debug("actionGenerateProcedureReport - printjobInfoObj", printjobInfoObj);

        if (printjobInfoObj.data.procedure.core.type_id) {
          printjobInfoObj.data.procedure.core.scope_display =
            getters.getterProcedureScopeTextByLc(
              printjobInfoObj.data.procedure.core.type_id,
              printjobInfoObj.data.procedure.core.target_id,
              printjobInfoObj.lang
            );
        }

        if (printjobInfoObj.data.procedure.stage) {
          printjobInfoObj.data.procedure.stage_display = rootGetters["LookupManager/getterProcedureStagesAsArrayByLc"](printjobInfoObj.lang)
            .filter((item) => item.stage === printjobInfoObj.data.procedure.stage)[0].display_name;
        }

        if (printjobInfoObj.data.procedure.stagehistory.length) {
          printjobInfoObj.data.procedure.stagehistory.forEach(function (item) {
            if (Object.prototype.hasOwnProperty.call(item, "changetype_id"))
              item.change_display = rootGetters[
                "LookupManager/getterTimelineChangeTypesAsArrayByLc"
              ](printjobInfoObj.lang).filter(
                (el) => el.id === item.changetype_id
              )[0].display_name;
            else
              item.change_display = rootGetters[
                "LookupManager/getterTimelineChangeTypesAsArrayByLc"
              ](printjobInfoObj.lang).filter(
                (el) => el.id === "ID_TIMELINE_CHANGETYPE_STAGECHANGED"
              )[0].display_name;

            item.finished_by = rootGetters[
              "AccountManager/getterUserFullnameFromUsername"
            ](item.finished_by);

            item.stage_display = rootGetters[
              "LookupManager/getterProcedureStagesAsArrayByLc"
            ](printjobInfoObj.lang).filter(
              (el) => el.stage === item.stage
            )[0].display_name;
            item.finished_at_display = rootGetters[
              "HelperManager/getterFormatDate"
            ](item.finished_at);
          });
        }

        printjobInfoObj.data.procedure.created_at_display = rootGetters["HelperManager/getterFormatDate"](printjobInfoObj.data.procedure.created_at);

        // Reorganize procedure record for printing based on controls

        printjobInfoObj.data.procedure.measures_tech_display = {};
        printjobInfoObj.data.procedure.measures_org_display = {};

        Object.keys(state.PROCEDURECONTROL_LIST).forEach((control_id) => {
          printjobInfoObj.data.procedure.measures_tech_display[control_id] = [];
          printjobInfoObj.data.procedure.measures_org_display[control_id] = [];
        });

        //
        // Standard measures - Merge and filter duplicates by using Set
        //

        //
        // Tech
        //
        var measures_tech_ids = [
          ...new Set([
            ...printjobInfoObj.data.procedure.common.tech_procedures,
            ...printjobInfoObj.data.procedure.responsibility.tech_procedures,
            ...printjobInfoObj.data.procedure.datainput.tech_procedures,
            ...printjobInfoObj.data.procedure.datastorage.tech_procedures,
            ...printjobInfoObj.data.procedure.datatransfer.tech_procedures,
            ...printjobInfoObj.data.procedure.datamanagement.tech_procedures,
          ]),
        ];

        // console.debug("Standard - Measures - Tech", measures_tech_ids);

        measures_tech_ids.forEach((measure_id) => {
          // console.debug("Measure - Tech", measure_id);

          var measureObj = getters
            .getterProcedureMeasuresAsArrayByLc(printjobInfoObj.lang)
            .filter((el) => el.id === measure_id)[0];

          measureObj.controls.forEach((control_id) => {
            // console.debug("Measure control - Tech", control_id);

            printjobInfoObj.data.procedure.measures_tech_display[
              control_id
            ].push(measureObj.display_printtext);
          });
        });

        //
        // Org
        //
        var measures_org_ids = [
          ...new Set([
            ...printjobInfoObj.data.procedure.common.org_procedures,
            ...printjobInfoObj.data.procedure.responsibility.org_procedures,
            ...printjobInfoObj.data.procedure.datainput.org_procedures,
            ...printjobInfoObj.data.procedure.datastorage.org_procedures,
            ...printjobInfoObj.data.procedure.datatransfer.org_procedures,
            ...printjobInfoObj.data.procedure.datamanagement.org_procedures,
          ]),
        ];

        // console.debug("Standard - Measures - Org", measures_org_ids);

        measures_org_ids.forEach((measure_id) => {
          // console.debug("Measure - Tech", measure_id);
          var measureObj = getters
            .getterProcedureMeasuresAsArrayByLc(printjobInfoObj.lang)
            .filter((el) => el.id === measure_id)[0];

          measureObj.controls.forEach((control_id) => {
            // console.debug("Measure control - Tech", control_id);
            printjobInfoObj.data.procedure.measures_org_display[
              control_id
            ].push(measureObj.display_printtext);
          });
        });

        //
        // Non Standard measures - Merge and filter duplicates by using Set
        //

        //
        // Tech
        //
        var measures_other_tech_obj = [
          ...printjobInfoObj.data.procedure.common.tech_procedures_other,
          ...printjobInfoObj.data.procedure.responsibility
            .tech_procedures_other,
          ...printjobInfoObj.data.procedure.datainput.tech_procedures_other,
          ...printjobInfoObj.data.procedure.datastorage.tech_procedures_other,
          ...printjobInfoObj.data.procedure.datatransfer.tech_procedures_other,
          ...printjobInfoObj.data.procedure.datamanagement
            .tech_procedures_other,
        ];

        // console.debug("Other - Measures - Tech", measures_other_tech_obj);

        measures_other_tech_obj.forEach((measure) => {
          // console.debug("Other Measure - Tech", measure);

          printjobInfoObj.data.procedure.measures_tech_display[
            measure.control_id
          ].push(measure.name);
        });

        //
        // Org
        //
        var measures_other_org_obj = [
          ...printjobInfoObj.data.procedure.common.org_procedures_other,
          ...printjobInfoObj.data.procedure.responsibility.org_procedures_other,
          ...printjobInfoObj.data.procedure.datainput.org_procedures_other,
          ...printjobInfoObj.data.procedure.datastorage.org_procedures_other,
          ...printjobInfoObj.data.procedure.datatransfer.org_procedures_other,
          ...printjobInfoObj.data.procedure.datamanagement.org_procedures_other,
        ];

        // console.debug("Other - Measures - Org", measures_other_org_obj);

        measures_other_org_obj.forEach((measure) => {
          // console.debug("Other Measure - Org", measure);
          printjobInfoObj.data.procedure.measures_org_display[
            measure.control_id
          ].push(measure.name);
        });

        // console.debug("actionGenerateActivityDocReport - printjobInfoObj", printjobInfoObj);

        return await dispatch("PrintManager/actionGeneratePrintDocument", printjobInfoObj, { root: true });
      } catch (err) {
        console.error("actionGenerateProcedureReport", err);
        return false;
      }
    },
    //assign procedure
    async actionAssignProcedure(
      { state, getters, rootGetters, dispatch, commit },
      assigndataObj
    ) {
      try {
        // console.debug("actionAssignProcedure - assigndataObj", assigndataObj);
        var procedureObj = await dispatch(
          "actionReceiveCurrentProcedureDataById",
          assigndataObj.key_id
        );

        if (procedureObj === false) throw "Error reading procedure by id";

        procedureObj.assigned_to = assigndataObj.newassigned_to;
        procedureObj.changenotes = assigndataObj.newnotes;
        procedureObj.changetype_id = assigndataObj.changetype_id;

        const result = await dispatch("actionSaveProcedure", procedureObj);

        if (result === false)
          throw "Error saving procedure assignto change";

        commit("PROCEDURE_ASSIGNED", procedureObj);

        return result;
      } catch (err) {
        console.error("actionAssignProcedure - Error : ", err);
        return false;
      }
    },
  },
  getters: {
    getterEmptyProcedureRecord: (state) => {
      return { ...PROCEDURE_RECORD };
    },
    getterProcedureStageDraft: (state) => {
      return PROCEDURESTAGE_LIST["ID_PROCEDURESTAGE_DRAFT"].stage;
    },
    getterProcedureStageReview: (state) => {
      return PROCEDURESTAGE_LIST["ID_PROCEDURESTAGE_REVIEW"].stage;
    },
    getterProcedureStageApproved: (state) => {
      return PROCEDURESTAGE_LIST["ID_PROCEDURESTAGE_APPROVED"].stage;
    },
    getterProcedureNextStageAllowed: (state, getters, rootState, rootGetters) => (procedureDataObj) => {
      let ballowstate = false;

      try {
        if (procedureDataObj === undefined) return ballowstate;

        // console.debug("getterProcedureNextStageAllowed", procedureDataObj);

        switch (procedureDataObj.stage) {
          case 1: // ID_PROCEDURESTAGE_DRAFT
          case 2: // ID_PROCEDURESTAGE_REVIEW
            ballowstate =
              procedureDataObj.core.valid &&
              procedureDataObj.common.valid &&
              procedureDataObj.responsibility.valid &&
              procedureDataObj.datainput.valid &&
              procedureDataObj.datatransfer.valid &&
              procedureDataObj.datastorage.valid &&
              procedureDataObj.datamanagement.valid &&
              procedureDataObj.valid;
            break;
          case 3: // ID_PROCEDURESTAGE_APPROVED
            ballowstate =
              procedureDataObj.core.valid &&
              procedureDataObj.common.valid &&
              procedureDataObj.responsibility.valid &&
              procedureDataObj.datainput.valid &&
              procedureDataObj.datatransfer.valid &&
              procedureDataObj.datastorage.valid &&
              procedureDataObj.datamanagement.valid &&
              procedureDataObj.valid;
            break;
        }
      } catch (error) {
        console.error("getterProcedureNextStageAllowed - Error : ", error);
      }

      return ballowstate;
    },
    getterProcedureToNextStage:
      (state, getters, rootState, rootGetters) => (procedureDataObj) => {
        try {
          if (procedureDataObj === undefined) return undefined;

          procedureDataObj.stagehistory.push({
            ...PROCEDURE_STAGEHISTORY_RECORD,
            stage: procedureDataObj.stage,
            revision: procedureDataObj.revision,
            created_at: procedureDataObj.updated_at,
            created_by: procedureDataObj.updated_by,
            finished_at: +new Date(),
            finished_by: rootGetters["SessionManager/getterCurrentUsername"],
          });

          // Increase stage by one
          if (procedureDataObj.stage <= getters.getterProcedureStageReview) {
            procedureDataObj.stage++;
            procedureDataObj.approved = false;
            procedureDataObj.changetype_id =
              "ID_TIMELINE_CHANGETYPE_STAGECHANGED";
            procedureDataObj.stagehistory[
              procedureDataObj.stagehistory.length - 1
            ].changetype_id = procedureDataObj.changetype_id;
          } else {
            if (procedureDataObj.active) {
              procedureDataObj.stage = getters.getterProcedureStageApproved;
              procedureDataObj.approved = true;
              procedureDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_CLOSED";
              procedureDataObj.stagehistory[
                procedureDataObj.stagehistory.length - 1
              ].changetype_id = procedureDataObj.changetype_id;
            } else {
              procedureDataObj.stage = getters.getterProcedureStageApproved;
              procedureDataObj.approved = true;
              procedureDataObj.changetype_id =
                "ID_TIMELINE_CHANGETYPE_INACTIVATE";
              procedureDataObj.stagehistory[
                procedureDataObj.stagehistory.length - 1
              ].changetype_id = procedureDataObj.changetype_id;
            }
          }
        } catch (error) {
          console.error(error);
          procedureDataObj = undefined;
        }

        return procedureDataObj;
      },
    getterProcedureIsApproved:
      (state, getters, rootState, rootGetters) => (procedureDataObj) => {
        let result = false;

        // console.debug("getterProcedureIsApproved", procedureDataObj);

        if (
          procedureDataObj &&
          Object.prototype.hasOwnProperty.call(procedureDataObj, "stage") &&
          Object.prototype.hasOwnProperty.call(procedureDataObj, "approved")
        ) {
          if (
            procedureDataObj.stage === getters.getterProcedureStageApproved &&
            procedureDataObj.approved === true
          )
            result = true;
        }

        return result;
      },
    getterProcedureNeedsApproval:
      (state, getters, rootState, rootGetters) => (procedureDataObj) => {
        let result = false;

        if (
          procedureDataObj &&
          Object.prototype.hasOwnProperty.call(procedureDataObj, "stage") &&
          Object.prototype.hasOwnProperty.call(procedureDataObj, "approved")
        ) {
          if (
            procedureDataObj.stage === getters.getterProcedureStageApproved &&
            procedureDataObj.approved === false
          )
            result = true;
        }

        return result;
      },
    getterProcedureReportData: (state, getters, rootState, rootGetters) => {
      const printjobInfoObj = {
        file: "procedure_report.odt",
        convertTo: "pdf",
        lang: rootGetters["TranslationManager/lang"].lc,
      };

      return printjobInfoObj;
    },
    getterProcedureScopeText:
      (state, getters, rootState, rootGetters) => (type_id, target_id) => {
        // console.debug("getterProcedureScopeText - type_id, target_id",type_id, target_id)
        return getters.getterProcedureScopeTextByLc(
          type_id,
          target_id,
          rootGetters["TranslationManager/getterLanguageAlpha2Code"]
        );
      },
    getterProcedureScopeTextByLc:
      (state, getters, rootState, rootGetters) => (type_id, target_id, lc) => {
        try {

          // console.debug("getterProcedureScopeTextByLc- target_id", target_id);

          var scopetext = rootGetters[
            "LookupManager/getterProcedureTargetsAsArrayByLc"
          ](lc).filter((item) => item.id === type_id)[0].display_name;

          // console.debug("getterProcedureScopeTextByLc- scopetext", scopetext);

          switch (type_id) {
            case "ID_PROCEDURETARGET_AREA":
              scopetext =
                scopetext +
                " - " +
                rootGetters["AreaManager/getterEntityAreaByIdByLc"](
                  target_id,
                  lc
                ).display_name;
              break;
            case "ID_PROCEDURETARGET_PROCESS":
              scopetext =
                scopetext +
                " - " +
                rootGetters[
                  "ProcessManager/getterEntityProcessByProcessIdByLc"
                ](target_id, lc).display_name;
              break;
            case "ID_PROCEDURETARGET_ACTIVITYDOC":
              var array = rootGetters["ActivityDocManager/getterActivityDocsAsArray"].filter(item => item.activitydoc_id == target_id);

              if (array.length == 1) {
                scopetext =
                  scopetext +
                  " - " +
                  array[0].name;
              }

              break;
            default:
              break;
          }

          return scopetext;
        } catch (error) {
          console.error("getterProcedureScopeTextByLc - Error : ", error);
          return "Unknown Target";
        }
      },
    getterProcedureMeasuresAsArray: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterProcedureMeasuresAsArrayByLc(
        rootGetters["TranslationManager/getterLanguageAlpha2Code"]
      );
    },
    getterProcedureAspectsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterProcedureAspectsAsArrayByLc(
        rootGetters["TranslationManager/getterLanguageAlpha2Code"]
      );
    },
    getterProcedureControlsAsArray: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterProcedureControlsAsArrayByLc(
        rootGetters["TranslationManager/getterLanguageAlpha2Code"]
      );
    },
    getterProcedureGroupsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterProcedureGroupsAsArrayByLc(
        rootGetters["TranslationManager/getterLanguageAlpha2Code"]
      );
    },
    getterProcedureControlsAsArrayByAspect: (
      state,
      getters,
      rootState,
      rootGetters
    ) => (aspect_id) => {
      return getters.getterProcedureControlsAsArray.filter(item => item.aspect == aspect_id);
    },
    getterProcedureMeasuresAsArrayByAspect: (
      state,
      getters,
      rootState,
      rootGetters
      ) => (aspect_id) => {
      return getters.getterProcedureMeasuresAsArrayByAspectByLc(aspect_id, rootGetters["TranslationManager/getterLanguageAlpha2Code"]);
    },
    getterProcedureMeasuresAsArrayByAspectByLc: (state, getters, rootState, rootGetters) => (aspect_id, lc) => {
      try {
        const controls = getters.getterProcedureControlsAsArrayByAspect(aspect_id);

        // console.debug("getterProcedureMeasuresAsArrayByAspectByLc - controls", controls);

        var array = [];

        for(const control in controls){
          array = [...getters.getterProcedureMeasuresAsArrayByLc(lc).filter(measure => measure.controls[0] == controls[control].id)]
        }

        // console.debug("getterProcedureMeasuresAsArrayByAspectByLc - filtered measures", array);

        return array;
      } catch (error) {
        console.error("getterProcedureMeasuresAsArrayByAspectByLc - Error", error);
        return [];
      }

    },
    getterProcedureAspectByControl: (
      state,
      getters,
      rootState,
      rootGetters
      ) => (control_id) => {
        const control = getters.getterProcedureControlsAsArray.filter(item => item.id == control_id)[0];
        const aspect = getters.getterProcedureAspectsAsArray.filter(item => item.id == control.aspect)[0];
      return aspect;
    },
    getterProcedureAspectsAsArrayByLc:
      (state, getters, rootState, rootGetters) => (lc) => {
        return rootGetters[
          "DBLookupManager/getterInternalLookupTranslateArrayByLc"
        ](state.PROCEDUREASPECT_LIST, state.PROCEDUREASPECT_I18N, lc, true);
      },
    getterProcedureControlsAsArrayByLc:
      (state, getters, rootState, rootGetters) => (lc) => {
        return rootGetters[
          "DBLookupManager/getterInternalLookupTranslateArrayByLc"
        ](state.PROCEDURECONTROL_LIST, state.PROCEDURECONTROL_I18N, lc, true);
      },
    getterProcedureGroupsAsArrayByLc:
      (state, getters, rootState, rootGetters) => (lc) => {
        return rootGetters[
          "DBLookupManager/getterInternalLookupTranslateArrayByLc"
        ](state.PROCEDUREGROUP_LIST, state.PROCEDUREGROUP_I18N, lc, false);
      },
    getterProcedureMeasuresAsArrayByLc:
      (state, getters, rootState, rootGetters) => (lc) => {
        return rootGetters[
          "DBLookupManager/getterInternalLookupTranslateArrayByLc"
        ](state.PROCEDUREMEASURE_LIST, state.PROCEDUREMEASURE_I18N, lc, true);
      },
    getterProcedureEditAllowed:
      (state, getters, rootState, rootGetters) => (procedureDataObj) => {
        try {
          if (
            procedureDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(procedureDataObj, "stage") ||
            !Object.prototype.hasOwnProperty.call(procedureDataObj, "assigned_to")
          )
            throw "Procedure object is undefined";

          if (rootGetters["SessionManager/getterCurrentUsername"] != procedureDataObj.assigned_to)
            return false;

          return (procedureDataObj.stage <= getters.getterProcedureStageApproved);
        } catch (error) {
          console.error("getterProcedureEditAllowed - Error : ", error);
          return false;
        }
      },
    getterProcedureDeleteAllowed:
      (state, getters, rootState, rootGetters) => (procedureDataObj) => {
        try {
          if (
            procedureDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(procedureDataObj, "assigned_to") ||
            !Object.prototype.hasOwnProperty.call(procedureDataObj, "revision") ||
            !Object.prototype.hasOwnProperty.call(procedureDataObj, "stage")
          )
            throw "Procedure object is undefined";

          if (rootGetters["SessionManager/getterCurrentUsername"] != procedureDataObj.assigned_to)
            return false;

          return (procedureDataObj.stage <= getters.getterProcedureStageReview && procedureDataObj.revision <= 1);
        } catch (error) {
          console.error("getterprocedureDeleteAllowed - Error : ", error);
          return false;
        }
      },
  },
};
