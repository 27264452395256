<template>
  <ModalDialogLayout
    persistent 
    :dialog.sync="isDialogActive"
    :width="maxWidth"
    :max-width="maxWidth"
    :content-class="contentClass"  
    :showClose="false"    
  >
    <template v-slot:title>
      {{ title }}
    </template>

    <template v-slot:subtitle>
      <v-row>
        <v-col cols="12" align="center" justify="center" class="mt-4">
          <div class="message-text">
            {{ message }}
          </div>
        </v-col>
      </v-row>
    </template>      

    <template v-slot:content>
      <v-row>
        <v-col cols="12" align="center" justify="center" class="pa-6">
          <v-progress-circular
            :size="70"
            :width="7"
            color="blue darken-1"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>   
    </template>
  </ModalDialogLayout>
</template>

<script>

import ModalDialogLayout from "@/layouts/ModalDialogLayout";

export default {
  name: "ProgressModalDialog",
  components: {
    ModalDialogLayout
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      required: false,
      default: "40%"
    },
    contentClass: {
      required: false,
      default: "no-scroll"
    }    
  },
  methods: {
  },
  computed: {
    isDialogActive: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      }
    },     
  },
  watch: {
    isDialogActive: {
      handler: function(new_data) {
         if (new_data === true) {
           this.$forceUpdate();
        }
      }
    }
  }
}
</script>

<style scoped>
.message-text {
  font-size: 115%;
  white-space: pre-line;
}
</style>
