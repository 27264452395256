export const DOCUMENTTYPE_I18N_KEY = "document_type.";

export const DOCUMENTTYPE_LIST = {
    "ID_DOCUMENTTYPE_ACCESSRULES": {
      "name": "DOCUMENTTYPE_ACCESSRULES_LABEL",
      "description":"DOCUMENTTYPE_ACCESSRULES_DESCRIPTION"
    },  
    "ID_DOCUMENTTYPE_DATACOLLECTION": {
      "name": "DOCUMENTTYPE_DATACOLLECTION_LABEL",
      "description":"DOCUMENTTYPE_DATACOLLECTION_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_DATAPROCESSINGCONTRACT": {
      "name": "DOCUMENTTYPE_DATAPROCESSINGCONTRACT_LABEL",
      "description": "DOCUMENTTYPE_DATAPROCESSINGCONTRACT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_REPORT": {
      "name": "DOCUMENTTYPE_REPORT_LABEL",
      "description": "DOCUMENTTYPE_REPORT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_RISKEVAULATION": {
      "name": "DOCUMENTTYPE_RISKEVAULATION_LABEL",
      "description": "DOCUMENTTYPE_RISKEVAULATION_DESCRIPTION"
    },    
    "ID_DOCUMENTTYPE_TRAININGCERTIFICATE": {
      "name": "DOCUMENTTYPE_TRAININGCERTIFICATE_LABEL",
      "description": "DOCUMENTTYPE_TRAININGCERTIFICATE_DESCRIPTION"
    }, 
    "ID_DOCUMENTTYPE_TRAININGMATERIAL": {
      "name": "DOCUMENTTYPE_TRAININGMATERIAL_LABEL",
      "description": "DOCUMENTTYPE_TRAININGMATERIAL_DESCRIPTION"
    },        
    "ID_DOCUMENTTYPE_AUDITDOCUMENTATION": {
      "name": "DOCUMENTTYPE_AUDITDOCUMENTATION_LABEL",
      "description": "DOCUMENTTYPE_AUDITDOCUMENTATION_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_JOINTCONTROLLERCONTRACT": {
      "name": "DOCUMENTTYPE_JOINTCONTROLLERCONTRACT_LABEL",
      "description": "DOCUMENTTYPE_JOINTCONTROLLERCONTRACT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_DATAPROTECTIONGRANT": {
      "name": "DOCUMENTTYPE_DATAPROTECTIONGRANT_LABEL",
      "description": "DOCUMENTTYPE_DATAPROTECTIONGRANT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_CERTIFICATE": {
      "name": "DOCUMENTTYPE_CERTIFICATE_LABEL",
      "description": "DOCUMENTTYPE_CERTIFICATE_DESCRIPTION"
    },    
    "ID_DOCUMENTTYPE_SLACONTRACT": {
      "name": "DOCUMENTTYPE_SLACONTRACT_LABEL",
      "description": "DOCUMENTTYPE_SLACONTRACT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_ITSECURITYDOCUMENT": {
      "name": "DOCUMENTTYPE_ITSECURITYDOCUMENT_LABEL",
      "description": "DOCUMENTTYPE_ITSECURITYDOCUMENT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_PROCESSDOCUMENT": {
      "name": "DOCUMENTTYPE_PROCESSDOCUMENT_LABEL",
      "description": "DOCUMENTTYPE_PROCESSDOCUMENT_DESCRIPTION"
    },  
    "ID_DOCUMENTTYPE_PROCESSMODEL": {
      "name": "DOCUMENTTYPE_PROCESSMODEL_LABEL",
      "description": "DOCUMENTTYPE_PROCESSMODEL_DESCRIPTION"
    }, 
    "ID_DOCUMENTTYPE_INCIDENTREPORT": {
      "name": "DOCUMENTTYPE_INCIDENTREPORT_LABEL",
      "description": "DOCUMENTTYPE_INCIDENTREPORT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_RISKREPORT": {
      "name": "DOCUMENTTYPE_RISKREPORT_LABEL",
      "description": "DOCUMENTTYPE_RISKREPORT_DESCRIPTION"
    },    
    "ID_DOCUMENTTYPE_INDIVIDUALNOTIFICATION": {
      "name": "DOCUMENTTYPE_INDIVIDUALNOTIFICATION_LABEL",
      "description": "DOCUMENTTYPE_INDIVIDUALNOTIFICATION_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_RISKASSESSMENT": {
      "name": "DOCUMENTTYPE_RISKASSESSMENT_LABEL",
      "description": "DOCUMENTTYPE_RISKASSESSMENT_DESCRIPTION"
    },                             
    "ID_DOCUMENTTYPE_GDPRHANDBOOK": {
      "name": "DOCUMENTTYPE_GDPRHANDBOOK_LABEL",
      "description": "DOCUMENTTYPE_GDPRHANDBOOK_DESCRIPTION"
    },                             
    "ID_DOCUMENTTYPE_GDPRGUIDELINE": {
      "name": "DOCUMENTTYPE_GDPRGUIDELINE_LABEL",
      "description": "DOCUMENTTYPE_GDPRGUIDELINE_DESCRIPTION"
    },                             
    "ID_DOCUMENTTYPE_SECURITYGUIDELINE": {
      "name": "DOCUMENTTYPE_SECURITYGUIDELINE_LABEL",
      "description": "DOCUMENTTYPE_SECURITYGUIDELINE_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_REQUESTRESULT": {
      "name": "DOCUMENTTYPE_REQUESTRESULT_LABEL",
      "description": "DOCUMENTTYPE_REQUESTRESULT_DESCRIPTION"
    }, 
    "ID_DOCUMENTTYPE_REQUESTREPORT": {
      "name": "DOCUMENTTYPE_REQUESTREPORT_LABEL",
      "description": "DOCUMENTTYPE_REQUESTREPORT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_PROCESSACTIVITY": {
      "name": "DOCUMENTTYPE_PROCESSACTIVITY_LABEL",
      "description": "DOCUMENTTYPE_PROCESSACTIVITY_DESCRIPTION"
    }, 
    "ID_DOCUMENTTYPE_CONCEPT": {
      "name": "DOCUMENTTYPE_CONCEPT_LABEL",
      "description": "DOCUMENTTYPE_CONCEPT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_PROOFDOCUMENT": {
      "name": "DOCUMENTTYPE_PROOFDOCUMENT_LABEL",
      "description": "DOCUMENTTYPE_PROOFDOCUMENT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_TOM": {
      "name": "DOCUMENTTYPE_TOM_LABEL",
      "description": "DOCUMENTTYPE_TOM_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_DSFA": {
      "name": "DOCUMENTTYPE_DSFA_LABEL",
      "description": "DOCUMENTTYPE_DSFA_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_ONLINEPRIVACY": {
      "name": "DOCUMENTTYPE_ONLINEPRIVACY_LABEL",
      "description": "DOCUMENTTYPE_ONLINEPRIVACY_DESCRIPTION"
    }, 
    "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT": {
      "name": "DOCUMENTTYPE_ADDITIONALDOCUMENT_LABEL",
      "description": "DOCUMENTTYPE_ADDITIONALDOCUMENT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_HANDOUTDOCUMENT": {
      "name": "DOCUMENTTYPE_HANDOUTDOCUMENT_LABEL",
      "description": "DOCUMENTTYPE_HANDOUTDOCUMENT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_CONSENT": {
      "name": "DOCUMENTTYPE_CONSENT_LABEL",
      "description": "DOCUMENTTYPE_CONSENT_DESCRIPTION"    
    },
    "ID_DOCUMENTTYPE_PRIVACYINFORMATION": {
      "name": "DOCUMENTTYPE_PRIVACYINFORMATION_LABEL",
      "description": "DOCUMENTTYPE_PRIVACYINFORMATION_DESCRIPTION" 
    },
    "ID_DOCUMENTTYPE_COMMITMENTTOCONFIDENTIALITY": {
      "name": "DOCUMENTTYPE_COMMITMENTTOCONFIDENTIALITY_LABEL",
      "description": "DOCUMENTTYPE_COMMITMENTTOCONFIDENTIALITY_DESCRIPTION" 
    },
    "ID_DOCUMENTTYPE_INTERNALINSTRUCTION": {
      "name": "DOCUMENTTYPE_INTERNALINSTRUCTION_LABEL",
      "description": "DOCUMENTTYPE_INTERNALINSTRUCTION_DESCRIPTION" 
    },
    "ID_DOCUMENTTYPE_DATASUBJECTREQUEST": {
      "name": "DOCUMENTTYPE_DATASUBJECTREQUEST_LABEL",
      "description": "DOCUMENTTYPE_DATASUBJECTREQUEST_DESCRIPTION" 
    },
    "ID_DOCUMENTTYPE_ADDITIONALAGREEMENTONDATAPROCESSINGCONTRACT": {
      "name": "DOCUMENTTYPE_ADDITIONALAGREEMENTONDATAPROCESSINGCONTRACT_LABEL",
      "description": "DOCUMENTTYPE_ADDITIONALAGREEMENTONDATAPROCESSINGCONTRACT_DESCRIPTION"
    },
    "ID_DOCUMENTTYPE_DPO_CONTRACT": {
      "name": "DOCUMENTTYPE_DPO_CONTRACT_LABEL",
      "description": "DOCUMENTTYPE_DPO_CONTRACT_DESCRIPTION"
    },    
    "ID_DOCUMENTTYPE_DPO_AUTHORITYCOMMISSION": {
      "name": "DOCUMENTTYPE_DPO_AUTHORITYCOMMISSION_LABEL",
      "description": "DOCUMENTTYPE_DPO_AUTHORITYCOMMISSION_DESCRIPTION"
    }, 
    "ID_DOCUMENTTYPE_DPO_AUTHORITYDECOMMISSION": {
      "name": "DOCUMENTTYPE_DPO_AUTHORITYDECOMMISSION_LABEL",
      "description": "DOCUMENTTYPE_DPO_AUTHORITYDECOMMISSION_DESCRIPTION"
    }, 
    "ID_DOCUMENTTYPE_OTHER": {
      "name": "DOCUMENTTYPE_OTHER_LABEL",
      "description": "DOCUMENTTYPE_OTHER_DESCRIPTION"
    }
};
