import backendDataApi from '@/services/backendDataApi.service.js';

import CommonUtils from '@/utils/common.utils.js';

import {
  INITDATAORIGIN_DOCUMENT_NAME,
} from '@/config/config.couchdb';

import {
  ENTITYTYPE_DOCUMENT_NAME
} from '@/models/entity_types.couchdb';

import {
  ENTITYSECTOR_DOCUMENT_NAME
} from '@/models/entity_sectors.couchdb';

import {
  ENTITYCLASS_DOCUMENT_NAME
} from '@/models/entity_classes.couchdb';

import {
  ENTITYPATTERN_DOCUMENT_NAME
} from '@/models/entity_patterns.couchdb';

export default {
  namespaced: true,
  state: {
    // Entity base lookup data
    ENTITYTYPE_I18N: {},
    ENTITYTYPE_I18N_KEY: "",
    ENTITYTYPE_LIST: {},

    ENTITYCLASS_I18N: {},
    ENTITYCLASS_I18N_KEY: "",
    ENTITYCLASS_LIST: {},

    ENTITYPATTERN_I18N: {},
    ENTITYPATTERN_I18N_KEY: "",
    ENTITYPATTERN_LIST: {},

    ENTITY_CLASS_PATTERN_MAP: {},

    // Lecacy for old contract partners
    ENTITYSECTOR_I18N: {},
    ENTITYSECTOR_I18N_KEY: "",
    ENTITYSECTOR_LIST: {},

    DATAORIGIN_LIST: {},
    DATAORIGIN_I18N_KEY: '',
    DATAORIGIN_I18N: {},
  },
  mutations: {

    ENTITYTYPE_LOAD(state, payload) {
      // console.debug("ENTITYTYPE_LOAD - payload", payload);

      state.ENTITYTYPE_I18N_KEY = payload.entity_types.I18N_KEY;
      state.ENTITYTYPE_I18N = { ...payload.entity_types.I18N};
      state.ENTITYTYPE_LIST = { ...payload.entity_types.LIST };

      // console.debug("ENTITYTYPE_LOAD - state", state);
    },
    ENTITYCLASS_LOAD(state, payload) {
      // console.debug("ENTITYCLASS_LOAD - payload", payload);

      state.ENTITYCLASS_I18N_KEY = payload.entity_classes.I18N_KEY;
      state.ENTITYCLASS_I18N = { ...payload.entity_classes.I18N };
      state.ENTITYCLASS_LIST = { ...payload.entity_classes.LIST };

      // console.debug("ENTITYCLASS_LOAD - state", state);
    },
    ENTITYPATTERN_LOAD(state, payload) {
      // console.debug("ENTITYPATTERN_LOAD - payload", payload);

      state.ENTITYPATTERN_I18N_KEY = payload.entity_patterns.I18N_KEY;
      state.ENTITYPATTERN_I18N = { ...payload.entity_patterns.I18N };
      state.ENTITYPATTERN_LIST = { ...payload.entity_patterns.LIST };

      state.ENTITY_CLASS_PATTERN_MAP = { ...payload.entity_class_pattern_map };

      // console.debug("ENTITYPATTERN_LOAD - state", state);
    },
    ENTITYSECTOR_LOAD(state, payload) {
      // console.debug("ENTITYSECTOR_LOAD - payload", payload);

      state.ENTITYSECTOR_I18N_KEY = payload.entity_sectors.I18N_KEY;
      state.ENTITYSECTOR_I18N = { ...payload.entity_sectors.I18N};
      state.ENTITYSECTOR_LIST = { ...payload.entity_sectors.LIST };

      // console.debug("ENTITYSECTOR_LOAD - state", state);
    },
    INITDATAORIGIN_LOAD(state, payload) {

      state.DATAORIGIN_I18N_KEY = payload.data_origins.I18N_KEY;
      state.DATAORIGIN_I18N = {
        ...payload.data_origins.I18N
      };
      state.DATAORIGIN_LIST = {
        ...payload.data_origins.LIST
      };
    }
  },
  actions: {
    //
    // Public
    //

    // load entity_types doc from couchDB
    async actionRetrieveEntityTypesDoc({
      state,
      rootGetters,
      dispatch,
      commit
    }) {
      try
      {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(ENTITYTYPE_DOCUMENT_NAME);

        // console.debug("actionRetrieveEntityTypesDoc - doc", doc);

        if(doc === false)
          throw "Error retrieving Entity Type Data";

        commit('ENTITYTYPE_LOAD', doc);
        return true;
      }
      catch(err)
      {
        console.error('actionRetrieveEntityTypesDoc - Error : ', err);
        return false;
      }
    },
    // load entity_types doc from couchDB
    async actionRetrieveEntitySectorsDoc({
      state,
      rootGetters,
      dispatch,
      commit
    }) {
      try
      {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(ENTITYSECTOR_DOCUMENT_NAME);

        // console.debug("actionRetrieveEntitySectorsDoc - doc", doc);

        if(doc === false)
          throw "Error retrieving Entity Sector Data";

        commit('ENTITYSECTOR_LOAD', doc);
        return true;
      }
      catch(err)
      {
        console.error('actionRetrieveEntitySectorsDoc - Error : ', err);
        return false;
      }
    },
    // load entity_classes doc from couchDB
    async actionRetrieveEntityClassesDoc({
      state,
      rootGetters,
      dispatch,
      commit
    }) {
      try
      {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(ENTITYCLASS_DOCUMENT_NAME);

        // console.debug("actionRetrieveEntityClassesDoc - doc", doc);

        if(doc === false)
          throw "Error retrieving Entity Class Data";

        commit('ENTITYCLASS_LOAD', doc);
        return true;
      }
      catch(err)
      {
        console.error('actionRetrieveEntityClassesDoc - Error : ', err);
        return false;
      }
    },
    // load entity pattern doc from couchDB
    async actionRetrieveEntityPatternsDoc({
      state,
      rootGetters,
      dispatch,
      commit
    }) {
      try
      {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(ENTITYPATTERN_DOCUMENT_NAME);

        // console.debug("actionRetrieveEntityPatternsDoc - doc", doc);

        if(doc === false)
          throw "Error retrieving Entity Pattern Data";

        commit('ENTITYPATTERN_LOAD', doc);
        return true;
      }
      catch(err)
      {
        console.error('actionRetrieveEntityPatternsDoc - Error : ', err);
        return false;
      }
    },

    // load subject init doc from couchDB
    async actionRetrieveInitDataOriginDoc({
      state,
      rootGetters,
      dispatch,
      commit
    }) {
      try {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(INITDATAORIGIN_DOCUMENT_NAME);

        // console.debug("actionRetrieveInitDataOriginDoc - doc", doc);
        if (doc === false)
          throw "Error retrieving Origin Data";

        commit('INITDATAORIGIN_LOAD', doc);
        return true;
      } catch (err) {
        console.error('actionRetrieveInitDataOriginDoc - Error : ', err);
        return false;
      }
    }
  },
  getters: {
    // Translations based on current lang
    getterEntityTypesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterEntityTypesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterEntityClassesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterEntityClassesAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterEntitySectorsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterEntitySectorsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterEntityPatternIdsAsArray: (state, getters, rootState, rootGetters) => {
      return Object.keys(state.ENTITYPATTERN_LIST);
    },
    getterEntityPatternsAsArrayByCountryByClassId: (state, getters, rootState, rootGetters) => (countrycode, entityclassid) => {
      return getters.getterEntityPatternsAsArrayByCountryByClassIdByLc(countrycode, entityclassid, rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterDataOriginsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDataOriginsAsArrayByLc(rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    //
    // Language parameter version
    //

    //
    // entity data
    //

    getterEntityTypesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterInternalLookupTranslateArrayByLc(state.ENTITYTYPE_LIST, state.ENTITYTYPE_I18N, lc, true, "sequence");
    },

    getterEntityClassesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterInternalLookupTranslateArrayByLc(state.ENTITYCLASS_LIST, state.ENTITYCLASS_I18N, lc, true, "sequence");
    },

    getterEntitySectorsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterInternalLookupTranslateArrayByLc(state.ENTITYSECTOR_LIST, state.ENTITYSECTOR_I18N, lc, true, "display_name");
    },

    getterEntityPatternsAsArrayByCountryByClassIdByLc: (state, getters, rootState, rootGetters) => (countrycode, entityclassid, lc) => {
      try {
        // console.debug("getterEntityPatternsAsArrayByCountryByClassIdByLc - entityclassid", entityclassid);
        // console.debug("getterEntityPatternsAsArrayByCountryByClassIdByLc - countrycode", countrycode);

        if(CommonUtils.isStringEmpty(entityclassid) || CommonUtils.isStringEmpty(countrycode) || CommonUtils.isStringEmpty(lc))
          throw "All parameter must be set";

        const initmap =
          (Object.prototype.hasOwnProperty.call(state.ENTITY_CLASS_PATTERN_MAP, countrycode) === true) ?
          state.ENTITY_CLASS_PATTERN_MAP[countrycode] : state.ENTITY_CLASS_PATTERN_MAP.default;

        // console.debug("getterEntityPatternsAsArrayByCountryByClassIdByLc - state map : ", state.ENTITY_TYPE_PATTERN_MAP);
        // console.debug("getterEntityPatternsAsArrayByCountryByClassIdByLc - state initmap : ", initmap);

        return getters.getterInternalLookupTranslateWithMapArrayByLc(entityclassid, initmap, state.ENTITYPATTERN_LIST, state.ENTITYPATTERN_I18N, lc, true);
      }
      catch(err)
      {
        console.error("getterEntityPatternsAsArrayByCountryByClassIdByLc - Error", err);
        return [];
      }
    },

    //
    // Data init docs
    //
    getterDataOriginsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      // returns an array of data subjects objects with translations
      return getters.getterInternalLookupTranslateArrayByLc(state.DATAORIGIN_LIST, state.DATAORIGIN_I18N, lc, true);
    },

    ////////////////////////////////////////////////////////////

    getterInternalLookupTranslateArrayByLc: (state, getters, rootState, rootGetters) => (srcarray, i18nobj, lc, sortflag, sortcolumn = "display_name") => {
      // returns an array of data objects with translations
      var array = [];
      var sortColumnExits = true;

      try {
        if (CommonUtils.isObjectEmpty(i18nobj))
          return array;

        if (!Object.prototype.hasOwnProperty.call(i18nobj, "languages"))
          return array;

        // console.debug("getterInternalLookupTranslateArrayByLc - lc", lc);

        if (i18nobj.languages.indexOf(lc) === -1)
          lc = i18nobj.defaultlang;

        // console.debug("getterInternalLookupTranslateArrayByLc", i18nobj.translations);

        for (const item in srcarray) {
          var obj = {
            ...srcarray[item],
            id: item,
            display_name: i18nobj['translations'][lc][srcarray[item]['name']],
            display_description: i18nobj['translations'][lc][srcarray[item]['description']]
          };

          if (Object.prototype.hasOwnProperty.call(obj, 'printtext') == true) {
            obj.display_printtext = i18nobj['translations'][lc][srcarray[item]['printtext']];
          }

          array.push(obj);

          if(sortColumnExits === true)
            sortColumnExits = Object.prototype.hasOwnProperty.call(obj, sortcolumn);
        }

        // Sort A-Z based on sortcolumn
        if (sortflag && sortColumnExits) {
          array.sort(CommonUtils.getArrayOfObjectDynamicSort(sortcolumn));
        }

      } catch (err) {
        console.error("getterInternalLookupTranslateArrayByLc", err);
        array = [];
      }

      return array;
    },

    getterInternalLookupTranslateWithMapArrayByLc: (state, getters, rootState, rootGetters) => (maprefid, maparray, srcarray, i18nobj, lc, sortflag, sortcolumn = "display_name") => {

      var array = [];
      var sortColumnExits = true;

      try {
        if (CommonUtils.isObjectEmpty(i18nobj))
          return array;

        if (!Object.prototype.hasOwnProperty.call(i18nobj, "languages"))
          return array;

        if (i18nobj.languages.indexOf(lc) === -1)
          lc = i18nobj.defaultlang;

        // console.debug("getterInternalLookupTranslateWithMapArrayByLc", i18nobj.translations);

        if (maprefid && maparray[maprefid] !== undefined) {
          for (const item of maparray[maprefid]) {

            // console.debug("getterInternalLookupTranslateWithMapArray - srcarray[item] : ", srcarray[item]);

            let obj = {
              ...srcarray[item],
              id: item,
              display_name: i18nobj['translations'][lc][srcarray[item]['name']],
              display_description: i18nobj['translations'][lc][srcarray[item]['description']]
            };

            if (Object.prototype.hasOwnProperty.call(obj, 'printtext') == true) {
              obj.display_printtext = i18nobj['translations'][lc][srcarray[item]['printtext']];
            }

            array.push(obj);

            if(sortColumnExits === true)
            sortColumnExits = Object.prototype.hasOwnProperty.call(obj, sortcolumn);
          }

          // Sort A-Z based on sortcolumn
          if (sortflag && sortColumnExits) {
            array.sort(CommonUtils.getArrayOfObjectDynamicSort(sortcolumn));
          }
        }
      } catch (err) {
        console.error("getterInternalLookupTranslateWithMapArrayByLc", err);
        array = [];
      }

      return array;
    }
  }
};