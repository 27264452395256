import backendDataApi from '@/services/backendDataApi.service.js';
import CommonUtils from '@/utils/common.utils.js';

import {DEFAULT_FOUNDATION_DATE, INITIAL_DOCUMENTATION_START_DATE} from '@/config/config.constants';

import merge from 'lodash.merge';

import {
  ENTITY_DOCUMENT_NAME,
  ENTITYDATA_RECORD,
  ENTITYDATA_SCHEMA_VERSION,
  DEFAULT_ENTITY_PATTERN,
  DEFAULT_ENTITY_CLASS
} from '@/models/entity.couchdb';

export default {
  namespaced: true,
  state: {
    EntityInformation: {
      EntityData: {},
      EntityLogo: {
        imageData: false
      }
    }
  },
  mutations: {
    ENTITY_LOAD(state, payload) {
      // console.debug("ENTITY_LOAD - payload", payload);

      merge(
        state.EntityInformation,
        {
          EntityLogo: {
            imageData: false
          },
          EntityData: {
            ...ENTITYDATA_RECORD
          }
        },
        payload
      );

      // console.debug("ENTITY_LOAD - payload", payload);
      // console.debug("ENTITY_LOAD - ENTITYDATA_RECORD", ENTITYDATA_RECORD);
      // console.debug("ENTITY_LOAD - state.EntityInformation", state.EntityInformation);
    },
    ENTITY_DATA_SAVE(state, payload) {
      // console.debug("ENTITY_DATA_SAVE - payload", payload);
      state.EntityInformation.EntityData = payload;
    },
    ENTITY_LOGO_SAVE(state, payload) {
      // console.debug("ENTITY_LOGO_SAVE - payload", payload);
      state.EntityInformation.EntityLogo = payload;
    }
  },
  actions: {
    // load entity doc from couchDB
    async actionRetrieveEntityDoc({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }) {
      try
      {
        var doc = await backendDataApi.readDocumentByDocIdFromBackend(ENTITY_DOCUMENT_NAME);

        // console.debug("actionRetrieveEntityDoc - doc", doc);

        if(doc === false)
          throw "Error retrieving Entity Data";

        if(Object.prototype.hasOwnProperty.call(doc, "EntityInformation") == true) {
          // prefill dates
          if(Object.prototype.hasOwnProperty.call(doc.EntityInformation.EntityData.EntityDetails, "initialdefault_date") == false) {
            doc.EntityInformation.EntityData.EntityDetails.initialdefault_date = INITIAL_DOCUMENTATION_START_DATE;
          }

          if(Object.prototype.hasOwnProperty.call(doc.EntityInformation.EntityData.EntityDetails, "foundation_date") == false) {
            doc.EntityInformation.EntityData.EntityDetails.foundation_date = DEFAULT_FOUNDATION_DATE;
          }
        }

        var entityInformationObj = await dispatch("actionUpgradeDataObject", doc.EntityInformation) ;

        // console.debug("actionRetrieveEntityDoc - entityInformationObj", entityInformationObj);

        commit('ENTITY_LOAD', entityInformationObj);

        return true;
      }
      catch(err)
      {
        console.error('actionRetrieveEntityDoc - Error : ', err);
        return false;
      }
    },
    // Data object upgrade function & migration based on version
    async actionUpgradeDataObject(
      {state, getters, rootGetters, commit, dispatch},
      dataObject
    ) {
      if(rootGetters["getterIsFirstRun"])
        return;

      // Safe guard unnecessary migration based on schema version
      if((Object.prototype.hasOwnProperty.call(dataObject, "version"))) {
        if(dataObject.version === ENTITYDATA_SCHEMA_VERSION)
          return dataObject;
      }

      var newEntityInformationObj = merge({}, dataObject);

      newEntityInformationObj.EntityData = merge({}, getters.getterEmptyEntityDataRecord, dataObject.EntityData);

      newEntityInformationObj.version = ENTITYDATA_SCHEMA_VERSION;

      if(Object.prototype.hasOwnProperty.call(dataObject.EntityData.EntityDetails, "entity_class") == false) {
        newEntityInformationObj.EntityData.EntityDetails.entity_class = DEFAULT_ENTITY_CLASS;
      }
      else {
        if(dataObject.EntityData.EntityDetails.entity_class == "")
          newEntityInformationObj.EntityData.EntityDetails.entity_class = DEFAULT_ENTITY_CLASS;
      }

      if(Object.prototype.hasOwnProperty.call(dataObject.EntityData, "EntityInitOptions") == false) {
        newEntityInformationObj.EntityData.EntityInitOptions = {
          ...getters.getterEmptyEntityDataRecord.EntityInitOptions
        };
      }

      if(CommonUtils.isStringEmpty(newEntityInformationObj.EntityData.EntityInitOptions.entity_pattern)  ) {
        if(Object.prototype.hasOwnProperty.call(newEntityInformationObj.EntityData.EntityDetails, "business_sector")) {
          // Migrate business sector to pattern
          var patternid = "ID_PATTERN_" + CommonUtils.extractNumberFromKeyId(newEntityInformationObj.EntityData.EntityDetails.business_sector);

          if(rootGetters["DBLookupManager/getterEntityPatternIdsAsArray"].indexOf(patternid) == -1)
              patternid = DEFAULT_ENTITY_PATTERN;

          newEntityInformationObj.EntityData.EntityInitOptions.entity_pattern = patternid;
          newEntityInformationObj.EntityData.EntityInitOptions.countrycode = newEntityInformationObj.EntityData.EntityInfo.country;
          newEntityInformationObj.EntityData.EntityInitOptions.regioncode = newEntityInformationObj.EntityData.EntityInfo.federal_state;
          newEntityInformationObj.EntityData.EntityInitOptions.entity_pattern = patternid;
        }
      }

      // console.debug("actionUpgradeDataObject In -  dataObject", dataObject);
      // console.debug("actionUpgradeDataObject Out - newEntityInformationObj", newEntityInformationObj);

      return newEntityInformationObj;
    },

    async actionSaveEntityInformationInBackend({
      state,
      getters,
      dispatch,
      commit
    }, payload) {
      try {
        // console.debug("actionSaveEntityInformationInBackend - payload", payload);
        return await backendDataApi.saveDocumentContentInBackendByDocId(ENTITY_DOCUMENT_NAME, payload);
      }
      catch(err)
      {
        console.error('actionSaveEntityInformationInBackend - Error : ', err);
        return false;
      }
    },
    async actionSaveEntityData({
      state,
      rootGetters,
      getters,
      dispatch,
      commit
    }, payload) {
      try {
        // console.debug("actionactionSaveEntityData - payload", payload);
        const contentObj = {
          EntityInformation: {
            ...state.EntityInformation,
            EntityData : {
              ...payload
            },
          },
          username: rootGetters["SessionManager/getterCurrentUsername"],
          timestamp: +new Date()
        };

        const result = await dispatch("actionSaveEntityInformationInBackend", contentObj);

        if( result == false)
          throw "Error saving entity data";

        commit('ENTITY_DATA_SAVE', payload);

        //  Sync contact and location data with entity
        await dispatch('LocationManager/actionUpdateLocationByEntityData', payload, {root:true});
        await dispatch('ContactBookManager/actionUpdatEntityContactsByEntityData', payload, {root:true});

        return true;
      }
      catch(err)
      {
        console.error('actionSaveEntityData - Error : ', err);
        return false;
      }
    },
    async actionSaveEntityLogo({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, payload) {
      try {
        // console.debug("actionactionSaveEntityLogo - payload", payload);
        const contentObj = {
          EntityInformation: {
            ...state.EntityInformation,
            EntityLogo : {
              ...payload
            },
          },
          username: rootGetters["SessionManager/getterCurrentUsername"],
          timestamp: +new Date()
        };

        const result = await dispatch("actionSaveEntityInformationInBackend", contentObj);

        if( result == false)
          throw "Error saving entity logo";

        commit('ENTITY_LOGO_SAVE', payload);

        return true;
      }
      catch(err)
      {
        console.error('actionSaveEntityData - Error : ', err);
        return false;
      }
    },
  },
  getters: {
    getterEmptyEntityDataRecord: () => {
      return { ...ENTITYDATA_RECORD };
    },
    getterEntityData: (state) => {
      return state.EntityInformation.EntityData;
    },
    getterEntityPatternID: (state) => {
      try {
        return state.EntityInformation.EntityData.EntityInitOptions.entity_pattern;
      } catch (err) {
        console.error("getterEntityPatternID - Error", err);
        return "";
      }
    },
    getterEntityLogo: (state) => {
      if (!state.EntityInformation.EntityLogo.imageData) {
        return { imageData: require("@/assets/defaultentitylogo.png") };
      } else {
        return state.EntityInformation.EntityLogo;
      }
    },
    getterEntityAllEmployeeCount: (state, getters) => {
      return getters.getterEntityAllInternalEmployeeCount + getters.getterEntityAllExternalEmployeeCount;
    },
    getterEntityAllInternalEmployeeCount: (state) => {
      return Number(state.EntityInformation.EntityData.EntityDetails.num_employees_fulltime) +
        Number(state.EntityInformation.EntityData.EntityDetails.num_employees_parttime) +
        Number(state.EntityInformation.EntityData.EntityDetails.num_employees_volunteer);
    },
    getterEntityAllExternalEmployeeCount: (state) => {
      return Number(state.EntityInformation.EntityData.EntityDetails.num_employees_external);
    },
    getterEntityAllEmployeeCountByDetail: (state, getters) => (entityDetailData) => {
      return (Number(entityDetailData.num_employees_fulltime) +
        Number(entityDetailData.num_employees_parttime) +
        Number(entityDetailData.num_employees_volunteer)+
        Number(entityDetailData.num_employees_external));
    },
    getterEntityCountriesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterAvailiableEntityCountriesAsArray;
    },
    getterAvailiableEntityCountriesAsArray: (state, getters, rootState, rootGetters) => {
      try {
        var countryarray = [...rootGetters["LookupManager/getterGDPRCountryList"]];

        countryarray = countryarray.concat(rootGetters["LookupManager/getterDPNonEUCountryList"]);

        // console.debug("getterAvailiableEntityCountriesAsArray - countryarray", countryarray);

        if (rootGetters['LicenseManager/getterLicenseData'].limits.entity.countries.indexOf("*") === -1)
          countryarray = countryarray.filter(item => rootGetters['LicenseManager/getterLicenseData'].limits.entity.countries.includes(item.value));

        // console.debug("getterAvailiableEntityCountriesAsArray - countryarray", countryarray);

        return countryarray;
      } catch (err) {
        console.error("getterAvailiableEntityCountriesAsArray - Error: ", err);
        return [];
      }
    },

    // Entity DB Lookup data
    getterEntityTypesByCountryAsArray: (state, getters, rootState, rootGetters) => (countrycode) => {
      var typesarray = [];

      try
      {
        rootGetters["DBLookupManager/getterEntityTypesAsArray"].forEach(typeitem => {
          // console.debug("getterEntityTypesAsArrayByCountry - typeitem: ", typeitem);

          if(typeitem.countries.indexOf("*") !== -1 || typeitem.countries.includes(countrycode)) {
            typesarray.push(typeitem);
          }
        });
      }
      catch(err) {
        console.error("getterEntityTypesAsArrayByCountry - Error: ", err);
      }

      return typesarray;
    },
    getterEntityClassesByCountryAsArray: (state, getters, rootState, rootGetters) => (countrycode) => {
      var classesarray = [];

      try
      {
        rootGetters["DBLookupManager/getterEntityClassesAsArray"].forEach(classitem => {
          // console.debug("getterEntityClassesAsArrayByCountry - classitem: ", classitem);
          if(classitem.countries.indexOf("*") !== -1 || classitem.countries.includes(countrycode)) {
            classitem.display_labelname = classitem.nace2_code + " - " + classitem.display_name;
            classesarray.push(classitem);
          }
        });
      }
      catch(err) {
        console.error("getterEntityClassesAsArrayByCountry - Error: ", err);
      }

      return classesarray;
    },
    getterEntityPatternsAsArrayByCountryByClassId: (state, getters, rootState, rootGetters) => (countrycode, entity_classid) => {
      try {
        // console.debug("getterEntityPatternsAsArrayByCountryByClassId - entityclassid", entity_classid);
        // console.debug("getterEntityPatternsAsArrayByCountryByClassId - countrycode", countrycode);

        if(CommonUtils.isStringEmpty(entity_classid) || CommonUtils.isStringEmpty(countrycode))
          throw "Invalid entitydata";

        return rootGetters["DBLookupManager/getterEntityPatternsAsArrayByCountryByClassIdByLc"](countrycode, entity_classid, rootGetters['TranslationManager/getterLanguageAlpha2Code']);
      }
      catch(err)
      {
        console.error("getterEntityPatternsAsArrayByCountryByClassId - Error", err);
        return [];
      }
    }
  }
};
