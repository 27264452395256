import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './translations';
import store from './store';
import vuetify from './plugins/vuetify';

import FlagIcon from 'vue-flag-icon';
import VueClipboard from 'vue-clipboard2';
import KnobControl from 'vue-knob-control';
import VueApexCharts from 'vue-apexcharts';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import VuetifyMoney from "vuetify-money";

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(FlagIcon);
Vue.use(VueClipboard);
Vue.use(KnobControl);
Vue.use(VueApexCharts);
Vue.use(VuetifyMoney);

import '@mdi/font/css/materialdesignicons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';

require('dayjs/locale/de');
require('dayjs/locale/en');

import Dayjs from 'vue-dayjs';

Vue.use(Dayjs, {
  lang: 'en', 
  filters: {
    customParseFormat: "customParseFormat", 
    ago: "ago", 
    utc: "utc", 
    isBefore: "isBefore",
    isAfter: "isAfter",
    isSameOrBefore: "isSameorBefore",
    isSameOrAfter: "isSameorAfter",    
    Timezone: "timezone"
  } 
});

import WorkBoxLayout from '@/layouts/WorkBoxLayout';
import BoxButtonLayout from '@/layouts/BoxButtonLayout';
import SimpleBoxLayout from '@/layouts/SimpleBoxLayout';

Vue.component('WorkBoxLayout', WorkBoxLayout);
Vue.component('BoxButtonLayout', BoxButtonLayout);
Vue.component('SimpleBoxLayout', SimpleBoxLayout);

Vue.component('KnobControl', KnobControl);
Vue.component('VueApexCharts', VueApexCharts);

import {
  Helpers
} from "@/mixins/helpers";

Vue.mixin(Helpers);

import {
  AppIcons
} from "@/mixins/appIcons";

Vue.mixin(AppIcons);

import {
  AlertMessage
} from "@/mixins/alertMessage";

Vue.mixin(AlertMessage);

Vue.config.productionTip = false;
Vue.config.devtools = (process.env.NODE_ENV === "development") || false;

import {
  mapActions
} from "vuex";

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App),
  data() {
    return {};
  },
  mounted() {
    // TODO
    // make a bettter UI for the blocking notification in the browser
    //
    // stop back
    history.pushState(null, null, location.href + "#safe");
    window.onpopstate = () => {
      this.showAlert = !this.showAlert;

      if (this.showAlert) {
        alert(this.$t("app.msg_use_software_navigation"));
      }
      history.go(1);
    };
    // prevent refresh
    window.onbeforeunload = () => {
      return this.$t("app.msg_use_software_navigation");
    };

    // detect browser language
    this.detectBrowserLanguage(navigator.language);
  },
  methods: {
    ...mapActions({
      detectBrowserLanguage: "TranslationManager/detectBrowserLanguage"
    }),
  },
}).$mount("#app");    
