  export const ASSETTARGET_I18N_KEY = "asset_targets.";

  export const ASSETTARGET_LIST = {
    "ID_ASSETTARGET_ENTITY": {
      "name": "ASSETTARGET_ENTITY_LABEL",
      "description": "ASSETTARGET_ENTITY_DESCRIPTION",
      "scope": "entity"
    },

    "ID_ASSETTARGET_AREA": {
      "name": "ASSETTARGET_AREA_LABEL",
      "description": "ASSETTARGET_AREA_DESCRIPTION",
      "scope": "area"
    },

    "ID_ASSETTARGET_PROCESS": {
      "name": "ASSETTARGET_PROCESS_LABEL",
      "description": "ASSETTARGET_PROCESS_DESCRIPTION",
      "scope": "process"
    },

    "ID_ASSETTARGET_ACTIVITYDOC": {
      "name": "ASSETTARGET_ACTIVITYDOC_LABEL",
      "description": "ASSETTARGET_ACTIVITYDOC_DESCRIPTION",
      "scope": "activitydoc"
    },

    "ID_ASSETTARGET_CUSTOM": {
      "name": "ASSETTARGET_CUSTOM_LABEL",
      "description": "ASSETTARGET_CUSTOM_DESCRIPTION",
      "scope": "custom"
    }
}
