export const DECOMMISSIONREASON_I18N_KEY = "decommission_reason.";

export const DECOMMISSIONREASON_LIST = {
    "ID_DECOMMISSIONREASON_SUPERSEEDED": {
      "name": "DECOMMISSIONREASON_SUPERSEEDED_LABEL",
      "description":"DECOMMISSIONREASON_SUPERSEEDED_DESCRIPTION"
    },
    "ID_DECOMMISSIONREASON_ARCHIVED": {
      "name": "DECOMMISSIONREASON_ARCHIVED_LABEL",
      "description":"DECOMMISSIONREASON_ARCHIVED_DESCRIPTION"
    },    
    "ID_DECOMMISSIONREASON_OTHER": {
      "name": "DECOMMISSIONREASON_OTHER_LABEL",
      "description": "DECOMMISSIONREASON_OTHER_DESCRIPTION"
    }
};
