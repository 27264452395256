//
// DSFA
//
import { STAGEHISTORY_DATA } from "@/config/config.couchdb.js";

export const DSFA_RECORD_TYPE = "dsfa";

export const DSFA_STAGEHISTORY_RECORD = {
    changetype_id: false,
    stage: 1,
    revision: 0,
    ...STAGEHISTORY_DATA
};

export const DSFA_SCHEMA_VERSION = 1;

export const DSFA_RECORD = {
  dsfa_id: undefined,

  stage: 1,
  revision: 0,

  version: DSFA_SCHEMA_VERSION,

  approved: false,

  assigned_to: "",

  changenotes: "",
  changetype_id: false,

  // Core
  core: {
    name: "",

    type_id: "",
    target_id: "",

    notes: "",

    valid: false
  },

  // Context
  context: {
    // Threshold
    threshold: {
      criteria: [],

      valid: false
    },

    // Assets
    assets: {
      asset_ids: [],
      other_asset: "",

      notes: "",

      valid: false
    },

    valid: false
  },

  // Exposition
  exposition: {
    chapter_1: "",
    chapter_2: "",

    valid: false
  },

  // Neccessity
  neccessity: {
    chapter_1: "",
    chapter_2: "",

    valid: false
  },

  // Risks
  risks: {
    integrity_risk_ids: [],
    availability_risk_ids: [],
    confidentiality_risk_ids: [],

    valid: false
  },

  // Perspective of affected
  affectedgroups: {
    chapter_1: "",

    risk_ids: [],

    valid: false
  },

  // Measures
  active_measures: {
    procedure_id: "",
    procedure_other: "",

    valid: false
  },
  planned_measures: {
    integrity_measure_ids: [],
    availability_measure_ids: [],
    confidentiality_measure_ids: [],
    evaluation_measure_ids: [],
    measures_other: "",

    valid: false
  },

  // Evaluation
  evaluation: {
    impact_level: 0,
    propability_level: 0,

    chapter_1: "",

    valid: false
  },

  // Consultation
  consultation: {
    chapter_1: "",

    experts: {
      entity_contacts: [],
      other_contacts: ""
    },

    // Konsultation der Behörde
    chapter_2: "",

    valid: false
  },

  // Conclusion
  conclusion: {
    chapter_1: "",
    chapter_2: "",
    chapter_3: "",

    valid: false
  },

  stagehistory: [],

  created_at: false,
  created_by: "",

  valid: false
};
