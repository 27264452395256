import backendDataApi from '@/services/backendDataApi.service.js';

import CommonUtils from '@/utils/common.utils.js';
import DatetimeUtils from '@/utils/datetime.utils';

import merge from 'lodash.merge';

import {
  RISK_RECORD,
  RISK_RECORD_TYPE,
  RISK_STAGEHISTORY_RECORD,
  RISK_SCHEMA_VERSION
} from '@/models/risk.couchdb';

import {
  RISKSTAGE_LIST
} from '@/lookup/risk_stages';

import i18n from '@/translations';

export default {
  namespaced: true,
  state: {},
  mutations: {
    RISK_APPROVED(state, payload) { },
    RISK_VALID(state, payload) { },
    RISK_SAVED(state, payload) { },
    RISK_REMOVED(state, payload) { },
    RISK_ASSIGNED(state, payload) { },
  },
  actions: {
    //
    // private - protected action functions
    //
    async deleteRiskByRiskIdFromBackend({ state }, risk_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({
        designdoc: "risks/list",
        keyid: risk_id,
      });
    },
    //find all risks with limited content byfilter in the couchdb and return them
    async readRiskOverviewlistFromBackend(
      { state },
      { startfilter, endfilter }
    ) {
      return await backendDataApi.readOverviewlistFromBackendByComplexFilter({
        designdoc: "risks/overviewlistByStage",
        startkey: startfilter,
        endkey: endfilter,
      });
    },
    //find a risk by id in the couchdb and return it
    async readRiskByIdFromBackend({ state }, risk_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({
        designdoc: "risks/list",
        keyid: risk_id,
      });
    },
    //create a new risk in the couchdb and return risk_id or false
    async createRiskAndStoreInBackend({ rootGetters, commit }, data) {
      // Make sure an risk_id is assigned on save for a new record
      let currentdatetime = +new Date();

      // console.debug("createRiskAndStoreInBackend - data", data);

      if (!data.risk_id || data.risk_id === undefined)
        data.risk_id = backendDataApi.generateNewCustomKeyId("rsk");

      // Safe guard to ensure a valid id
      if (!data.risk_id || 0 === data.risk_id.length) return false;

      data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
      data.created_at = currentdatetime;
      data.revision = data.revision + 1;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_DRAFTCREATED";

        data.stagehistory.push({
          ...RISK_STAGEHISTORY_RECORD,
          changetype_id: data.changetype_id,
          stage: data.stage,
          revision: data.revision,
          created_at: data.created_at,
          created_by: data.created_by,
          finished_at: +new Date(),
          finished_by: rootGetters["SessionManager/getterCurrentUsername"],
        });
      }

      if (CommonUtils.isStringEmpty(data.assigned_to)) {
        data.assigned_to = rootGetters["SessionManager/getterCurrentUsername"];
      }

      const record = {
        username: rootGetters["SessionManager/getterCurrentUsername"],

        type: RISK_RECORD_TYPE,
        keyid: data.risk_id,

        timeline: [data],
      };

      // console.debug("createRiskAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a risk in the couchdb and return risk_id or false
    async updateRiskAndStoreInBackend({ rootGetters, dispatch, commit }, data) {
      try {
        const currentdatetime = +new Date();

        data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
        data.created_at = currentdatetime;

        if (!data.changetype_id || data.changetype_id == "ID_TIMELINE_CHANGETYPE_CREATED") {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        const record = await dispatch("readRiskByIdFromBackend", data.risk_id);

        // console.debug("updateRiskAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          throw "Reading before update returns more than one record";
        }

        var doc = record[0].value;

        if (doc.timeline[doc.timeline.length - 1].active != data.active)
          data.changetype_id = data.active ? "ID_TIMELINE_CHANGETYPE_ACTIVATE" : "ID_TIMELINE_CHANGETYPE_INACTIVATE";

        // console.debug("updateRiskAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      } catch (err) {
        console.error("updateRiskAndStoreInBackend - Error: ", err);
        return false;
      }
    },
    // Data object upgrade function & migration based on version
    async actionUpgradeDataObject(
      { state, getters, rootGetters, commit, dispatch },
      riskObject
    ) {
      // console.debug("actionUpgradeDataObject - call", riskObject)
      // Safe guard unnecessary migration based on schema version
      if (Object.prototype.hasOwnProperty.call(riskObject, "version")) {
        if (riskObject.version === RISK_SCHEMA_VERSION)
        {
          return riskObject;
        }
      }

      var riskObj = {};

      merge(riskObj, getters.getterEmptyRiskRecord, riskObject);

      // console.debug("actionUpgradeDataObject - riskObject", riskObject)
      // console.debug("actionUpgradeDataObject - riskObj", riskObj)

      return riskObj;
    },

    //
    // Public action functions
    //

    //find a risk by risk_id in the couchdb and return it
    async actionReceiveRiskById({ commit, dispatch }, risk_id) {
      try {
        const result = await dispatch("readRiskByIdFromBackend", risk_id);

        if (result.length != 1)
          throw "Reading unique risk_id returns more than one record";

        // console.debug("actionReceiveRiskById - result", { ...result[0].value })

        return { ...result[0].value };
      } catch (err) {
        console.error("actionReceiveRiskById - Error: ", err);
        return false;
      }
    },
    //find the risk by id in the couchdb and return the last data element
    async actionReceiveCurrentRiskDataById(
      { state, getters, rootGetters, commit, dispatch },
      risk_id
    ) {
      try {
        const result = await dispatch("actionReceiveRiskById", risk_id);

        if (result == undefined || !result)
          throw "Error risk not found";

        // console.debug("actionReceiveCurrentRiskDataById - result", result);

        if (result.constructor !== Object || Object.keys(result).length === 0)
          throw "Error risk record is invalid";

        return await dispatch("actionUpgradeDataObject", result.timeline[result.timeline.length - 1]);
      } catch (err) {
        console.error("actionReceiveCurrentRiskDataById - Error : ", err);
        return false;
      }
    },
    async actionReceiveRiskOverviewAsArray({ dispatch }, filterarray) {
      try {
        var filterObj = {
          startfilter: [],
          endfilter: [{}],
        };

        if (Array.isArray(filterarray) && filterarray.length == 2) {
          filterObj.startfilter = [filterarray[0]];
          filterObj.endfilter = [filterarray[1], {}];
        }

        const result = await dispatch(
          "readRiskOverviewlistFromBackend",
          filterObj
        );

        if (result.length) return result.map((o) => o.value);
        else return [];
      } catch (err) {
        console.error("actionReceiveRiskOverviewAsArray Error: ", err);
        return false;
      }
    },
    async actionGenerateRiskReport(
      { state, getters, rootGetters, dispatch, commit },
      riskObj
    ) {
      try {
        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "risk_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

        printjobInfoObj.data.risk = { ...riskObj };

        // console.debug("actionGenerateRiskReport - printjobInfoObj", printjobInfoObj);

        // Risk id shorten
        if (printjobInfoObj.data.risk.risk_id) {
          const keyid = printjobInfoObj.data.risk.risk_id;
          printjobInfoObj.data.risk.risk_id =
            keyid.lastIndexOf("_") > 0 ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;
        }

        // Category
        printjobInfoObj.data.risk.core.category_display = "";

        if (printjobInfoObj.data.risk.core.category_id) {
          printjobInfoObj.data.risk.core.category_display = rootGetters[
            "LookupManager/getterRiskCataloguesAsArrayByLc"
          ](printjobInfoObj.lang).filter(
            (el) => el.id === printjobInfoObj.data.risk.core.category_id
          )[0].display_name;
        }

        printjobInfoObj.data.risk.core.danger_display = "";

        if (printjobInfoObj.data.risk.core.danger_id && printjobInfoObj.data.risk.core.category_id) {
          printjobInfoObj.data.risk.core.danger_display =
            rootGetters["LookupManager/getterRiskDangersAsArrayByLc"](printjobInfoObj.lang)
              .filter((el) => el.id === printjobInfoObj.data.risk.core.danger_id)[0].display_name;
        }

        // Base values
        // datainput
        printjobInfoObj.data.risk.core.aspects_display = [];

        if (printjobInfoObj.data.risk.core.aspect_ids.length) {
          printjobInfoObj.data.risk.core.aspect_ids.forEach(function (item) {
            printjobInfoObj.data.risk.core.aspects_display.push(
              rootGetters["LookupManager/getterRiskBaseValuesAsArrayByLc"](printjobInfoObj.lang)
                .filter((el) => el.id === item)[0].display_name
            );
          });
        }

        // risk level
        printjobInfoObj.data.risk.core.current_risk_level =
          getters.getterCurrentRiskLevel(riskObj).risk_level;
        printjobInfoObj.data.risk.evaluation.risk_level =
          printjobInfoObj.data.risk.evaluation.impact_level *
          printjobInfoObj.data.risk.evaluation.propability_level;
        printjobInfoObj.data.risk.mitigation.risk_level =
          printjobInfoObj.data.risk.mitigation.impact_level *
          printjobInfoObj.data.risk.mitigation.propability_level;

        // console.debug('printjobInfoObj.data.risk.core.current_risk_level', printjobInfoObj.data.risk.core.current_risk_level);
        // console.debug('printjobInfoObj.data.risk.evaluation.risk_level',printjobInfoObj.data.risk.evaluation.risk_level);
        // console.debug('printjobInfoObj.data.risk.mitigation.risk_level', printjobInfoObj.data.risk.mitigation.risk_level );

        // console.debug('Array', rootGetters['LookupManager/getterRiskLevelTotalsAsArrayByLc'](printjobInfoObj.lang) );

        // risk to text
        if (printjobInfoObj.data.risk.core.current_risk_level > 0) {
          printjobInfoObj.data.risk.core.current_risk_level_display =
            rootGetters["LookupManager/getterRiskLevelTotalsAsArrayByLc"](
              printjobInfoObj.lang
            ).filter(
              (el) =>
                el.min <= printjobInfoObj.data.risk.core.current_risk_level &&
                el.max >= printjobInfoObj.data.risk.core.current_risk_level
            )[0].display_name;
        } else printjobInfoObj.data.risk.core.current_risk_level_display = "-";

        if (printjobInfoObj.data.risk.evaluation.valid) {
          printjobInfoObj.data.risk.evaluation.risk_level_display = rootGetters[
            "LookupManager/getterRiskLevelTotalsAsArrayByLc"
          ](printjobInfoObj.lang).filter(
            (el) =>
              el.min <= printjobInfoObj.data.risk.evaluation.risk_level &&
              el.max >= printjobInfoObj.data.risk.evaluation.risk_level
          )[0].display_name;

          printjobInfoObj.data.risk.evaluation.impact_level_display =
            rootGetters["LookupManager/getterRiskLevelImpactsAsArrayByLc"](
              printjobInfoObj.lang
            ).filter(
              (el) =>
                el.level === printjobInfoObj.data.risk.evaluation.impact_level
            )[0].display_name;

          printjobInfoObj.data.risk.evaluation.propability_level_display =
            rootGetters[
              "LookupManager/getterRiskLevelPropabilitiesAsArrayByLc"
            ](printjobInfoObj.lang).filter(
              (el) =>
                el.level ===
                printjobInfoObj.data.risk.evaluation.propability_level
            )[0].display_name;
        } else {
          printjobInfoObj.data.risk.evaluation.risk_level_display = "-";
          printjobInfoObj.data.risk.evaluation.impact_level_display = "-";
          printjobInfoObj.data.risk.evaluation.propability_level_display = "-";
        }

        if (printjobInfoObj.data.risk.mitigation.valid) {
          printjobInfoObj.data.risk.mitigation.risk_level_display = rootGetters[
            "LookupManager/getterRiskLevelTotalsAsArrayByLc"
          ](printjobInfoObj.lang).filter(
            (el) =>
              printjobInfoObj.data.risk.mitigation.risk_level >= el.min &&
              printjobInfoObj.data.risk.mitigation.risk_level <= el.max
          )[0].display_name;

          printjobInfoObj.data.risk.mitigation.impact_level_display =
            rootGetters["LookupManager/getterRiskLevelImpactsAsArrayByLc"](
              printjobInfoObj.lang
            ).filter(
              (el) =>
                el.level === printjobInfoObj.data.risk.mitigation.impact_level
            )[0].display_name;

          printjobInfoObj.data.risk.mitigation.propability_level_display =
            rootGetters[
              "LookupManager/getterRiskLevelPropabilitiesAsArrayByLc"
            ](printjobInfoObj.lang).filter(
              (el) =>
                el.level ===
                printjobInfoObj.data.risk.mitigation.propability_level
            )[0].display_name;
        } else {
          printjobInfoObj.data.risk.mitigation.risk_level_display = "-";
          printjobInfoObj.data.risk.mitigation.impact_level_display = "-";
          printjobInfoObj.data.risk.mitigation.propability_level_display = "-";
        }
        printjobInfoObj.data.risk.created_at_display = DatetimeUtils.getFormatDate(printjobInfoObj.data.risk.created_at);

        if (printjobInfoObj.data.risk.stage) {
          printjobInfoObj.data.risk.stage_display = rootGetters[
            "LookupManager/getterRiskStagesAsArrayByLc"
          ](printjobInfoObj.lang).filter(
            (item) => item.stage === printjobInfoObj.data.risk.stage
          )[0].display_name;
        }

        // History data
        if (printjobInfoObj.data.risk.stagehistory.length) {
          printjobInfoObj.data.risk.stagehistory.forEach(function (item) {
            if (Object.prototype.hasOwnProperty.call(item, "changetype_id"))
              item.change_display = rootGetters[
                "LookupManager/getterTimelineChangeTypesAsArrayByLc"
              ](printjobInfoObj.lang).filter(
                (el) => el.id === item.changetype_id
              )[0].display_name;
            else
              item.change_display = rootGetters[
                "LookupManager/getterTimelineChangeTypesAsArrayByLc"
              ](printjobInfoObj.lang).filter(
                (el) => el.id === "ID_TIMELINE_CHANGETYPE_STAGECHANGED"
              )[0].display_name;

            item.finished_by = rootGetters[
              "AccountManager/getterUserFullnameFromUsername"
            ](item.finished_by);

            item.stage_display = rootGetters[
              "LookupManager/getterRiskStagesAsArrayByLc"
            ](printjobInfoObj.lang).filter(
              (el) => el.stage === item.stage
            )[0].display_name;
            item.finished_at_display = DatetimeUtils.getFormatDate(item.finished_at);
          });
        }

        return await dispatch("PrintManager/actionGeneratePrintDocument", printjobInfoObj, { root: true });
      } catch (err) {
        console.error("actionGenerateRiskReport - Error : ", err);
        return false;
      }
    },
    //save or update a new risk into the couchdb
    async actionSaveRisk(
      { state, getters, rootGetters, dispatch, commit },
      data
    ) {
      try {
        // console.debug("actionSaveRisk", data);
        var result = false;

        if (!data.risk_id) {
          // console.debug("actionSaveRisk - Create Mode", data);
          result = await dispatch("createRiskAndStoreInBackend", data);

          if (result === false) throw "Error creating Risk";

          commit("RISK_SAVED", data);

          return result;
        }

        // console.debug("actionSaveRisk - Update Mode", data);

        result = await dispatch("updateRiskAndStoreInBackend", data);
        // let result = true;
        // console.debug('actionSaveRisk - Update result', result);

        if (result === false) throw "Error saving Risk";

        commit("RISK_SAVED", data);

        if (getters.getterRiskNeedsApproval(data)) commit("RISK_VALID", data);

        if (getters.getterRiskIsApproved(data)) {
          commit("RISK_APPROVED", data);

          // console.debug("actionSaveRisk - Approved Risk", data);

          let report = await dispatch("actionGenerateRiskReport", data);

          if (report == false) {
            throw "Report Document Generation Error";
          }

          // set attachment object
          const docobj = {
            name:
              i18n.t("common.risk") +
              " v" +
              data.revision +
              " " +
              (data.active ? i18n.t("common.active") : i18n.t("common.inactive")),

            doccategory_id: "ID_DOCUMENTCATEGORY_RISK",
            doctype_id: "ID_DOCUMENTTYPE_RISKREPORT",

            filename: DatetimeUtils.getCurrentDateTimeCompact() +
              "_risk_rev_" +
              data.revision +
              "_approved.pdf",
            mimetype: "application/pdf",
            notes: "Auto Generated by GDPR App",

            can_deleted: false,

            reference: {
              refid: data.risk_id,
              scope: "risk",
              revision: data.revision,
              stage: data.stage,
            },
          };

          await dispatch("AttachmentManager/savePrintDocumentAsAttachment", { metadata: docobj, printdata: report }, { root: true });
        }

        return result;
      } catch (err) {
        console.error("actionSaveRisk Error: ", err);
        return false;
      }
    },
    //delete a risk from the couchdb
    async actionDeleteRiskById(
      { state, getters, rootGetters, dispatch, commit },
      risk_id
    ) {
      try {
        // console.debug("actionDeleteRiskById", risk_id);

        let result = await dispatch("deleteRiskByRiskIdFromBackend", risk_id);

        if (result !== false) {
          // console.debug('actionDeleteRiskById - After Update');
          commit("RISK_REMOVED", risk_id);

          // Delete attachments
          await dispatch(
            "AttachmentManager/actionDeleteAttachmentsByReference",
            { refid: risk_id, scope: "risk" },
            { root: true }
          );
        }

        return result;
      } catch (err) {
        console.error("actionDeleteRiskById - Error : ", err);
        return false;
      }
    },
    //assign risk
    async actionAssignRisk(
      { state, getters, rootGetters, dispatch, commit },
      assigndataObj
    ) {
      try {
        // console.debug("actionAssignRisk - assigndataObj", assigndataObj);
        var riskObj = await dispatch(
          "actionReceiveCurrentRiskDataById",
          assigndataObj.key_id
        );

        if (riskObj === false) throw "Error reading risk by id";

        riskObj.assigned_to = assigndataObj.newassigned_to;
        riskObj.changenotes = assigndataObj.newnotes;
        riskObj.changetype_id = assigndataObj.changetype_id;

        const result = await dispatch("actionSaveRisk", riskObj);

        if (result === false)
          throw "Error saving risk assignto change";

        commit("RISK_ASSIGNED", riskObj);

        return result;
      } catch (err) {
        console.error("actionAssignRisk - Error : ", err);
        return false;
      }
    },
  },
  getters: {
    getterEmptyRiskRecord: (state) => {
      return { ...RISK_RECORD };
    },
    getterRiskStageDraft: () => {
      return RISKSTAGE_LIST["ID_RISKSTAGE_DRAFT"].stage;
    },
    getterRiskStageReview: () => {
      return RISKSTAGE_LIST["ID_RISKSTAGE_REVIEW"].stage;
    },
    getterRiskStageApproved: () => {
      return RISKSTAGE_LIST["ID_RISKSTAGE_APPROVED"].stage;
    },
    getterRiskToNextStage: (state, getters, rootState, rootGetters) => (riskDataObj) => {
        try {
          if (riskDataObj === undefined) return undefined;

          riskDataObj.stagehistory.push({
            ...RISK_STAGEHISTORY_RECORD,
            stage: riskDataObj.stage,
            revision: riskDataObj.revision,
            created_at: riskDataObj.updated_at,
            created_by: riskDataObj.updated_by,
            finished_at: +new Date(),
            finished_by: rootGetters["SessionManager/getterCurrentUsername"],
          });

          // Increase stage by one
          if (riskDataObj.stage <= getters.getterRiskStageReview) {
            riskDataObj.stage++;
            riskDataObj.approved = false;
            riskDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_STAGECHANGED";
            riskDataObj.stagehistory[
              riskDataObj.stagehistory.length - 1
            ].changetype_id = riskDataObj.changetype_id;
          } else {
            if (riskDataObj.active) {
              riskDataObj.stage = getters.getterRiskStageApproved;
              riskDataObj.approved = true;
              riskDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_CLOSED";
              riskDataObj.stagehistory[
                riskDataObj.stagehistory.length - 1
              ].changetype_id = riskDataObj.changetype_id;
            } else {
              riskDataObj.stage = getters.getterRiskStageApproved;
              riskDataObj.approved = true;
              riskDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_INACTIVATE";
              riskDataObj.stagehistory[
                riskDataObj.stagehistory.length - 1
              ].changetype_id = riskDataObj.changetype_id;
            }
          }
        } catch (error) {
          console.error(error);
          riskDataObj = undefined;
        }

        return riskDataObj;
      },
    getterCurrentRiskLevel: (state, getters, rootState, rootGetters) => (riskDataObj) => {
      try {
        if (riskDataObj === undefined) return false;

        if (riskDataObj.effectiveness.check_done && riskDataObj.effectiveness.effective)
          return {
            impact_level: riskDataObj.mitigation.impact_level,
            propability_level: riskDataObj.mitigation.propability_level,
            risk_level:
              riskDataObj.mitigation.impact_level *
              riskDataObj.mitigation.propability_level,
          };
        else
          return {
            impact_level: riskDataObj.evaluation.impact_level,
            propability_level: riskDataObj.evaluation.propability_level,
            risk_level:
              riskDataObj.evaluation.impact_level *
              riskDataObj.evaluation.propability_level,
          };
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    getterIsRiskLevelAcceptable: (state, getters, rootState, rootGetters) => (riskDataObj) => {
      try {
        const riskLevelObj = getters.getterCurrentRiskLevel(riskDataObj);

        if(Object.prototype.hasOwnProperty.call(riskLevelObj, 'risk_level'))
          return (riskLevelObj.risk_level < 10);
        else
          return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    getterIsRiskEvaluationValid: (state, getters, rootState, rootGetters) => (riskDataObj) => {
      if( !Object.prototype.hasOwnProperty.call(riskDataObj, 'evaluation'))
        return false;

      return riskDataObj.evaluation.valid;
    },
    getterIsRiskMitigationValid: (state, getters, rootState, rootGetters) => (riskDataObj) => {
      if( !Object.prototype.hasOwnProperty.call(riskDataObj, 'mitigation') ||
          !Object.prototype.hasOwnProperty.call(riskDataObj, 'acceptance'))
        return false;

      return (riskDataObj.acceptance.risk_accepted || riskDataObj.stage < getters.getterRiskStageReview) ? true : riskDataObj.mitigation.valid;
    },
    getterIsRiskEffectivenessValid: (state, getters, rootState, rootGetters) => (riskDataObj) => {
      if(CommonUtils.isObjectEmpty(riskDataObj))
          return false;

      if( !Object.prototype.hasOwnProperty.call(riskDataObj, 'effectiveness') ||
      !Object.prototype.hasOwnProperty.call(riskDataObj, 'mitigation') ||
      !Object.prototype.hasOwnProperty.call(riskDataObj, 'acceptance'))
        return false;

      if( !Object.prototype.hasOwnProperty.call(riskDataObj.mitigation, 'impact_level') ||
          !Object.prototype.hasOwnProperty.call(riskDataObj.mitigation, 'propability_level') )
        return false;

      const mitigationlevel = riskDataObj.mitigation.impact_level *
      riskDataObj.mitigation.propability_level;

      return (riskDataObj.acceptance.risk_accepted || riskDataObj.stage < getters.getterRiskStageReview || mitigationlevel == 0) ? true :
        (riskDataObj.acceptance.risk_accepted || (riskDataObj.effectiveness.valid && riskDataObj.effectiveness.check_done));
    },
    getterIsRiskAcceptanceValid: (state, getters, rootState, rootGetters) => (riskDataObj) => {
      if( !Object.prototype.hasOwnProperty.call(riskDataObj, 'effectiveness') ||
          !Object.prototype.hasOwnProperty.call(riskDataObj, 'acceptance'))
        return false;

      if(riskDataObj.stage <= getters.getterRiskStageReview || getters.getterIsRiskLevelAcceptable(riskDataObj))
        return true;

      if(!riskDataObj.acceptance.risk_accepted &&
          riskDataObj.effectiveness.check_done &&
          riskDataObj.effectiveness.effective !== true &&
          !getters.getterIsRiskLevelAcceptable(riskDataObj))
        return false;

      return riskDataObj.acceptance.valid;
    },
    getterRiskNextStageAllowed: (state, getters, rootState, rootGetters) => (riskDataObj) => {
      let ballowstate = false;

      try {
        if (riskDataObj === undefined || riskDataObj === false)
          return ballowstate;

        // console.debug("getterRiskNextStageAllowed - riskDataObj", riskDataObj);

        switch (riskDataObj.stage) {
          case 1: // ID_RISKSTAGE_DRAFT
            ballowstate =
              riskDataObj.core.valid &&
              riskDataObj.evaluation.valid &&
              riskDataObj.responsible.valid;
            break;
          case 2: // ID_RISKSTAGE_REVIEW
            ballowstate =
              getters.getterIsRiskMitigationValid(riskDataObj) &&
              getters.getterIsRiskEffectivenessValid(riskDataObj) &&
              getters.getterIsRiskAcceptanceValid(riskDataObj) &&

              riskDataObj.core.valid &&
              riskDataObj.evaluation.valid &&
              riskDataObj.responsible.valid;
            break;
          case 3: // ID_RISKSTAGE_APPROVED
            ballowstate =
              !riskDataObj.approved &&

              getters.getterIsRiskMitigationValid(riskDataObj) &&
              getters.getterIsRiskEffectivenessValid(riskDataObj) &&
              getters.getterIsRiskAcceptanceValid(riskDataObj) &&

              riskDataObj.core.valid &&
              riskDataObj.evaluation.valid &&
              riskDataObj.responsible.valid;
            break;
        }
      } catch (error) {
        console.error("getterRiskNextStageAllowed - Error : ", error);
        ballowstate = false;
      }

      // console.debug("getterRiskNextStageAllowed - ballowstate", ballowstate);

      return ballowstate;
    },
    getterRiskIsApproved: (state, getters, rootState, rootGetters) => (riskDataObj) => {
      var result = false;

      // console.debug("getterRiskIsApproved", riskDataObj);

      if (riskDataObj && Object.prototype.hasOwnProperty.call(riskDataObj, "stage") && Object.prototype.hasOwnProperty.call(riskDataObj, "approved")) {
        if (riskDataObj.stage === getters.getterRiskStageApproved && riskDataObj.approved === true)
          result = true;
      }

      return result;
    },
    getterRiskNeedsApproval:
      (state, getters, rootState, rootGetters) => (riskDataObj) => {
        let result = false;

        if (
          riskDataObj &&
          Object.prototype.hasOwnProperty.call(riskDataObj, "stage") &&
          Object.prototype.hasOwnProperty.call(riskDataObj, "approved")
        ) {
          if (
            riskDataObj.stage === getters.getterRiskStageApproved &&
            riskDataObj.approved === false
          )
            result = true;
        }

        return result;
      },
    getterRiskReportData: (state, getters, rootState, rootGetters) => {
      const printjobInfoObj = {
        file: "risk_report.odt",
        convertTo: "pdf",
        lang: rootGetters["TranslationManager/lang"].lc,
      };

      return printjobInfoObj;
    },
    getterRiskLevelColor:
      (state, getters, rootState, rootGetters) => (risk_level) => {
        switch (Math.floor(risk_level)) {
          case 1:
            return "#00DD00";
          case 2:
            return "#EEDD00";
          case 3:
            return "#EEA000";
          case 4:
            return "#FF4800";
          case 5:
            return "#EE0000";
          default:
            return "#A8A8A8";
        }
      },
    getterRiskMeterColor:
      (state, getters, rootState, rootGetters) => (value) => {
        let risklevel = 0;

        if (value <= 4) risklevel = 1;

        if (value >= 5 && value <= 9) risklevel = 2;

        if (value >= 10 && value <= 14) risklevel = 3;

        if (value >= 15 && value <= 19) risklevel = 4;

        if (value >= 20 && value <= 25) risklevel = 5;

        return getters.getterRiskLevelColor(risklevel);
      },
    getterRiskEditAllowed:
      (state, getters, rootState, rootGetters) => (riskDataObj) => {
        try {
          if (
            riskDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(riskDataObj, "stage") ||
            !Object.prototype.hasOwnProperty.call(riskDataObj, "assigned_to")
          )
            throw "Risk object is undefined";

          if (rootGetters["SessionManager/getterCurrentUsername"] != riskDataObj.assigned_to)
            return false;

          return (riskDataObj.stage <= getters.getterRiskStageApproved);
        } catch (error) {
          console.error("getterRiskEditAllowed - Error : ", error);
          return false;
        }
      },
    getterRiskDeleteAllowed:
      (state, getters, rootState, rootGetters) => (riskDataObj) => {
        try {
          if (
            riskDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(riskDataObj, "revision") ||
            !Object.prototype.hasOwnProperty.call(riskDataObj, "stage") ||
            !Object.prototype.hasOwnProperty.call(riskDataObj, "assigned_to")
          )
            throw "Risk object is undefined";

          if (rootGetters["SessionManager/getterCurrentUsername"] != riskDataObj.assigned_to)
            return false;

          return (riskDataObj.stage == getters.getterRiskStageDraft && riskDataObj.revision <= 1);
        } catch (error) {
          console.error("getterRiskDeleteAllowed - Error : ", error);
          return false;
        }
      },
  },
};