import backendDataApi from '@/services/backendDataApi.service.js';

import CommonUtils from '@/utils/common.utils.js';

import CryptoJS from 'crypto-js';

import {
  PROCESS_RECORD,
  PROCESS_RECORD_TYPE,
  PROCESS_RECORD_SUBTYPE,
  ENTITYPROCESS_DOCUMENT_NAME
} from '@/models/entity_process.couchdb';

export default {
  namespaced: true,
  state: {
    ProcessList: {},

    PROCESSTYPE_I18N_KEY: "",

    PROCESSTYPE_I18N: {},
    PROCESSTYPE_LIST: {},

    ENTITYPROCESS_I18N_KEY: "",

    ENTITYPROCESS_I18N: {},
    ENTITYPROCESS_LIST: {},

    ENTITY_AREA_PROCESSES_MAP: {},
    ENTITY_AREA_PROCESSES_INIT_MAP: {},
  },
  mutations: {
    PROCESS_SAVED(state, processObj) {
      // console.debug('ProcessManager - PROCESS_SAVED', processObj);
    },
    PROCESS_REMOVED(state, process_id) {
      // console.debug('ProcessManager - PROCESS_SAVED', processObj);
    },
    PROCESS_INACTIVATE(state, processObj) {
      // console.debug('ProcessManager - PROCESS_INACTIVATE', processObj);
    },
    PROCESS_CACHE_LOAD(state, payload) {
      // console.debug('ProcessManager - PROCESS_CACHE_LOAD - payload', payload);
      state.ProcessList = payload;
    },
    INITPROCESS_LOAD(state, payload) {
      // console.debug("INITAREA_LOAD - payload", payload);

      state.ENTITYPROCESS_I18N_KEY = payload.entity_processes.I18N_KEY;
      state.ENTITYPROCESS_I18N = { ...payload.entity_processes.I18N };

      state.ENTITYPROCESS_LIST = { ...payload.entity_processes.LIST };

      state.PROCESSTYPE_I18N_KEY = payload.process_types.I18N_KEY;
      state.PROCESSTYPE_I18N = { ...payload.process_types.I18N };

      state.PROCESSTYPE_LIST = { ...payload.process_types.LIST };

      state.ENTITY_AREA_PROCESSES_MAP = {
        ...payload.entity_area_processes_map,
      };
      state.ENTITY_AREA_PROCESSES_INIT_MAP = {
        ...payload.entity_area_processes_init_map,
      };

      // console.debug("INITAREA_LOAD - payload", state);
    },
  },
  actions: {
    //
    // Private
    //
    // Private functions
    async deleteProcessByProcessIdFromBackend({ state }, process_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({
        designdoc: "processes/list",
        keyid: process_id,
      });
    },

    //find all process activity with limited content (activitydoc_id, process_pid) in the couchdb and return them
    async readProcessOverviewlistFromBackend({ state }, process_id) {
      return await backendDataApi.readOverviewlistFromBackend({
        designdoc: "processes/overviewlist",
        keyid: process_id,
      });
    },
    //find a area by id in the couchdb and return it
    async readProcessByIdFromBackend({ state }, process_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({
        designdoc: "processes/list",
        keyid: process_id,
      });
    },
    //create a new area in the couchdb and return area_id or false
    async createProcessAndStoreInBackend(
      { state, getters, rootGetters, dispatch, commit },
      data
    ) {
      try {
        // Make sure an area_id is assigned on save for a new incident
        const currentdatetime = +new Date();

        // console.debug("createProcessAndStoreInBackend - data", data);

        if (data.is_custom == true)
          data.proc_id = backendDataApi.generateNewCustomKeyId("pro");

        // Safe guard to ensure a valid id
        if (CommonUtils.isStringEmpty(data.proc_id))
          throw "Empty proc_id not allowed";

        if (CommonUtils.isStringEmpty(data.area_id))
          throw "Empty area_id not allowed";

        data.process_id = getters.getterProcessIdFromAreaIdandProcId(data.area_id, data.proc_id);

        if (CommonUtils.isStringEmpty(data.process_id))
          throw "Empty processId not allowed";

        data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
        data.created_at = currentdatetime;

        if (CommonUtils.isStringEmpty(data.changetype_id)) {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CREATED";
        }

        const record = {
          username: rootGetters["SessionManager/getterCurrentUsername"],

          type: PROCESS_RECORD_TYPE,
          subtype: PROCESS_RECORD_SUBTYPE,
          keyid: data.process_id,

          timeline: [data],
        };

        // console.debug("createProcessAndStoreInBackend - Create Mode", record);

        return await backendDataApi.createDocumentInBackend(record);
      } catch (err) {
        console.error("updateProcessAndStoreInBackend - Error: ", err);
        return false;
      }
    },
    //update a area in the couchdb and return area_id or false
    async updateProcessAndStoreInBackend(
      { state, getters, rootGetters, dispatch, commit },
      data
    ) {
      try {
        const  currentdatetime = +new Date();

        data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
        data.created_at = currentdatetime;

        if (CommonUtils.isStringEmpty(data.changetype_id) || data.changetype_id == "ID_TIMELINE_CHANGETYPE_CREATED") {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        const record = await dispatch("readProcessByIdFromBackend", data.process_id);

        // console.debug("updateProcessAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        var doc = record[0].value;

        if (doc.timeline[doc.timeline.length - 1].active != data.active)
          data.changetype_id = data.active ? "ID_TIMELINE_CHANGETYPE_ACTIVATE" : "ID_TIMELINE_CHANGETYPE_INACTIVATE";

        // console.debug("updateProcessAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      } catch (err) {
        console.error("updateProcessAndStoreInBackend - Error: ", err);
        return false;
      }
    },

    //
    // Public
    //

    // load process int doc from couchDB
    async actionRetrieveInitProcessDoc({
      state,
      rootGetters,
      dispatch,
      commit,
    }) {
      try {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(ENTITYPROCESS_DOCUMENT_NAME);

        // console.debug("actionRetrieveInitProcessDoc - doc", doc);
        if (doc === false) throw "Error retrieving Process Data";

        commit("INITPROCESS_LOAD", doc);
        return true;
      } catch (err) {
        console.error("actionRetrieveInitProcessDoc - Error : ", err);
        return false;
      }
    },

    // generate a new custom id for process
    async actionRefreshProcessesCache({
      state,
      getters,
      rootGetters,
      dispatch,
      commit,
    }) {
      try {
        // console.debug("actionRefreshProcessesCache");

        const result = await dispatch("readProcessOverviewlistFromBackend");

        if (result === false)
          throw "Cannot read process overviewlist from backend";

        // console.debug("actionRefreshProcessesCache - result", result);

        var processlist = {};

        if (result.length) {
          result.forEach((item) => {
            processlist[item.value.process_id] = item.value;
          });
        }

        //console.debug("actionRefreshProcessesCache - processlist", processlist);

        commit("PROCESS_CACHE_LOAD", processlist);

        return true;
      } catch (err) {
        console.error("actionRefreshProcessesCache - Error : ", err);
        return false;
      }
    },

    //save or update a new process into the couchdb
    async actionSaveProcess(
      { state, getters, rootGetters, dispatch, commit },
      data
    ) {
      try {
        // console.debug("actionSaveProcess", data);

        var result = false;

        // Clean up data
        delete data.display_name;
        delete data.display_description;

        if (CommonUtils.isStringEmpty(data.process_id) || Object.prototype.hasOwnProperty.call(data, "force_create")) {
          delete data.force_create;

          // console.debug("actionSaveProcess - Create Mode", data);

          // Fallback to Entity data if responsible is empty
          if (CommonUtils.isObjectEmpty(data.responsible)) {
            data.responsible = {
              ...rootGetters["EntityManager/getterEntityData"].Responsible,
              name: rootGetters["EntityManager/getterEntityData"].Responsible
                .responsible_name,
            };

            delete data.responsible.responsible_name;
          }

          result = await dispatch("createProcessAndStoreInBackend", data);
        } else {
          result = await dispatch("updateProcessAndStoreInBackend", data);
        }

        if (result !== false) {
          await dispatch("actionRefreshProcessesCache");

          await dispatch(
            "ContactBookManager/actionSaveContact",
            {
              create_if_needed: true,
              ...data.responsible,
              contact_id: CryptoJS.SHA1(
                data.process_id + "ID_ENTITYCONTACT_ROLE_PROCESSRESPONSIBLE"
              ).toString(CryptoJS.enc.Hex),
              role_id: "ID_ENTITYCONTACT_ROLE_PROCESSRESPONSIBLE",
              reference: {
                scope: "area",
                refid: data.area_id,
              },
            },
            { root: true }
          );

          commit("PROCESS_SAVED", data);

          // console.debug('actionSaveProcess - After Update');
        }

        return result;
      } catch (err) {
        console.error("actionSaveProcess - Error : ", err);
        return false;
      }
    },

    //delete a existing process from the couchdb
    async actionDeleteProcessById(
      { state, getters, rootGetters, dispatch, commit },
      process_id
    ) {
      try {
        // console.debug("actionDeleteProcessById", process_id);

        const result = await dispatch("deleteProcessByProcessIdFromBackend", process_id);

        if (result !== false) {
          await dispatch("actionRefreshProcessesCache");

          // console.debug('actionDeleteProcessById');

          commit("PROCESS_REMOVED", process_id);

          // Delete attachments
          await dispatch(
            "AttachmentManager/actionDeleteAttachmentsByReference",
            { refid: process_id, scope: "process" },
            { root: true }
          );

          await dispatch(
            "ContactBookManager/actionDeleteContactById",
            CryptoJS.SHA1(
              process_id + "ID_ENTITYCONTACT_ROLE_PROCESSRESPONSIBLE"
            ).toString(CryptoJS.enc.Hex),
            { root: true }
          );
        }

        return result;
      } catch (err) {
        console.error("actionDeleteProcessById - Error : ", err);
        return false;
      }
    },
    //find a process by process_id in the couchdb and return it
    actionReceiveProcessById(
      { state, getters, rootGetters, dispatch, commit },
      process_id
    ) {
      return dispatch("readProcessByIdFromBackend", process_id)
        .then((result) => {
          if (result.length == 1) return { ...result[0].value };
          else return {};
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
    },
    actionReceiveCurrentProcessDataById(
      { state, getters, rootGetters, commit, dispatch },
      process_id
    ) {
      return dispatch("actionReceiveProcessById", process_id)
        .then((result) => {

          if (!CommonUtils.isObjectEmpty(result)) {
            return {
              ...result.timeline[result.timeline.length - 1],
            };
          }
          else
            return false;
        })
        .catch((e) => {
          console.error("actionReceiveCurrentProcessDataById - Error :", e);
          return false;
        });
    },
    //find all processs with limited content from the couchdb and return them
    actionReceiveProcessOverviewlistAsArray({ dispatch }, filter) {
      return dispatch("readProcessOverviewlistFromBackend", filter).then(
        (result) => {
          let array = [];

          if (result.length) {
            result.forEach((item) => {
              array.push(item.value);
            });
          }

          return array;
        }
      );
    },
    // create the mandatory generic default process for area
    async actionCreateGenericProcessByArea(
      { state, getters, rootGetters, dispatch, commit },
      areaData
    ) {
      try {
        const area_id = areaData.area_id;

        // console.debug("actionCreateGenericProcessByArea - area_id", area_id);

        if (CommonUtils.isStringEmpty(area_id))
          throw "Invalid area data";

        try {
          await dispatch("actionDeleteGenericProcessByAreaId", area_id);
        } catch (error) {
          console.warn("actionCreateGenericProcessByArea - Error deleting default process : ", error);
        }

        var processobject = {
          ...getters.getterEmptyProcessRecord,

          // Flag to force create mode in actionSaveProcess action
          force_create: true,

          proc_id: getters.getterDefaultGenericProcessProcID,
          area_id: area_id,
          core: {
            ...getters.getterEmptyProcessRecord.core,
            name: state.ENTITYPROCESS_LIST[
              getters.getterDefaultGenericProcessProcID
            ].name,
            description:
              state.ENTITYPROCESS_LIST[
                getters.getterDefaultGenericProcessProcID
              ].description,
            valid: true,
          },
          responsible: {
            ...areaData.responsible,
          },
        };

        // console.debug("actionCreateGenericProcessByArea - processobject", processobject);

        if ((await dispatch("actionSaveProcess", processobject)) === false) {
          console.error("actionCreateGenericProcessByArea - Error saving process : ", processobject);
          throw "Error saving new generic process object";
        }

        await dispatch("actionRefreshProcessesCache");

        return true;
      } catch (err) {
        console.error("actionCreateGenericProcessByArea - Error : ", err);
        return false;
      }
    },
    //create the initial processs based on sector_process_map at the end of firstrun
    async actionCreateInitialProcessesByArea(
      { state, getters, rootGetters, dispatch, commit },
      areaData
    ) {
      try {
        const area_id = areaData.area_id;

        // console.debug("actionCreateInitialProcessesByArea - area_id", area_id);

        if (CommonUtils.isStringEmpty(area_id))
          throw "Invalid area data";

        // Create generic process in any case
        if ((await dispatch("actionCreateGenericProcessByArea", areaData)) == false)
          throw "Error creating mandatory generic process";

        // Create other processes based on initmap by country code
        if (rootGetters["getterAutoInitWithDataMaps"] === true) {
          const countrycode = rootGetters["EntityManager/getterEntityData"].EntityInitOptions.countrycode;

          if (CommonUtils.isStringEmpty(countrycode))
            throw "Invalid entitydata";

          const initmap =
            (Object.prototype.hasOwnProperty.call(state.ENTITY_AREA_PROCESSES_INIT_MAP, countrycode) === true) ?
              state.ENTITY_AREA_PROCESSES_INIT_MAP[countrycode] :
              state.ENTITY_AREA_PROCESSES_INIT_MAP.default;

          if (initmap[area_id] !== undefined) {
            // add default processs from init map
            for (const proc_id of initmap[area_id]) {
              // console.debug("actionCreateInitialProcessesByArea - process_ID", proc_id);

              let processobject = {
                ...getters.getterEmptyProcessRecord,

                // Flag to force create mode in actionSaveProcess action
                force_create: true,

                proc_id: proc_id,
                area_id: area_id,
                core: {
                  ...getters.getterEmptyProcessRecord.core,
                  name: state.ENTITYPROCESS_LIST[proc_id].name,
                  description: state.ENTITYPROCESS_LIST[proc_id].description,
                  valid: true,
                },
                responsible: {
                  ...areaData.responsible,
                },
              };

              // console.debug("actionCreateInitialProcessesByArea - processobject", processobject);

              if (
                (await dispatch("actionSaveProcess", processobject)) === false
              ) {
                console.error(
                  "actionCreateInitialProcessesByArea - Error saving process : ",
                  processobject
                );
                throw "Error saving new mapped process object";
              }
            }
          }
        }

        await dispatch("actionRefreshProcessesCache");

        // Create initial activitydoc data based on area data and use only generic process
        await dispatch(
          "ActivityDocManager/actionCreateInitialActivityDocByArea",
          areaData,
          { root: true }
        );

        return true;
      } catch (err) {
        console.error("actionCreateInitialProcessesByArea - Error : ", err);
        return false;
      }
    },
    async actionDeleteGenericProcessByAreaId(
      { state, getters, rootGetters, dispatch, commit },
      area_id
    ) {
      try {
        // console.debug("actionCreateGenericProcessByArea - area_id", area_id);

        if (CommonUtils.isStringEmpty(area_id)) throw "Invalid area data";

        const process_id = getters.getterProcessIdFromAreaIdandProcId(area_id, getters.getterDefaultGenericProcessProcID);

        return await dispatch("actionDeleteProcessById", process_id);
      } catch (error) {
        console.error("actionCreateGenericProcessByArea - Error deleting default process : ",error);
        return false;
      }
    },
    async actionGenerateProcessReport(
      { state, getters, rootGetters, dispatch, commit },
      processObj
    ) {
      try {
        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "process_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

        printjobInfoObj.data.process = { ...processObj };

        // console.debug("actionGenerateProcessReport - printjobInfoObj", printjobInfoObj);

        // Create data
        printjobInfoObj.data.process.created_at_display = rootGetters["HelperManager/getterFormatDate"](printjobInfoObj.data.process.created_at);

        var processdata = rootGetters["ProcessManager/getterEntityProcessByProcessIdByLc"](printjobInfoObj.data.process.process_id, printjobInfoObj.lang);

        printjobInfoObj.data.process.display_name = processdata.display_name;
        printjobInfoObj.data.process.display_description = processdata.display_description;

        if (printjobInfoObj.data.process.process_id) {
          const keyid = printjobInfoObj.data.process.process_id;
          printjobInfoObj.data.process.process_id =
            keyid.lastIndexOf("_") > 0 ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;
        }
        // Area object
        printjobInfoObj.data.area = rootGetters["AreaManager/getterEntityAreaByIdByLc"](processObj.area_id, printjobInfoObj.lang);

        // Related activitydocs array
        printjobInfoObj.data.activitydocs = rootGetters["ActivityDocManager/getterActivityDocsAsArrayByAreaId"](processObj.area_id);

        for (const doc in printjobInfoObj.data.activitydocs) {
          printjobInfoObj.data.activitydocs[doc].stage_display = rootGetters[
            "LookupManager/getterActivityDocStagesAsArrayByLc"
          ](printjobInfoObj.lang).filter(
            (item) => item.stage === printjobInfoObj.data.activitydocs[doc].stage
          )[0].display_printtext;

          const keyid = printjobInfoObj.data.activitydocs[doc].activitydoc_id;
          printjobInfoObj.data.activitydocs[doc].id_display =
            keyid.lastIndexOf("_") > 0 ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;
        }

        // Related documents array
        printjobInfoObj.data.process.documents_display = [];

        var documentarray = [];

        if (printjobInfoObj.data.process.hasReference)
          documentarray = await dispatch(
            "AttachmentManager/actionReadAttachmentsByReferenceAsArray",
            {
              reference: {
                scope: printjobInfoObj.data.process.reference.scope,
                refid: printjobInfoObj.data.process.reference.refid,
              },
            },
            { root: true }
          );
        else
          documentarray = await dispatch(
            "AttachmentManager/actionReadAttachmentsByReferenceAsArray",
            {
              reference: {
                scope: "process",
                refid: printjobInfoObj.data.process.process_id,
              },
            },
            { root: true }
          );

        for (const index in documentarray) {
          // console.debug("documentarray - filename", documentarray[index].value.filename);
          // console.debug("documentarray - type", documentarray[index].value.doctype_id);
          var doctype = rootGetters["LookupManager/getterDocumentTypesAsArrayByLc"](printjobInfoObj.lang)
            .filter((el) => el.id === documentarray[index].doctype_id)[0].display_name;

          printjobInfoObj.data.process.documents_display.push(documentarray[index].name + " (" +
            documentarray[index].filename + ", " + doctype + ")")
        }

        printjobInfoObj.data.process.processDiagram = false;
        /*
            // Process diagram
            // example

            let processModelFile = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAACjklEQVRo3u3YPWxNYRgH8F99JYoyIBQRg1Hie7NRkRBSlRjE2DCwGCpiQSUmiwgiIsRgFSE0QVlFazAx+Ag2RVsESWs4b+P27bnntLe3H+T8kye5933/5/98nOf9uJcCBQoUKFCgwP+FhWhFJ3qDdeBUmJvU2INu9JexbjRNdJBZwfdlBD9gfZMxiYWyKx/bVyyohuMpVUrgMOaUfP+FFiwJdjSMDaAOh8aklBXiucEVbknhtEScjvEKbifaJbtJN9pSOHH71Kdw6g1d0DHawngvHmHHaIM/I72Ha6uQwNdoflYZX6dHU/lyi3B5xO2U30JHZbfQigx/2ytJoD1DsDHinormf4Yk6oO1hLFSzolIY3eGv4eVJNCTIXgx4o50G/2C+ZHGpQx+2nrJRVeOYF3EbzL8gyx+g3NzCvCpkgTacgJpTXmmSbI4syrfmPLc6Rxf9ypJYGtORX8a2gYkJ+xJPJO0YU/4fKIMf76h6yN+Y1sqSQCOl0miD8cqFR1vPw24L1kTXeFzQxWDH28/BaqKmirp1GIjNmEVVmKpvzfUHrzHS7zAEzzF97FKYBY2Yz1+46zkglWK6diFvdiGmSP0/QN3cRO3gp9SzMYRTAvJPsC3PNE1uBEq04/bIdBSTMVBvDH8kzfPXuNA0C7FDNwJnG+4jtVpgdfhisFb2UtDb53L8LiKgcf2SNJ6cTe8MnhrvazkB9Q8yUETi+2LhBbjbYbzd0G4GRtCIDODLQ1jzYHzLkPnDRZFvven8J5KriDOlxGKK3EthdODC1hrZKjBuvBsb4ru1ZQ3nxbjOfhYZjLG5zFsndi6Uvyn8T7UlAl2oEqxwHhiWP6r9a/EhOGfT6BAgQIFChQoMJH4A1iep231bnIRAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA1LTAyVDE0OjMzOjUyKzAwOjAwa/pOBAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNS0wMlQxNDozMzo1MiswMDowMBqn9rgAAAAASUVORK5CYII=';

            printjobInfoObj.data.process.processDiagram = processModelFile;

            let processModelFile = await dispatch("AttachmentManager/actionReceiveProcessModelAttachmentByProcessId",
              {
                reference: {
                  scope: "process",
                  refid: printjobInfoObj.data.process.process_id,
                },
              },
              { root: true }
            );

            if (processModelFile) {
              printjobInfoObj.data.process.processDiagram = processModelFile;
            }
            else {
              printjobInfoObj.data.process.processDiagram = "";
            }

            // console.debug("actionGenerateProcessReport- processDiagram", printjobInfoObj.data.process.processDiagram);

            // console.debug("actionGenerateProcessReport - printObj", printjobInfoObj);
     */
        return await dispatch("PrintManager/actionGeneratePrintDocument", printjobInfoObj, { root: true });
      } catch (err) {
        console.error("actionGenerateProcessReport - Error : ", err);
        return false;
      }
    },
  },
  getters: {
    // Public
    getterEmptyProcessRecord: (state) => {
      return { ...PROCESS_RECORD };
    },
    getterProcessReportData: (state, getters, rootState, rootGetters) => {
      const printjobInfoObj = {
        file: "process_report.odt",
        convertTo: "pdf",
        lang: rootGetters["TranslationManager/lang"].lc,
      };

      return printjobInfoObj;
    },
    getterDefaultGenericProcessProcID: (state) => {
      return "ID_PROCESS_GENERICDATAPROCESSING";
    },
    getterDefaultGenericProcessProcessIDByAreaID:
      (state, getters) => (areaId) => {
        return getters.getterProcessIdFromAreaIdandProcId(
          areaId,
          getters.getterDefaultGenericProcessProcID
        );
      },
    getterEntityProcessByProcessId:
      (state, getters, rootState, rootGetters) => (process_id) => {
        return getters.getterEntityProcessByProcessIdByLc(
          process_id,
          rootGetters["TranslationManager/getterLanguageAlpha2Code"]
        );
      },
    getterEntityProcessByAreaIdandProcId:
      (state, getters, rootState, rootGetters) => (areaId, procId) => {
        return getters.getterEntityProcessByAreaIdandProcIdByLc(
          areaId,
          procId,
          rootGetters["TranslationManager/getterLanguageAlpha2Code"]
        );
      },
    getterEntityProcessByAreaIdandProcIdByLc:
      (state, getters, rootState, rootGetters) => (areaId, procId, lc) => {
        try {
          if (!areaId || !procId)
            throw "Cannot read process with empty areaId or procid argument";

          const process_id = getters.getterProcessIdFromAreaIdandProcId(
            areaId,
            procId
          );

          return getters.getterEntityProcessByProcessIdByLc(process_id, lc);
        } catch (err) {
          console.error(
            "getterEntityProcessByAreaIdandProcIdByLc - Error : ",
            err
          );
          return false;
        }
      },
    getterEntityProcessByProcessIdByLc:
      (state, getters, rootState, rootGetters) => (processId, lc) => {
        try {
          let processObj = false;

          // console.debug("getterEntityProcessByProcessIdByLc - processId, lc", processId, lc);

          if (0 === processId.length)
            throw "Cannot read process with empty processId argument";

          let procCacheObj = state.ProcessList[processId];

          if (
            procCacheObj === undefined ||
            (Object.keys(procCacheObj).length === 0 &&
              procCacheObj.constructor === Object)
          )
            throw "Cannot read process with unknown processId argument";

          if (procCacheObj.is_custom) {
            processObj = {
              ...getters.getterEmptyProcessRecord,
              ...procCacheObj,

              id: processId,

              display_name: procCacheObj.name,
              display_description: procCacheObj.description,
            };
          } else {
            if (
              Object.keys(state.ENTITYPROCESS_I18N).length === 0 &&
              state.ENTITYPROCESS_I18N.constructor === Object
            )
              return processObj;

            let eplc = lc;

            if (state.ENTITYPROCESS_I18N.languages.indexOf(lc) === -1)
              eplc = state.ENTITYPROCESS_I18N.defaultlang;

            processObj = {
              ...getters.getterEmptyProcessRecord,
              ...procCacheObj,

              id: processId,

              display_name:
                state.ENTITYPROCESS_I18N["translations"][eplc][
                state.ENTITYPROCESS_LIST[procCacheObj.proc_id].name
                ],
              display_description:
                state.ENTITYPROCESS_I18N["translations"][eplc][
                state.ENTITYPROCESS_LIST[procCacheObj.proc_id].description
                ],
            };

            if (
              Object.prototype.hasOwnProperty.call(
                processObj,
                "i18n_overwrites"
              )
            ) {
              if (
                Object.keys(processObj.i18n_overwrites).filter(
                  (item) => item === lc
                ).length > 0
              ) {
                processObj.display_name = processObj.i18n_overwrites[lc].name;
                processObj.display_description =
                  processObj.i18n_overwrites[lc].description;
              }
            }
          }

          return processObj;
        } catch (err) {
          console.error("getterEntityProcessByProcessIdByLc - Error: ", err);
          return false;
        }
      },
    getterDefaultProcessByProcId:
      (state, getters, rootState, rootGetters) => (procId) => {
        // console.debug("getterDefaultProcessByProcId - lang", rootGetters['TranslationManager/lang']);
        // console.debug("getterDefaultProcessByProcId - Alpha2", rootGetters['TranslationManager/getterLanguageAlpha2Code']);

        return getters.getterDefaultProcessByProcIdByLc(
          procId,
          rootGetters["TranslationManager/getterLanguageAlpha2Code"]
        );
      },
    getterDefaultProcessByProcIdByLc:
      (state, getters, rootState, rootGetters) => (procId, lc) => {
        let processObj = false;

        try {
          if (procId) {
            if (
              Object.keys(state.ENTITYPROCESS_I18N).length === 0 &&
              state.ENTITYPROCESS_I18N.constructor === Object
            )
              return processObj;

            // console.debug("getterDefaultProcessByProcIdByLc - lc", lc);

            if (state.ENTITYPROCESS_I18N.languages.indexOf(lc) === -1)
              lc = state.ENTITYPROCESS_I18N.defaultlang;

            // console.debug("getterDefaultProcessByProcIdByLc - lc", lc);

            if (state.ENTITYPROCESS_LIST[procId] !== undefined) {
              processObj = {
                ...state.ENTITYPROCESS_LIST[procId],
                proc_id: procId,
                id: procId,
                display_name:
                  state.ENTITYPROCESS_I18N["translations"][lc][
                  state.ENTITYPROCESS_LIST[procId].name
                  ],
                display_description:
                  state.ENTITYPROCESS_I18N["translations"][lc][
                  state.ENTITYPROCESS_LIST[procId].description
                  ],
              };
            }
          }
        } catch (err) {
          console.error("getterDefaultProcessByProcIdByLc - Error: ", err);
        }

        return processObj;
      },
    getterEntityProcessesAsArrayByAreaId:
      (state, getters, rootState, rootGetters) => (areaId) => {
        return getters.getterEntityProcessesAsArrayByAreaIdByLc(
          areaId,
          rootGetters["TranslationManager/getterLanguageAlpha2Code"]
        );
      },
    getterEntityProcessesAsArrayByAreaIdByLc:
      (state, getters, rootGetters) => (areaId, lc) => {
        let array = [];

        // console.debug("getterEntityProcessesAsArrayByAreaIdByLc - areaId, lc", areaId, lc);

        try {
          array = getters
            .getterEntityProcessesAsArrayByLc(lc)
            .filter((item) => item.area_id === areaId);
        } catch (err) {
          console.error(
            "getterEntityProcessesAsArrayByAreaIDByLc - Error: ",
            err
          );
        }

        return array;
      },
    getterEntityProcessesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterEntityProcessesAsArrayByLc(
        rootGetters["TranslationManager/getterLanguageAlpha2Code"]
      );
    },
    getterEntityProcessesAsArrayByLc:
      (state, getters, rootState, rootGetters) => (lc) => {
        let array = [];

        for (const processId in state.ProcessList) {
          array.push({
            ...getters.getterEntityProcessByProcessIdByLc(processId, lc),
          });
        }

        return array;
      },
    getterAvailableDefaultProcessesAsArrayByAreaId: (state, getters, rootState, rootGetters) => (countrycode, areaId) => {
      const initmap =
        (Object.prototype.hasOwnProperty.call(state.ENTITY_AREA_PROCESSES_MAP, countrycode) === true) ?
          state.ENTITY_AREA_PROCESSES_MAP[countrycode] :
          state.ENTITY_AREA_PROCESSES_MAP.default;

      var array = [];

      // console.debug("getterAvailableDefaultProcessesAsArrayByAreaId - areaId", areaId);
      // console.debug("getterAvailableDefaultProcessesAsArrayByAreaId - initmap", initmap);

      if (areaId) {
        initmap[areaId].forEach((procId) => {
          array.push(getters.getterDefaultProcessByProcId(procId));
        });
      }

      return array;
    },
    getterAvailableDefaultProcessesAsArray: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      let array = [];

      for (let procId in state.ENTITYPROCESS_LIST) {
        array.push(getters.getterDefaultProcessByProcId(procId));
      }

      return array;
    },

    getterSelectableProcessArrayByAreaId:
      (state, getters, rootState, rootGetters) => (areaid) => {
        const countrycode = "";
        return getters
          .getterAvailableDefaultProcessesAsArrayByAreaId(countrycode, areaid)
          .filter(
            (item) =>
              !getters.getterEntityProcessesAsArray.some(
                (process) => process.proc_id === item.proc_id
              )
          );
      },

    getterSelectableProcessesAsArray: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterAvailableDefaultProcessesAsArray.filter(
        (item) =>
          !getters.getterEntityProcessesAsArray.some(
            (process) => process.proc_id === item.proc_id
          )
      );
    },

    getterProcessTypesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterProcessTypesAsArrayByLc(
        rootGetters["TranslationManager/getterLanguageAlpha2Code"]
      );
    },

    getterProcessTypesAsArrayByLc:
      (state, getters, rootState, rootGetters) => (lc) => {
        return rootGetters[
          "DBLookupManager/getterInternalLookupTranslateArrayByLc"
        ](state.PROCESSTYPE_LIST, state.PROCESSTYPE_I18N, lc, true);
      },

    // Internal
    getterProcessIdFromAreaIdandProcId:
      (state, getters, rootState, rootGetters) => (area_id, proc_id) => {
        try {
          if (!area_id || !proc_id) return false;
          else
            return CryptoJS.SHA1(area_id + proc_id).toString(CryptoJS.enc.Hex);
        } catch (err) {
          console.error("getterProcessIdFromAreaIdandProcId - Error", err);
          return false;
        }
      },
    getterIsDefaultGenericProcessIdByAreaId:
      (state, getters, rootState, rootGetters) => (processObj, area_id) => {
        try {
          // console.debug("getterIsDefaultGenericProcessIdByAreaId - processObj, area_id", processObj, area_id);

          if (
            !Object.prototype.hasOwnProperty.call(processObj, "process_id") ||
            !area_id ||
            area_id.length == 0
          )
            return false;

          return (
            processObj.process_id ==
            getters.getterDefaultGenericProcessProcessIDByAreaID(area_id)
          );
        } catch (err) {
          console.error("getterProcessIdFromAreaIdandProcId - Error", err);
          return false;
        }
      },
  },
};