import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';

import i18n from '@/translations';

import {ERROR_MESSAGES} from '@/config/auth.errormessages.js';

/**
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */
function _getStatusMessage (status) {
  let message = '';

  switch (status) {
    case 200:
      message = 'All done. Request successfully executed';
      break;
    case 201:
      message = 'Data successfully created';
      break;
    case 400:
      message = 'Bad Request';
      break;
    case 401:
      message = 'Unauthorized';
      break;
    case 404:
      message = 'Not found';
      break;
    case 503:
      message = 'Service unavailable. Try again later';
      break;
    default:
      message = 'Something wrong. Client default error message';
      break;
  }
  return message;
}

/**
 * Create instance, which represent response object
 * @param {Object} [data] - custom data
 * @param {Object} [response] - axios response object
 * @param {String} [message] - custom message to display
 */
export class ResponseWrapper {
  constructor (response, data = {}, message="") {
    this.data = data;
    this.success = response.data.success;
    this.status = response.status;
    this.statusMessage = _getStatusMessage(this.status);
    this.message = message || response.data.message || null;
  }
}

/**
 * Create instance, which represent error object
 * @param {Object} [error] - axios error object
 * @param {String} [message] - custom message to display
 */
export class ErrorWrapper extends Error {
  constructor (error, message) {
    super();

    if(error !== undefined)
    {
      // console.debug("ErrorWrapper - error", error.response);
      // console.debug("ErrorWrapper - message", message);

      this.success = error.response ? error.response.data.success : false;
      this.meta = error.response ? error.response.data.message : false;
      this.code = error.response ? error.response.data.error.errorcode : false;
      this.status = error.response ? error.response.status : false;
      this.statusMessage = _getStatusMessage(this.status);
      this.errorKey = error.response.data.error.errorkey || "GenericServiceBackendError";
      this.errorMessage = error.response.data.error.message;
    }
    else {
      this.success = false;
      this.meta = false;
      this.code = 999;
      this.status = false;
      this.statusMessage = _getStatusMessage(this.status);
      this.errorKey = "GenericServiceBackendError";
      this.errorMessage = error.response.data.error.message;
    }   
  }
}

/**
 * Uses to clear request data before send it
 * Client shouldn't change entity id
 * @param data
 * @return {{}}
 */
export function clearData (data) {
  const result = {};
  forEach(data, (item, propName) => {
    if (isArray(item) && item.length) {
      result[propName] = item;
    }
    if (!isArray(item) && item && (propName !== 'id')) {
      result[propName] = item;
    }
  });
  return result;
}
