import backendDataApi from '@/services/backendDataApi.service.js';
import CommonUtils from '@/utils/common.utils.js';

import merge from "lodash.merge";

import {
  DATACATEGORY_RECORD_TYPE,
  DATACATEGORY_RECORD_SUBTYPE,
  DATACATEGORY_RECORD,
  DATACATEGORY_I18N_DISPLAY,
  DATACATEGORY_I18N_DEFAULTLANGUAGE
} from '@/models/datacategory.couchdb';

export default {
  namespaced: true,
  state: {
    datacategorylist: []
  },
  mutations: {
    DATACATEGORY_SAVED(state, payload) { },
    DATACATEGORY_REMOVED(state, payload) { },
    DATACATEGORY_CACHE_LOAD(state, payload) {
      state.datacategorylist = [...payload];
    },
  },
  actions: {
    //
    // private - protected action functions
    //

    //find all datacategorys with limited content by filter in the couchdb and return them
    async readDataCategoryOverviewlistFromBackend({ state }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({
        designdoc: "datacategories/list",
        keyid: filter,
      });
    },
    //find a datacategory by id in the couchdb and return it
    async readDataCategoryByIdFromBackend({
      state
    }, datacategory_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({ designdoc: 'datacategories/list', keyid: datacategory_id });
    },
    //create a new datacategory in the couchdb and return id or false
    async createDataCategoryAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      // Make sure an id is assigned on save for a new incident
      const currentdatetime = +new Date();

      // console.debug("createDataCategoryAndStoreInBackend - data", data);
      if(data.standard == false)
        data.datacategory_id = backendDataApi.generateNewCustomKeyId('dtc');

      // Safe guard to ensure a valid id
      if (!data.datacategory_id || 0 === data.datacategory_id.length)
        return false;

      data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
      data.created_at = currentdatetime;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_CREATED";
      }

      // transform translations
      var new_record = {
        ...DATACATEGORY_RECORD,
        ...data
      };

      const lang = data.display_translation.lang;

      new_record.i18n.translations[lang] = {};

      new_record.i18n.translations[lang].name = data.display_translation.name;
      new_record.i18n.translations[lang].description = data.display_translation.description;

      delete new_record.name;
      delete new_record.description;

      delete new_record.display_name;
      delete new_record.display_description;

      delete new_record.display_translation;
      delete new_record.standard;
      delete new_record.force_create;
      delete new_record.keyid;

      const record = {
        type: DATACATEGORY_RECORD_TYPE,
        subtype: DATACATEGORY_RECORD_SUBTYPE,
        standard: data.standard,
        creator: rootGetters['SessionManager/getterCurrentUsername'],
        created_at: currentdatetime,
        replicate: false,
        keyid: data.datacategory_id,
        timeline: [new_record]
      };

      // console.debug("createDataCategoryAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a datacategory in the couchdb and return id or false
    async updateDataCategoryAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      try {
        const currentdatetime = +new Date();

        data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
        data.created_at = currentdatetime;

        if (!data.changetype_id || data.changetype_id == 'ID_TIMELINE_CHANGETYPE_CREATED') {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        const record = await dispatch('readDataCategoryByIdFromBackend', data.datacategory_id);

        // console.debug("updateDataCategoryAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        var doc = record[0].value;

        // console.debug("updateDataCategoryAndStoreInBackend - Update Mode DOC", doc);

        // transform translations
        var new_record = {
          ...DATACATEGORY_RECORD,
          ...data
        };

        const lang = data.display_translation.lang;

        new_record.i18n.translations[lang] = {};

        new_record.i18n.translations[lang].name = data.display_translation.name;
        new_record.i18n.translations[lang].description = data.display_translation.description;

        delete new_record.name;
        delete new_record.description;

        delete new_record.display_name;
        delete new_record.display_description;

        delete new_record.display_translation;
        delete new_record.standard;
        delete new_record.keyid;

        doc.timeline.push(new_record);

        return await backendDataApi.updateDocumentInBackend(doc);
      }
      catch (err) {
        console.error("updateDataCategoryAndStoreInBackend - Error: ", err);
        return false;
      }
    },
    async deleteDataCategoryByIdFromBackend({
      state
    }, datacategory_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({ designdoc: 'datacategories/list', keyid: datacategory_id });
    },

    //
    // Public action functions
    //

    //delete a existing asset from the couchdb
    async actionDeleteDataCategoryById({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, datacategory_id) {
      try {
        const result = await dispatch('deleteDataCategoryByIdFromBackend', datacategory_id);

        if (result !== false) {
          // console.debug('actionDeleteDataCategoryById - After Update');
          commit('DATACATEGORY_REMOVED', datacategory_id);
        }

        return result;
      }
      catch (err) {
        console.error("actionDeleteDataCategoryById - Error : ", err);
        return false;
      }
    },
    //find a datacategory by id in the couchdb and return it
    async actionReceiveDataCategoryById({
      commit,
      dispatch
    }, datacategory_id) {
      return dispatch('readDataCategoryByIdFromBackend', datacategory_id).then(result => {
        if (result.length == 1)
          return { ...result[0].value };
        else
          return {};
      })
        .catch(e => {
          console.error(e);
          return false;
        });
    },
    //find the proof by id in the couchdb and return the last data element
    async actionReceiveCurrentDataCategoryDataById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, datacategory_id) {
      try {
        const result = await dispatch('actionReceiveDataCategoryById', datacategory_id);

        // console.debug("actionReceiveCurrentDataCategoryDataById result", result);

        if (result == false || CommonUtils.isObjectEmpty(result))
          throw "Error datacategory not found";

        // console.debug("actionReceiveCurrentDataCategoryDataById result", result);
        const currentDataCategory = {
          ...getters.getterEmptyDataCategoryRecord,
          standard: result.standard,
          keyid: result.keyid,
          ...result.timeline[result.timeline.length - 1]
        };

        return CommonUtils.translateI18nDataObjectByLc(currentDataCategory, rootGetters["TranslationManager/getterLanguageAlpha2Code"]);
      }
      catch (err) {
        console.error("actionReceiveCurrentDataCategoryDataById - Error : ", err);
        return false;
      }
    },
    async actionReceiveActiveDataCategoriesAsArray({
      state,
      getters,
      rootGetters,
      commit,
      dispatch }) {
      try {
        const result = await backendDataApi.readViewFromBackendByOption("datacategories/activeInActivityDocs", {reduce: true, group_level: 1, include_docs: false}) ;

        // console.debug("actionReceiveActiveDataCategoriesAsArray - result", result)

        if(result == false)
          throw "Error reading active datacategories list";

        // console.debug("actionReceiveActiveDataCategoriesAsArray - keys", result.map((o) => o.key));

        return result.map((o) => o.key);
      }
      catch (err) {
        console.error("actionReceiveActiveDataCategoriesAsArray - Error : ", err);
        return [];
      }
    },
    async actionReceiveActivityDocIdsAsArrayByDataCategoryId({
      state,
      getters,
      rootGetters,
      commit,
      dispatch }, datacategory_id) {
      try {
        const result = await backendDataApi.readViewFromBackendByOption('datacategories/activeInActivityDocs', {key: datacategory_id, reduce: false, include_docs: false});

        // console.debug("actionReceiveActivityDocIdsAsArrayByDataCategoryId - result", result)

        // console.debug("actionReceiveActivityDocIdsAsArrayByDataCategoryId - keys", result.map((o) => o.key));

        return CommonUtils.removeDuplicatesFromArray(result.map((o) => o.value.activitydoc_id));
      }
      catch (err) {
        console.error("actionReceiveActivityDocIdsAsArrayByDataCategoryId - Error : ", err);
        return [];
      }
    },
    // read all datacategory docs from the couchdb in cache
    async actionRefreshDataCategoriesCache({ getters, rootGetters, commit, dispatch }) {
      try {
        const result = await dispatch("readDataCategoryOverviewlistFromBackend");

        // console.debug("actionRefreshDataCategoriesCache - result", result);

        if (result === false)
          throw "Cannot read datacategory overviewlist from backend";

        var datacategorylist = [];

        if (result.length) {
          result.forEach((item) => {
            const datacategoryObj = {
              keyid: item.value.keyid,
              ...item.value.timeline[item.value.timeline.length - 1],
              standard: item.value.standard
            };

            // console.debug("actionRefreshDataCategoriesCache- datacategoryObj", datacategoryObj);
            datacategorylist.push(datacategoryObj);
          });
        }

        // console.debug("actionRefreshDataCategoriesCache - datacategorylist", datacategorylist);

        commit("DATACATEGORY_CACHE_LOAD", datacategorylist);

        return true;
      } catch (err) {
        console.error("actionRefreshDataCategoriesCache - Error : ", err);
        return false;
      }
    },
    //save or update a new datacategory into the couchdb
    async actionSaveDataCategory({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, data) {
      try {
        // console.debug("actionSaveDataCategory", data);
        let result = false;

        if (!data.datacategory_id || Object.prototype.hasOwnProperty.call(data, "force_create")) {
          delete data.force_create;
          // console.debug("actionSaveDataCategory - Create Mode", data);
          result = await dispatch('createDataCategoryAndStoreInBackend', data);
        }
        else {
          // console.debug("actionSaveDataCategory - Update Mode", data);
          result = await dispatch('updateDataCategoryAndStoreInBackend', data);
        }

        //console.debug("actionSaveDataCategory - result", result);

        if (result == false)
          throw "Error saving DataCategory";

        commit("DATACATEGORY_SAVED", data);

        return result;
      }
      catch (err) {
        console.error("actionSaveDataCategory Error: ", err);
        return false;
      }
    },
  },
  getters: {
    getterEmptyDataCategoryRecord: (state) => {
      // console.debug("getterEmptyDataCategoryRecord - ARTEFACT_I18N_DISPLAY", merge({}, ARTEFACT_I18N_DISPLAY));
      return { ...DATACATEGORY_RECORD, ...DATACATEGORY_I18N_DISPLAY };
    },
    getterDataCategoriesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDataCategoriesAsArrayByLc("");
    },
    getterAvailableDataCategoriesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDataCategoriesAsArrayByLc("").filter(el => el.active == true);
    },
    getterDataCategoryTypeIsSensibleById: (state, getters, rootState, rootGetters) => (datacategory_id) => {
      try {
        const datacategoryObj = getters.getterDataCategoryById(datacategory_id);

        if(CommonUtils.isObjectEmpty(datacategoryObj))
          throw "Empty id not allowed";

        return getters.getterDataCategoryTypeIsSensible(datacategoryObj.type);
      } catch (error) {
        console.error("getterDataCategoryById - Error :", error);
        return false;
      }
    },
    getterDataCategoryTypeIsSensible: (state, getters, rootState, rootGetters) => (type) => {
      return (type == 9 || type == 10);
    },
    getterDataCategoryById: (state, getters, rootState, rootGetters) => (datacategory_id) => {
      try {
        var datacategoryObj = {};

        if(CommonUtils.isStringEmpty(datacategory_id))
          throw "Empty id not allowed";

        const tempary = getters.getterDataCategoriesAsArray.filter(item => item.datacategory_id == datacategory_id);

        if(CommonUtils.isArrayEmpty(tempary) == false)
          datacategoryObj = tempary[0];

        return datacategoryObj;
      } catch (error) {
        console.error("getterDataCategoryById - Error :", error);
        return false;
      }
    },
    getterDataCategoriesAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      try {
        var datacategorylist = [];

        const lang = CommonUtils.isStringEmpty(lc) ? rootGetters["TranslationManager/getterLanguageAlpha2Code"] : lc;

        state.datacategorylist.forEach((item) => {
          const datacategoryObj = merge({}, getters.getterEmptyDataCategoryRecord, item);

          // console.debug("getterDataCategoriesAsArrayByLc - datacategoryObj", datacategoryObj);

          const result = CommonUtils.translateI18nDataObjectByLc(datacategoryObj, lang, "datacategory_id");

          // console.debug("getterDataCategoriesAsArrayByLc - result", result);

          if (result == false)
            throw "Error in  getterDataCategoriesAsArrayByLc translation";

          datacategorylist.push(result);
        });

        return datacategorylist;
      } catch (error) {
        console.error("getterDataCategoriesAsArrayByLc - Error :", error);
        return [];
      }
    },
    getterCompactDataCategoriesAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterDataCategorysAsArray.map(o => ({
        id: o.datacategory_id,
        name: o.display_translation.name,
        description: o.display_translation.description
      }));
    },
  },
};