export const RETENTION_STARTTIMES_ARRAY = [
    {
        "languages": [
            "DE",
            "GB"
        ],
        "justiceareas": [
            "DE", "CH"
        ],
        "translations": {
            "DE": {
                "printtext": "nach Erhebung der Daten",
                "name": "Datenerhebung"
            },
            "GB": {
                "printtext": "after collecting the data",
                "name": "After datacollection"
            }
        },
        "id": "ID_RETENTION_START_DATACOLLETION",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "justiceareas": [
            "DE", "CH"
        ],
        "translations": {
            "DE": {
                "printtext": "nach Ende des Vorgangs",
                "name": "Vorgangsende"
            },
            "GB": {
                "printtext": "after the end of the process",
                "name": "End of process"
            }
        },
        "id": "ID_RETENTION_START_ENDOFPROCESS",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "justiceareas": [
            "DE", "CH"
        ],
        "translations": {
            "DE": {
                "printtext": "nach Ende der Beziehung",
                "name": "Beziehungsende"
            },
            "GB": {
                "printtext": "after end of relationship",
                "name": "End of relation"
            }
        },
        "id": "ID_RETENTION_START_ENDOFRELATION",
        "deflanguage": "DE"
    },
    {
        "languages": [
            "DE",
            "GB"
        ],
        "justiceareas": [
            "DE", "CH"
        ],
        "translations": {
            "DE": {
                "printtext": "nach Ende der Einwilligung",
                "name": "Einwilligungsende"
            },
            "GB": {
                "printtext": "after end of consent",
                "name": "end of consent"
            }
        },
        "id": "ID_RETENTION_START_WITHDRAWAL",
        "deflanguage": "DE"
    },
];
