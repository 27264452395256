import backendDataApi from '@/services/backendDataApi.service.js';

import merge from 'lodash.merge';

import CommonUtils from '@/utils/common.utils';

import {
  GDPR_DOCUMENT_NAME,
  GDPR_RECORD,
  GDPR_SCHEMA_VERSION,
  GDPR_LAWBASISDATA
} from '@/models/gdpr.couchdb';

export default {
  namespaced: true,
  state: {
    GdprInformation: {
      ...GDPR_RECORD
    }
  },
  mutations: {
    GDPR_LOAD(state, payload) {
      // console.debug("GDPR_LOAD - payload", payload);

      merge(
        state.GdprInformation, {
          ...GDPR_RECORD
        }, payload
      );

      // console.debug("GDPR_LOAD - payload", payload);
      // console.debug("GDPR_LOAD - GDPR_RECORD", GDPR_RECORD);
      // console.debug("GDPR_LOAD - state.GdprInformation", state.GdprInformation);
    },
    GDPR_DATA_SAVE(state, payload) {
      // console.debug("GDPR_DATA_SAVE - payload", payload);
      state.GdprInformation = payload;
    }
  },
  actions: {
    // load gdpr doc from couchDB
    async actionRetrieveGdprDoc({
      state,
      rootGetters,
      dispatch,
      commit
    }) {
      try
      {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(GDPR_DOCUMENT_NAME);

        // console.debug("actionRetrieveGdprDoc - doc", doc);

        if(doc === false)
          throw "Error retrieving Gdpr Data";

        const newGdprInformationObj = await dispatch("actionUpgradeDataObject", doc.GdprInformation)

        // console.debug("actionRetrieveGdprDoc - newGdprInformationObj", newGdprInformationObj);

        commit('GDPR_LOAD', newGdprInformationObj);
        return true;
      }
      catch(err)
      {
        console.error('actionRetrieveGdprDoc - Error : ', err);
        return false;
      }
    },
    async actionSaveGdprInformationInBackend({
      state,
      getters,
      dispatch,
      commit
    }, payload) {
      try {
        // console.debug("actionSaveGdprInformationInBackend - payload", payload);
        return await backendDataApi.saveDocumentContentInBackendByDocId(GDPR_DOCUMENT_NAME, payload);
      }
      catch(err)
      {
        console.error('actionSaveGdprInformationInBackend - Error : ', err);
        return false;
      }
    },
    async actionSaveGdprData({
      state,
      rootGetters,
      getters,
      dispatch,
      commit
    }, payload) {
      try {
        // console.debug("actionactionSaveGdprData - payload", payload);
        const contentObj = {
          GdprInformation: {
            ...state.GdprInformation,
            ...payload
          },
          username: rootGetters["SessionManager/getterCurrentUsername"],
          timestamp: +new Date()
        };

        const result = await dispatch("actionSaveGdprInformationInBackend", contentObj);

        if( result == false)
          throw "Error saving gdpr data";

        commit('GDPR_DATA_SAVE', payload);

        return true;
      }
      catch(err)
      {
        console.error('actionSaveGdprData - Error : ', err);
        return false;
      }
    },
    async actionDeactivateGdprOfficer({
      state,
      rootGetters,
      getters,
      dispatch,
      commit
    }, officer_id) {
      try {
        // console.debug("actionDeactivateGdprOfficer - officer_id", officer_id);
        if(state.GdprInformation.gdprofficer.has_gdprofficer == true && state.GdprInformation.gdprofficer.officer_id == officer_id)
        {
          var gdprObj = {...state.GdprInformation};

          gdprObj.gdprofficer.officer_id = "";
          gdprObj.gdprofficer.has_gdprofficer = false;

          const result = await dispatch("actionSaveGdprData", gdprObj);

          if( result == false)
            throw "Error saving gdpr data";
        }

        return true;
      }
      catch(err)
      {
        console.error('actionDeactivateGdprOfficer - Error : ', err);
        return false;
      }
    },
    async actionReceiveCurrentGdprOfficerData({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, payload) {
      try
      {
        return await dispatch('OfficerManager/actionReceiveCurrentGdprOfficerData', false, {root: true});
      }
      catch(err)
      {
        console.error("actionReceiveCurrentGdprOfficerData - Error : ", err);
        return false;
      }
    },
    async actionReceiveCurrentGdprEuRepresentantData({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, payload) {
      try
      {
        return await dispatch('OfficerManager/actionReceiveCurrentGdprEuRepresentantData', false, {root: true});
      }
      catch(err)
      {
        console.error("actionReceiveCurrentGdprEuRepresentantData - Error : ", err);
        return false;
      }
    },
    async actionReceiveCurrentGdprAgencyDataAsArray({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, payload) {
      try
      {
        const result = await dispatch('actionReceiveCurrentGdprAgencyData');

        if(result === undefined || !result || (Object.keys(result).length === 0 && result.constructor === Object))
          throw "Error actionReceiveCurrentGdprAgencyData not found";

        return [
          result.name,
          result.address,
          result.phone_0,
          result.fax,
          result.email_0,
          result.website,
          result.state
        ];
      }
      catch(err)
      {
        console.error("actionReceiveCurrentGdprAgencyData - Error : ", err);
        return [];
      }
    },
    async actionReceiveCurrentGdprAgencyData({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, payload) {
      try
      {
        const result = await dispatch('actionRetrieveGdprDoc');

        if(result == undefined || !result)
          throw "Error actionRetrieveGdprDoc not found";

        await dispatch('AgencyManager/actionRetrieveAgenciesGDPRDoc',false, {root: true});

        const agencyarray =
          rootGetters['AgencyManager/getterAgenciesByCountryByListAsArray'](
            state.GdprInformation.lawbasis.countrycode,
            state.GdprInformation.lawbasis.agencylistcode).filter(item => item.id == state.GdprInformation.agency.agencycode);

        if(agencyarray.length <= 0)
          throw "Error agency not found";

        return {...agencyarray[0]};
      }
      catch(err)
      {
        console.error("actionReceiveCurrentGdprAgency - Error : ", err);
        return false;
      }
    },
    async actionReceiveCurrentGdprPrintInfo({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, payload) {
      try
      {
        var officerObj = await dispatch('actionReceiveCurrentGdprOfficerData');
        var agencyObj = await dispatch('actionReceiveCurrentGdprAgencyData');
        var representantObj = await dispatch('actionReceiveCurrentGdprEuRepresentantData');

        if(officerObj === false) {
          officerObj = { ...rootGetters["OfficerManager/getterEmptyOfficerRecord"]};
        }

        if(agencyObj === false) {
          agencyObj = {...rootGetters["AgencyManager/getterEmptyAgencyRecord"]};
        }

        agencyObj.phone_0 = agencyObj.phone_0.replace('Tel.', '').replace('Phone','').replace(':','');
        agencyObj.fax = agencyObj.phone_0.replace('Fax', '').replace(':','');
        agencyObj.email_0 = agencyObj.email_0.replace('Mail:', '');
        agencyObj.website = agencyObj.website.replace('Web:', '');

        if(representantObj === false) {
          representantObj = { ...rootGetters["OfficerManager/getterEmptyEuRepresentantRecord"]};
        }

        return {
          settings: { ...getters.getterGdprData},
          officer: {...officerObj},
          representant: {...representantObj},
          agency: {...agencyObj}
        };
      }
      catch(err)
      {
        console.error("actionReceiveCurrentGdprPrintInfo - Error : ", err);
        return {
          settings: {},
          officer: {},
          representant: {},
          agency: {}
        };
      }
    },
    // Data object upgrade function & migration based on version
    async actionUpgradeDataObject(
      {state, getters, rootGetters, commit, dispatch},
      dataObject
    ) {
        try {
        // console.debug("actionUpgradeDataObject - dataObject", dataObject);
        if(rootGetters["getterIsFirstRun"])
          return;

        // Safe guard unnecessary migration based on schema version
        if(Object.prototype.hasOwnProperty.call(dataObject, "version") == true) {
          if(dataObject.version === GDPR_SCHEMA_VERSION)
            return dataObject;
        }

        var newObj = merge({}, getters.getterEmptyGdprRecord, dataObject);

        // Migration of lawbasis
        if(Object.prototype.hasOwnProperty.call(dataObject, "lawbasis")) {
          if( Object.prototype.hasOwnProperty.call(dataObject.lawbasis, "lawbasis_id") == false ||
              CommonUtils.isStringEmpty(dataObject.lawbasis.lawbasis_id))
          {
            if(Object.prototype.hasOwnProperty.call(dataObject.lawbasis, "agencylawcode") == true) {
              newObj.lawbasis.region_override = !dataObject.lawbasis.region_override;

              newObj.lawbasis.lawbasis_id = "ID_LAWBASIS_GDPR_EU";

              if(dataObject.lawbasis.agencylawcode == "GDPR_EU" && dataObject.lawbasis.country == "DE")
                newObj.lawbasis.gdprlawcodes = ["GDPR", "BDSG"];

              if(dataObject.lawbasis.agencylawcode == "KDR_DE") newObj.lawbasis.lawbasis_id = "ID_LAWBASIS_KDG_DE";
              if(dataObject.lawbasis.agencylawcode == "EKD_DE") newObj.lawbasis.lawbasis_id = "ID_LAWBASIS_DSKEKD_DE";

              delete newObj.lawbasis.name;

              await dispatch("actionSaveGdprData", newObj);

              console.warn("actionUpgradeDataObject - GDPR Upgrade done");
            }
          }
        }

        // console.debug("actionUpgradeDataObject In  - dataObject", dataObject);
        // console.debug("actionUpgradeDataObject Out - newObj", newObj);

        return newObj;
      } catch (error) {
        console.error("actionUpgradeDataObject - Error:", error);
        return dataObject;
      }
    },
  },
  getters: {
    getterEmptyGdprRecord: () => {
      return { ...GDPR_RECORD };
    },
    getterGdprData: (state) => {
      return state.GdprInformation;
    },
    // returns Object containing:
    // name:     text with natural name of law or regualtion
    // lawcode: text with code string identifing agency lawcode (GDPR_EU, KDR_DE, DSG_<code>, EKD_DE)
    // region_override: boolean if region should not be used,
    //
    getterGdprDataByInitData:(state, getters, rootState, rootGetters) => (initDataObj) => {
      try {

        if(CommonUtils.isObjectEmpty(initDataObj))
          throw "Empty initData not allowed";

        const lawcontentObj = rootGetters["LawbasisContentManager/getterLawbasisContentById"](initDataObj.gdprlawbasis);

        // console.debug("getterGdprDataByInitData - lawcontentObj", lawcontentObj);

        if (lawcontentObj == false)
          throw "lawcontent not found for init data";

        var gdprDataObj = merge({}, getters.getterEmptyGdprRecord);

        gdprDataObj.agency = {
          agencycode: lawcontentObj.details.agencylistcode + "#" + initDataObj.countrycode + "#" + (lawcontentObj.details.region_override ? initDataObj.regioncode: initDataObj.countrycode),
          valid: true
        }

        // console.debug("getterGdprDataByInitData - gdprDataObj", gdprDataObj);

        gdprDataObj.lawbasis = {
          ...GDPR_LAWBASISDATA,
          lawbasis_id: initDataObj.gdprlawbasis,
          countrycode: initDataObj.countrycode,
          regioncode:  initDataObj.regioncode,
          gdprlawcodes: lawcontentObj.details.lawcodes,
          agencylistcode: lawcontentObj.details.agencylistcode,
          region_override: lawcontentObj.details.region_override,
          valid: true
        };

        return gdprDataObj;
      }
      catch(error) {
        console.error("getterGdprDataByInitData - Error", error);
        return false;
      }
    },
    getterDefaultGdprAgencyCodeByLawbasisData: (state, getters, rootState, rootGetters) => (lawbasisObj) => {
      try {
        return lawbasisObj.agencylistcode + "#" + lawbasisObj.countrycode + "#" + (lawbasisObj.region_override ? lawbasisObj.countrycode: lawbasisObj.regioncode);
      } catch (err) {
        console.error("getterGetDefaultGdprAgencyCodeByLawbasisData - Error: ", err);
        return "";
      }
    },
    getterGdprOfficerIsRecommended: (state) => (num_employees, country_id) => {
      if(state.GdprInformation.gdprofficer.ignore_gdprofficer == true)
        return false;

      if(state.GdprInformation.gdprofficer.has_gdprofficer == true)
        return false;

      var threshold_num_employees = 0;

      switch(country_id)
      {
        case 'DE':
          threshold_num_employees = 0;
          break;

        case 'CH':
          threshold_num_employees = 0;
          break;

        default:
          threshold_num_employees = 0;
          break;
      }

      return (num_employees >= threshold_num_employees);
    },
    getterGdprOfficerIsAssigned: (state) => {
      return (state.GdprInformation.gdprofficer.has_gdprofficer == true && state.GdprInformation.gdprofficer.officer_id) ? state.GdprInformation.gdprofficer.officer_id : false;
    },
    getterEuRepresentativeIsAssigned: (state) => {
      return (state.GdprInformation.representant.has_gdprrepresentant == true && state.GdprInformation.representant.officer_id) ? state.GdprInformation.representant.officer_id : false;
    }
  }
};
