// Application group
export const APP_GROUP_PREFIX = "grp_";

export const APP_GROUP_SUPERVISORS = APP_GROUP_PREFIX + "supervisors";
export const APP_GROUP_COORDINATORS = APP_GROUP_PREFIX + "coordinators";
export const APP_GROUP_USERS = APP_GROUP_PREFIX + "users";
export const APP_GROUP_CONTROLLERS = APP_GROUP_PREFIX + "gdprofficers";
export const APP_GROUP_AUDITORS = APP_GROUP_PREFIX + "auditors";
export const APP_GROUP_AUTHORITIES = APP_GROUP_PREFIX + "authorities";

export const APP_DEFAULT_GROUP = APP_GROUP_USERS;

export const APP_GROUP_ARRAY = [
  APP_GROUP_SUPERVISORS,
  APP_GROUP_USERS,
  APP_GROUP_COORDINATORS,
  APP_GROUP_CONTROLLERS,
  APP_GROUP_AUDITORS,
  APP_GROUP_AUTHORITIES
];

export const APP_PRIVELEGE_PREFIX = "priv_";

// Standard App Priveleges
export const APP_PRIVELEGE_ENTITY = APP_PRIVELEGE_PREFIX + "entity";
export const APP_PRIVELEGE_LOCATION = APP_PRIVELEGE_PREFIX + "location";
export const APP_PRIVELEGE_AREA = APP_PRIVELEGE_PREFIX + "area";
export const APP_PRIVELEGE_PROCESS = APP_PRIVELEGE_PREFIX + "process";

export const APP_PRIVELEGE_ORGCHART = APP_PRIVELEGE_PREFIX + "orgchart";
export const APP_PRIVELEGE_CONTACTBOOK = APP_PRIVELEGE_PREFIX + "contactbook";

export const APP_PRIVELEGE_TASK = APP_PRIVELEGE_PREFIX + "task";
export const APP_PRIVELEGE_PRINT = APP_PRIVELEGE_PREFIX + "print";

export const APP_PRIVELEGE_ASSET = APP_PRIVELEGE_PREFIX + "asset";
export const APP_PRIVELEGE_RISK = APP_PRIVELEGE_PREFIX + "risk";

export const APP_PRIVELEGE_USRMGMT = APP_PRIVELEGE_PREFIX + "usrmgmt";

export const APP_PRIVELEGE_INCIDENT = APP_PRIVELEGE_PREFIX + "incident";
export const APP_PRIVELEGE_REQUEST = APP_PRIVELEGE_PREFIX + "request";
export const APP_PRIVELEGE_PROOF = APP_PRIVELEGE_PREFIX + "proof";
export const APP_PRIVELEGE_PROCEDURE = APP_PRIVELEGE_PREFIX + "procedure";
export const APP_PRIVELEGE_ACTIVITYDOC = APP_PRIVELEGE_PREFIX + "activitydoc";
export const APP_PRIVELEGE_CONTRACTPARTNER = APP_PRIVELEGE_PREFIX + "contractpartner";

export const APP_PRIVELEGE_DOCUMENTARCHIVE = APP_PRIVELEGE_PREFIX + "documentarchive";
export const APP_PRIVELEGE_ATTACHMENT = APP_PRIVELEGE_PREFIX + "attachment";
export const APP_PRIVELEGE_DSFA = APP_PRIVELEGE_PREFIX + "dsfa";

export const APP_PRIVELEGE_ARRAY = [
  APP_PRIVELEGE_ENTITY,
  APP_PRIVELEGE_LOCATION,
  APP_PRIVELEGE_AREA,
  APP_PRIVELEGE_INCIDENT,
  APP_PRIVELEGE_REQUEST,
  APP_PRIVELEGE_PROOF,
  APP_PRIVELEGE_CONTACTBOOK,
  APP_PRIVELEGE_CONTRACTPARTNER,
  APP_PRIVELEGE_PROCESS,
  APP_PRIVELEGE_ACTIVITYDOC,
  APP_PRIVELEGE_PROCEDURE,
  APP_PRIVELEGE_DOCUMENTARCHIVE,
  APP_PRIVELEGE_ATTACHMENT,
  APP_PRIVELEGE_ASSET,
  APP_PRIVELEGE_RISK,
  APP_PRIVELEGE_TASK,
  APP_PRIVELEGE_DSFA,
  APP_PRIVELEGE_USRMGMT,
  APP_PRIVELEGE_ORGCHART,
  APP_PRIVELEGE_PRINT
];
