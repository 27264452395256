import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter);

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);

var DatetimeUtils = {
    getIsValidTime(time, format = "hh:mm") {
      return dayjs(time, format).isValid();
    },
    getIsValidDate(date, format = "YYYY-MM-DD") {
      return dayjs(date, format).isValid();
    },
    getIsValidDateTime(datetime, format = "YYYY-MM-DD hh:mm") {
      return dayjs(datetime, format).isValid();
    },
    getCompareCurrentDateTimeToDeadlineAsText(value, lc) {
      // return dayjs().diff(value, 'hour');
      return dayjs(value).locale(lc).fromNow(false);
    },
    getCurrentDate() {
      return dayjs().format("YYYY-MM-DD");
    },
    getCurrentYear() {
      return dayjs().format("YYYY");
    },
    getCurrentMonth() {
      return dayjs().format("YYYY-MM");
    },
    getTomorrowDate() {
      return dayjs().add(1, 'days').format("YYYY-MM-DD");
    },
    getterCurrentTime() {
      return dayjs().format("hh:mm");
    },
    getCurrentDateTime() {
      return dayjs().format("YYYY-MM-DD HH:mm");
    },
    getCurrentDateTimeLong() {
      return dayjs().format("YYYY-MM-DD HH:mm:ss");
    },
    getCurrentDateTimeCompact() {
      return dayjs().format("YYYYMMDDHHmmss");
    },
    getFormatDate(value) {
      if (isNaN(value))
        return "";
      else
        return dayjs(value).format("YYYY-MM-DD");
    },
    getFormatTime(value) {
      if (isNaN(value))
        return "";
      else
        return dayjs(value).format("HH:mm");
    },
    getYearFromDate(value) {
      if(undefined == value || value == "")
        return "";
      else
        return dayjs(value).year();
    },
    getFormatNewYearDate(value) {
      if(undefined == value || value == "")
        return "";
      else
        return dayjs(value).set('M',0).set('D',1).format("YYYY-MM-DD")
    },
    getDateValueWithFormat(value, format, locale) {
      if(undefined == value || value == "")
        return "";
      else
        return dayjs(value).locale(locale).format(format)
    },
    getFormatDateTimeCompact(value) {
      if (isNaN(value))
        return "";
      else
        return dayjs(value).format("YYYYMMDDHHmmss");
    },
    getFormatDateTime(value) {
      if (isNaN(value))
        return "";
      else
        return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
    getFormatDateTimeLong(value) {
      if (isNaN(value))
        return "";
      else
        return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
    },
    getCalculateDeadlineDateTime(date, time, offset, unit) {
      const deadlineObj = dayjs(date + " " + time, 'YYYY-MM-DD HH:mm');

      return dayjs(deadlineObj).add(offset, unit).format('YYYY-MM-DD HH:mm');
    },
    getCalculateDeadlineDate (date, offset, unit) {
      const deadlineObj = dayjs(date, 'YYYY-MM-DD');

      return dayjs(deadlineObj).add(offset, unit).format('YYYY-MM-DD');
    },
    getCompareDateAndTimeValuesIsBefore(dateA, timeA, dateB, timeB) {
      const datetimeAObj = dayjs(dateA + " " + timeA, 'YYYY-MM-DD HH:mm');
      const datetimeBObj = dayjs(dateB + " " + timeB, 'YYYY-MM-DD HH:mm');

      return datetimeAObj.isBefore(datetimeBObj);
    },
    getCompareDateAndTimeValueDateTimeValueIsBefore(dateA, timeA, datetimeB) {
      const datetimeAObj = dayjs(dateA + " " + timeA, 'YYYY-MM-DD HH:mm');
      const datetimeBObj = dayjs(datetimeB, 'YYYY-MM-DD HH:mm');

      return datetimeAObj.isBefore(datetimeBObj);
    },
    getCompareDateTimeValuesIsBefore(datetimeA, datetimeB) {
      const datetimeAObj = dayjs(datetimeA, 'YYYY-MM-DD HH:mm');
      const datetimeBObj = dayjs(datetimeB, 'YYYY-MM-DD HH:mm');

      return datetimeAObj.isBefore(datetimeBObj);
    },
    getCompareDateValuesIsBefore(dateA, dateB) {
      const dateAObj = dayjs(dateA, 'YYYY-MM-DD');
      const dateBObj = dayjs(dateB, 'YYYY-MM-DD');

      return dateAObj.isBefore(dateBObj);
    },
    getIsCurrentDateBetweenDeadlineDateAndDayOffset(deadlinedate, days_offset) {
      const deadlinedateObj = dayjs(deadlinedate, 'YYYY-MM-DD');
      const offsetdateObj = dayjs(deadlinedate, 'YYYY-MM-DD').day(days_offset);

      // console.debug("getterIsCurrentDateBetweenDeadlineDateAndDayOffset - days_offset", days_offset);
      // console.debug("getterIsCurrentDateBetweenDeadlineDateAndDayOffset - deadlinedateObj", deadlinedateObj);
      // console.debug("getterIsCurrentDateBetweenDeadlineDateAndDayOffset - offsetdateObj", offsetdateObj);

      return dayjs().isAfter(offsetdateObj) && dayjs().isBefore(deadlinedateObj);
    },
    getIsCurrentDateBeforeDeadlineDateWithDayOffset(deadlinedate, days_offset) {
      const offsetdateObj = dayjs(deadlinedate, 'YYYY-MM-DD').day(days_offset);

      return dayjs().isBefore(offsetdateObj);
    },
    getIsCurrentDateAfterDeadline(deadline) {
      try{
        return this.getCompareDateValuesIsBefore(deadline, this.getCurrentDate());
      }
      catch(err)
      {
        return false;
      }
    },
};

export default DatetimeUtils;
