export const PROCEDURESTAGE_I18N_KEY = "procedure_stages.";

export const PROCEDURESTAGE_LIST = {
    "ID_PROCEDURESTAGE_DRAFT": {
      "stage": 1,
      "name": "PROCEDURESTAGE_DRAFT_LABEL",
      "description": "PROCEDURESTAGE_DRAFT_DESCRIPTION"
    },
    "ID_PROCEDURESTAGE_REVIEW": {
      "stage": 2,
      "name": "PROCEDURESTAGE_REVIEW_LABEL",
      "description": "PROCEDURESTAGE_REVIEW_DESCRIPTION"
    },
    "ID_PROCEDURESTAGE_APPROVED": {
      "stage": 3,
      "name": "PROCEDURESTAGE_APPROVED_LABEL",
      "description": "PROCEDURESTAGE_APPROVED_DESCRIPTION"
    }
};
  