import backendDataApi from "@/services/backendDataApi.service.js";

import CommonUtils from '@/utils/common.utils.js';

import {
  LAWBASIS_CONTENT_DOCUMENT_NAME,
  LAWBASIS_SHORTNAME_LENGTH
} from '@/models/lawbasis_content.couchdb';

export default {
  namespaced: true,
  state: {
    LAWBASIS_CONTENT_I18N: {},
    LAWBASIS_CONTENT_I18N_KEY: "",
    LAWBASIS_CONTENT:[],

    LAWBOOKS_ARRAY: []
  },
  mutations: {
    INITLAWBASIS_CONTENT_LOAD(state, payload) {
      // console.debug("LAWBASIS_CONTENT_LOAD - payload", payload);

      state.LAWBASIS_CONTENT_I18N_KEY = payload.I18N_KEY;
      state.LAWBASIS_CONTENT_I18N = { ...payload.I18N};
      state.LAWBASIS_CONTENT = [ ...payload.LIST ];

      // console.debug("LAWBASIS_CONTENT_LOAD - state", state);
    },
    REGULATIONS_INIT(state, payload) {
      // console.debug("REGULATIONS_INIT - payload", payload);
      state.LAWBOOKS_ARRAY = [];

      // console.debug("REGULATIONS_INIT - payload", state);
    },
    REGULATIONS_ADD(state, payload) {
      // console.debug("REGULATIONS_ADD - payload", payload);
      state.LAWBOOKS_ARRAY.push(payload);
      // console.debug("REGULATIONS_ADD - payload", state);
    },
  },
  actions: {
    // load entity_types doc from couchDB
    async actionRetrieveLawbasisContentDoc({
      state,
      rootGetters,
      dispatch,
      commit
    }) {
      try
      {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(LAWBASIS_CONTENT_DOCUMENT_NAME);

        // console.debug("actionRetrieveLawbasisContentDoc - doc", doc);

        if(doc === false)
          throw "Error retrieving lawbasis content Data";

        commit('INITLAWBASIS_CONTENT_LOAD', doc);
        return true;
      }
      catch(err)
      {
        console.error('actionRetrieveLawbasisContentDoc - Error : ', err);
        return false;
      }
    },
    async actionRetrieveAllLawbasisDocs({
      state,
      rootGetters,
      dispatch,
      commit,
    }) {
      try {
        if(CommonUtils.isArrayEmpty(state.LAWBASIS_CONTENT)) {
          if(await dispatch("actionRetrieveLawbasisContentDoc") == false)
            throw "Error loading content data";
        }

        commit("REGULATIONS_INIT");

        for(const lawentry of state.LAWBASIS_CONTENT) {

          if(CommonUtils.isStringEmpty(lawentry.document_id) || CommonUtils.isStringEmpty(lawentry.contenttype))
            continue;

          const loadoption = {
            dbdocname: lawentry.document_id,
            commitname:'REGULATIONS_ADD'
          };

          if(false == await dispatch("actionRetrieveLawbookDoc", loadoption))
            throw "Error actionRetrieveLawbookDoc";
        }

        return true;
      } catch (err) {
        console.error("actionRetrieveAllLawbasisDocs - Error : ", err);
        return false;
      }
    },
    // load any actionRetrieveLawBookDoc doc from couchDB
    async actionRetrieveLawbookDoc({
      state,
      rootGetters,
      dispatch,
      commit,
    }, payload) {
      try {
        // console.debug("actionRetrieveLawbookDoc - dbdocname, commitname", payload.dbdocname, payload.commitname);

        const doc = await backendDataApi.readDocumentByDocIdFromBackend(payload.dbdocname);

        // console.debug("actionRetrieveLawbookDoc - doc", doc);

        if (doc === false)
          throw "Error retrieving actionRetrieveLawbookDoc Data";

        commit(payload.commitname, doc);
        return true;
      } catch (err) {
        console.error("actionRetrieveLawbookDoc - Error : ", err);
        return false;
      }
    },
  },
  getters: {
    getterDefaultGdprLawbasisIdByCountryCodeAndEntityType: (state, getters, rootState, rootGetters) => (country, entity_type) => {
      try {
        // console.debug("getterDefaultGdprLawbasisIdByCountryCodeAndEntityType - country, entity_type", country, entity_type);

        if(CommonUtils.isStringEmpty(country) || CommonUtils.isStringEmpty(entity_type))
          return "";

        // console.debug("getterDefaultGdprLawbasisIdByCountryCodeAndEntityType - state.LAWBASIS_CONTENT", state.LAWBASIS_CONTENT);

        const countrycode = country.toUpperCase();

        var lawcontentArray = [];

        if (countrycode != 'DE') {
          lawcontentArray = state.LAWBASIS_CONTENT.filter(item => (
            item.justice_areas.includes(countrycode) &&
            item.contenttype == "primary" &&
            item.details.lawtype == "gdpr"));
        }
        else
        {
          var entitysubfilter = "*";

          switch(entity_type) {
            case "ID_ENTITYTYPE-EVANGELICALCHURCHORGANISATION":
            case "ID_ENTITYTYPE-CATHOLICORDERORGANISATION":
            case "ID_ENTITYTYPE-CATHOLICCHURCHORGANISATION":
              entitysubfilter = entity_type;
          }

          lawcontentArray = state.LAWBASIS_CONTENT.filter(item => (
            (item.entity_types.includes(entity_type) ||
            item.entity_types.includes(entitysubfilter)) &&
            item.justice_areas.includes(countrycode) &&
            item.contenttype == "primary" &&
            item.details.lawtype == "gdpr"));
        }

        if(CommonUtils.isArrayEmpty(lawcontentArray))
          throw "No default gdpr lawbasis content entry found";

          return lawcontentArray[0].keyid;
      }
      catch(err) {
        console.error("getterDefaultGdprLawbasisIdByCountryCodeAndEntityType - Error: ",err)
        return "";
      }
    },
    getterAvailiableLawbasisContentAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterAvailiableLawbasisContentAsArrayByLc("");
    },
    getterAvailiableLawbasisContentAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      try {
        const lang = CommonUtils.isStringEmpty(lc) ? rootGetters["TranslationManager/getterLanguageAlpha2Code"] : lc;

        return state.LAWBASIS_CONTENT.map(item => ({
          id: item.keyid,
          active: item.active,
          entity_types: item.entity_types,
          justice_areas: item.justice_areas,
          contenttype: item.contenttype,
          documenttype: item.documenttype,
          name: item.name,
          display_name: state.LAWBASIS_CONTENT_I18N.translations[lang][item.name],
          lawtype: item.details.lawtype,
        }));
      }
      catch(err) {
        console.error("getterAvailiableLawbasisContentAsArrayByLc - Error: ",err)
        return [];
      }
    },
    getterAvailiableGdprLawbasisContentAsArrayByCountryCodeAndEntityType: (state, getters, rootState, rootGetters) => (country, entity_type) => {
      // Europe GDPR but filter German sector for religious sector id
      try {
        // console.debug("getterAvailiableGdprLawbasisContentAsArrayByCountryCodeAndEntityType - country, entity_type", country, entity_type);

        if(CommonUtils.isStringEmpty(country) || CommonUtils.isStringEmpty(entity_type))
          return[];

        const countrycode = country.toUpperCase();

        // console.debug("getterAvailiableGdprLawbasisContentAsArrayByCountryCodeAndEntityType - getterAvailiableLawbasisContentAsArray", getters.getterAvailiableLawbasisContentAsArray);

        return getters.getterAvailiableLawbasisContentAsArray.filter(item => item.justice_areas.includes(countrycode) && item.lawtype == 'gdpr' && item.contenttype == "primary");
      }
      catch(err) {
        console.error("getterAvailiableGdprLawbasisContentAsArrayByCountryCodeAndEntityType - Error: ",err)
        return {};
      }
    },
    getterLawbasisContentById:(state, getters, rootState, rootGetters) => (lawcontent_id) => {
      try {
        // console.debug("getterLawbasisContentById - lawcontent_id", lawcontent_id);

        if(CommonUtils.isStringEmpty(lawcontent_id))
          throw "Empty lawcontent_id not allowed";

        const ary = state.LAWBASIS_CONTENT.filter(item => item.keyid == lawcontent_id);

        // console.debug("getterLawbasisContentById - ary", ary);

        if(CommonUtils.isArrayEmpty(ary))
          throw "lawcontent for id not found";

        return ary[0];
      }
      catch(error) {
        console.error("getterLawbasisContentById - Error", error);
        return false;
      }
    },
    getterLawbasisContentByLawbookId:(state, getters, rootState, rootGetters) => (lawbook_id) => {
      try {
        // console.debug("getterLawbasisContentByLawbookId - lawcontent_id", lawcontent_id);

        if(CommonUtils.isStringEmpty(lawbook_id))
          throw "Empty lawbook_id not allowed";

        const ary = state.LAWBASIS_CONTENT.filter(item => item.document_id == lawbook_id);

        // console.debug("getterLawbasisContentByLawbookId - ary", ary);

        if(CommonUtils.isArrayEmpty(ary))
          throw "lawcontent for id not found";

        return ary[0];
      }
      catch(error) {
        console.error("getterLawbasisContentByLawbookId - Error", error);
        return false;
      }
    },
    getterLawbasisContentAsArray: (state, getters, rootState, rootGetters) => {
      return state.LAWBASIS_CONTENT;
    },
    getterAvailiableLawBooksAsArray: (state, getters, rootState, rootGetters) => {
      return state.LAWBOOKS_ARRAY;
    },
    /*
    getterRegulationsAsArrayByCountryByLc: (state, getters, rootState, rootGetters) => (alpha2, lc) => {
      try {
        const lang = CommonUtils.isStringEmpty(lc) ? rootGetters["TranslationManager/getterLanguageAlpha2Code"] : lc;

        var countrycode = alpha2;

        if(CommonUtils.isStringEmpty(countrycode)){
          if (rootGetters["GdprManager/getterGdprData"] == undefined)
          return [];

          if (rootGetters["GdprManager/getterGdprData"].lawbasis == undefined)
            return [];

            countrycode = rootGetters["GdprManager/getterGdprData"].lawbasis.countrycode;
        }

        // returns an array of lawbasis objects with translations
        var ary = [];

        for(const lawbook of getters.getterAvailiableLawBooksAsArray) {
          // console.debug("getterRegulationsAsArrayByCountryByLc - lawbook", lawbook);

          if(!lawbook.justiceareas.includes(countrycode))
            continue;

          var language = lang.toLowerCase();

          if (lawbook.languages.indexOf(language) === -1) {
            language = lawbook.deflanguage;
          }

          // console.debug("getterRegulationsAsArrayByCountryByLc - lawbook, lang, language", lawbook, lang, language);

          for(const regulation of lawbook.lawbasis) {

            // console.debug("getterRegulationsAsArrayByCountryByLc - regulation", regulation);

            var short_displayname = CommonUtils.truncateString(
              regulation.translations[language].name +
              " - " +
              regulation.translations[language].description,
              100
            );

            ary.push({
              lawbookid: lawbook.keyid,
              id: regulation.id,
              code: (regulation.code) ? regulation.code : lawbook.code,
              name: regulation.translations[language].name,
              display_name: short_displayname,
              display_description: regulation.translations[language].description,
            });
          }
        }

        // console.debug("getterRegulationsAsArrayByCountryByLc - ary", ary);

        // Sort A-Z based on display_name
        ary.sort(CommonUtils.getArrayOfObjectDynamicSort("code"));

        return ary;
      }
      catch (err) {
        console.error("getterRegulationsAsArrayByCountryByLc - Error", err);
        return [];
      }
    },
    getterRegulationsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterRegulationsAsArrayByCountryByLc("", lc);
    },
    getterLawBookRegulationsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterLawBookRegulationsAsArrayByCountryByLc("","");
    },
    */
    getterLawBookRegulationsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      return getters.getterLawBookRegulationsAsArrayByCountryByLc("", lc);
    },
    getterLawBookRegulationsAsArrayByCountryByLc: (state, getters, rootState, rootGetters) => (alpha2, lc) => {
      try {
        const lang = CommonUtils.isStringEmpty(lc) ? rootGetters["TranslationManager/getterLanguageAlpha2Code"] : lc;

        var countrycode = alpha2;

        if(CommonUtils.isStringEmpty(countrycode)){
          if (rootGetters["GdprManager/getterGdprData"] == undefined)
          return [];

          if (rootGetters["GdprManager/getterGdprData"].lawbasis == undefined)
            return [];

          countrycode = rootGetters["GdprManager/getterGdprData"].lawbasis.countrycode;
        }

        // returns an array of lawbasis objects with translations
        var ary = [];

        for(const lawbook of getters.getterAvailiableLawBooksAsArray) {
          // console.debug("getterRegulationsAsArrayByCountryByLc - lawbook", lawbook);

          if(!lawbook.justiceareas.includes(countrycode))
            continue;

          var language = lang.toLowerCase();

          if (lawbook.languages.indexOf(language) === -1) {
            language = lawbook.deflanguage;
          }

          const contentObj = getters.getterLawbasisContentByLawbookId(lawbook.keyid);

          for(const regulation of lawbook.lawbasis) {

            // console.debug("getterRegulationsAsArrayByCountryByLc - regulation", regulation);
            var short_displayname = CommonUtils.truncateString(
              regulation.translations[language].name +
              " - " +
              regulation.translations[language].description,
              LAWBASIS_SHORTNAME_LENGTH
            );

            ary.push({
              lawbasisid: contentObj.keyid,
              lawbookid: lawbook.keyid,

              lawtype: contentObj.details.lawtype,

              justiceareas: lawbook.justiceareas,

              id: regulation.id,
              code: (regulation.code) ? regulation.code : lawbook.code,

              sublevel3: regulation.sublevel3,
              sublevel2: regulation.sublevel2,
              sublevel1: regulation.sublevel1,
              section: regulation.section,

              gdpr_id: regulation.gdpr_id,

              display_name: short_displayname,
              display_description: regulation.translations[language].description,

              ...regulation.translations[language]
            });
          }
        }

        // console.debug("getterRegulationsAsArrayByCountryByLc - ary", ary);

        // Sort A-Z based on display_name
        ary.sort(CommonUtils.getArrayOfObjectDynamicSort("id"));

        return ary;
      }
      catch (err) {
        console.error("getterLawBookRegulationsAsArrayByCountryByLc - Error", err);
        return [];
      }
    },
    getterLawBookRegulationById: (state, getters, rootState, rootGetters) => (regulation_id) => {
      try {
        if (CommonUtils.isStringEmpty(regulation_id))
          return false;

        const result = getters.getterLawBookRegulationsAsArrayByCountryByLc("","").filter((item) => item.id === regulation_id);

        if(CommonUtils.isArrayEmpty(result))
          return false;

        const regulation = result[0];

        // console.debug("getterLawBookRegulationById - regulation", regulation);

        if (CommonUtils.isObjectEmpty(regulation))
          return false;

        return regulation;
      } catch (error) {
        console.error("getterLawBookRegulationById - Error", error);
        return false;
      }
    },
  },
};
