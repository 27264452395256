import backendDataApi from '@/services/backendDataApi.service.js';

import {
  CONTACT_RECORD,
  CONTACT_RECORD_TYPE
}  from '@/config/config.couchdb';

export default {
  namespaced: true,
  state: {
    ContactList: []
  },
  mutations: {
    CONTACT_SAVED(state, payload) {},
    CONTACT_REMOVED(state, payload) {},
    CONTACT_CACHE_LOAD(state, payload) {
      // console.debug('ContactBookManager - CONTACT_CACHE_LOAD - payload', payload);
      state.ContactList = payload;
    }
  },
  actions: {
    //
    // private - protected action functions
    //
    async deleteContactByContactIdFromBackend({
      state
    }, contact_id){
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({designdoc: 'contacts/list', keyid: contact_id});
    },

    //find all contacts with limited content (activitydoc_id, process_pid) in the couchdb and return them
    async readContactOverviewlistFromBackend({
      state
    }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({designdoc: 'contacts/overviewlist', keyid: filter});
    },
    //find a contact by id in the couchdb and return it
    async readContactByIdFromBackend({
      state
    }, contact_id){
      return await backendDataApi.readDocumentByKeyIdFromBackend({designdoc: 'contacts/list', keyid: contact_id});
    },
    //create a new contact in the couchdb and return contact_id or false
    async createContactAndStoreInBackend({
      rootGetters,
      commit
    }, data) {
      // Make sure an contact_id is assigned on save for a new incident
      let currentdatetime = +new Date();

      // console.debug("createContactAndStoreInBackend - data", data);

      if(!data.contact_id || 0 === data.contact_id.length)
        data.contact_id = backendDataApi.generateNewCustomKeyId('con');

      // Safe guard to ensure a valid id
      if(!data.contact_id || 0 === data.contact_id.length)
        return false;

      data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
      data.created_at = currentdatetime;

      if(!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_CREATED";
      }

      const record = {
        username: rootGetters['SessionManager/getterCurrentUsername'],

        type: CONTACT_RECORD_TYPE,
        keyid: data.contact_id,

        timeline: [data]
      };

      // console.debug("createContactAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a contact in the couchdb and return contact_id or false
    async updateContactAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      try
      {
        let currentdatetime = +new Date();

        data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
        data.created_at = currentdatetime;

        if(!data.changetype_id || data.changetype_id == 'ID_TIMELINE_CHANGETYPE_CREATED') {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        const record = await dispatch('readContactByIdFromBackend', data.contact_id);

        // console.debug("updateContactAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        let doc = record[0].value;

        // console.debug("updateContactAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      }
      catch(err) {
        console.error("updateContactAndStoreInBackend - Error: ", err);
        return false;
      }
    },

    //
    // Public action functions
    //

    //find a contact by contact_id in the couchdb and return it
    async actionReceiveContactById({
      commit,
      dispatch
    }, contact_id){
      return dispatch('readContactByIdFromBackend', contact_id).then(result => {
        let contactObj = {};

        if (result.length == 1) {
          result.forEach(item => {
            contactObj = {
              ...item.value
            };
          });
        }

        // console.debug("actionReceiveContactById - contactObj", contactObj);

        return contactObj;
      })
      .catch(e => {
        console.error(e);
        return false;
      });
    },
    //delete a existing contact from the couchdb
    async actionDeleteContactById({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, contact_id) {
      try
      {
        // console.debug("actionDeleteContactById", contact_id);

        const result = await dispatch('deleteContactByContactIdFromBackend', contact_id);

        if(result !== false ) {
          // console.debug('actionDeleteContactById - After Update');
          commit('CONTACT_REMOVED', contact_id);

          // Delete attachments
          await dispatch('AttachmentManager/actionDeleteAttachmentsByReference', {refid: contact_id, scope: "contact"}, {root: true});
        }

        return result;
      }
      catch(err)
      {
        console.error("actionDeleteContactById - Error : ", err);
        return false;
      }
    },
    async actionReceiveContactOverviewlistAsArray({
      dispatch
    }, filter ) {
      return dispatch('readContactOverviewlistFromBackend').then(result => {
        let contactList = [];

        if (result.length) {
          result.forEach(contact => {
            contactList.push(contact.value);
          });
        }

        // console.debug(' actionReceiveContactOverviewlistAsArray', contactList);

        return contactList;
      })
      .catch(e => {
        console.error(e);
        return false;
      });
    },
    //save or update a new contact into the couchdb
    async actionSaveContact({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, data) {
      try
      {
        // console.debug("actionSaveContact - data", data);

        let result = false;

        if(!data.contact_id || Object.prototype.hasOwnProperty.call(data, "force_create")) {
          delete data.force_create;
          // console.debug("actionSaveContact - Create Mode", data);
          result = await dispatch('createContactAndStoreInBackend', data);
        }
        else
        {
          if(Object.prototype.hasOwnProperty.call(data, "create_if_needed"))
          {
            // console.debug("actionSaveContact - create_if_needed Mode", data);
            delete data.create_if_needed;

            let contactObj = await dispatch('actionReceiveContactById', data.contact_id);

            if(Object.keys(contactObj).length === 0 && contactObj.constructor === Object) {
              result = await dispatch('createContactAndStoreInBackend', data);
            }
          }

          if(!result)
            // console.debug("actionSaveContact - Update Mode", data);
            result = await dispatch('updateContactAndStoreInBackend', data);
        }

        if(result == false)
          throw "Error saving Contact";

        commit("CONTACT_SAVED", data);

        return result;
      }
      catch(err) {
        console.error("actionSaveContact - Error: ", err);
        return false;
      }
    },
    async actionUpdatEntityContactsByEntityData({
      getters,
      rootGetters,
      commit,
      dispatch
    }, entitydata ) {
      try
      {
        // console.debug("actionUpdatEntityContactsByEntityData - entitydata", entitydata);

        let respcontactObj = {
          ...getters.getterEmptyContactRecord,
          ...entitydata.Responsible,
          name: entitydata.Responsible.responsible_name,
          role_id: 'ID_ENTITYCONTACT_ROLE_RESPONSIBLE',
          location_id: rootGetters["LocationManager/getterLocationIdEntityMain"],
          reference: {
            scope: 'entity',
            refid: ''
          },
          create_if_needed: true
        };

        delete respcontactObj.responsible_name;
        delete respcontactObj.role;

        respcontactObj.contact_id = 'ID_CONTACT_ENTITYRESPONSIBLE';

        const result = await dispatch('actionSaveContact', respcontactObj);

        if(result == false) {
          throw "actionUpdatEntityContactsByEntityData - Error saving contact : " + JSON.stringify(respcontactObj);
        }
      }
      catch(err)
      {
        console.error("actionUpdatEntityContactsByEntityData - Error : ", err);
      }
    },
    // actionRefreshContactsCache
    async actionRefreshContactsCache({
      getters,
      rootGetters,
      commit,
      dispatch
    }) {
      try
      {
        // console.debug("actionRefreshContactsCache");

        let result = await dispatch('readContactOverviewlistFromBackend');

        if(result === false)
          throw "Cannot read contact overviewlist from backend";

        // console.debug("actionRefreshAreasCache - result", result);

        let contactlist = [];

        if (result.length) {
          result.forEach(item => {
            contactlist.push({
              keyid: item.value.contact_id,
              ...item.value
            });
          });
        }

        commit('CONTACT_CACHE_LOAD', contactlist);
      }
      catch(err)
      {
        console.error("actionRefreshContactsCache - Error : ", err);
      }
    },
  },
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  getters: {
    getterEmptyContactRecord: (state) => {
      return {...CONTACT_RECORD};
    },
    getterAllowContactChange: (state,getters) => (contact) => {
      try
      {
        if( getters.getterProtectedEntityContactRolesIDAsArray.indexOf(contact.role_id) !== -1)
          return false;
        else
          return true;
      }
      catch(err) {
        console.error("getterAllowContactChange Error: ", err);
        return true;
      }
    },
    getterProtectedEntityContactRolesIDAsArray: (state) =>  {
      return [
        'ID_ENTITYCONTACT_ROLE_AREARESPONSIBLE',
        'ID_ENTITYCONTACT_ROLE_PROCESSRESPONSIBLE',
        'ID_ENTITYCONTACT_ROLE_RESPONSIBLE',
        'ID_ENTITYCONTACT_ROLE_GDPROFFICER'
      ];
    },
    getterContactsAsArray: (state, getters, rootState, rootGetters) => {
      return state.ContactList;
    },
    getterContactsAsArrayByAreaId: (state, getters, rootState, rootGetters) => (areaid) => {
      return state.ContactList.filter(item => item.refernce.scope == 'area' && item.refernce.refid == areaid);
    },
  },

};