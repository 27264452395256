import PouchDB from 'pouchdb';
import axios from 'axios';
import path from 'path';

import {AuthService} from '@/services/auth.service';

import {
  REMOTE
} from '@/config/config.couchdb';

var DB_INSTANCE = null;
class SingletonDB {
  constructor() {}

  loginTenant(username, password, tenant) {
    DB_INSTANCE = new PouchDB(`${REMOTE}/${tenant}/`, {
      auth: {
        username,
        password
      },
      skip_setup: true
    });
    return DB_INSTANCE.get('');
  }

  loginTenantByToken(dbinstance) {
    DB_INSTANCE = new PouchDB(`${REMOTE}/${dbinstance}/`, {
      fetch: async function (url, opts) {
        try {
          if(AuthService.isAccessTokenExpired())
          {
            if(AuthService.isRefreshTokenExpired() || !AuthService.hasRefreshToken()) {
              // console.debug("loginTenantByToken - isRefreshTokenExpired is true");
              await AuthService.makeLogout(true);
            }

            // console.debug("loginTenantByToken - isAccessTokenExpired is true");

            const response = await AuthService.refreshTokens();

            // console.debug("loginTenantByToken - refreshTokens response", response.success);

            if(response.success !== true) {
              console.error("loginTenantByToken - refreshTokens Error : ", response);
            }
          }

          if(!AuthService.isAccessTokenExpired()) {
            opts.headers.set('Authorization', AuthService.getAccessToken());

            // console.debug("loginTenantByToken - opts", opts);

            return PouchDB.fetch(url, opts);
          }
          else {
            // console.warn("loginTenantByToken - fetch warn - access token expired");
            throw "loginTenantByToken - fetch - no valid access token";
          }
        }
        catch (error) {
          console.error("loginTenantByToken - fetch Error", error);
          await AuthService.makeLogout(true);
          throw error;
        }
      },
      skip_setup: true
    });
    return DB_INSTANCE.get('');
  }

  instance() {
    if(DB_INSTANCE === null || DB_INSTANCE === undefined ) {
      AuthService.makeLogout(true)
        .then(()=>{DB_INSTANCE = null;})
        .catch(()=>{ DB_INSTANCE = null;});
    }

    return DB_INSTANCE;
  }

  async session() {
    try
    {
      const result = await axios.get(new URL(this.getBackendUrlString('_session')), {
          headers: {
            'Authorization': `${AuthService.getAccessToken()}`
          }
        });

      // console.debug("session - result", result);

      return result.data.userCtx;
    }
    catch(err)
    {
      console.error("session - Error: ", err);
      return false;
    }
  }

  logout() {
    if(DB_INSTANCE !== null && DB_INSTANCE !== undefined ) {
      DB_INSTANCE.close().then((result) => {});
    }

    DB_INSTANCE = null;
  }

  getBackendUrlString(urlpath) {
    return new URL(path.join(REMOTE, urlpath)).toString();
  }
}

export default new SingletonDB();