export const LOCATIONTYPE_I18N_KEY = "location_types.";

export const LOCATIONTYPE_LIST = {
    "ID_LOCATIONTYPE_MAIN": {
      "name": "LOCATIONTYPE_MAIN_LABEL",
      "description": "LOCATIONTYPE_MAIN_DESCRIPTION"
    },
    "ID_LOCATIONTYPE_ESTABLISHMENT": {
      "name": "LOCATIONTYPE_ESTABLISHMENT_LABEL",
      "description": "LOCATIONTYPE_ESTABLISHMENT_DESCRIPTION"
    },    
    "ID_LOCATIONTYPE_BRANCH": {
      "name": "LOCATIONTYPE_BRANCH_LABEL",
      "description": "LOCATIONTYPE_BRANCH_DESCRIPTION"
    },
    "ID_LOCATIONTYPE_PRODUCTION": {
      "name": "LOCATIONTYPE_PRODUCTION_LABEL",
      "description": "LOCATIONTYPE_PRODUCTION_DESCRIPTION"
    } 
};
  