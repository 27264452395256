import axios from 'axios';
import path from 'path';

import { ResponseWrapper, ErrorWrapper } from './response.util';

import $store from '../store';
import $router from '../router';

import { BACKENDAPI_HOST, BACKENDAPI_PATH, BACKENDAPI_APPID } from '@/config/config.constants.js';

export class AuthService {
  /**
   ******************************
   * @API
   ******************************
   */
   static async getBackendStatus () {
    try {
      const requestOption = {
        headers : {
          'Content-Type': 'application/json',
          'cache-control': 'no-store'
        }
      };

      const response = await axios.get(this.getBackendUrlString("/status"), requestOption );

      // console.debug("getBackendStatus - response", response);

      return true;
    } catch (error) {
      return false;
    }
  }

  static async makeLogin ({ tenant, username, password }) {
    try {
      const requestOption = {
        headers : {
          'Content-Type': 'application/json',
          'cache-control': 'no-store'
        }
      };

      const response = await axios.post(this.getBackendUrlString("/auth/login"),
        { tenant, username, password, appid: BACKENDAPI_APPID }, requestOption
      );

      // console.debug("makeLogin - response", response);

      _setAuthData({
        accessToken: response.data.token.access,
        refreshToken: response.data.token.refresh,
        exp: _parseTokenData(response.data.token.access).exp,
        sub: _parseTokenData(response.data.token.access).sub
      });

      return new ResponseWrapper(response, response.data.userData);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async forgotLogin({ tenant, email, lang }) {
    try {
      const requestOption = {
        headers : {
          'Content-Type': 'application/json',
          'cache-control': 'no-store'
        }
      };

      const response = await axios.post(this.getBackendUrlString('/auth/forgot'),
        { tenant, email, lang, appid: BACKENDAPI_APPID }, requestOption
      );

      // console.debug("forgotLogin - response", response);
      return new ResponseWrapper(response, response.data.userData);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async resetLogin({ tenant, username, password, securecode, lang }) {
    try {
      const requestOption = {
        headers : {
          'Content-Type': 'application/json',
          'cache-control': 'no-store'
        }
      };

      const response = await axios.post(this.getBackendUrlString("/auth/reset"),
        { tenant, username, password, securecode, lang, appid: BACKENDAPI_APPID }, requestOption
      );

      // console.debug("resetLogin - response", response);
      return new ResponseWrapper(response, response.data.userData);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async makeLogout (forceLoginPage = false) {
    try {
      // console.debug("makeLogout - getBearerRefreshToken", this.getBearerRefreshToken());

      if(!this.hasRefreshToken())
      {
        _resetAuthData();
      }
      else
      {
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': this.getBearerRefreshToken(),
          'cache-control': 'no-store'
        };

        _resetAuthData();

        const response = await axios.post(this.getBackendUrlString('/auth/logout'),
          {},
          {
            headers: headers
          }
        );

        // console.debug("makeLogout - response", response);
      }

      if(forceLoginPage) {
        $router.push({ name: 'login' }).catch(() => {});
        _setForceLoginPageMessageData();
      }

      return true;
    } catch (error) {
      _resetAuthData();
      // throw new ErrorWrapper(error);
      console.error("AuthService - makeLogout Error : ", error);
      return false;
    }
  }

  static async refreshTokens () {
    try {
      // console.debug("refreshTokens - getRefreshBearer", this.getBearerRefreshToken());

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': this.getBearerRefreshToken(),
        'cache-control': 'no-store'
      };

      // console.debug("before - post headers", headers);

      const response = await axios.post(
        this.getBackendUrlString('/auth/token'), {appid: BACKENDAPI_APPID},
        {
          headers: headers
        }
      );

      _setAuthData({
        accessToken: response.data.token.access,
        refreshToken: response.data.token.refresh,
        exp: _parseTokenData(response.data.token.access).exp,
        sub: _parseTokenData(response.data.token.access).sub
      });

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      console.error("refreshTokens - Error: ", error.response.data);

      _resetAuthData();

      // $router.push({ name: 'login' }).catch(() => {});
      throw new ErrorWrapper(error);
    }
  }

  /**
   ******************************
   * @METHODS
   ******************************
   */
  static getBackendUrlString(urlpath) {
    return new URL(path.join(BACKENDAPI_PATH, urlpath), BACKENDAPI_HOST).toString();
  }

  static isAccessTokenExpired () {
    const accessTokenExpDate = $store.getters["AuthTokenManager/getterAccessTokenExpDate"]() - 50;
    const nowTime = Math.floor(new Date().getTime() / 1000);

    return accessTokenExpDate <= nowTime;
  }

  static isRefreshTokenExpired () {
    try
    {
      if($store.getters["AuthTokenManager/getterRefreshToken"]())
      {
        const refreshTokenExpDate = _parseTokenData($store.getters["AuthTokenManager/getterRefreshToken"]()).exp - 100;
        const nowTime = Math.floor(new Date().getTime() / 1000);

        return refreshTokenExpDate <= nowTime;
      }
      else
        return true;
    }
    catch(error)
    {
      console.error("isRefreshTokenExpired - Error:  ", error);
      return true;
    }
  }

  static hasRefreshToken () {
    return (String($store.getters['AuthTokenManager/getterRefreshToken']()).length > 0);
  }

  static setRefreshToken (refreshToken) {
    $store.commit('AuthTokenManager/SET_REFRESHTOKEN', refreshToken);
  }

  static getAccessToken () {
    return `Bearer ${$store.getters['AuthTokenManager/getterAccessToken']()}`;
  }

  static setAccessToken (accessToken) {
    $store.commit('AuthTokenManager/SET_ACCESSTOKEN', accessToken);
  }

  static getBearerRefreshToken () {
    return `Bearer ${$store.getters['AuthTokenManager/getterRefreshToken']()}`;
  }

  static debounceRefreshTokens = this._debounce(async () => {
    // console.debug("debounceRefreshTokens");
    return await this.refreshTokens();
  }, 100);

  /**
   * https://stackoverflow.com/questions/35228052/debounce-function-implemented-with-promises
   * @param inner
   * @param ms
   * @returns {function(...[*]): Promise<unknown>}
   * @private
   */
  static _debounce (inner, ms = 0) {
    let timer = null;
    let resolves = [];

    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        const result = inner();
        resolves.forEach(r => r(result));
        resolves = [];
      }, ms);

      return new Promise(resolve => resolves.push(resolve));
    };
  }
}

/**
 ******************************
 * @private_methods
 ******************************
 */

function _parseTokenData (jwtToken) {
  let payload = '';
  let tokenData = {};

  try {
    payload = jwtToken.split('.')[1];
    tokenData = JSON.parse(Buffer.from(payload, 'base64').toString('ascii'));
  } catch (error) {
    throw new Error(error);
  }

  return tokenData;
}

function _resetAuthData () {
  // reset userData in store
  $store.commit('AuthTokenManager/RESET_STATE');
}

function _setAuthData ({ accessToken, refreshToken,  exp, sub } = {}) {
  // console.debug("_setAuthData - accessToken, refreshToken,  exp", accessToken, refreshToken,  exp);

  $store.commit('AuthTokenManager/SET_ACCESSTOKEN_SUB', sub);
  $store.commit('AuthTokenManager/SET_ACCESSTOKEN_EXP_DATE', exp);
  $store.commit('AuthTokenManager/SET_ACCESSTOKEN', accessToken);
  $store.commit('AuthTokenManager/SET_REFRESHTOKEN', refreshToken);
}

function _setForceLoginPageMessageData () {
  console.debug("_setForceLoginPageMessageData");

  $store.commit('AuthTokenManager/RESET_STATE');
  $store.commit('SessionManager/DESTROY_SESSION');

  $store.commit('SET_MESSAGE_OK_DIALOG', {
      message: 'Session Timeout - Please login again.',
      active: true,
      title: 'Information'
  });
}