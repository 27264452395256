export const USERTASKSTAGE_I18N_KEY = "usertask_stages.";

export const USERTASKSTAGE_LIST = {
    "ID_USERTASKSTAGE_PLANED": {
      "stage": 1,
      "name": "USERTASKSTAGE_PLANED_LABEL",
      "description": "USERTASKSTAGE_PLANED_DESCRIPTION",
      "printtext": "USERTASKSTAGE_PLANED_PRINTTEXT"
    },
    "ID_USERTASKSTAGE_WORK": {
      "stage": 2,
      "name": "USERTASKSTAGE_WORK_LABEL",
      "description": "USERTASKSTAGE_WORK_DESCRIPTION",
      "printtext": "USERTASKSTAGE_WORK_PRINTTEXT"
    },
    "ID_USERTASKSTAGE_CLOSED": {
      "stage": 3,
      "name": "USERTASKSTAGE_CLOSED_LABEL",
      "description": "USERTASKSTAGE_CLOSED_DESCRIPTION",
      "printtext": "USERTASKSTAGE_CLOSED_PRINTTEXT"
    },
    "ID_USERTASKSTAGE_ARCHIVED": {
      "stage": 4,
      "name": "USERTASKSTAGE_ARCHIVED_LABEL",
      "description": "USERTASKSTAGE_ARCHIVED_DESCRIPTION",
      "printtext": "USERTASKSTAGE_ARCHIVED_PRINTTEXT"
    }    
};