export const REPOSITORY_DE_LIST = {
    "_id": "repository",
    "type": "template",
    "subtype": "files",
    "standard": true,
    "creator": "DAPAX",
    "created_at": "2021-08-12",
    "updated_at": "",
    "keyid": "repository",
    "repository": {
        "justiceareas": [
            "DE"
        ],
        "name": "Musterdokumentvorlagen",
        "description": "Musterdokumentvorlagen",
        "I18N_KEY": "repository.",
        "LIST": [
            {
                "filename": "Auskunftsersuchen_Negativbescheid_Muster.docx",
                "name": "auskunftsersuchennegativ_template_name",
                "description": "auskunftsersuchennegativ_template_description",
                "category": "ID_DOCUMENTCATEGORY_REQUEST",
                "type": "ID_DOCUMENTTYPE_REQUESTREPORT"
            },
            {
                "filename": "Auskunftsersuchen_Positivbescheid_Muster.docx",
                "name": "auskunftsersuchenpositiv_template_name",
                "description": "auskunftsersuchenpositiv_template_description",
                "category": "ID_DOCUMENTCATEGORY_REQUEST",
                "type": "ID_DOCUMENTTYPE_REQUESTREPORT"
            },
            {
                "filename": "Datenschutzinformation für Auskunftsersuchende nach DSGVO_Muster.docx",
                "name": "datenschutzinformationauskunft_dsgvo_template_name",
                "description": "datenschutzinformationauskunft_dsgvo_template_description",
                "category": "ID_DOCUMENTCATEGORY_REQUEST",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            },
            {
                "filename": "Datenschutzinformation für Auskunftsersuchende nach DSK-EKD_Muster.docx",
                "name": "datenschutzinformationauskunft_dskekd_template_name",
                "description": "datenschutzinformationauskunft_dskekd_template_description",
                "category": "ID_DOCUMENTCATEGORY_REQUEST",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            },
            {
                "filename": "Datenschutzinformation für Auskunftsersuchende nach KDG_Muster.docx",
                "name": "datenschutzinformationauskunft_kdg_template_name",
                "description": "datenschutzinformationauskunft_kdg_template_description",
                "category": "ID_DOCUMENTCATEGORY_REQUEST",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            },
            {
                "filename": "Datenschutzinformation für Auskunftsersuchende nach KDR-OG_Muster.docx",
                "name": "datenschutzinformationauskunft_kdrog_template_name",
                "description": "datenschutzinformationauskunft_kdrog_template_description",
                "category": "ID_DOCUMENTCATEGORY_REQUEST",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            },
            {
                "filename": "Datenschutzleitlinie_Muster.docx",
                "name": "datenschutzleitlinie_template_name",
                "description": "datenschutzleitlinie_template_description",
                "category": "ID_DOCUMENTCATEGORY_GUIDELINE",
                "type": "ID_DOCUMENTTYPE_CONCEPT"
            },
            {
                "filename": "Dienstanweisung über die Nutzung elektronischer Kommunikationssysteme am Arbeitsplatz_Muster.docx",
                "name": "dienstanweisungkommunikation_template_name",
                "description": "dienstanweisungkommunikation_template_description",
                "category": "ID_DOCUMENTCATEGORY_GUIDELINE",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            },
            {
                "filename": "Erklärung zur Nutzung der dienstlichen E-Mail-Adresse und des dienstlichen Internetzugangs_Muster.docx",
                "name": "dienstanweisunginternet_template_name",
                "description": "dienstanweisunginternet_template_description",
                "category": "ID_DOCUMENTCATEGORY_GUIDELINE",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            },
            {
                "filename": "Löschanweisung_Muster.docx",
                "name": "loeschanweisung_template_name",
                "description": "loeschanweisung_template_description",
                "category": "ID_DOCUMENTCATEGORY_GUIDELINE",
                "type": "ID_DOCUMENTTYPE_CONCEPT"
            },
            {
                "filename": "Löschleitlinie_Muster.docx",
                "name": "loeschleitlinie_template_name",
                "description": "loeschleitlinie_template_description",
                "category": "ID_DOCUMENTCATEGORY_GUIDELINE",
                "type": "ID_DOCUMENTTYPE_CONCEPT"
            },
            {
                "filename": "Richtlinie für Heimarbeit_Muster.docx",
                "name": "richtlinieheimarbeit_template_name",
                "description": "richtlinieheimarbeit_template_description",
                "category": "ID_DOCUMENTCATEGORY_GUIDELINE",
                "type": "ID_DOCUMENTTYPE_CONCEPT"
            },
            {
                "filename": "Vereinbarung zur Auftragsverarbeitung nach DSGVO_Muster.docx",
                "name": "vereinbarungauftragsverarbeitung_template_name",
                "description": "vereinbarungauftragsverarbeitung_template_description",
                "category": "ID_DOCUMENTCATEGORY_CONTRACT",
                "type": "ID_DOCUMENTTYPE_DATAPROCESSINGCONTRACT"
            },
            {
                "filename": "Verpflichtung zur Vertraulichkeit von Beschäftigten nach DSGVO und BDSG_Muster.docx",
                "name": "verpflichtungvertraulichkeit_dsgvo_template_name",
                "description": "verpflichtungvertraulichkeit_dsgvo_template_description",
                "category": "ID_DOCUMENTCATEGORY_GUIDELINE",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            },
            {
                "filename": "Verpflichtung zur Vertraulichkeit von Beschäftigten nach DSG-EKD_Muster.docx",
                "name": "verpflichtungvertraulichkeit_dsgekd_template_name",
                "description": "verpflichtungvertraulichkeit_dsgekd_template_description",
                "category": "ID_DOCUMENTCATEGORY_GUIDELINE",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            },
            {
                "filename": "Verpflichtung zur Vertraulichkeit von Beschäftigten nach KDG_Muster.docx",
                "name": "verpflichtungvertraulichkeit_kdg_template_name",
                "description": "verpflichtungvertraulichkeit_kdg_template_description",
                "category": "ID_DOCUMENTCATEGORY_GUIDELINE",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            },
            {
                "filename": "Verpflichtung zur Vertraulichkeit von Beschäftigten nach KDR-OG_Muster.docx",
                "name": "verpflichtungvertraulichkeit_kdrog_template_name",
                "description": "verpflichtungvertraulichkeit_kdrog_template_description",
                "category": "ID_DOCUMENTCATEGORY_GUIDELINE",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            },
            {
                "filename": "Zusatzvereinbarung nach DSG_EKD zur Vereinbarung zur Auftragsverarbeitung nach DSGVO_Muster.docx",
                "name": "zusatzvereinbarungavv_dskekd_template_name",
                "description": "zusatzvereinbarungavv_dskekd_template_description",
                "category": "ID_DOCUMENTCATEGORY_CONTRACT",
                "type": "ID_DOCUMENTTYPE_DATAPROCESSINGCONTRACT"
            },
            {
                "filename": "Zusatzvereinbarung nach KDG zur Vereinbarung zur Auftragsverarbeitung nach DSGVO_Muster.docx",
                "name": "zusatzvereinbarungavv_kdg_template_name",
                "description": "zusatzvereinbarungavv_kdg_template_description",
                "category": "ID_DOCUMENTCATEGORY_CONTRACT",
                "type": "ID_DOCUMENTTYPE_DATAPROCESSINGCONTRACT"
            },
            {
                "filename": "Zusatzvereinbarung nach KDR-OG zur Vereinbarung zur Auftragsverarbeitung nach DSGVO_Muster.docx",
                "name": "zusatzvereinbarungavv_kdrog_template_name",
                "description": "zusatzvereinbarungavv_kdrog_template_description",
                "category": "ID_DOCUMENTCATEGORY_CONTRACT",
                "type": "ID_DOCUMENTTYPE_DATAPROCESSINGCONTRACT"
            },
            {
                "filename": "Infoschreiben_Veraenderung_DSB.pdf",
                "name": "infoschreiben_veraenderung_dsb_name",
                "description": "infoschreiben_veraenderung_dsb_description",
                "category": "ID_DOCUMENTCATEGORY_INFORMATION",
                "type": "ID_DOCUMENTTYPE_HANDOUTDOCUMENT"
            },
            {
                "filename": "Infoschreiben_Homeoffice.pdf",
                "name": "infoschreiben_homeoffice_name",
                "description": "infoschreiben_homeoffice_description",
                "category": "ID_DOCUMENTCATEGORY_INFORMATION",
                "type": "ID_DOCUMENTTYPE_HANDOUTDOCUMENT"
            },
            {
                "filename": "Infoschreiben_Corona.pdf",
                "name": "infoschreiben_corona_name",
                "description": "infoschreiben_corona_description",
                "category": "ID_DOCUMENTCATEGORY_INFORMATION",
                "type": "ID_DOCUMENTTYPE_HANDOUTDOCUMENT"
            },
            {
                "filename": "Vorblatt_Verarbeitungsverzeichnis_Verantwortlicher.docx",
                "name": "vorblatt_verarbeitungsverzeichnis_verantwortlicher_name",
                "description": "vorblatt_verarbeitungsverzeichnis_verantwortlicher_description",
                "category": "ID_DOCUMENTCATEGORY_PROCESS",
                "type": "ID_DOCUMENTTYPE_PROCESSACTIVITY"
            }, 
            {
                "filename": "Verarbeitungsverzeichnis.docx",
                "name": "verarbeitungsverzeichnis_name",
                "description": "verarbeitungsverzeichnis_description",
                "category": "ID_DOCUMENTCATEGORY_PROCESS",
                "type": "ID_DOCUMENTTYPE_PROCESSACTIVITY"
            },
            {
                "filename": "Technische_und_organisatorische_Massnahmen.docx",
                "name": "technische_und_organisatorische_massnahmen_name",
                "description": "technische_und_organisatorische_massnahmen_description",
                "category": "ID_DOCUMENTCATEGORY_PROCEDURE",
                "type": "ID_DOCUMENTTYPE_TOM"
            },  
            {
                "filename": "Erfassungsformular_Datenschutzpanne.docx",
                "name": "erfassungsformular_datenschutzpanne_name",
                "description": "erfassungsformular_datenschutzpanne_description",
                "category": "ID_DOCUMENTCATEGORY_INCIDENT",
                "type": "ID_DOCUMENTTYPE_DATACOLLECTION"
            }                                                   
        ],
        "I18N": {
            "languages": [
                "de",
                "gb"
            ],
            "defaultlang": "de",
            "translations": {
                "de": {
                    "auskunftsersuchennegativ_template_name": "Auskunftsersuchen - Negativbescheid",
                    "auskunftsersuchennegativ_template_description": "Musterdokument für nicht umsetzbare Auskunftsersuchen",
                    "auskunftsersuchenpositiv_template_name": "Auskunftsersuchen - Positivbescheid",
                    "auskunftsersuchenpositiv_template_description": "Musterdokument für umsetzbare Auskunftsersuchen",
                    "datenschutzinformationauskunft_dsgvo_template_name": "Datenschutzinformation für Auskunftsersuchende",
                    "datenschutzinformationauskunft_dsgvo_template_description": "Musterinformationsschreiben zum Datenschutz bei Auskunftsersuchen nach DSGVO",
                    "datenschutzinformationauskunft_dskekd_template_name": "Datenschutzinformation für Auskunftsersuchende (ev. Kirche)",
                    "datenschutzinformationauskunft_dskekd_template_description": "Musterinformationsschreiben zum Datenschutz bei Auskunftsersuchen nach DSK-EKD",
                    "datenschutzinformationauskunft_kdg_template_name": "Datenschutzinformation für Auskunftsersuchende (kath. Kirche)",
                    "datenschutzinformationauskunft_kdg_template_description": "Musterinformationsschreiben zum Datenschutz bei Auskunftsersuchen nach KDG",
                    "datenschutzinformationauskunft_kdrog_template_name": "Datenschutzinformation für Auskunftsersuchende (kath. Orden)",
                    "datenschutzinformationauskunft_kdrog_template_description": "Musterinformationsschreiben zum Datenschutz bei Auskunftsersuchen nach KDR-OG",
                    "datenschutzleitlinie_template_name": "Datenschutzleitlinie",
                    "datenschutzleitlinie_template_description": "Musterleitlinie zum allgemeinen Verständnis und der Sicherstellung des Datenschutzes",
                    "dienstanweisungkommunikation_template_name": "Vereinbarung mit Beschäftigten zur IT-Nutzung",
                    "dienstanweisungkommunikation_template_description": "Mustererklärung zur Nutzung elektronischer Kommunikationssysteme am Arbeitsplatz",
                    "dienstanweisunginternet_template_name": "Vereinbarung mit Beschäftigten zur E-Mail und Internet Nutzung",
                    "dienstanweisunginternet_template_description": "Mustererklärung zur privaten E-Mail und Internet Nutzung durch Beschäftigte",
                    "loeschanweisung_template_name": "Löschanweisung",
                    "loeschanweisung_template_description": "Musterdokumentation der konkreten Löschumsetzung",
                    "loeschleitlinie_template_name": "Löschleitlinie",
                    "loeschleitlinie_template_description": "Musterkonzept zum sicheren Löschen und Vernichten von Daten",
                    "richtlinieheimarbeit_template_name": "Richtlinie für Heimarbeit",
                    "richtlinieheimarbeit_template_description": "Musterrichtlinie zur Sicherstellung des Datenschutz im Home-Office",
                    "vereinbarungauftragsverarbeitung_template_name": "Vereinbarung zur Auftragsverarbeitung",
                    "vereinbarungauftragsverarbeitung_template_description": "Musterdokument zur Datenschutzvereinbarung mit einem Dienstleister",
                    "verpflichtungvertraulichkeit_dsgvo_template_name": "Verpflichtung zur Vertraulichkeit von Beschäftigten",
                    "verpflichtungvertraulichkeit_dsgvo_template_description": "Musterdokument zur Vertraulichkeitsverpflichtung von Beschäftigten nach DSGVO",
                    "verpflichtungvertraulichkeit_dsgekd_template_name": "Verpflichtung zur Vertraulichkeit von Beschäftigten (ev. Kirche)",
                    "verpflichtungvertraulichkeit_dsgekd_template_description": "Musterdokument zur Vertraulichkeitsverpflichtung von Beschäftigten nach DSG-EKD",
                    "verpflichtungvertraulichkeit_kdg_template_name": "Verpflichtung zur Vertraulichkeit von Beschäftigten (kath. Kirche)",
                    "verpflichtungvertraulichkeit_kdg_template_description": "Musterdokument zur Vertraulichkeitsverpflichtung von Beschäftigten nach KDG",
                    "verpflichtungvertraulichkeit_kdrog_template_name": "Verpflichtung zur Vertraulichkeit von Beschäftigten (kath. Orden)",
                    "verpflichtungvertraulichkeit_kdrog_template_description": "Musterdokument zur Vertraulichkeitsverpflichtung von Beschäftigten  nach KDR-OG",
                    "zusatzvereinbarungavv_dskekd_template_name": "Zusatzvereinbarung zur Auftragsverarbeitung (ev. Kirche)",
                    "zusatzvereinbarungavv_dskekd_template_description": "Zusatzvereinbarungsmuster für Auftragsverarbeitung nach DSGVO für kirchliche Stellen nach DSG-EKD",
                    "zusatzvereinbarungavv_kdg_template_name": "Zusatzvereinbarung zur Auftragsverarbeitung (kath. Kirche)",
                    "zusatzvereinbarungavv_kdg_template_description": "Zusatzvereinbarungsmuster für Auftragsverarbeitung nach DSGVO für kirchliche Stellen nach KDG",
                    "zusatzvereinbarungavv_kdrog_template_name": "Zusatzvereinbarung zur Auftragsverarbeitung (kath. Orden)",
                    "zusatzvereinbarungavv_kdrog_template_description": "Zusatzvereinbarungsmuster für Auftragsverarbeitung nach DSGVO für kirchliche Stellen nach KDR-OG",
                    "infoschreiben_veraenderung_dsb_name": "Mandanteninformation - Veränderung DSB",
                    "infoschreiben_veraenderung_dsb_description": "Information zu Maßnahmen bei Veränderung des Datenschutzbeauftragten",
                    "infoschreiben_homeoffice_name": "Mandanteninformation - Datenschutz im Homeoffice",
                    "infoschreiben_homeoffice_description": "Information zum Datenschutz im Homeoffice",
                    "infoschreiben_corona_name": "Mandanteninformation - Datenschutz in der Corona Pandemie",
                    "infoschreiben_corona_description": "Datenschutz in der Corona Pandemie",
                    "vorblatt_verarbeitungsverzeichnis_verantwortlicher_name": "Vorlage - Vorblatt Verarbeitungsverzeichnis",
                    "vorblatt_verarbeitungsverzeichnis_verantwortlicher_description": "Vorblatt für Verarbeitungsverzeichnis in der Rolle als Verantwortlicher",
                    
                    "verarbeitungsverzeichnis_name": "Vorlage - Verarbeitungsverzeichnis",
                    "verarbeitungsverzeichnis_description": "Dokumentvorlage für ein Verarbeitungsverzeichnis",
                    
                    "technische_und_organisatorische_massnahmen_name": "Vorlage - Technische und organisatorische Massnahmen Dokumentation",
                    "technische_und_organisatorische_massnahmen_description": "Technische und organisatorische Massnahmen (ToM) erfassen",
                    
                    "erfassungsformular_datenschutzpanne_name": "Vorlage - Erfassungsformular Datenschutzpanne",
                    "erfassungsformular_datenschutzpanne_description": "Datenerhebung und -erfasung in Rahmen einer Datenschutzpanne"
                },
                "gb": {
                    "auskunftsersuchennegativ_template_name": "Request for information - negative decision",
                    "auskunftsersuchennegativ_template_description": "Sample document for non-feasible requests for information",
                    "auskunftsersuchenpositiv_template_name": "Request for information - positive decision",
                    "auskunftsersuchenpositiv_template_description": "Sample document for actionable requests for information",
                    "datenschutzinformationauskunft_dsgvo_template_name": "Data protection information for those seeking information",
                    "datenschutzinformationauskunft_dsgvo_template_description": "Sample information letter on data protection for requests for information according to GDPR",
                    "datenschutzinformationauskunft_dskekd_template_name": "Data protection information for those seeking information (possibly church)",
                    "datenschutzinformationauskunft_dskekd_template_description": "Sample information letter on data protection for requests for information according to DSK-EKD",
                    "datenschutzinformationauskunft_kdg_template_name": "Data protection information for those seeking information (Catholic Church)",
                    "datenschutzinformationauskunft_kdg_template_description": "Sample information letter on data protection for requests for information according to KDG",
                    "datenschutzinformationauskunft_kdrog_template_name": "Data protection information for those seeking information (Catholic order)",
                    "datenschutzinformationauskunft_kdrog_template_description": "Sample information letter on data protection for requests for information according to KDR-OG",
                    "datenschutzleitlinie_template_name": "Data protection guideline",
                    "datenschutzleitlinie_template_description": "Model guideline for general understanding and ensuring data protection",
                    "dienstanweisungkommunikation_template_name": "Agreement with employees on the use of IT",
                    "dienstanweisungkommunikation_template_description": "Sample declaration for the use of electronic communication systems in the workplace",
                    "dienstanweisunginternet_template_name": "Agreement with employees on e-mail and Internet use",
                    "dienstanweisunginternet_template_description": "Sample declaration for private e-mail and Internet use by employees",
                    "loeschanweisung_template_name": "Delete instruction",
                    "loeschanweisung_template_description": "Sample documentation of the specific deletion implementation",
                    "loeschleitlinie_template_name": "Deletion Guideline",
                    "loeschleitlinie_template_description": "Sample concept for the secure deletion and destruction of data",
                    "richtlinieheimarbeit_template_name": "Home Work Policy",
                    "richtlinieheimarbeit_template_description": "Model guideline for ensuring data protection in the home office",
                    "vereinbarungauftragsverarbeitung_template_name": "Agreement on order processing",
                    "vereinbarungauftragsverarbeitung_template_description": "Sample document for the data protection agreement with a service provider",
                    "verpflichtungvertraulichkeit_dsgvo_template_name": "Employee confidentiality obligation",
                    "verpflichtungvertraulichkeit_dsgvo_template_description": "Sample document on the confidentiality obligation of employees according to GDPR",
                    "verpflichtungvertraulichkeit_dsgekd_template_name": "Obligation to confidentiality of employees (possibly church)",
                    "verpflichtungvertraulichkeit_dsgekd_template_description": "Sample document on the confidentiality obligation of employees according to DSG-EKD",
                    "verpflichtungvertraulichkeit_kdg_template_name": "Obligation to keep employees confidential (Catholic Church)",
                    "verpflichtungvertraulichkeit_kdg_template_description": "Sample document on the confidentiality obligation of employees according to the KDG",
                    "verpflichtungvertraulichkeit_kdrog_template_name": "Obligation to keep employees confidential (Catholic order)",
                    "verpflichtungvertraulichkeit_kdrog_template_description": "Sample document on the confidentiality obligation of employees according to KDR-OG",
                    "zusatzvereinbarungavv_dskekd_template_name": "Additional agreement for order processing (possibly church)",
                    "zusatzvereinbarungavv_dskekd_template_description": "Additional sample agreement for order processing according to GDPR for church bodies according to DSG-EKD",
                    "zusatzvereinbarungavv_kdg_template_name": "Additional agreement for order processing (Catholic Church)",
                    "zusatzvereinbarungavv_kdg_template_description": "Additional sample agreement for order processing according to GDPR for church bodies according to KDG",
                    "zusatzvereinbarungavv_kdrog_template_name": "Additional agreement for order processing (Catholic order)",
                    "zusatzvereinbarungavv_kdrog_template_description": "Additional sample agreement for order processing according to GDPR for church bodies according to KDR-OG",
                    "infoschreiben_veraenderung_dsb_name": "Information - GDPR Officer changes",
                    "infoschreiben_veraenderung_dsb_description": "Advice in regards to GDPR Officer changes",
                    "infoschreiben_homeoffice_name": "Information - Homeoffice and data security",
                    "infoschreiben_homeoffice_description": "Advice in regards to homeoffice and data security",
                    "infoschreiben_corona_name": "Information - Corona Pandemie and GDPR",
                    "infoschreiben_corona_description": "Advice in regards to COVID-19 impacts and GDPR",
                    "vorblatt_verarbeitungsverzeichnis_verantwortlicher_name": "Template - processing list template",
                    "vorblatt_verarbeitungsverzeichnis_verantwortlicher_description": "Form sheet for processing list in the role as controller",
                    
                    "verarbeitungsverzeichnis_name": "Template - Processing Directory",
                    "verarbeitungsverzeichnis_description": "Sample template for keeping a processing register",
                    
                    "technische_und_organisatorische_massnahmen_name": "Template - Technical and organizational measures documentation",
                    "technische_und_organisatorische_massnahmen_description": "Capture technical and organizational measures (ToM)",
                    
                    "erfassungsformular_datenschutzpanne_name": "Template - data protection glitch registration form",
                    "erfassungsformular_datenschutzpanne_description": "Data collection and recording in the context of a data protection breach"
                }
            }
        }
    }
}