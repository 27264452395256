<template>
    <v-footer app :elevation="6">
      <span v-html="`${app_title} Version ${currentYear} # ${app_version} &copy;  ${app_vendor}`"></span>

      <template v-if="getterIsAuthenticated">
        <span class="ml-2" v-if="!isStringEmpty(getterCurrentUserTenant)">{{ '| ' + $t('footerbar.label_tenant') + getterCurrentUserTenant}}</span>
        <span class="ml-2" v-if="!isStringEmpty(getterCurrentUsername)">{{ '| ' + $t('footerbar.label_currentuser') + ' : ' +  getterCurrentUserDisplayname + ' (' + getterCurrentUsername +')'}}</span>
        <template v-if="showExtStatusInfo">
          <span class="ml-2" v-if="!isStringEmpty(getterLicensedPlan)">{{ '| Instance : ' + getterCurrentDBInstance}}</span>
          <span class="ml-2" v-if="!isStringEmpty(getterLicensedPlan)">{{ '| License : ' + getterLicensedPlan}}</span>
        </template>
      </template>

      <v-spacer></v-spacer>

      <span class="mx-4" v-if="!isStringEmpty(app_imprint_url)">
        <a class="footer-link" :href="app_imprint_url" target="_blank" rel="noopener noreferrer">{{$t('footerbar.label_imprint')}}</a>
      </span>
      <span class="mx-4" v-if="!isStringEmpty(app_terms_url)">
        <a class="footer-link" :href="app_terms_url" target="_blank" rel="noopener noreferrer">{{$t('footerbar.label_terms')}}</a>
      </span>
      <span class="mx-4 " v-if="!isStringEmpty(app_privacypolicy_url)">
        <a class="footer-link" :href="app_privacypolicy_url" target="_blank" rel="noopener noreferrer">{{$t('footerbar.label_privacypolicy')}}</a>
      </span>
    </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FooterBar",
  props: {
    showExtStatusInfo: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => {
    return {
      app_title: process.env.VUE_APP_TITLE,
      app_version: process.env.VUE_APP_VERSION,
      app_vendor: process.env.VUE_APP_VENDOR,
      app_imprint_url: process.env.VUE_APP_IMPRINT_URL,
      app_terms_url: process.env.VUE_APP_TERMS_URL,
      app_privacypolicy_url: process.env.VUE_APP_PRIVACYPOLICY_URL
    }
  },
  mounted() {},
  methods: {},
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    ...mapGetters({
      getterIsAuthenticated: "SessionManager/getterIsAuthenticated",
      getterCurrentUsername: "SessionManager/getterCurrentUsername",
      getterCurrentUserDisplayname: "SessionManager/getterCurrentUserDisplayname",
      getterCurrentUserTenant: "SessionManager/getterCurrentUserTenant",
      getterCurrentDBInstance: "SessionManager/getterCurrentDBInstance",
      getterLicensedPlan: "LicenseManager/getterLicensedPlan",
      lang: "TranslationManager/lang",
      getterLanguageBCP47Tag: "TranslationManager/getterLanguageBCP47Tag",
    }),
  }
};
</script>

<style scoped lang="css">
a.footer-link {
  text-decoration: none;
  background-color: transparent;
}

.theme--dark a.footer-link:hover {
  color: rgba(255,255,255, 0.5);
}

.theme--light a.footer-link:hover {
  color: rgba(0,0,0, 0.5);
}

.theme--light a.footer-link {
  color: unset;
}

.theme--dark a.footer-link {
  color: unset;
}
</style>