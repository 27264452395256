export const INCIDENTRISKCATEGORY_I18N_KEY = "incident_riskcategories.";

export const INCIDENTRISKCATEGORY_LIST = {

  "ID_INCIDENTRISKCATEGORY_LOSSOFCONFIDENTIALITY": {
    "name": "INCIDENTRISKCATEGORY_LOSSOFCONFIDENTIALITY_LABEL", 
    "description": "INCIDENTRISKCATEGORY_LOSSOFCONFIDENTIALITY_DESCRIPTION" 
  }, 
  "ID_INCIDENTRISKCATEGORY_COMPLETENESSOFCONFIDENTIALITY": {
    "name": "INCIDENTRISKCATEGORY_COMPLETENESSOFCONFIDENTIALITY_LABEL", 
    "description": "INCIDENTRISKCATEGORY_COMPLETENESSOFCONFIDENTIALITY_DESCRIPTION" 
  },
  "ID_INCIDENTRISKCATEGORY_AVAILABILITYOFCONFIDENTIALITY": {
    "name": "INCIDENTRISKCATEGORY_AVAILABILITYOFCONFIDENTIALITY_LABEL", 
    "description": "INCIDENTRISKCATEGORY_AVAILABILITYOFCONFIDENTIALITY_DESCRIPTION" 
  } 
};