import DB from '@/services/couchdb.service.js';

const getDefaultState = () => {
  return {
    authdata: {
      account_id: "",
      username: "",
      tenant: ""
    },
    authprofile: {
      displayname: "",
      fullname: "",
      email: "",
      timezone: "",
      language: ""
    },
    appconfig: {
      dbinstance: "",
      usersettings: {
        ui: {
          theme: "dark",
          language: "de",
          timezone: "Europe/Berlin"
        },

        notifyemail: "",
        notifybyemail: false,

        group: "",
        modules: [],
        priveleges: []
      },
    },
    isAuthenticated: false
  };
};

export default {
  namespaced: true,
  state: {
    ...getDefaultState()
  },
  mutations: {
    CREATE_SESSION(state, payload) {
      // console.debug('CREATE_SESSION - payload', payload);
      Object.assign(state, {
        ...payload
      });
    },

    UPDATE_SESSION(state, payload) {
      // console.debug('UPDATE_SESSION');
      Object.assign(state, {
        ...payload
      });
    },

    DESTROY_SESSION(state) {
      // console.debug('DESTROY_SESSION');
      Object.assign(state, {
        ...getDefaultState()
      });
    }
  },
  actions: {
    // destory user session
    async actionDestroySession({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }) {
      try{
        commit('DESTROY_SESSION');
        await DB.logout();

        // console.debug("SessionManager - actionDetroySession - Start");
        try {

          await dispatch('actionResetApplicationState', true, {root:true});
          await dispatch('actionSetDarkThemeMode', true, {root:true});
        }
        catch(err)
        {
          console.error("actionDestroySession - Set UI Theme Error : ", err);
        }

        await dispatch('actionSetLoadingState', false, {root:true});

        // console.debug("SessionManager - actionDetroySession - End");
      }
      catch(err)
      {
        console.error("SessionManager - actionDetroySession", err);
      }
    },

    // connect the user session
    async actionUpdateSession({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }) {
      try
      {
        const userprofile = await dispatch('ProfileManager/actionGetCurrentUserProfile', '', {root:true});

        // console.debug("actionUpdateSession - userprofile", userprofile);

        if(userprofile == false)
          throw "No valid account profile in backend data store found";

        commit('UPDATE_SESSION', {
          authdata: { ...userprofile.authdata },
          authprofile: { ...userprofile.authprofile },
          appconfig: { ...userprofile.appconfig },
          isAuthenticated: true
        });

        // console.debug("actionUpdateSession - state", state);
        return true;
      }
      catch(err)
      {
        console.error('SessionManager - actionUpdateSession Error : ', err);

        await dispatch('actionSetLoadingState', false, {root:true});

        commit('DESTROY_SESSION');
        return false;
      }
    },

    // connect the user session
    async actionCreateSession({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }) {
      try
      {
        await dispatch('actionSetLoadingState', true, {root:true});

        const userprofile = await dispatch('ProfileManager/actionGetCurrentUserProfile', '', {root:true});

        // console.debug("actionCreateSession - userprofile", userprofile);

        if(userprofile == false)
          throw "No valid account profile in backend data store found";

        const dbinstanceName = userprofile.appconfig.dbinstance;

        if(await DB.loginTenantByToken(dbinstanceName) == false)
          throw "Error connecting tenant backend data store";

        // console.debug("actionCreateSession - dbinstanceName", dbinstanceName);

        const dbsessioninfo = await DB.session();

        // console.debug("actionCreateSession - dbsessioninfo", dbsessioninfo);

        if(dbsessioninfo == false)
          throw "Error getting session information from tenant backend data store";

        commit('CREATE_SESSION', {
          authdata: { ...userprofile.authdata },
          authprofile: { ...userprofile.authprofile },
          appconfig: { ...userprofile.appconfig },
          isAuthenticated: true
        });

        // console.debug("actionCreateSession - state", state);

        if(!await dispatch('actionRetrieveCoreData', undefined, {root:true}))
          throw "Error retrieving Application Core Data from tenant backend data store";

        try {
          await dispatch('actionSetDarkThemeMode', state.appconfig.usersettings.ui.theme, {root:true});
          await dispatch('TranslationManager/setLocaleByLc', state.appconfig.usersettings.ui.language, {root:true});
        }
        catch(err)
        {
          console.error("actionCreateSession - Set UI Profile Error : ", err);
        }

        // console.debug("actionCreateSession after - loading state", rootGetters['getterLoadingState']);
        await dispatch('actionSetLoadingState', false, {root:true});

        return true;
      }
      catch(err)
      {
        console.error('SessionManager - actionCreateSession Error : ', err);

        await dispatch('actionSetLoadingState', false, {root:true});

        commit('DESTROY_SESSION');
        return false;
      }
    }
  },
  getters: {
    getterIsAuthenticated: (state) => {
      return (state.isAuthenticated === true);
    },
    getterCurrentAccountId: (state) => {
      if(state.isAuthenticated === true)
        return state.authdata.account_id;
      else
        return false;
    },
    getterCurrentUsername: (state) => {
      if(state.isAuthenticated === true)
        return state.authdata.username;
      else
        return "";
    },
    getterCurrentUserTenant: (state) => {
      if(state.isAuthenticated === true)
        return state.authdata.tenant;
      else
        return "";
    },
    getterCurrentDBInstance: (state) => {
      if(state.isAuthenticated === true)
        return state.appconfig.dbinstance;
      else
        return "";
    },
    getterCurrentUserFullname: (state) => {
      if(state.isAuthenticated === true)
        return state.authprofile.fullname;
      else
        return "";
    },
    getterCurrentUserDisplayname: (state) => {
      if(state.isAuthenticated === true)
        return state.authprofile.displayname;
      else
        return "";
    },
    getterCurrentUserGroup: (state) => {
      if(state.isAuthenticated === true)
        return state.appconfig.usersettings.group;
      else
        return "";
    },
    getterCurrentUserModulesAsArray:  (state) => {
      if(state.isAuthenticated === true)
        return [...state.appconfig.usersettings.modules];
      else
        return [];
    },
    getterCurrentUserPrivilegesAsArray:  (state) => {
      if(state.isAuthenticated === true)
        return [...state.appconfig.usersettings.priveleges];
      else
        return [];
    },
  }
};