export default {
  namespaced: true,
  state: {
    accessTokenExpDate: '',  
    accessToken: '',          
    refreshToken: '',
    accessTokenSubject: ''        
  },
  mutations: {
    RESET_STATE(state) {
      state.accessTokenExpDate = '';
      state.accessToken = '';
      state.refreshToken = '';
      state.accessTokenSubject = '';
    }, 
    SET_ACCESSTOKEN_SUB(state, subject) {
      state.accessTokenSubject = subject;
    },   
    SET_ACCESSTOKEN_EXP_DATE (state, expDate) {
      state.accessTokenExpDate = expDate;
    },
    SET_REFRESHTOKEN (state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    SET_ACCESSTOKEN (state, accessToken) {
      state.accessToken = accessToken;
    },            
  },
  actions: {
  },
  getters: {
    getterAccessTokenExpDate: (state, getters, rootState, rootGetters) => () => {
      return state.accessTokenExpDate;
    },
    getterAccessToken: (state, getters, rootState, rootGetters) => () => {
      return state.accessToken;
    },    
    getterRefreshToken: (state, getters, rootState, rootGetters) => () => {
      return state.refreshToken;
    },
    getterAccessTokenSubject: (state, getters, rootState, rootGetters) => () => {
      return state.accessTokenSubject;
    }    
  }
};