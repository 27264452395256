//
// Entity classification
//

export const ENTITYPATTERN_DOCUMENT_NAME = "entity_patterns";

export const ENTITYPATTERN_RECORD_TYPE = "lookup";
export const ENTITYPATTERN_RECORD_SUBTYPE = "entity";

export const ENTITYPATTERN_RECORD = {
    name: "",
    description: "",
    countries: [],
    sequence: 0
};
