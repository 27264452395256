<template>
  <ModalDialogLayout v-if="isDialogActive"
    persistent 
    :dialog.sync="isDialogActive"
    :width="maxWidth"
    :max-width="maxWidth"
    :content-class="contentClass"  
    @closeModal="buttonOkClick"    
  >
    <template v-slot:title>
      {{ title }}
    </template>

    <template v-slot:subtitle>
      <v-row class="mt-4">
        <v-col cols="12">
          <div class="message-text">
            {{ message }}
          </div>
        </v-col>
      </v-row>
    </template>      

    <template v-slot:content>
      <v-row>
        <v-col cols="12" align="right" class="pa-6">
          <v-btn class="btn-yes" small @click="buttonOkClick">Ok</v-btn>
        </v-col>
      </v-row>   
    </template>
  </ModalDialogLayout>
</template>

<script>

import ModalDialogLayout from "@/layouts/ModalDialogLayout";

export default {
  name: "MessageOkDialog",
  components: {
    ModalDialogLayout
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      required: false,
      default: "600px"
    },
    contentClass: {
      type: String,
      required: false,
      default: ""
    }    
  },
  methods: {
    buttonOkClick() {
      this.isDialogActive = false;
      this.$emit('buttonOkClick'); 
    }
  },
  computed: {
    isDialogActive: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      }
    },     
  },
  watch: {
    isDialogActive: {
      handler: function(new_data) {
         if (new_data === true) {
           this.$forceUpdate();
        }
      }
    }
  }
}
</script>

<style scoped>
.message-text {
  font-size: 115%;
  white-space: pre-line;
}
</style>