const ERROR_MESSAGES = {
    "AppIdUnknown": {
        errorkey: "AppIdUnknown",
        errorcode: 1,
        message: "Account cannot login. ApplicationId is not known or valid."
    },
    "AccountTempLocked": {
        errorkey: "AccountTempLocked",
        errorcode: 2,
        message: "Account username is locked temporarily due to suspicious usage."
    },
    "AccountUnknown": {
        errorkey: "AccountUnknown",
        errorcode: 3,
        message: "Account username is not known or valid."
    },
    "AccountInvalid": {
        errorkey: "AccountInvalid",        
        errorcode: 4,
        message: "Account information is invalid."
    },
    "AccountPermLocked": {
        errorkey: "AccountPermLocked",        
        errorcode: 5,
        message: "Account username is locked permanetly by your account manager."
    },
    "AccountResetInProgess": {
        errorkey: "AccountResetInProgess",           
        errorcode: 6,
        message: "No Account login possible. Account reset in progress."        
    },
    "AccountDisabled": {
        errorkey: "AccountDisabled",           
        errorcode: 7,
        message: "No Account login possible. Account is disabled by your administration."        
    },
    "AccountWrongPassword": {
        errorkey: "AccountWrongPassword",           
        errorcode: 8,
        message: "Account login failed. Provided account password is not correct."        
    },
    "AccountGenericLoginFailed": {
        errorkey: "AccountGenericLoginFailed",           
        errorcode: 9,
        message: "Account login failed. Additional Information "
    }, 
    "AccountGenericForgotLoginFailed": {
        errorkey: "AccountGenericForgotLoginFailed",           
        errorcode: 10,
        message: "Account reset by forgot login failed. Additional Information "
    },
    "AccountNoResetInProgess": {
        errorkey: "AccountNoResetInProgess",           
        errorcode: 11,
        message: "No Account reset possible. Account reset process is not started or timeout grace period reached."        
    },
    "AccountGenericResetLoginFailed": {
        errorkey: "AccountGenericResetLoginFailed",           
        errorcode: 12,
        message: "Account reset failed. Additional Information "
    }, 
    "AccountGenericLogoutFailed": {
        errorkey: "AccountGenericLogoutFailed",           
        errorcode: 13,
        message: "Account logout failed. Additional Information "
    },
    "AccountGenericRateLimitReached": {
        errorkey: "AccountGenericRateLimitReached",           
        errorcode: 14,
        message: "Your ip address exceeded the the request limit"
    },    
    "AccountNotFoundByRefreshToken": {
        errorkey: "AccountNotFoundByRefreshToken",           
        errorcode: 15,
        message: "Account token refresh failed. Additional Information "
    },
    "AccountAccessTokenRefreshFailed": {
        errorkey: "AccountAccessTokenRefreshFailed",           
        errorcode: 16,
        message: "Account token refresh failed. Additional Information "
    },
    "AccountGenericRegisterFailed": {
        errorkey: "AccountGenericRegisterFailed",           
        errorcode: 17,
        message: "Account register failed. Additional Information "
    }, 
    "AccountGenericUpdateFailed": {
        errorkey: "AccountGenericUpdateFailed",           
        errorcode: 18,
        message: "Account update failed. Additional Information "
    }, 
    "AccountGenericDeleteFailed": {
        errorkey: "AccountGenericDeleteFailed",           
        errorcode: 19,
        message: "Account delete failed. Additional Information "
    },
    "AccountGenericPasswordResetFailed": {
        errorkey: "AccountGenericPasswordResetFailed",           
        errorcode: 20,
        message: "Account password reset failed. Additional Information "
    },  
    "AccountAlreadyRegistered": {
        errorkey: "AccountAlreadyRegistered",           
        errorcode: 21,
        message: "Account register failed. Account already registered."
    },
    "AccountProfileGenericError": {
        errorkey: "AccountProfileGenericError",           
        errorcode: 22,
        message: "Account profile operation failed. Additional Information "
    },
    "AccountAppIdUnknown": {
        errorkey: "AccountAppIdUnknown",           
        errorcode: 23,
        message: "Account not allowed for application. ApplicationId not defined for this account"
    }, 
    "AccountLockedForAppId": {
        errorkey: "AccountLockedForAppId",           
        errorcode: 24,
        message: "Account not authorized for this application. ApplicationId blocked for this account"
    },  
    "AccountPasswordTestFailed": {
        errorkey: "AccountPasswordTestFailed",           
        errorcode: 25,
        message: "New account password does not match the required password rules."
    }, 
    "AccountEmailUnknown": {
        errorkey: "AccountEmailUnknown",           
        errorcode: 26,
        message: "Account not found with given email address."
    },   
    "AccountListFailed": {
        errorkey: "AccountListFailed",           
        errorcode: 27,
        message: "Account list could not be generated."
    },  
    "TenantGenericLockFailed": {
        errorkey: "TenantGenericLockFailed",           
        errorcode: 40,
        message: "Tenant lock could not be set."
    },  
    "TenantGenericUnlockFailed": {
        errorkey: "TenantGenericUnlockFailed",           
        errorcode: 41,
        message: "Tenant lock could not be removed."
    }, 
    "TenantLockedByAdmin": {
        errorkey: "TenantLockedByAdmin",           
        errorcode: 42,
        message: "Tenant permanently locked by Administrator."
    },       
    "AccessTokenMissing": {
        errorkey: "AccessTokenMissing",           
        errorcode: 100,
        message: "An access token is required for authentication."
    },
    "AccessTokenBlackListed": {
        errorkey: "AccessTokenBlackListed",           
        errorcode: 101,
        message: "The access token is not allowed due to black list entry."
    }, 
    "AccessTokenExpired": {
        errorkey: "AccessTokenExpired",           
        errorcode: 102,
        message: "The access token is expired and no longer valid."
    }, 
    "AccessTokenGenericError": {
        errorkey: "AccessTokenGenericError",           
        errorcode: 103,
        message: "The access token is invalid. Additional Information "
    }, 
    "RefreshTokenMissing": {
        errorkey: "RefreshTokenMissing",           
        errorcode: 104,
        message: "A refresh token is required for authentication."
    },
    "RefreshTokenUnknown": {
        errorkey: "RefreshTokenUnknown",           
        errorcode: 105,
        message: "The refresh token is unknown."
    }, 
    "RefreshTokenExpired": {
        errorkey: "RefreshTokenExpired",           
        errorcode: 106,
        message: "The refresh token is expired and no longer valid."
    }, 
    "RefreshTokenGenericError": {
        errorkey: "RefreshTokenGenericError",           
        errorcode: 107,
        message: "The refresh token is invalid. Additional Information "
    },     
    "AuthRoleUnknown": {
        errorkey: "AuthRoleUnknown",           
        errorcode: 108,
        message: "The auth role is unknown."
    },
    "AuthRoleNotMatched": {
        errorkey: "AuthRoleNotMatched",           
        errorcode: 109,
        message: "The required auth role is not provided."
    },
    "AccessTokenWrongIssuer": {
        errorkey: "AccessTokenWrongIssuer",           
        errorcode: 110,
        message: "The access token was provided by wrong authority."
    }, 
    "AccessTokenWrongClaim": {
        errorkey: "AccessTokenWrongClaim",           
        errorcode: 110,
        message: "The access token was provided with wrong claim information."
    }      
};

module.exports = {ERROR_MESSAGES};