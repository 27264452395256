//this manager handle all authentication in the system
import * as AppModules from "@/config/config.licmodules.js";
import * as AppPriveleges from "@/config/config.priveleges.js";

import CommonUtils from '@/utils/common.utils.js';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  getters: {
    getterAvailiablePrivelegeGroupsAsArray: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return [...AppPriveleges.APP_GROUP_ARRAY];
    },
    getterCheckCurrentUserGroup:
      (state, getters, rootState, rootGetters) => (group) => {
        try {
          // console.debug("AccessManager - getterCheckCurrentUserGroup");

          return (
            rootGetters["SessionManager/getterIsAuthenticated"] === true &&
            rootGetters["SessionManager/getterCurrentUserGroup"] === group
          );
        } catch (err) {
          return false;
        }
      },
    getterCurrentUserIsSupervisor:
      (state, getters, rootState, rootGetters) => () => {
        return getters.getterCheckCurrentUserGroup(
          AppPriveleges.APP_GROUP_SUPERVISORS
        );
      },
    getterCurrentUserIsCoordinator:
      (state, getters, rootState, rootGetters) => () => {
        return getters.getterCheckCurrentUserGroup(
          AppPriveleges.APP_GROUP_COORDINATORS
        );
      },
    getterCurrentUserIsAuditor:
      (state, getters, rootState, rootGetters) => () => {
        return getters.getterCheckCurrentUserGroup(
          AppPriveleges.APP_GROUP_AUDITORS
        );
      },
    getterCurrentUserIsAuthority:
      (state, getters, rootState, rootGetters) => () => {
        return getters.getterCheckCurrentUserGroup(
          AppPriveleges.APP_GROUP_AUTHORITIES
        );
      },
    getterCurrentUserIsAdminGroup:
      (state, getters, rootState, rootGetters) => () => {
        return (
          getters.getterCurrentUserIsSupervisor() ||
          getters.getterCurrentUserIsCoordinator()
        );
      },
    getterCurrentUserIsReadonlyGroup:
      (state, getters, rootState, rootGetters) => () => {
        return (
          getters.getterCurrentUserIsAuditor() ||
          getters.getterCurrentUserIsAuthority()
        );
      },
    getterCurrentUserCanManageUsers:
      (state, getters, rootState, rootGetters) => () => {
        return getters.getterCurrentUserIsSupervisor();
      },
    getterCurrentUserCanManageDocuments:
    (state, getters, rootState, rootGetters) => () => {
      return getters.getterCurrentUserIsSupervisor() && getters.getterHasAttachmentModulePrivelege;
    },
    getterCurrentUserCanEditEntity: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCurrentUserIsSupervisor();
    },
    getterCurrentUserGroupCanAccessAttachment: (
      state,
      getters,
      rootState,
      rootGetters
    ) => (deniedGroupsArray) => {
      try
      {
        return !deniedGroupsArray.includes(rootGetters["SessionManager/getterCurrentUserGroup"]);
      }
      catch(error)
      {
        return false;
      }
    },
    getterCanAddLocation: (state, getters, rootState, rootGetters) => {
      try {
        if (!getters.getterCurrentUserIsSupervisor()) return false;

        if (
          Object.prototype.hasOwnProperty.call(
            rootGetters["LicenseManager/getterLicenseData"],
            "limits"
          )
        )
          return (
            getters.getterHasLocationModulePrivelege &&
            rootGetters["LocationManager/getterEntityLocationsAsArray"].length <
            rootGetters["LicenseManager/getterLocationCountLimit"]
          );
        else return false;
      } catch (err) {
        console.error("getterCanAddLocation - Error :", err);
        return false;
      }
    },
    getterCanDeleteLocation: (state) => {
      return false;
    },
    getterCanEditLocation: (state, getters, rootState, rootGetters) => {
      return getters.getterCurrentUserIsSupervisor();
    },
    getterCanAddOfficer: (state, getters, rootState, rootGetters) => {
      try {
        return getters.getterCurrentUserIsSupervisor();
      } catch (err) {
        console.error("getterCanAddOfficer - Error :", err);
        return false;
      }
    },
    getterCanDeleteOfficer: (state) => {
      return false;
    },
    getterCanEditOfficer: (state, getters, rootState, rootGetters) => {
      return getters.getterCurrentUserIsSupervisor();
    },
    // Module privelege functions
    getterCheckUserModulePrivelege: (state, getters, rootState, rootGetters) => (username, module) => {
      try {
        // console.debug("getterCheckUserPrivelege - user, module", username, module);
        // console.debug("getterCheckUserPrivelege - modules", rootGetters['SessionManager/getterCurrentUserModulesAsArray']);

        const result =
          rootGetters["SessionManager/getterIsAuthenticated"] === true &&
          rootGetters[
            "SessionManager/getterCurrentUserModulesAsArray"
          ].includes(module);

        // console.debug("getterCheckUserPrivelege - result", result);

        return result;
      } catch (err) {
        return false;
      }
    },
    getterHasIncidentModulePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_INCIDENT
      );
    },
    getterHasRequestModulePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_REQUEST
      );
    },
    getterHasProcessModulePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_PROCESS
      );
    },
    getterHasProcedureModulePrivelege: (state, getters, rootState, rootGetters) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_PROCEDURE
      );
    },
    getterHasDsfaModulePrivelege: (state, getters, rootState, rootGetters) => {
      return getters.getterHasRiskModulePrivelege && getters.getterHasActivityDocModulePrivelege && getters.getterHasProcedureModulePrivelege
            &&
             getters.getterCheckUserModulePrivelege(
              rootGetters["SessionManager/getterCurrentUsername"], AppModules.LICENSE_MODULE_DSFA);
    },
    getterHasProofModulePrivelege: (state, getters, rootState, rootGetters) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_PROOF
      );
    },
    getterHasAreaModulePrivelege: (state, getters, rootState, rootGetters) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_AREA
      );
    },
    getterHasEntityModulePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_ENTITY
      );
    },
    getterHasLocationModulePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_LOCATION
      );
    },
    getterHasContractPartnerModulePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_CONTRACTPARTNER
      );
    },
    getterHasContactBookModulePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_CONTACTBOOK
      );
    },
    getterHasOrgChartModulePrivelege: (state, getters, rootState, rootGetters) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_ORGCHART
      );
    },
    getterHasDocumentArchiveModulePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_DOCUMENTARCHIVE
      );
    },
    getterHasAttachmentModulePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_ATTACHMENT
      );
    },
    getterHasAssetModulePrivelege: (state, getters, rootState, rootGetters) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_ASSET
      );
    },
    getterHasRiskModulePrivelege: (state, getters, rootState, rootGetters) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_RISK
      );
    },
    getterHasActivityDocModulePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_ACTIVITYDOC
      );
    },
    getterHasTaskModulePrivelege: (state, getters, rootState, rootGetters) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_TASK
      );
    },
    getterHasDfsaModulePrivelege: (state, getters, rootState, rootGetters) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_DSFA
      );
    },
    getterHasUsrMgmtModulePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      return getters.getterCheckUserModulePrivelege(
        rootGetters["SessionManager/getterCurrentUsername"],
        AppModules.LICENSE_MODULE_USRMGMT
      );
    },
    getterHasManagePrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      try {
        return (
          getters.getterCurrentUserIsSupervisor() ||
          getters.getterCurrentUserIsCoordinator()
        );
      } catch (err) {
        console.error("getterHasManagePrivelege - Error: ", err);
        return false;
      }
    },
    getterHasGotoPrivelegeWithOwnerCheck: (
      state,
      getters,
      rootState,
      rootGetters
    ) => (privmodule, owner) => {
      try {
        // console.debug("getterHasGotoPrivelegeWithOwnerCheck", privmodule, owner);

        if (CommonUtils.isStringEmpty(privmodule) || CommonUtils.isStringEmpty(owner)) {
          throw "Empty parameters are not allowed";
        }

        const userflag = rootGetters["SessionManager/getterCurrentUsername"] == owner ||
          getters.getterCurrentUserIsSupervisor() ||
          getters.getterCurrentUserIsCoordinator();

        const moduleflag = getters.getterHasGotoPrivelege(privmodule);

        return userflag && moduleflag;
      }
      catch(error)
      {
        console.error("getterHasGotoPrivelegeWithOwnerCheck - Error :", error)
        return false;
      }
    },
    getterHasGotoPrivelege: (
      state,
      getters,
      rootState,
      rootGetters
    ) => (privmodule) => {
      try {
        // console.debug("getterHasGotoPrivelege", privmodule);
        if (CommonUtils.isStringEmpty(privmodule)) {
          throw "Empty parameter is not allowed";
        }

        var modulename = privmodule

        //
        // Ugly hack
        //
        if(modulename == "usertask") {
          modulename = "task";
        }

        if(modulename == "officer") {
          modulename = "entity";
        }

        if(modulename == "contact") {
          modulename = "addressbook";
        }

        return getters.getterCheckUserModulePrivelege(rootGetters["SessionManager/getterCurrentUsername"], modulename);
      }
      catch(error)
      {
        console.error("getterHasGotoPrivelege - Error :", error)
        return false;
      }
    },
    getterCanAddUserAccount: (state, getters, rootState, rootGetters) => () => {
      try {
        if (!getters.getterCurrentUserIsSupervisor()) return false;

        if (
          Object.prototype.hasOwnProperty.call(
            rootGetters["LicenseManager/getterLicenseData"],
            "limits"
          )
        )
          return (
            getters.getterCurrentUserCanManageUsers() &&
            rootGetters["AccountManager/getterActiveUserCount"]() <
            rootGetters["LicenseManager/getterLicenseData"].limits.usrmgmt
              .count_max
          );
        else return false;
      } catch (err) {
        console.error("getterCanAddUserAccount - Error", err);
        return false;
      }
    },
    // Usertask Rights
    getterRightCanModifyUsertask:
      (state, getters, rootState, rootGetters) => (usertaskDataObj) => {
        try {
          if (
            usertaskDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(
              usertaskDataObj,
              "assigned_to"
            )
          )
            throw "Usertask object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] ==
            usertaskDataObj.assigned_to
          );
        } catch (error) {
          console.error("getterRightCanModifyUsertask - Error : ", error);
          return false;
        }
      },
    getterRightCanSeeAllUsertask:
      (state, getters, rootState, rootGetters) => () => {
        try {
          return (
            getters.getterCurrentUserIsSupervisor() ||
            getters.getterCurrentUserIsCoordinator()
          );
        } catch (error) {
          console.error("getterRightCanSeeAllUsertask - Error : ", error);
          return false;
        }
      },
    getterRightCanReclaimUsertask:
      (state, getters, rootState, rootGetters) => (usertaskDataObj) => {
        try {
          if (
            usertaskDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(
              usertaskDataObj,
              "assigned_to"
            )
          )
            throw "Usertask object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] !=
            usertaskDataObj.assigned_to &&
            (getters.getterCurrentUserIsSupervisor() ||
              getters.getterCurrentUserIsCoordinator())
          );
        } catch (error) {
          console.error("getterRightCanReclaimUsertask - Error : ", error);
          return false;
        }
      },
    //Incident Rights
    getterRightCanModifyIncident:
      (state, getters, rootState, rootGetters) => (incidentDataObj) => {
        try {
          if (incidentDataObj === undefined)
            throw "Incident object is undefined";

          return true;
        } catch (error) {
          console.error("getterRightCanModifyIncident - Error : ", error);
          return false;
        }
      },
    getterRightCanReclaimIncident:
      (state, getters, rootState, rootGetters) => (incidentDataObj) => {
        try {
          if (
            incidentDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(
              incidentDataObj,
              "assigned_to"
            )
          )
            throw "Incident object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] !=
            incidentDataObj.assigned_to &&
            (getters.getterCurrentUserIsSupervisor() ||
              getters.getterCurrentUserIsCoordinator())
          );
        } catch (error) {
          console.error("getterRightCanReclaimIncident - Error : ", error);
          return false;
        }
      },
    //Request Rights
    getterRightCanModifyRequest:
      (state, getters, rootState, rootGetters) => (requestDataObj) => {
        try {
          if (
            requestDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(requestDataObj, "assigned_to")
          )
            throw "Request object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] ==
            requestDataObj.assigned_to
          );
        } catch (error) {
          console.error("getterRightCanModifyRequest - Error : ", error);
          return false;
        }
      },
    getterRightCanReclaimRequest:
      (state, getters, rootState, rootGetters) => (requestDataObj) => {
        try {
          if (
            requestDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(requestDataObj, "assigned_to")
          )
            throw "Request object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] !=
            requestDataObj.assigned_to &&
            (getters.getterCurrentUserIsSupervisor() ||
              getters.getterCurrentUserIsCoordinator())
          );
        } catch (error) {
          console.error("getterRightCanReclaimRequest - Error : ", error);
          return false;
        }
      },
    //Proof Rights
    getterRightCanModifyProof:
      (state, getters, rootState, rootGetters) => (proofDataObj) => {
        try {
          if (
            proofDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(proofDataObj, "assigned_to")
          )
            throw "Proof object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] ==
            proofDataObj.assigned_to
          );
        } catch (error) {
          console.error("getterRightCanModifyProof - Error : ", error);
          return false;
        }
      },
    getterRightCanReclaimProof:
      (state, getters, rootState, rootGetters) => (proofDataObj) => {
        try {
          if (
            proofDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(proofDataObj, "assigned_to")
          )
            throw "Proof object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] !=
            proofDataObj.assigned_to &&
            (getters.getterCurrentUserIsSupervisor() ||
              getters.getterCurrentUserIsCoordinator())
          );
        } catch (error) {
          console.error("getterRightCanReclaimProof - Error : ", error);
          return false;
        }
      },
    //Risk Rights
    getterRightCanModifyRisk:
      (state, getters, rootState, rootGetters) => (riskDataObj) => {
        try {
          if (
            riskDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(riskDataObj, "assigned_to")
          )
            throw "Risk object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] ==
            riskDataObj.assigned_to
          );
        } catch (error) {
          console.error("getterRightCanModifyRisk - Error : ", error);
          return false;
        }
      },
    getterRightCanReclaimRisk:
      (state, getters, rootState, rootGetters) => (riskDataObj) => {
        try {
          if (
            riskDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(riskDataObj, "assigned_to")
          )
            throw "Risk object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] !=
            riskDataObj.assigned_to &&
            (getters.getterCurrentUserIsSupervisor() ||
              getters.getterCurrentUserIsCoordinator())
          );
        } catch (error) {
          console.error("getterRightCanReclaimRisk - Error : ", error);
          return false;
        }
      },
    //Dsfa Rights
    getterRightCanModifyDsfa:
      (state, getters, rootState, rootGetters) => (dsfaDataObj) => {
        try {
          if (
            dsfaDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(
              dsfaDataObj,
              "assigned_to"
            )
          )
            throw "Dsfa object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] ==
            dsfaDataObj.assigned_to
          );
        } catch (error) {
          console.error("getterRightCanModifyDsfa - Error : ", error);
          return false;
        }
      },
    getterRightCanReclaimDsfa:
      (state, getters, rootState, rootGetters) => (dsfaDataObj) => {
        try {
          if (
            dsfaDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(
              dsfaDataObj,
              "assigned_to"
            )
          )
            throw "Dsfa object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] !=
              dsfaDataObj.assigned_to &&
            (getters.getterCurrentUserIsSupervisor() ||
              getters.getterCurrentUserIsCoordinator())
          );
        } catch (error) {
          console.error("getterRightCanReclaimDsfa - Error : ", error);
          return false;
        }
      },
      //Procedure Rights
      getterRightCanModifyProcedure:
        (state, getters, rootState, rootGetters) => (procedureDataObj) => {
          try {
            if (
              procedureDataObj === undefined ||
              !Object.prototype.hasOwnProperty.call(
                procedureDataObj,
                "assigned_to"
              )
            )
              throw "Procedure object is undefined";

            return (
              rootGetters["SessionManager/getterCurrentUsername"] ==
              procedureDataObj.assigned_to
            );
          } catch (error) {
            console.error("getterRightCanModifyProcedure - Error : ", error);
            return false;
          }
        },
      getterRightCanReclaimProcedure:
        (state, getters, rootState, rootGetters) => (procedureDataObj) => {
          try {
            if (
              procedureDataObj === undefined ||
              !Object.prototype.hasOwnProperty.call(
                procedureDataObj,
                "assigned_to"
              )
            )
              throw "Procedure object is undefined";

            return (
              rootGetters["SessionManager/getterCurrentUsername"] !=
                procedureDataObj.assigned_to &&
              (getters.getterCurrentUserIsSupervisor() ||
                getters.getterCurrentUserIsCoordinator())
            );
          } catch (error) {
            console.error("getterRightCanReclaimProcedure - Error : ", error);
            return false;
          }
        },
    //Activitydoc Rights
    getterRightCanModifyActivitydoc:
      (state, getters, rootState, rootGetters) => (activitydocDataObj) => {
        try {
          if (
            activitydocDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(activitydocDataObj, "assigned_to")
          )
            throw "Activitydoc object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] ==
            activitydocDataObj.assigned_to
          );
        } catch (error) {
          console.error("getterRightCanModifyActivitydoc - Error : ", error);
          return false;
        }
      },
    getterRightCanReclaimActivitydoc:
      (state, getters, rootState, rootGetters) => (activitydocDataObj) => {
        try {
          if (
            activitydocDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(activitydocDataObj, "assigned_to")
          )
            throw "Activitydoc object is undefined";

          return (
            rootGetters["SessionManager/getterCurrentUsername"] !=
            activitydocDataObj.assigned_to &&
            (getters.getterCurrentUserIsSupervisor() ||
              getters.getterCurrentUserIsCoordinator())
          );
        } catch (error) {
          console.error("getterRightCanReclaimActivitydoc - Error : ", error);
          return false;
        }
      },
  },
};
