import {
  REPOSITORY_DE_LIST
} from '@/lookup/repository_de';

import {
  REPOSITORY_CH_LIST
} from '@/lookup/repository_ch';

import {
  REPOSITORY_EU_LIST
} from '@/lookup/repository_eu';

import cloneDeep from 'lodash.clonedeep';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  getters: {
    getterRepositoryDocumentCategoryRequest: (state) => {
      return "ID_DOCUMENTCATEGORY_REQUEST";
    },
    getterRepositoryDocumentsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      try {
        let array = [];

        if (rootGetters['EntityManager/getterEntityData'].EntityInfo == undefined)
          return [];

        let country = rootGetters['EntityManager/getterEntityData'].EntityInfo.country;

        let dataObj = {};

        switch(country.toLowerCase()) {
          case 'de':
            dataObj = cloneDeep(REPOSITORY_DE_LIST.repository);
            break;

          case 'ch':
            dataObj = cloneDeep(REPOSITORY_CH_LIST.repository);
            break;

          default: 
            dataObj = cloneDeep(REPOSITORY_EU_LIST.repository);          
            break;
        }

        if (Object.keys(dataObj.I18N).length === 0 && dataObj.I18N.constructor === Object)
          return array;

        if (!Object.prototype.hasOwnProperty.call(dataObj.I18N, "languages"))
          return array;

        // console.debug("getterInternalLookupTranslateArrayByLc - lc", lc);

        if (dataObj.I18N.languages.indexOf(lc) === -1)
          lc = dataObj.I18N.defaultlang;

        for (let index in dataObj.LIST) {
          let obj = {
            ...dataObj.LIST[index],
            id: index,
            country: country.toLowerCase(),
            path: country.toLowerCase(),
            display_name: dataObj.I18N.translations[lc][dataObj.LIST[index]['name']],
            display_description: dataObj.I18N.translations[lc][dataObj.LIST[index]['description']],
            display_category: rootGetters["LookupManager/getterDocumentCategoryById"](dataObj.LIST[index].category).display_name,
            display_type: rootGetters["LookupManager/getterDocumentTypeById"](dataObj.LIST[index].type).display_name
          };

          if (Object.prototype.hasOwnProperty.call(obj, 'printtext') == true) {
            obj.display_printtext = dataObj.I18N['translations'][lc][dataObj.LIST[index]['printtext']];
          }

          array.push(obj);
        }

        // Sort A-Z based on display_name
        array.sort(rootGetters["HelperManager/getterArrayOfObjectDynamicSort"]('display_name'));

        return array;

      } catch (err) {
        console.error("getterRepositoryDocumentsAsArrayByLc - Error", err);
        return [];
      }
    },
  }
};