import backendDataApi from '@/services/backendDataApi.service.js';

import {
  USERTASK_RECORD,
  USERTASK_RECORD_TYPE,
  USERTASK_STAGEHISTORY_RECORD
} from '@/config/config.couchdb';

import {
  USERTASKSTAGE_LIST
} from '@/lookup/usertask_stages';

export default {
  namespaced: true,
  state: {},
  mutations: {
    USERTASK_APPROVED(state, payload) { },
    USERTASK_VALID(state, payload) { },
    USERTASK_SAVED(state, payload) { },
    USERTASK_REMOVED(state, payload) { },
    USERTASK_ASSIGNED(state, payload) { }
  },
  actions: {
    //
    // private - protected action functions
    // 
    async deleteUsertaskByUsertaskIdFromBackend({
      state
    }, usertask_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({ designdoc: 'usertasks/list', keyid: usertask_id });
    },

    //find all process activity with limited content (activitydoc_id, process_pid) in the couchdb and return them
    async readUsertaskOverviewlistFromBackend({
      state
    }, { startfilter, endfilter }) {
      return await backendDataApi.readOverviewlistFromBackendByComplexFilter({ designdoc: 'usertasks/overviewlistrange', startkey: startfilter, endkey: endfilter });
    },
    //find a user task by id in the couchdb and return it
    async readUsertaskByIdFromBackend({
      state
    }, usertask_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({ designdoc: 'usertasks/list', keyid: usertask_id });
    },
    //create a new user task in the couchdb and return usertask_id or false     
    async createUsertaskAndStoreInBackend({
      getters,
      rootGetters,
      commit
    }, data) {
      // Make sure an usertask_id is assigned on save for a new record
      const currentdatetime = +new Date();

      // console.debug("createUsertaskAndStoreInBackend - data", data);

      if (!data.usertask_id || data.usertask_id === undefined)
        data.usertask_id = backendDataApi.generateNewCustomKeyId('utk');

      // Safe guard to ensure a valid id
      if (!data.usertask_id || 0 === data.usertask_id.length)
        return false;

      data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
      data.created_at = currentdatetime;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_DRAFTCREATED";

        data.stagehistory.push({
          ...USERTASK_STAGEHISTORY_RECORD,
          changetype_id: data.changetype_id,
          stage: data.stage,
          created_at: data.created_at,
          created_by: data.created_by,
          finished_at: currentdatetime,
          finished_by: rootGetters['SessionManager/getterCurrentUsername']
        });
      }

      if (!data.assigned_to) {
        data.assigned_to = rootGetters['SessionManager/getterCurrentUsername'];
      }

      if (Object.prototype.hasOwnProperty.call(data, 'newnotes') === true) {
        if (!!data.newnotes && 0 < data.newnotes.length) {
          data.notes = "[" + rootGetters["HelperManager/getterFormatDateTimeLong"](currentdatetime) + " - " + data.created_by + "]\n" + data.newnotes + "\n\n" + data.notes;
        }

        delete data.newnotes;
      }

      const record = {
        username: rootGetters['SessionManager/getterCurrentUsername'],

        type: USERTASK_RECORD_TYPE,
        keyid: data.usertask_id,

        timeline: [data]
      };

      // console.debug("createUsertaskAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a user task in the couchdb and return usertask_id or false   
    async updateUsertaskAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      try {
        const currentdatetime = +new Date();

        data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
        data.created_at = currentdatetime;

        if (Object.prototype.hasOwnProperty.call(data, 'newnotes') === true) {
          if (!!data.newnotes && 0 < data.newnotes.length) {
            data.notes = "[" + rootGetters["HelperManager/getterFormatDateTimeLong"](currentdatetime) + " - " + data.created_by + "]\n" + data.newnotes + "\n\n" + data.notes;
          }

          delete data.newnotes;
        }

        const record = await dispatch('readUsertaskByIdFromBackend', data.usertask_id);

        // console.debug("updateUsertaskAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          throw "Illegal readUsertaskByIdFromBackend result";
        }

        let doc = record[0].value;

        // console.debug("updateUsertaskAndStoreInBackend - Update Mode DOC", doc);

        if (!data.changetype_id || data.changetype_id == 'ID_TIMELINE_CHANGETYPE_CREATED') {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        if (record[0].value.timeline[record[0].value.timeline.length - 1].assigned_to != data.assigned_to && data.changetype_id !== "ID_TIMELINE_CHANGETYPE_ASSIGNRECLAIMED") {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_USERASSIGNED";
        }

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      }
      catch (err) {
        console.error("updateUsertaskAndStoreInBackend - Error: ", err);
        return false;
      }
    },
    //
    // Public action functions
    //

    //find a user task by usertask_id in the couchdb and return it
    async actionReceiveUsertaskById({
      commit,
      dispatch
    }, usertask_id) {
      try {
        const result = await dispatch('readUsertaskByIdFromBackend', usertask_id);

        if (result.length == 1)
          return { ...result[0].value };
        else
          return {};
      }
      catch (err) {
        console.error("actionReceiveUsertaskById Error: ", err);
        return false;
      }
    },
    //find the risk by id in the couchdb and return the last data element
    async actionReceiveCurrentUsertaskDataById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, usertask_id) {
      try {
        let result = await dispatch('actionReceiveUsertaskById', usertask_id);

        if (result == undefined || !result)
          throw "Error risk not found";

        // console.debug("actionReceiveCurrentUsertaskDataById result", result);
        if (!(Object.keys(result).length === 0 && result.constructor === Object))
          return { ...result.timeline[result.timeline.length - 1] };
        else
          return false;
      }
      catch (err) {
        console.error("actionReceiveCurrentUsertaskDataById - Error : ", err);
        return false;
      }
    },
    async actionReceiveUsertaskOverviewAsArray({
      dispatch
    }, stagefilter) {
      try {
        var filterObj = {
          startfilter: [], endfilter: [{}]
        };

        if (Array.isArray(stagefilter) && stagefilter.length == 2) {
          filterObj.startfilter = [stagefilter[0]];
          filterObj.endfilter = [stagefilter[1], {}];
        }

        const result = await dispatch('readUsertaskOverviewlistFromBackend', filterObj);

        // console.debug("actionReceiveUsertaskOverviewAsArray - result", result);

        if (result.length)
          return result.map(o => o.value);
        else
          return [];
      }
      catch (err) {
        console.error("actionReceiveUsertaskOverviewAsArray Error: ", err);
        return false;
      }
    },
    //save or update a new user task into the couchdb
    async actionSaveUsertask({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, data) {
      try {
        // console.debug("actionSaveUsertask", data);
        if (!data.usertask_id) {
          // console.debug("actionSaveUsertask - Create Mode", data);
          const result = await dispatch('createUsertaskAndStoreInBackend', data);

          if (result === false)
            throw "Error creating Usertask";

          commit('USERTASK_SAVED', data);

          return result;
        }

        // console.debug("actionSaveUsertask - Update Mode", data);
        const result = await dispatch('updateUsertaskAndStoreInBackend', data);

        if (result === false)
          throw "Error saving Usertask";

        commit('USERTASK_SAVED', data);

        return result;
      }
      catch (err) {
        console.error("actionSaveUsertask Error: ", err);
        return false;
      }
    },
    //delete a user task from the couchdb
    async actionDeleteUsertaskById({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, usertask_id) {
      try {
        // console.debug("actionDeleteUsertaskById", usertask_id);

        const result = await dispatch('deleteUsertaskByUsertaskIdFromBackend', usertask_id);

        // console.debug('actionDeleteUsertaskById - result', result);

        if (result !== false) {
          // console.debug('actionDeleteUsertaskById - After delete');
          commit('USERTASK_REMOVED', usertask_id);

          // Delete attachments
          await dispatch('AttachmentManager/actionDeleteAttachmentsByReference', { refid: usertask_id, scope: "usertask" }, { root: true });

          // console.debug('actionDeleteUsertaskById - AttachmentManager delete');
        }

        return result;
      }
      catch (err) {
        console.error("actionDeleteUsertaskById - Error : ", err);
        return false;
      }
    },
    // assign a usertask to current user
    async actionAssignUsertask({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, assigndataObj) {
      try {
        // console.debug("actionAssignUsertask - assigndataObj", assigndataObj);
        let usertaskObj = await dispatch('actionReceiveCurrentUsertaskDataById', assigndataObj.key_id);

        if (usertaskObj === false)
          throw "Error reading usertask by id";

        usertaskObj.assigned_to = assigndataObj.newassigned_to;
        usertaskObj.newnotes = assigndataObj.newnotes;
        usertaskObj.changetype_id = assigndataObj.changetype_id;

        let result = await dispatch("actionSaveUsertask", usertaskObj);

        if (result === false)
          throw "Error saving usertask assignto change";

        commit('USERTASK_ASSIGNED', usertaskObj);

        return result;
      }
      catch (err) {
        console.error("actionAssignUsertask - Error : ", err);
        return false;
      }
    },
    async actionGenerateUsertaskReport({
      state,
      getters,
      rootGetters,
      dispatch,
      commit }, usertaskObj) {
      try {
        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "usertask_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

        printjobInfoObj.data.usertask = { ...usertaskObj };

        // console.debug("usertask - ", usertaskObj)

        if (printjobInfoObj.data.usertask.usertask_id) {
          const keyid = printjobInfoObj.data.usertask.usertask_id;
          printjobInfoObj.data.usertask.usertask_id =
            keyid.lastIndexOf("_") > 0 ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;
        }

        // console.debug("actionGenerateUsertaskReport - printjobInfoObj", printjobInfoObj);        

        // Reference user task
        printjobInfoObj.data.usertask.hasReference = !getters.getterUsertaskTypeIsCustom(printjobInfoObj.data.usertask);

        // Create data
        printjobInfoObj.data.usertask.created_at_display = rootGetters['HelperManager/getterFormatDate'](printjobInfoObj.data.usertask.created_at);
        printjobInfoObj.data.usertask.start_date_display = rootGetters['HelperManager/getterFormatDate'](printjobInfoObj.data.usertask.start);
        printjobInfoObj.data.usertask.deadline_date_display = rootGetters['HelperManager/getterFormatDate'](printjobInfoObj.data.usertask.deadline);

        // Related documents array
        printjobInfoObj.data.usertask.documents_display = [];

        var documentarray = [];

        if (printjobInfoObj.data.usertask.hasReference)
          documentarray = await dispatch("AttachmentManager/actionReadAttachmentsByReferenceAsArray", { reference: { scope: printjobInfoObj.data.usertask.reference.scope, refid: printjobInfoObj.data.usertask.reference.refid } }, { root: true });
        else
          documentarray = await dispatch("AttachmentManager/actionReadAttachmentsByReferenceAsArray", { reference: { scope: 'usertask', refid: printjobInfoObj.data.usertask.usertask_id } }, { root: true });

        for (let index in documentarray) {
          // console.debug("documentarray - filename", documentarray[index].value.filename);
          // console.debug("documentarray - type", documentarray[index].value.doctype_id);
          let doctype = rootGetters["LookupManager/getterDocumentTypesAsArrayByLc"](printjobInfoObj.lang).filter(el => el.id === documentarray[index].doctype_id)[0].display_name;
          printjobInfoObj.data.usertask.documents_display.push(documentarray[index].name + " (" + documentarray[index].filename + ", " + doctype + ")");
        }

        // Stage
        printjobInfoObj.data.usertask.stage_display =
          rootGetters['LookupManager/getterUsertaskStagesAsArrayByLc'](printjobInfoObj.lang).filter(el => el.stage === printjobInfoObj.data.usertask.stage)[0].display_printtext;

        // History data
        if (printjobInfoObj.data.usertask.stagehistory.length) {
          printjobInfoObj.data.usertask.stagehistory.forEach(function (item) {
            if (Object.prototype.hasOwnProperty.call(item, 'changetype_id'))
              item.change_display =
                rootGetters['LookupManager/getterTimelineChangeTypesAsArrayByLc'](printjobInfoObj.lang).filter(el => el.id === item.changetype_id)[0].display_name;
            else
              item.change_display =
                rootGetters['LookupManager/getterTimelineChangeTypesAsArrayByLc'](printjobInfoObj.lang).filter(el => el.id === "ID_TIMELINE_CHANGETYPE_STAGECHANGED")[0].display_name;

            item.finished_by = rootGetters['AccountManager/getterUserFullnameFromUsername'](item.finished_by);

            item.stage_display =
              rootGetters['LookupManager/getterProofStagesAsArrayByLc'](printjobInfoObj.lang).filter(el => el.stage === item.stage)[0].display_printtext;
            item.finished_at_display = rootGetters['HelperManager/getterFormatDate'](item.finished_at);
          });
        }

        return await dispatch('PrintManager/actionGeneratePrintDocument', printjobInfoObj, { root: true });
      }
      catch (err) {
        console.error("actionGenerateUsertaskReport - Error : ", err);
        return false;
      }
    },
  },
  getters: {
    getterEmptyUsertaskRecord: () => {
      return { ...USERTASK_RECORD };
    },
    getterUsertaskStagePlaned: () => {
      return USERTASKSTAGE_LIST['ID_USERTASKSTAGE_PLANED'].stage;
    },
    getterUsertaskStageWork: () => {
      return USERTASKSTAGE_LIST['ID_USERTASKSTAGE_WORK'].stage;
    },
    getterUsertaskStageClosed: () => {
      return USERTASKSTAGE_LIST['ID_USERTASKSTAGE_CLOSED'].stage;
    },
    getterUsertaskStageArchived: () => {
      return USERTASKSTAGE_LIST['ID_USERTASKSTAGE_ARCHIVED'].stage;
    },
    getterUsertaskReportData: (state, getters, rootState, rootGetters) => {
      const printjobInfoObj = {
        file: 'usertask_report.odt',
        convertTo: 'pdf',
        lang: rootGetters['TranslationManager/lang'].lc
      };

      return printjobInfoObj;
    },
    getterUsertaskStageIsOpen: (state, getters, rootState, rootGetters) => (usertaskDataObj) => {
      try {
        if (usertaskDataObj === undefined)
          throw "Usertask object is undefined";

        if (usertaskDataObj.stage < getters.getterUsertaskStagePlaned || usertaskDataObj.stage > getters.getterUsertaskStageArchived)
          throw "Usertask stage is not valid";

        return (usertaskDataObj.active && usertaskDataObj.stage <= getters.getterUsertaskStageClosed);
      }
      catch (error) {
        console.error(error);
        return false;
      }
    },
    getterUsertaskTypeIsCustom: (state, getters, rootState, rootGetters) => (usertaskDataObj) => {
      try {
        if (usertaskDataObj == undefined || Object.prototype.hasOwnProperty.call(usertaskDataObj, 'reference') !== true)
          throw "Usertask object is undefined";

        return (!usertaskDataObj.reference.scope || 0 === usertaskDataObj.reference.scope.length);
      }
      catch (error) {
        console.error("getterUsertaskTypeIsCustom - Error : ", error);
        return false;
      }
    },
    getterUsertaskToNextStage: (state, getters, rootState, rootGetters) => (usertaskDataObj) => {
      try {
        if (usertaskDataObj === undefined || !Object.prototype.hasOwnProperty.call(usertaskDataObj, 'stagehistory'))
          throw "Usertask object is undefined";

        usertaskDataObj.stagehistory.push({
          ...USERTASK_STAGEHISTORY_RECORD,
          stage: usertaskDataObj.stage,
          created_at: usertaskDataObj.updated_at,
          created_by: usertaskDataObj.updated_by,
          finished_at: +new Date(),
          finished_by: rootGetters['SessionManager/getterCurrentUsername']
        });

        // Increase stage by one
        if (usertaskDataObj.stage <= getters.getterUsertaskStageWork) {
          usertaskDataObj.stage++;
          usertaskDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_STAGECHANGED";
          usertaskDataObj.stagehistory[usertaskDataObj.stagehistory.length - 1].changetype_id = usertaskDataObj.changetype_id;
        }
        else {
          if (usertaskDataObj.active && !usertaskDataObj.archiveOnComplete) {
            usertaskDataObj.active = false;
            usertaskDataObj.stage = getters.getterUsertaskStageClosed;
            usertaskDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_CLOSED";
            usertaskDataObj.stagehistory[usertaskDataObj.stagehistory.length - 1].changetype_id = usertaskDataObj.changetype_id;
          }
          else {
            usertaskDataObj.active = false;
            usertaskDataObj.stage = getters.getterUsertaskStageArchived;
            usertaskDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_ARCHIVED";
            usertaskDataObj.stagehistory[usertaskDataObj.stagehistory.length - 1].changetype_id = usertaskDataObj.changetype_id;

            usertaskDataObj.stagehistory.push({
              ...USERTASK_STAGEHISTORY_RECORD,
              stage: getters.getterUsertaskStageArchived,
              created_at: usertaskDataObj.updated_at,
              created_by: usertaskDataObj.updated_by,
              finished_at: +new Date(),
              finished_by: rootGetters['SessionManager/getterCurrentUsername']
            });
          }
        }
      }
      catch (error) {
        console.error("getterUsertaskToNextStage - Error : ", error);
        usertaskDataObj = undefined;
      }

      return usertaskDataObj;
    },
    getterUsertaskNextStageAllowed: (state, getters, rootState, rootGetters) => (usertaskDataObj) => {
      try {
        if (usertaskDataObj === undefined || !Object.prototype.hasOwnProperty.call(usertaskDataObj, 'stage'))
          throw "Usertask object is undefined";

        // console.debug("getterUsertaskNextStageAllowed - usertaskDataObj", usertaskDataObj);

        var ballowstate = false;

        switch (usertaskDataObj.stage) {
          case getters.getterUsertaskStagePlaned:   // ID_USERTASKSTAGE_PLANED
          case getters.getterUsertaskStageWork:     // ID_USERTASKSTAGE_WORK
            ballowstate = usertaskDataObj.valid;
            break;
          case getters.getterUsertaskStageClosed:   // ID_USERTASKSTAGE_CLOSED
            ballowstate = usertaskDataObj.valid;
            break;
          case getters.getterUsertaskStageArchived: // ID_PROCEDURESTAGE_ARCHIVED
            ballowstate = false;
            break;
        }

        return ballowstate;
      }
      catch (error) {
        console.error("getterUsertaskNextStageAllowed - Error : ", error);
        return false;
      }
    },
    getterUsertaskDeleteAllowed: (state, getters, rootState, rootGetters) => (usertaskDataObj) => {
      try {
        if (
          usertaskDataObj === undefined ||
          !Object.prototype.hasOwnProperty.call(usertaskDataObj, 'stage') ||
          !Object.prototype.hasOwnProperty.call(usertaskDataObj, 'assigned_to')
        )
          throw "Usertask object is undefined";

        if (rootGetters["SessionManager/getterCurrentUsername"] != usertaskDataObj.assigned_to)
          return false;

        return (usertaskDataObj.stage == getters.getterUsertaskStagePlaned);
      }
      catch (error) {
        console.error("getterUsertaskDeleteAllowed - Error : ", error);
        return false;
      }
    },
    getterUsertaskEditAllowed: (state, getters, rootState, rootGetters) => (usertaskDataObj) => {
      try {
        if (
          usertaskDataObj === undefined ||
          !Object.prototype.hasOwnProperty.call(usertaskDataObj, 'stage') ||
          !Object.prototype.hasOwnProperty.call(usertaskDataObj, 'assigned_to')
        )
          throw "Usertask object is undefined";

        if (rootGetters["SessionManager/getterCurrentUsername"] != usertaskDataObj.assigned_to)
          return false;

        return (usertaskDataObj.stage <= getters.getterUsertaskStageClosed && usertaskDataObj.active);
      }
      catch (error) {
        console.error("getterUsertaskEditAllowed - Error : ", error);
        return false;
      }
    }
  }
};