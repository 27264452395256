import { RestBaseService } from './restbase.service';
import { ErrorWrapper, ResponseWrapper } from './response.util';
import { BACKENDAPI_APPID } from '@/config/config.constants.js';

import merge from "lodash.merge";

export class AccountService extends RestBaseService {
  static get entity () {
    return '';
  }

  static get prefix () {
    return 'account';
  }  

  static async getCurrentProfile() {
    try {
      // console.debug("AccountService - getCurrentProfile", $store.getters['AuthTokenManager/getterAccessTokenSubject']());
      const response = await this.request({ auth: true }).get(`${this.prefix}/profile`);

      return new ResponseWrapper(response, response.data);
    } 
    catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getAccountsForTenant(tenant) {
    try {
      // console.debug("AccountService - getAccountsForTenant", $store.getters['AuthTokenManager/getterAccessTokenSubject']());

      // Add appid to profile data
      const requestData = {
        tenant: tenant,
        appid: BACKENDAPI_APPID
      };

      const response = await this.request({ auth: true }).post(`${this.prefix}/list`, requestData);

      return new ResponseWrapper(response, response.data);
    } 
    catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  } 
  
  static async updateAccountForTenant(tenant, accountData) {
    try {
      // console.debug("AccountService - updateAccountForTenant accountData : ", accountData);

      // Add appid to profile data
      let requestData = {
        tenant: tenant,
        appid: BACKENDAPI_APPID,
        accountData: merge({}, accountData)
      };

      requestData.accountData.appconfig.appid = BACKENDAPI_APPID;

      delete requestData.accountData.valid;

      // console.debug("AccountService - updateAccountForTenant requestData : ", requestData);

      const response = await this.request({ auth: true }).post(`${this.prefix}/update`, requestData);

      return new ResponseWrapper(response, response.data);
    } 
    catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  } 

  static async addAccountForTenant(tenant, newaccountData) {
    try {
      // console.debug("AccountService - addAccountForTenant accountData : ", newaccountData);

      // Add appid to profile data
      let requestData = {
        tenant: tenant,
        password: newaccountData.authdata.password,
        appid: BACKENDAPI_APPID,
        accountData: merge({}, newaccountData)
      };

      requestData.accountData.authdata.account_id = "newaccount";
      requestData.accountData.appconfig.appid = BACKENDAPI_APPID;

      delete requestData.accountData.valid;
      delete requestData.accountData.authdata.password;

      // console.debug("AccountService - addAccountForTenant requestData : ", requestData);

      const response = await this.request({ auth: true }).post(`${this.prefix}/add`, requestData);

      return new ResponseWrapper(response, response.data);
    } 
    catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }   
  
  static async deleteAccountForTenant(tenant, accountId) {
    try {
      // console.debug("AccountService - deleteAccountForTenant accountId : ", accountId);

      const requestData = {
        tenant: tenant,
        appid: BACKENDAPI_APPID,
        account_id: accountId
      };

      // console.debug("AccountService - deleteAccountForTenant requestData : ", requestData);

      const response = await this.request({ auth: true }).post(`${this.prefix}/delete`, requestData);

      return new ResponseWrapper(response, response.data);
    } 
    catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }     
}
