import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter);

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
  },
  getters: {
    getterFileBlobFromBase64String: state => (srcBase64, mimetype) => {
        // decode base64 string, remove space for IE compatibility
        var binary = atob(srcBase64.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }

        // create the blob object with content-type mimetype
        return new Blob( [view], { type: mimetype});
    },
    getterAdressStringFromObject: state => (oAdress, namekey) => {
      if(!namekey.length) namekey = 'name';

      if(!Object.prototype.hasOwnProperty.call(oAdress, namekey)) return '';

      if(oAdress !== undefined && oAdress[namekey].length) {
        return (oAdress[namekey] + '\r' + oAdress.street + '\r' + oAdress.country + ' ' + oAdress.zipcode + ' ' + oAdress.city +'\r' + oAdress.email_address + '\r' + oAdress.phone);
      } else {
        return '';
      }
    },
    getterArrayOfObjectDynamicSort: () => (property) => {
      var sortOrder = 1;

      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }

      return function (a,b) {
          /* next line works with strings and numbers,
           * and you may want to customize it to your needs
           */
          var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result * sortOrder;
      };
    },
    getterCompareCurrentDateTimeToDeadlineAsText: state => (value, lc) => {
      // return dayjs().diff(value, 'hour');
      if (undefined == value || value == "") return "";

      return dayjs(value).locale(lc).fromNow(false);
    },
    getterCurrentDate: state => {
      return dayjs().format("YYYY-MM-DD");
    },
    getterCurrentYear: state => {
      return dayjs().format("YYYY");
    },
    getterCurrentMonth: state => {
      return dayjs().format("YYYY-MM");
    },
    getterTomorrowDate: state => {
      return dayjs().add(1, 'days').format("YYYY-MM-DD");
    },
    getterCurrentTime: state => {
      return dayjs().format("hh:mm");
    },
    getterCurrentDateTime: state => {
      return dayjs().format("YYYY-MM-DD HH:mm");
    },
    getterCurrentDateTimeLong: state => {
      return dayjs().format("YYYY-MM-DD HH:mm:ss");
    },
    getterCurrentDateTimeCompact: state => {
      return dayjs().format("YYYYMMDDHHmmss");
    },
    getterFormatDate: state => (value) => {
      if (undefined == value || value == "") return "";

      return dayjs(value).format("YYYY-MM-DD");
    },
    getterFormatTime: state => (value) => {
      if (undefined == value || value == "") return "";

      return dayjs(value).format("HH:mm");
    },
    getterYearFromDate: state => (value) => {
      if(undefined == value || value == "") return "";

      return dayjs(value).year();
    },
    getterFormatNewYearDate: state => (value) => {
      if(undefined == value || value == "") return "";

      return dayjs(value).set('M',0).set('D',1).format("YYYY-MM-DD")
    },
    getterFormatDateTimeCompact: state => (value) => {
      if (undefined == value || value == "") return "";

      return dayjs(value).format("YYYYMMDDHHmmss");
    },
    getterFormatDateTime: state => (value) => {
      if (undefined == value || value == "") return "";

      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },
    getterFormatDateTimeLong: state => (value) => {
      if (undefined == value || value == "") return "";

      return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
    },
    getterCalculateDeadlineDateTime: () => (date, time, offset, unit) => {
      var deadlineObj = dayjs(date + " " + time, 'YYYY-MM-DD HH:mm');

      return dayjs(deadlineObj).add(offset, unit).format('YYYY-MM-DD HH:mm');
    },
    getterCalculateDeadlineDate: () => (date, offset, unit) => {
      var deadlineObj = dayjs(date, 'YYYY-MM-DD');

      return dayjs(deadlineObj).add(offset, unit).format('YYYY-MM-DD');
    },
    gettertruncateString: () => (str, n) => {
      return (str.length > n) ? str.substr(0, n-1) + '(...)' : str;
    },
    getterCompareDateAndTimeValuesIsBefore: () => (dateA, timeA, dateB, timeB) => {
      var datetimeAObj = dayjs(dateA + " " + timeA, 'YYYY-MM-DD HH:mm');
      var datetimeBObj = dayjs(dateB + " " + timeB, 'YYYY-MM-DD HH:mm');

      return datetimeAObj.isBefore(datetimeBObj);
    },
    getterCompareDateAndTimeValueDateTimeValueIsBefore: () => (dateA, timeA, datetimeB) => {
      var datetimeAObj = dayjs(dateA + " " + timeA, 'YYYY-MM-DD HH:mm');
      var datetimeBObj = dayjs(datetimeB, 'YYYY-MM-DD HH:mm');

      return datetimeAObj.isBefore(datetimeBObj);
    },
    getterCompareDateTimeValuesIsBefore: () => (datetimeA, datetimeB) => {
      var datetimeAObj = dayjs(datetimeA, 'YYYY-MM-DD HH:mm');
      var datetimeBObj = dayjs(datetimeB, 'YYYY-MM-DD HH:mm');

      return datetimeAObj.isBefore(datetimeBObj);
    },
    getterCompareDateValuesIsBefore: () => (dateA, dateB) => {
      var dateAObj = dayjs(dateA, 'YYYY-MM-DD');
      var dateBObj = dayjs(dateB, 'YYYY-MM-DD');

      return dateAObj.isBefore(dateBObj);
    },
    getterIsCurrentDateBetweenDeadlineDateAndDayOffset: () => (deadlinedate, days_offset) => {
      var deadlinedateObj = dayjs(deadlinedate, 'YYYY-MM-DD');
      var offsetdateObj = dayjs(deadlinedate, 'YYYY-MM-DD').day(days_offset);

      // console.debug("getterIsCurrentDateBetweenDeadlineDateAndDayOffset - days_offset", days_offset);
      // console.debug("getterIsCurrentDateBetweenDeadlineDateAndDayOffset - deadlinedateObj", deadlinedateObj);
      // console.debug("getterIsCurrentDateBetweenDeadlineDateAndDayOffset - offsetdateObj", offsetdateObj);

      return dayjs().isAfter(offsetdateObj) && dayjs().isBefore(deadlinedateObj);
    },
    getterIsCurrentDateBeforeDeadlineDateWithDayOffset: () => (deadlinedate, days_offset) => {
      const offsetdateObj = dayjs(deadlinedate, 'YYYY-MM-DD').day(days_offset);

      return dayjs().isBefore(offsetdateObj);
    },
    getterIsCurrentDateAfterDeadline: (state, getters) => (deadline) => {
      try{
        return getters.getterCompareDateValuesIsBefore(deadline, getters.getterCurrentDate);
      }
      catch(err)
      {
        return false;
      }
    },

  }
};