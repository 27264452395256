import {AccountService} from '@/services/account.service';

export default {
  namespaced: true,
  state: {
    AccountCache: []
  },
  mutations: {
    ACCOUNT_CACHE_LOAD(state, payload) {
      // console.debug('AccountManager - ACCOUNT_CACHE_LOAD - payload', payload);
      state.AccountCache = [...payload];
    }
  },
  actions: {
    async actionRefreshAccountsCache({
      state,
      getters,
      rootGetters,
      commit,
      dispatch})
    {
      try
      {
        // console.debug("actionRefreshAccountsCache - called");

        const response = await AccountService.getAccountsForTenant(rootGetters["SessionManager/getterCurrentUserTenant"]);

        // console.debug("actionRefreshAccountsCache - response", response.data);

        if(response.data.success == false)
          throw `Error refreshing account cache from backend data store : ${response.data.message}.`;

        commit("ACCOUNT_CACHE_LOAD", response.data.data.accountList);

        return true;
      }
      catch(err)
      {
        console.error('AccountManager - actionRefreshAccountsCache Error : ', err);
        commit("ACCOUNT_CACHE_LOAD", []);
        return false;
      }
    },
    async actionUpdateUserAccount({
      state,
      getters,
      rootGetters,
      commit,
      dispatch}, accountData)
    {
      try
      {
        // console.debug("actionUpdateUserAccount - called");
        accountData.appconfig.dbinstance = rootGetters["SessionManager/getterCurrentDBInstance"];

        const response = await AccountService.updateAccountForTenant(rootGetters["SessionManager/getterCurrentUserTenant"], accountData);

        // console.debug("actionUpdateUserAccount - response", response.data);

        if(response.data.success == false)
          throw `Error saving user sccount in backend data store : ${response.data.message}.`;

        await dispatch("actionRefreshAccountsCache");

        return true;
      }
      catch(err)
      {
        console.error('AccountManager - actionUpdateUserAccount Error : ', err);
        return false;
      }
    },

    async actionAddUserAccount({
      state,
      getters,
      rootGetters,
      commit,
      dispatch}, accountData)
    {
      try
      {
        // console.debug("actionAddUserAccount - accountData", accountData);

        accountData.appconfig.dbinstance = rootGetters["SessionManager/getterCurrentDBInstance"];

        const response = await AccountService.addAccountForTenant(rootGetters["SessionManager/getterCurrentUserTenant"], accountData);

        // console.debug("actionAddUserAccount - response", response.data);

        if(response.data.success == false)
          throw `Error saving user sccount in backend data store : ${response.data.message}.`;

        await dispatch("actionRefreshAccountsCache");

        return {success: true, id: response.data.data.account_id};
      }
      catch(err)
      {
        console.error('AccountManager - actionAddUserAccount Error : ', err);
        return false;
      }
    },

    async actionDeleteUserAccount({
      state,
      getters,
      rootGetters,
      commit,
      dispatch}, accountId)
    {
      try
      {
        // console.debug("actionDeleteUserAccount - called");

        const response = await AccountService.deleteAccountForTenant(rootGetters["SessionManager/getterCurrentUserTenant"], accountId);

        // console.debug("actionDeleteUserAccount - response", response.data);

        if(response.data.success == false)
          throw `Error deactivating user account in backend data store : ${response.data.message}.`;

        dispatch("actionRefreshAccountsCache");

        return true;
      }
      catch(err)
      {
        console.error('AccountManager - actionDeleteUserAccount Error : ', err);
        return false;
      }
    },
  },
  getters: {
    getterAccountsAsArray: (state, getters, rootState, rootGetters) => {
      if (rootGetters["SessionManager/getterIsAuthenticated"] === true)
        return state.AccountCache;
      else
        return [];
    },
    getterAccountShortlistAsArray: (state, getters, rootState, rootGetters) => {
      if (rootGetters["SessionManager/getterIsAuthenticated"] === true) {
        let temparray = [];

        for (let index in state.AccountCache) {
          temparray.push(
            {
              account_id: state.AccountCache[index].authdata.account_id,
              username: state.AccountCache[index].authdata.username,
              fullname: state.AccountCache[index].authprofile.fullname,
              displayname: state.AccountCache[index].authprofile.displayname,
              active: state.AccountCache[index].appconfig.usersettings.active
            }
          );
        }

        return temparray;
      }
      else
        return [];
    },
    getterActiveUserCount: (state, getters, rootState, rootGetters) => () => {
      try
      {
        if (rootGetters["SessionManager/getterIsAuthenticated"] === true)
        {
          return state.AccountCache.filter(item => item.appconfig.usersettings.active === true).length;
        }
        else
          return 0;
      }
      catch(error) {
        console.error("getterActiveUserCount - Error", error);
        return 0;
      }
    },
    getterUserDisplaynameFromUsername: (state, getters, rootState, rootGetters) => (username) => {
      try
      {
        if (rootGetters["SessionManager/getterIsAuthenticated"] === true) {
          const users = state.AccountCache.filter(item => item.authdata.username == username);

          if(users.length > 0)
          {
            return users[0].authprofile.displayname;
          }
        }

        return username;
      }
      catch(error)
      {
        console.error("getterUserDisplaynameFromUsername - Error :", error);
        return username;
      }
    },
    getterUserFullnameFromUsername: (state, getters, rootState, rootGetters) => (username) => {
      try
      {
        // console.debug("getterUserFullnameFromUsername - username", username);

        if (rootGetters["SessionManager/getterIsAuthenticated"] === true) {
          const users = state.AccountCache.filter(item => item.authdata.username == username);

          // console.debug("getterUserFullnameFromUsername - username", users);

          if(users.length > 0)
          {
            return users[0].authprofile.fullname;
          }
        }

        return username;
      }
      catch(error)
      {
        console.error("getterUserFullnameFromUsername - Error :", error);
        return username;
      }
    },
    getterAccountFromCacheByAccountId: (state, getters, rootState, rootGetters) => (accountId) => {
      try {
        if (rootGetters["SessionManager/getterIsAuthenticated"] == false)
          return {};

         const accounts = state.AccountCache.filter(item => item.authdata.account_id === accountId);

          if(accounts.length !== 1)
            return {};

          return accounts[0];
      }
      catch(error)
      {
        return {};
      }
    },
    getterAccountModulesFromCacheByUsername: (state, getters, rootState, rootGetters) => (username) => {
      try {
        if (rootGetters["SessionManager/getterIsAuthenticated"] == false)
          return [];

         const accounts = state.AccountCache.filter(item => item.authdata.username === username);

          if(accounts.length !== 1)
            return [];

          // console.debug("getterAccountModulesFromCacheByUsername - accounts", accounts);

          return accounts[0].appconfig.usersettings.modules;
      }
      catch(error)
      {
        return [];
      }
    },
  }
};