export const IMPACTLEVEL_I18N_KEY = "impactlevels.";

export const IMPACTLEVEL_LIST = {
    "ID_IMPACTLEVEL_NOIMPACT": {
      "name": "IMPACTLEVEL_NOIMPACT_LABEL",
      "description": "IMPACTLEVEL_NOIMPACT_DESCRIPTION",
      level : 1
    },
    "ID_IMPACTLEVEL_LOWIMPACT": {
      "name": "IMPACTLEVEL_LOWIMPACT_LABEL",
      "description": "IMPACTLEVEL_LOWIMPACT_DESCRIPTION",
      level : 2
    },
    "ID_IMPACTLEVEL_MEDIUMIMPACT": {
      "name": "IMPACTLEVEL_MEDIUMIMPACT_LABEL",
      "description": "IMPACTLEVEL_MEDIUMIMPACT_DESCRIPTION",
      level : 3
    },
    "ID_IMPACTLEVEL_HIGHIMPACT": {
      "name": "IMPACTLEVEL_HIGHIMPACT_LABEL",
      "description": "IMPACTLEVEL_HIGHIMPACT_DESCRIPTION",
      level : 4
    },
    "ID_IMPACTLEVEL_VERYHIGHIMPACT": {
      "name": "IMPACTLEVEL_VERYHIGHIMPACT_LABEL",
      "description": "IMPACTLEVEL_VERYHIGHIMPACT_DESCRIPTION",
      level : 5
    }    
  };
