import backendDataApi from '@/services/backendDataApi.service.js';
import { isArguments } from 'lodash';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    //
    // Public
    // 

    //find a tempate by template_id in the couchdb and return it
    async actionReceiveTemplateById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, template_id) {
      try
      {
        let result = await backendDataApi.readDocumentByKeyIdFromBackend({designdoc: 'templates/list', keyid: template_id});

        if(result === false)
          throw "Error reading overlist list";

        // console.debug("actionReceiveTemplateById - result", result);          

        let templateObj = {};

        if (result.length == 1) {
          result.forEach(item => {
            templateObj = {
              ...item.value
            };
          });
        }

        // console.debug("actionReceiveTemplateById - templateObj", templateObj);   

        return templateObj;
      }
      catch(err)
      {
        console.error("actionReceiveTemplateById - Error: ", err);
        return false;
      }
    },
    async actionReceiveCurrentTemplateDataById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, template_id) {
      try
      {
        let result = await dispatch("actionReceiveTemplateById", template_id);

        if(result === false)
          throw "Error reading overlist list";

        // console.debug("actionReceiveCurrentTemplateDataById - result", result);               

        let templateObj = {};

        if(!(Object.keys(result).length === 0 && result.constructor === Object))
        {
          templateObj = {
            ...result.data[result.data.length-1]
          };
        }

        // console.debug("actionReceiveCurrentTemplateDataById - templateObj", templateObj);   

        return templateObj;
      }
      catch(err)
      {
        console.error("actionReceiveCurrentTemplateDataById - Error: ", err);
        return false;
      }
    },  

    //find all activitydoc templates with limited content from the couchdb and return them
    async actionReceiveTemplateActivityDocOverviewlistAsArray({
      rootGetters,
      dispatch
    }, filter) {
      try
      {
        let result = await backendDataApi.readOverviewlistFromBackend({designdoc: 'templates/activitydocoverviewlist', keyid: filter});

        if(result === false)
          throw "Error reading overlist list";

        let array = [];

        if (result.length) {
          result.forEach(item => {
            if(Object.prototype.hasOwnProperty.call(item.value, "justice_areas"))
            {
              if(item.value.justice_areas.findIndex(code => code.toUpperCase() === rootGetters["EntityManager/getterEntityData"].EntityInfo.country.toUpperCase()) !==-1 || item.value.justice_areas.indexOf('*')  !== -1)
                array.push(item.value);
            }
            else
              array.push(item.value);
          });
        }

        return array;
      }
      catch(err)
      {
        console.error("actionReceiveTemplateActivityDocOverviewlistAsArray - Error: ", err);
        return false;
      }
    },
    //find all contractpartner templates with limited content from the couchdb and return them
    async actionReceiveTemplateContractPartnerAsArray({
      dispatch
    }, filter) {
      try
      {
        const result = await backendDataApi.readOverviewlistFromBackend({designdoc: 'templates/contractpartneroverviewlist', keyid: filter});

        if(result === false)
          throw "Error reading overlist list";

        if (result.length)
          return result.map(o => o.value);
        else
          return [];
      }
      catch(err)
      {
        console.error("actionReceiveTemplateContractPartnerAsArray - Error: ", err);
        return false;
      }
    },    
    async actionReceiveTemplateContractPartnerAsArrayByCountryCode({
      dispatch
    }, countrycode) {
      try
      {
        if(!countrycode)
          throw "Error parameter country code required"; 

        const result = await dispatch('actionReceiveTemplateContractPartnerAsArray');

        // console.debug("actionReceiveTemplateContractPartnerAsArrayByCountryCode - result", result);

        if(result === false)
          throw "Error reading overlist list";

        if (result.length)
          return result.filter(item => item.justice_areas.some(code => (code == countrycode || code == '*')));
        else
          return [];
      }
      catch(err)
      {
        console.error("actionReceiveTemplateContractPartnerAsArrayByCountryCode - Error: ", err);
        return [];
      }
    },   
  },
  getters: {
  }
};