import backendDataApi from '@/services/backendDataApi.service.js';

import CommonUtils from '@/utils/common.utils.js';
import merge from "lodash.merge";


import {
  CONTRACTPARTNER_RECORD,
  CONTRACTPARTNER_RECORD_TYPE
}  from '@/config/config.couchdb';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    CONTRACTPARTNER_SAVED(state, payload) {},
    CONTRACTPARTNER_REMOVED(state, payload) {},
  },
  actions: {
    //
    // private - protected action functions
    //
    async deleteContractPartnerByContractPartnerIdFromBackend({
      state
    }, contractpartner_id){
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({designdoc: 'contractpartners/list', keyid: contractpartner_id});
    },
    //find all contractpartner with limited content by filter in the couchdb and return them
    async readContractPartnerOverviewlistFromBackend({
      state
    }, {startfilter, endfilter} ) {
      return await backendDataApi.readOverviewlistFromBackendByComplexFilter({designdoc: 'contractpartners/overviewlistByStage', startkey: startfilter, endkey: endfilter});
    },
    //find a contractpartner by id in the couchdb and return it
    async readContractPartnerByIdFromBackend({
      state
    }, contractpartner_id){
      return await backendDataApi.readDocumentByKeyIdFromBackend({designdoc: 'contractpartners/list', keyid: contractpartner_id});
    },
    //create a new contractpartner in the couchdb and return contractpartner_id or false
    async createContractPartnerAndStoreInBackend({
      rootGetters,
      commit
    }, data) {
      // Make sure an contractpartner_id is assigned on save for a new incident
      const currentdatetime = +new Date();

      // console.debug("createContractPartnerAndStoreInBackend - data", data);

      if(!data.contractpartner_id || data.contractpartner_id === undefined)
        data.contractpartner_id = backendDataApi.generateNewCustomKeyId('cop');

      // Safe guard to ensure a valid id
      if(!data.contractpartner_id || 0 === data.contractpartner_id.length)
        return false;

      if(!data.assigned_to) {
        data.assigned_to = rootGetters['SessionManager/getterCurrentUsername'];
      }

      data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
      data.created_at = currentdatetime;

      if(!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_CREATED";
      }

      const record = {
        username: rootGetters['SessionManager/getterCurrentUsername'],

        type:  CONTRACTPARTNER_RECORD_TYPE,
        keyid: data.contractpartner_id,

        timeline: [data]
      };

      // console.debug("createContractPartnerAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a contractpartner in the couchdb and return contractpartner_id or false
    async updateContractPartnerAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      try
      {
        const currentdatetime = +new Date();

        // console.debug("updateContractPartnerAndStoreInBackend- data", data);

        data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
        data.created_at = currentdatetime;

        if(0 == data.changetype_id.length || data.changetype_id == 'ID_TIMELINE_CHANGETYPE_CREATED') {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        if(!data.assigned_to) {
          data.assigned_to = rootGetters['SessionManager/getterCurrentUsername'];
        }

        // console.debug("updateContractPartnerAndStoreInBackend- data", data);

        let record = await dispatch('readContractPartnerByIdFromBackend', data.contractpartner_id);

        // console.debug("updateContractPartnerAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        if(record[0].value.timeline[record[0].value.timeline.length-1].active != data.active)
          data.changetype_id = (data.active) ? "ID_TIMELINE_CHANGETYPE_ACTIVATE" : "ID_TIMELINE_CHANGETYPE_INACTIVATE";

        // console.debug("updateContractPartnerAndStoreInBackend - Update Mode DOC", record[0].value);

        record[0].value.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(record[0].value);
      }
      catch(err) {
        console.error("updateContractPartnerAndStoreInBackend - Error: ", err);
        return false;
      }
    },

    //
    // Public action functions
    //

    //find a contractPartner by contractPartner_id in the couchdb and return it
    async actionReceiveContractPartnerById({
      commit,
      dispatch
    }, contractPartner_id){
      return dispatch('readContractPartnerByIdFromBackend', contractPartner_id).then(result => {

        if (result.length == 1)
          return {...result[0].value};
        else
          return {};
      })
      .catch(e => {
        console.error(e);
        return false;
      });
    },
    //find the ContractPartner by id in the couchdb and return the last data element
    async actionReceiveCurrentContractPartnerDataById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, contractPartner_id) {
      try
      {
        let result = await dispatch('actionReceiveContractPartnerById', contractPartner_id);

        if(result == undefined || !result)
          throw "Error contractpartner not found";

        // console.debug("actionReceiveCurrentContractPartnerDataById result", result);
        if(!(Object.keys(result).length === 0 && result.constructor === Object))
          return { ...result.timeline[result.timeline.length-1] };
        else
          return false;
      }
      catch(err)
      {
        console.error("actionReceiveCurrentContractPartnerDataById - Error : ", err);
        return false;
      }
    },
    async actionReceiveContractPartnerOverviewAsArray({
      dispatch
    }, filterarray) {
      try {
        var filterObj = {
          startfilter: [], endfilter: [{}]
        };

        if(Array.isArray(filterarray) && filterarray.length == 2) {
          filterObj.startfilter = [filterarray[0]];
          filterObj.endfilter = [filterarray[1], {}];
        }

        const result = await dispatch('readContractPartnerOverviewlistFromBackend',filterObj);

        if(result.length)
          return result.map(o => new Object({ active: true, ...o.value}) );
        else
          return [];
      }
      catch(err) {
        console.error("actionReceiveContractPartnerOverviewAsArray Error: ", err);
        return false;
      }
    },
    async actionRefreshContractPartnerDataByOverviewObject({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, oldContractPartnerObj) {
      try
      {
        // console.debug("actionRefreshContractPartnerDataByOverviewObject In  - oldContractPartnerObj", oldContractPartnerObj);

        var keyid = "";

        if(Object.prototype.hasOwnProperty.call(oldContractPartnerObj, "contractpartner_id") == true) {
          keyid = oldContractPartnerObj.contractpartner_id;
        }
        else {
          if(Object.prototype.hasOwnProperty.call(oldContractPartnerObj, "keyid") == true) {
            keyid = oldContractPartnerObj.keyid;
          }
        }

        if(CommonUtils.isStringEmpty(keyid))
          throw "No valid contractpartner object as input value"

        const newcontractpartnerObj = await dispatch("actionReceiveCurrentContractPartnerDataById", keyid);

        if(CommonUtils.isObjectEmpty(newcontractpartnerObj))
          throw "No valid contractpartner object as update value found"

        if(Object.prototype.hasOwnProperty.call(oldContractPartnerObj, "lastupdate_date") == true &&
           Object.prototype.hasOwnProperty.call(newcontractpartnerObj, "created_at") == true)
        {
          if(oldContractPartnerObj.lastupdate_date >= newcontractpartnerObj.created_at) {
            console.warn("actionReceiveCurrentContractPartnerDataById - input is newer than refresh data");
            return oldContractPartnerObj;
          }
        }

        var newObj = merge({}, oldContractPartnerObj);

        newObj.lastupdate_date = newcontractpartnerObj.created_at;

        newObj.name = newcontractpartnerObj.PartnerData.name;
        newObj.street = newcontractpartnerObj.PartnerData.street;
        newObj.zipcode = newcontractpartnerObj.PartnerData.zipcode,
        newObj.city = newcontractpartnerObj.PartnerData.city;
        newObj.country = newcontractpartnerObj.PartnerData.country;
        newObj.phone = newcontractpartnerObj.PartnerData.phone;
        newObj.email_address = newcontractpartnerObj.PartnerData.email_address;
        newObj.web_homepage = newcontractpartnerObj.PartnerData.web_homepage;
        newObj.purpose = newcontractpartnerObj.DetailData.notes;

        // console.debug("actionRefreshContractPartnerDataByOverviewObject In  - oldContractPartnerObj", oldContractPartnerObj);
        // console.debug("actionRefreshContractPartnerDataByOverviewObject Out - newObj", newObj);

        return newObj;
      }
      catch(err)
      {
        console.error("actionReceiveCurrentContractPartnerDataById - Error : ", err);
        return oldContractPartnerObj;
      }
    },
    //save or update a new contractPartner into the couchdb
    async actionSaveContractPartner({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, data) {
      try
      {
        // console.debug("actionSaveContractPartner", data);
        let result = false;

        if(!data.contractpartner_id || Object.prototype.hasOwnProperty.call(data, "force_create")) {
          delete data.force_create;

          // console.debug("actionSaveContractPartner - Create Mode", data);
          result = await dispatch('createContractPartnerAndStoreInBackend', data);
        }
        else
        {
          // console.debug("actionSaveContractPartner - Update Mode", data);
          result = await dispatch('updateContractPartnerAndStoreInBackend', data);
        }

        if(result == false)
          throw "Error saving ContractPartner";

        commit('CONTRACTPARTNER_SAVED', data);

        return result;
      }
      catch(err) {
        console.error("actionSaveContractPartner Error: ", err);
        return false;
      }
    },
    //delete a existing contractPartner from the couchdb
    async actionDeleteContractPartnerById({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, contractpartner_id) {
      try {
        // console.debug("actionDeleteContractPartnerById", contractpartner_id);

        let result = await dispatch('deleteContractPartnerByContractPartnerIdFromBackend', contractpartner_id);

        if(result !== false ) {
          // console.debug('actionDeleteAreaById - After Update');
          commit('CONTRACTPARTNER_REMOVED', contractpartner_id);

          // Delete attachments
          await dispatch('AttachmentManager/actionDeleteAttachmentsByReference', {refid: contractpartner_id, scope: "contractpartner"}, {root: true});
        }

        return result;
      }
      catch(err)
      {
        console.error("actionDeleteContractPartnerById - Error : ", err);
        return false;
      }
    },
    //find the ContractPartner by id in the couchdb and set the state
    async actionSetActiveStateContractPartnerById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, { contractpartner_id, newstate}) {
      try
      {
        let resultObj = await dispatch('actionReceiveCurrentContractPartnerDataById', contractpartner_id);

        if(resultObj === false)
          throw "Error contractpartner not found";

        if(resultObj.active != newstate)
        {
          resultObj.active = newstate;
          resultObj.changetype_id = (newstate) ? "ID_TIMELINE_CHANGETYPE_ACTIVATE" : "ID_TIMELINE_CHANGETYPE_INACTIVATE";

          // console.debug("actionSaveContractPartner - Update Mode", data);
          const result = await dispatch('updateContractPartnerAndStoreInBackend', resultObj);

          if(result == false)
            throw "Error updating ContractPartner state";

          commit('CONTRACTPARTNER_SAVED', resultObj);

          return result;
        }

        return true;
      }
      catch(err)
      {
        console.error("actionSetActiveStateContractPartnerById - Error : ", err);
        return false;
      }
    },
  },
  getters: {
    //----------------------------------------------------------------------
    //getters for the contract partner book  -------------------------------
    //----------------------------------------------------------------------

    getterEmptyContractPartnerRecord: (state) => {
      return { ...CONTRACTPARTNER_RECORD };
    },

    getterContractPartnerLogo: (state) => (partnerlogoobj) => {
      if(Object.prototype.hasOwnProperty.call(partnerlogoobj, "imageData") == true && partnerlogoobj.imageData !== false)
        return partnerlogoobj;
      else
        return { imageData: require("@/assets/defaultpartnerlogo.png") };
    }
  }
};
