import axios from 'axios';
import path from 'path';

import { ResponseWrapper, ErrorWrapper } from './response.util';

import {AuthService} from '@/services/auth.service';

import {PRINTAPI_HOST, PRINTAPI_PATH, PRINTAPI_APPID } from '@/config/config.constants.js';

export class PrintService {

  static async convertDocument(printData) {
      try {
        const response = await axios.post(
          this.getBackendUrlString("/convert"),
          printData,
          { 
            headers: {
              'Authorization': `${AuthService.getAccessToken()}`
            }
          }
        );
  
        // console.debug("convertDocument - response", response);
  
        return new ResponseWrapper(response, response.data.result);
      } catch (error) {
        throw new ErrorWrapper(error);
      }
  }

  /**
   ******************************
   * @METHODS
   ******************************
   */
   static getBackendUrlString(urlpath) {
    return new URL(path.join(PRINTAPI_PATH, urlpath), PRINTAPI_HOST).toString();
  }  
}
