import backendDataApi from '@/services/backendDataApi.service.js';

import {
  OFFICER_RECORD,
  OFFICER_RECORD_TYPE
} from '@/config/config.couchdb';


export default {
  namespaced: true,
  state: {
    OfficerList: []
  },
  mutations: {
    OFFICER_SAVED(state, payload) {},
    OFFICER_REMOVED(state, payload) {},
    OFFICER_CACHE_LOAD(state, payload) {
      // console.debug('OfficerManager - OFFICER_CACHE_LOAD - payload', payload); 
      state.OfficerList = [...payload];
    }
  },
  actions: {
    // Private functions
    async deleteOfficerByOfficerIdFromBackend({
      state
    }, officer_id){
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({designdoc: 'officers/list', keyid: officer_id});
    },    

    //find all officers with limited content in the couchdb and return them
    async readOfficerOverviewlistFromBackend({
      state
    }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({designdoc: 'officers/overviewlist', keyid: filter});
    },    
    //find a officer by id in the couchdb and return it
    async readOfficerByIdFromBackend({
      state
    }, officer_id){
      return await backendDataApi.readDocumentByKeyIdFromBackend({designdoc: 'officers/list', keyid: officer_id});
    },
    //create a new officer in the couchdb and return officer_id or false     
    async createOfficerAndStoreInBackend({
      rootGetters,
      commit
    }, data) {
      // Make sure an officer_id is assigned on save for a new incident
      const  currentdatetime = +new Date();

      // console.debug("createOfficerAndStoreInBackend - data", data);

      if(!data.officer_id || data.officer_id === undefined)
        data.officer_id = backendDataApi.generateNewCustomKeyId('dpo');

      // Safe guard to ensure a valid id
      if(!data.officer_id || 0 === data.officer_id.length)
        return false;

      data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
      data.created_at = currentdatetime;   
      
      if(!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_CREATED"; 
      }

      const record = {
        username: rootGetters['SessionManager/getterCurrentUsername'],
        
        type: OFFICER_RECORD_TYPE,
        keyid: data.officer_id,
        
        timeline: [data]
      };

      // console.debug("createOfficerAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },  
    //update a officer in the couchdb and return officer_id or false   
    async updateOfficerAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      try
      {
        const currentdatetime = +new Date();

        data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
        data.created_at = currentdatetime;
        
        if(!data.changetype_id || data.changetype_id == 'ID_TIMELINE_CHANGETYPE_CREATED') {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED"; 
        }

        const record = await dispatch('readOfficerByIdFromBackend', data.officer_id);

        // console.debug("updateOfficerAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }
        
        let doc = record[0].value;

        // console.debug("updateOfficerAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      }
      catch(err) {
        console.error("updateOfficerAndStoreInBackend - Error: ", err);
        return false;
      }
    },  
    
    //
    // Public
    // 

    // generate a new custom id for officer     
    async actionRefreshOfficersCache({
      getters,      
      commit,
      dispatch
    }) {
      try
      {
        // console.debug("actionRefreshOfficersCache");

        const result = await dispatch('readOfficerOverviewlistFromBackend');
        
        if(result === false)
          throw "Cannot read officer overviewlist from backend";

        // console.debug("actionRefreshOfficersCache - result", result);

        var resultarray = [];

        if (result.length) 
          resultarray = result.map((o) => o.value);

        commit('OFFICER_CACHE_LOAD', resultarray);

        return true;
      }
      catch(err)
      {
        console.error("actionRefreshOfficersCache - Error : ", err);
        return false;        
      }      
    },     

    //save or update a new officer into the couchdb
    async actionSaveOfficer({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, data) {
      try
      {
        let result = false;

        if(!data.officer_id || Object.prototype.hasOwnProperty.call(data, "force_create")) {
          // console.debug("actionSaveOfficer - Create Mode", data);
          result = await dispatch('createOfficerAndStoreInBackend', data);        
        }
        else 
        {
          if(Object.prototype.hasOwnProperty.call(data, "create_if_needed"))
          {
            // console.debug("actionSaveOfficer - create_if_needed Mode", data);
            delete data.create_if_needed;

            let officerObj = await dispatch('actionReceiveOfficerById', data.officer_id);
            
            if(Object.keys(officerObj).length === 0 && officerObj.constructor === Object) {
              result = await dispatch('createOfficerAndStoreInBackend', data);
            }
          }

          if(!result)
          {
            const deactivate_dpo = (data.officer_id && Object.prototype.hasOwnProperty.call(data.detail, "force_active_decommission"));

            delete data.detail.force_active_decommission;

            // console.debug("actionSaveOfficer - Update Mode", data);          
            result = await dispatch('updateOfficerAndStoreInBackend', data);    
            
            if(result !== false && deactivate_dpo)
            {
              try {
                await dispatch('GdprManager/actionDeactivateGdprOfficer', data.officer_id, {root: true});    
              } 
              catch(error) {
                console.warn("actionSaveOfficer - gdpr deactivate_dpo : Error", error);
              }
            }
          }
        }

        if(result !== false) {
          await dispatch('actionRefreshOfficersCache');  
          // console.debug('actionSaveOfficer - After Update');
          commit("OFFICER_SAVED", data);
        }

        return result;
      }
      catch(err)
      {
        console.error("actionSaveOfficer - Error : ", err);
        return false; 
      }
    },
    //delete a existing officer from the couchdb
    async actionDeleteOfficerById({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, officer_id) {
      try
      {
        // console.debug("actionDeleteOfficerById", officer_id);

        let result = await dispatch('deleteOfficerByOfficerIdFromBackend', officer_id);    

        if(result !== false ) {
          await dispatch('actionRefreshOfficersCache');
          commit("OFFICER_REMOVED", officer_id);  
          
          // Delete attachments
          await dispatch('AttachmentManager/actionDeleteAttachmentsByReference', {refid: officer_id, scope: "officer"}, {root: true});    
        }

        return result;
      }
      catch(err)
      {
        console.error("actionDeleteOfficerById - Error : ", err);
        return false; 
      }      
    },
    //find a officer by officer_id in the couchdb and return it
    actionReceiveOfficerById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, officer_id){
      return dispatch('readOfficerByIdFromBackend', officer_id).then(result => {
        let officerObj = {};

        if (result.length == 1) {
          result.forEach(item => {
            officerObj = {
              ...item.value
            };
          });
        }

        // console.debug("actionReceiveOfficerById - officerObj", officerObj);

        return officerObj;
      })
      .catch(e => {
        console.error(e);
        return false;
      });
    }, 
    async actionReceiveCurrentOfficerDataById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, officer_id) {
      try
      {
        const result = await dispatch('actionReceiveOfficerById', officer_id);

        if(result == undefined || !result)
          throw "Error officer not found";

        // console.debug("actionReceiveCurrentOfficerDataById result", result);
        
        let officerObj = {};

        if(!(Object.keys(result).length === 0 && result.constructor === Object))
        {
          officerObj = {
            ...result.timeline[result.timeline.length-1]
          };
        }

        return officerObj;
      }
      catch(err)
      { 
        console.error("actionReceiveCurrentOfficerDataById - Error : ", err);
        return false;
      }
    },          
    //find all officers with limited content from the couchdb and return them
    actionReceiveOfficerOverviewlistAsArray({
      dispatch
    }, filter) {
      return dispatch('readOfficerOverviewlistFromBackend', filter).then(result => {
        let array = [];

        if (result.length) {
          result.forEach(item => {
            array.push(item.value);
          });
        }

        return array;
      });
    },
    async actionReceiveCurrentGdprOfficerData({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, payload) {
      try
      {
        const result = await dispatch('GdprManager/actionRetrieveGdprDoc', "",{root: true});    

        if(result == undefined || !result)
          throw "Error actionRetrieveGdprDoc not found";

        if(rootGetters["GdprManager/getterGdprData"].gdprofficer.has_gdprofficer == false || !rootGetters["GdprManager/getterGdprData"].gdprofficer.officer_id) {
          return false;
        }

        const officer = await dispatch('actionReceiveOfficerById', rootGetters["GdprManager/getterGdprData"].gdprofficer.officer_id);

        if(officer == undefined || !result)
          throw "Error officer not found";

        // console.debug("actionReceiveCurrentGdprOfficerData - officer", officer);
        
        var officerObj = {};

        if(!(Object.keys(officer).length === 0 && officer.constructor === Object))
        {
          return {
            ...officer.timeline[officer.timeline.length-1]
          };
        }
        else
          throw "Error officer object is corrupt";
      }
      catch(err)
      { 
        console.error("actionReceiveCurrentGdprOfficerData - Error : ", err);
        return false;
      }
    },
    async actionReceiveCurrentGdprEuRepresentantData({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, payload) {
      try
      {
        const result = await dispatch('GdprManager/actionRetrieveGdprDoc', "",{root: true});    

        if(result == undefined || !result)
          throw "Error actionRetrieveGdprDoc not found";

        if(rootGetters["GdprManager/getterGdprData"].representant.has_gdprrepresentant == false || !rootGetters["GdprManager/getterGdprData"].representant.officer_id)
          return false;

        const representant = await dispatch('actionReceiveOfficerById', rootGetters["GdprManager/getterGdprData"].representant.officer_id);

        if(representant == undefined || !result)
          throw "Error gdpr eu representant not found";

        // console.debug("actionReceiveCurrentGdprEuRepresentantData - representant", representant);
        
        if(!(Object.keys(representant).length === 0 && representant.constructor === Object))
        {
          return {
            ...representant.timeline[representant.timeline.length-1]
          };
        }
        else
          throw "Error representant object is corrupt";
      }
      catch(err)
      { 
        console.error("actionReceiveCurrentGdprEuRepresentantData - Error : ", err);
        return false;
      }
    },
    async actionGenerateOfficerReport(
      { state, getters, rootGetters, dispatch, commit }, officerObj
    ) {
      try {
        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData", 
          {
            templateFile: "officer_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

        // Create data
        printjobInfoObj.data.officer = {...officerObj};

        // console.debug("actionGenerateOfficerReport - printObj", printjobInfoObj.data);
        
        var type_display = rootGetters["LookupManager/getterGdprOfficerTypesAsArray"].filter(item => item.id == printjobInfoObj.data.officer.contact.type_id)

        if (type_display.length) {
          printjobInfoObj.data.officer.contact.type_display = type_display[0].display_name
        }

        // console.debug("actionGenerateOfficerReport - printObj", printjobInfoObj);

        return await dispatch("PrintManager/actionGeneratePrintDocument", printjobInfoObj, { root: true });
      } catch (err) {
        console.error("actionGenerateOfficerReport - Error : ", err);
        return false;
      }
    },
  },
  getters: {
    // Public
    getterOfficerTypeGdprOfficer: () => {
      return "ID_OFFICERTYPE_GDPROFFICER";
    },
    getterOfficerTypeEuRepresentant: () => {
      return "ID_OFFICERTYPE_GDPREUREPRESENTATIVE";
    },
    getterEmptyOfficerRecord: () => {
      return Object.assign({}, OFFICER_RECORD);
    },
    getterOfficersAsArray: (state, getters, rootState, rootGetters) => {
      var array = [];

      try {
        for(const item of state.OfficerList) {
          // console.debug("getterOfficersAsArray - item", item);

          item.type_display_name = rootGetters["LookupManager/getterOfficerTypesAsArray"].filter(el => el.id === item.type_id)[0].display_name;

          array.push(item);
        }   
      } catch (error) {
        console.error("getterOfficersAsArray - Error : ", error);
      }

      return array;
    },
    getterCurrentGdprOfficerContact: (state, getters, rootState, rootGetters) => {
      try {
        if(rootGetters["GdprManager/getterGdprOfficerIsAssigned"] == false)
          return false;

        const arr = getters.getterOfficersAsArray.filter(item => item.officer_id == rootGetters["GdprManager/getterGdprOfficerIsAssigned"]);

        if(arr.length != 1)
          return false;

        return {...arr[0]};
      } catch (error) {
        console.error("getterCurrentGdprOfficerContact - Error : ", error);
        return false;
      }
    },
    getterCurrentEuRepresentativeContact: (state, getters, rootState, rootGetters) => {
      try {
        if(rootGetters["GdprManager/getterEuRepresentativeIsAssigned"] == false)
          return false;

        const arr = getters.getterOfficersAsArray.filter(item => item.officer_id == rootGetters["GdprManager/getterEuRepresentativeIsAssigned"]);

        if(arr.length != 1)
          return false;

        return {...arr[0]};
      } catch (error) {
        console.error("getterCurrentEuRepresentativeContact - Error : ", error);
        return false;
      }
    }
  }
};