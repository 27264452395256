import $store from '@/store';

import { AuthService } from '@/services/auth.service';

/**
 * Current user state initialization
 * @WARN Must be always first in middleware chain
 */
export async function initApplicationStateMiddleware (to, from, next) {
  try
  {
    const isOnline = await AuthService.getBackendStatus();

    $store.dispatch('actionSetIsBackendOnline', isOnline);

    // console.debug("initApplicationStateMiddleware - loadstate", $store.getters['getterLoadingState']);
    // console.debug("initApplicationStateMiddleware - AuthService.hasRefreshToken()", AuthService.hasRefreshToken());
    // console.debug("initApplicationStateMiddleware - AuthService.isRefreshTokenExpired()", AuthService.isRefreshTokenExpired());
    // console.debug("initApplicationStateMiddleware - to.name", to.name);

    if ((!AuthService.hasRefreshToken() || AuthService.isRefreshTokenExpired() || !isOnline) && to.name !== 'login' )
    {
      // console.debug("initApplicationStateMiddleware - reroute to login");
      throw "Invalid authentification data - reroute to login";
    }

    // console.debug("initApplicationStateMiddleware - before currentUserId");

    const currentUserId = $store.getters['SessionManager/getterCurrentAccountId'];

    // console.debug("initApplicationStateMiddleware - currentUserId", currentUserId);

    if (AuthService.hasRefreshToken() && !currentUserId) {
      // Get new access token with refresh token
      const response = await AuthService.debounceRefreshTokens();

      if(response.success !== true)
        throw response.errorMessage;

      // console.debug("initApplicationStateMiddleware - GetCurrentAccount", $store.getters['ProfileManager/getterCurrentAccountInfo'])

      if(!await $store.dispatch('SessionManager/actionCreateSession'))
        throw "SessionManager/actionCreateSession Error";

      // console.debug("initApplicationStateMiddleware - refreshed token", to, from );
    }
    else {
     //  console.debug("initApplicationStateMiddleware - unaltered", to, from );
    }

    next();
  }
  catch (err) {
    console.error("initApplicationStateMiddleware - Error: ", err);
    await AuthService.makeLogout();
    await $store.dispatch('SessionManager/actionDestroySession');
    next('login');
  }
}

/**
 * Check access permission to auth routes
 */
export async function checkAccessMiddleware (to, from, next) {
  try {
    const currentUserId = $store.getters['SessionManager/getterCurrentAccountId'];
    const isAuthRoute = to.matched.some(item => item.meta.isAuth);

    // console.debug("checkAccessMiddleware - currentUserId, isAuthRoute", currentUserId, isAuthRoute);

    if(isAuthRoute && !(AuthService.hasRefreshToken() || currentUserId) )
    {
      // console.debug(`checkAccessMiddleware - No token but auth required for target ${to.name}.`);
      throw `Auth required for target ${to.name} - redirect to login page`;
    }

    if(!isAuthRoute) {
      // console.debug(`checkAccessMiddleware - No auth required for target ${to.name}.`);
      if(to.name === 'login')
        return next();
      else
        return next('login');
    }

    // console.debug(`checkAccessMiddleware - Token auth passed for target ${to.name}.`);

    // if(to.name !== 'home') {
    //   return next("home");
    // }

    next();
  }
  catch (err) {
    console.error("checkRoutingMiddleware - Error : ", err );
    await AuthService.makeLogout();
    await $store.dispatch('SessionManager/actionDestroySession');
    return next('login');
  }
}

/**
 * Check first run collect-initial-data route
 * @WARN Must be always before in auth access middleware chain
 */
 export async function checkRoutingMiddleware (to, from, next) {
  try {
    const currentUserId = $store.getters['SessionManager/getterCurrentAccountId'];

    if(AuthService.hasRefreshToken() && currentUserId)
    {
      // Valid login
      // console.debug("checkRoutingMiddleware - with auth", to, from );

      if( $store.getters['LicenseManager/getterCheckIsLicenseExpired']() )
      {
        if(to.name !== "license-expired" ) {
          // console.debug("checkRoutingMiddleware - reroute expired", $store.getters['LicenseManager/getterCheckIsLicenseExpired']());
          // console.debug("checkRoutingMiddleware - reroute expired grace", $store.getters['LicenseManager/getterCheckIsLicenseInGracePeriod']());
          // console.debug("checkRoutingMiddleware - reroute state grace", $store.getters.getterIsIgnoreLicenceGracePeriodState);
          return next( "license-expired" );
        }
        else
        {
          // console.debug("checkRoutingMiddleware - reroute other", $store.getters['LicenseManager/getterCheckIsLicenseExpired']());
          // console.debug("checkRoutingMiddleware - reroute other grace", $store.getters['LicenseManager/getterCheckIsLicenseInGracePeriod']());
          // console.debug("checkRoutingMiddleware - reroute other grace", $store.getters.getterIsIgnoreLicenceGracePeriodState);

          await $store.dispatch('actionSetLoadingState', false);
        }
      }
      else
      {
        if($store.getters.getterIsFirstRun == true)
        {
          if(to.name !== "collect-initial-data" ) {
            // console.debug("checkRoutingMiddleware - reroute collect-initial-data", $store.getters.getterIsFirstRun );
            return next( "collect-initial-data" );
          }
        }
        else
        {
          if(to.name !== 'home')
            return next("home");
        }
      }
    }
    else
    {
      if(to.name !== "login")
      {
        await AuthService.makeLogout();
        await $store.dispatch('SessionManager/actionDestroySession');
        return next('login');
      }
    }

    next();
  }
  catch(err)
  {
    console.error("checkRoutingMiddleware - Error : ", err );
    await AuthService.makeLogout();
    await $store.dispatch('SessionManager/actionDestroySession');
    return next('login');
  }
}