//
// Artefact
//
export const ARTEFACT_RECORD_TYPE = "lookup";
export const ARTEFACT_RECORD_SUBTYPE = "artefact";

export const ARTEFACT_I18N_DEFAULTLANGUAGE = "de";

export const ARTEFACT_I18N_DISPLAY = {
  display_translation: {
    lang: "",
    name: "",
    description: ""
  }
};

export const ARTEFACT_I18N_DATA = {
  defaultlang: ARTEFACT_I18N_DEFAULTLANGUAGE,
  translations: {}
};

export const ARTEFACT_RECORD = {
  artefact_id: "",

  standard: false,

  protection_class: "",
  retention_rule: "",
  retention_start: "",
  retention_other: "",
  data_categories: [],
  areas: [],

  valid: false,
  created_at: false,
  created_by: "",

  i18n: {
    ...ARTEFACT_I18N_DATA
  }
};
