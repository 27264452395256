export const ASSETCATEGORY_I18N_KEY = "asset_categories.";

export const ASSETCATEGORY_LIST = {

	"ID_ASSETCATEGORY_HARDWARE": {
		"name": "ASSETCATEGORY_HARDWARE_LABEL", 
		"description": "ASSETCATEGORY_HARDWARE_DESCRIPTION"
	}, 

	"ID_ASSETCATEGORY_SOFTWARE": {
		"name": "ASSETCATEGORY_SOFTWARE_LABEL", 
		"description": "ASSETCATEGORY_SOFTWARE_DESCRIPTION"
	}, 

	"ID_ASSETCATEGORY_GENERIC": {
		"name": "ASSETCATEGORY_GENERIC_LABEL", 
		"description": "ASSETCATEGORY_GENERIC_DESCRIPTION"
	} 
};
