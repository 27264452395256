export const PROOFLEVEL_I18N_KEY = "proof_levels.";

export const PROOFLEVEL_LIST = {
    "ID_PROOFLEVEL_OPEN": {
      "level": 0,
      "name": "PROOFLEVEL_OPEN_LABEL",
      "description": "PROOFLEVEL_OPEN_DESCRIPTION",
      "printtext": "PROOFLEVEL_OPEN_PRINTTEXT"
    },
    "ID_PROOFLEVEL_FAILED": {
      "level": 1,
      "name": "PROOFLEVEL_FAILED_LABEL",
      "description": "PROOFLEVEL_FAILED_DESCRIPTION",
      "printtext": "PROOFLEVEL_FAILED_PRINTTEXT"
    },
    "ID_PROOFLEVEL_LIMITED": {
      "level": 2,
      "name": "PROOFLEVEL_LIMITED_LABEL",
      "description": "PROOFLEVEL_LIMITED_DESCRIPTION",
      "printtext": "PROOFLEVEL_LIMITED_PRINTTEXT"
    },
    "ID_PROOFLEVEL_COMPLETE": {
      "level": 3,
      "name": "PROOFLEVEL_COMPLETE_LABEL",
      "description": "PROOFLEVEL_COMPLETE_DESCRIPTION",
      "printtext": "PROOFLEVEL_COMPLETE_PRINTTEXT"
    },

};
  
