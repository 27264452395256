import Vue from 'vue';
import Router from 'vue-router';

import { initApplicationStateMiddleware, checkAccessMiddleware, checkRoutingMiddleware } from './router.middleware';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      meta: { isAuth: true },
      component: () => import( /* webpackChunkName: "home" */ '@/views/Home')
    },
    {
      path: '/collect-initial-data',
      name: 'collect-initial-data',
      props: true,
      meta: { isAuth: true },
      component: () => import( /* webpackChunkName: "collect-initial-data" */ '@/views/CollectInitialData'),
    },
    {
      path: '/license-expired',
      name: 'license-expired',
      meta: { isAuth: true },
      component: () => import( /* webpackChunkName: "collect-initial-data" */ '@/views/ExpiredLicense'),
    },
    {
      path: '/login',
      name: 'login',
      meta: { isAuth: false },
      component: () => import( /* webpackChunkName: "login" */ '@/views/Login')
    }
  ]
});

router.beforeEach(initApplicationStateMiddleware);
router.beforeEach(checkAccessMiddleware);
router.beforeEach(checkRoutingMiddleware);

export default router;