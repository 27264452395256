import clonedeep from "lodash.clonedeep";

import backendDataApi from '@/services/backendDataApi.service.js';

import {
  VUEX_DOCUMENT_NAME,
} from '@/config/config.couchdb';

import {
  APP_CONFIG_RECORD,
} from '@/config/config.constants';

import CommonUtils from "@/utils/common.utils";

export default {
  // load vuex doc from couchDB
  async actionRetrieveVuexDoc({
    state,
    rootGetters,
    dispatch,
    commit
  }) {
    try {
      const doc = await backendDataApi.readDocumentByDocIdFromBackend(VUEX_DOCUMENT_NAME);

      // console.debug("actionRetrieveVuexDoc - doc", doc);

      if (doc === false)
        throw "Error loading Application base data";

      commit('VUEX_LOAD', doc.store);

      // console.debug("actionRetrieveVuexDoc - state", state);

      return true;
    }
    catch (err) {
      console.error('actionRetrieveVuexDoc - Error : ', err);
      return false;
    }
  },
  // Save vuex doc in backend
  async actionSaveVuexDoc({
    state,
    getters,
    dispatch,
    commit
  }, payload) {
    try {
      // console.debug("actionSaveEntityInformationInBackend - payload", payload);

      let vuexdata = {
        store: {
          ...clonedeep(payload)
        },
        timestamp: +new Date()
      };

      // Cleanup data not to persist
      delete vuexdata.store.Application.Runtime;

      // User store
      delete vuexdata.store.User;

      // Before save delete runtime store module elements
      delete vuexdata.store.SessionManager;
      delete vuexdata.store.AuthTokenManager;
      delete vuexdata.store.ProfileManager;
      delete vuexdata.store.AccessManager;

      delete vuexdata.store.UsertaskManager;
      delete vuexdata.store.TemplateManager;
      delete vuexdata.store.LicenseManager;
      delete vuexdata.store.TranslationManager;
      delete vuexdata.store.EntityManager;
      delete vuexdata.store.AttachmentManager;

      delete vuexdata.store.ContactBookManager;
      delete vuexdata.store.ContractPartnerManager;
      delete vuexdata.store.AreaManager;
      delete vuexdata.store.ProcessManager;
      delete vuexdata.store.ActivityDocManager;
      delete vuexdata.store.RepositoryManager;

      delete vuexdata.store.LocationManager;
      delete vuexdata.store.AssetManager;
      delete vuexdata.store.RiskManager;
      delete vuexdata.store.RequestManager;
      delete vuexdata.store.ProofManager;
      delete vuexdata.store.HelperManager;
      delete vuexdata.store.IncidentManager;
      delete vuexdata.store.ProcedureManager;
      delete vuexdata.store.PrintManager;
      delete vuexdata.store.LookupManager;
      delete vuexdata.store.DBLookupManager;
      delete vuexdata.store.LawbasisGdprManager;
      delete vuexdata.store.AnnouncementManager;
      delete vuexdata.store.AgencyManager;
      delete vuexdata.store.OfficerManager;
      delete vuexdata.store.GdprManager;
      delete vuexdata.store.ExportManager;

      delete vuexdata.store.LawbasisContentManager;

      delete vuexdata.store.ArtefactManager;
      delete vuexdata.store.DataSubjectManager;
      delete vuexdata.store.DataCategoryManager;

      delete vuexdata.store.DsfaManager;
      delete vuexdata.store.AccountManager;

      // console.debug("actionSaveEntityInformationInBackend - vuexdata", vuexdata);

      const result = await backendDataApi.saveDocumentContentInBackendByDocId(VUEX_DOCUMENT_NAME, vuexdata);

      // console.debug("actionSaveEntityInformationInBackend - result", result);

      return result;
    }
    catch (err) {
      console.error('actionSaveVuexDoc - Error : ', err);
      return false;
    }
  },
  async actionSaveFirstAppRun({
    state,
    getters,
    rootGetters,
    commit,
    dispatch
  }, payload) {
    // store / update into the DB
    // update the state "firstAppRun" false
    // payload contain the EntityData information
    try {
      // console.debug("actionSaveFirstAppRun - payload", payload);

      if (getters.getterIsFirstRun) {
        await dispatch('EntityManager/actionSaveEntityData', payload);

        await dispatch("LawbasisContentManager/actionRetrieveAllLawbasisDocs");

        await dispatch('AreaManager/actionCreateInitialAreasByEntityData', payload);
        await dispatch('ProcedureManager/actionCreateProceduresOnFirstRun', payload.EntityDetails);

        commit('SET_FIRST_RUN', {
          is_first_run: false,
          first_run_timestamp: +new Date()
        });

        // Save to backend
        await dispatch('actionSaveVuexDoc', state);
      }
    }
    catch (err) {
      console.error("actionSaveFirstAppRun - Error", err);
    }
  },
  async actionSaveInitalDataComplete({
    state,
    getters,
    commit,
    dispatch
  }, payload) {
    try {
      // console.debug("actionSaveInitalDataComplete - payload", payload);

      commit('SET_INITIAL_DATA_COMPLETE', {
        is_initial_data_complete: true,
        initial_data_timestamp: +new Date()
      });

      // Save to backend
      const result = await dispatch('actionSaveVuexDoc', state);

      // console.debug("actionSaveInitalDataComplete - result", result);

      return true;
    }
    catch (err) {
      console.error("actionSaveInitalDataComplete - Error", err);
      return false;
    }
  },
  actionSetDarkThemeMode({
    commit
  }, payload) {
    commit('SET_DARK_THEME_MODE', (payload === true || payload == 'dark'));
  },
  actionSetLoadingState({
    commit
  }, payload) {
    // console.debug("actionSetLoadingState - payload", payload);
    commit('SET_LOADING_STATE', payload);
  },
  actionSetIgnoreLicenceGracePeriodState({
    commit
  }, payload) {
    commit('SET_IGNORELICENCEGRACEPERIOD_STATE', payload);
  },
  actionSetAutoInitWithDataMaps({
    commit
  }, payload) {
    commit('SET_AUTOINITWITHDATAMAPS_STATE', payload);
  },
  actionSetActiveComponentName({
    getters,
    commit,
    dispatch
  }, payload) {
    // console.debug("actionSetActiveComponentName", payload);
    commit('SET_ACTIVE_COMPONENTNAME', payload);
  },
  actionUpdateBreadcrumbs({
    getters,
    commit,
    dispatch
  }, { component, selection, position }) {
    // console.debug("actionUpdateBreadcrumbs", component, selection, position);

    commit('SET_BREADCRUMBS_STATE', { component, selection, position });
  },
  async actionShowComponent({
    getters,
    commit,
    dispatch
  }, { component, payload }) {
    // console.debug("actionShowComponent", component, payload);

    await dispatch("actionSetActiveComponentName", component);
    await dispatch("actionUpdateBreadcrumbs", { component, selection: "", position: "" });

    commit('SET_CONTENTCOMPONENT_STATE', { name: component, payload: payload });
  },
  actionResetApplicationState({
    getters,
    commit,
    dispatch
  }, payload) {
    // console.debug("actionResetApplicationState";
    commit('RESET_STATE');
  },
  actionSetAlertMessage({
    getters,
    commit,
    dispatch
  }, payload) {
    // console.debug("actionSetAlertMessage", payload);
    commit('SET_ALERT_MESSAGE', payload);
  },
  actionClearAlertMessage({
    getters,
    commit,
    dispatch
  }) {
    // console.debug("actionClearAlertMessage", payload);
    commit('SET_ALERT_MESSAGE',{ ...APP_CONFIG_RECORD.alert_message });
  },
  actionSetMessageOkDialog({
    getters,
    commit,
    dispatch
  }, payload) {
    // console.debug("actionSetMessageOkDialog", payload);
    commit('SET_MESSAGE_OK_DIALOG', payload);
  },
  actionClearMessageOkDialog({
    getters,
    commit,
    dispatch
  }) {
    // console.debug("actionClearMessageOkDialog", payload);
    commit('SET_MESSAGE_OK_DIALOG', { ...APP_CONFIG_RECORD.message_ok_dialog });
  },
  actionSetMessageConfirmDialog({
    getters,
    commit,
    dispatch
  }, payload) {
    // console.debug("actionSetMessageConfirmDialog", payload);
    commit('SET_MESSAGE_CONFIRM_DIALOG', payload);
  },
  actionClearMessageConfirmDialog({
    getters,
    commit,
    dispatch
  }) {
    // console.debug("actionClearMessageConfirmDialog", payload);
    commit('SET_MESSAGE_CONFIRM_DIALOG', {
      ...APP_CONFIG_RECORD.message_confirm_dialog,
      confirmOperation: () => {},
      cancelOperation: () => {}
    });
  },
  actionSetInProgressDialog({
    getters,
    commit,
    dispatch
  }, payload) {
    // console.debug("actionSetInProgressDialog", payload);
    commit('SET_IN_PROGRESS_DIALOG', payload);
  },
  actionClearInProgressDialog({
    getters,
    commit,
    dispatch
  }) {
    // console.debug("actionClearInProgressDialog", payload);
    commit('SET_IN_PROGRESS_DIALOG', { ...APP_CONFIG_RECORD.progress_dialog });
  },
  actionSetIsBackendOnline({
    getters,
    commit,
    dispatch
  }, payload) {
    // console.debug("actionSetIsBackendOnline", payload);
    commit('SET_IS_BACKEND_ONLINE', payload);
  },
  async actionRetrieveCoreData({
    getters,
    commit,
    dispatch
  }, payload) {
    try {
      // console.debug("actionRetrieveCoreData", payload);

      if (!await dispatch('actionRetrieveVuexDoc'))
        throw "Error loading Apllication Base Data";

      if (!await dispatch('LicenseManager/actionRetrieveLicenseDoc', '', { root: true }))
        throw "Error loading License Data";

      if (!await dispatch('DBLookupManager/actionRetrieveEntityTypesDoc', '', { root: true }))
        throw "Error loading Initial Lookup Data for Entities";

      if (!await dispatch('DBLookupManager/actionRetrieveEntityClassesDoc', '', { root: true }))
        throw "Error loading Initial Lookup Data for Entities";

      if (!await dispatch('DBLookupManager/actionRetrieveEntityPatternsDoc', '', { root: true }))
        throw "Error loading Initial Lookup Data for Entity Patterns";

      if (!await dispatch('DBLookupManager/actionRetrieveEntitySectorsDoc', '', { root: true }))
        throw "Error loading Initial Lookup Data for Entity Sectors";

      if (!await dispatch('LawbasisContentManager/actionRetrieveLawbasisContentDoc', '', { root: true }))
        throw "Error loading Initial Lawbasis content data";

      if (!await dispatch('EntityManager/actionRetrieveEntityDoc', '', { root: true }))
        throw "Error loading Entity Data";

      if (!await dispatch('GdprManager/actionRetrieveGdprDoc', '', { root: true }))
        throw "Error loading Gdpr Data";

      if (!await dispatch('AreaManager/actionRetrieveInitAreaDoc', '', { root: true }))
        throw "Error loading Initial Lookup Data for Area";

      if (!await dispatch('AreaManager/actionRetrieveEntityPatternAreaMapDoc', '', { root: true }))
        throw "Error loading Initial Lookup Data for Area";

      if (!await dispatch('ProcessManager/actionRetrieveInitProcessDoc', '', { root: true }))
        throw "Error loading Initial Lookup Data for Process";

      if (!getters.getterIsFirstRun) {
        if (!await dispatch('LawbasisGdprManager/actionRetrieveAllLawbasisDocs', '', { root: true }))
          throw "Error loading Lawbasis Data";

        if (!await dispatch('OfficerManager/actionRefreshOfficersCache', '', { root: true }))
          throw "Error loading Gdpr Officer Data";

        if (!await dispatch('LocationManager/actionRefreshLocationsCache', '', { root: true }))
          throw "Error loading Location Data";

        if (!await dispatch('AreaManager/actionRefreshAreasCache', '', { root: true }))
          throw "Error loading Area Data";

        if (!await dispatch('ProcessManager/actionRefreshProcessesCache', '', { root: true }))
          throw "Error loading Process Data";

        if (!await dispatch('ActivityDocManager/actionRefreshActivityDocsCache', '', { root: true }))
          throw "Error loading ActivityDoc Data";

        if (!await dispatch('AccountManager/actionRefreshAccountsCache', '', { root: true }))
          throw "Error loading Account Data";

        // Agencies
        if (!await dispatch('AgencyManager/actionRetrieveAgenciesGDPRDoc', '', { root: true }))
          throw "Error loading GDPR Agencies Data";
      }

      return true;
    }
    catch (err) {
      console.error("actionRetrieveCoreData - Error: ", err);
      return false;
    }
  }
};