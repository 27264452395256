import { mapActions } from "vuex";

export const AlertMessage = {
  methods: {
    ...mapActions({
      actionSetAlertMessage: "actionSetAlertMessage",
      actionClearAlertMessage: "actionClearAlertMessage"
    }),
    showAlert(type = true, message = "") {
      this.actionClearAlertMessage();

      this.actionSetAlertMessage({
          message: this.getDefaultAlertMessage(message, type),
          type: this.getAlertTypeByBoolean(type),
          active: true
      });
    },
    getDefaultAlertMessage(message, type) {
      if (message !== "") {
        return message;
      }

      return type ? "alertnotifymessager.msg_alert_operation_success" :"alertnotifymessager.msg_alert_operation_failed";
    },
    getAlertTypeByBoolean(boolflag) {
      return boolflag ? "success" : "warning";
    },
    hideAlert() {
      //hide the alert window if it is being displayed on the screen
      this.actionClearAlertMessage();
    }
  }
};