import backendDataApi from '@/services/backendDataApi.service.js';
import CommonUtils from '@/utils/common.utils.js';

import merge from "lodash.merge";

import {
  ARTEFACT_RECORD_TYPE,
  ARTEFACT_RECORD_SUBTYPE,
  ARTEFACT_RECORD,
  ARTEFACT_I18N_DISPLAY
} from '@/models/artefact.couchdb';

export default {
  namespaced: true,
  state: {
    artefactlist: []
  },
  mutations: {
    ARTEFACT_SAVED(state, payload) { },
    ARTEFACT_REMOVED(state, payload) { },
    ARTEFACT_CACHE_LOAD(state, payload) {
      state.artefactlist = [...payload];
    },
  },
  actions: {
    //
    // private - protected action functions
    //

    //find all artefacts with limited content by filter in the couchdb and return them
    async readArtefactOverviewlistFromBackend({ state }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({
        designdoc: "artefacts/list",
        keyid: filter,
      });
    },
    //find a artefact by id in the couchdb and return it
    async readArtefactByIdFromBackend({
      state
    }, artefact_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({ designdoc: 'artefacts/list', keyid: artefact_id });
    },
    //create a new artefact in the couchdb and return id or false
    async createArtefactAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      // Make sure an id is assigned on save for a new incident
      const currentdatetime = +new Date();

      // console.debug("createArtefactAndStoreInBackend - data", data);
      if(data.standard == false)
        data.artefact_id = backendDataApi.generateNewCustomKeyId('arf');

      // Safe guard to ensure a valid id
      if (!data.artefact_id || 0 === data.artefact_id.length)
        return false;

      data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
      data.created_at = currentdatetime;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_CREATED";
      }

      // transform translations
      var new_record = {
        ...ARTEFACT_RECORD,
        ...data
      };

      const lang = data.display_translation.lang;

      new_record.i18n.translations[lang] = {};

      new_record.i18n.translations[lang].name = data.display_translation.name;
      new_record.i18n.translations[lang].description = data.display_translation.description;

      delete new_record.name;
      delete new_record.description;

      delete new_record.display_name;
      delete new_record.display_description;

      delete new_record.display_translation;
      delete new_record.standard;
      delete new_record.force_create;
      delete new_record.keyid;

      const record = {
        type: ARTEFACT_RECORD_TYPE,
        subtype: ARTEFACT_RECORD_SUBTYPE,
        standard: data.standard,
        creator: rootGetters['SessionManager/getterCurrentUsername'],
        created_at: currentdatetime,
        replicate: false,
        keyid: data.artefact_id,
        timeline: [new_record]
      };

      // console.debug("createArtefactAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a artefact in the couchdb and return id or false
    async updateArtefactAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      try {
        const currentdatetime = +new Date();

        data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
        data.created_at = currentdatetime;

        if (!data.changetype_id || data.changetype_id == 'ID_TIMELINE_CHANGETYPE_CREATED') {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        const record = await dispatch('readArtefactByIdFromBackend', data.artefact_id);

        // console.debug("updateArtefactAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        var doc = record[0].value;

        // console.debug("updateArtefactAndStoreInBackend - Update Mode DOC", doc);

        // transform translations
        var new_record = {
          ...ARTEFACT_RECORD,
          ...data
        };

        const lang = data.display_translation.lang;

        new_record.i18n.translations[lang] = {};

        new_record.i18n.translations[lang].name = data.display_translation.name;
        new_record.i18n.translations[lang].description = data.display_translation.description;

        delete new_record.name;
        delete new_record.description;

        delete new_record.display_name;
        delete new_record.display_description;

        delete new_record.display_translation;
        delete new_record.standard;
        delete new_record.keyid;

        doc.timeline.push(new_record);

        return await backendDataApi.updateDocumentInBackend(doc);
      }
      catch (err) {
        console.error("updateArtefactAndStoreInBackend - Error: ", err);
        return false;
      }
    },
    async deleteArtefactByIdFromBackend({
      state
    }, artefact_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({ designdoc: 'artefacts/list', keyid: artefact_id });
    },

    //
    // Public action functions
    //

    //delete a existing asset from the couchdb
    async actionDeleteArtefactById({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, artefact_id) {
      try {
        const result = await dispatch('deleteArtefactByIdFromBackend', artefact_id);

        if (result !== false) {
          // console.debug('actionDeleteArtefactById - After Update');
          commit('ARTEFACT_REMOVED', artefact_id);
        }

        return result;
      }
      catch (err) {
        console.error("actionDeleteArtefactById - Error : ", err);
        return false;
      }
    },
    //find a artefact by id in the couchdb and return it
    async actionReceiveArtefactById({
      commit,
      dispatch
    }, artefact_id) {
      return dispatch('readArtefactByIdFromBackend', artefact_id).then(result => {
        if (result.length == 1)
          return { ...result[0].value };
        else
          return {};
      })
        .catch(e => {
          console.error(e);
          return false;
        });
    },
    //find the proof by id in the couchdb and return the last data element
    async actionReceiveCurrentArtefactDataById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, artefact_id) {
      try {
        const result = await dispatch('actionReceiveArtefactById', artefact_id);

        // console.debug("actionReceiveCurrentArtefactDataById result", result);

        if (result == false || CommonUtils.isObjectEmpty(result))
          throw "Error artefact not found";

        // console.debug("actionReceiveCurrentArtefactDataById result", result);
        const currentArtefact = {
          standard: result.standard,
          keyid: result.keyid,
          ...getters.getterEmptyArtefactRecord,
          ...result.timeline[result.timeline.length - 1]
        };

        return CommonUtils.translateI18nDataObjectByLc(currentArtefact, rootGetters["TranslationManager/getterLanguageAlpha2Code"]);
      }
      catch (err) {
        console.error("actionReceiveCurrentArtefactDataById - Error : ", err);
        return false;
      }
    },
    async actionReceiveActiveArtefactsAsArray({
      state,
      getters,
      rootGetters,
      commit,
      dispatch }) {
      try {
        const result = await backendDataApi.readViewFromBackendByOption("artefacts/activeInActivityDocs", {reduce: true, group_level: 1, include_docs: false}) ;

        // console.debug("actionReceiveActiveArtefactsAsArray - result", result)

        if(result == false)
          throw "Error reading active artefacts list";

        // console.debug("actionReceiveActiveArtefactsAsArray - keys", result.map((o) => o.key));

        return result.map((o) => o.key);
      }
      catch (err) {
        console.error("actionReceiveActiveArtefactsAsArray - Error : ", err);
        return [];
      }
    },
    async actionReceiveActivityDocIdsAsArrayByArtefactId({
      state,
      getters,
      rootGetters,
      commit,
      dispatch }, artefact_id) {
      try {
        const result = await backendDataApi.readViewFromBackendByOption('artefacts/activeInActivityDocs', {key: artefact_id, reduce: false, include_docs: false});

        // console.debug("actionReceiveActivityDocIdsAsArrayByArtefactId - result", result)

        // console.debug("actionReceiveActivityDocIdsAsArrayByArtefactId - keys", result.map((o) => o.key));

        return CommonUtils.removeDuplicatesFromArray(result.map((o) => o.value.activitydoc_id));
      }
      catch (err) {
        console.error("actionReceiveActivityDocIdsAsArrayByArtefactId - Error : ", err);
        return [];
      }
    },
    // read all artefact docs from the couchdb in cache
    async actionRefreshArtefactsCache({ getters, rootGetters, commit, dispatch }) {
      try {
        const result = await dispatch("readArtefactOverviewlistFromBackend");

        // console.debug("actionRefreshArtefactsCache - result", result);

        if (result === false)
          throw "Cannot read artefact overviewlist from backend";

        var artefactlist = [];

        if (result.length) {
          result.forEach((item) => {
            const artefactObj = {
              keyid: item.value.keyid,
              ...item.value.timeline[item.value.timeline.length - 1],
              standard: item.value.standard
            };

            // console.debug("actionRefreshArtefactsCache- artefactObj", artefactObj);
            artefactlist.push(artefactObj);
          });
        }

        // console.debug("actionRefreshArtefactsCache - artefactlist", artefactlist);

        commit("ARTEFACT_CACHE_LOAD", artefactlist);

        return true;
      } catch (err) {
        console.error("actionRefreshArtefactsCache - Error : ", err);
        return false;
      }
    },
    //save or update a new artefact into the couchdb
    async actionSaveArtefact({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, data) {
      try {
        // console.debug("actionSaveArtefact", data);
        let result = false;

        if (!data.artefact_id || Object.prototype.hasOwnProperty.call(data, "force_create")) {
          delete data.force_create;
          // console.debug("actionSaveArtefact - Create Mode", data);
          result = await dispatch('createArtefactAndStoreInBackend', data);
        }
        else {
          // console.debug("actionSaveArtefact - Update Mode", data);
          result = await dispatch('updateArtefactAndStoreInBackend', data);
        }

        //console.debug("actionSaveArtefact - result", result);

        if (result == false)
          throw "Error saving Artefact";

        commit("ARTEFACT_SAVED", data);

        return result;
      }
      catch (err) {
        console.error("actionSaveArtefact Error: ", err);
        return false;
      }
    },
  },
  getters: {
    getterEmptyArtefactRecord: (state) => {
      // console.debug("getterEmptyArtefactRecord - ARTEFACT_I18N_DISPLAY", merge({}, ARTEFACT_I18N_DISPLAY));
      return { ...ARTEFACT_RECORD, ...ARTEFACT_I18N_DISPLAY };
    },
    getterArtefactsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterArtefactsAsArrayByLc("");
    },
    getterArtefactById: (state, getters, rootState, rootGetters) => (artefact_id) => {
      try {
        var artefactObj = {};

        if(CommonUtils.isStringEmpty(artefact_id))
          throw "Empty id not allowed";

        const tempary = getters.getterArtefactsAsArray.filter(item => item.artefact_id == artefact_id);

        if(CommonUtils.isArrayEmpty(tempary) == false)
          artefactObj = tempary[0];

        return artefactObj;
      } catch (error) {
        console.error("getterArtefactById - Error :", error);
        return false;
      }
    },
    getterArtefactsAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      try {
        var artefactlist = [];

        const lang = CommonUtils.isStringEmpty(lc) ? rootGetters["TranslationManager/getterLanguageAlpha2Code"] : lc;

        state.artefactlist.forEach((item) => {
          const artefactObj = merge({}, getters.getterEmptyArtefactRecord, item);

          // console.debug("getterArtefactsAsArrayByLc - artefactObj", artefactObj);

          const result = CommonUtils.translateI18nDataObjectByLc(artefactObj, lang, "artefact_id");

          // console.debug("getterArtefactsAsArrayByLc - result", result);

          if (result == false)
            throw "Error in  getterTranslateArtefactDataByLc translation";

          artefactlist.push(result);
        });

        return artefactlist;
      } catch (error) {
        console.error("getterArtefactsAsArrayByLc - Error :", error);
        return [];
      }
    },
    getterCompactArtefactsAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterArtefactsAsArray.map(o => ({
        id: o.artefact_id,
        name: o.display_translation.name,
        description: o.display_translation.description
      }));
    },
  },
};