// Global setup to connect to the CouchDB
// The .env variables are used to build the DB connection string

// Take care of : char when using port
export const REMOTE = `${process.env.VUE_APP_COUCHDB_SCHEME}://${process.env.VUE_APP_COUCHDB_HOST}:${process.env.VUE_APP_COUCHDB_PORT}${process.env.VUE_APP_COUCHDB_PATH}`;

//
// Application specific named documents
//
export const VUEX_DOCUMENT_NAME = 'vuex';

// Base doc shared and updates by replication from cockpit
export const INITDATAORIGIN_DOCUMENT_NAME = 'dataorigin_gdpr';

export const INITPROCEDURE_DOCUMENT_NAME = 'procedure_gdpr';

// GDPR Agencies doc shared and updates by replication from cockpit
export const AGENCIES_GDPR_DOCUMENT_NAME = 'agencies_gdpr';

//
// Standard data objects (NoSQL scheme)
//
export const STAGEHISTORY_DATA = {
  created_at: false,
  created_by: "",
  finished_at: false,
  finished_by: ""
};

export const ADDRESS_DATA = {
  street: "",
  city: "",
  zipcode: "",
  country: "",
  federal_state: "",
  valid: false
};

export const CONTACT_DATA = {
  email_address: "",
  phone: "",
  fax: "",
  mobile: "",
  valid: false
};


//
// Contactbook
//
export const CONTACT_RECORD_TYPE = "contact";

export const CONTACT_RECORD = {
  contact_id: false,

  active: true,

  changetype_id: '',

  name: "",

  role_id: "",

  location_id: "",

  ...ADDRESS_DATA,
  ...CONTACT_DATA,

  notes: '',

  valid: false,

  created_at: false,
  created_by: ""
};

//
// Location
//
export const LOCATION_RECORD_TYPE = "location";

export const LOCATION_RECORD = {
  location_id: false,
  changetype_id: false,

  active: true,

  core: {
    type_id: false,

    name: "",

    ...ADDRESS_DATA,
    ...CONTACT_DATA,

    notes: '',

    valid: false,
  },

  responsible: {
    name: '',

    role_id: "ID_ENTITYCONTACT_ROLE_LOCATIONRESPONSIBLE",

    location_id: false,

    ...ADDRESS_DATA,
    ...CONTACT_DATA,

    valid: false
  },

  valid: false,

  created_at: false,
  created_by: ""
};

//
// Usertask record
//

export const USERTASK_RECORD_TYPE = "usertask";

export const USERTASK_STAGEHISTORY_RECORD = {
  stage: 1,
  ...STAGEHISTORY_DATA
};

export const USERTASK_RECORD = {

  usertask_id: false,
  stage: 1,

  active: true,

  assigned_to: "",
  changetype_id: "",

  archiveOnComplete: true,

  title: "",
  notes: "",

  deadline: false,
  start: false,

  reference: {
    stage: "",
    scope: "",
    refid: ""
  },

  valid: false,

  stagehistory: [],

  created_at: false,
  created_by: "",
};


//
// Attachment Metadata
//
export const ATTACHMENT_RECORD_TYPE = "attachment";

export const ATTACHMENT_METADATA_RECORD = {
  type: "attachment",

  name: '',

  attachment_id: false,

  doccategory_id: '',
  doctype_id: '',

  filename: '',

  notes: '',

  can_deleted: true,

  upload_by: '',
  upload_at: false,

  decommission_date: false,
  decommission_reason: '',

  active: true,

  proof_check_active: false,

  prooflink: {
    active: false,
    start_date: false,
    timeinterval: {},
    refid: false,
  },

  denied_groups: [],

  mimetype: '',

  reference: {
    scope: '',
    refid: false,
    stage: false,
  },

  valid: false
};

export const ATTACHMENT_RECORD = {
  ...ATTACHMENT_METADATA_RECORD,
  _attachments: {}
};

//
// OFFICER
//
export const OFFICER_RECORD_TYPE = "officer";

export const OFFICER_RECORD = {
  officer_id: false,

  changetype_id: "",

  active: false,

  contact: {
    external: false,
    type_id: false,

    company: "",
    name: "",

    ...ADDRESS_DATA,
    ...CONTACT_DATA,

    valid: false
  },
  detail: {
    formal_commission: false,
    commission_date: false,
    reporting_date: false,
    commission_notes: "",

    formal_decommission: false,
    decommission_date: false,
    cancellation_date: false,
    decommission_notes: "",

    valid: false
  },

  valid: false,

  created_at: false,
  created_by: ""
};

//
// Contract Partner
//
export const CONTRACTPARTNER_RECORD_TYPE = "contractpartner";

export const CONTRACTPARTNER_RECORD = {
  contractpartner_id: false,

  active: true,

  notes: "",

  decommission_date: false,
  decommission_notes: "",

  assigned_to: "",
  changetype_id: "",

  PartnerData: {
    valid: false,

    name: "",
    street: "",
    zipcode: "",
    city: "",
    country: "",
    federal_state: "",
    web_homepage: "",
    registration_number: "",
    email_address: "",
    phone: ""
  },
  ResponsibleData: {
    valid: false,

    street: "",
    city: "",
    zipcode: "",
    country: "",
    federal_state: "",
    name: "",
    role: "",
    email_address: "",
    phone: ""
  },
  GDPROfficerData: {
    valid: false,

    has_gdpr_officer: false,
    street: "",
    city: "",
    zipcode: "",
    country: "",
    federal_state: "",
    name: "",
    email_address: "",
    phone: ""
  },
  DetailData: {
    valid: false,

    entity_type: "",
    business_sector: "",
    contract_type: ""
  },
  LogoData: {
    imageData: false
  },

  valid: false,
  created_at: false,
  created_by: ""
};


//
// Request
//
export const REQUEST_RECORD_TYPE = "request";
export const REQUEST_SCHEMA_VERSION = 1;

export const REQUEST_STAGEHISTORY_RECORD = {
  stage: 1,
  ...STAGEHISTORY_DATA
};

export const REQUEST_RECORD = {

  request_id: false,
  stage: 1,
  active: true,

  version: REQUEST_SCHEMA_VERSION,

  assigned_to: "",
  changetype_id: "",
  changenotes: "",

  core: {
    description: '',
    notes: '',
    detection_date: '',
    detection_time: '',
    deadline_datetime: '',
    origin: "",
    category: "",
    valid: false
  },

  origin: {
    name: "",
    organisation: "",

    ...ADDRESS_DATA,
    ...CONTACT_DATA,

    valid: false
  },

  validation: {
    validationmethod_id: "",

    identification_id: "",
    authorization_id: "",

    method: "",
    result: "",

    proof_date: '',
    proof_time: '',

    valid: false
  },

  evaluation: {
    eval_date: '',
    eval_time: '',

    result: "",

    individual_categories: [],
    individual_categories_other: "",

    data_categories: [],
    data_categories_other: "",

    valid: false
  },

  notification: {
    notify_date: '',
    notify_time: '',

    notify_state_id: "",

    notify_notes: "",

    valid: false
  },

  preparation: {
    prepare_state_id: "",

    result: "",

    valid: false
  },

  completion: {
    result: "",

    completion_date: '',
    completion_time: '',

    valid: false
  },

  stagehistory: [],

  created_at: false,
  created_by: "",
};

//
// Incident
//
export const INCIDENT_RECORD_TYPE = "incident";
export const INCIDENT_SCHEMA_VERSION = 1;

export const INCIDENT_STAGEHISTORY_RECORD = {
  stage: 1,
  ...STAGEHISTORY_DATA
};

export const INCIDENT_RECORD = {

  incident_id: false,
  stage: 1,
  active: true,

  version: INCIDENT_SCHEMA_VERSION,

  assigned_to: "",
  changetype_id: "",
  changenotes: "",

  core: {
    title: '',
    description: '',
    location: "",

    detection_date: "",
    detection_time: "",

    deadline_datetime: "",

    detector_type: "",

    valid: false
  },

  impact: {
    individual_categories: [],
    individual_categories_other: "",

    data_categories: [],
    data_categories_other: "",

    individual_count: 0,
    datarecord_count: 0,

    impact_notes: "",

    valid: false
  },

  risk: {
    consequences: [],
    categories: [],

    level: "",

    evaluation: "",
    description: "",

    valid: false
  },

  implementation: {
    counter_actions_notes: "",

    begin_date: "",
    begin_time: "",

    end_date: "",
    end_time: "",

    reason_id: "",

    plan: "",

    valid: false,
  },

  notification: {
    individuals_notify_required: false,

    individuals_notify_state_id: false,
    individuals_notify_notes: "",

    individuals_notify_date: '',
    individuals_notify_time: '',

    thirdparty_involved: false,

    thirdparties: [],
    thirdparties_notes: "",

    valid: false,
  },

  report: {
    agency_report_required: false,
    agency_report_notes: "",

    agency_report_state_id: false,

    agency_report_date: '',
    agency_report_time: '',

    agency_report_incomplete: false,
    agency_report_incomplete_notes: "",

    agency_report_delayed: false,
    agency_report_delayed_notes: "",

    charge_reported: false,
    charge_notes: "",

    valid: false
  },

  completion: {
    result: "",
    internal_notes: "",

    implemented_date: '',
    implemented_time: '',

    valid: false
  },

  stagehistory: [],

  created_at: false,
  created_by: "",
};

//
// Proof
//
export const PROOF_RECORD_TYPE = "proof";

export const PROOF_STAGEHISTORY_RECORD = {
  changetype_id: false,
  stage: 1,
  ...STAGEHISTORY_DATA
};

export const PROOF_RECORD = {

  proof_id: false,
  stage: 1,
  active: true,

  assigned_to: "",
  changetype_id: "",
  changenotes: "",

  core: {
    type_id: "",
    timeinterval: {
      name: '',
      interval: false,
      unit: ''
    },

    repeating_active: false,
    description: "",
    start_date: false,
    deadline_date: false,

    reference: {
      scope: "",
      type: "",
      refid: false
    },
    valid: false
  },

  correctness: {
    done: false,
    result: '',
    remark: '',
    notes: '',
    level: 0,
    valid: false
  },
  completeness: {
    done: false,
    result: '',
    remark: '',
    notes: '',
    level: 0,
    valid: false
  },
  timeliness: {
    done: false,
    result: '',
    remark: '',
    notes: '',
    level: 0,
    valid: false
  },
  consistency: {
    done: false,
    result: '',
    remark: '',
    notes: '',
    level: 0,
    valid: false
  },
  overall: {
    result: '',
    remark: '',
    notes: '',
    level: 0,
    valid: false
  },

  stagehistory: [],

  created_at: false,
  created_by: ""
};

//
// Procedure
//
export const PROCEDURE_RECORD_TYPE = "procedure";

export const PROCEDURE_STAGEHISTORY_RECORD = {
  changetype_id: false,
  stage: 1,
  ...STAGEHISTORY_DATA
};

export const PROCEDURE_RECORD = {

  procedure_id: false,

  active: true,
  approved: false,

  stage: 1,
  revision: 0,

  assigned_to: "",
  changetype_id: "",
  changenotes: "",

  core: {
    notes: '',

    activation_date: "",
    decommission_date: "",

    type_id: "",
    target_id: "",

    valid: false
  },

  common: {
    procedure_required: true,

    notes: '',

    tech_procedures: [],
    org_procedures: [],

    tech_procedures_other: [],
    org_procedures_other: [],

    valid: false
  },

  responsibility: {
    procedure_required: true,

    notes: '',

    tech_procedures: [],
    org_procedures: [],

    tech_procedures_other: [],
    org_procedures_other: [],

    valid: false
  },

  datainput: {
    procedure_required: true,

    notes: '',

    tech_procedures: [],
    org_procedures: [],

    tech_procedures_other: [],
    org_procedures_other: [],

    valid: false
  },

  datatransfer: {
    procedure_required: true,

    notes: '',

    tech_procedures: [],
    org_procedures: [],

    tech_procedures_other: [],
    org_procedures_other: [],

    valid: false
  },

  datastorage: {
    procedure_required: true,

    notes: '',

    tech_procedures: [],
    org_procedures: [],

    tech_procedures_other: [],
    org_procedures_other: [],

    valid: false
  },

  datamanagement: {
    procedure_required: true,

    notes: '',

    tech_procedures: [],
    org_procedures: [],

    tech_procedures_other: [],
    org_procedures_other: [],

    valid: false
  },

  valid: false,

  stagehistory: [],

  created_at: false,
  created_by: ""
};


//
// Template basic record
//
export const TEMPLATE_RECORD_TYPE = "template";

export const TEMPLATE_RECORD = {
  type: TEMPLATE_RECORD_TYPE,
  subtype: "",
  replicate: false,
  stage: 1,
  revision: 0,
  keyid: "",
  standard: true,
  active: false,
  selectable: false,
  creator: "",
  version: "",
  release_date: "",
  justice_areas: [],
  data: [],
};
