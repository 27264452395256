export const LC_LOCALE_KEY = 'lc_locale';

export const DEFAULT_LANG = 'gb';

//array containing the language datainformation
export const LANGUAGES_ARRAY = [{
    locale: 'en_GB',
    moment: 'en-gb',
    code: 'en',
    uc: 'GB',
    lc: 'gb',
    full: 'English',
    bcp47: 'en-GB'    
  },
  {
    locale: 'de_DE',
    moment: 'de',
    code: 'de',
    uc: 'DE',
    lc: 'de',
    full: 'Deutsch',
    bcp47: 'de-DE'  
  }
];

export const DEFAULT_LANGUAGE = {
  ...LANGUAGES_ARRAY[0]
};
