export const TIMEINTERVAL_I18N_KEY = "time_intervals.";

export const TIMEINTERVAL_LIST = [
    {
      "interval": 1,
      "unit": "day",
      "name": "TIMEINTERVAL_ONEDAY_LABEL",
      "description": "TIMEINTERVAL_ONEDAY_DESCRIPTION"
    },
    {
      "interval": 1,
      "unit": "week",
      "name": "TIMEINTERVAL_ONEWEEK_LABEL",
      "description": "TIMEINTERVAL_ONEWEEK_DESCRIPTION"
    },
    {
      "interval": 2,
      "unit": "week",
      "name": "TIMEINTERVAL_TWOWEEKS_LABEL",
      "description": "TIMEINTERVAL_TWOWEEKS_DESCRIPTION"
    },
    {
      "interval": 1,
      "unit": "month",
      "name": "TIMEINTERVAL_ONEMONTH_LABEL",
      "description": "TIMEINTERVAL_ONEMONTH_DESCRIPTION"
    },
    {
      "interval": 2,
      "unit": "month",
      "name": "TIMEINTERVAL_TWOMONTHS_LABEL",
      "description": "TIMEINTERVAL_TWOMONTHS_DESCRIPTION"
    },
    {
      "interval": 3,
      "unit": "month",
      "name": "TIMEINTERVAL_THREEMONTHS_LABEL",
      "description": "TIMEINTERVAL_THREEMONTHS_DESCRIPTION"
    }, 
    {
      "interval": 6,
      "unit": "month",
      "name": "TIMEINTERVAL_SIXMONTHS_LABEL",
      "description": "TIMEINTERVAL_SIXMONTHS_DESCRIPTION"
    },
    {
      "interval": 1,
      "unit": "year",
      "name": "TIMEINTERVAL_ONEYEAR_LABEL",
      "description": "TIMEINTERVAL_ONEYEAR_DESCRIPTION"
    },
    {
      "interval": 0,
      "unit": "day",
      "name": "TIMEINTERVAL_CUSTOM_LABEL",
      "description": "TIMEINTERVAL_CUSTOM_DESCRIPTION"
    }    
  ];