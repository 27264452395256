export const REPOSITORY_CH_LIST = {
    "_id": "repository",
    "type": "template",
    "subtype": "files",
    "standard": true,
    "creator": "DAPAX",
    "created_at": "2022-10-16",
    "updated_at": "2022-10-17",
    "keyid": "repository",
    "repository": {
        "justiceareas": [
            "CH"
        ],

        "name": "Musterdokumentvorlagen",
        "description": "Musterdokumentvorlagen",

        "I18N_KEY": "repository.",
        "LIST": [{
                "filename": "Datenschutzinformation_Bewerbungsverfahren_nach_DSG_Muster.docx",
                "name": "datenschutzinformation_bewerbungsverfahren_dsg_template_name",
                "description": "datenschutzinformation_bewerbungsverfahren_dsg_template_description",
                "category": "ID_DOCUMENTCATEGORY_INFORMATION",
                "type": "ID_DOCUMENTTYPE_PRIVACYINFORMATION"
            },

            {
                "filename": "Datenschutzinformation_Durchfuehrung_Beschaeftigungsverhaeltnis_nach_DSG_Muster.docx",
                "name": "datenschutzinformation_durchfuehrung_beschaeftigungsverhaeltnis_dsg_template_name",
                "description": "datenschutzinformation_durchfuehrung_beschaeftigungsverhaeltnis_dsg_template_description",
                "category": "ID_DOCUMENTCATEGORY_INFORMATION",
                "type": "ID_DOCUMENTTYPE_PRIVACYINFORMATION"
            },

            {
                "filename": "Datenschutzinformation_Heimbewohner_nach_DSG_Muster.docx",
                "name": "datenschutzinformation_heimbewohner_dsg_template_name",
                "description": "datenschutzinformation_heimbewohner_dsg_template_description",
                "category": "ID_DOCUMENTCATEGORY_INFORMATION",
                "type": "ID_DOCUMENTTYPE_PRIVACYINFORMATION"
            },
            {
                "filename": "Eingangsbestaetigung_Betroffenenanfrage_nach_DSG_Muster.docx",
                "name": "eingangsbestaetigung_betroffenenanfrage_dsg_template_name",
                "description": "eingangsbestaetigung_betroffenenanfrage_dsg_template_description",
                "category": "ID_DOCUMENTCATEGORY_REQUEST",
                "type": "ID_DOCUMENTTYPE_INDIVIDUALNOTIFICATION"
            },
            {
                "filename": "Einwilligung_Fotonutzung_Muster.docx",
                "name": "einwilligung_fotonutzung_template_name",
                "description": "einwilligung_fotonutzung_template_description",
                "category": "ID_DOCUMENTCATEGORY_PROCESS",
                "type": "ID_DOCUMENTTYPE_CONSENT"
            },

            {
                "filename": "EMailAbbinder_nach_DSG_Muster.docx",
                "name": "emailabbinder_dsg_template_name",
                "description": "emailabbinder_dsg_template_description",
                "category": "ID_DOCUMENTCATEGORY_INFORMATION",
                "type": "ID_DOCUMENTTYPE_PRIVACYINFORMATION"
            },
            {
                "filename": "Verpflichtung_zur_Vertraulichkeit_von_Beschaeftigten_nach_DSG_Muster.docx",
                "name": "vertraulichkeitsverpflichtung_dsg_template_name",
                "description": "vertraulichkeitsverpflichtung_dsg_template_description",
                "category": "ID_DOCUMENTCATEGORY_OTHER",
                "type": "ID_DOCUMENTTYPE_COMMITMENTTOCONFIDENTIALITY"
            }
        ],
        "I18N": {
            "languages": [
                "de",
                "gb"
            ],
            "defaultlang": "de",
            "translations": {
                "de": {
                  "datenschutzinformation_bewerbungsverfahren_dsg_template_name": "Datenschutzinformation für Bewerberinnen und Bewerber",
                  "datenschutzinformation_bewerbungsverfahren_dsg_template_description": "Musterinformationsschreiben zum Datenschutz beim Bewerbungsverfahren",
                  "datenschutzinformation_durchfuehrung_beschaeftigungsverhaeltnis_dsg_template_name": "Datenschutzinformation für Beschäftigte",
                  "datenschutzinformation_durchfuehrung_beschaeftigungsverhaeltnis_dsg_template_description": "Musterinformationsschreiben zum Datenschutz beim Beschäftigungsverhältnis",
                  "datenschutzinformation_heimbewohner_dsg_template_name": "Datenschutzinformation für Heimbewohnerinnen und Heimbewohner",
                  "datenschutzinformation_heimbewohner_dsg_template_description": "Musterinformationsschreiben zum Datenschutz beim Pflege- und Betreuungsverhältnis",
                  "eingangsbestaetigung_betroffenenanfrage_dsg_template_name": "Eingangsbestätigung von Auskunftsersuchen",
                  "eingangsbestaetigung_betroffenenanfrage_dsg_template_description":"Musterdokument zur Eingangsbestätigung von Auskunftsersuchen von Personen",
                  "einwilligung_fotonutzung_template_name": "Einwilligungserklärung zur Nutzung von Beschäftigtenfotos",
                  "einwilligung_fotonutzung_template_description": "Mustereinwilligungserklärung zur Nutzung von Beschäftigtenfotos",
                  "emailabbinder_dsg_template_name": "E-Mail-Abbinder",
                  "emailabbinder_dsg_template_description":"Musterinformationsschreiben zum Datenschutz bei Kontaktaufnahme per E-Mail",
                  "vertraulichkeitsverpflichtung_dsg_template_name": "Verpflichtung zur Vertraulichkeit von Beschäftigten",
                  "vertraulichkeitsverpflichtung_dsg_template_description": "Musterdokument zur Vertraulichkeitsverpflichtung von Beschäftigten"
                },
                "gb": {
                  "datenschutzinformation_bewerbungsverfahren_dsg_template_name": "Privacy information for applicants",
                  "datenschutzinformation_bewerbungsverfahren_dsg_template_description": "Sample information letter on data protection in the application procedure",
                  "datenschutzinformation_durchfuehrung_beschaeftigungsverhaeltnis_dsg_template_name": "Privacy information for employees",
                  "datenschutzinformation_durchfuehrung_beschaeftigungsverhaeltnis_dsg_template_description": "Sample information letter on data protection in the employment relationship",
                  "datenschutzinformation_heimbewohner_dsg_template_name": "Data protection information for residents of the home",
                  "datenschutzinformation_heimbewohner_dsg_template_description": "Sample information letter on data protection in the care and support relationship",
                  "eingangsbestaetigung_betroffenenanfrage_dsg_template_name": "Acknowledgement of receipt of requests for information",
                  "eingangsbestaetigung_betroffenenanfrage_dsg_template_description":"Sample document for acknowledgement of receipt of requests for information from persons",
                  "einwilligung_fotonutzung_template_name": "Declaration of consent for the use of employee photos",
                  "einwilligung_fotonutzung_template_description": "Model declaration of consent for the use of employee photos",
                  "emailabbinder_dsg_template_name": "E-mail binder",
                  "emailabbinder_dsg_template_description":"Sample information letter on data protection when contacting us by e-mail",
                  "vertraulichkeitsverpflichtung_dsg_template_name": "Obligation to confidentiality of employees",
                  "vertraulichkeitsverpflichtung_dsg_template_description": "Sample document on the confidentiality obligation of employees"
                }
            }
        }
    }
};
