
export const AppIcons = {
    data() {
        return {};
    },
    computed: {
        //Topic icons
        getIncidentIcon() {
            return 'mdi-close-circle-outline';
        },
        getContactIcon() {
            return 'mdi-account-outline';
        },
        getContactBookIcon() {
            return 'mdi-account-group-outline';
        },
        getRequestIcon() {
            return 'mdi-account-box-multiple-outline';
        },
        getProofIcon() {
            return 'mdi-checkbox-marked-outline';
        },
        getOrgChartIcon() {
            return 'mdi-file-tree-outline';
        },
        getTaskIcon() {
            return 'mdi-calendar-outline';
        },
        getDocumentIcon() {
            return 'mdi-file-search-outline';
        },
        getContractpartnerIcon() {
            return 'mdi-contacts-outline';
        },
        getAssetIcon() {
            return 'mdi-tag-multiple-outline';
        },
        getRiskIcon() {
            return 'mdi-alert-rhombus-outline';
        },
        getProcedureIcon() {
            return 'mdi-shield-home-outline';
        },
        getDsfaIcon() {
            return 'mdi-book-alert-outline';
        },
        getActivitydocIcon() {
            return 'mdi-content-save-cog-outline';
        },
        getProcessIcon() {
            return 'mdi-archive-outline';
        },
        getAreaIcon() {
            return 'mdi-ungroup';
        },
        getEntityIcon() {
            return 'mdi-domain';
        },
        getGDPROfficerIcon() {
            return 'mdi-shield-account-outline';
        },
        getPendingActivitiesIcon() {
            return 'mdi-calendar-clock-outline';
        },
        getLocationIcon(){
            return 'mdi-google-maps';
        },
        getOfficerIcon(){
            return 'mdi-shield-account-outline';
        },

        //Default action icons
        getActionAddIcon() {
            return 'mdi-plus-circle';
        },
        getActionReloadIcon() {
            return 'mdi-reload';
        },
        getActionBackIcon() {
            return 'mdi-chevron-left';
        },
        getActionNextIcon() {
            return 'mdi-chevron-right';
        },
        getActionGoToIcon(){
            return 'mdi-arrow-right-circle-outline';
        },

        //Default information icons
        getDoneIcon() {
            return 'mdi-check';
        },
        getSaveIcon() {
            return 'mdi-check';
        },
        getPlusIcon() {
            return 'mdi-plus';
        },
        getCheckOutlineIcon() {
            return 'mdi-check-outline';
        },
        getNotDoneIcon() {
            return 'mdi-circle-outline';
        },
        getAlertIcon() {
            return 'mdi-alert';
        },
        getInformationIcon() {
            return 'mdi-information-outline';
        },
        getOverDueIcon() {
            return  'mdi-bell-alert-outline'; //'mdi-calendar-alert';
        }
    }
};