//
// Entity classification
//

export const ENTITYCLASS_DOCUMENT_NAME = "entity_classes";

export const ENTITYCLASS_RECORD_TYPE = "lookup";
export const ENTITYCLASS_RECORD_SUBTYPE = "entity";

export const ENTITYCLASS_RECORD = {
    name: "",
    description: "",
    countries: [],
    nace2_code: "",
    sequence: 0
};
