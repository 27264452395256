<template>
    <v-tooltip left>
        <template v-slot:activator="{ on }">                          
        <div v-on="on" class="language-select">
            <v-select
            class="language-selection"
            :items="languages"
            v-model="global_language"
            item-text="uc"
            return-object
            @change="evtChangeCurrentLanguage"
            :append-icon="''"
            >
              <template v-slot:selection="{ item }">
                <flag :iso="item.lc" :squared="false" />
              </template>
            </v-select>
        </div>
        </template>
        <span v-text="$t('login.btn_language_select')"></span>
    </v-tooltip>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
  name: "LanguageSelector",    
  components: {
  },
  data: () => ({
    global_language: ""
  }),
  beforeMount() {
  },
  mounted() {
    this.global_language = this.getterLanguage;   
  },
  methods: {
    ...mapActions({
      setLocale: "TranslationManager/setLocale",
    }),    
    evtChangeCurrentLanguage() {
      this.setLocale(this.global_language);
      this.$emit('setLanguage', this.global_language);
    }
  },
  computed: {
    ...mapGetters({
      languages: "TranslationManager/languages",
      getterLanguage: "TranslationManager/lang",  
    })
  },
};
</script>

<style scoped lang="scss">
@import '@/scss/variables';

/* Language selector using v-select */

.language-select {
  width: $flag-icon-width;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.language-selection :deep().v-input__slot:before {
  border-style: none !important;
}
.language-select .v-text-field.primary--text {
  color: transparent !important;
}
</style>