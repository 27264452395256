import DB from '@/services/couchdb.service.js';

import CommonUtils from '@/utils/common.utils.js';

import {
  CUSTOM_ID_PREFIX
} from '@/config/config.constants.js';

var backenDataApi = (function() {
    'use strict';

    return {
      readOverviewlistFromBackend: function(query) {
        // console.debug("readOverviewlistFromBackend", query)

        return DB.instance()
        .query(query.designdoc, {key: query.keyid, include_docs: false})
        .then(result => {return result.rows;})
        .catch(err => {
          console.error("readOverviewlistFromBackend - Error: ", err);
          return false;
        });
      },

      readOverviewlistFromBackendByComplexFilter: function(query) {
        // console.debug("readOverviewlistFromBackend", query)

        return DB.instance()
        .query(query.designdoc, {startkey: query.startkey, endkey: query.endkey, include_docs: false})
        .then(result => {return result.rows;})
        .catch(err => {
          console.error("readOverviewlistFromBackend - Error: ", err);
          return false;
        });
      },

      readViewFromBackendByOption: function(viewname, options) {
        // console.debug("readOverviewlistFromBackend", query)

        return DB.instance()
        .query(viewname, options)
        .then(result => {return result.rows;})
        .catch(err => {
          console.error("readViewFromBackendByOption - Error: ", err);
          return false;
        });
      },


      readDocumentlistFromBackend: function(query) {
        // console.debug("readDocumentlistFromBackend", query);

        return DB.instance()
        .query(query.designdoc, {key: query.keyid, include_docs: query.include_docs})
        .then(result => {return result.rows;})
        .catch(err => {
          console.error("readOverviewlistFromBackend - Error: ", err);
          return false;
        });
      },

      deleteDocumentByKeyIdFromBackend: async function(query) {
        try
        {
          if(CommonUtils.isObjectEmpty(query) || CommonUtils.isStringEmpty(query.keyid)) return false;

          const qrydoc = await DB.instance().query(query.designdoc, {key: query.keyid, include_docs: false});

          if(CommonUtils.isArrayEmpty(qrydoc.rows))
            throw "Document with KeyID " + query.keyid + " not found";

          // console.debug("deleteDocumentByKeyIdFromBackend - qrydoc", qrydoc.rows);

          const result = await DB.instance().remove(qrydoc.rows[0].value);

          // console.debug("deleteDocumentByKeyIdFromBackend - remove result", result);

          return (!CommonUtils.isObjectEmpty(result) && result.ok === true);
        }
        catch(err)
        {
          console.warn("deleteDocumentByKeyIdFromBackend - Error: ", err);
          return false;
        }
      },

      readAttachmentBlobByDocFromBackend: async function(doc) {
        try
        {
          if(CommonUtils.isObjectEmpty(doc)) return false;

          return await DB.instance().getAttachment(doc._id, Object.keys(doc._attachments)[0]);
        }
        catch(error)
        {
          console.error("readAttachmentBlobByDocFromBackend", error);
          return false;
        }
      },

      readAttachmentBlobByDocIdByFilenameFromBackend: async function(doc_id, filename) {
        try
        {
          if(CommonUtils.isStringEmpty(doc_id) || CommonUtils.isStringEmpty(filename)) return false;

          return await DB.instance().getAttachment(doc_id, filename);
        }
        catch(error)
        {
          console.error("readAttachmentBlobByDocFromBackend", error);
          return false;
        }
      },

      deleteDocumentFromBackend: async function(doc) {
        try
        {
          if(CommonUtils.isObjectEmpty(doc)) return false;

          // console.debug("deleteDocumentByDocFromBackend - doc", doc);

          const result = await DB.instance().remove(doc);

          // console.debug("deleteDocumentByDocFromBackend - remove result", result);

          return (!CommonUtils.isObjectEmpty(result) && result.ok === true);
        }
        catch(err)
        {
          console.error("deleteDocumentByDocFromBackend - Error: ", err);
          return false;
        }
      },

      readDocumentByKeyIdFromBackend: function(query) {

        if(CommonUtils.isStringEmpty(query.keyid)) return false;

        return DB.instance()
        .query(query.designdoc, {key: query.keyid, include_docs: false})
        .then(result => {
          // console.debug('readDocumentByKeyIdFromBackend result', result);
          return result.rows; })
        .catch(err => {
          console.error("readDocumentByKeyIdFromBackend - Error: ", err);
          return false;
        });
      },

      createDocumentInBackend: function(documentObj) {
        if(CommonUtils.isObjectEmpty(documentObj))
          return false;

        const currentdatetime = +new Date();

        documentObj.createstamp = currentdatetime;
        documentObj.timestamp = currentdatetime;

        return DB.instance().post(documentObj)
        .then(response => {
          // add to the list
          response.id = documentObj.keyid;
          return response;
        })
        .catch(err => {
          console.error("createDocumentInBackend - Error: ", err);
          return false;
        });
      },

      updateDocumentInBackend: function(documentObj) {
        if(CommonUtils.isObjectEmpty(documentObj))
          return false;

        const currentdatetime = +new Date();

        documentObj.timestamp = currentdatetime;

        return DB.instance().put(documentObj)
        .then(response => {
          // add to the list

          response.id = documentObj.keyid;
          return response;
        })
        .catch(err => {
          console.error("updateDocumentInBackend - Error: ", err);
          return false;
        });
      },

      generateNewCustomKeyId: function(typename) {
        return CUSTOM_ID_PREFIX + "_" + typename.toUpperCase() + "_" + Math.floor(Math.random() * (+new Date()));
      },

      readDocumentByDocIdFromBackend: async function(docId) {
        try
        {
          if(CommonUtils.isStringEmpty(docId) || !DB.instance()) return false;

          const doc = await DB.instance().get(docId);

          // console.debug("readDocumentByDocIdFromBackend - doc", doc);
          return doc;
        }
        catch(err)
        {
          console.error('readDocumentByDocIdFromBackend - Error : ', err);
          return false;
        }
      },
      saveDocumentContentInBackendByDocId: function(document_id, documentObj) {
        // update document in couchDB
        if(CommonUtils.isStringEmpty(document_id))
          return false;

        if(CommonUtils.isObjectEmpty(documentObj))
          return false;

        return DB.instance().get(document_id)
        .then(doc => {
          let doc_content = {
            ...doc,
            ...documentObj
          };

          // Reset the existing doc id and rev for update
          doc_content._id = doc._id;
          doc_content._rev = doc._rev;

          const currentdatetime = +new Date();

          documentObj.timestamp = currentdatetime;

          return DB.instance().put(doc_content, (errors, results) => {
            return results;
            // check errors?
          });
        })
        .catch((errors) => {
          return false;
        });
      }
    };
  })();

  export default backenDataApi;