import backendDataApi from "@/services/backendDataApi.service.js";

import merge from 'lodash.merge';

import {REQUESTSTAGE_LIST} from "@/lookup/request_stages";

import {
  REQUEST_RECORD,
  REQUEST_RECORD_TYPE,
  REQUEST_SCHEMA_VERSION,
  REQUEST_STAGEHISTORY_RECORD,
} from "@/config/config.couchdb";

export default {
  namespaced: true,
  state: {},
  mutations: {
    REQUEST_SAVED(state, payload) {},
    REQUEST_REMOVED(state, payload) {},
    REQUEST_ASSIGNED(state, payload) {},
  },
  actions: {
    //
    // private - protected action functions
    //
    async deleteRequestByRequestIdFromBackend({state}, request_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({
        designdoc: "requests/list",
        keyid: request_id,
      });
    },
    //find all requests with limited content by filter in the couchdb and return them
    async readRequestOverviewlistFromBackend(
      {state},
      {startfilter, endfilter}
    ) {
      return await backendDataApi.readOverviewlistFromBackendByComplexFilter({
        designdoc: "requests/overviewlistByStage",
        startkey: startfilter,
        endkey: endfilter,
      });
    },
    //find a request by id in the couchdb and return it
    async readRequestByIdFromBackend({state}, request_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({
        designdoc: "requests/list",
        keyid: request_id,
      });
    },
    //create a new request in the couchdb and return request_id or false
    async createRequestAndStoreInBackend({rootGetters, commit}, data) {
      // Make sure an request_id is assigned on save for a new record
      const currentdatetime = +new Date();

      // console.debug("createRequestAndStoreInBackend - data", data);

      if (!data.request_id || data.request_id === undefined)
        data.request_id = backendDataApi.generateNewCustomKeyId("req");

      // Safe guard to ensure a valid id
      if (!data.request_id || 0 === data.request_id.length) return false;

      data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
      data.created_at = currentdatetime;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_DRAFTCREATED";

        data.stagehistory.push({
          ...REQUEST_STAGEHISTORY_RECORD,
          changetype_id: data.changetype_id,
          stage: data.stage,
          created_at: data.created_at,
          created_by: data.created_by,
          finished_at: +new Date(),
          finished_by: rootGetters["SessionManager/getterCurrentUsername"],
        });
      }

      if (!data.assigned_to) {
        data.assigned_to = rootGetters["SessionManager/getterCurrentUsername"];
      }

      const record = {
        username: rootGetters["SessionManager/getterCurrentUsername"],

        type: REQUEST_RECORD_TYPE,
        keyid: data.request_id,

        timeline: [data],
      };

      // console.debug("createRequestAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a request in the couchdb and return request_id or false
    async updateRequestAndStoreInBackend(
      {rootGetters, dispatch, commit},
      data
    ) {
      try {
        const currentdatetime = +new Date();

        data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
        data.created_at = currentdatetime;

        if (
          !data.changetype_id ||
          data.changetype_id == "ID_TIMELINE_CHANGETYPE_CREATED"
        ) {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        const record = await dispatch("readRequestByIdFromBackend", data.request_id);

        // console.debug("updateRequestAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        // console.debug("updateRequestAndStoreInBackend - Update Mode DOC", record[0].value);

        record[0].value.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(record[0].value);
      } catch (err) {
        console.error("updateRequestAndStoreInBackend - Error: ", err);
        return false;
      }
    },

    // Data object upgrade function & migration based on version
    async actionUpgradeDataObject(
      {state, getters, rootGetters, commit, dispatch},
      requestObject
    ) {
      // Safe guard unnecessary migration based on schema version
      if((Object.prototype.hasOwnProperty.call(requestObject, "version"))) {
        if(requestObject.version === REQUEST_SCHEMA_VERSION)
          return requestObject;
      }

      // Simple default property propagation
      var requestObj = {};

      merge(requestObj, getters.getterEmptyRequestRecord, requestObject);

      // console.debug("actionUpgradeDataObject - requestObject", requestObject);
      // console.debug("actionUpgradeDataObject - requestObj", requestObj);

      return requestObj;
    },

    //
    // Public actions
    //

    //find all requests with limited content in the couchdb and return them
    async actionReceiveRequestOverviewAsArray({dispatch}, filterarray) {
      try {
        var filterObj = {
          startfilter: [],
          endfilter: [{}],
        };

        if (Array.isArray(filterarray) && filterarray.length == 2) {
          filterObj.startfilter = [filterarray[0]];
          filterObj.endfilter = [filterarray[1], {}];
        }

        const result = await dispatch(
          "readRequestOverviewlistFromBackend",
          filterObj
        );

        if (result.length) return result.map((o) => o.value);
        else return [];
      } catch (err) {
        console.error("actionReceiveRequestOverviewAsArray Error: ", err);
        return false;
      }
    },
    async actionReceiveRequestById({commit, dispatch}, request_id) {
      // console.debug('actionReceiveRequestById - request_id', request_id);

      try {
        const result = await dispatch("readRequestByIdFromBackend", request_id);

        if (result.length != 1)
          throw "Reading unique request_id returns more than one record";

        return {...result[0].value};
      } catch (err) {
        console.error("actionReceiveRequestById - Error: ", err);
        return false;
      }
    },
    //find an request by id in the couchdb and return the last data element
    async actionReceiveCurrentRequestDataById(
      {state, getters, rootGetters, commit, dispatch},
      request_id
    ) {
      try {
        const result = await dispatch("actionReceiveRequestById", request_id);

        if(result == undefined || false === result)
          throw "Error request not found";

        // console.debug("actionReceiveCurrentRequestDataById result", result);

        if ((Object.keys(result).length === 0 || result.constructor !== Object))
          throw "Error current request not found";

        return {...result.timeline[result.timeline.length - 1]};
      } catch (err) {
        console.error("actionReceiveCurrentRequestDataById - Error : ", err);
        return false;
      }
    },
    //delete a request from the couchdb
    async actionDeleteRequestById(
      {state, getters, rootGetters, dispatch, commit},
      request_id
    ) {
      try {
        //  console.debug("actionDeleteRequestById - request_id", request_id);

        const result = await dispatch(
          "deleteRequestByRequestIdFromBackend",
          request_id
        );
        //  console.debug("actionDeleteRequestById - result", result);
        if (result !== false) {
          // console.debug('actionDeleteRequestById - After Update');
          commit("REQUEST_REMOVED", request_id);

          // Delete attachments
          await dispatch(
            "AttachmentManager/actionDeleteAttachmentsByReference",
            {refid: request_id, scope: "request"},
            {root: true}
          );
        }

        return result;
      } catch (err) {
        console.error("actionDeleteRequestById - Error : ", err);
        return false;
      }
    },
    //generate request report
    async actionGenerateRequestReport(
      {state, getters, rootGetters, dispatch, commit},
      requestObj
    ) {
      try {
        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "request_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

        printjobInfoObj.data.request = {...requestObj};

        // console.debug("actionGenerateRequestReport - printjobInfoObj", printjobInfoObj);

        if (printjobInfoObj.data.request.stage) {
          printjobInfoObj.data.request.stage_display = rootGetters["LookupManager/getterRequestStagesAsArrayByLc"](printjobInfoObj.lang)
            .filter((item) => item.stage === printjobInfoObj.data.request.stage)[0].display_name;
        }

        if (printjobInfoObj.data.request.request_id) {
          const keyid = printjobInfoObj.data.request.request_id;

          printjobInfoObj.data.request.request_id = keyid.lastIndexOf("_") > 0 ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;
        }

        // Core
        if (printjobInfoObj.data.request.core.category && 0 !== printjobInfoObj.data.request.core.category.length) {
          printjobInfoObj.data.request.core.category_display = rootGetters["LookupManager/getterRequestCategoriesAsArrayByLc"](printjobInfoObj.lang)
            .filter((item) => item.id === printjobInfoObj.data.request.core.category)[0].display_name;
        }

        if (printjobInfoObj.data.request.core.origin && 0 !== printjobInfoObj.data.request.core.origin.length) {
          printjobInfoObj.data.request.core.origin_display = rootGetters["LookupManager/getterRequestOriginsAsArrayByLc"](printjobInfoObj.lang)
            .filter((item) => item.id === printjobInfoObj.data.request.core.origin)[0].display_name;
        }

        // Validation
        if (printjobInfoObj.data.request.validation.validationmethod_id && 0 !== printjobInfoObj.data.request.validation.validationmethod_id.length) {
          printjobInfoObj.data.request.validation.validationmethod_display = rootGetters["LookupManager/getterRequestValidationMethodsAsArrayByLc"](printjobInfoObj.lang)
            .filter((item) => item.id === printjobInfoObj.data.request.validation.validationmethod_id)[0].display_name;
        }

        if (printjobInfoObj.data.request.validation.identification_id && 0 !== printjobInfoObj.data.request.validation.identification_id.length) {
          printjobInfoObj.data.request.validation.identification_display = rootGetters["LookupManager/getterRequestIdentityStatesAsArrayByLc"](printjobInfoObj.lang)
            .filter((item) => item.id === printjobInfoObj.data.request.validation.identification_id)[0].display_name;
        }

        if (printjobInfoObj.data.request.validation.authorization_id && 0 !== printjobInfoObj.data.request.validation.authorization_id.length) {
          printjobInfoObj.data.request.validation.authorization_display = rootGetters["LookupManager/getterRequestAuthorizationStatesAsArrayByLc"](printjobInfoObj.lang)
            .filter((item) => item.id === printjobInfoObj.data.request.validation.authorization_id)[0].display_name;
        }

        // Evaluation
        printjobInfoObj.data.request.evaluation.individual_categories_display = [];
        printjobInfoObj.data.request.evaluation.individual_categories.forEach(
          function (item) {
            printjobInfoObj.data.request.evaluation.individual_categories_display.push(
              rootGetters["DataSubjectManager/getterDataSubjectsAsArrayByLc"](printjobInfoObj.lang)
                .filter((subject) => subject.id === item)[0].display_name);
          }
        );

        printjobInfoObj.data.request.evaluation.data_categories_display = [];
        printjobInfoObj.data.request.evaluation.data_categories.forEach(
          function (item) {
            printjobInfoObj.data.request.evaluation.data_categories_display.push(
              rootGetters["DataCategoryManager/getterDataCategoriesAsArrayByLc"](printjobInfoObj.lang)
                .filter((category) => category.id === item)[0].display_name);
          }
        );

        // Preparation
        if (printjobInfoObj.data.request.preparation.prepare_state_id && 0 !== printjobInfoObj.data.request.preparation.prepare_state_id.length) {
          printjobInfoObj.data.request.preparation.prepare_state_display = rootGetters["LookupManager/getterRequestPreparationStatesAsArrayByLc"](printjobInfoObj.lang)
            .filter((item) => item.id === printjobInfoObj.data.request.preparation.prepare_state_id)[0].display_name;
        }

        // Notification
        if (printjobInfoObj.data.request.notification.notify_state_id && 0 !== printjobInfoObj.data.request.notification.notify_state_id.length) {
          printjobInfoObj.data.request.notification.notify_state_display = rootGetters["LookupManager/getterRequestNotificationStatesAsArrayByLc"](printjobInfoObj.lang)
            .filter((item) => item.id === printjobInfoObj.data.request.notification.notify_state_id)[0].display_name;
        }

        // History data
        if (printjobInfoObj.data.request.stagehistory.length) {
          printjobInfoObj.data.request.stagehistory.forEach(function (item) {
            if (Object.prototype.hasOwnProperty.call(item, "changetype_id"))
              item.change_display = rootGetters["LookupManager/getterTimelineChangeTypesAsArrayByLc"](printjobInfoObj.lang)
                .filter((el) => el.id === item.changetype_id)[0].display_name;
            else
              item.change_display = rootGetters["LookupManager/getterTimelineChangeTypesAsArrayByLc"](printjobInfoObj.lang)
              .filter((el) => el.id === "ID_TIMELINE_CHANGETYPE_STAGECHANGED")[0].display_name;

            item.finished_by = rootGetters["AccountManager/getterUserFullnameFromUsername"](item.finished_by);
            item.finished_at_display = rootGetters["HelperManager/getterFormatDate"](item.finished_at);

            item.stage_display = rootGetters["LookupManager/getterRequestStagesAsArrayByLc"](printjobInfoObj.lang)
              .filter((el) => el.stage === item.stage)[0].display_name;
          });
        }

        // console.debug("actionGenerateRequestReport - printjobInfoObj", printjobInfoObj);

        return await dispatch("PrintManager/actionGeneratePrintDocument", printjobInfoObj, {root: true});
      } catch (err) {
        console.error("RequestManager - actionGenerateRequestReport Error : ", err);
        return false;
      }
    },
    //save or update a new reuest into the couchdb
    async actionSaveRequest(
      {state, getters, rootGetters, dispatch, commit},
      data
    ) {
      try {
        // console.debug("actionSaveRequest", data);
        let result = false;

        if (!data.request_id) {
          // console.debug("actionSaveRequest - Create Mode", data);
          result = await dispatch("createRequestAndStoreInBackend", data);

          // console.debug("actiogetterRequestStageRecordednSaveRequest - save result", result);

          if (result === false) throw "Error creating request";

          commit("REQUEST_SAVED", data);

          return result;
        }

        // console.debug("actionSaveRequest - Update Mode", data);

        result = await dispatch("updateRequestAndStoreInBackend", data);

        // console.debug('actionSaveRequest - Update result', result);

        if (result == false) throw "Error saving Request";

        commit("REQUEST_SAVED", data);

        if (getters.getterRequestIsClosed(data)) {
          // console.debug("actionSaveRequest - Closed Request", data);

          let report = await dispatch("actionGenerateRequestReport", data);

          if (report == false) {
            throw "Report Document Generation Error";
          }

          // set attachment object
          let docobj = {
            name: "Request Report",

            doccategory_id: "ID_DOCUMENTCATEGORY_REQUEST",
            doctype_id: "ID_DOCUMENTTYPE_REQUESTREPORT",

            filename:
              rootGetters["HelperManager/getterCurrentDateTimeCompact"] +
              "_request_report_closed.pdf",
            mimetype: "application/pdf",
            notes: "Auto Generated by GDPR App",

            can_deleted: false,

            reference: {
              refid: data.request_id,
              scope: "request",
              stage: data.stage,
            },
          };

          await dispatch(
            "AttachmentManager/savePrintDocumentAsAttachment",
            {metadata: docobj, printdata: report},
            {root: true}
          );
        }

        return result;
      } catch (err) {
        console.error("actionSaveRequest Error: ", err);
        return false;
      }
    },
    //reassign request
    async actionAssignRequest(
      {state, getters, rootGetters, dispatch, commit},
      assigndataObj
    ) {
      try {
        // console.debug("actionAssignRequest - assigndataObj", assigndataObj);
        var requestObj = await dispatch(
          "actionReceiveCurrentRequestDataById",
          assigndataObj.key_id
        );

        if (requestObj === false) throw "Error reading request by id";

        requestObj.assigned_to = assigndataObj.newassigned_to;
        requestObj.changenotes = assigndataObj.newnotes;
        requestObj.changetype_id = assigndataObj.changetype_id;

        const result = await dispatch("actionSaveRequest", requestObj);

        if (result === false) throw "Error saving request assignto change";

        commit("REQUEST_ASSIGNED", requestObj);

        return result;
      } catch (err) {
        console.error("actionAssignRequest - Error : ", err);
        return false;
      }
    },
  },

  getters: {
    getterRequestStageRecorded: () => {
      return REQUESTSTAGE_LIST["ID_REQUESTSTAGE_RECORDED"].stage;
    },
    getterRequestStageClosed: () => {
      return REQUESTSTAGE_LIST["ID_REQUESTSTAGE_CLOSED"].stage;
    },
    getterRequestStagePrepared: () => {
      return REQUESTSTAGE_LIST["ID_REQUESTSTAGE_PREPARED"].stage;
    },
    getterRequestStageChecked: () => {
      return REQUESTSTAGE_LIST["ID_REQUESTSTAGE_CHECKED"].stage;
    },
    getterRequestStageInformed: () => {
      return REQUESTSTAGE_LIST["ID_REQUESTSTAGE_INFORMED"].stage;
    },
    getterRequestStageEvaluated: () => {
      return REQUESTSTAGE_LIST["ID_REQUESTSTAGE_EVALUATED"].stage;
    },
    getterRequestDefaultPreparationStateId: () => {
      return "ID_REQUESTPREPARATIONSTATE_OTHER";
    },
    getterRequestDefaultValidationMethodId: () => {
      return "ID_REQUESTVALIDATIONMETHOD_OTHER";
    },
    getterRequestDefaultNotificationStateId: () => {
      return "ID_REQUESTNOTIFICATIONSTATE_OTHER";
    },
    getterRequestDefaultCompletionStateId: () => {
      return "ID_REQUESTCOMPLETIONSTATE_OTHER";
    },
    getterRequestDefaultIdentificationStateId: () => {
      return "ID_REQUESTIDENTITYSTATE_OTHER";
    },
    getterRequestDefaultAuthorizationStateId: () => {
      return "ID_REQUESTAUTHORIZATIONSTATE_OTHER";
    },
    getterRequestNotificationStateNotNeededId: () => {
      return "ID_REQUESTNOTIFICATIONSTATE_NOTNEEDED";
    },
    getterCalculateDeadlineDateTime:
      (state, getters, rootState, rootGetters) => (date, time) => {
        return rootGetters["HelperManager/getterCalculateDeadlineDateTime"](
          date,
          time,
          28,
          "days"
        );
      },
      getterCalculateDeadlineDate:
        (state, getters, rootState, rootGetters) => (date, offset) => {
          return rootGetters["HelperManager/getterCalculateDeadlineDate"](
            date,
            offset,
            "days"
          );
        },
    getterEmptyRequestRecord: () => {
      return {...REQUEST_RECORD};
    },
    getterRequestIsCompleted:
      (state, getters, rootState, rootGetters) => (requestDataObj) => {
        if (requestDataObj === undefined) return undefined;

        if (
          requestDataObj.stage === getters.getterRequestStageClosed &&
          requestDataObj.active === false
        )
          return true;

        return false;
      },

    getterRequestToNextStage:
      (state, getters, rootState, rootGetters) => (requestDataObj) => {
        try {
          if (requestDataObj === undefined) return undefined;

          requestDataObj.stagehistory.push({
            ...REQUEST_STAGEHISTORY_RECORD,
            stage: requestDataObj.stage,
            created_at: requestDataObj.created_at,
            created_by: requestDataObj.created_by,
            finished_at: +new Date(),
            finished_by: rootGetters["SessionManager/getterCurrentUsername"],
          });

          // Increase stage by one
          if (requestDataObj.stage < getters.getterRequestStageClosed) {
            requestDataObj.changetype_id =
              "ID_TIMELINE_CHANGETYPE_STAGECHANGED";
            requestDataObj.stage++;
            requestDataObj.active = true;
            requestDataObj.stagehistory[
              requestDataObj.stagehistory.length - 1
            ].changetype_id = requestDataObj.changetype_id;
          } else {
            requestDataObj.stage = getters.getterRequestStageClosed;
            requestDataObj.active = false;
            requestDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_CLOSED";
            requestDataObj.stagehistory[
              requestDataObj.stagehistory.length - 1
            ].changetype_id = requestDataObj.changetype_id;
          }

          return requestDataObj;
        } catch (error) {
          console.error(error);
          requestDataObj = undefined;
        }

        return requestDataObj;
      },
    getterRequestNextStageAllowed: () => (requestDataObj) => {
      var ballowstate = false;

      if (requestDataObj === undefined) return ballowstate;

      if (!Object.prototype.hasOwnProperty.call(requestDataObj, "stage")) return ballowstate;

      switch (requestDataObj.stage) {
        case 1: // ID_REQUESTSTAGE_RECORDED
          ballowstate =
            requestDataObj.core.valid &&
            0 !== requestDataObj.origin.name.length &&
            requestDataObj.origin.valid;
          break;

        case 2: // ID_REQUESTSTAGE_CHECKED
          ballowstate =
          requestDataObj.core.valid &&
            0 !== requestDataObj.origin.name.length && requestDataObj.origin.valid &&
            0 !== requestDataObj.validation.validationmethod_id.length &&
            0 !== requestDataObj.validation.identification_id.length &&
            0 !== requestDataObj.validation.authorization_id.length &&
            0 !== requestDataObj.validation.result.length &&
            0 !== requestDataObj.validation.method.length &&
            requestDataObj.validation.valid;
          break;

        case 3: // ID_REQUESTSTAGE_EVALUATED
          ballowstate =
            requestDataObj.core.valid &&
            requestDataObj.validation.valid &&
            0 !== requestDataObj.evaluation.result.length &&
            requestDataObj.evaluation.valid;
        break;
        case 4: // ID_REQUESTSTAGE_PREPARED
        ballowstate =
          requestDataObj.core.valid &&
          requestDataObj.origin.valid &&
          requestDataObj.validation.valid &&
          requestDataObj.evaluation.valid &&
          0 !== requestDataObj.preparation.prepare_state_id.length &&
          0 !== requestDataObj.preparation.result.length &&
          requestDataObj.preparation.valid;
      break;
        case 5: // ID_REQUESTSTAGE_INFORMED
          ballowstate =
            requestDataObj.core.valid &&
            requestDataObj.origin.valid &&
            requestDataObj.validation.valid &&
            requestDataObj.evaluation.valid &&
            requestDataObj.preparation.valid &&
            requestDataObj.notification.valid;
          break;
        case 6: // ID_REQUESTSTAGE_CLOSED
          ballowstate =
            requestDataObj.core.valid &&
            requestDataObj.origin.valid &&
            requestDataObj.validation.valid &&
            requestDataObj.evaluation.valid &&
            requestDataObj.notification.valid &&
            requestDataObj.preparation.valid &&
            requestDataObj.completion.valid;
          break;
        default:
      }

      return ballowstate;
    },
    getterRequestReportData: (state, getters, rootState, rootGetters) => {
      const printjobInfoObj = {
        file: "request_report.odt",
        convertTo: "pdf",
        lang: rootGetters["TranslationManager/lang"].lc,
      };

      return printjobInfoObj;
    },
    getterRequestIsClosed:
      (state, getters, rootState, rootGetters) => (request) => {
        try {
          if (
            request.stage == getters.getterRequestStageClosed &&
            request.active === false
          )
            return true;
        } catch (error) {
          console.error("getterRequestIsClosed", error);
          return false;
        }
      },
    getterRequestEditAllowed:
      (state, getters, rootState, rootGetters) => (requestDataObj) => {
        try {
          if (
            requestDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(requestDataObj, "stage") ||
            !Object.prototype.hasOwnProperty.call(requestDataObj, "assigned_to")
          )
            throw "Request object is undefined";

          if (
            rootGetters["SessionManager/getterCurrentUsername"] !=
            requestDataObj.assigned_to
          )
            return false;

          return (
            requestDataObj.stage <= getters.getterRequestStageClosed &&
            requestDataObj.active
          );
        } catch (error) {
          console.error("getterRequestEditAllowed - Error : ", error);
          return false;
        }
      },
    getterRequestDeleteAllowed:
      (state, getters, rootState, rootGetters) => (requestDataObj) => {
        try {
          if (
            requestDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(requestDataObj, "stage") ||
            !Object.prototype.hasOwnProperty.call(requestDataObj, "assigned_to")
          )
            throw "Request object is undefined";

          if (
            rootGetters["SessionManager/getterCurrentUsername"] !=
            requestDataObj.assigned_to
          )
            return false;

          return requestDataObj.stage == getters.getterRequestStageRecorded;
        } catch (error) {
          console.error("getterRequestDeleteAllowed - Error : ", error);
          return false;
        }
      },
  },
};
