export const DEFAULT_TENANT = `${process.env.VUE_APP_DEFAULT_TENANT}` || "";
export const DEFAULT_USERNAME = `${process.env.VUE_APP_DEFAULT_USERNAME}` || "";
export const DEFAULT_PASSWORD = `${process.env.VUE_APP_DEFAULT_PASSWORD}` || "";

export const UPLOAD_FILESIZE_LIMIT = parseInt(`${process.env.VUE_APP_UPLOAD_LIMIT}`);

export const TEMPLATE_FOLDER = `${process.env.VUE_APP_TEMPLATE_FOLDER}`;

export const PRINTAPI_URL = `${process.env.VUE_APP_PRINTAPI_URL}`;
export const PRINTAPI_TOKEN = `${process.env.VUE_APP_PRINTAPI_TOKEN}`;

export const BACKENDAPI_HOST = `${process.env.VUE_APP_BACKENDAPI_HOST}` || "/";
export const BACKENDAPI_PATH = `${process.env.VUE_APP_BACKENDAPI_PATH}` || "";
export const BACKENDAPI_APPID = `${process.env.VUE_APP_BACKENDAPI_APPID}` || "buzbgdprapp";
export const BACKENDAPI_STATE_CHECKINTERVALL = `${process.env.VUE_APP_BACKENDAPI_STATE_CHECKINTERVALL}` || 120;

export const LICENSEAPI_HOST = `${process.env.VUE_APP_LICENSEAPI_HOST}` || "/";
export const LICENSEAPI_PATH = `${process.env.VUE_APP_LICENSEAPI_PATH}` || "";
export const LICENSEAPI_APPID = `${process.env.VUE_APP_LICENSEAPI_APPID}` || "buzbgdprapp";

export const LICENSE_GRACEPERIOD = `${process.env.VUE_APP_LICENSE_GRACEPERIOD}` || 30;

export const PRINTAPI_HOST = `${process.env.VUE_APP_PRINTAPI_HOST}` || "/";
export const PRINTAPI_PATH = `${process.env.VUE_APP_PRINTAPI_PATH}` || "";
export const PRINTAPI_APPID = `${process.env.VUE_APP_PRINTAPI_APPID}` || "buzbgdprapp";

export const CONNECTIONAPI_HOST = `${process.env.VUE_APP_CONNECTIONAPI_HOST}` || "/";
export const CONNECTIONAPI_PATH = `${process.env.VUE_APP_CONNECTIONAPI_PATH}` || "";
export const CONNECTIONAPI_APPID = `${process.env.VUE_APP_CONNECTIONAPI_APPID}` || "buzbgdprapp";

export const BANNER_ACTIVE = `${process.env.VUE_APP_BANNER_ACTIVE}`;
export const BANNER_TEXT = `${process.env.VUE_APP_BANNER_TEXT}`;
export const BANNER_TEXTCOLOR = `${process.env.VUE_APP_BANNER_TEXTCOLOR}`;
export const BANNER_COLOR = `${process.env.VUE_APP_BANNER_COLOR}`;

export const DATAPROCESSOR_CONTRACT_ID = 'ID_DOC_BUZB_CONTRACT';
export const DATAPROCESSOR_CONTRACT_FILENAME = 'BuzB_Dataproccessor_Contract.pdf';

export const APP_SHOP_ORDER_URL = `${process.env.VUE_APP_SHOP_ORDER_URL}`;
export const APP_SHOP_RENEW_URL = `${process.env.VUE_APP_SHOP_RENEW_URL}`;

export const APP_TERMS_URL = `${process.env.VUE_APP_TERMS_URL}`;
export const APP_PRIVACYPOLICY_URL = `${process.env.VUE_APP_PRIVACYPOLICY_URL}`;

export const APP_SESSION_DATA_ENCRYPT = () => { return (process.env.VUE_APP_SESSION_DATA_ENCRYPT == "1" || process.env.VUE_APP_SESSION_DATA_ENCRYPT == "true"); };
export const APP_SESSION_DATA_ENCRYPT_KEY = `${process.env.VUE_APP_SESSION_DATA_ENCRYPT_KEY}`;

export const PREVIEW_MIMES_TYPES = [
  'text/plain',
  'application/pdf',
  'image/jpg',
  'image/jpeg',
  'image/png'
];

export const CONVERTER_INPUT_OUTPUT_MAP = {
  "odt": ["odt", "docx", "pdf", "jpg", "png"],
  "ods": ["ods", "xlsx", "pdf", "csv"],
  "xlsx": ["ods", "xlsx", "pdf", "csv"],
  "docx": ["odt", "docx", "pdf", "jpg", "png"]
};

export const CONVERTER_MIME_TYPES_FILEEXT_MAP = [{
  fileext: "pdf",
  mimetype: "application/pdf"
},
{
  fileext: "doc",
  mimetype: "application/msword"
},
{
  fileext: "docx",
  mimetype: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
},
{
  fileext: "xls",
  mimetype: "application/ms-excel"
},
{
  fileext: "xlsx",
  mimetype: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
},
{
  fileext: "ods",
  mimetype: "application/vnd.oasis.opendocument.spreadsheet"
},
{
  fileext: "odt",
  mimetype: "application/vnd.oasis.opendocument.text"
},
{
  fileext: "jpg",
  mimetype: "image/jpg"
},
{
  fileext: "png",
  mimetype: "image/png"
},
{
  fileext: "csv",
  mimetype: "text/csv"
},
{
  fileext: "txt",
  mimetype: "text/plain"
},
{
  fileext: "json",
  mimetype: "application/json"
}
];

/* eslint-disable-next-line */
export const PHONE_NUMBER_REGEX_STR = '^(?:\\+\\d{1,3}|0\\d{1,3}|00\\d{1,2})?(?:\\s?\\(\\d+\\))?(?:[-\\/\\s.]|\\d)+$';
/* eslint-disable-next-line */
export const EMAIL_REGEX_STR = '^(([^<>()\\[\\]\\.,;:\\s@"]+(\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
/* eslint-disable-next-line */
export const URL_REGEX_STR = '/[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi';
/* eslint-disable-next-line */
export const USERNAME_REGEX_STR = '^([a-z]{1})+([a-z0-9]){5,14}$';
/* eslint-disable-next-line */
export const TENANT_REGEX_STR = '^([a-z0-9]){6,14}$';
/* eslint-disable-next-line */
export const PASSWORD_REGEX_STR = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\\W).{8,25}$';

export const ADDRESS_FIELDS = [
  'street',
  'zipcode',
  'city',
  'country',
  'federal_state'
];

export const CONTACT_FIELDS = [
  'fax',
  'mobile',
  'email_address',
  'phone'
];

export const CUSTOM_ID_PREFIX = 'CD';

export const DEFAULT_ENTITY_LOGO = '@/assets/defaultentitylogo.png';
export const DEFAULT_PROFILE_LOGO = "@/assets/defaultprofile.png";
export const DEFAULT_PROCESS_MODEL = "assets/default_process.bpmn";

export const ALLOWED_ATTACHMENT_EXT = ".docx,.doc,.xlsx,.xls,.odt,.ods,.pptx,.ppt,.odp,.pdf,.txt,.jpg,.png,.bpmn,.tif,.gif";

// Waiting page
export const WAITPAGE_CONTENT = (message) => {
  return '<!DOCTYPE html> <html> <head> 	<title>Preview</title> </head> <body style="margin:0px;padding:0px;overflow:hidden">   <div id="preview" class="container">      <div class="item">       <h1>' +
    message +
    '</h1>    <div class="shapes">      </row>      </div>   </div> </body> </html>  <style> body{   margin: 0; }  h1 {   position: relative;    left: -30px;   padding-bottom: 16px;   color: black;   text-align: end; } .container{    background: rgba(200,200,200,1); 	 width: 100%; 	 display: flex; 	 height: 100vh; 	 justify-content: center; }  .item{ 	align-self: center; }  .shapes {    width: 105.60000000000001px;    height: 105.60000000000001px;    color: #404040;    background: linear-gradient(currentColor 0 0) left   35.2px top    0,           linear-gradient(currentColor 0 0) top    35.2px right  0,           linear-gradient(currentColor 0 0) right  35.2px bottom 0,           linear-gradient(currentColor 0 0) bottom 35.2px left   0;    background-size: calc(100%/3) calc(100%/3);    background-repeat: no-repeat;    animation: shapes-dqf486 0.6000000000000001s infinite alternate linear,           shapes-g0d60l 1.2000000000000002s infinite; }  @keyframes shapes-dqf486 {    90%, 100% {       background-size: calc(2*100%/3) calc(100%/3),calc(100%/3) calc(2*100%/3);    } }  @keyframes shapes-g0d60l {    0%, 49.99% {       transform: scaleX(1);    }     50%, 100% {       transform: scaleX(-1);    } } </style>';
};

export const PREVIEWPAGE_CONTENT = (urlToShow) => {
  return '<iframe src="' + urlToShow +
    '" frameborder="0" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px" height="100%" width="100%"' +
    '</iframe>';
};

export const PREVIEWPAGE_CONTENTDATA = (mimetype, uriDataToShow) => {
  return '<iframe src="data:' + mimetype + ', ' + uriDataToShow +
    '" frameborder="0" style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px" height="100%" width="100%"' +
    '</iframe>';
};

export const APP_CONFIG_RECORD = {
  is_first_run: true,
  is_initial_data_complete: false,
  is_dark_theme: true,
  is_reference: false,

  settings: {
    hasRequestorHint: true,
  },

  Runtime: {
    is_ignore_license_grace_period: false,

    auto_init_with_datamaps: true,

    is_backend_online: false,
    is_loading: false,

    alert_message: {
      message: '',
      active: false,
      type: 'success'
    },

    message_ok_dialog: {
      message: '',
      active: false,
      title: ''
    },

    message_confirm_dialog: {
      title: '',
      message: '',
      active: false,
      label_yes: '',
      label_no: '',
      confirmOperation: () => {},
      cancelOperation: () => {}
    },

    progress_dialog: {
      message: '',
      active: false,
      title: ''
    },

    active_component: "",

    contentComponent: {
      name: false,
      payload: {}
    },

    breadcrumbs: [
      {
        component: "Dashboard",
        selection: '',
        position: '',
      }
    ],
  },

  SupportData: {
    Contact: {},
    TechnicalContact: {},
    valid: false
  },

  SupportLogo: {
    imagaData: false
  }
};

export const EMPTY_ASSIGNTODATA = {
  display_title: "",

  key_id: "",
  changetype_id: 'ID_TIMELINE_CHANGETYPE_USERASSIGNED',

  assigned_to: "",

  newnotes: "",
  newassigned_to: "",

  valid: false
};

export const NO_FILTER_CODE = 99;

export const EMPTY_OVERVIEWFILTERDATA = {
  area: NO_FILTER_CODE,
  status: NO_FILTER_CODE,
  assignedto: NO_FILTER_CODE,
  custom: NO_FILTER_CODE
};

export const EMPTY_PAYLOADDATA = {
  targetId: false,
  filter: {
    ...EMPTY_OVERVIEWFILTERDATA
  }
};

export const INITIAL_DOCUMENTATION_START_DATE = "2018-05-28";
export const INITIAL_DOCUMENTATION_START_YEAR = "2018";
export const DEFAULT_FOUNDATION_DATE = "2018-05-01";

export const DEFAULT_UI_FONTSIZE = 14;
export const DEFAULT_UI_CARDTITLE = DEFAULT_UI_FONTSIZE*4+2;
export const DEFAULT_UI_TABSIZE = DEFAULT_UI_FONTSIZE*4+1;
