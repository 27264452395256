export default {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        async actionExportListDataToCsvAsBlobUrl({ state, getters, rootGetters, dispatch, commit }, payload) {

            try {
                // console.debug("actionExportListDataToCsvAsBlobUrl - payload", payload);

                var row = ''
                var c = ''
                var type = ''

                // Header line
                payload.header.map(function (m, i) {
                    // console.debug("payload.header.map - m,i", m,i);
                    row += '"' + m.text + '"' + payload.separator
                });

                row = row.slice(0, -1)
                c += row + '\r\n'

                // content rows
                payload.data.map(function (m, i) {
                    // console.debug("payload.data.map - m,i", m,i);

                    row = '';

                    payload.header.map(function (k, j) {
                        type = typeof m[k.value];

                        if (type === 'number' || type === 'float') {
                          row += m[k.value] + payload.separator;
                        } else {
                          row += '"' + m[k.value] + '"' + payload.separator;
                        }

                        // console.debug("payload.header.map - m,i", m,i);
                    });

                    row = row.slice(0, -1)
                    c += row + '\r\n'
                });

                // console.debug("content - c", c);

                // create download url
                const bloburl = window.URL.createObjectURL(new Blob([c], {type: 'text/csv'}));

                if(bloburl === false)
                    throw "Error reading attachment blob";

                return bloburl;
            } catch (err) {
                console.error("actionExportListDataToCsvAsBlobUrl", err);
                return false;
            }
        },
    },
};