export const DATAPROCESSORROLE_I18N_KEY = "dataprocessor_roles.";

export const DATAPROCESSORROLE_LIST = {
	"ID_DATAPROCESSORROLE_CONTROLLER": {
		"name": "DATAPROCESSORROLE_CONTROLLER_LABEL",
		"description": "DATAPROCESSORROLE_CONTROLLER_DESCRIPTION"
	},
	"ID_DATAPROCESSORROLE_JOINTCONTROLLER": {
		"name": "DATAPROCESSORROLE_JOINTCONTROLLER_LABEL",
		"description": "DATAPROCESSORROLE_JOINTCONTROLLER_DESCRIPTION"
	},
	"ID_DATAPROCESSORROLE_DATAPROCESSOR": {
		"name": "DATAPROCESSORROLE_DATAPROCESSOR_LABEL",
		"description": "DATAPROCESSORROLE_DATAPROCESSOR_DESCRIPTION"
	}
};
