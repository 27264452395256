import backendDataApi from '@/services/backendDataApi.service.js';

import {PrintService} from '@/services/print.service';

import {
  TEMPLATE_FOLDER,
  CONVERTER_MIME_TYPES_FILEEXT_MAP
} from "@/config/config.constants.js";

export const PRINTJOBINFO = {
  convertTo: '',
  file: '',
  data: {
    demoMode: false
  },
  lang: '',
  country: ''
};

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    //find a printreport by id in the couchdb and return it
    async readPrintTemplateByIdFromBackend({
      state
    }, printtemplate_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({
        designdoc: 'reports/list',
        keyid: printtemplate_id
      });
    },
    // get the attachment blob 
    async actionPrintTemplateBlob({
      state
    }, doc) {
      try {
        // console.debug("actionReadAttachmentBlobAsURL", doc);
        // getting the first attachment in the list
        return await backendDataApi.readAttachmentBlobByDocFromBackend(doc);
      } catch (error) {
        console.error("actionReadAttachmentBlob", error);
        return false;
      }
    },
    // Report Generation Actions
    async actionGeneratePrintDocument({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, printObj) {
      try {
        // console.debug('actionGeneratePrintDocument Started');

        if (rootGetters['LicenseManager/getterCheckLicensedModulePrint']() !== true)
          throw "Print Module not part of your current license.";

        // console.debug('actionGeneratePrintDocument Data', printObj);

        const justBase64 = await dispatch('actionGetReportTemplateFromPublicFolder', {
          langcode: printObj.country,
          subfolder: printObj.data.gdpr.settings.subfolder,
          filename: printObj.file
        });

        if (justBase64 == false)
          throw 'Requested report document not found or readable.';

        //data.print contains the documents data set for prefilling
        //data to send
        const printData = {
          "options": {
            "convertTo": printObj.convertTo,
          },
          "data": {
            ...printObj.data
          },
          "templateData": justBase64,
          "srcfmt": printObj.file.split('.').pop()
        };

        const respNode = await PrintService.convertDocument(printData);

        // console.debug('actionGeneratePrintDocument - Carbone Response', respNode);

        if (respNode.success !== true)
          throw respNode.message;

        const mimetype = getters.getterMimeTypeFromFileExt(printObj.convertTo);

        if (mimetype == false)
          throw "Unknown Mimetype as report output format";

        return {
          format: printObj.convertTo,
          lang: printObj.lang,
          printout: respNode.data,
          sourcedata: printObj,
          mimetype: mimetype + ';base64'
        };
      } catch (err) {
        console.error("actionGeneratePrintDocument - Error:", err);
        return false;
      }
    },
    // Read report file from public template folder 
    async actionGetReportTemplateFromPublicFolder({
      state,
      rootGetters,
      dispatch,
      commit
    }, docref) {
      try {
        // console.debug('actionGetReportTemplateFromPublicFolder Started');

        // console.debug('actionGetReportTemplateFromPublicFolder Data', docref);

        //find the template in the public folder and convert it to blob
        //this part can be changed to use custom templates in a later state
        var templatefullfilename = TEMPLATE_FOLDER + '/';

        if(Object.prototype.hasOwnProperty.call(docref, "subfolder") == true)
        {
          if( docref.subfolder !== undefined && 0 != docref.subfolder.length)
            templatefullfilename = templatefullfilename + docref.subfolder + '/';
        }

        templatefullfilename = templatefullfilename + '/' + docref.langcode.toLowerCase() + '/' + docref.filename;

        const response = await fetch(templatefullfilename, {
          method: 'GET',
          cache: 'no-cache',
        });

        if (response.status != 200)
          throw 'Requested document not found.';

        // console.debug('actionGetReportTemplateFromPublicFolder AXIOS Response:', resp);

        const respBlob = await response.blob();

        if (respBlob.size == 0 || respBlob.type.toLowerCase().indexOf("text/html") !== -1)
          throw 'Requested document blob not found.';

        // console.debug('actionGetReportTemplateFromPublicFolder - respBlob', respBlob);

        // from blob to binary
        const base64Template = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(respBlob);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });

        //remove the mimetype excess information in the base64 string
        return base64Template.split(",").pop();
      } catch (err) {
        console.error("actionGetReportTemplateFromPublicFolder - Error: ", err);
        return false;
      }
    },
    // Read report file from public template folder 
    async actionGetReportTemplateFromDatabase({
      state,
      rootGetters,
      dispatch,
      commit
    }, docref) {
      try {
        // console.debug('actionGetReportTemplateFromDatabase Started');

        // console.debug('actionGetReportTemplateFromDatabase - docref', docref);

        const result = await dispatch('readPrintTemplateByIdFromBackend', docref.keyid);

        if (result == false || result.length !== 1)
          throw "Unknown Report template in database";

        // console.debug('actionGetReportTemplateFromDatabase - result', result);

        const blob = await dispatch('actionPrintTemplateBlob', result[0].value);

        // console.debug('actionGetReportTemplateFromDatabase - blob', blob);

        //remove the mimetype excess information in the base64 string
        return blob.split(",").pop();
      } catch (err) {
        console.error("actionGetReportTemplateFromDatabase - Error: ", err);
        return false;
      }
    },
    async actionPreparePrintJobInfoData({
      state,
      getters,
      rootGetters,
      dispatch,
      commit}, payload) {

      try {
        var printjobInfoObj = {
          ...getters.getterEmptyPrintJobInfo,
          file: payload.templateFile,
          convertTo: payload.convertToFormat,
          data: {
            entity: {...rootGetters["EntityManager/getterEntityData"]},
            gdpr: await dispatch("GdprManager/actionReceiveCurrentGdprPrintInfo", false, {root: true}),
            demoMode: rootGetters["LicenseManager/getterCheckIsTestingLicense"]
          },
          lang: rootGetters["GdprManager/getterGdprData"].lawbasis.countrycode.toLowerCase(),
          country: rootGetters["GdprManager/getterGdprData"].lawbasis.countrycode.toLowerCase(),
        };

        if (printjobInfoObj.country == "at" || printjobInfoObj.country == "ch")
          printjobInfoObj.lang = "de";        

        if (rootGetters["TranslationManager/isSupportedAlpha2Code"](printjobInfoObj.lang) == false) {
          printjobInfoObj.lang = rootGetters["TranslationManager/getterDefaultLanguageAlpha2Code"];
          printjobInfoObj.country = rootGetters["TranslationManager/getterDefaultLanguageAlpha2Code"];
        }

        return printjobInfoObj;
      } catch (err) {
        throw `actionPreparePrintJobInfoData - Error: ${err}`;
      }
    }
  },
  getters: {
    getterEmptyPrintJobInfo: (state, getters, rootState, rootGetters) => {
      return {...PRINTJOBINFO};
    },
    getterMimeTypeFromFileExt: (state, getters, rootState, rootGetters) => (fileext) => {
      try {
        return CONVERTER_MIME_TYPES_FILEEXT_MAP.filter(item => item.fileext === fileext)[0].mimetype;
      } catch (err) {
        console.error("getterMimeTypeFromFileExt - Error", err);
        return false;
      }
    }
  }
};