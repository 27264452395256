export const ENTITYCONTACT_ROLE_I18N_KEY = "entitycontact_roles.";

export const ENTITYCONTACT_ROLE_LIST = {
	"ID_ENTITYCONTACT_ROLE_RESPONSIBLE": {
		"name": "ENTITYCONTACT_ROLE_RESPONSIBLE_LABEL",
		"description": "ENTITYCONTACT_ROLE_RESPONSIBLE_DESCRIPTION",
		"selectable": false
	},
	"ID_ENTITYCONTACT_ROLE_GDPROFFICER": {
		"name": "ENTITYCONTACT_ROLE_GDPROFFICER_LABEL",
		"description": "ENTITYCONTACT_ROLE_GDPROFFICER_DESCRIPTION",
		"selectable": false	
	},	
	"ID_ENTITYCONTACT_ROLE_AREARESPONSIBLE": {
		"name": "ENTITYCONTACT_ROLE_AREARESPONSIBLE_LABEL",
		"description": "ENTITYCONTACT_ROLE_AREARESPONSIBLE_DESCRIPTION",
		"selectable": false	
    },	 
    "ID_ENTITYCONTACT_ROLE_MEMBER": {
		"name": "ENTITYCONTACT_ROLE_MEMBER_LABEL",
		"description": "ENTITYCONTACT_ROLE_MEMBER_DESCRIPTION",
		"selectable": true	
    },
    "ID_ENTITYCONTACT_ROLE_EMPLOYEE": {
		"name": "ENTITYCONTACT_ROLE_EMPLOYEE_LABEL",
		"description": "ENTITYCONTACT_ROLE_EMPLOYEE_DESCRIPTION",
		"selectable": true	
    },
    "ID_ENTITYCONTACT_ROLE_SUBSTITUTE": {
		"name": "ENTITYCONTACT_ROLE_SUBSTITUTE_LABEL",
		"description": "ENTITYCONTACT_ROLE_SUBSTITUTE_DESCRIPTION",
		"selectable": true	
	},
    "ID_ENTITYCONTACT_ROLE_PROCESSRESPONSIBLE": {
		"name": "ENTITYCONTACT_ROLE_PROCESSRESPONSIBLE_LABEL",
		"description": "ENTITYCONTACT_ROLE_PROCESSRESPONSIBLE_DESCRIPTION",
		"selectable": false	
	},
    "ID_ENTITYCONTACT_ROLE_ASSETRESPONSIBLE": {
		"name": "ENTITYCONTACT_ROLE_ASSETRESPONSIBLE_LABEL",
		"description": "ENTITYCONTACT_ROLE_ASSETRESPONSIBLE_DESCRIPTION",
		"selectable": false	
	},
    "ID_ENTITYCONTACT_ROLE_RISKRESPONSIBLE": {
		"name": "ENTITYCONTACT_ROLE_RISKRESPONSIBLE_LABEL",
		"description": "ENTITYCONTACT_ROLE_RISKRESPONSIBLE_DESCRIPTION",
		"selectable": false	
	},
    "ID_ENTITYCONTACT_ROLE_LOCATIONRESPONSIBLE": {
		"name": "ENTITYCONTACT_ROLE_LOCATIONRESPONSIBLE_LABEL",
		"description": "ENTITYCONTACT_ROLE_LOCATIONRESPONSIBLE_DESCRIPTION"	,
		"selectable": false
	}		 
};