var CommonUtils = (function() {
    'use strict';

    return {
        extractNumberFromKeyId: function(keyid) {
            return (keyid.lastIndexOf("_") > 0) ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;
        },
        getIsOther: function(idvalue) {
            if (!this.isStringEmpty(idvalue))
                return (idvalue.substr(idvalue.length - 5, idvalue.length) === 'OTHER');
            else
                return false;
        },
        removeDuplicatesFromArray: function(array) {
            return [...new Set(array)];
        },
        truncateString: function(str, n) {
            return (str.length > n) ? str.substr(0, n - 1) + ' ...' : str;
        },
        isStringEmpty: function(str) {
            return (!str || 0 === str.length);
        },
        isObjectEmpty: function(obj) {
            if (obj === undefined || obj === false)
                return true;
            else
                return (Object.keys(obj).length === 0 && obj.constructor === Object);
        },
        isArrayEmpty: function(obj) {
            try {
                if (obj === undefined || obj === false || obj.constructor !== Array)
                    return true;
            else
                return (obj.length < 1);
            } catch (error) {
                return true;
            }
        },
        hasArrayElement(ary, el) {
            if(this.isArrayEmpty(ary) != false)
                return false;

            return ary.includes(el);
        },
        getFileBlobFromBase64String: function(srcBase64, mimetype) {
            // decode base64 string, remove space for IE compatibility
            const binary = atob(srcBase64.replace(/\s/g, ''));

            var view = new Uint8Array(new ArrayBuffer(binary.length));

            for (var i = 0; i < binary.length; i++) {
              view[i] = binary.charCodeAt(i);
            }

            // create the blob object with content-type mimetype
            return new Blob( [view], { type: mimetype});
        },
        getArrayOfObjectDynamicSort: function(property) {
            var sortOrder = 1;

            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }

            return function (a,b) {
                /* next line works with strings and numbers,
                * and you may want to customize it to your needs
                */
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            };
        },
        getTrafficLightColorOfFive: function(status) {
            switch (status) {
                case 1:
                return '#00AA00'; //green

                case 2:
                return '#99c140';

                case 3:
                return '#e7b416';  // yellow

                case 4:
                return '#db7b2b';

                case 5:
                return '#DD0000';  // red

                default:
                return 'grey lighten-1';
            }
        },
        getTrafficLightColorOfThree: function(status) {
            switch (status) {
                case 3:
                return '#00AA00'; //green

                case 2:
                return '#e7b416';  // yellow

                case 1:
                return '#DD0000';  // red

                default:
                return 'grey lighten-1';
            }
        },
        getStatusColor: function(status) {
            switch (status) {
                case 'fail':
                return 'red darken-2';

                case 'warn':
                return 'blue darken-2';

                case 'ok':
                return 'green darken-2';

                default:
                return 'grey darken-1';
            }
        },
        stringToBinaryArray: function(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        },
        translateI18nDataObjectByLc: function(dataObj, lang, idname) {
            try {
              if(CommonUtils.isObjectEmpty(dataObj))
                throw "Empty data object no allowed";

              if (Object.prototype.hasOwnProperty.call(dataObj, "i18n") == false)
                throw "Missing i18n data";

              if(Object.prototype.hasOwnProperty.call(dataObj.i18n.translations, lang) == false)
                lang = dataObj.i18n.translations.defaultlang;

              dataObj.display_translation.lang = lang;
              dataObj.display_translation.name = dataObj.i18n.translations[lang].name
              dataObj.display_translation.description = dataObj.i18n.translations[lang].description;

              if(Object.prototype.hasOwnProperty.call(dataObj.i18n.translations[lang], 'printtext') == true)
                dataObj.display_translation.printtext = dataObj.i18n.translations[lang].printtext;

              dataObj.display_name = dataObj.display_translation.name;
              dataObj.display_description = dataObj.display_translation.description;

              if(!CommonUtils.isStringEmpty(idname)) {
                dataObj.id = dataObj[idname];
              }

              return dataObj;
            } catch (error) {
              console.error("getTranslateI18nDataObjectByLc - Error :", error);
              return false;
            }
        },
    };
})();

export default CommonUtils;