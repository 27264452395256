import merge from "lodash.merge";

import {UserProfileService} from '@/services/profile.service';

import {APP_DEFAULT_GROUP} from '@/config/config.priveleges.js';

// Auth user data record
const AUTHDATA_RECORD = {
  account_id: "",
  username: "",
  tenant: ""
};

// Auth user profile record
const AUTHPROFILE_RECORD = {
  displayname: "",
  fullname: "",
  email: "",
  language: "de",
  timezone: "Europe/Berlin"
};

const BUZB_UISETTING_RECORD = {
  theme: "dark",
  language: "de",
  timezone: "Europe/Berlin"
};

const BUZB_USERSETTING_RECORD = {
  ui: {
    ...BUZB_UISETTING_RECORD
  },

  locked: false,
  protected: false,
  active: true,

  notifyemail: "",
  notifybyemail: false,

  group: APP_DEFAULT_GROUP,
  priveleges: [],
  modules: [],
  note: ""
};

const BUZBAPPCONFIG_RECORD = {
  dbinstance: "",
  usersettings: {
    ...BUZB_USERSETTING_RECORD
  }
};

const USERPROFILE_RECORD = {
  authdata: {
    ...AUTHDATA_RECORD
  },
  authprofile: {
    ...AUTHPROFILE_RECORD
  },
  appconfig: {
    ...BUZBAPPCONFIG_RECORD,
  }
};

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    async actionGetCurrentUserProfile({
      state,
      getters,
      rootGetters,
      commit,
      dispatch})
    {
      try
      {
        const response = await UserProfileService.getCurrentProfile();

        // console.debug("actionGetCurrentUserProfile - response", response);

        if(response.data.success == false ||
          Object.prototype.hasOwnProperty.call(response.data.data, "authdata") == false ||
          Object.prototype.hasOwnProperty.call(response.data.data.authdata, "account_id") == false ||
          Object.prototype.hasOwnProperty.call(response.data.data.authdata, "username") == false ||
          Object.prototype.hasOwnProperty.call(response.data.data.authdata, "tenant") == false ||
          Object.prototype.hasOwnProperty.call(response.data.data, "authprofile") == false ||
          Object.prototype.hasOwnProperty.call(response.data.data, "appconfig") == false ||
          Object.prototype.hasOwnProperty.call(response.data.data.appconfig, "dbinstance") == false)
          throw "No valid account profile in backend data store found";

        let userProfile = {
          authdata: {
            ...USERPROFILE_RECORD.authdata,
            ...response.data.data.authdata
          },
          authprofile: {
            ...USERPROFILE_RECORD.authprofile,
            ...response.data.data.authprofile
          },
          appconfig: {
            ...merge({}, USERPROFILE_RECORD.appconfig, response.data.data.appconfig)
          }
        };

        if(userProfile.appconfig.usersettings.protected && userProfile.appconfig.usersettings.modules.length <= 0)
        {
          userProfile.appconfig.usersettings.modules = [...rootGetters["LicenseManager/getterLicensedModulesAsArray"]];
        }

        // console.debug("ProfileManager - actionGetCurrentUserProfile : userProfile", userProfile.appconfig);

        return userProfile;
      }
      catch(err)
      {
        console.error('ProfileManager - actionGetCurrentUserProfile Error : ', err);
        return false;
      }
    },
    async actionUpdateCurrentUserProfile({
      state,
      getters,
      rootGetters,
      commit,
      dispatch}, profileData)
    {
      try
      {
        // console.debug("actionUpdateCurrentUserProfile - profileData", profileData);

        var userProfile = {
          authdata: {
            ...USERPROFILE_RECORD.authdata,
            ...profileData.authdata
          },
          authprofile: {
            ...USERPROFILE_RECORD.authprofile,
            ...profileData.authprofile
          },
          appconfig: {
            ...merge({}, USERPROFILE_RECORD.appconfig, profileData.appconfig)
          }
        };

        // console.debug("actionUpdateCurrentUserProfile - userProfile", userProfile);

        delete userProfile.valid;

        // console.debug("actionUpdateCurrentUserProfile - userProfile", userProfile);

        const response = await UserProfileService.updateCurrentProfile(userProfile);

        // console.debug("actionUpdateCurrentUserProfile - response", response.data);

        if(response.data.success == false)
          throw `Error saving cureent user profile in backend data store : ${response.data.message}.`;

        return true;
      }
      catch(err)
      {
        console.error('ProfileManager - actionUpdateCurrentUserProfile Error : ', err);
        return false;
      }
    },
    async actionChangeCurrentUserPassword({
      state,
      getters,
      rootGetters,
      commit,
      dispatch}, password)
    {
      try
      {
        // console.debug("actionChangeCurrentUserPassword - userProfile", password);

        const response = await UserProfileService.changeCurrentPassword(password);

        // console.debug("actionChangeCurrentUserPassword - response", response.data);

        if(response.data.success == false)
          throw `Error saving cureent user profile in backend data store : ${response.data.message}.`;

        return true;
      }
      catch(err)
      {
        console.error('ProfileManager - actionChangeCurrentUserPassword Error : ', err);
        return false;
      }
    }
  },
  getters: {
    getterEmptyUserProfileRecord: () => {
      return {...USERPROFILE_RECORD};
    }
  }
};