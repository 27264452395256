export const RISKBASEVALUE_I18N_KEY = "risk_basevalues.";

export const RISKBASEVALUE_LIST = {

  "ID_RISKBASEVALUE_CONFIDENTIALITY": {
    "name": "RISKBASEVALUE_CONFIDENTIALITY_LABEL", 
    "description": "RISKBASEVALUE_CONFIDENTIALITY_DESCRIPTION" 
  }, 
  "ID_RISKBASEVALUE_AVAILIBILITY": {
    "name": "RISKBASEVALUE_AVAILIBILITY_LABEL", 
    "description": "RISKBASEVALUE_AVAILIBILITY_DESCRIPTION" 
  },
  "ID_RISKBASEVALUE_INTEGRITY": {
    "name": "RISKBASEVALUE_INTEGRITY_LABEL", 
    "description": "RISKBASEVALUE_INTEGRITY_DESCRIPTION" 
  },
  "ID_RISKBASEVALUE_TRANSPARENCY": {
    "name": "RISKBASEVALUE_TRANSPARENCY_LABEL", 
    "description": "RISKBASEVALUE_TRANSPARENCY_DESCRIPTION" 
  },
  "ID_RISKBASEVALUE_INTERVENABILITY": {
    "name": "RISKBASEVALUE_INTERVENABILITY_LABEL", 
    "description": "RISKBASEVALUE_INTERVENABILITY_DESCRIPTION" 
  }, 
  "ID_RISKBASEVALUE_DATAMINIMALISATION": {
    "name": "RISKBASEVALUE_DATAMINIMALISATION_LABEL", 
    "description": "RISKBASEVALUE_DATAMINIMALISATION_DESCRIPTION" 
  },  
  "ID_RISKBASEVALUE_NONCHAINEDPROCESSING": {
    "name": "RISKBASEVALUE_NONCHAINEDPROCESSING_LABEL", 
    "description": "RISKBASEVALUE_NONCHAINEDPROCESSING_DESCRIPTION" 
  }      
};
  
  