export const UITHEME_I18N_KEY = "ui_themes.";

export const UITHEME_LIST = {
    "ID_UITHEME_DARK": {
      "name": "UITHEME_DARK_LABEL",
      "description": "UITHEME_DARK_DESCRIPTION",
      "mode": "dark"
    },
    "ID_UITHEME_LIGHT": {
      "name": "UITHEME_LIGHT_LABEL",
      "description": "UITHEME_LIGHT_DESCRIPTION",
      "mode": "light"
    }
};