//
// DataSubject
//
export const DATASUBJECT_RECORD_TYPE = "lookup";
export const DATASUBJECT_RECORD_SUBTYPE = "datasubject";

export const DATASUBJECT_I18N_DEFAULTLANGUAGE = "de";

export const DATASUBJECT_I18N_DISPLAY = {
  display_translation: {
    lang: "",
    name: "",
    description: ""
  }
};

export const DATASUBJECT_I18N_DATA = {
  defaultlang: DATASUBJECT_I18N_DEFAULTLANGUAGE,
  translations: {}
};

export const DATASUBJECT_RECORD = {
  datasubject_id: "",

  standard: false,

  aspect_ids: [],

  areas: [],

  options: {},

  valid: false,
  created_at: false,
  created_by: "",

  i18n: {
    ...DATASUBJECT_I18N_DATA
  }
};

