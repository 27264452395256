import backendDataApi from '@/services/backendDataApi.service.js';

import CommonUtils from '@/utils/common.utils.js';
import DatetimeUtils from '@/utils/datetime.utils';

import CryptoJS from 'crypto-js';

import {
  ENTITYAREA_DOCUMENT_NAME,
  AREA_RECORD,
  AREA_RECORD_TYPE,

} from '@/models/entity_areas.couchdb';


import {
  ENTITYPATTERN_AREA_MAP_DOCUMENT_NAME
} from '@/models/entity_pattern_area_map.couchdb';

export default {
  namespaced: true,
  state: {
    AreaList: {},

    ENTITYAREA_I18N_KEY: "",

    ENTITYAREA_I18N: {},
    ENTITYAREA_LIST: {},

    ENTITY_PATTERN_AREA_MAP: {},

    // Nationalized data with generic in default object
    ENTITY_PATTERN_AREA_INIT_MAP: {},
  },
  mutations: {
    AREA_SAVED(state, payload) { },
    AREA_REMOVED(state, payload) { },
    AREA_CACHE_LOAD(state, payload) {
      // console.debug('AreaManager - AREA_CACHE_LOAD - payload', payload);
      state.AreaList = payload;
    },
    INITAREA_LOAD(state, payload) {
      // console.debug("INITAREA_LOAD - payload", payload);

      state.ENTITYAREA_I18N_KEY = payload.entity_areas.I18N_KEY;
      state.ENTITYAREA_I18N = { ...payload.entity_areas.I18N };

      state.ENTITYAREA_LIST = { ...payload.entity_areas.LIST };
      // console.debug("INITAREA_LOAD - payload", state);
    },
    ENITIYPATTERN_AREA_MAP_LOAD(state, payload) {
      // console.debug("INITAREA_PATTERN_MAP_LOAD - payload", payload);

      state.ENTITY_PATTERN_AREA_MAP = { ...payload.entity_pattern_area_map };
      state.ENTITY_PATTERN_AREA_INIT_MAP = {
        ...payload.entity_pattern_area_init_map,
      };

      // console.debug("INITAREA_PATTERN_MAP_LOAD - payload", state);
    },
  },
  actions: {
    // Private functions
    async deleteAreaByAreaIdFromBackend({ state }, area_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({
        designdoc: "areas/list",
        keyid: area_id,
      });
    },

    //find all areas with limited content (activitydoc_id, process_pid) in the couchdb and return them
    async readAreaOverviewlistFromBackend({ state }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({
        designdoc: "areas/overviewlist",
        keyid: filter,
      });
    },
    //find an area by id in the couchdb and return it
    async readAreaByIdFromBackend({ state }, area_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({
        designdoc: "areas/list",
        keyid: area_id,
      });
    },
    //create a new area in the couchdb and return area_id or false
    async createAreaAndStoreInBackend({ rootGetters, commit }, data) {
      // Make sure an area_id is assigned on save for a new incident
      const currentdatetime = +new Date();

      // console.debug("createAreaAndStoreInBackend - data", data);

      if (data.is_custom == true)
        data.area_id = backendDataApi.generateNewCustomKeyId("ara");

      // Safe guard to ensure a valid id
      if (CommonUtils.isStringEmpty(data.area_id))
        return false;

      data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
      data.created_at = currentdatetime;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_CREATED";
      }

      const record = {
        type: AREA_RECORD_TYPE,
        keyid: data.area_id,

        username: rootGetters["SessionManager/getterCurrentUsername"],

        timeline: [data],
      };

      // console.debug("createAreaAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a area in the couchdb and return area_id or false
    async updateAreaAndStoreInBackend({ rootGetters, dispatch, commit }, data) {
      try {
        const currentdatetime = +new Date();

        data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
        data.created_at = currentdatetime;

        if (CommonUtils.isStringEmpty(data.changetype_id) || data.changetype_id == "ID_TIMELINE_CHANGETYPE_CREATED") {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        const record = await dispatch("readAreaByIdFromBackend", data.area_id);

        // console.debug("updateAreaAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        var doc = record[0].value;

        // console.debug("updateAreaAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      } catch (err) {
        console.error("updateAreaAndStoreInBackend - Error: ", err);
        return false;
      }
    },

    //
    // Public
    //

    // load area init doc from couchDB
    async actionRetrieveInitAreaDoc({ state, rootGetters, dispatch, commit }) {
      try {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(ENTITYAREA_DOCUMENT_NAME);

        // console.debug("actionRetrieveInitAreaDoc - doc", doc);
        if (doc === false) throw "Error retrieving Entity Area Data";

        commit("INITAREA_LOAD", doc);
        return true;
      } catch (err) {
        console.error("actionRetrieveInitAreaDoc - Error : ", err);
        return false;
      }
    },
    async actionRetrieveEntityPatternAreaMapDoc({ state, rootGetters, dispatch, commit }) {
      try {
        const doc = await backendDataApi.readDocumentByDocIdFromBackend(ENTITYPATTERN_AREA_MAP_DOCUMENT_NAME);

        // console.debug("actionRetrieveEntityPatternAreaMapDoc - doc", doc);
        if (doc === false) throw "Error retrieving Entity Pattern Area Map Data";

        commit("ENITIYPATTERN_AREA_MAP_LOAD", doc);
        return true;
      } catch (err) {
        console.error("actionRetrieveEntityPatternAreaMapDoc - Error : ", err);
        return false;
      }
    },
    // generate a cache for areas in vuey
    async actionRefreshAreasCache({ getters, commit, dispatch }) {
      try {
        // console.debug("actionRefreshAreasCache");
        const result = await dispatch("readAreaOverviewlistFromBackend");

        if (result == false)
          throw "Cannot read area overviewlist from backend";

        // console.debug("actionRefreshAreasCache - result", result);

        var arealist = {};

        if (result.length) {
          result.forEach((item) => {
            arealist[item.value.area_id] = item.value;
          });
        }

        commit("AREA_CACHE_LOAD", arealist);

        return true;
      } catch (err) {
        console.error("actionRefreshAreasCache - Error : ", err);
        return false;
      }
    },
    //save or update a new area into the couchdb
    async actionSaveArea({ geters, rootGetters, dispatch, commit }, data) {
      try {
        var result = false;
        // console.debug("actionSaveArea", data);

        // Clean up data
        delete data.display_name;
        delete data.display_description;

        if (CommonUtils.isStringEmpty(data.area_id) || Object.prototype.hasOwnProperty.call(data, "force_create")) {
          delete data.force_create;

          // console.debug("actionSaveArea - Create Mode", data);
          result = await dispatch("createAreaAndStoreInBackend", data);

          if (result === false) throw "Error creating area";

          // console.debug("actionSaveArea - actionCreateInitialProcessesByArea call", data);

          if ((await dispatch("ProcessManager/actionCreateInitialProcessesByArea", data, { root: true })) === false) {
            await dispatch("actionDeleteAreaById", data.area_id);
            throw "Error creating mandatory default process within area";
          }
        }
        else {
          result = await dispatch("updateAreaAndStoreInBackend", data);

          // console.debug("actionSaveArea - updateAreaAndStoreInBackend call", data);

          if (result === false) throw "Error updating area";
        }

        await dispatch("actionRefreshAreasCache");

        await dispatch(
          "ContactBookManager/actionSaveContact",
          {
            create_if_needed: true,
            ...data.responsible,
            contact_id: CryptoJS.SHA1(
              data.area_id + "ID_ENTITYCONTACT_ROLE_AREARESPONSIBLE"
            ).toString(CryptoJS.enc.Hex),
            role_id: "ID_ENTITYCONTACT_ROLE_AREARESPONSIBLE",
            reference: {
              scope: "area",
              refid: data.area_id,
            },
          },
          { root: true }
        );

        commit("AREA_SAVED", data);

        return result;
      } catch (err) {
        console.error("actionSaveArea - Error: ", err);
        return false;
      }
    },
    //delete a existing area from the couchdb
    async actionDeleteAreaById(
      { state, getters, rootGetters, dispatch, commit },
      area_id
    ) {
      try {
        // console.debug("actionDeleteAreaById", area_id);

        if ((await dispatch("deleteAreaByAreaIdFromBackend", area_id)) === false)
          throw "deleteAreaByAreaIdFromBackend deletion error";

        await dispatch("actionRefreshAreasCache");

        // console.debug('actionDeleteAreaById - After Update');
        commit("AREA_REMOVED", area_id);

        // Delete generic default process
        await dispatch("ProcessManager/actionDeleteGenericProcessByAreaId", area_id, { root: true });

        // Delete attachments
        await dispatch("AttachmentManager/actionDeleteAttachmentsByReference", { refid: area_id, scope: "area" }, { root: true });

        // Delete contacts
        await dispatch(
          "ContactBookManager/actionDeleteContactById",
          CryptoJS.SHA1(
            area_id + "ID_ENTITYCONTACT_ROLE_AREARESPONSIBLE"
          ).toString(CryptoJS.enc.Hex),
          { root: true }
        );

        return true;
      } catch (err) {
        console.error("actionDeleteAreaById - Error : ", err);
        return false;
      }
    },
    //find a area by area_id in the couchdb and return it
    actionReceiveAreaById(
      { state, getters, rootGetters, commit, dispatch },
      area_id
    ) {
      // console.debug("readAreaByIdFromBackend - area_id : ", area_id)

      return dispatch("readAreaByIdFromBackend", area_id)
        .then((result) => {
          if (result.length == 1)
            return { ...result[0].value };
          else
            return false;
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
    },
    actionReceiveCurrentAreaDataById(
      { state, getters, rootGetters, commit, dispatch },
      area_id
    ) {
      return dispatch("actionReceiveAreaById", area_id)
        .then((result) => {
          let areaObj = {};

          if (
            !(Object.keys(result).length === 0 && result.constructor === Object)
          ) {
            areaObj = {
              ...getters.getterEmptyAreaRecord,
              ...result.timeline[result.timeline.length - 1],
            };
          }

          return areaObj;
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
    },
    //find all areas with limited content from the couchdb and return them
    actionReceiveAreaOverviewlistAsArray({ dispatch }, filter) {
      return dispatch("readAreaOverviewlistFromBackend", filter)
        .then((result) => {
          if (result.length) return result.map((o) => o.value);
          else return [];
        })
        .catch((e) => {
          console.error("actionReceiveAreaOverviewlistAsArray - Error : ", e);
          return [];
        });
    },
    //create the initial areas based on sector_area_map at the end of firstrun
    async actionCreateInitialAreasByEntityData(
      { state, getters, commit, dispatch },
      entityData
    ) {
      try {
        const entitypattern_id = entityData.EntityInitOptions.entity_pattern;
        const countrycode = entityData.EntityInitOptions.countrycode;

        // console.debug("actionCreateInitialAreasByEntityData - entitypattern_id", entitypattern_id);
        // console.debug("actionCreateInitialAreasByEntityData - countrycode", countrycode);

        if (CommonUtils.isStringEmpty(entitypattern_id) || CommonUtils.isStringEmpty(countrycode))
          throw "Invalid entitydata";

        const initmap =
          Object.prototype.hasOwnProperty.call(state.ENTITY_PATTERN_AREA_INIT_MAP, countrycode) === true ?
            state.ENTITY_PATTERN_AREA_INIT_MAP[countrycode] :
            state.ENTITY_PATTERN_AREA_INIT_MAP.default;

        if (CommonUtils.isObjectEmpty(initmap))
          throw "Error no pattern area map for countrycode found";


        // add default areas from init map
        for (const area_ID of initmap[entitypattern_id]) {
          // console.debug("actionCreateInitialAreasByEntityData - area_ID", area_ID);

          var areaobject = {
            ...getters.getterEmptyAreaRecord,
            force_create: true,
            area_id: area_ID,
            core: {
              ...getters.getterEmptyAreaRecord.core,
              name: state.ENTITYAREA_LIST[area_ID].name,
              description: state.ENTITYAREA_LIST[area_ID].description,
              valid: true,
            },
            responsible: {
              ...entityData.Responsible,
              name: entityData.Responsible.responsible_name,
            },
          };

          delete areaobject.responsible.responsible_name;

          // console.debug("actionCreateInitialAreasByEntityData - areaobject", areaobject);

          if ((await dispatch("actionSaveArea", areaobject)) === false)
            throw `${("Error creating area with Id ", area_ID)}`;
        }

        await dispatch("actionRefreshAreasCache");

        return true;
      } catch (err) {
        console.error("actionCreateInitialAreasByEntityData - Error : ", err);
        return false;
      }
    },
    async actionGenerateAreaReport(
      { state, getters, rootGetters, dispatch, commit },
      areaObj
    ) {
      try {
        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "area_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

        printjobInfoObj.data.area = {...areaObj};

        // console.debug("actionGenerateAreaReport - printjobInfoObj", printjobInfoObj);

        // Create data
        printjobInfoObj.data.area.created_at_display = DatetimeUtils.getFormatDate(printjobInfoObj.data.area.created_at);

        var areadata = rootGetters["AreaManager/getterEntityAreaByIdByLc"](areaObj.area_id, printjobInfoObj.lang);
        printjobInfoObj.data.area.display_name = areadata.display_name;
        printjobInfoObj.data.area.display_description = areadata.display_description;

        printjobInfoObj.data.area.processAllowed = rootGetters["LicenseManager/getterCheckLicensedModuleProcess"];

        // Related processes array
        printjobInfoObj.data.processes = rootGetters["ProcessManager/getterEntityProcessesAsArrayByAreaIdByLc"](areaObj.area_id, printjobInfoObj.lang);

        //Related activitydocs array
        printjobInfoObj.data.activitydocs = rootGetters["ActivityDocManager/getterActivityDocsAsArrayByAreaId"](areaObj.area_id);

        for (const doc in printjobInfoObj.data.activitydocs) {
          printjobInfoObj.data.activitydocs[doc].stage_display =
            rootGetters["LookupManager/getterActivityDocStagesAsArrayByLc"](printjobInfoObj.lang)
              .filter(item => item.stage === printjobInfoObj.data.activitydocs[doc].stage)[0].display_printtext;

          const keyid = printjobInfoObj.data.activitydocs[doc].activitydoc_id;
          printjobInfoObj.data.activitydocs[doc].id_display =
          keyid.lastIndexOf("_") > 0 ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;
        }

        // Related documents array
        printjobInfoObj.data.area.documents_display = [];

        var documentarray = [];

        if (printjobInfoObj.data.area.hasReference)
          documentarray = await dispatch(
            "AttachmentManager/actionReadAttachmentsByReferenceAsArray",
            {
              reference: {
                scope: printjobInfoObj.data.area.reference.scope,
                refid: printjobInfoObj.data.area.reference.refid,
              },
            },
            { root: true }
          );
        else
          documentarray = await dispatch("AttachmentManager/actionReadAttachmentsByReferenceAsArray",
            {
              reference: {
                scope: "area",
                refid: printjobInfoObj.data.area.area_id,
              },
            },
            { root: true }
          );

        for (const index in documentarray) {
          // console.debug("documentarray - filename", documentarray[index].value.filename);
          // console.debug("documentarray - type", documentarray[index].value.doctype_id);
          const doctype = rootGetters["LookupManager/getterDocumentTypesAsArrayByLc"](printjobInfoObj.lang)
            .filter(item => item.id === documentarray[index].doctype_id)[0].display_name;
          printjobInfoObj.data.area.documents_display.push(documentarray[index].name + " (" +
            documentarray[index].filename + ", " + doctype + ")"
          );
        }

        // console.debug("actionGenerateAreaReport - printObj", printjobInfoObj);

        return await dispatch("PrintManager/actionGeneratePrintDocument", printjobInfoObj, { root: true }
        );
      } catch (err) {
        console.error("actionGenerateAreaReport - Error : ", err);
        return false;
      }
    },
  },
  getters: {
    // Public
    getterEntityAreaById: (state, getters, rootState, rootGetters) => (areaId) => {
      return getters.getterEntityAreaByIdByLc(areaId, rootGetters["TranslationManager/getterLanguageAlpha2Code"]);
    },
    getterAreaReportData: (state, getters, rootState, rootGetters) => {
      const printjobInfoObj = {
        file: "area_report.odt",
        convertTo: "pdf",
        lang: rootGetters["TranslationManager/lang"].lc,
      };

      return printjobInfoObj;
    },
    getterEntityAreaByIdByLc: (state, getters, rootState, rootGetters) => (areaId, lc) => {
      try {
        var areaObj = false;

        // console.debug("getterEntityAreaByIdByLc - areaId", areaId);

        if (Object.keys(state.ENTITYAREA_I18N).length === 0 && state.ENTITYAREA_I18N.constructor === Object)
          throw "No entity area language data availiable";

        if (state.ENTITYAREA_I18N.languages.indexOf(lc) === -1)
          lc = state.ENTITYAREA_I18N.defaultlang;

        if (CommonUtils.isStringEmpty(areaId))
          throw "Area id must not be empty";

        if (state.AreaList[areaId] !== undefined) {
          if (state.AreaList[areaId].is_custom) {
            areaObj = {
              ...state.AreaList[areaId],
              id: areaId,
              area_id: areaId,
              display_name: state.AreaList[areaId].name,
              display_description: state.AreaList[areaId].description,
            };
          }
          else {
            areaObj = {
              ...state.AreaList[areaId],
              id: areaId,
              area_id: areaId,
              display_name:
                state.ENTITYAREA_I18N.translations[lc][
                state.AreaList[areaId].name
                ],
              display_description:
                state.ENTITYAREA_I18N.translations[lc][
                state.AreaList[areaId].description
                ],
            };

            if (Object.prototype.hasOwnProperty.call(areaObj,"i18n_overwrites")) {
              if (Object.keys(areaObj.i18n_overwrites).filter((item) => item === lc).length > 0) {
                areaObj.display_name = areaObj.i18n_overwrites[lc].name;
                areaObj.display_description = areaObj.i18n_overwrites[lc].description;
              }
            }
          }
        }
        else {
          if (state.ENTITYAREA_LIST[areaId] !== undefined) {
            areaObj = {
              ...state.ENTITYAREA_LIST[areaId],
              id: areaId,
              area_id: areaId,
              display_name:
                state.ENTITYAREA_I18N.translations[lc][
                state.ENTITYAREA_LIST[areaId].name
                ],
              display_description:
                state.ENTITYAREA_I18N.translations[lc][
                state.ENTITYAREA_LIST[areaId].description
                ],
            };
          }
        }

        return areaObj;
      } catch (err) {
        console.error("getterEntityAreaById - Error", err);
        return false;
      }
    },
    getterEntityAreaIdArray: (state) => {
      return [...new Set([...Object.keys(state.AreaList)])];
    },
    getterAvailableEntityAreaIdArray: (state) => {
      return [...new Set([...Object.keys(state.ENTITYAREA_LIST)])];
    },
    getterAvailableEntityAreasAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      var currentAreas = [];

      getters.getterAvailableEntityAreaIdArray.forEach((areaId) => {
        // console.debug("getterAvailableEntityAreasAsArrayByLc - areaId", areaId);

        const obj = getters.getterEntityAreaByIdByLc(areaId, lc);

        // console.debug("getterAvailableEntityAreasAsArrayByLc - obj", obj);

        if (obj !== false) {
          currentAreas.push(obj);
        }
      });

      return currentAreas;
    },
    getterEntityAreasAsArray: (state, getters, rootState, rootGetters) => {
      return getters.getterEntityAreasAsArrayByLc(rootGetters["TranslationManager/getterLanguageAlpha2Code"]);
    },
    getterEntityAreasAsArrayByLc: (state, getters, rootState, rootGetters) => (lc) => {
      let currentAreas = [];

      for (let areaId in state.AreaList) {
        let obj = getters.getterEntityAreaByIdByLc(areaId, lc);

        if (obj !== false) {
          currentAreas.push(obj);
        }
      }

      return currentAreas;
    },
    getterSelectableEntityAreaIdArray: (state, getters) => {
      return getters.getterAvailableEntityAreaIdArray
          .filter((x) => !getters.getterEntityAreaIdArray.includes(x));
    },
    getterEmptyAreaRecord: () => {
      return { ...AREA_RECORD };
    },
    //
    // New pattern based functions
    //
    getterEntityInitialAreaAsArrayByEntityPatternId: (state, getters) => (countrycode, entitypattern_id) => {
      try {
        if (CommonUtils.isStringEmpty(entitypattern_id) || CommonUtils.isStringEmpty(countrycode)) return [];

        // console.debug("getterEntityInitialAreaAsArrayByEntityPatternId - state map : ", state.ENTITY_PATTERN_AREA_INIT_MAP);

        const initmap =
          Object.prototype.hasOwnProperty.call(
            state.ENTITY_PATTERN_AREA_INIT_MAP,
            countrycode
          ) === true ? state.ENTITY_PATTERN_AREA_INIT_MAP[countrycode] : state.ENTITY_PATTERN_AREA_INIT_MAP.default;

        var array = [];

        if (initmap !== undefined) {
          for (const item of initmap[entitypattern_id]) {
            array.push({
              ...getters.getterEntityAreaById(item),
            });
          }
        }

        return array;
      } catch (err) {
        console.error("getterEntityInitialAreaAsArrayByEntityPatternId - Error", err);
        return [];
      }
    },
    getterAreaIdArrayByPatternId: (state) => (pattern_id) => {
      let array = [];

      if ( !CommonUtils.isStringEmpty(pattern_id) && state.ENTITY_PATTERN_AREA_MAP[pattern_id] !== undefined
      ) {
        array = state.ENTITY_PATTERN_AREA_MAP[pattern_id];
      }

      return array;
    },
    getterAvailableAreaAsArrayByPatternId: (state, getters) => (pattern_id) => {
      var array = [];

      getters
        .getterAvailableAreaIdArrayByPatternId(pattern_id)
        .forEach((areaId) => {
          array.push(getters.getterEntityAreaById(areaId));
        });

      return array;
    },
    getterAvailableAreaIdArrayByPatternId: (state, getters) => (pattern_id) => {
      return [
        ...new Set([
          ...getters.getterEntityAreaIdArray,
          ...getters.getterAreaIdArrayByPatternId(pattern_id),
        ]),
      ];
    },
    getterSelectableAreaIdArrayByPatternId: (state, getters) => (pattern_id) => {
      return getters
        .getterAreaIdArrayByPatternId(pattern_id)
        .filter((x) => !getters.getterEntityAreaIdArray.includes(x));
    },
    //
    // End
    //
  },
};