export const REPOSITORY_EU_LIST = {
    "_id": "repository",
    "type": "template",
    "subtype": "files",
    "standard": true,
    "creator": "DAPAX",
    "created_at": "2021-08-12",
    "updated_at": "",
    "keyid": "repository",
    "repository": {
        "justiceareas": [
            "BE",
            "BG",
            "DK",
            "EE",
            "FI",
            "FR",
            "GR",
            "IE",
            "IT",
            "HR",
            "LV",
            "LT",
            "LU",
            "LI",
            "MT",
            "NL",
            "AT",
            "PL",
            "PT",
            "RO",
            "SM",
            "SE",
            "SK",
            "SI",
            "ES",
            "CZ",
            "HU",
            "VA",
            "FO",
            "CY"
        ],
        "name": "Musterdokumentvorlagen EU",
        "description": "Musterdokumentvorlagen EU ausser DE",
        "I18N_KEY": "repository.",
        "LIST": [{
                "filename": "gdpr_data_deletion_guideline_template.docx",
                "name": "gdpr_data_deletion_guideline_template",
                "description": "gdpr_data_deletion_guideline_template_description",
                "category": "ID_DOCUMENTCATEGORY_GUIDELINE",
                "type": "ID_DOCUMENTTYPE_GDPRGUIDELINE"
            },
            {
                "filename": "gdpr_data_breach_reporting_concept_template.docx",
                "name": "gdpr_data_breach_reporting_concept_template",
                "description": "gdpr_data_breach_reporting_concept_template_description",
                "category": "ID_DOCUMENTCATEGORY_INCIDENT",
                "type": "ID_DOCUMENTTYPE_CONCEPT"
            },
            {
                "filename": "gdpr_data_deletion_sop_template.docx",
                "name": "gdpr_data_deletion_sop_template",
                "description": "gdpr_data_deletion_sop_template_description",
                "category": "ID_DOCUMENTCATEGORY_PROOF",
                "type": "ID_DOCUMENTTYPE_CONCEPT"
            },
            {
                "filename": "gdpr_data_deletion_proof_template.docx",
                "name": "gdpr_data_deletion_proof_template",
                "description": "gdpr_data_deletion_proof_template_description",
                "category": "ID_DOCUMENTCATEGORY_PROOF",
                "type": "ID_DOCUMENTTYPE_PROOFDOCUMENT"
            },
            {
                "filename": "gdpr_individual_rights_concept_template.docx",
                "name": "gdpr_individual_rights_concept_template",
                "description": "gdpr_individual_rights_concept_template_description",
                "category": "ID_DOCUMENTCATEGORY_REQUEST",
                "type": "ID_DOCUMENTTYPE_CONCEPT"
            },
            {
                "filename": "gdpr_data_breach_agency_report_template.docx",
                "name": "gdpr_data_breach_agency_report_template",
                "description": "gdpr_data_breach_agency_report_template_description",
                "category": "ID_DOCUMENTCATEGORY_INCIDENT",
                "type": "ID_DOCUMENTTYPE_INCIDENTREPORT"
            },
            {
                "filename": "gdpr_data_processing_contract_template.docx",
                "name": "gdpr_data_processing_contract_template",
                "description": "gdpr_data_processing_contract_template_description",
                "category": "ID_DOCUMENTCATEGORY_CONTRACT",
                "type": "ID_DOCUMENTTYPE_DATAPROCESSINGCONTRACT"
            },
            {
                "filename": "gdpr_general_description_of_technical_and_organisational_security_measures.docx",
                "name": "gdpr_General_descrip_of_technical_and_organisational_security_measures",
                "description": "gdpr_General_descrip_of_technical_and_organisational_security_measures_description",
                "category": "ID_DOCUMENTCATEGORY_ITSECURITY",
                "type": "ID_DOCUMENTTYPE_TOM"
            },
            {
                "filename": "gdpr_individual_rights_data_information_template.docx",
                "name": "gdpr_individual_rights_data_information_template",
                "description": "gdpr_individual_rights_data_information_template_description",
                "category": "ID_DOCUMENTCATEGORY_REQUEST",
                "type": "ID_DOCUMENTTYPE_REQUESTREPORT"
            },
            {
                "filename": "gdpr_generic_accompanying_document_template.docx",
                "name": "gdpr_generic_accompanying_document_template",
                "description": "gdpr_generic_accompanying_document_template_description",
                "category": "ID_DOCUMENTCATEGORY_INFORMATION",
                "type": "ID_DOCUMENTTYPE_ADDITIONALDOCUMENT"
            }
        ],
        "I18N": {
            "languages": [
                "de",
                "gb"
            ],
            "defaultlang": "gb",
            "translations": {
                "de": {
                    "gdpr_company_guideline_template": "Datenschutzrichtlinie",
                    "gdpr_company_guideline_template_description": "Konzept zum Stellenwert und Umgang mit dem Datenschutz",
                    "gdpr_data_deletion_guideline_template": "Löschrichtlinie",
                    "gdpr_data_deletion_guideline_template_description": "Konzept zum sicheren Löschen und Vernichten von Daten",
                    "gdpr_data_breach_reporting_concept_template": "Konzept zur Bearbeitung von Datenschutzvorfällen",
                    "gdpr_data_breach_reporting_concept_template_description": "Bearbeitung von Datenschutzvorfällen",
                    "gdpr_data_deletion_sop_template": "Löscharbeitsanweisung",
                    "gdpr_data_deletion_sop_template_description": "Dokumentation der Arbeitsschrittezur Löschung von Daten ",
                    "gdpr_data_deletion_proof_template": "Löschnachweis",
                    "gdpr_data_deletion_proof_template_description": "Dokumentationsblatt zur Protkollierung der durchgeführten Datenlöschungen",
                    "gdpr_individual_rights_concept_template": "Konzept zur Bearbeitung von Anfragen",
                    "gdpr_individual_rights_concept_template_description": "Konzept zur Bearbeitung von Anfragen und Wahrung der Betroffenenrechte",
                    "gdpr_data_breach_agency_report_template": "Vorfall Meldebericht",
                    "gdpr_data_breach_agency_report_template_description": "Generischer Bericht zur Meldung eines Vorfalls an die Aufsichtbehörde",
                    "gdpr_data_processing_contract_template": "Vertrag zur Auftragsdatenverarbeitung",
                    "gdpr_data_processing_contract_template_description": "Datenschutzvereinbarung mit einem Dienstleister",
                    "gdpr_General_descrip_of_technical_and_organisational_security_measures": "TOM Dokument",
                    "gdpr_General_descrip_of_technical_and_organisational_security_measures_description": "Dokumentation der technn. und organisatorischen Maßnahmen",
                    "gdpr_individual_rights_data_information_template": "Dokumentvorlage zur Datenauskunft",
                    "gdpr_individual_rights_data_information_template_description": "Dokumentvorlage zur Datenauskunft bei Anfragen",
                    "gdpr_generic_accompanying_document_template": "Generisches Begleitdokument",
                    "gdpr_generic_accompanying_document_template_description": "Allgemeine Dokumentvorlage für Begleitschreiben"
                },
                "gb": {
                    "gdpr_company_guideline_template": "GDPR Guideline and Policy",
                    "gdpr_company_guideline_template_description": "Concept of the importance and handling of data protection",
                    "gdpr_data_deletion_guideline_template": "Retention / Deletion Policy",
                    "gdpr_data_deletion_guldeline_template_description": "Concept for the secure deletion and destruction of data",
                    "gdpr_data_breach_reporting_concept_template": "Data Breach Concept",
                    "gdpr_data_breach_reporting_concept_template_description": "Concept for processing data protection incident",
                    "gdpr_data_deletion_sop_template": "Standard Work Order for Data Deletion",
                    "gdpr_data_deletion_sop_template_description": "Documentation of the working procedures for deleting data",
                    "gdpr_data_deletion_proof_template": "Deletion Proof Document",
                    "gdpr_data_deletion_proof_template_description": "Documentation sheet for logging the data deletions carried out",
                    "gdpr_individual_rights_concept_template": "Concept for Requests",
                    "gdpr_individual_rights_concept_template_description": "Concept for processing inquiries and safeguarding the rights of those affected",
                    "gdpr_data_breach_agency_report_template": "Data Breach Report Template",
                    "gdpr_data_breach_agency_report_template_description": "Generic report for reporting an incident to the supervisory authority",
                    "gdpr_data_processing_contract_template": "Generic Data Processing Contract",
                    "gdpr_data_processing_contract_template_description": "Data protection agreement with a service provider",
                    "gdpr_General_descrip_of_technical_and_organisational_security_measures": "TOM Document",
                    "gdpr_General_descrip_of_technical_and_organisational_security_measures_description": "Documentation of the techn. and organizational measures",
                    "gdpr_individual_rights_data_information_template": "Request Data Information Template",
                    "gdpr_individual_rights_data_information_template_description": "Document template for data information inquiries",
                    "gdpr_generic_accompanying_document_template": "Generic Master Template",
                    "gdpr_generic_accompanying_document_template_description": "General template for documentation",
                    "gdpr_data_deletion_guideline_template_description": "Concept for the secure deletion and destruction of data"
                }
            }
        }
    }
}