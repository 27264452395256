  export const PROCEDURETARGET_I18N_KEY = "procedure_targets.";

export const PROCEDURETARGET_LIST = {
  "ID_PROCEDURETARGET_ENTITY": {
    "name": "PROCEDURETARGET_ENTITY_LABEL",
    "description": "PROCEDURETARGET_ENTITY_DESCRIPTION",
    "scope": "entity"
  },

  "ID_PROCEDURETARGET_LOCATION": {
    "name": "PROCEDURETARGET_LOCATION_LABEL",
    "description": "PROCEDURETARGET_LOCATION_DESCRIPTION",
    "scope": "location"
  },

  "ID_PROCEDURETARGET_AREA": {
    "name": "PROCEDURETARGET_AREA_LABEL",
    "description": "PROCEDURETARGET_AREA_DESCRIPTION",
    "scope": "area"
  },

  "ID_PROCEDURETARGET_PROCESS": {
    "name": "PROCEDURETARGET_PROCESS_LABEL",
    "description": "PROCEDURETARGET_PROCESS_DESCRIPTION",
    "scope": "process"
  },

  "ID_PROCEDURETARGET_ACTIVITYDOC": {
    "name": "PROCEDURETARGET_ACTIVITYDOC_LABEL",
    "description": "PROCEDURETARGET_ACTIVITYDOC_DESCRIPTION",
    "scope": "activitydoc"
  },
};