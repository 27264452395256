// Standard app modules for license and access management
export const LICENSE_MODULE_ENTITY = "entity";
export const LICENSE_MODULE_LOCATION = "location";
export const LICENSE_MODULE_AREA = "area";
export const LICENSE_MODULE_INCIDENT = "incident";
export const LICENSE_MODULE_REQUEST = "request";
export const LICENSE_MODULE_PROOF = "proof";
export const LICENSE_MODULE_CONTACTBOOK = "addressbook";
export const LICENSE_MODULE_ORGCHART = "orgchart";
export const LICENSE_MODULE_CONTRACTPARTNER = "contractpartner";
export const LICENSE_MODULE_PROCESS = "process";
export const LICENSE_MODULE_PROCEDURE = "procedure";
export const LICENSE_MODULE_DSFA = "dsfa";
export const LICENSE_MODULE_ACTIVITYDOC = "activitydoc";
export const LICENSE_MODULE_DOCUMENTARCHIVE = "documentarchive";
export const LICENSE_MODULE_ATTACHMENT = "attachment";
export const LICENSE_MODULE_ASSET = "asset";
export const LICENSE_MODULE_RISK = "risk";
export const LICENSE_MODULE_TASK = "task";

export const LICENSE_MODULE_OFFICER = "officer";
export const LICENSE_MODULE_USERTASK = "usertask";

export const LICENSE_MODULE_USRMGMT = "usrmgmt";
export const LICENSE_MODULE_PRINT = "print";

export const LICENSE_MODULES_ARRAY = [
  LICENSE_MODULE_ENTITY,
  LICENSE_MODULE_LOCATION,
  LICENSE_MODULE_AREA,
  LICENSE_MODULE_INCIDENT,
  LICENSE_MODULE_REQUEST,
  LICENSE_MODULE_PROOF,
  LICENSE_MODULE_CONTACTBOOK,
  LICENSE_MODULE_ORGCHART,
  LICENSE_MODULE_CONTRACTPARTNER,
  LICENSE_MODULE_PROCESS,
  LICENSE_MODULE_PROCEDURE,
  LICENSE_MODULE_DSFA,
  LICENSE_MODULE_ACTIVITYDOC,
  LICENSE_MODULE_DOCUMENTARCHIVE,
  LICENSE_MODULE_ATTACHMENT,
  LICENSE_MODULE_ASSET,
  LICENSE_MODULE_RISK,
  LICENSE_MODULE_TASK,
  LICENSE_MODULE_USRMGMT,
  LICENSE_MODULE_PRINT
];

export const LICENSE_PLAN_STARTER_NAME = "starter";
