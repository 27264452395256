import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import de from 'vuetify/es5/locale/de'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en, de },
    current: 'en',
  },
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {}
    }
  },
  icons: {
    iconfont: 'mdi',
  }
});
