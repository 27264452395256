export const DSFASTAGE_I18N_KEY = "dsfa_stages.";

export const DSFASTAGE_LIST = {
    "ID_DSFASTAGE_DRAFT": {
      "stage": 1,
      "name": "DSFASTAGE_DRAFT_LABEL",
      "description": "DSFASTAGE_DRAFT_DESCRIPTION"
    },
    "ID_DSFASTAGE_REVIEW": {
      "stage": 2,
      "name": "DSFASTAGE_REVIEW_LABEL",
      "description": "DSFASTAGE_REVIEW_DESCRIPTION"
    },
    "ID_DSFASTAGE_APPROVED": {
      "stage": 3,
      "name": "DSFASTAGE_APPROVED_LABEL",
      "description": "DSFASTAGE_APPROVED_DESCRIPTION"
    }
};
  