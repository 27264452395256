import { STAGEHISTORY_DATA } from "@/config/config.couchdb.js";
import { CONTACT_DATA, ADDRESS_DATA } from "../config/config.couchdb";

//
// Risks
//

export const RISK_RECORD_TYPE = "risk";

export const RISK_STAGEHISTORY_RECORD = {
  changetype_id: false,
  stage: 1,
  revision: 0,
  ...STAGEHISTORY_DATA
};

export const RISK_SCHEMA_VERSION = 2;

export const RISK_RECORD = {

  risk_id: undefined,

  stage: 1,
  revision: 0,

  version: RISK_SCHEMA_VERSION,

  approved: false,

  active: true,
  assigned_to: "",

  changenotes: "",
  changetype_id: false,

  core: {
    category_name: "",
    name: "",

    version: RISK_SCHEMA_VERSION,

    notes: "",

    category_id: false,
    danger_id: false,

    aspect_ids: [],

    activation_date: false,
    decommission_date: false,

    decommission_notes: "",

    valid: false
  },

  evaluation: {
    description: "",
    impact: "",

    impact_level: 0,
    propability_level: 0,


    valid: false
  },

  mitigation: {
    description: "",
    impact: "",

    impact_level: 0,
    propability_level: 0,

    valid: false
  },

  responsible: {
    name: '',

    role_id: "ID_ENTITYCONTACT_ROLE_RISKRESPONSIBLE",

    location_id: false,

    ...ADDRESS_DATA,
    ...CONTACT_DATA,
    valid: false
  },

  effectiveness: {
    check_done: false,

    effective: false,

    method: "",
    notes: "",

    check_date: '',
    check_time: '',

    valid: false
  },

  acceptance: {
    risk_accepted: false,

    mitigation_cost: null,
    risk_cost: null,

    declaration: "",
    notes: "",

    accept_date: '',
    accept_time: '',

    valid: false
  },

  stagehistory: [],

  created_at: false,
  created_by: "",

  valid: false
};