export const RISKCATALOGUE_I18N_KEY = "risk_catalogues.";
export const RISKCATALOGUE_LIST = {

	"ID_RISKCATALOGUE_BSIHANDBOOK": {
		"name": "RISKCATALOGUE_BSIHANDBOOK_LABEL", 
		"description": "RISKCATALOGUE_BSIHANDBOOK_DESCRIPTION",
		"basevalues": [
			"ID_RISKBASEVALUE_CONFIDENTIALITY",
			"ID_RISKBASEVALUE_INTEGRITY",
			"ID_RISKBASEVALUE_AVAILIBILITY"
		]
	},
	"ID_RISKCATALOGUE_DATAPROTECTIONMODEL": {
		"name": "RISKCATALOGUE_DATAPROTECTIONMODEL_LABEL", 
		"description": "RISKCATALOGUE_DATAPROTECTIONMODEL_DESCRIPTION",
		"basevalues": [
			"ID_RISKBASEVALUE_CONFIDENTIALITY",
			"ID_RISKBASEVALUE_INTEGRITY",
			"ID_RISKBASEVALUE_AVAILIBILITY",
			"ID_RISKBASEVALUE_TRANSPARENCY",
			"ID_RISKBASEVALUE_INTERVENABILITY",
			"ID_RISKBASEVALUE_DATAMINIMALISATION",
			"ID_RISKBASEVALUE_NONCHAINEDPROCESSING"
		]
	},
	"ID_RISKCATALOGUE_GENERIC": {
		"name": "RISKCATALOGUE_GENERIC_LABEL", 
		"description": "RISKCATALOGUE_GENERIC_DESCRIPTION",
		"basevalues": [
			"ID_RISKBASEVALUE_CONFIDENTIALITY",
			"ID_RISKBASEVALUE_INTEGRITY",
			"ID_RISKBASEVALUE_AVAILIBILITY"
		]
	}	
};

export const RISKDANGER_I18N_KEY = "risk_dangers.";
export const RISKDANGER_LIST = {

		//
		// Catalogue BSI
		//

		"ID_RISKDANGER_FIRE": {
			"name": "RISKDANGER_FIRE_LABEL",
			"description": "RISKDANGER_FIRE_DESCRIPTION",
			"category_id": "G.0.1",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_UNFAVORABLE_CLIMATIC_CONDITIONS": {
			"name": "RISKDANGER_UNFAVORABLE_CLIMATIC_CONDITIONS_LABEL",
			"description": "RISKDANGER_UNFAVORABLE_CLIMATIC_CONDITIONS_DESCRIPTION",
			"category_id": "G.0.2",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_WATER": {
			"name": "RISKDANGER_WATER_LABEL",
			"description": "RISKDANGER_WATER_DESCRIPTION",
			"category_id": "G.0.3",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_POLLUTION_DUST_CORROSION": {
			"name": "RISKDANGER_POLLUTION_DUST_CORROSION_LABEL",
			"description": "RISKDANGER_POLLUTION_DUST_CORROSION_DESCRIPTION",
			"category_id": "G.0.4",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_NATURAL_DISASTERS": {
			"name": "RISKDANGER_NATURAL_DISASTERS_LABEL",
			"description": "RISKDANGER_NATURAL_DISASTERS_DESCRIPTION",
			"category_id": "G.0.5",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_DISASTERS_IN_THE_AREA": {
			"name": "RISKDANGER_DISASTERS_IN_THE_AREA_LABEL",
			"description": "RISKDANGER_DISASTERS_IN_THE_AREA_DESCRIPTION",
			"category_id": "G.0.6",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_MAJOR_EVENTS_IN_THE_AREA": {
			"name": "RISKDANGER_MAJOR_EVENTS_IN_THE_AREA_LABEL",
			"description": "RISKDANGER_MAJOR_EVENTS_IN_THE_AREA_DESCRIPTION",
			"category_id": "G.0.7",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_FAILURE_OR_MALFUNCTION_OF_THE_POWER_SUPPLY": {
			"name": "RISKDANGER_FAILURE_OR_MALFUNCTION_OF_THE_POWER_SUPPLY_LABEL",
			"description": "RISKDANGER_FAILURE_OR_MALFUNCTION_OF_THE_POWER_SUPPLY_DESCRIPTION",
			"category_id": "G.0.8",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_FAILURE_OR_DISRUPTION_OF_COMMUNICATION_NETWORKS": {
			"name": "RISKDANGER_FAILURE_OR_DISRUPTION_OF_COMMUNICATION_NETWORKS_LABEL",
			"description": "RISKDANGER_FAILURE_OR_DISRUPTION_OF_COMMUNICATION_NETWORKS_DESCRIPTION",
			"category_id": "G 0.9",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_FAILURE_OR_DISRUPTION_OF_SUPPLY_NETWORKS": {
			"name": "RISKDANGER_FAILURE_OR_DISRUPTION_OF_SUPPLY_NETWORKS_LABEL",
			"description": "RISKDANGER_FAILURE_OR_DISRUPTION_OF_SUPPLY_NETWORKS_DESCRIPTION",
			"category_id": "G 0.10",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_FAILURE_OR_DISRUPTION_OF_SERVICE_PROVIDERS": {
			"name": "RISKDANGER_FAILURE_OR_DISRUPTION_OF_SERVICE_PROVIDERS_LABEL",
			"description": "RISKDANGER_FAILURE_OR_DISRUPTION_OF_SERVICE_PROVIDERS_DESCRIPTION",
			"category_id": "G 0.11",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_ELECTROMAGNETIC_INTERFERENCE": {
			"name": "RISKDANGER_ELECTROMAGNETIC_INTERFERENCE_LABEL",
			"description": "RISKDANGER_ELECTROMAGNETIC_INTERFERENCE_DESCRIPTION",
			"category_id": "G 0.12",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_INTERCEPTING_COMPROMISING_RADIATION": {
			"name": "RISKDANGER_INTERCEPTING_COMPROMISING_RADIATION_LABEL",
			"description": "RISKDANGER_INTERCEPTING_COMPROMISING_RADIATION_DESCRIPTION",
			"category_id": "G 0.13",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY"
			]
		},
		"ID_RISKDANGER_SPYING_OUT_INFORMATIONESPIONAGE": {
			"name": "RISKDANGER_SPYING_OUT_INFORMATIONESPIONAGE_LABEL",
			"description": "RISKDANGER_SPYING_OUT_INFORMATIONESPIONAGE_DESCRIPTION",
			"category_id": "G 0.14",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY"
			]
		},
		"ID_RISKDANGER_WIRETAPPING": {
			"name": "RISKDANGER_WIRETAPPING_LABEL",
			"description": "RISKDANGER_WIRETAPPING_DESCRIPTION",
			"category_id": "G 0.15",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY"
			]
		},
		"ID_RISKDANGER_THEFT_OF_DEVICES,_DATA_CARRIERS_AND_DOCUMENTS": {
			"name": "RISKDANGER_THEFT_OF_DEVICES,_DATA_CARRIERS_AND_DOCUMENTS_LABEL",
			"description": "RISKDANGER_THEFT_OF_DEVICES,_DATA_CARRIERS_AND_DOCUMENTS_DESCRIPTION",
			"category_id": "G 0.16",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_LOSS_OF_DEVICES,_DATA_CARRIERS_AND_DOCUMENTS": {
			"name": "RISKDANGER_LOSS_OF_DEVICES,_DATA_CARRIERS_AND_DOCUMENTS_LABEL",
			"description": "RISKDANGER_LOSS_OF_DEVICES,_DATA_CARRIERS_AND_DOCUMENTS_DESCRIPTION",
			"category_id": "G 0.17",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_BAD_PLANNING_OR_LACK_OF_ADAPTATION": {
			"name": "RISKDANGER_BAD_PLANNING_OR_LACK_OF_ADAPTATION_LABEL",
			"description": "RISKDANGER_BAD_PLANNING_OR_LACK_OF_ADAPTATION_DESCRIPTION",
			"category_id": "G 0.18",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_DISCLOSURE_OF_SENSITIVE_INFORMATION": {
			"name": "RISKDANGER_DISCLOSURE_OF_SENSITIVE_INFORMATION_LABEL",
			"description": "RISKDANGER_DISCLOSURE_OF_SENSITIVE_INFORMATION_DESCRIPTION",
			"category_id": "G 0.19",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY"
			]
		},
		"ID_RISKDANGER_INFORMATION_FROM_AN_UNRELIABLE_SOURCE": {
			"name": "RISKDANGER_INFORMATION_FROM_AN_UNRELIABLE_SOURCE_LABEL",
			"description": "RISKDANGER_INFORMATION_FROM_AN_UNRELIABLE_SOURCE_DESCRIPTION",
			"category_id": "G 0.20",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_MANIPULATION_OF_HARDWARE_AND_SOFTWARE": {
			"name": "RISKDANGER_MANIPULATION_OF_HARDWARE_AND_SOFTWARE_LABEL",
			"description": "RISKDANGER_MANIPULATION_OF_HARDWARE_AND_SOFTWARE_DESCRIPTION",
			"category_id": "G 0.21",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_MANIPULATION_OF_INFORMATION": {
			"name": "RISKDANGER_MANIPULATION_OF_INFORMATION_LABEL",
			"description": "RISKDANGER_MANIPULATION_OF_INFORMATION_DESCRIPTION",
			"category_id": "G 0.22",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY"
			]
		},
		"ID_RISKDANGER_UNAUTHORIZED_ENTRY_INTO_IT_SYSTEMS": {
			"name": "RISKDANGER_UNAUTHORIZED_ENTRY_INTO_IT_SYSTEMS_LABEL",
			"description": "RISKDANGER_UNAUTHORIZED_ENTRY_INTO_IT_SYSTEMS_DESCRIPTION",
			"category_id": "G 0.23",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY"
			]
		},
		"ID_RISKDANGER_DESTRUCTION_OF_DEVICES_OR_DATA_CARRIERS": {
			"name": "RISKDANGER_DESTRUCTION_OF_DEVICES_OR_DATA_CARRIERS_LABEL",
			"description": "RISKDANGER_DESTRUCTION_OF_DEVICES_OR_DATA_CARRIERS_DESCRIPTION",
			"category_id": "G 0.24",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_FAILURE_OF_DEVICES_OR_SYSTEMS": {
			"name": "RISKDANGER_FAILURE_OF_DEVICES_OR_SYSTEMS_LABEL",
			"description": "RISKDANGER_FAILURE_OF_DEVICES_OR_SYSTEMS_DESCRIPTION",
			"category_id": "G 0.25",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_DEVICE_OR_SYSTEM_MALFUNCTION": {
			"name": "RISKDANGER_DEVICE_OR_SYSTEM_MALFUNCTION_LABEL",
			"description": "RISKDANGER_DEVICE_OR_SYSTEM_MALFUNCTION_DESCRIPTION",
			"category_id": "G 0.26",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_LACK_OF_RESOURCES": {
			"name": "RISKDANGER_LACK_OF_RESOURCES_LABEL",
			"description": "RISKDANGER_LACK_OF_RESOURCES_DESCRIPTION",
			"category_id": "G 0.27",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_SOFTWARE_VULNERABILITIES_OR_ERRORS": {
			"name": "RISKDANGER_SOFTWARE_VULNERABILITIES_OR_ERRORS_LABEL",
			"description": "RISKDANGER_SOFTWARE_VULNERABILITIES_OR_ERRORS_DESCRIPTION",
			"category_id": "G 0.28",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_VIOLATION_OF_ANY_LAW_OR_REGULATION": {
			"name": "RISKDANGER_VIOLATION_OF_ANY_LAW_OR_REGULATION_LABEL",
			"description": "RISKDANGER_VIOLATION_OF_ANY_LAW_OR_REGULATION_DESCRIPTION",
			"category_id": "G 0.29",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_UNAUTHORIZED_USE_OR_ADMINISTRATION_OF_DEVICES_AND_SYSTEMS": {
			"name": "RISKDANGER_UNAUTHORIZED_USE_OR_ADMINISTRATION_OF_DEVICES_AND_SYSTEMS_LABEL",
			"description": "RISKDANGER_UNAUTHORIZED_USE_OR_ADMINISTRATION_OF_DEVICES_AND_SYSTEMS_DESCRIPTION",
			"category_id": "G 0.30",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_INCORRECT_USE_OR_ADMINISTRATION_OF_DEVICES_AND_SYSTEMS": {
			"name": "RISKDANGER_INCORRECT_USE_OR_ADMINISTRATION_OF_DEVICES_AND_SYSTEMS_LABEL",
			"description": "RISKDANGER_INCORRECT_USE_OR_ADMINISTRATION_OF_DEVICES_AND_SYSTEMS_DESCRIPTION",
			"category_id": "G 0.31",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_ABUSE_OF_PERMISSIONS": {
			"name": "RISKDANGER_ABUSE_OF_PERMISSIONS_LABEL",
			"description": "RISKDANGER_ABUSE_OF_PERMISSIONS_DESCRIPTION",
			"category_id": "G 0.32",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_STAFF_SHORTAGE": {
			"name": "RISKDANGER_STAFF_SHORTAGE_LABEL",
			"description": "RISKDANGER_STAFF_SHORTAGE_DESCRIPTION",
			"category_id": "G 0.33",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_ATTACK": {
			"name": "RISKDANGER_ATTACK_LABEL",
			"description": "RISKDANGER_ATTACK_DESCRIPTION",
			"category_id": "G 0.34",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_COERCION_EXTORTION_OR_CORRUPTION": {
			"name": "RISKDANGER_COERCION_EXTORTION_OR_CORRUPTION_LABEL",
			"description": "RISKDANGER_COERCION_EXTORTION_OR_CORRUPTION_DESCRIPTION",
			"category_id": "G 0.35",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_IDENTITY_THEFT": {
			"name": "RISKDANGER_IDENTITY_THEFT_LABEL",
			"description": "RISKDANGER_IDENTITY_THEFT_DESCRIPTION",
			"category_id": "G 0.36",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_DENYING_ACTIONS": {
			"name": "RISKDANGER_DENYING_ACTIONS_LABEL",
			"description": "RISKDANGER_DENYING_ACTIONS_DESCRIPTION",
			"category_id": "G 0.37",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY"
			]
		},
		"ID_RISKDANGER_MISUSE_OF_PERSONAL_DATA": {
			"name": "RISKDANGER_MISUSE_OF_PERSONAL_DATA_LABEL",
			"description": "RISKDANGER_MISUSE_OF_PERSONAL_DATA_DESCRIPTION",
			"category_id": "G 0.38",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY"
			]
		},
		"ID_RISKDANGER_MALICIOUS_PROGRAMS": {
			"name": "RISKDANGER_MALICIOUS_PROGRAMS_LABEL",
			"description": "RISKDANGER_MALICIOUS_PROGRAMS_DESCRIPTION",
			"category_id": "G 0.39",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_DENIAL_OF_SERVICE": {
			"name": "RISKDANGER_DENIAL_OF_SERVICE_LABEL",
			"description": "RISKDANGER_DENIAL_OF_SERVICE_DESCRIPTION",
			"category_id": "G 0.40",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_SABOTAGE": {
			"name": "RISKDANGER_SABOTAGE_LABEL",
			"description": "RISKDANGER_SABOTAGE_DESCRIPTION",
			"category_id": "G 0.41",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_SOCIAL_ENGINEERING": {
			"name": "RISKDANGER_SOCIAL_ENGINEERING_LABEL",
			"description": "RISKDANGER_SOCIAL_ENGINEERING_DESCRIPTION",
			"category_id": "G 0.42",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY"
			]
		},
		"ID_RISKDANGER_IMPORT_OF_MESSAGES": {
			"name": "RISKDANGER_IMPORT_OF_MESSAGES_LABEL",
			"description": "RISKDANGER_IMPORT_OF_MESSAGES_DESCRIPTION",
			"category_id": "G 0.43",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY"
			]
		},
		"ID_RISKDANGER_UNAUTHORIZED_ENTRY_INTO_PREMISES": {
			"name": "RISKDANGER_UNAUTHORIZED_ENTRY_INTO_PREMISES_LABEL",
			"description": "RISKDANGER_UNAUTHORIZED_ENTRY_INTO_PREMISES_DESCRIPTION",
			"category_id": "G 0.44",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_DATA_LOSS": {
			"name": "RISKDANGER_DATA_LOSS_LABEL",
			"description": "RISKDANGER_DATA_LOSS_DESCRIPTION",
			"category_id": "G 0.45",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
		"ID_RISKDANGER_LOSS_OF_INTEGRITY_OF_INFORMATION_WORTH_PROTECTING": {
			"name": "RISKDANGER_LOSS_OF_INTEGRITY_OF_INFORMATION_WORTH_PROTECTING_LABEL",
			"description": "RISKDANGER_LOSS_OF_INTEGRITY_OF_INFORMATION_WORTH_PROTECTING_DESCRIPTION",
			"category_id": "G 0.46",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY"
			]
		},
		"ID_RISKDANGER_HARMFUL_SIDE_EFFECTS_OF_IT-BASED_ATTACKS": {
			"name": "RISKDANGER_HARMFUL_SIDE_EFFECTS_OF_IT-BASED_ATTACKS_LABEL",
			"description": "RISKDANGER_HARMFUL_SIDE_EFFECTS_OF_IT-BASED_ATTACKS_DESCRIPTION",
			"category_id": "G 0.47",
			"catalogue_id": "ID_RISKCATALOGUE_BSIHANDBOOK",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		//
		// Catalogue generic
		//

		"ID_RISKDANGER_INTERNALPERSON_WITH_HARMINTENT": {
			"name": "RISKDANGER_INTERNALPERSON_WITH_HARMINTENT_LABEL",
			"description": "RISKDANGER_INTERNALPERSON_WITH_HARMINTENT_DESCRIPTION",
			"category_id": "R-1.1",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_INTERNALPERSON_WITHOUT_HARMINTENT": {
			"name": "RISKDANGER_INTERNALPERSON_WITHOUT_HARMINTENT_LABEL",
			"description": "RISKDANGER_INTERNALPERSON_WITHOUT_HARMINTENT_DESCRIPTION",
			"category_id": "R-1.2",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_EXTERNALPERSON_WITH_HARMINTENT": {
			"name": "RISKDANGER_EXTERNALPERSON_WITH_HARMINTENT_LABEL",
			"description": "RISKDANGER_EXTERNALPERSON_WITH_HARMINTENT_DESCRIPTION",
			"category_id": "R-2.1",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_EXTERNALPERSON_WITHOUT_HARMINTENT": {
			"name": "RISKDANGER_EXTERNALPERSON_WITHOUT_HARMINTENT_LABEL",
			"description": "RISKDANGER_EXTERNALPERSON_WITHOUT_HARMINTENT_DESCRIPTION",
			"category_id": "R-2.2",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_TECHNICALERROR": {
			"name": "RISKDANGER_TECHNICALERROR_LABEL",
			"description": "RISKDANGER_TECHNICALERROR_DESCRIPTION",
			"category_id": "R-3.1",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},		

		"ID_RISKDANGER_INTERNET": {
			"name": "RISKDANGER_INTERNET_LABEL",
			"description": "RISKDANGER_INTERNET_DESCRIPTION",
			"category_id": "R-3.2",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_ELECTICITY": {
			"name": "RISKDANGER_ELECTICITY_LABEL",
			"description": "RISKDANGER_ELECTICITY_DESCRIPTION",
			"category_id": "R-3.3",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},		

		"ID_RISKDANGER_NATURE": {
			"name": "RISKDANGER_NATURE_LABEL",
			"description": "RISKDANGER_NATURE_DESCRIPTION",
			"category_id": "R-4.1",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_BIGEVENT": {
			"name": "RISKDANGER_BIGEVENT_LABEL",
			"description": "RISKDANGER_BIGEVENT_DESCRIPTION",
			"category_id": "R-4.2",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",				
				"ID_RISKBASEVALUE_INTEGRITY"
			]
		},	
		
		"ID_RISKDANGER_ELEMENTARY_WATER": {
			"name": "RISKDANGER_ELEMENTARY_WATER_LABEL",
			"description": "RISKDANGER_ELEMENTARY_WATER_DESCRIPTION",
			"category_id": "R-5.1",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},		
		
		"ID_RISKDANGER_ELEMENTARY_FIRE": {
			"name": "RISKDANGER_ELEMENTARY_FIRE_LABEL",
			"description": "RISKDANGER_ELEMENTARY_FIRE_DESCRIPTION",
			"category_id": "R-5.2",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},	

		"ID_RISKDANGER_ELEMENTARY_FLASH": {
			"name": "RISKDANGER_ELEMENTARY_FLASH_LABEL",
			"description": "RISKDANGER_ELEMENTARY_FLASH_DESCRIPTION",
			"category_id": "R-5.3",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},	

		"ID_RISKDANGER_ELEMENTARY_STORM": {
			"name": "RISKDANGER_ELEMENTARY_STORM_LABEL",
			"description": "RISKDANGER_ELEMENTARY_STORM_DESCRIPTION",
			"category_id": "R-5.4",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},			

		"ID_RISKDANGER_ELEMENTARY_EARTHQUAKE": {
			"name": "RISKDANGER_ELEMENTARY_EARTHQUAKE_LABEL",
			"description": "RISKDANGER_ELEMENTARY_EARTHQUAKE_DESCRIPTION",
			"category_id": "R-5.5",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},	

		"ID_RISKDANGER_STATE_INSTITUTION": {
			"name": "RISKDANGER_STATE_INSTITUTION_LABEL",
			"description": "RISKDANGER_STATE_INSTITUTION_DESCRIPTION",
			"category_id": "R-6.1",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_OTHER": {
			"name": "RISKDANGER_OTHER_LABEL",
			"description": "RISKDANGER_OTHER_DESCRIPTION",
			"category_id": "R-99.99",
			"catalogue_id": "ID_RISKCATALOGUE_GENERIC",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		// 
		// Standard data portection model
		//

		"ID_RISKDANGER_INTERNAL_WITH_HARMINTENT": {
			"name": "RISKDANGER_INTERNALPERSON_WITH_HARMINTENT_LABEL",
			"description": "RISKDANGER_INTERNALPERSON_WITH_HARMINTENT_DESCRIPTION",
			"category_id": "R-1.1",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_INTERNAL_WITHOUT_HARMINTENT": {
			"name": "RISKDANGER_INTERNALPERSON_WITHOUT_HARMINTENT_LABEL",
			"description": "RISKDANGER_INTERNALPERSON_WITHOUT_HARMINTENT_DESCRIPTION",
			"category_id": "R-1.2",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_EXTERNAL_WITH_HARMINTENT": {
			"name": "RISKDANGER_EXTERNALPERSON_WITH_HARMINTENT_LABEL",
			"description": "RISKDANGER_EXTERNALPERSON_WITH_HARMINTENT_DESCRIPTION",
			"category_id": "R-2.1",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_EXTERNAL_WITHOUT_HARMINTENT": {
			"name": "RISKDANGER_EXTERNALPERSON_WITHOUT_HARMINTENT_LABEL",
			"description": "RISKDANGER_EXTERNALPERSON_WITHOUT_HARMINTENT_DESCRIPTION",
			"category_id": "R-2.2",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_PARTNER": {
			"name": "RISKDANGER_PARTNER_LABEL",
			"description": "RISKDANGER_PARTNER_DESCRIPTION",
			"category_id": "R-3.1",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},

		"ID_RISKDANGER_INSTITUTION": {
			"name": "RISKDANGER_STATE_INSTITUTION_LABEL",
			"description": "RISKDANGER_STATE_INSTITUTION_DESCRIPTION",
			"category_id": "R-3.2",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},		

		"ID_RISKDANGER_TECHNICALFAILURE": {
			"name": "RISKDANGER_TECHNICALERROR_LABEL",
			"description": "RISKDANGER_TECHNICALERROR_DESCRIPTION",
			"category_id": "R-4.1",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},	

		"ID_RISKDANGER_ELEMENTARYNATURE": {
			"name": "RISKDANGER_NATURE_LABEL",
			"description": "RISKDANGER_NATURE_DESCRIPTION",
			"category_id": "R-5.1",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY"
			]
		},
	
		"ID_RISKDANGER_UNALLOWED_PROCESSING": {
			"name": "RISKDANGER_UNALLOWED_PROCESSING_LABEL",
			"description": "RISKDANGER_UNALLOWED_PROCESSING_DESCRIPTION",
			"category_id": "R-6.1",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_TRANSPARENCY",
				"ID_RISKBASEVALUE_INTERVENABILITY"
			]
		},

		"ID_RISKDANGER_PROCESSING_TRUST": {
			"name": "RISKDANGER_PROCESSING_TRUST_LABEL",
			"description": "RISKDANGER_PROCESSING_TRUST_DESCRIPTION",
			"category_id": "R-6.2",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_TRANSPARENCY",
				"ID_RISKBASEVALUE_INTERVENABILITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_NONCHAINEDPROCESSING",
				"ID_RISKBASEVALUE_DATAMINIMALISATION"
			]
		},	

		"ID_RISKDANGER_INTRANSPARENCY": {
			"name": "RISKDANGER_INTRANSPARENCY_LABEL",
			"description": "RISKDANGER_INTRANSPARENCY_DESCRIPTION",
			"category_id": "R-6.3",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_TRANSPARENCY",
				"ID_RISKBASEVALUE_INTERVENABILITY"
			]
		},

		"ID_RISKDANGER_CONFIDENTIALITYBREAK": {
			"name": "RISKDANGER_CONFIDENTIALITYBREAK_LABEL",
			"description": "RISKDANGER_CONFIDENTIALITYBREAK_DESCRIPTION",
			"category_id": "R-6.3",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY"
			]
		},

		"ID_RISKDANGER_INTEGRETIYBREAK": {
			"name": "RISKDANGER_INTEGRETIYBREAK_LABEL",
			"description": "RISKDANGER_INTEGRETIYBREAK_DESCRIPTION",
			"category_id": "R-6.4",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY"
			]
		},		

		"ID_RISKDANGER_REFUSALOFRIGHTS": {
			"name": "RISKDANGER_REFUSALOFRIGHTS_LABEL",
			"description": "RISKDANGER_REFUSALOFRIGHTS_DESCRIPTION",
			"category_id": "R-6.4",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_TRANSPARENCY",
				"ID_RISKBASEVALUE_INTERVENABILITY"
			]
		},

		"ID_RISKDANGER_DATA_MISUSAGE": {
			"name": "RISKDANGER_DATA_MISUSAGE_LABEL",
			"description": "RISKDANGER_DATA_MISUSAGE_DESCRIPTION",
			"category_id": "R-6.5",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_NONCHAINEDPROCESSING",
				"ID_RISKBASEVALUE_DATAMINIMALISATION"
			]
		},

		"ID_RISKDANGER_DATA_OTHERUSAGE": {
			"name": "RISKDANGER_DATA_OTHERUSAGE_LABEL",
			"description": "RISKDANGER_DATA_OTHERUSAGE_DESCRIPTION",
			"category_id": "R-6.6",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_TRANSPARENCY",
				"ID_RISKBASEVALUE_DATAMINIMALISATION"
			]
		},

		"ID_RISKDANGER_WRONGDATA_USAGE": {
			"name": "RISKDANGER_WRONGDATA_USAGE_LABEL",
			"description": "RISKDANGER_WRONGDATA_USAGE_DESCRIPTION",
			"category_id": "R-6.7",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY"
			]
		},

		"ID_RISKDANGER_EXTENTEDDATA_USAGE": {
			"name": "RISKDANGER_EXTENTEDDATA_USAGE_LABEL",
			"description": "RISKDANGER_EXTENTEDDATA_USAGE_DESCRIPTION",
			"category_id": "R-6.8",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_INTEGRITY"
			]
		},

		"ID_RISKDANGER_UNPERMITTED_PROCESSING": {
			"name": "RISKDANGER_UNPERMITTED_PROCESSING_LABEL",
			"description": "RISKDANGER_UNPERMITTED_PROCESSING_DESCRIPTION",
			"category_id": "R-6.9",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_TRANSPARENCY",
				"ID_RISKBASEVALUE_INTERVENABILITY",
				"ID_RISKBASEVALUE_NONCHAINEDPROCESSING",
				"ID_RISKBASEVALUE_DATAMINIMALISATION"
			]
		},				
		"ID_RISKDANGER_ANY": {
			"name": "RISKDANGER_OTHER_LABEL",
			"description": "RISKDANGER_OTHER_DESCRIPTION",
			"category_id": "R-99.98",
			"catalogue_id": "ID_RISKCATALOGUE_DATAPROTECTIONMODEL",
			"basevalues": [
				"ID_RISKBASEVALUE_CONFIDENTIALITY",
				"ID_RISKBASEVALUE_INTEGRITY",
				"ID_RISKBASEVALUE_AVAILIBILITY",
				"ID_RISKBASEVALUE_TRANSPARENCY",
				"ID_RISKBASEVALUE_INTERVENABILITY",
				"ID_RISKBASEVALUE_DATAMINIMALISATION",
				"ID_RISKBASEVALUE_NONCHAINEDPROCESSING"
			]
		}
};
