export const INCIDENTRISKCONSEQUENCE_I18N_KEY = "incident_riskconsequences.";

export const INCIDENTRISKCONSEQUENCE_LIST = {

    "ID_INCIDENTRISKCONSEQUENCE_DISCRIMINATION": {
      "name": "INCIDENTRISKCONSEQUENCE_DISCRIMINATION_LABEL",
      "description": "INCIDENTRISKCONSEQUENCE_DISCRIMINATION_DESCRIPTION"       
  }, 
    "ID_INCIDENTRISKCONSEQUENCE_IDENTITYTHEFTORFRAUD": {
      "name": "INCIDENTRISKCONSEQUENCE_IDENTITYTHEFTORFRAUD_LABEL", 
      "description": "INCIDENTRISKCONSEQUENCE_IDENTITYTHEFTORFRAUD_DESCRIPTION"
  }, 
    "ID_INCIDENTRISKCONSEQUENCE_DANGERTOLIFE": {
      "name": "INCIDENTRISKCONSEQUENCE_DANGERTOLIFE_LABEL", 
      "description": "INCIDENTRISKCONSEQUENCE_DANGERTOLIFE_DESCRIPTION"
  }, 
    "ID_INCIDENTRISKCONSEQUENCE_FINANCIALDAMAGES": {
      "name": "INCIDENTRISKCONSEQUENCE_FINANCIALDAMAGES_LABEL", 
      "description": "INCIDENTRISKCONSEQUENCE_FINANCIALDAMAGES_DESCRIPTION"
  }, 
    "ID_INCIDENTRISKCONSEQUENCE_REPUTATIONDAMAGE": {
      "name": "INCIDENTRISKCONSEQUENCE_REPUTATIONDAMAGE_LABEL", 
      "description": "INCIDENTRISKCONSEQUENCE_REPUTATIONDAMAGE_DESCRIPTION"
  }, 
    "ID_INCIDENTRISKCONSEQUENCE_EXISTENTIALTHREAT": {
      "name": "INCIDENTRISKCONSEQUENCE_EXISTENTIALTHREAT_LABEL", 
      "description": "INCIDENTRISKCONSEQUENCE_EXISTENTIALTHREAT_DESCRIPTION"
  }, 
    "ID_INCIDENTRISKCONSEQUENCE_EXPOSURE": {
      "name": "INCIDENTRISKCONSEQUENCE_EXPOSURE_LABEL", 
      "description": "INCIDENTRISKCONSEQUENCE_EXPOSURE_DESCRIPTION"
  }, 
    "ID_INCIDENTRISKCONSEQUENCE_LOSSOFJOB": {
      "name": "INCIDENTRISKCONSEQUENCE_LOSSOFJOB_LABEL", 
      "description": "INCIDENTRISKCONSEQUENCE_LOSSOFJOB_DESCRIPTION"
  }, 
    "ID_INCIDENTRISKCONSEQUENCE_SECRETREVELATION": {
      "name": "INCIDENTRISKCONSEQUENCE_SECRETREVELATION_LABEL", 
      "description": "INCIDENTRISKCONSEQUENCE_SECRETREVELATION_DESCRIPTION"
  }, 
    "ID_INCIDENTRISKCONSEQUENCE_UNAUTHORIZEDCANCELLATIONOFPSEUDONYMIZATION": {
      "name": "INCIDENTRISKCONSEQUENCE_UNAUTHORIZEDCANCELLATIONOFPSEUDONYMIZATION_LABEL", 
      "description": "INCIDENTRISKCONSEQUENCE_UNAUTHORIZEDCANCELLATIONOFPSEUDONYMIZATION_DESCRIPTION"
  }, 
    "ID_INCIDENTRISKCONSEQUENCE_SOCIALDISADVANTAGES": {
      "name": "INCIDENTRISKCONSEQUENCE_SOCIALDISADVANTAGES_LABEL", 
      "description": "INCIDENTRISKCONSEQUENCE_SOCIALDISADVANTAGES_DESCRIPTION"
  }, 
    "ID_INCIDENTRISKCONSEQUENCE_ECONOMICDISADVANTAGES": {
      "name": "INCIDENTRISKCONSEQUENCE_ECONOMICDISADVANTAGES_LABEL", 
      "description": "INCIDENTRISKCONSEQUENCE_ECONOMICDISADVANTAGES_DESCRIPTION"
  } 
};
  