export const REQUESTVALIDATIONMETHOD_I18N_KEY = "request_validationmethods.";

export const REQUESTVALIDATIONMETHOD_LIST = {
    "ID_REQUESTVALIDATIONMETHOD_ONLINE": {
      "name": "REQUESTVALIDATIONMETHOD_ONLINE_LABEL",
      "description": "REQUESTVALIDATIONMETHOD_ONLINE_DESCRIPTION"
    },
    "ID_REQUESTVALIDATIONMETHOD_VIDEOIDENT": {
      "name": "REQUESTVALIDATIONMETHOD_VIDEOIDENT_LABEL",
      "description": "REQUESTVALIDATIONMETHOD_VIDEOIDENT_DESCRIPTION"
    },
    "ID_REQUESTVALIDATIONMETHOD_PASSPORT": {
      "name": "REQUESTVALIDATIONMETHOD_PASSPORT_LABEL",
      "description": "REQUESTVALIDATIONMETHOD_PASSPORT_DESCRIPTION"
    },
    "ID_REQUESTVALIDATIONMETHOD_ONSITE": {
      "name": "REQUESTVALIDATIONMETHOD_ONSITE_LABEL",
      "description": "REQUESTVALIDATIONMETHOD_ONSITE_DESCRIPTION"
    },  
    "ID_REQUESTVALIDATIONMETHOD_OFFICALDOCUMENT": {
      "name": "REQUESTVALIDATIONMETHOD_OFFICALDOCUMENT_LABEL",
      "description": "REQUESTVALIDATIONMETHOD_OFFICALDOCUMENT_DESCRIPTION"
    },
    "ID_REQUESTVALIDATIONMETHOD_ALREADYKNOWN": {
      "name": "REQUESTVALIDATIONMETHOD_ALREADYKNOWN_LABEL",
      "description": "REQUESTVALIDATIONMETHOD_ALREADYKNOWN_DESCRIPTION"
    },
    "ID_REQUESTVALIDATIONMETHOD_OTHER": {
      "name": "REQUESTVALIDATIONMETHOD_OTHER_LABEL",
      "description": "REQUESTVALIDATIONMETHOD_OTHER_DESCRIPTION"
    }
};
  
export const REQUESTIDENTITYSTATE_I18N_KEY = "request_identitystates.";
export const REQUESTIDENTITYSTATE_LIST = {
    "ID_REQUESTIDENTITYSTATE_SUCCESS": {
      "name": "REQUESTIDENTITYSTATE_SUCCESS_LABEL",
      "description": "REQUESTIDENTITYSTATE_SUCCESS_DESCRIPTION",
      "icon": "mdi-check"
    },
    "ID_REQUESTIDENTITYSTATE_FAILED": {
      "name": "REQUESTIDENTITYSTATE_FAILED_LABEL",
      "description": "REQUESTIDENTITYSTATE_FAILED_DESCRIPTION",
      "icon": "mdi-alert-octagon-outline"
    },
    "ID_REQUESTIDENTITYSTATE_OTHER": {
      "name": "REQUESTIDENTITYSTATE_OTHER_LABEL",
      "description": "REQUESTIDENTITYSTATE_OTHER_DESCRIPTION",
      "icon": "mdi-information"
    }
};

export const REQUESTAUTHORIZATIONSTATE_I18N_KEY = "request_authorizationstates.";
export const REQUESTAUTHORIZATIONSTATE_LIST = {
    "ID_REQUESTAUTHORIZATIONSTATE_SUCCESS": {
      "name": "REQUESTAUTHORIZATIONSTATE_SUCCESS_LABEL",
      "description": "REQUESTAUTHORIZATIONSTATE_SUCCESS_DESCRIPTION",
      "icon": "mdi-check"
    },
    "ID_REQUESTAUTHORIZATIONSTATE_FAILED": {
      "name": "REQUESTAUTHORIZATIONSTATE_FAILED_LABEL",
      "description": "REQUESTAUTHORIZATIONSTATE_FAILED_DESCRIPTION",
      "icon": "mdi-alert-octagon-outline"
    },
    "ID_REQUESTAUTHORIZATIONSTATE_OTHER": {
      "name": "REQUESTAUTHORIZATIONSTATE_OTHER_LABEL",
      "description": "REQUESTAUTHORIZATIONSTATE_OTHER_DESCRIPTION",
      "icon": "mdi-information"
    }
};

export const REQUESTNOTIFICATIONSTATE_I18N_KEY = "request_notificationstates.";
export const REQUESTNOTIFICATIONSTATE_LIST = {
    "ID_REQUESTNOTIFICATIONSTATE_SUCCESS": {
      "name": "REQUESTNOTIFICATIONSTATE_SUCCESS_LABEL",
      "description": "REQUESTNOTIFICATIONSTATE_SUCCESS_DESCRIPTION",
      "icon": "mdi-check"
    },
    "ID_REQUESTNOTIFICATIONSTATE_FAILED": {
      "name": "REQUESTNOTIFICATIONSTATE_FAILED_LABEL",
      "description": "REQUESTNOTIFICATIONSTATE_FAILED_DESCRIPTION",
      "icon": "mdi-alert-octagon-outline"
    },
    "ID_REQUESTNOTIFICATIONSTATE_NOTNEEDED": {
      "name": "REQUESTNOTIFICATIONSTATE_NOTNEEDED_LABEL",
      "description": "REQUESTNOTIFICATIONSTATE_NOTNEEDED_DESCRIPTION",
      "icon": "mdi-octagon-outline"
    },    
    "ID_REQUESTNOTIFICATIONSTATE_OTHER": {
      "name": "REQUESTNOTIFICATIONSTATE_OTHER_LABEL",
      "description": "REQUESTNOTIFICATIONSTATE_OTHER_DESCRIPTION",
      "icon": "mdi-information"
    }
};

export const REQUESTPREPARATIONSTATE_I18N_KEY = "request_preparationstates.";
export const REQUESTPREPARATIONSTATE_LIST = {
    "ID_REQUESTPREPARATIONSTATE_SUCCESS": {
      "name": "REQUESTPREPARATIONSTATE_SUCCESS_LABEL",
      "description": "REQUESTPREPARATIONSTATE_SUCCESS_DESCRIPTION",
      "icon": "mdi-check"
    },
    "ID_REQUESTPREPARATIONSTATE_FAILED": {
      "name": "REQUESTPREPARATIONSTATE_FAILED_LABEL",
      "description": "REQUESTPREPARATIONSTATE_FAILED_DESCRIPTION",
      "icon": "mdi-alert-octagon-outline"
    },
    "ID_REQUESTPREPARATIONSTATE_NOTNEEDED": {
      "name": "REQUESTPREPARATIONSTATE_NOTNEEDED_LABEL",
      "description": "REQUESTPREPARATIONSTATE_NOTNEEDED_DESCRIPTION",
      "icon": "mdi-octagon-outline"
    },    
    "ID_REQUESTPREPARATIONSTATE_OTHER": {
      "name": "REQUESTPREPARATIONSTATE_OTHER_LABEL",
      "description": "REQUESTPREPARATIONSTATE_OTHER_DESCRIPTION",
      "icon": "mdi-information"
    }
};