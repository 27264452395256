//
// DataSubject
//
export const DATACATEGORY_RECORD_TYPE = "lookup";
export const DATACATEGORY_RECORD_SUBTYPE = "datacategory";

export const DATACATEGORY_I18N_DEFAULTLANGUAGE = "de";

export const DATACATEGORY_I18N_DISPLAY = {
  display_translation: {
    lang: "",
    name: "",
    description: ""
  }
};

export const DATACATEGORY_I18N_DATA = {
  defaultlang: DATACATEGORY_I18N_DEFAULTLANGUAGE,
  translations: {}
};

export const DATACATEGORY_RECORD = {
  datacategory_id: "",

  standard: false,
  active: true,
  type: false,

  valid: false,
  created_at: false,
  created_by: "",

  i18n: {
    ...DATACATEGORY_I18N_DATA
  }
};

