import backendDataApi from '@/services/backendDataApi.service.js';

import CommonUtils from '@/utils/common.utils.js';
import DatetimeUtils from '@/utils/datetime.utils';

import merge from "lodash.merge";

import {
  ASSET_RECORD,
  ASSET_RECORD_TYPE
}  from '@/models/asset.couchdb';

export default {
  namespaced: true,
  state: {},
  mutations: {
    ASSET_SAVED(state, payload) {},
    ASSET_REMOVED(state, payload) {},
  },
  actions: {
    //
    // private - protected action functions
    //
    async deleteAssetByAssetIdFromBackend({
      state
    }, asset_id){
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({designdoc: 'assets/list', keyid: asset_id});
    },

    //find all assets with limited content by filter in the couchdb and return them
    async readAssetOverviewlistFromBackend({
      state
    }, {startfilter, endfilter} ) {
      return await backendDataApi.readOverviewlistFromBackendByComplexFilter({designdoc: 'assets/overviewlistByStage', startkey: startfilter, endkey: endfilter});
    },
    //find a asset by id in the couchdb and return it
    async readAssetByIdFromBackend({
      state
    }, asset_id){
      return await backendDataApi.readDocumentByKeyIdFromBackend({designdoc: 'assets/list', keyid: asset_id});
    },
    //create a new asset in the couchdb and return asset_id or false
    async createAssetAndStoreInBackend({
      rootGetters,
      commit
    }, data) {
      // Make sure an asset_id is assigned on save for a new incident
      const currentdatetime = +new Date();

      // console.debug("createAssetAndStoreInBackend - data", data);

      if(CommonUtils.isStringEmpty(data.asset_id))
        data.asset_id = backendDataApi.generateNewCustomKeyId('ast');

      // Safe guard to ensure a valid id
      if(CommonUtils.isStringEmpty(data.asset_id))
        return false;

      data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
      data.created_at = currentdatetime;

     if(CommonUtils.isStringEmpty(data.changetype_id)) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_CREATED";
      }

      if(CommonUtils.isStringEmpty(data.assigned_to)) {
        data.assigned_to = rootGetters['SessionManager/getterCurrentUsername'];
      }

      const record = {
        username: rootGetters['SessionManager/getterCurrentUsername'],

        type: ASSET_RECORD_TYPE,
        keyid: data.asset_id,

        timeline: [data]
      };

      // console.debug("createAssetAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a asset in the couchdb and return asset_id or false
    async updateAssetAndStoreInBackend({
      rootGetters,
      dispatch,
      commit
    }, data) {
      try
      {
        let currentdatetime = +new Date();

        data.created_by = rootGetters['SessionManager/getterCurrentUsername'];
        data.created_at = currentdatetime;

        if(!data.changetype_id || data.changetype_id == 'ID_TIMELINE_CHANGETYPE_CREATED') {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        if(!data.assigned_to) {
          data.assigned_to = rootGetters['SessionManager/getterCurrentUsername'];
        }

        const record = await dispatch('readAssetByIdFromBackend', data.asset_id);

        // console.debug("updateAssetAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        var doc = record[0].value;

        if(doc.timeline[doc.timeline.length-1].active != data.active)
          data.changetype_id = (data.active) ? "ID_TIMELINE_CHANGETYPE_ACTIVATE" : "ID_TIMELINE_CHANGETYPE_INACTIVATE";

        // console.debug("updateAssetAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      }
      catch(err) {
        console.error("updateAssetAndStoreInBackend - Error: ", err);
        return false;
      }
    },
    //
    // Public action functions
    //
    //find a asset by asset_id in the couchdb and return it
    async actionReceiveAssetById({
      commit,
      dispatch
    }, asset_id){
      return dispatch('readAssetByIdFromBackend', asset_id).then(result => {
        if (result.length == 1)
          return {...result[0].value};
        else
          return {};
      })
      .catch(e => {
        console.error(e);
        return false;
      });
    },
    //find the proof by id in the couchdb and return the last data element
    async actionReceiveCurrentAssetDataById({
      state,
      getters,
      rootGetters,
      commit,
      dispatch
    }, asset_id) {
      try
      {
        const result = await dispatch('actionReceiveAssetById', asset_id);

        if(result == undefined || !result)
          throw "Error asset not found";

        // console.debug("actionReceiveCurrentAssetDataById result", result);

        if(!(Object.keys(result).length === 0 && result.constructor === Object))
        {
          return {
            ...result.timeline[result.timeline.length-1]
          };
        }
        else
          return {};
      }
      catch(err)
      {
        console.error("actionReceiveCurrentAssetDataById - Error : ", err);
        return false;
      }
    },
    //delete a existing asset from the couchdb
    async actionDeleteAssetById({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, asset_id) {
      try
      {
        // console.debug("actionDeleteAssetById", area_id);

        let result = await dispatch('deleteAssetByAssetIdFromBackend', asset_id);

        if(result !== false ) {
          // console.debug('actionDeleteAssetById - After Update');
          commit('ASSET_REMOVED', asset_id);

          // Delete attachments
          await dispatch('AttachmentManager/actionDeleteAttachmentsByReference', {refid: asset_id, scope: "asset"}, {root: true});
        }

        return result;
      }
      catch(err)
      {
        console.error("actionDeleteAssetById - Error : ", err);
        return false;
      }
    },
    async actionReceiveAssetOverviewAsArray({
      dispatch
    }, filterarray ) {
      try {
        var filterObj = {
          startfilter: [], endfilter: [{}]
        };

        if(Array.isArray(filterarray) && filterarray.length == 2) {
          filterObj.startfilter = [filterarray[0]];
          filterObj.endfilter = [filterarray[1], {}];
        }

        const result = await dispatch('readAssetOverviewlistFromBackend', filterObj);

        if(result.length)
          return result.map(o => o.value);
        else
          return [];
      }
      catch(err) {
        console.error("actionReceiveAssetOverviewAsArray Error: ", err);
        return false;
      }
    },
    async actionGenerateAssetReport({
      state,
      getters,
      rootGetters,
      dispatch,
      commit}, assetObj)
    {
      try {
        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "asset_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

        const expandedObj = getters.getterExpandAssetData(assetObj, printjobInfoObj.lang, printjobInfoObj.lang);

        if(expandedObj == false) {
          throw "Error preparing printing data";
        }

        printjobInfoObj.data.asset = { ...expandedObj};

        //console.debug("actionGenerateAssetReport - printjobInfoObj", printjobInfoObj);

        return await dispatch('PrintManager/actionGeneratePrintDocument', printjobInfoObj, {root:true});
      }
      catch(err) {
        console.error("actionGenerateAssetReport - Error : ", err);
        return false;
      }
    },
    //save or update a new asset into the couchdb
    async actionSaveAsset({
      state,
      getters,
      rootGetters,
      dispatch,
      commit
    }, data) {
      try
      {
        // console.debug("actionSaveAsset", data);
        let result = false;

        if(!data.asset_id || Object.prototype.hasOwnProperty.call(data, "force_create")) {
          delete data.force_create;
          // console.debug("actionSaveAsset - Create Mode", data);
          result = await dispatch('createAssetAndStoreInBackend', data);
        }
        else{
          // console.debug("actionSaveAsset - Update Mode", data);
          result = await dispatch('updateAssetAndStoreInBackend', data);
        }

        if(result == false)
          throw "Error saving Asset";

        commit("ASSET_SAVED", data);

        return result;
      }
      catch(err) {
        console.error("actionSaveAsset Error: ", err);
        return false;
      }
    },
  },
  getters: {
    getterEmptyAssetRecord: (state) => {
        return {...ASSET_RECORD};
    },
    getterIsTargetScopeEntity: (state, getters, rootState, rootGetters) => (assetObj) => {
      try {
        if (CommonUtils.isObjectEmpty(assetObj))
          return false;

        if (CommonUtils.isStringEmpty(assetObj.usage.type_id))
          return false;

        return (assetObj.usage.type_id == 'ID_ASSETTARGET_ENTITY');
      } catch (err) {
        console.error("getterIsTargetScopeEntity - Error : ", err)
        return false;
      }
    },
    getterAssetUsageText: (state, getters, rootState, rootGetters) => (type_id, target_id) => {
      return getters.getterAssetUsageTextByLc(type_id, target_id, rootGetters['TranslationManager/getterLanguageAlpha2Code']);
    },
    getterAssetUsageTextByLc: (state, getters, rootState, rootGetters) => (type_id, target_id, lc) => {
      let scopetext = rootGetters['LookupManager/getterAssetTargetsAsArrayByLc'](lc).filter(item => item.id === type_id)[0].display_name;

      try{
        if(type_id)
        {
          switch(type_id) {
            case 'ID_ASSETTARGET_ACTITIYDOC':
              scopetext = scopetext +
                          " : " +
                          rootGetters['ActivityDocManager/getterActivityDocByActivityDocId'].filter(item => item.id === target_id)[0].name;
            break;

            case 'ID_ASSETTARGET_PROCESS':
              scopetext = scopetext +
                          " : " +
                          rootGetters['ProcessManager/getterEntityProcessesAsArrayByLc'](lc).filter(item => item.id === target_id)[0].display_name;
            break;

            case 'ID_ASSETTARGET_AREA':
              scopetext = scopetext +
                          " : " +
                          rootGetters['AreaManager/getterEntityAreasAsArrayByLc'](lc).filter(item => item.id === target_id)[0].display_name;
            break;
            default:
            break;
          }
        }
      }
      catch(error)
      {
        console.error("getterAssetUsageTextByLc - Error : ", error);
        scopetext = false;
      }

      return scopetext;
    },
    getterActivityDocsByUsageAsArray: (state, getters, rootState, rootGetters) => (usageObj) => {
      var array = [];

      try {
        if (CommonUtils.isObjectEmpty(usageObj))
          return [];

        if (CommonUtils.isStringEmpty(usageObj.type_id))
          return [];

        switch(usageObj.type_id) {
          case 'ID_ASSETTARGET_AREA':
            array = rootGetters["ActivityDocManager/getterActivityDocsAsArrayByAreaId"](usageObj.target_id);
          break;
          case 'ID_ASSETTARGET_PROCESS':
            array = rootGetters["ActivityDocManager/getterActivityDocsAsArrayByProcessId"](usageObj.target_id);
          break;
          case 'ID_ASSETTARGET_ACTIVITYDOC':
            array = rootGetters["ActivityDocManager/getterActivityDocsAsArrayByActivityDocId"](usageObj.target_id);
          break;
          case 'ID_ASSETTARGET_CUSTOM':
            usageObj.target_id.forEach(el =>{
              array.push(rootGetters["ActivityDocManager/getterActivityDocByActivityDocId"](el));
            });
          break;
        }

      } catch (err) {
        console.error("getterActivityDocsByUsageAsArray - Error : ", err)
      }

      return array;
    },
    getterExpandAssetData: (state, getters, rootState, rootGetters) => (assetObj, lang, law_lang) => {
      try {
        var expandedObj = merge({}, assetObj);

        const keyid = expandedObj.asset_id;

        expandedObj.asset_id = keyid.lastIndexOf("_") > 0 ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;

        // console.debug("actionGenerateAssetReport - printjobInfoObj", printjobInfoObj);

        if(!CommonUtils.isStringEmpty(expandedObj.core.category_id))
        {
          expandedObj.core.category_display =
            rootGetters['LookupManager/getterAssetCategoriesAsArrayByLc'](lang)
              .filter(el => el.id === expandedObj.core.category_id)[0].display_name;
        }

        if(!CommonUtils.isStringEmpty(expandedObj.usage.type_id))
        {
          expandedObj.usage.usage_display = getters.getterAssetUsageTextByLc(
            expandedObj.usage.type_id,
            expandedObj.usage.target_id,
            lang
          );

          expandedObj.usage.usage_activitydocs = getters.getterActivityDocsByUsageAsArray(expandedObj.usage).map( o => ({
            display_id: CommonUtils.extractNumberFromKeyId(o.activitydoc_id),
            display_name: o.name + " (" + rootGetters["AreaManager/getterEntityAreaById"](o.area_id).display_name + ")",
            purpose: o.purpose
          }));

          expandedObj.usage.has_usage_activitydocs = !CommonUtils.isArrayEmpty(expandedObj.usage.usage_activitydocs);
        }

        expandedObj.created_at_display = DatetimeUtils.getFormatDate(expandedObj.created_at);

        return expandedObj;
      }
      catch(error)
      {
        console.error("getterExpandAssetDocData - Error:", error);
        return false;
      }
    },
  },
};