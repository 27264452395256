export const INCIDENTREASON_I18N_KEY = "incident_reasons.";

export const INCIDENTREASON_LIST = {

  "ID_INCIDENTREASON_TECHNICALERROR": {
    "name": "INCIDENTREASON_TECHNICALERROR_LABEL",
    "description": "INCIDENTREASON_TECHNICALERROR_DESCRIPTION",
    "printtext": "INCIDENTREASON_TECHNICALERROR_PRINTTEXT"
  }, 

  "ID_INCIDENTREASON_INTERNALPERSON_WITH_HARMINTENT": {
      "name": "INCIDENTREASON_INTERNALPERSON_WITH_HARMINTENT_LABEL",
      "description": "INCIDENTREASON_INTERNALPERSON_WITH_HARMINTENT_DESCRIPTION",
      "printtext": "INCIDENTREASON_INTERNALPERSON_WITH_HARMINTENT_PRINTTEXT"
  }, 

  "ID_INCIDENTREASON_INTERNALPERSON_WITHOUT_HARMINTENT": {
      "name": "INCIDENTREASON_INTERNALPERSON_WITHOUT_HARMINTENT_LABEL",
      "description": "INCIDENTREASON_INTERNALPERSON_WITH_HARMINTENT_DESCRIPTION",
      "printtext": "INCIDENTREASON_INTERNALPERSON_WITH_HARMINTENT_PRINTTEXT"
  }, 

  "ID_INCIDENTREASON_EXTERNALPERSON_WITH_HARMINTENT": {
      "name": "INCIDENTREASON_EXTERNALPERSON_WITH_HARMINTENT_LABEL",
      "description": "INCIDENTREASON_EXTERNALPERSON_WITH_HARMINTENT_DESCRIPTION",
      "printtext": "INCIDENTREASON_EXTERNALPERSON_WITH_HARMINTENT_PRINTTEXT"
  }, 

  "ID_INCIDENTREASON_EXTERNALPERSON_WITHOUT_HARMINTENT": {
      "name": "INCIDENTREASON_EXTERNALPERSON_WITHOUT_HARMINTENT_LABEL",
      "description": "INCIDENTREASON_TECHNICALERROR_DESCRIPTION",
      "printtext": "INCIDENTREASON_TECHNICALERROR_PRINTTEXT"
  }, 
  "ID_INCIDENTREASON_OTHER": {
      "name": "INCIDENTREASON_OTHER_LABEL",
      "description": "INCIDENTREASON_OTHER_DESCRIPTION",
      "printtext": "INCIDENTREASON_OTHER_PRINTTEXT"
  }  
};
  