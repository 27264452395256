export const RISKSTAGE_I18N_KEY = "risk_stages.";

export const RISKSTAGE_LIST = {
    "ID_RISKSTAGE_DRAFT": {
      "stage": 1,
      "name": "RISKSTAGE_DRAFT_LABEL",
      "description": "RISKSTAGE_DRAFT_DESCRIPTION"
    },
    "ID_RISKSTAGE_REVIEW": {
      "stage": 2,
      "name": "RISKSTAGE_REVIEW_LABEL",
      "description": "RISKSTAGE_REVIEW_DESCRIPTION"
    },
    "ID_RISKSTAGE_APPROVED": {
      "stage": 3,
      "name": "RISKSTAGE_APPROVED_LABEL",
      "description": "RISKSTAGE_APPROVED_DESCRIPTION"
    }
};
  