
export const INCIDENTNOTIFICATIONSTATE_I18N_KEY = "incident_notificationstates.";
export const INCIDENTNOTIFICATIONSTATE_LIST = {
    "ID_INCIDENTNOTIFICATIONSTATE_SUCCESS": {
      "name": "INCIDENTNOTIFICATIONSTATE_SUCCESS_LABEL",
      "description": "INCIDENTNOTIFICATIONSTATE_SUCCESS_DESCRIPTION",
      "icon": "mdi-check"
    },
    "ID_INCIDENTNOTIFICATIONSTATE_NOTNEEDED": {
      "name": "INCIDENTNOTIFICATIONSTATE_NOTNEEDED_LABEL",
      "description": "INCIDENTNOTIFICATIONSTATE_NOTNEEDED_DESCRIPTION",
      "icon": "mdi-octagon-outline"
    }
};

export const INCIDENTREPORTSTATE_I18N_KEY = "incident_reportstates.";
export const INCIDENTREPORTSTATE_LIST = {
    "ID_INCIDENTREPORTSTATE_SUCCESS": {
      "name": "INCIDENTREPORTSTATE_SUCCESS_LABEL",
      "description": "INCIDENTREPORTSTATE_SUCCESS_DESCRIPTION",
      "icon": "mdi-check"
    },
    "ID_INCIDENTREPORTSTATE_NOTNEEDED": {
      "name": "INCIDENTREPORTSTATE_NOTNEEDED_LABEL",
      "description": "INCIDENTREPORTSTATE_NOTNEEDED_DESCRIPTION",
      "icon": "mdi-octagon-outline"
    }
};