export const THRESHOLDLEVEL_I18N_KEY = "threshold_levels.";

export const THRESHOLDLEVEL_LIST = {
    "ID_THRESHOLDLEVEL_LOW": {
        "name": "THRESHOLDLEVEL_LOW_LABEL",
        "description": "THRESHOLDLEVEL_LOW_DESCRIPTION",
        "printtext": "THRESHOLDLEVEL_LOW_PRINTTEXT",
        "sequence": 10
    },
    "ID_THRESHOLDLEVEL_BLACKLIST": {
        "name": "THRESHOLDLEVEL_BLACKLIST_LABEL",
        "description": "THRESHOLDLEVEL_BLACKLIST_DESCRIPTION",
        "printtext": "THRESHOLDLEVEL_BLACKLIST_PRINTTEXT",
        "sequence": 20
    },
    "ID_THRESHOLDLEVEL_WHITELIST": {
        "name": "THRESHOLDLEVEL_WHITELIST_LABEL",
        "description": "THRESHOLDLEVEL_WHITELIST_DESCRIPTION",
        "printtext": "THRESHOLDLEVEL_WHITELIST_PRINTTEXT",
        "sequence": 25
    },
    "ID_THRESHOLDLEVEL_MEDIUM": {
        "name": "THRESHOLDLEVEL_MEDIUM_LABEL",
        "description": "THRESHOLDLEVEL_MEDIUM_DESCRIPTION",
        "printtext": "THRESHOLDLEVEL_MEDIUM_PRINTTEXT",
        "sequence": 30
    },
    "ID_THRESHOLDLEVEL_HIGH": {
        "name": "THRESHOLDLEVEL_HIGH_LABEL",
        "description": "THRESHOLDLEVEL_HIGH_DESCRIPTION",
        "printtext": "THRESHOLDLEVEL_HIGH_PRINTTEXT",
        "sequence": 40
    }
};