<template>
  <v-row justify="center">
    <v-dialog :value="show" persistent :width="width" max-width="50%">

      <v-card>
        <v-card-title class="headline">{{ title }}</v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
          class="btn-no" @click="click(false)">{{
            getButtonLabelNo
          }}</v-btn>
          <v-btn class="btn-yes" @click="click(true)">{{
            getButtonLabelYes
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  name: "ConfirmDialog",
  props: {
    show: {
      type: Boolean,
      required: true
    },
    width: {
      type: String,
      required: false,
      default: '40%'  
    },
    title:{
      required: true,
      type: String
    },
    message: {
      required: true,
      type: String
    },
    button_no: {
      required: false,
      type: String,
      default: ''
    }, 
    button_yes: {
      required: false,
      type: String,
      default: ''
    }
  },
  methods: {
    click(action) {
      this.$emit("confirm", action);
    }
  },
  computed: {
    getButtonLabelYes(){
      if(this.isStringEmpty(this.button_yes) == true){
        return this.$t("confirmdialog.label_btn_yes");
      }
      else{
        return this.button_yes;
      }
    },
    getButtonLabelNo(){
      if(this.isStringEmpty(this.button_no) == true){
        return this.$t("confirmdialog.label_btn_no");
      }
      else{
        return this.button_no;
      }
    }
  }
};
</script>
