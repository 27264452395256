export const RISKLEVEL_I18N_KEY = "risk_levels.";

export const RISKLEVELIMPACT_LIST = {
    "ID_RISKLEVELIMPACT_MINIMAL": {
      "level": 1,
      "name": "RISKLEVELIMPACT_MINIMAL_LABEL",
      "description": "RISKLEVELIMPACT_MINIMAL_DESCRIPTION"
    },
    "ID_RISKLEVELIMPACT_LOW": {
      "level": 2,
      "name": "RISKLEVELIMPACT_LOW_LABEL",
      "description": "RISKLEVELIMPACT_LOW_DESCRIPTION"
    },
    "ID_RISKLEVELIMPACT_MEDIUM": {
      "level": 3,
      "name": "RISKLEVELIMPACT_MEDIUM_LABEL",
      "description": "RISKLEVELIMPACT_MEDIUM_DESCRIPTION"
    },
    "ID_RISKLEVELIMPACT_HIGH": {
      "level": 4,
      "name": "RISKLEVELIMPACT_HIGH_LABEL",
      "description": "RISKLEVELIMPACT_HIGH_DESCRIPTION"
    },
    "ID_RISKLEVELIMPACT_CRITICAL": {
      "level": 5,
      "name": "RISKLEVELIMPACT_CRITICAL_LABEL",
      "description": "RISKLEVELIMPACT_CRITICAL_DESCRIPTION"
    }
};
  
export const RISKLEVELPROPABILITY_LIST = {
  "ID_RISKLEVELPROPABILITY_VERYLOW": {
    "level": 1,
    "name": "RISKLEVELPROPABILITY_VERYLOW_LABEL",
    "description": "RISKLEVELPROPABILITY_VERYLOW_DESCRIPTION"
  },
  "ID_RISKLEVELPROPABILITY_LOW": {
    "level": 2,
    "name": "RISKLEVELPROPABILITY_LOW_LABEL",
    "description": "RISKLEVELPROPABILITY_LOW_DESCRIPTION"
  },
  "ID_RISKLEVELPROPABILITY_MEDIUM": {
    "level": 3,
    "name": "RISKLEVELPROPABILITY_MEDIUM_LABEL",
    "description": "RISKLEVELPROPABILITY_MEDIUM_DESCRIPTION"
  },
  "ID_RISKLEVELPROPABILITY_HIGH": {
    "level": 4,
    "name": "RISKLEVELPROPABILITY_HIGH_LABEL",
    "description": "RISKLEVELPROPABILITY_HIGH_DESCRIPTION"
  },
  "ID_RISKLEVELPROPABILITY_VERYHIGH": {
    "level": 5,
    "name": "RISKLEVELPROPABILITY_VERYHIGH_LABEL",
    "description": "RISKLEVELPROPABILITY_VERYHIGH_DESCRIPTION"
  }
};

export const RISKLEVELTOTAL_LIST = {
  "ID_RISKLEVELTOTAL_LOW": {
    "min": 1,
    "max": 4,
    "name": "RISKLEVELTOTAL_LOW_LABEL",
    "description": "RISKLEVELTOTAL_LOW_DESCRIPTION"
  },
  "ID_RISKLEVELTOTAL_ACCEPTABLE": {
    "min": 5,
    "max": 9,
    "name": "RISKLEVELTOTAL_ACCEPTABLE_LABEL",
    "description": "RISKLEVELTOTAL_ACCEPTABLE_DESCRIPTION"
  },
  "ID_RISKLEVELTOTAL_INACCEPTABLE": {
    "min": 10,
    "max": 14,
    "name": "RISKLEVELTOTAL_INACCEPTABLE_LABEL",
    "description": "RISKLEVELTOTAL_INACCEPTABLE_DESCRIPTION"
  },
  "ID_RISKLEVELTOTAL_CRITICAL": {
    "min": 15,
    "max": 25,
    "name": "RISKLEVELTOTAL_CRITICAL_LABEL",
    "description": "RISKLEVELTOTAL_CRITICAL_DESCRIPTION"
  }
};
