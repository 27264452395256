export const INCIDENTSTAGE_I18N_KEY = "incident_stages.";

export const INCIDENTSTAGE_LIST = {
    "ID_INCIDENTSTAGE_RECORDED": {
      "stage": 1,
      "name": "INCIDENTSTAGE_RECORDED_LABEL",
      "description": "INCIDENTSTAGE_RECORDED_DESCRIPTION"
    },
    "ID_INCIDENTSTAGE_CHECKED": {
      "stage": 2,
      "name": "INCIDENTSTAGE_CHECKED_LABEL",
      "description": "INCIDENTSTAGE_CHECKED_DESCRIPTION"
    },
    "ID_INCIDENTSTAGE_EVALUATED": {
      "stage": 3,
      "name": "INCIDENTSTAGE_EVALUATED_LABEL",
      "description": "INCIDENTSTAGE_EVALUATED_DESCRIPTION"
    },
    "ID_INCIDENTSTAGE_PREPARED": {
      "stage": 4,
      "name": "INCIDENTSTAGE_PREPARED_LABEL",
      "description": "INCIDENTSTAGE_PREPARED_DESCRIPTION"
    },
    "ID_INCIDENTSTAGE_INFORMED": {
      "stage": 5,
      "name": "INCIDENTSTAGE_INFORMED_LABEL",
      "description": "INCIDENTSTAGE_INFORMED_DESCRIPTION"
    },
    "ID_INCIDENTSTAGE_IMPLEMENTED": {
      "stage": 6,
      "name": "INCIDENTSTAGE_IMPLEMENTED_LABEL",
      "description": "INCIDENTSTAGE_IMPLEMENTED_DESCRIPTION"
    },
    "ID_INCIDENTSTAGE_CLOSED": {
      "stage": 7,
      "name": "INCIDENTSTAGE_CLOSED_LABEL",
      "description": "INCIDENTSTAGE_CLOSED_DESCRIPTION"
    }
};
  