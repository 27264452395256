import backendDataApi from "@/services/backendDataApi.service.js";

import CommonUtils from '@/utils/common.utils.js';
import merge from "lodash.merge";

import {
  INITACTIVITYDOC_DOCUMENT_NAME,

  ACTIVITYDOC_RECORD_TYPE,
  ACTIVITYDOC_SCHEMA_VERSION,
  ACTIVITYDOC_RECORD,
  ACTIVITYDOC_DATAINPUT_RECORD,
  ACTIVITYDOC_STAGEHISTORY_RECORD,
} from "@/models/activitydoc.couchdb";

import { INITIAL_DOCUMENTATION_START_DATE } from '@/config/config.constants';

import { ACTIVITYDOCSTAGE_LIST } from "@/lookup/activitydoc_stages";

import i18n from "@/translations/index";

export default {
  namespaced: true,
  state: {
    ActivityDocList: [],

    ACTIVITYDOC_AREA_SECTOR_INIT_MAP: {},

    has_area_sector_init_map: false,
  },
  mutations: {
    ACTIVITYDOC_APPROVED(state, payload) { },
    ACTIVITYDOC_VALID(state, payload) { },
    ACTIVITYDOC_SAVED(state, payload) { },
    ACTIVITYDOC_REMOVED(state, payload) { },
    ACTIVITYDOC_CACHE_LOAD(state, payload) {
      // console.debug('ActivityDocManager - ACTIVITYDOC_CACHE_LOAD - payload', payload);
      state.ActivityDocList = [...payload];
    },
    INITACTIVITYDOC_LOAD(state, payload) {
      // console.debug("INITACTIVITYDOC_LOAD - payload", payload);
      state.ACTIVITYDOC_AREA_SECTOR_INIT_MAP = {
        ...payload.activitydoc_area_sector_init_map,
      };

      state.has_area_sector_init_map = true;
      // console.debug("INITACTIVITYDOC_LOAD - payload", state);
    },
    ACTIVITYDOC_ASSIGNED(state, payload) { },
  },
  actions: {
    // Private functions
    async deleteActivityDocByActivityDocIdFromBackend({ state }, activitydoc_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({
        designdoc: "activitydocs/list",
        keyid: activitydoc_id,
      });
    },

    //find all process activity with limited content (activitydoc_id, process_pid) in the couchdb and return them
    async readActivityDocOverviewlistFromBackend({ state }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({
        designdoc: "activitydocs/overviewlist",
        keyid: filter,
      });
    },
    //find a activitydoc by id in the couchdb and return it
    async readActivityDocByIdFromBackend({ state }, activitydoc_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({
        designdoc: "activitydocs/list",
        keyid: activitydoc_id,
      });
    },

    //find all process activity with limited content (activitydoc_id, process_id) in the couchdb and return them
    async readActivityDocDataSubjectsFromBackend({ commit }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({
        designdoc: "activitydocs/datasubjectslist",
        keyid: filter,
      });
    },

    //find all process activity with limited content (activitydoc_id, process_id) in the couchdb and return them
    async readActivityDocContractPartnerFromBackend({ commit }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({
        designdoc: "activitydocs/contractpartnerlist",
        keyid: filter,
      });
    },

    //create a new activitydoc in the couchdb and return activitydoc_id or false
    async createActivityDocAndStoreInBackend({ rootGetters, commit }, data) {
      // Make sure an activitydoc_id is assigned on save for a new activitydoc
      const currentdatetime = +new Date();

      // console.debug("createActivityDocAndStoreInBackend - data", data);

      if (!data.activitydoc_id || 0 === data.activitydoc_id.length)
        data.activitydoc_id = backendDataApi.generateNewCustomKeyId("pad");

      // Safe guard to ensure a valid id
      if (!data.activitydoc_id || 0 === data.activitydoc_id.length)
        return false;

      data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
      data.created_at = currentdatetime;
      data.revision = data.revision + 1;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_DRAFTCREATED";

        data.stagehistory.push({
          ...ACTIVITYDOC_STAGEHISTORY_RECORD,
          changetype_id: data.changetype_id,
          stage: data.stage,
          revision: data.revision,
          created_at: data.created_at,
          created_by: data.created_by,
          finished_at: +new Date(),
          finished_by: rootGetters["SessionManager/getterCurrentUsername"],
        });
      }

      if (!data.assigned_to) {
        data.assigned_to = rootGetters["SessionManager/getterCurrentUsername"];
      }

      const record = {
        type: ACTIVITYDOC_RECORD_TYPE,
        keyid: data.activitydoc_id,

        username: rootGetters["SessionManager/getterCurrentUsername"],

        timeline: [data],
      };

      // console.debug("createActivityDocAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a activitydoc in the couchdb and return activitydoc_id or false
    async updateActivityDocAndStoreInBackend(
      { rootGetters, dispatch, commit },
      data
    ) {
      try {
        let currentdatetime = +new Date();

        data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
        data.created_at = currentdatetime;

        if (!data.changetype_id || data.changetype_id == "ID_TIMELINE_CHANGETYPE_DRAFTCREATED") {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        if (!data.assigned_to) {
          data.assigned_to = rootGetters["SessionManager/getterCurrentUsername"];
        }

        const record = await dispatch(
          "readActivityDocByIdFromBackend",
          data.activitydoc_id
        );

        // console.debug("updateActivityDocAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        let doc = record[0].value;

        if (doc.timeline[doc.timeline.length - 1].active != data.active)
          data.changetype_id = data.active ? "ID_TIMELINE_CHANGETYPE_ACTIVATE" : "ID_TIMELINE_CHANGETYPE_INACTIVATE";

        // console.debug("updateActivityDocAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      } catch (err) {
        console.error("updateActivityDocAndStoreInBackend - Error: ", err);
        return false;
      }
    },

    //
    // Public actions
    //

    // load sctivitydoc init doc from couchDB
    async actionRetrieveInitActivityDoc(
      { state, rootGetters, dispatch, commit },
      forceflag
    ) {
      try {
        if (state.has_area_sector_init_map !== true || forceflag === true) {
          const doc = await backendDataApi.readDocumentByDocIdFromBackend(
            INITACTIVITYDOC_DOCUMENT_NAME
          );

          // console.debug("actionRetrieveInitActivityDoc - doc", doc);
          if (doc === false)
            throw "Error retrieving inital ActivityDoc Data";

          commit("INITACTIVITYDOC_LOAD", doc);
        }

        return true;
      } catch (err) {
        console.error("actionRetrieveInitActivityDoc - Error : ", err);
        return false;
      }
    },

    //find a process activity revision by activitydoc_id in the couchdb and return it
    async actionReceiveActivityDocById({ dispatch, commit }, activitydoc_id) {
      try {
        const result = await dispatch("readActivityDocByIdFromBackend", activitydoc_id);

        if (result.length != 1)
          throw "Reading unique activitydoc_id returns more than one record";

        // console.debug("actionReceiveRiskById - result", { ...result[0].value })

        return { ...result[0].value };
      } catch (err) {
        console.error("actionReceiveRiskById - Error: ", err);
        return false;
      }
    },
    //find a process activity revision by activitydoc_id in the couchdb and return the last data element
    async actionReceiveCurrentActivityDocDataById(
      { state, getters, rootGetters, commit, dispatch },
      activitydoc_id
    ) {
      try {
        const result = await dispatch("actionReceiveActivityDocById", activitydoc_id);

        if (result == undefined || !result)
          throw "Error activityDoc not found";

        // console.debug("actionReceiveCurrentActivityDocDataById result", result);

        if (CommonUtils.isObjectEmpty(result))
          throw "Error activitydoc record is invalid";

        return await dispatch("actionUpgradeDataObject", result.timeline[result.timeline.length - 1]);
      } catch (err) {
        console.error(
          "actionReceiveCurrentActivityDocDataById - Error : ",
          err
        );
        return false;
      }
    },
    //find all process activity with limited content in the couchdb and return them
    async actionReceiveActivityDocOverviewAsArray({ dispatch }) {
      try {
        const result = await dispatch("readActivityDocOverviewlistFromBackend");

        if (result === false) throw "Error getting list";

        if (result.length) {
          return result.map((o) => o.value);
        } else return [];
      } catch (err) {
        console.error("actionReceiveActivityDocOverviewAsArray Error: ", err);
        return false;
      }
    },
    //find all contractpartners used in activitydocs in the couchdb and return them
    async actionReceiveActivityDocContractPartnerAsArray(
      { dispatch },
      contractpartner_id
    ) {
      try {
        const result = await dispatch("readActivityDocContractPartnerFromBackend");

        if (result === false) throw "Error getting list";

        if (result.length) {
          if (!CommonUtils.isStringEmpty(contractpartner_id))
            return result
              .map((o) => o.value)
              .filter((item) =>
                item.contractpartner_ids.includes(contractpartner_id)
              );
          else
            return result.map((o) => o.value);
        }
        else
          return [];
      } catch (err) {
        console.error("actionReceiveActivityDocContractPartnerAsArray Error: ", err);
        return false;
      }
    },
    //find all data subjects in activitydocs in the couchdb and return them
    async actionReceiveActivityDocDataSubjectsAsArray(
      { dispatch },
      datasubject_id) {
      try {
        const result = await dispatch("readActivityDocDataSubjectsFromBackend");

        if (result === false)
          throw "Error getting list";

        // console.debug("actionReceiveActivityDocDataSubjectsAsArray - result", result)

        if (result.length) {
          if (!CommonUtils.isStringEmpty(datasubject_id))
            return result
              .map((o) => o.value)
              .filter((item) => item.datasubject_ids.includes(datasubject_id));
          else
            return result.map((o) => o.value);
        }
        else
          return [];
      }
      catch (error) {
        console.error("actionReceiveActivityDocDataSubjectsAsArray - Error : ", error);
        return false;
      }
    },
    async actionReceiveActivitydocsByDataCategoryId({ dispatch }, datacategory_id) {
      try {
        const result = await dispatch("readActivityDocDataSubjectsFromBackend");

        // console.debug("actionReceiveActivitydocsByDataCategoryId - result", result)

        if(result == false)
          throw "Error reading activitydoc list";

        // console.debug("actionReceiveActivitydocsByDataCategoryId - keys", result.map((o) => o.key));

        var docArray = [];

        result.forEach(object => {
          if (object.value.datacategory_ids.includes(datacategory_id)) {
            docArray.push(object.key)
          }
        });

        return docArray;
      }
      catch (error) {
        console.error("actionReceiveActivitydocsByDataCategoryId - Error : ", error);
        return [];
      }
    },
    async actionReceiveActivitydocsByArtefactId({ dispatch }, artefact_id) {
      try {
        const result = await dispatch("readActiveActivityDocArtefactsFromBackend");

        // console.debug("actionReceiveActivitydocsByArtefactId - result", result)

        if (result.length) {
          var docArray = [];
          result.forEach(object => {
            if (object.value.includes(artefact_id)) {
              docArray.push(object.key)
            }
          })
          return docArray;
        }
        else {
          return [];
        }
      }
      catch (error) {
        console.error("actionReceiveActivitydocsByArtefactId - Error : ", error);
        return false;
      }
    },
    async actionReceiveActivitydocsByDataSubjectId({ dispatch }, datasubject_id) {
      try {
        const result = await dispatch("readActivityDocDataSubjectsFromBackend");

        // console.debug("actionReceiveActivitydocsByDataSubjectId - result", result)

        if(result == false)
          throw "Error reading activitydoc list";

        // console.debug("actionReceiveActivitydocsByDataSubjectId - keys", result.map((o) => o.key));

        var docArray = [];

        result.forEach(object => {
          if (object.value.datasubject_ids.includes(datasubject_id)) {
            docArray.push(object.key)
          }
        });

        return docArray;
      }
      catch (error) {
        console.error("actionReceiveActivitydocsByDataSubjectId - Error : ", error);
        return [];
      }
    },
    //delete a existing activitydoc_id from the couchdb
    async actionDeleteActivityDocById(
      { state, getters, rootGetters, dispatch, commit },
      activitydoc_id
    ) {
      try {
        // console.debug("actionDeleteActivityDocById", activitydoc_id);

        const result = await dispatch(
          "deleteActivityDocByActivityDocIdFromBackend",
          activitydoc_id
        );

        if (result !== false) {
          await dispatch("actionRefreshActivityDocsCache");
          // console.debug('actionDeleteActivityDocById - After Update');
          commit("ACTIVITYDOC_REMOVED", activitydoc_id);

          // Delete attachments
          await dispatch(
            "AttachmentManager/actionDeleteAttachmentsByReference",
            { refid: activitydoc_id, scope: "activitydoc" },
            { root: true }
          );
        }

        return result;
      } catch (err) {
        console.error("actionDeleteActivityDocById - Error : ", err);
        return false;
      }
    },
    //save or update a new process activity into the couchdb
    async actionSaveActivityDoc(
      { state, getters, rootGetters, dispatch, commit },
      data
    ) {
      try {
        // console.debug("actionSaveActivityDoc", data);
        var result = false;

        if (!data.activitydoc_id || Object.prototype.hasOwnProperty.call(data, "force_create")) {
          delete data.force_create;

          // console.debug("actionSaveActivityDoc - Create Mode", data);
          result = await dispatch("createActivityDocAndStoreInBackend", data);

          if (result === false) throw "Error creating activitydoc";

          commit("ACTIVITYDOC_SAVED", data);

          return result;
        }

        // console.debug("actionSaveActivityDoc- Update Mode", data);

        result = await dispatch("updateActivityDocAndStoreInBackend", data);

        // console.debug('actionSaveActivityDoc - Update result', result);

        if (result == false) throw "Error saving activityDoc";

        //
        // Check contract partners in activtydoc and activate the partner if inactive
        //

        const cpresult = await dispatch( "readActivityDocContractPartnerFromBackend", data.activitydoc_id);

        if (cpresult === false) throw "Error getting contract partner list";

        // console.debug("actionSaveActivityDoc - cpresult", cpresult);

        if (cpresult.length == 1) {
          for (const partner of cpresult[0].value.contractpartner_ids) {
            if (partner && 0 !== partner.length) {
              await dispatch(
                "ContractPartnerManager/actionSetActiveStateContractPartnerById",
                { contractpartner_id: partner, newstate: true },
                { root: true }
              );
            }
          }
        }

        if (getters.getterActivityDocNeedsApproval(data))
          commit("ACTIVITYDOC_VALID", data);

        if (getters.getterActivityDocIsApproved(data)) {
          commit("ACTIVITYDOC_APPROVED", data);

          const proofresult = await dispatch("ProofManager/actionSaveProofFromActivityDocData", data, { root: true });

          if (proofresult === false)
            console.error("Error generating proof with activitydocdata - data", data);

          // console.debug("actionSaveActivityDoc - Approved ActivityDoc", data);

          const report = await dispatch("actionGenerateActivityDocReport", data);

          if (report == false)
            throw "Report Document Generation Error";

          // set attachment object
          const docobj = {
            name: i18n.t("common.process_activity_filename"),

            doccategory_id: "ID_DOCUMENTCATEGORY_PROCESS",
            doctype_id: "ID_DOCUMENTTYPE_PROCESSACTIVITY",

            filename:
              rootGetters["HelperManager/getterCurrentDateTimeCompact"] +
              "_process_activity_rev_" +
              data.revision +
              "_approved.pdf",
            mimetype: "application/pdf",
            notes: "Name : " + data.core.name + "\nAuto Generated by GDPR App",

            can_deleted: false,

            reference: {
              refid: data.activitydoc_id,
              scope: "activitydoc",
              stage: data.stage,
              revision: data.revision,
            },
          };

          // console.debug("actionSaveActivityDoc - docobj", docobj);

          await dispatch("AttachmentManager/savePrintDocumentAsAttachment", { metadata: docobj, printdata: report }, { root: true });
        }

        return result;
      } catch (err) {
        console.error("actionSaveActivityDoc Error: ", err);
        return false;
      }
    },
    // read activity array cache from the couchdb
    async actionRefreshActivityDocsCache({ getters, commit, dispatch }) {
      try {
        // console.debug("actionRefreshActivityDocsCache");

        const result = await dispatch("readActivityDocOverviewlistFromBackend");

        // console.debug("actionRefreshActivityDocsCache - result", result);

        if (result === false)
          throw "Cannot read activitydoc overviewlist from backend";

        // console.debug("actionRefreshActivityDocsCache - result", result);

        let activitydoclist = [];

        if (result.length) {
          result.forEach((item) => {
            activitydoclist.push({
              keyid: item.value.activitydoc_id,
              ...item.value,
            });
          });
        }

        // console.debug("actionRefreshActivityDocsCache - activitydoclist", activitydoclist);

        commit("ACTIVITYDOC_CACHE_LOAD", activitydoclist);

        return true;
      } catch (err) {
        console.error("actionRefreshActivityDocsCache - Error : ", err);
        return false;
      }
    },
    async actionGenerateActivityDocReport(
      { state, getters, rootGetters, dispatch, commit },
      activityDocObj
    ) {
      try {
        // console.debug("actionGenerateActivityDocReport - activityDocObj", activityDocObj);
        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "activity_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

        const expandedObj = getters.getterExpandActivityDocData(activityDocObj, printjobInfoObj.lang, printjobInfoObj.lang);

        if(expandedObj == false) {
          throw "Error preparing printing data";
        }

        printjobInfoObj.data.activitydoc = { ...expandedObj};

        // console.debug("actionGenerateActivityDocReport - printjobInfoObj", printjobInfoObj);

        return await dispatch("PrintManager/actionGeneratePrintDocument", printjobInfoObj, { root: true });
      } catch (err) {
        console.error("actionGenerateActivityDocReport", err);
        return false;
      }
    },
    //create the initial processs based on sector_process_map at the end of firstrun
    async actionCreateInitialActivityDocByArea(
      { state, getters, rootGetters, dispatch, commit },
      areaData
    ) {
      try {
        if (rootGetters["getterAutoInitWithDataMaps"] !== true)
          return true;

        // load init document
        if ((await dispatch("actionRetrieveInitActivityDoc")) == false)
          throw "Error activitydoc init data";

        const area_id = areaData.area_id;

        if (CommonUtils.isStringEmpty(area_id)) throw "Invalid area data";

        const entityObj = rootGetters["EntityManager/getterEntityData"];
        const gdprObj = rootGetters["GdprManager/getterGdprData"];

        // console.debug("actionCreateInitialActivityDocByArea - area_id", area_id);

        // console.debug("actionCreateInitialActivityDocByArea - map", state.ACTIVITYDOC_AREA_SECTOR_INIT_MAP[area_id]);

        const countrycode = gdprObj.lawbasis.countrycode;

        if (CommonUtils.isStringEmpty(countrycode))
          throw "Invalid gdprdata empty countrycode";

        const initmap = Object.prototype.hasOwnProperty.call(state.ACTIVITYDOC_AREA_SECTOR_INIT_MAP, countrycode) === true ?
          state.ACTIVITYDOC_AREA_SECTOR_INIT_MAP[countrycode] : state.ACTIVITYDOC_AREA_SECTOR_INIT_MAP.default;

        if (initmap[area_id] !== undefined) {
          var arraykey = "default";

          if (initmap[area_id][entityObj.EntityDetails.business_sector] !== undefined)
            arraykey = entityObj.EntityDetails.business_sector;
          else{
            if (initmap[area_id][entityObj.EntityInitOptions.entity_pattern] !== undefined)
              arraykey = entityObj.EntityInitOptions.entity_pattern;
          }

          // add default activitydoc from init map
          for (const template_id of initmap[area_id][arraykey]) {
            // console.debug("actionCreateInitialActivityDocByArea - template_id", template_id);

            const templatedoc = await dispatch("TemplateManager/actionReceiveTemplateById", template_id, { root: true });

            if (CommonUtils.isObjectEmpty(templatedoc)) {
              console.error("actionCreateInitialActivityDocByArea - Template not found ", template_id);
              continue;
            }

            if (Object.prototype.hasOwnProperty.call(templatedoc, "justice_areas")) {
              if (templatedoc.justice_areas.indexOf(countrycode) === -1 && templatedoc.justice_areas.indexOf("*") === -1) {
                console.warn("actionCreateInitialActivityDocByArea - Template justice_areas does not contain current entity country", template_id);
                continue;
              }
            }

            const templateObj = templatedoc.data[templatedoc.data.length - 1];

            if (templateObj === false || (Object.keys(templateObj).length === 0 && templateObj.constructor === Object)) {
              console.error("actionCreateInitialActivityDocByArea - Template not found ", template_id);
              continue;
            }

            // console.debug("actionCreateInitialActivityDocByArea - templateObj", templateObj);

            var activityDocObject = {};

            merge(activityDocObject, getters.getterEmptyActivityDocRecord, templateObj);

            activityDocObject.area_id = area_id;
            activityDocObject.process_id = rootGetters["ProcessManager/getterDefaultGenericProcessProcessIDByAreaID"](area_id);
            activityDocObject.force_create = true;
            activityDocObject.revision = 0;
            activityDocObject.changetype_id = false;

            activityDocObject.core.scope = entityObj.EntityInfo.entity_name;

            // prefill activation date
            if (Object.prototype.hasOwnProperty.call(entityObj.EntityDetails, "initialdefault_date")) {
              activityDocObject.core.activation_date = entityObj.EntityDetails.initialdefault_date;
            }
            else {
              activityDocObject.core.activation_date = INITIAL_DOCUMENTATION_START_DATE;
            }

            // Fallback to Entity data
            activityDocObject.responsible = {
              ...entityObj.Responsible,
              name: entityObj.Responsible.responsible_name,
            };

            delete activityDocObject.responsible.responsible_name;
            delete activityDocObject.regulation;

            // Transform law basis from template to document for country justice area
            activityDocObject.regulation = {};

            merge(
              activityDocObject.regulation,
              getters.getterEmptyActivityDocRecord.regulation,
              templateObj.regulation[countrycode]
            );

            // Transform template gdpr id for special German church GDPR regulation
            if (rootGetters["LawbasisGdprManager/getterHasEntityGermanSpecialChurchLawbasis"] == true) {
              activityDocObject.regulation.gdpr_law =
                rootGetters["LawbasisGdprManager/getterTransformGdprLawbasisIdArrayToCustomGdprIdArray"](templateObj.regulation[countrycode].gdpr_law);
            }

            // console.debug("actionCreateInitialProcessesByArea - activityDocObject", activityDocObject);

            if (false == await dispatch("actionSaveActivityDoc", activityDocObject)) {
              console.error("actionCreateInitialActivityDocByArea - Error saving activitydoc : ", activityDocObject);
            }
          }
        }

        await dispatch("actionRefreshActivityDocsCache");

        return true;
      } catch (err) {
        console.error("actionCreateInitialActivityDocByArea - Error : ", err);
        return false;
      }
    },
    //assign activitydoc
    async actionAssignActivitydoc(
      { state, getters, rootGetters, dispatch, commit },
      assigndataObj
    ) {
      try {
        // console.debug("actionAssignActivitydoc - assigndataObj", assigndataObj);

        var activitydocObj = await dispatch(
          "actionReceiveCurrentActivityDocDataById",
          assigndataObj.key_id
        );

        if (activitydocObj === false) throw "Error reading activitydoc by id";

        // console.debug("actionAssignActivitydoc - activitydocObj", activitydocObj);

        activitydocObj.assigned_to = assigndataObj.newassigned_to;
        activitydocObj.changenotes = assigndataObj.newnotes;
        activitydocObj.changetype_id = assigndataObj.changetype_id;

        const result = await dispatch("actionSaveActivityDoc", activitydocObj);

        if (result === false) throw "Error saving activitydoc assignto change";

        commit("ACTIVITYDOC_ASSIGNED", activitydocObj);

        // console.debug("actionAssignActivitydoc - result", result);

        return result;
      } catch (err) {
        console.error("actionAssignActivitydoc - Error : ", err);
        return false;
      }
    },
    async actionReportListAsBlobURL(
      { state, getters, rootGetters, dispatch, commit },
      payload
    ) {
      try {
        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "activity_listreport.ods",
            convertToFormat: "xlsx"
          },
          {
            root: true
          });

        var reportData = [];

        // console.debug("actionReportListAsBlobURL - payload", payload);

        if(CommonUtils.isObjectEmpty(payload) || CommonUtils.isArrayEmpty(payload.data)) {
          throw "No data provided";
        }

        for(const id of payload.data) {
          // console.debug("actionReportListAsBlobURL - id", id);

          const activtydocObj = await dispatch("actionReceiveCurrentActivityDocDataById", id);

          if(activtydocObj == false) {
            throw "Error reading data";
          }

          // console.debug("actionReportListAsBlobURL - activtydocObj", activtydocObj);

          const expandedObj = getters.getterExpandActivityDocData(activtydocObj, payload.lc, printjobInfoObj.lang);

          if(expandedObj == false) {
            throw "Error preparing data";
          }

          reportData.push(expandedObj);
        }

        // console.debug("actionReportListAsBlobURL - reportData", reportData);

        printjobInfoObj.data.content = reportData;

        const baseData =  await dispatch("PrintManager/actionGeneratePrintDocument", printjobInfoObj, { root: true });

        if(baseData == false) {
          throw "Error report base data";
        }

        // console.debug("actionReportListAsBlobURL - baseData",baseData);
        // console.debug("actionReportListAsBlobURL - baseData",baseData.printout);

        const blob = new Blob([CommonUtils.stringToBinaryArray(atob(baseData.printout))], {type: baseData.mimetype.replace(';base64','')});

        // console.debug("new Blob - blob", blob);

        // create download url
        const bloburl = window.URL.createObjectURL(blob);

        // console.debug("actionReportListAsBlobURL - bloburl", bloburl);

        if(bloburl === false)
            throw "Error reading attachment blob";

        return bloburl;
      } catch (err) {
        console.error("actionGenerateActivityDocReport", err);
        return false;
      }
    },

    // Data object upgrade function & migration based on version
    async actionUpgradeDataObject(
      {state, getters, rootGetters, commit, dispatch},
      dataObject
    ) {
      var newObj = merge({}, getters.getterEmptyActivityDocRecord, dataObject);

      try {
        // Update to current contractpartner data
        dataObject.datatransfer.jointcontrollers.forEach(async function(jtitem, index) {
          this[index] = await dispatch("ContractPartnerManager/actionRefreshContractPartnerDataByOverviewObject", jtitem, {root: true});
        }, newObj.datatransfer.jointcontrollers);

        dataObject.datatransfer.dataprocessors.forEach(async function (dpitem, index) {
          this[index] = await dispatch("ContractPartnerManager/actionRefreshContractPartnerDataByOverviewObject", dpitem, {root: true});
        }, newObj.datatransfer.dataprocessors);

        dataObject.datatransfer.external_parties.forEach(async function(epitem, index) {
          this[index] = await dispatch("ContractPartnerManager/actionRefreshContractPartnerDataByOverviewObject", epitem, {root: true});
        }, newObj.datatransfer.external_parties);

        // Safe guard unnecessary migration based on schema version
        if((Object.prototype.hasOwnProperty.call(dataObject, "version"))) {
          if(dataObject.version !== ACTIVITYDOC_SCHEMA_VERSION)
            ;
        }

        // console.debug("actionUpgradeDataObject In -  dataObject", dataObject);
        // console.debug("actionUpgradeDataObject Out - newObj", newObj);
      }
      catch(error) {
        console.error("actionUpgradeDataObject - Error", error);
      }

      return newObj;
    },
  },
  getters: {
    getterEmptyActivityDocRecord: () => {
      return { ...ACTIVITYDOC_RECORD };
    },
    getterEmptyActivityDocDataInputRecord: () => {
      return { ...ACTIVITYDOC_DATAINPUT_RECORD };
    },
    getterActivityDocStageDraft: () => {
      return ACTIVITYDOCSTAGE_LIST["ID_ACTIVITYDOCSTAGE_DRAFT"].stage;
    },
    getterActivityDocStageReview: () => {
      return ACTIVITYDOCSTAGE_LIST["ID_ACTIVITYDOCSTAGE_REVIEW"].stage;
    },
    getterActivityDocStageApproved: () => {
      return ACTIVITYDOCSTAGE_LIST["ID_ACTIVITYDOCSTAGE_APPROVED"].stage;
    },
    getterActivityDocsAsArrayByAreaId:
      (state, getters, rootState, rootGetters) => (area_id) => {
        return state.ActivityDocList.filter((item) => item.area_id === area_id);
      },
    getterActivityDocsAsArray: (state, getters, rootState, rootGetters) => {
      return state.ActivityDocList;
    },
    getterActivityDocsAsArrayByActivityDocId: (state, getters, rootState, rootGetters) => (activitydoc_id) => {
        return state.ActivityDocList.filter((item) => item.activitydoc_id === activitydoc_id);
    },
    getterActivityDocByActivityDocId: (state, getters, rootState, rootGetters) => (activitydoc_id) => {
      try {
        return state.ActivityDocList.filter((item) => item.activitydoc_id === activitydoc_id)[0];
      } catch (error) {
        return false;
      }
    },
    getterActivityDocsAsArrayByProcessId:
      (state, getters, rootState, rootGetters) => (process_id) => {
        return state.ActivityDocList.filter(
          (item) => item.process_id === process_id
        );
      },
    getterActivityDocNextStageAllowed: (state, getters, rootState, rootGetters) => (activityDocDataObj) => {
      let ballowstate = false;

      try {
        if (activityDocDataObj === undefined) return ballowstate;

        // console.debug("getterActivityDocNextStageAllowed", activityDocDataObj);

        switch (activityDocDataObj.stage) {
          case 1: // ID_ACTIVITYDOCSTAGE_DRAFT
          case 2: // ID_ACTIVITYDOCSTAGE_REVIEW
          case 3: // ID_ACTIVITYDOCSTAGE_APPROVED
            ballowstate =
              activityDocDataObj.core.valid &&
              activityDocDataObj.responsible.valid &&
              activityDocDataObj.datainput.valid &&
              activityDocDataObj.regulation.valid &&
              activityDocDataObj.datatransfer.valid &&
              activityDocDataObj.risk.valid &&
              activityDocDataObj.valid &&
              activityDocDataObj.active &&
              !activityDocDataObj.approved;
            break;
        }
      } catch (error) {
        console.error("getterActivityDocNextStageAllowed - Error : ", error);
      }

      return ballowstate;
    },
    getterActivityDocToNextStage: (state, getters, rootState, rootGetters) => (activityDocDataObj) => {
      try {
        if (activityDocDataObj === undefined) return undefined;

        activityDocDataObj.stagehistory.push({
          ...ACTIVITYDOC_STAGEHISTORY_RECORD,
          stage: activityDocDataObj.stage,
          revision: activityDocDataObj.revision,
          created_at: activityDocDataObj.updated_at,
          created_by: activityDocDataObj.updated_by,
          finished_at: +new Date(),
          finished_by: rootGetters["SessionManager/getterCurrentUsername"],
        });

        // Increase stage by one
        if (
          activityDocDataObj.stage <= getters.getterActivityDocStageReview
        ) {
          activityDocDataObj.stage++;
          activityDocDataObj.approved = false;
          activityDocDataObj.changetype_id =
            "ID_TIMELINE_CHANGETYPE_STAGECHANGED";
          activityDocDataObj.stagehistory[
            activityDocDataObj.stagehistory.length - 1
          ].changetype_id = activityDocDataObj.changetype_id;
        } else {
          if (activityDocDataObj.active) {
            activityDocDataObj.stage = getters.getterActivityDocStageApproved;
            activityDocDataObj.approved = true;
            activityDocDataObj.changetype_id =
              "ID_TIMELINE_CHANGETYPE_CLOSED";
            activityDocDataObj.stagehistory[
              activityDocDataObj.stagehistory.length - 1
            ].changetype_id = activityDocDataObj.changetype_id;
          } else {
            activityDocDataObj.stage = getters.getterActivityDocStageApproved;
            activityDocDataObj.approved = true;
            activityDocDataObj.changetype_id =
              "ID_TIMELINE_CHANGETYPE_INACTIVATE";
            activityDocDataObj.stagehistory[
              activityDocDataObj.stagehistory.length - 1
            ].changetype_id = activityDocDataObj.changetype_id;
          }
        }

        return activityDocDataObj;
      } catch (error) {
        console.error(error);
        activityDocDataObj = undefined;
      }

      return activityDocDataObj;
    },
    getterActivityDocReportData: (state, getters, rootState, rootGetters) => {
      const printjobInfoObj = {
        file: "activity_report.odt",
        convertTo: "pdf",
        lang: rootGetters["TranslationManager/lang"].lc,
      };

      return printjobInfoObj;
    },
    getterActivityTableReportData: (state, getters, rootState, rootGetters) => {
      const printjobInfoObj = {
        file: "activity_table_report.ods",
        convertTo: "xlsx",
        lang: rootGetters["TranslationManager/lang"].lc,
      };

      return printjobInfoObj;
    },
    getterActivityDocIsApproved: (state, getters, rootState, rootGetters) => (activityDocDataObj) => {
        let result = false;

        // console.debug("getterActivityDocIsApproved", activityDocDataObj);

        if (
          activityDocDataObj &&
          Object.prototype.hasOwnProperty.call(activityDocDataObj, "stage") &&
          Object.prototype.hasOwnProperty.call(activityDocDataObj, "approved")
        ) {
          if (
            activityDocDataObj.stage ===
            getters.getterActivityDocStageApproved &&
            activityDocDataObj.approved === true
          )
            result = true;
        }

        return result;
      },
    getterActivityDocLastApproved: (state, getters, rootState, rootGetters) => (activityDocObj) => {
        let activityDocDataObj = false;

        // console.debug("getterActivityDocLastApproved", activityDocObj);

        if (
          activityDocObj &&
          Object.prototype.hasOwnProperty.call(activityDocObj, "activitydocs")
        ) {
          for (let idx in activityDocObj.activitydocs) {
            if (
              getters.getterActivityDocIsApproved(
                activityDocObj.activitydocs[idx]
              )
            )
              activityDocDataObj = activityDocObj.activitydocs[idx];
          }
        }

        return activityDocDataObj;
      },
    getterActivityDocNeedsApproval: (state, getters, rootState, rootGetters) => (activityDocDataObj) => {
      let result = false;

      if (
        activityDocDataObj &&
        Object.prototype.hasOwnProperty.call(activityDocDataObj, "stage") &&
        Object.prototype.hasOwnProperty.call(activityDocDataObj, "approved")
      ) {
        if (
          activityDocDataObj.stage ===
          getters.getterActivityDocStageApproved &&
          activityDocDataObj.approved === false
        )
          result = true;
      }

      return result;
    },
    getterActivityDocEditAllowed: (state, getters, rootState, rootGetters) => (activitydocDataObj) => {
      try {
        if (
          activitydocDataObj === undefined ||
          !Object.prototype.hasOwnProperty.call(
            activitydocDataObj,
            "stage"
          ) ||
          !Object.prototype.hasOwnProperty.call(
            activitydocDataObj,
            "assigned_to"
          )
        )
          throw "Activitydoc object is undefined";

        if (
          rootGetters["SessionManager/getterCurrentUsername"] !=
          activitydocDataObj.assigned_to
        )
          return false;

        return (
          activitydocDataObj.stage <= getters.getterActivityDocStageApproved
        );
      } catch (error) {
        console.error("getterActivityDocEditAllowed - Error : ", error);
        return false;
      }
    },
    getterActivityDocDeleteAllowed: (state, getters, rootState, rootGetters) => (activitydocDataObj) => {
      try {
        if (
          activitydocDataObj === undefined ||
          !Object.prototype.hasOwnProperty.call(
            activitydocDataObj,
            "stage"
          ) ||
          !Object.prototype.hasOwnProperty.call(
            activitydocDataObj,
            "assigned_to"
          ) ||
          !Object.prototype.hasOwnProperty.call(
            activitydocDataObj,
            "revision"
          )
        )
          throw "Activitydoc object is undefined";

        if (
          rootGetters["SessionManager/getterCurrentUsername"] !=
          activitydocDataObj.assigned_to
        )
          return false;

        return (
          activitydocDataObj.stage == getters.getterActivityDocStageDraft &&
          activitydocDataObj.revision <= 1
        );
      } catch (error) {
        console.error("getterActivityDocDeleteAllowed - Error : ", error);
        return false;
      }
    },
    getterExpandActivityDocData: (state, getters, rootState, rootGetters) => (activityDocObj, lang, law_lang) => {
        try {
          var expandedObj = merge({}, activityDocObj);

          // console.debug("actionExpandActivityDocData - printjobInfoObj", printjobInfoObj);

          expandedObj.classification = 0;

          var artefacts_factor = 0;
          var data_categories_factor = 0;

          const itemfactor = 10;
          const sectionfactor = 50;
          const artefactlimit = 100;

          if (expandedObj.activitydoc_id) {
            var keyid = expandedObj.activitydoc_id;
            expandedObj.activitydoc_id =
              keyid.lastIndexOf("_") > 0 ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;
          }

          if (expandedObj.stage) {
            expandedObj.stage_display = rootGetters["LookupManager/getterActivityDocStagesAsArrayByLc"](lang)
              .filter((item) => item.stage === expandedObj.stage)[0].display_printtext;
          }

          // Area display name retrieval
          if (!CommonUtils.isStringEmpty(expandedObj.area_id)) {
            const areaObj = rootGetters["AreaManager/getterEntityAreaByIdByLc"](expandedObj.area_id, lang);

            if (areaObj !== false) {
              expandedObj.area_display = areaObj.display_name;
            }
          }

          expandedObj.hasThresholdData = false;

          // Threshold  retrieval
          if (Object.prototype.hasOwnProperty.call(expandedObj, "threshold")) {
            expandedObj.threshold.active = !CommonUtils.isArrayEmpty(expandedObj.threshold.criteria) && rootGetters["LicenseManager/getterActivityDocThresholdVisibleFeature"];

            expandedObj.threshold.criteria_display = [];

            expandedObj.threshold.criteria.forEach(function (item) {

              const thresholdObj = rootGetters["LookupManager/getterThresholdCriteriaAsArrayByLc"](lang).filter((el) => el.id === item);

              if(!CommonUtils.isObjectEmpty(thresholdObj)) {
                expandedObj.threshold.criteria_display.push({
                  id: item,
                  display_printtext: thresholdObj[0].display_name + ":\r\n" + thresholdObj[0].display_description
                });
              }
            });

            expandedObj.hasThresholdData = expandedObj.threshold.active;
          }

          // console.debug("actionExpandActivityDocData 1 - expandedObj", expandedObj);

          // Dataprocessorflag
          expandedObj.core.isDataProcessor =
            expandedObj.core.role === "ID_DATAPROCESSORROLE_DATAPROCESSOR";

          // console.debug("actionExpandActivityDocData 1 - printjobInfoObj", printjobInfoObj);

          if (expandedObj.stagehistory.length) {
            expandedObj.stagehistory.forEach(function (item) {
              if (Object.prototype.hasOwnProperty.call(item, "changetype_id")) {
                item.change_display = rootGetters["LookupManager/getterTimelineChangeTypesAsArrayByLc"](lang).filter(
                  (el) => el.id === item.changetype_id)[0].display_name;
              }
              else {
                item.change_display = rootGetters["LookupManager/getterTimelineChangeTypesAsArrayByLc"](lang).filter(
                  (el) => el.id === "ID_TIMELINE_CHANGETYPE_STAGECHANGED")[0].display_name;
              }

              item.finished_by = rootGetters["AccountManager/getterUserFullnameFromUsername"](item.finished_by);

              item.stage_display = rootGetters["LookupManager/getterActivityDocStagesAsArrayByLc"](lang).filter(
                (el) => el.stage === item.stage)[0].display_printtext;
              item.finished_at_display = rootGetters["HelperManager/getterFormatDate"](item.finished_at);
            });
          }

          // console.debug("actionExpandActivityDocData 2 - printjobInfoObj", printjobInfoObj);

          // datainput
          expandedObj.datainput.sources_display = [];

          if (expandedObj.datainput.sources.length) {
            expandedObj.datainput.sources.forEach(function (item) {
              var sourceObj = {
                ...item,
                origin: "",
                individual_category: "",
                artefacts: [],
                data_categories: [],
                data_categories_special: [],
              };

              sourceObj.origin = rootGetters["DBLookupManager/getterDataOriginsAsArrayByLc"](lang)
                  .filter((el) => el.id === item.origin)[0].display_name;

              if (item.individual_category == "ID_DATASUBJECT_OTHER" || (item.individual_category_other && item.individual_category.length == 0)) {
                sourceObj.individual_category = item.individual_category_other;
              }
              else {
                // console.debug("getterExpandActivityDocData - item", item);
                // console.debug("getterExpandActivityDocData - sourceObj", sourceObj);
                // console.debug("getterExpandActivityDocData - getterDataSubjectsAsArrayByLc", rootGetters["DataSubjectManager/getterDataSubjectsAsArrayByLc"](lang));

                if(CommonUtils.isArrayEmpty(rootGetters["DataSubjectManager/getterDataSubjectsAsArrayByLc"](lang).filter(el => el.datasubject_id === item.individual_category)))
                  console.warn("getterExpandActivityDocData - individual_category not found", item.individual_category);
                else
                  sourceObj.individual_category = rootGetters["DataSubjectManager/getterDataSubjectsAsArrayByLc"](lang)
                    .filter(el => el.id === item.individual_category)[0].display_name;
              }

              item.artefacts.forEach(function (artefact) {
                var artefactObj = rootGetters["ArtefactManager/getterArtefactsAsArrayByLc"](lang).filter((el) => el.id === artefact)[0];
                if (!CommonUtils.isObjectEmpty(artefactObj)) {
                  if(artefacts_factor < artefactlimit) artefacts_factor += ((artefactObj.protection_class-1) * itemfactor);

                  sourceObj.artefacts.push(artefactObj.display_name);
                }
              });

              item.artefacts_other.forEach(function (artefact) {
                sourceObj.artefacts.push(artefact);
              });

              item.data_categories.forEach(function (category) {
                var datacategoryObj =
                  rootGetters["DataCategoryManager/getterDataCategoriesAsArrayByLc"](lang)
                    .filter((el) => el.id === category)[0];

                // console.debug("datacategoryObj", datacategoryObj);
                if (datacategoryObj !== undefined) {
                  if (rootGetters["LawbasisGdprManager/getterIsDataCategorySpecial"](datacategoryObj) === true) {
                    sourceObj.data_categories_special.push(datacategoryObj.display_name);
                    if(data_categories_factor < sectionfactor) data_categories_factor += itemfactor;
                  }
                  else
                    sourceObj.data_categories.push(datacategoryObj.display_name);
                }
              });

              item.data_categories_other.forEach(function (category) {
                // console.debug("category", category);
                if (category !== undefined) {
                  if (rootGetters["LawbasisGdprManager/getterIsDataCategorySpecial"](category) === true) {
                    sourceObj.data_categories_special.push(category.name);
                    if(data_categories_factor < sectionfactor) data_categories_factor += itemfactor;
                  }
                  else
                    sourceObj.data_categories.push(category.name);
                }
              });

              expandedObj.datainput.sources_display.push(sourceObj);
            });
          }

          // console.debug("actionExpandActivityDocData - printjobInfoObj", printjobInfoObj);

          expandedObj.datatransfer.hasJointControllers =
            (expandedObj.datatransfer.jointcontrollers.length >= 1);

          expandedObj.datatransfer.hasDataProcessors =
            (expandedObj.datatransfer.dataprocessors.length >= 1);

          expandedObj.datatransfer.internal_areas_display = [];

          if (expandedObj.datatransfer.internal_areas.length) {
            expandedObj.datatransfer.internal_areas.forEach(item => {
              var areaObj = rootGetters["AreaManager/getterEntityAreaByIdByLc"](item, lang);

              expandedObj.datatransfer.internal_areas_display.push(areaObj.display_name);
            }
            );
          }

          expandedObj.datatransfer.external_parties_display = [];

          if (expandedObj.datatransfer.external_parties.length) {
            expandedObj.datatransfer.external_parties.forEach(item => {
              expandedObj.datatransfer.external_parties_display
                .push(item.name + " (" + item.city + ")");
            }
            );
          }

          expandedObj.regulation.gdpr_law_display = [];

          if (expandedObj.regulation.gdpr_law.length) {
            expandedObj.regulation.gdpr_law.forEach(item => {
              const law = rootGetters["LawbasisGdprManager/getterLawbasisRegulationByIdByLc"](item, law_lang);

              //console.debug("actionExpandActivityDocData - law", law);

              if (!CommonUtils.isObjectEmpty(law)) {
                expandedObj.regulation.gdpr_law_display.push(law.name + " - " + law.description);
              }
            });
          }

          expandedObj.regulation.special_law_display = [];

          if (expandedObj.regulation.special_law.length) {
            expandedObj.regulation.special_law.forEach(item => {
              const law = rootGetters["LawbasisGdprManager/getterLawbasisRegulationByIdByLc"](item, law_lang);

              //console.debug("actionExpandActivityDocData - law", law);

              if (!CommonUtils.isObjectEmpty(law)) {
                expandedObj.regulation.special_law_display.push(law.name + " - " + law.description);
              }
            }
            );
          }

          expandedObj.regulation.retention_display = [];

          if (expandedObj.regulation.retention.length) {
            expandedObj.regulation.retention.forEach(
              function (item) {
                expandedObj.regulation.retention_display.push(
                  rootGetters[
                    "LookupManager/getterRetentionRulesAsArray"
                  ].filter((el) => el.id === item)[0].printtext
                );
              }
            );
          }

          expandedObj.created_at_display =
            rootGetters["HelperManager/getterFormatDate"](expandedObj.created_at);

          // console.debug("actionExpandActivityDocData - printjobInfoObj", printjobInfoObj);
          if(artefacts_factor > artefactlimit) artefacts_factor = artefactlimit;

          expandedObj.classification += (artefacts_factor + data_categories_factor);

          expandedObj.classification += expandedObj.risk.profiling_used * sectionfactor;
          expandedObj.classification += expandedObj.risk.impact_assessment_done * sectionfactor;
          expandedObj.classification += expandedObj.risk.non_eu_destination * sectionfactor;

          return expandedObj;
        }
        catch(error)
        {
          console.error("actionExpandActivityDocData - Error:", error);
          return false;
        }
    },
    getterExpandDataInputData: (state, getters, rootState, rootGetters) => (inputObj, lang) => {
      // console.debug("getterExpandDataInputData - inputObj", inputObj);
      // console.debug("getterExpandDataInputData - lang", lang);

      try {
        var sourceObj = {
          ...inputObj,
          origin_display: "",
          individual_category_display: "",
          artefacts_display: [],
          data_categories_display: [],
          data_categories_special_display: [],
          data_subject_display: []
        };

        sourceObj.origin_display = rootGetters["DBLookupManager/getterDataOriginsAsArrayByLc"](lang)
            .filter((el) => el.id === inputObj.origin)[0].display_name;

        if (inputObj.individual_category == "ID_DATASUBJECT_OTHER" || (inputObj.individual_category_other && inputObj.individual_category.length == 0)) {
          sourceObj.individual_category_display = inputObj.individual_category_other;
        }
        else {
          sourceObj.individual_category_display = rootGetters["DataSubjectManager/getterDataSubjectsAsArrayByLc"](lang)
            .filter((el) => el.id === inputObj.individual_category)[0].display_name;
        }

        sourceObj.data_subject_display = {
          id: inputObj.individual_category,
          name: sourceObj.individual_category_display,
          description: rootGetters["DataSubjectManager/getterDataSubjectsAsArrayByLc"](lang)
                        .filter((el) => el.id === inputObj.individual_category)[0].display_description
        }

        inputObj.artefacts.forEach(function (artefact) {
          var artefactObj = rootGetters["ArtefactManager/getterArtefactsAsArrayByLc"](lang).filter((el) => el.id === artefact)[0];
          if (!CommonUtils.isObjectEmpty(artefactObj)) {
            sourceObj.artefacts_display.push(artefactObj.display_name);
          }
        });

        inputObj.artefacts_other.forEach(function (artefact) {
          sourceObj.artefacts_display.push(artefact);
        });

        inputObj.data_categories.forEach(function (category) {
          var datacategoryObj =
            rootGetters["DataCategoryManager/getterDataCategoriesAsArrayByLc"](lang)
              .filter((el) => el.id === category)[0];

          // console.debug("datacategoryObj", datacategoryObj);
          if (datacategoryObj !== undefined) {
            if (rootGetters["LawbasisGdprManager/getterIsDataCategorySpecial"](datacategoryObj) === true) {
              sourceObj.data_categories_special_display.push(datacategoryObj.display_name);
            }
            else
              sourceObj.data_categories_display.push(datacategoryObj.display_name);
          }
        });

        inputObj.data_categories_other.forEach(function (category) {
          // console.debug("category", category);
          if (category !== undefined) {
            if (rootGetters["LawbasisGdprManager/getterIsDataCategorySpecial"](category) === true) {
              sourceObj.data_categories_special_display.push(category.name);
            }
            else
              sourceObj.data_categories_display.push(category.name);
          }
        });

        return sourceObj;

      } catch (error) {
        console.error("getterExpandDataInputData - Error :", error)
        return false;
      }
    },
  },
};
