export const ACTIVITYDOCSTAGE_I18N_KEY = "activitydoc_stages.";

export const ACTIVITYDOCSTAGE_LIST = {
    "ID_ACTIVITYDOCSTAGE_DRAFT": {
      "stage": 1,
      "name": "ACTIVITYDOCSTAGE_DRAFT_LABEL",
      "description": "ACTIVITYDOCSTAGE_DRAFT_DESCRIPTION",
      "printtext": "ACTIVITYDOCSTAGE_DRAFT_PRINTTEXT"
    },
    "ID_ACTIVITYDOCSTAGE_REVIEW": {
      "stage": 2,
      "name": "ACTIVITYDOCSTAGE_REVIEW_LABEL",
      "description": "ACTIVITYDOCSTAGE_REVIEW_DESCRIPTION",
      "printtext": "ACTIVITYDOCSTAGE_REVIEW_PRINTTEXT"
    },
    "ID_ACTIVITYDOCSTAGE_APPROVED": {
      "stage": 3,
      "name": "ACTIVITYDOCSTAGE_APPROVED_LABEL",
      "description": "ACTIVITYDOCSTAGE_APPROVED_DESCRIPTION",
      "printtext": "ACTIVITYDOCSTAGE_APPROVED_PRINTTEXT"
    }
};
  