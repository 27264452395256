export const REQUESTSTAGE_I18N_KEY = "request_stages.";

export const REQUESTSTAGE_LIST = {
    "ID_REQUESTSTAGE_RECORDED": {
      "stage": 1,
      "name": "REQUESTSTAGE_RECORDED_LABEL",
      "description": "REQUESTSTAGE_RECORDED_DESCRIPTION"
    },
    "ID_REQUESTSTAGE_CHECKED": {
      "stage": 2,
      "name": "REQUESTSTAGE_CHECKED_LABEL",
      "description": "REQUESTSTAGE_CHECKED_DESCRIPTION"
    },
    "ID_REQUESTSTAGE_EVALUATED": {
      "stage": 3,
      "name": "REQUESTSTAGE_EVALUATED_LABEL",
      "description": "REQUESTSTAGE_EVALUATED_DESCRIPTION"
    },
    "ID_REQUESTSTAGE_PREPARED": {
      "stage": 4,
      "name": "REQUESTSTAGE_PREPARED_LABEL",
      "description": "REQUESTSTAGE_PREPARED_DESCRIPTION"
    },
    "ID_REQUESTSTAGE_INFORMED": {
      "stage": 5,
      "name": "REQUESTSTAGE_INFORMED_LABEL",
      "description": "REQUESTSTAGE_INFORMED_DESCRIPTION"
    },
    "ID_REQUESTSTAGE_CLOSED": {
      "stage": 6,
      "name": "REQUESTSTAGE_CLOSED_LABEL",
      "description": "REQUESTSTAGE_CLOSED_DESCRIPTION"
    }
};
  