import backendDataApi from '@/services/backendDataApi.service.js';

import {
  PROOFSTAGE_LIST,
} from '@/lookup/proof_stages';

import {
  PROOF_RECORD,
  PROOF_RECORD_TYPE,
  PROOF_STAGEHISTORY_RECORD
} from '@/config/config.couchdb';

export default {
  namespaced: true,
  state: {},
  mutations: {
    PROOF_SAVED(state, payload) { },
    PROOF_REMOVED(state, payload) { },
    PROOF_COMPLETED(state, payload) { },
    PROOF_ASSIGNED(state, payload) { },
  },
  actions: {
    // Private functions
    //
    async deleteProofByProofIdFromBackend({ state }, proof_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({
        designdoc: "proofs/list",
        keyid: proof_id,
      });
    },
    //find all proofs with limited content in the couchdb and return them
    async readProofOverviewlistFromBackend(
      { state },
      { startfilter, endfilter }
    ) {
      return await backendDataApi.readOverviewlistFromBackendByComplexFilter({
        designdoc: "proofs/overviewlistByStage",
        startkey: startfilter,
        endkey: endfilter,
      });
    },
    //find a proof by id in the couchdb and return it
    async readProofByIdFromBackend({ state }, proof_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({
        designdoc: "proofs/list",
        keyid: proof_id,
      });
    },
    //create a new proof in the couchdb and return proof_id or false
    async createProofAndStoreInBackend({ rootGetters, commit }, data) {
      // Make sure an proof_id is assigned on save for a new record
      const currentdatetime = +new Date();

      // console.debug("createProofAndStoreInBackend - data", data);

      if (!data.proof_id || data.proof_id === undefined)
        data.proof_id = backendDataApi.generateNewCustomKeyId("prf");

      // Safe guard to ensure a valid id
      if (!data.proof_id || 0 === data.proof_id.length) return false;

      data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
      data.created_at = currentdatetime;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_DRAFTCREATED";

        data.stagehistory.push({
          ...PROOF_STAGEHISTORY_RECORD,
          changetype_id: data.changetype_id,
          stage: data.stage,
          created_at: data.created_at,
          created_by: data.created_by,
          finished_at: +new Date(),
          finished_by: rootGetters["SessionManager/getterCurrentUsername"],
        });
      }

      if (!data.assigned_to) {
        data.assigned_to = rootGetters["SessionManager/getterCurrentUsername"];
      }

      const record = {
        username: rootGetters["SessionManager/getterCurrentUsername"],

        type: PROOF_RECORD_TYPE,
        keyid: data.proof_id,

        timeline: [data],
      };

      // console.debug("createProofAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a proof in the couchdb and return proof_id or false
    async updateProofAndStoreInBackend(
      { rootGetters, dispatch, commit },
      data
    ) {
      try {
        const currentdatetime = +new Date();

        data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
        data.created_at = currentdatetime;

        if (
          !data.changetype_id ||
          data.changetype_id == "ID_TIMELINE_CHANGETYPE_CREATED"
        ) {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        const record = await dispatch("readProofByIdFromBackend", data.proof_id);

        // console.debug("updateProofAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        let doc = record[0].value;

        // console.debug("updateProofAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      } catch (err) {
        console.error("updateProofAndStoreInBackend - Error: ", err);
        return false;
      }
    },

    //
    // Public actions
    //

    //save or update a new proof into the couchdb
    async actionSaveProof(
      { state, getters, rootGetters, dispatch, commit },
      data
    ) {
      try {
        // console.debug("actionSaveProof", data);
        let result = false;

        if (!data.proof_id) {
          // console.debug("actionSaveProcedure - Create Mode", data);
          result = await dispatch("createProofAndStoreInBackend", data);

          if (result === false) throw "Error creating proof";

          commit("PROOF_SAVED", data);

          return result;
        } else {
          // console.debug("actionSaveProof- Update Mode", data);
          result = await dispatch("updateProofAndStoreInBackend", data);

          if (result !== false) {
            commit("PROOF_SAVED", data);

            if (getters.getterProofIsCompleted(data)) {
              commit("PROOF_COMPLETED", data);

              if (data.core.repeating_active) {
                let proofObj = {
                  ...getters.getterEmptyProofRecord,
                  core: {
                    ...data.core,
                    start_date: rootGetters["HelperManager/getterTomorrowDate"],
                    deadline_date: rootGetters[
                      "HelperManager/getterCalculateDeadlineDate"
                    ](
                      rootGetters["HelperManager/getterTomorrowDate"],
                      data.core.timeinterval.interval,
                      data.core.timeinterval.unit
                    ),
                  },
                };

                let newproofresult = await dispatch(
                  "createProofAndStoreInBackend",
                  proofObj
                );

                if (newproofresult === false)
                  console.error(
                    "Error creating repeating new proof - proofObj",
                    proofObj
                  );

                commit("PROOF_SAVED", proofObj);
              }
            }
          }

          return result;
        }
      } catch (err) {
        console.error("actionSaveProof - Error: ", err);
        return false;
      }
    },
    //delete a existing proof from the couchdb
    async actionDeleteProofById(
      { state, getters, rootGetters, dispatch, commit },
      proof_id
    ) {
      try {
        // console.debug("actionDeleteProofById", proof_id);

        let result = await dispatch(
          "deleteProofByProofIdFromBackend",
          proof_id
        );

        if (result !== false) {
          commit("PROOF_REMOVED", proof_id);
        }

        await dispatch(
          "AttachmentManager/actionRemoveProofLinkByProofId",
          proof_id,
          { root: true }
        );
        await dispatch(
          "AttachmentManager/actionDeleteAttachmentsByReference",
          { refid: proof_id, scope: "proof" },
          { root: true }
        );

        return result;
      } catch (err) {
        console.error("actionDeleteProofById - Error : ", err);
        return false;
      }
    },
    //find a proof by proof_id in the couchdb and return it
    actionReceiveProofById({ commit, dispatch }, proof_id) {
      return dispatch("readProofByIdFromBackend", proof_id)
        .then((result) => {
          if (result.length == 1) {
            return { ...result[0].value };
          } else return {};
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
    },
    //find all proofs with limited content from the couchdb and return them
    async actionReceiveProofOverviewAsArray({ dispatch }, filterarray) {
      try {
        var filterObj = {
          startfilter: [],
          endfilter: [{}],
        };

        if (Array.isArray(filterarray) && filterarray.length == 2) {
          filterObj.startfilter = [filterarray[0]];
          filterObj.endfilter = [filterarray[1], {}];
        }

        const result = await dispatch(
          "readProofOverviewlistFromBackend",
          filterObj
        );

        if (result.length) return result.map((o) => o.value);
        else return [];
      } catch (err) {
        console.error("actionReceiveProofOverviewAsArray Error: ", err);
        return false;
      }
    },
    async actionGenerateProofReport(
      { state, getters, rootGetters, dispatch, commit },
      proofObj
    ) {
      try {
        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "proof_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

        printjobInfoObj.data.proof = { ...proofObj };

        if (printjobInfoObj.data.proof.proof_id) {
          const keyid = printjobInfoObj.data.proof.proof_id;
          printjobInfoObj.data.proof.proof_id =
            keyid.lastIndexOf("_") > 0 ? keyid.slice(keyid.lastIndexOf("_") + 1) : keyid;
        }

        // console.debug("actionGenerateProofReport - printjobInfoObj", printjobInfoObj);        

        // Type
        printjobInfoObj.data.proof.core.type_display = rootGetters[
          "LookupManager/getterProofTargetsAsArrayByLc"
        ](printjobInfoObj.lang).filter(
          (el) => el.id === printjobInfoObj.data.proof.core.type_id
        )[0].display_name;
        printjobInfoObj.data.proof.core.type_description_display = rootGetters[
          "LookupManager/getterProofTargetsAsArrayByLc"
        ](printjobInfoObj.lang).filter(
          (item) => item.id === printjobInfoObj.data.proof.core.type_id
        )[0].display_description;

        // Create data
        printjobInfoObj.data.proof.created_at_display = rootGetters[
          "HelperManager/getterFormatDate"
        ](printjobInfoObj.data.proof.created_at);
        printjobInfoObj.data.proof.core.start_date_display = rootGetters[
          "HelperManager/getterFormatDate"
        ](printjobInfoObj.data.proof.core.start_date);
        printjobInfoObj.data.proof.core.deadline_date_display = rootGetters[
          "HelperManager/getterFormatDate"
        ](printjobInfoObj.data.proof.core.deadline_date);

        // level data
        printjobInfoObj.data.proof.completeness.level_display = rootGetters[
          "LookupManager/getterProofLevelsAsArrayByLc"
        ](printjobInfoObj.lang).filter(
          (el) => el.level === printjobInfoObj.data.proof.completeness.level
        )[0].display_name;
        printjobInfoObj.data.proof.correctness.level_display = rootGetters[
          "LookupManager/getterProofLevelsAsArrayByLc"
        ](printjobInfoObj.lang).filter(
          (el) => el.level === printjobInfoObj.data.proof.correctness.level
        )[0].display_name;
        printjobInfoObj.data.proof.consistency.level_display = rootGetters[
          "LookupManager/getterProofLevelsAsArrayByLc"
        ](printjobInfoObj.lang).filter(
          (el) => el.level === printjobInfoObj.data.proof.consistency.level
        )[0].display_name;
        printjobInfoObj.data.proof.timeliness.level_display = rootGetters[
          "LookupManager/getterProofLevelsAsArrayByLc"
        ](printjobInfoObj.lang).filter(
          (el) => el.level === printjobInfoObj.data.proof.timeliness.level
        )[0].display_name;
        printjobInfoObj.data.proof.overall.level_display = rootGetters[
          "LookupManager/getterProofLevelsAsArrayByLc"
        ](printjobInfoObj.lang).filter(
          (el) => el.level === printjobInfoObj.data.proof.overall.level
        )[0].display_name;

        // Related documents array
        printjobInfoObj.data.proof.documents_display = [];

        let documentarray = await dispatch(
          "AttachmentManager/actionReadAttachmentsByReferenceAsArray",
          {
            reference: {
              scope: "proof",
              refid: printjobInfoObj.data.proof.proof_id,
            },
          },
          { root: true }
        );

        for (let index in documentarray) {
          // console.debug("documentarray - filename", documentarray[index].value.filename);
          // console.debug("documentarray - type", documentarray[index].value.doctype_id);
          let doctype = rootGetters[
            "LookupManager/getterDocumentTypesAsArrayByLc"
          ](printjobInfoObj.lang).filter(
            (el) => el.id === documentarray[index].doctype_id
          )[0].display_name;
          printjobInfoObj.data.proof.documents_display.push(
            documentarray[index].filename + " (" + doctype + ")"
          );
        }

        // Stage
        printjobInfoObj.data.proof.stage_display = rootGetters[
          "LookupManager/getterProofStagesAsArrayByLc"
        ](printjobInfoObj.lang).filter(
          (el) => el.stage === printjobInfoObj.data.proof.stage
        )[0].display_printtext;

        // History data
        if (printjobInfoObj.data.proof.stagehistory.length) {
          printjobInfoObj.data.proof.stagehistory.forEach(function (item) {
            if (Object.prototype.hasOwnProperty.call(item, "changetype_id"))
              item.change_display = rootGetters[
                "LookupManager/getterTimelineChangeTypesAsArrayByLc"
              ](printjobInfoObj.lang).filter(
                (el) => el.id === item.changetype_id
              )[0].display_name;
            else
              item.change_display = rootGetters[
                "LookupManager/getterTimelineChangeTypesAsArrayByLc"
              ](printjobInfoObj.lang).filter(
                (el) => el.id === "ID_TIMELINE_CHANGETYPE_STAGECHANGED"
              )[0].display_name;

            item.finished_by = rootGetters[
              "AccountManager/getterUserFullnameFromUsername"
            ](item.finished_by);

            item.stage_display = rootGetters[
              "LookupManager/getterProofStagesAsArrayByLc"
            ](printjobInfoObj.lang).filter(
              (el) => el.stage === item.stage
            )[0].display_printtext;
            item.finished_at_display = rootGetters[
              "HelperManager/getterFormatDate"
            ](item.finished_at);
          });
        }

        return await dispatch(
          "PrintManager/actionGeneratePrintDocument",
          printjobInfoObj,
          { root: true }
        );
      } catch (err) {
        console.error("actionGenerateProofReport - Error : ", err);
        return false;
      }
    },
    //find the proof by id in the couchdb and return the last data element
    async actionReceiveCurrentProofDataById(
      { state, getters, rootGetters, commit, dispatch },
      proof_id
    ) {
      try {
        let result = await dispatch("actionReceiveProofById", proof_id);

        if (result == undefined || !result) throw "Error proof not found";

        // console.debug("actionReceiveCurrentProofDataById result", result);

        let proofObj = {};

        if (
          !(Object.keys(result).length === 0 && result.constructor === Object)
        ) {
          proofObj = {
            ...result.timeline[result.timeline.length - 1],
          };
        }

        return proofObj;
      } catch (err) {
        console.error("actionReceiveCurrentProofDataById - Error : ", err);
        return false;
      }
    },
    // create or update proof for activitydoc
    async actionSaveProofFromActivityDocData(
      { getters, rootGetters, commit, dispatch },
      newdata
    ) {
      try {
        // console.debug("actionSaveProofFromActivityDocData - newdata", newdata);

        if (
          rootGetters["LicenseManager/getterCheckLicensedModuleProof"]() !==
          true
        ) {
          return true;
        }

        // create a document proof with link to original activitydoc as proof reference
        let deadlinedate = rootGetters[
          "HelperManager/getterCalculateDeadlineDate"
        ](rootGetters["HelperManager/getterCurrentDate"], 1, "year");

        let proofObj = {
          ...getters.getterEmptyProofRecord,
          core: {
            type_id: "ID_PROOFTARGET_ENTITYPROCESSACTIVITY",
            start_date: rootGetters["HelperManager/getterCurrentDate"],
            description:
              "Jahresregelprüfung von Verarbeitung " + newdata.core.name,
            deadline_date: deadlinedate,
            reference: {
              scope: "activitydoc",
              type: "",
              refid: newdata.activitydoc_id,
            },
            valid: true,
            timeinterval: {
              interval: 300,
              unit: "day",
              name: "TIMEINTERVAL_ONEYEAR_LABEL",
              description: "TIMEINTERVAL_ONEYEAR_DESCRIPTION",
            },
          },
        };

        let proofresult = await dispatch("actionSaveProof", proofObj);

        if (proofresult === false)
          throw "Error creating activitydoc proof in database";

        return proofresult;
      } catch (err) {
        console.error("actionSaveProofFromActivityDocData - Error: ", err);
        return false;
      }
    },
    // create or update proof for procedure
    async actionSaveProofFromProcedureData(
      { getters, rootGetters, commit, dispatch },
      newdata
    ) {
      try {
        if (
          rootGetters["LicenseManager/getterCheckLicensedModuleProof"]() !==
          true
        ) {
          return true;
        }

        // console.debug("actionSaveProofFromProcedureData - newdata", newdata);

        // create a document proof with link to original procedure as proof reference
        let deadlinedate = rootGetters[
          "HelperManager/getterCalculateDeadlineDate"
        ](rootGetters["HelperManager/getterCurrentDate"], 1, "year");

        let proofObj = {
          ...getters.getterEmptyProofRecord,
          core: {
            type_id: "ID_PROOFTARGET_ENTITYSECURITYPROCEDURE",
            start_date: rootGetters["HelperManager/getterCurrentDate"],
            description: "TOM Jahresregelprüfung",
            deadline_date: deadlinedate,
            reference: {
              scope: "procedure",
              type: "",
              refid: newdata.procedure_id,
            },
            valid: true,
            timeinterval: {
              interval: 300,
              unit: "day",
              name: "TIMEINTERVAL_ONEYEAR_LABEL",
              description: "TIMEINTERVAL_ONEYEAR_DESCRIPTION",
            },
          },
        };

        let proofresult = await dispatch("actionSaveProof", proofObj);

        if (proofresult === false)
          throw "Error creating procedure proof in database";

        return proofresult;
      } catch (err) {
        console.error("actionSaveProofFromProcedureData - Error: ", err);
        return false;
      }
    },
    // create or update proof for dsfa
    async actionSaveProofFromDsfaData(
      { getters, rootGetters, commit, dispatch },
      newdata
    ) {
      try {
        if (
          rootGetters["LicenseManager/getterCheckLicensedModuleProof"]() !==
          true
        ) {
          return true;
        }

        // console.debug("actionSaveProofFromDsfaData - newdata", newdata);

        // create a document proof with link to original dsfa as proof reference
        let deadlinedate = rootGetters[
          "HelperManager/getterCalculateDeadlineDate"
        ](rootGetters["HelperManager/getterCurrentDate"], 1, "year");

        let proofObj = {
          ...getters.getterEmptyProofRecord,
          core: {
            type_id: "ID_PROOFTARGET_ENTITYSECURITYPROCEDURE",
            start_date: rootGetters["HelperManager/getterCurrentDate"],
            description: "DSFA Jahresregelprüfung",
            deadline_date: deadlinedate,
            reference: {
              scope: "dsfa",
              type: "",
              refid: newdata.dsfa_id,
            },
            valid: true,
            timeinterval: {
              interval: 300,
              unit: "day",
              name: "TIMEINTERVAL_ONEYEAR_LABEL",
              description: "TIMEINTERVAL_ONEYEAR_DESCRIPTION",
            },
          },
        };

        let proofresult = await dispatch("actionSaveProof", proofObj);

        if (proofresult === false)
          throw "Error creating dsfa proof in database";

        return proofresult;
      } catch (err) {
        console.error("actionSaveProofFromDsfaData - Error: ", err);
        return false;
      }
    },
    // create or update proof for attachment
    async actionSaveProofFromAttachmentData(
      { getters, rootGetters, commit, dispatch },
      newdata
    ) {
      try {
        // console.debug("actionSaveProofFromAttachmentData - newdata", newdata);

        if (
          rootGetters["LicenseManager/getterCheckLicensedModuleProof"]() !==
          true
        ) {
          return true;
        }

        // create a document proof with link to original attachment as proof reference
        let deadlinedate = rootGetters[
          "HelperManager/getterCalculateDeadlineDate"
        ](
          newdata.prooflink.start_date,
          newdata.prooflink.timeinterval.interval,
          newdata.prooflink.timeinterval.unit
        );

        let proofObj = {
          ...getters.getterEmptyProofRecord,
          core: {
            type_id: "ID_PROOFTARGET_ENTITYGENERICDOCUMENT",
            start_date: newdata.prooflink.start_date,
            description: "Dokumentenprüfung von " + newdata.name,
            deadline_date: deadlinedate,
            reference: {
              scope: "attachment",
              type: "",
              refid: newdata.attachment_id,
            },
            valid: true,
            timeinterval: {
              ...newdata.prooflink.timeinterval,
            },
          },
        };

        if (
          newdata.prooflink.active == true &&
          newdata.prooflink.refid &&
          0 !== newdata.prooflink.refid.length
        )
          proofObj.proof_id = newdata.prooflink.refid;

        let proofresult = await dispatch("actionSaveProof", proofObj);

        if (proofresult === false)
          throw "Error creating attachment proof in database";

        return proofresult;
      } catch (err) {
        console.error("actionSaveProofFromAttachmentData - Error: ", err);
        return false;
      }
    },
    //assign proof
    async actionAssignProof(
      { state, getters, rootGetters, dispatch, commit },
      assigndataObj
    ) {
      try {
        // console.debug("actionAssignProof - assigndataObj", assigndataObj);
        var proofObj = await dispatch(
          "actionReceiveCurrentProofDataById",
          assigndataObj.key_id
        );

        if (proofObj === false) throw "Error reading proof by id";

        proofObj.assigned_to = assigndataObj.newassigned_to;
        proofObj.changenotes = assigndataObj.newnotes;
        proofObj.changetype_id = assigndataObj.changetype_id;

        const result = await dispatch("actionSaveProof", proofObj);

        if (result === false)
          throw "Error saving proof assignto change";

        commit("PROOF_ASSIGNED", proofObj);

        return result;
      } catch (err) {
        console.error("actionAssignProof - Error : ", err);
        return false;
      }
    },
  },
  getters: {
    getterEmptyProofRecord: (state) => {
      return { ...PROOF_RECORD };
    },
    getterProofStagePlaned: (state) => {
      return PROOFSTAGE_LIST["ID_PROOFSTAGE_PLANED"].stage;
    },
    getterProofStageCompleted: (state) => {
      return PROOFSTAGE_LIST["ID_PROOFSTAGE_COMPLETED"].stage;
    },
    getterProofStageReview: (state) => {
      return PROOFSTAGE_LIST["ID_PROOFSTAGE_REVIEW"].stage;
    },
    getterProofReportData: (state, getters, rootState, rootGetters) => {
      const printjobInfoObj = {
        file: "proof_report.odt",
        convertTo: "pdf",
        lang: rootGetters["TranslationManager/lang"].lc,
      };

      return printjobInfoObj;
    },
    getterProofNextStageAllowed:
      (state, getters, rootState, rootGetters) => (proofDataObj) => {
        let ballowstate = false;

        try {
          if (proofDataObj === undefined) return ballowstate;

          // console.debug("getterProofNextStageAllowed - proofDataObj", proofDataObj);

          switch (proofDataObj.stage) {
            case 1: // ID_PROCEDURESTAGE_DRAFT
              ballowstate = proofDataObj.core.valid;
              break;
            case 2: // ID_PROCEDURESTAGE_REVIEW
              ballowstate =
                proofDataObj.core.valid &&
                proofDataObj.completeness.valid &&
                proofDataObj.correctness.valid &&
                proofDataObj.consistency.valid &&
                proofDataObj.timeliness.valid &&
                proofDataObj.completeness.done &&
                proofDataObj.correctness.done &&
                proofDataObj.consistency.done &&
                proofDataObj.timeliness.done &&
                proofDataObj.valid;
              break;
            case 3: // ID_PROCEDURESTAGE_COMPLETED
              ballowstate =
                proofDataObj.core.valid &&
                proofDataObj.completeness.valid &&
                proofDataObj.correctness.valid &&
                proofDataObj.consistency.valid &&
                proofDataObj.timeliness.valid &&
                proofDataObj.completeness.done &&
                proofDataObj.correctness.done &&
                proofDataObj.consistency.done &&
                proofDataObj.timeliness.done &&
                proofDataObj.overall.valid &&
                proofDataObj.overall.level > 0 &&
                proofDataObj.valid;
              break;
          }
        } catch (error) {
          console.error("getterProofNextStageAllowed - Error : ", error);
        }

        // console.debug("getterProofNextStageAllowed - ballowstate", ballowstate);

        return ballowstate;
      },
    getterProofToNextStage:
      (state, getters, rootState, rootGetters) => (proofDataObj) => {
        try {
          if (proofDataObj === undefined) return undefined;

          proofDataObj.stagehistory.push({
            ...PROOF_STAGEHISTORY_RECORD,
            stage: proofDataObj.stage,
            created_at: proofDataObj.updated_at,
            created_by: proofDataObj.updated_by,
            finished_at: +new Date(),
            finished_by: rootGetters["SessionManager/getterCurrentUsername"],
          });

          // Increase stage by one
          if (proofDataObj.stage <= getters.getterProofStageReview) {
            proofDataObj.stage++;
            proofDataObj.active = true;
            proofDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_STAGECHANGED";
            proofDataObj.stagehistory[
              proofDataObj.stagehistory.length - 1
            ].changetype_id = proofDataObj.changetype_id;
          } else {
            proofDataObj.stage = getters.getterProofStageCompleted;
            proofDataObj.active = false;
            proofDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_CLOSED";
            proofDataObj.stagehistory[
              proofDataObj.stagehistory.length - 1
            ].changetype_id = proofDataObj.changetype_id;
          }

          return proofDataObj;
        } catch (error) {
          console.error("getterProofToNextStage - ereor : ", error);
          proofDataObj = undefined;
        }

        return proofDataObj;
      },
    getterProofIsCompleted:
      (state, getters, rootState, rootGetters) => (proofDataObj) => {
        let result = false;

        // console.debug("getterActivityDocIsApproved", activityDocDataObj);

        if (
          proofDataObj &&
          Object.prototype.hasOwnProperty.call(proofDataObj, "stage") &&
          Object.prototype.hasOwnProperty.call(proofDataObj, "active")
        ) {
          if (
            proofDataObj.stage === getters.getterProofStageCompleted &&
            proofDataObj.active === false
          )
            result = true;
        }

        return result;
      },
    getterProofLastCompleted:
      (state, getters, rootState, rootGetters) => (proofObj) => {
        let proofDataObj = false;

        // console.debug("getterProofLastCompleted", proofObj);

        if (
          proofObj &&
          Object.prototype.hasOwnProperty.call(proofObj, "proofs")
        ) {
          for (let idx in proofObj.proofs) {
            if (getters.getterProofIsCompleted(proofObj.timeline[idx]))
              proofDataObj = proofObj.timeline[idx];
          }
        }

        return proofDataObj;
      },
    getterProofNeedsComplete:
      (state, getters, rootState, rootGetters) => (proofDataObj) => {
        let result = false;

        if (
          proofDataObj &&
          Object.prototype.hasOwnProperty.call(proofDataObj, "stage") &&
          Object.prototype.hasOwnProperty.call(proofDataObj, "active")
        ) {
          if (
            proofDataObj.stage === getters.getterProofStageCompleted &&
            proofDataObj.active === true
          )
            result = true;
        }

        return result;
      },
    getterProofEditAllowed:
      (state, getters, rootState, rootGetters) => (proofDataObj) => {
        try {
          if (
            proofDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(proofDataObj, "stage") ||
            !Object.prototype.hasOwnProperty.call(proofDataObj, "assigned_to")
          )
            throw "Proof object is undefined";

          if (rootGetters["SessionManager/getterCurrentUsername"] != proofDataObj.assigned_to)
            return false;

          return (
            proofDataObj.stage <= getters.getterProofStageCompleted &&
            proofDataObj.active
          );
        } catch (error) {
          console.error("getterProofEditAllowed - Error : ", error);
          return false;
        }
      },
    getterProofDeleteAllowed:
      (state, getters, rootState, rootGetters) => (proofDataObj) => {
        try {
          if (
            proofDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(proofDataObj, "stage") ||
            !Object.prototype.hasOwnProperty.call(proofDataObj, "assigned_to")
          )
            throw "Proof object is undefined";

          if (rootGetters["SessionManager/getterCurrentUsername"] != proofDataObj.assigned_to)
            return false;

          return (proofDataObj.stage == getters.getterProofStagePlaned);
        } catch (error) {
          console.error("getterProofDeleteAllowed - Error : ", error);
          return false;
        }
      },
  },
};