//
// Areas
//

import { CONTACT_DATA, ADDRESS_DATA } from "../config/config.couchdb";

export const ENTITYAREA_DOCUMENT_NAME = 'entity_areas';

export const AREA_RECORD_TYPE = "area";
export const AREA_RECORD_SUBTYPE = "entity";

export const AREA_RECORD = {
  area_id: false,

  is_custom: false,

  changetype_id: '',

  core: {
    name: '',
    description: '',
    notes: '',

    i18n_overwrites: {},

    valid: false
  },

  responsible: {
    contact_id: false,

    name: '',
    role_id: '',

    location_id: false,

    ...ADDRESS_DATA,
    ...CONTACT_DATA,

    valid: false
  },

  valid: false,

  created_at: false,
  created_by: "",
};
