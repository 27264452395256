import {
  DEFAULT_LANGUAGE
} from '@/config/config.locales';

import {
  APP_CONFIG_RECORD
} from '@/config/config.constants.js';

const getDefaultState = () => {
  return {
    Application: {
      ...APP_CONFIG_RECORD
    },

    TranslationManager: {
      "lang": DEFAULT_LANGUAGE,
      "valid": false
    },
  };
};

export default {
  VUEX_LOAD(state, payload) {
    const is_loading = state.Application.Runtime.is_loading;

    state.Application = {
      ...getDefaultState().Application,
      ...payload.Application,
    };

    state.Application.Runtime.is_loading = is_loading;
  },
  SET_FIRST_RUN(state, payload) {
    state.Application.is_first_run = payload.is_first_run;
    state.Application.first_run_timestamp = payload.first_run_timestamp;
  },
  SET_INITIAL_DATA_COMPLETE(state, payload) {
    state.Application.is_initial_data_complete = payload.is_initial_data_complete;
    state.Application.initial_data_timestamp = payload.initial_data_timestamp;
  },
  SET_DARK_THEME_MODE(state, payload) {
    state.Application.is_dark_theme = payload;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_ALERT_MESSAGE(state, payload) {
    state.Application.Runtime.alert_message = { ...payload };
  },
  SET_MESSAGE_OK_DIALOG(state, payload) {
    state.Application.Runtime.message_ok_dialog = { ...payload };
  },
  SET_MESSAGE_CONFIRM_DIALOG(state, payload) {
    state.Application.Runtime.message_confirm_dialog = { ...payload };
  },
  SET_IN_PROGRESS_DIALOG(state, payload) {
    state.Application.Runtime.progress_dialog = { ...payload };
  },
  SET_LOADING_STATE(state, payload) {
    state.Application.Runtime.is_loading = payload;
  },
  SET_IS_BACKEND_ONLINE(state, payload) {
    state.Application.Runtime.is_backend_online = payload;
  },
  SET_IGNORELICENCEGRACEPERIOD_STATE(state, payload) {
    state.Application.Runtime.is_ignore_license_grace_period = payload;
  },
  SET_AUTOINITWITHDATAMAPS_STATE(state, payload) {
    state.Application.Runtime.auto_init_with_datamaps = payload;
  },
  SET_BREADCRUMBS_STATE(state, payload) {
    state.Application.Runtime.breadcrumbs = this.state.Application.Runtime.breadcrumbs.slice(0, 1);

    if (payload.component !== 'Dashboard') {
      state.Application.Runtime.breadcrumbs.push(payload);
    }
  },
  SET_ACTIVE_COMPONENTNAME(state, payload) {
    state.Application.Runtime.active_component = payload;
  },
  SET_CONTENTCOMPONENT_STATE(state, payload) {
    state.Application.Runtime.contentComponent = { ...payload };
  }
};
