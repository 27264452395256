import backendDataApi from '@/services/backendDataApi.service.js';

import CommonUtils from '@/utils/common.utils';
import DatetimeUtils from '@/utils/datetime.utils';

import {
  DSFA_RECORD,
  DSFA_RECORD_TYPE,
  DSFA_STAGEHISTORY_RECORD
} from '@/models/dsfa.couchdb';

import {
  DSFASTAGE_LIST
} from '@/lookup/dsfa_stages';

import i18n from '@/translations';

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    DSFA_APPROVED(state, payload) { },
    DSFA_VALID(state, payload) { },
    DSFA_SAVED(state, payload) { },
    DSFA_REMOVED(state, payload) { },
    DSFA_ASSIGNED(state, payload) { },
  },
  actions: {
    //
    // private - protected action functions
    //
    async deleteDsfaByDsfaIdFromBackend({ state }, dsfa_id) {
      return await backendDataApi.deleteDocumentByKeyIdFromBackend({
        designdoc: "dsfas/list",
        keyid: dsfa_id,
      });
    },
    //find all process activity with limited content (activitydoc_id, process_pid) in the couchdb and return them
    async readDsfaOverviewlistFromBackend({ state }, filter) {
      return await backendDataApi.readOverviewlistFromBackend({
        designdoc: "dsfas/overviewlist",
        keyid: filter,
      });
    },
    //find a dsfa by id in the couchdb and return it
    async readDsfaByIdFromBackend({ state }, dsfa_id) {
      return await backendDataApi.readDocumentByKeyIdFromBackend({
        designdoc: "dsfas/list",
        keyid: dsfa_id,
      });
    },
    //create a new dsfa in the couchdb and return dsfa_id or false
    async createDsfaAndStoreInBackend({ rootGetters, commit }, data) {
      // Make sure an dsfa_id is assigned on save for a new record
      let currentdatetime = +new Date();

      // console.debug("createDsfaAndStoreInBackend - data", data);

      if (!data.dsfa_id || data.dsfa_id === undefined)
        data.dsfa_id = backendDataApi.generateNewCustomKeyId("dfa");

      // Safe guard to ensure a valid id
      if (!data.dsfa_id || 0 === data.dsfa_id.length) return false;

      data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
      data.created_at = currentdatetime;
      data.revision = data.revision + 1;

      if (!data.changetype_id) {
        data.changetype_id = "ID_TIMELINE_CHANGETYPE_DRAFTCREATED";

        data.stagehistory.push({
          ...DSFA_STAGEHISTORY_RECORD,
          changetype_id: data.changetype_id,
          stage: data.stage,
          revision: data.revision,
          created_at: data.created_at,
          created_by: data.created_by,
          finished_at: +new Date(),
          finished_by: rootGetters["SessionManager/getterCurrentUsername"],
        });
      }

      if (!data.assigned_to) {
        data.assigned_to = rootGetters["SessionManager/getterCurrentUsername"];
      }

      const record = {
        username: rootGetters["SessionManager/getterCurrentUsername"],

        type: DSFA_RECORD_TYPE,
        keyid: data.dsfa_id,

        timeline: [data],
      };

      // console.debug("createDsfaAndStoreInBackend - Create Mode", record);

      return await backendDataApi.createDocumentInBackend(record);
    },
    //update a dsfa in the couchdb and return dsfa_id or false
    async updateDsfaAndStoreInBackend(
      { rootGetters, dispatch, commit },
      data
    ) {
      try {
        let currentdatetime = +new Date();

        data.created_by = rootGetters["SessionManager/getterCurrentUsername"];
        data.created_at = currentdatetime;

        if (
          !data.changetype_id ||
          data.changetype_id == "ID_TIMELINE_CHANGETYPE_CREATED"
        ) {
          data.changetype_id = "ID_TIMELINE_CHANGETYPE_CONTENTCHANGED";
        }

        const record = await dispatch("readDsfaByIdFromBackend", data.dsfa_id);

        // console.debug("updateDsfaAndStoreInBackend- Update Mode Result", record);

        if (record.length !== 1) {
          return false;
        }

        var doc = record[0].value;

        // console.debug("updateDsfaAndStoreInBackend - Update Mode DOC", doc);

        doc.timeline.push(data);

        return await backendDataApi.updateDocumentInBackend(doc);
      } catch (err) {
        console.error("updateDsfaAndStoreInBackend - Error: ", err);
        return false;
      }
    },

    //
    // Public actions
    //

    //find and return all dsfas in the couchdb
    actionReceiveDsfaOverviewAsArray({ dispatch }) {
      return dispatch("readDsfaOverviewlistFromBackend").then((result) => {
        let array = [];

        if (result.length) {
          result.forEach((item) => {
            array.push(item.value);
          });
        }

        return array;
      });
    },
    //save or update a new dsfa into the couchdb
    async actionSaveDsfa(
      { state, getters, rootGetters, dispatch, commit },
      data
    ) {
      try {
        // console.debug("actionSaveDsfa", data);
        var result = false;

        if (!data.dsfa_id) {
          // console.debug("actionSaveDsfa - Create Mode", data);
          result = await dispatch("createDsfaAndStoreInBackend", data);

          if (result === false) throw "Error creating dsfa";

          commit("DSFA_SAVED", data);

          return result;
        }

        if (getters.getterDsfaIsApproved(data)) {
          commit("DSFA_APPROVED", data);

          const ziparchiv = await dispatch("actionGenerateDsfaAsZipArchiveAsBlob", data);

          if (ziparchiv == false) {
              throw "Dsfa Zip Archive Document Generation Error";
          }

          // set attachment object
          const docobj = {
            name: i18n.t("common.dsfa") + "_v" + data.revision,
            doccategory_id: "ID_DOCUMENTCATEGORY_DSFA",
            doctype_id: "ID_DOCUMENTTYPE_DSFA",

            filename: rootGetters["HelperManager/getterCurrentDateTimeCompact"] + "_dsfa_rev_" + data.revision + "_approved.zip",
            mimetype: "application/zip",
            notes: "Auto Generated by GDPR App",

            can_deleted: false,

            reference: {
              refid: data.dsfa_id,
              scope: "dsfa",
              stage: data.stage,
              revision: data.revision,
            },
          };

          result = await dispatch("AttachmentManager/actionSaveAttachmentBlob", { metadata: docobj, blob: ziparchiv }, { root: true });

          if (result == false) throw "Error creating dsfa archive document";
        }

        // console.debug("actionSaveDsfa- Update Mode", data);

        result = await dispatch("updateDsfaAndStoreInBackend", data);

        // console.debug('actionSaveDsfa - Update result', result);

        if (result == false) throw "Error saving dsfa update";

        commit("DSFA_SAVED", data);

        if (getters.getterDsfaNeedsApproval(data))
          commit("DSFA_VALID", data);

        return result;
      } catch (err) {
        console.error("actionSaveDsfa Error: ", err);
        return false;
      }
    },
    actionReceiveDsfaById({ commit, dispatch }, dsfa_id) {
      // console.debug('actionReceiveDsfaById - dsfa_id', dsfa_id);

      return dispatch("readDsfaByIdFromBackend", dsfa_id)
        .then((result) => {
          if (result.length == 1)
            return { ...result[0].value };
          else
            return {};
        })
        .catch((e) => {
          console.error(e);
          return false;
        });
    },
    //find a dsfa revision by activitydoc_id in the couchdb and return the last data element
    async actionReceiveCurrentDsfaDataById(
      { state, getters, rootGetters, commit, dispatch },
      dsfa_id
    ) {
      try {
        let result = await dispatch("actionReceiveDsfaById", dsfa_id);

        if (result == undefined || !result) throw "Error dsfa not found";

        // console.debug("actionReceiveCurrentDsfaDataById result", result);

        let dsfaObj = {};

        if (
          !(Object.keys(result).length === 0 && result.constructor === Object)
        ) {
          dsfaObj = {
            ...result.timeline[result.timeline.length - 1],
          };
        }

        return dsfaObj;
      } catch (err) {
        console.error("actionReceiveCurrentDsfaDataById - Error : ", err);
        return false;
      }
    },
    //delete a incident from the couchdb
    async actionDeleteDsfaById(
      { state, getters, rootGetters, dispatch, commit },
      dsfa_id
    ) {
      try {
        // console.debug("actionDeleteDsfaById", activitydoc_id);

        let result = await dispatch(
          "deleteDsfaByDsfaIdFromBackend",
          dsfa_id
        );

        if (result !== false) {
          // console.debug('actionDeleteDsfaById - After Update');
          commit("DSFA_REMOVED", dsfa_id);

          // Delete attachments
          await dispatch(
            "AttachmentManager/actionDeleteAttachmentsByReference",
            { refid: dsfa_id, scope: "dsfa" },
            { root: true }
          );
        }

        return result;
      } catch (err) {
        console.error("actionDeleteDsfaById - Error : ", err);
        return false;
      }
    },
    async actionGenerateDsfaAsZipArchive(
      { state, getters, rootGetters, dispatch, commit },
      dsfaObj
    ) {
      try {
        const blob = await dispatch("actionGenerateDsfaAsZipArchiveAsBlob", dsfaObj);

        return URL.createObjectURL(blob);
      } catch (err) {
        console.error("actionGenerateDsfaAsZip", err);
        return false;
      }
    },
    async actionGenerateDsfaAsZipArchiveAsBlob(
      { state, getters, rootGetters, dispatch, commit },
      dsfaObj
    ) {
      try {
        // Get all related attachments
        const refobj = {
          reference:{
            refid: dsfaObj.dsfa_id,
            scope: "dsfa"
          }
        };

        var currentDate = new Date();

        var zipcontent = [];
        var contentText = [];

        const dsfareport = await dispatch("actionGenerateDsfaReport", dsfaObj);

        // Dsfa Report
        zipcontent.push({
            name: "dsfa_report_"+dsfaObj.dsfa_id+".pdf",
            lastModified: currentDate,
            input: CommonUtils.getFileBlobFromBase64String(dsfareport.printout, "application/pdf" )
        });

        contentText.push(
          "dsfa_report_"+dsfaObj.dsfa_id+".pdf;"+
          dsfaObj.stage+";"+
          DatetimeUtils.getFormatDateTime(currentDate)+";"+
          rootGetters["SessionManager/getterCurrentUsername"]+
          ";Dsfa Report");

        // ActivityDocs Report
        for(const item of getters.getterActivityDocsByScopeAsArray(dsfaObj.core.type_id, dsfaObj.core.target_id)) {
          const activityObj = await dispatch("ActivityDocManager/actionReceiveCurrentActivityDocDataById", item.activitydoc_id, {root: true});

          if(!CommonUtils.isObjectEmpty(activityObj)) {
            const activitydocreport = await dispatch("ActivityDocManager/actionGenerateActivityDocReport", activityObj, {root: true});
            if(!CommonUtils.isObjectEmpty(activitydocreport)) {
              zipcontent.push({
                name: "activity_process_report_"+activityObj.activitydoc_id+".pdf",
                lastModified: currentDate,
                input: CommonUtils.getFileBlobFromBase64String(activitydocreport.printout, "application/pdf" )
              });

              contentText.push(
                "activity_process_report_"+activityObj.activitydoc_id+".pdf;" +
                activityObj.stage+";"+
                DatetimeUtils.getFormatDateTime(currentDate)+";"+
                rootGetters["SessionManager/getterCurrentUsername"]+
                ";Process Activity Report");
            }
          }
        }

        // Risk Report SDM
        for(const item of dsfaObj.affectedgroups.risk_ids) {
          const riskObj = await dispatch("RiskManager/actionReceiveCurrentRiskDataById", item, {root: true});

          if(!CommonUtils.isObjectEmpty(riskObj)) {
            const riskreport = await dispatch("RiskManager/actionGenerateRiskReport", riskObj, {root: true});
            if(!CommonUtils.isObjectEmpty(riskObj)) {
              zipcontent.push({
                name: "risk_report_"+riskObj.risk_id+".pdf",
                lastModified: currentDate,
                input: CommonUtils.getFileBlobFromBase64String(riskreport.printout, "application/pdf" )
              });

              contentText.push(
                "risk_report_"+riskObj.risk_id+".pdf;" +
                riskObj.stage+";"+
                DatetimeUtils.getFormatDateTime(currentDate)+";"+
                rootGetters["SessionManager/getterCurrentUsername"]+
                ";Asset Report SDM");
            }
          }
        }

        var bsiRiskArray = [
          ...dsfaObj.risks.integrity_risk_ids,
          ...dsfaObj.risks.availability_risk_ids,
          ...dsfaObj.risks.confidentiality_risk_ids
        ];

        // Risk Report BSI
        for(const item of bsiRiskArray) {
          const riskObj = await dispatch("RiskManager/actionReceiveCurrentRiskDataById", item, {root: true});

          if(!CommonUtils.isObjectEmpty(riskObj)) {
            const riskreport = await dispatch("RiskManager/actionGenerateRiskReport", riskObj, {root: true});
            if(!CommonUtils.isObjectEmpty(riskObj)) {
              zipcontent.push({
                name: "risk_report_"+riskObj.risk_id+".pdf",
                lastModified: currentDate,
                input: CommonUtils.getFileBlobFromBase64String(riskreport.printout, "application/pdf" )
              });

              contentText.push(
                "risk_report_"+riskObj.risk_id+".pdf;" +
                riskObj.stage+";"+
                DatetimeUtils.getFormatDateTime(currentDate)+";"+
                rootGetters["SessionManager/getterCurrentUsername"]+
                ";Asset Report BSI / Generic");
            }
          }
        }

        // Asset Report
        if (!CommonUtils.isArrayEmpty(dsfaObj.context.assets.asset_ids)) {
          for( const item of dsfaObj.context.assets.asset_ids) {
            const assetObj = await dispatch("AssetManager/actionReceiveCurrentAssetDataById", item, {root: true});

            if(!CommonUtils.isObjectEmpty(assetObj)) {
              const assetreport = await dispatch("AssetManager/actionGenerateAssetReport", assetObj, {root: true});
              if(!CommonUtils.isObjectEmpty(assetreport)) {
                zipcontent.push({
                  name: "asset_report_"+assetObj.asset_id+".pdf",
                  lastModified: currentDate,
                  input: CommonUtils.getFileBlobFromBase64String(assetreport.printout, "application/pdf" )
                });

                contentText.push(
                  "asset_report_"+assetObj.asset_id+".pdf;" +
                  assetObj.stage+";"+
                  DatetimeUtils.getFormatDateTime(currentDate)+";"+
                  rootGetters["SessionManager/getterCurrentUsername"]+
                  ";Asset Report");
              }
            }
          }
        }

        // ToM Report
        if (!CommonUtils.isStringEmpty(dsfaObj.active_measures.procedure_id)) {
          const procedureObj = await dispatch("ProcedureManager/actionReceiveCurrentProcedureDataById", dsfaObj.active_measures.procedure_id, {root: true});

          if(!CommonUtils.isObjectEmpty(procedureObj)) {
            const procedurereport = await dispatch("ProcedureManager/actionGenerateProcedureReport", procedureObj, {root: true});
            if(!CommonUtils.isObjectEmpty(procedurereport)) {
              zipcontent.push({
                name: "tom_procedure_report_"+procedureObj.procedure_id+".pdf",
                lastModified: currentDate,
                input: CommonUtils.getFileBlobFromBase64String(procedurereport.printout, "application/pdf" )
              });

              contentText.push(
                "asset_report_"+procedureObj.procedure_id+".pdf;" +
                procedureObj.stage+";"+
                DatetimeUtils.getFormatDateTime(currentDate)+";"+
                rootGetters["SessionManager/getterCurrentUsername"]+
                ";ToM Procedures Report");
            }
          }
        }

        // Attachments
        return await dispatch("AttachmentManager/actionDownloadAttachmentZipByRefAsBlob",
          {
            content: zipcontent,
            text: contentText,
            attref: refobj
          }, {root: true});
      } catch (err) {
        console.error("actionGenerateDsfaAsZip", err);
        return false;
      }
    },
    async actionGenerateDsfaReport(
      { state, getters, rootGetters, dispatch, commit },
      dsfaObj
    ) {
      try {
        // console.debug("actionGenerateDsfaReport - dsfaObj", dsfaObj);

        var printjobInfoObj = await dispatch("PrintManager/actionPreparePrintJobInfoData",
          {
            templateFile: "dsfa_report.odt",
            convertToFormat: "pdf"
          },
          {
            root: true
          });

          var expandedObj = getters.getterExpandDsfaData(dsfaObj, printjobInfoObj.lang, printjobInfoObj.lang);

          if(expandedObj == false) {
            throw "Error preparing printing data";
          }

          // =================================================
          // Assets

          expandedObj.context.assets.assets_display = [];

          if (expandedObj.context.assets.asset_ids.length) {
            for( const item of expandedObj.context.assets.asset_ids) {
              const assetObj = await dispatch("AssetManager/actionReceiveCurrentAssetDataById", item, {root: true});

              if(!CommonUtils.isObjectEmpty(assetObj)) {
                expandedObj.context.assets.assets_display.push({
                  id: CommonUtils.extractNumberFromKeyId(assetObj.asset_id),
                  name: (assetObj.core.name + " | " + assetObj.core.vendor + " (ID" + CommonUtils.extractNumberFromKeyId(assetObj.asset_id) +")")
                });
              }
            }
          }

          // =========================================
          // ActivitiyDocs

          expandedObj.core.activitydocs = [];

          for(const item of getters.getterActivityDocsByScopeAsArray(expandedObj.core.type_id, expandedObj.core.target_id)) {
            var activityObj = await dispatch("ActivityDocManager/actionReceiveCurrentActivityDocDataById", item.activitydoc_id, {root: true});

            // console.debug("actionGenerateDsfaReport - activityObj", activityObj)

            if(!CommonUtils.isObjectEmpty(activityObj)) {
              activityObj.datainput.sources_display = [];

              activityObj.datainput.sources.forEach((sourceObj) => {
                // console.debug("actionGenerateDsfaReport - sourceObj", sourceObj)

                const expObj = rootGetters["ActivityDocManager/getterExpandDataInputData"](sourceObj, printjobInfoObj.lang);

                // console.debug("actionGenerateDsfaReport - expObj", expObj)

                if(!CommonUtils.isObjectEmpty(expObj))
                  activityObj.datainput.sources_display.push(expObj);
              });

              delete activityObj.datainput.sources;

              activityObj.display_corename = item.name + " | " + i18n.t("common.area") + " " + rootGetters["AreaManager/getterEntityAreaByIdByLc"](item.area_id, printjobInfoObj.lang).display_name + " (ID " + CommonUtils.extractNumberFromKeyId(item.activitydoc_id) + ")";

              expandedObj.core.activitydocs.push(activityObj);
            }
          }

          // =========================================
          // Affectedgroups from activitydocs

          expandedObj.affectedgroups.groups_display = [];

          if (expandedObj.core.activitydocs.length) {
            for(const item of expandedObj.core.activitydocs) {

              // console.debug("actionGenerateDsfaReport - activitydoc item", item);

              item.datainput.sources_display.forEach((inputobj) => {

                // console.debug("actionGenerateDsfaReport - activitydoc inputobj", inputobj);

                expandedObj.affectedgroups.groups_display.push(inputobj.data_subject_display);
              });
            }

            const temparry =
              Array.from(new Set(expandedObj.affectedgroups.groups_display.map(a => a.name)))
                      .map(name => {return expandedObj.affectedgroups.groups_display.find(a => a.name === name)});

            expandedObj.affectedgroups.groups_display = [...temparry];
          }

          // =========================================
          // Risk - affectedgroups

          expandedObj.affectedgroups.risk_ids_display = [];

          expandedObj.affectedgroups.propability_level_display = "";
          expandedObj.affectedgroups.propability_level = 0;

          expandedObj.affectedgroups.impact_level_display = "";
          expandedObj.affectedgroups.impact_level = 0;

          expandedObj.affectedgroups.risk_level_display = "";
          expandedObj.affectedgroups.risk_level = 0;

          for(const item of expandedObj.affectedgroups.risk_ids) {
            const riskObj = await dispatch("RiskManager/actionReceiveCurrentRiskDataById", item, {root: true});

            // console.debug("actionGenerateDsfaReport - riskObj", riskObj)

            if(!CommonUtils.isObjectEmpty(riskObj)) {
              const displayname = i18n.t("common.risk") + " : " + riskObj.core.name + " (ID " + CommonUtils.extractNumberFromKeyId(riskObj.risk_id) + ")";

              expandedObj.affectedgroups.risk_ids_display.push(displayname);

              expandedObj.affectedgroups.propability_level += riskObj.mitigation.propability_level;
              expandedObj.affectedgroups.impact_level += riskObj.mitigation.impact_level;
            }
          }

          if(!CommonUtils.isArrayEmpty(expandedObj.affectedgroups.risk_ids_display)) {

            expandedObj.affectedgroups.propability_level = Math.round(expandedObj.affectedgroups.propability_level / expandedObj.affectedgroups.risk_ids_display.length);
            expandedObj.affectedgroups.impact_level = Math.round(expandedObj.affectedgroups.impact_level / expandedObj.affectedgroups.risk_ids_display.length);

            expandedObj.affectedgroups.risk_level = expandedObj.affectedgroups.propability_level * expandedObj.affectedgroups.impact_level;

            if(expandedObj.affectedgroups.impact_level > 0)
            {
              expandedObj.affectedgroups.risk_level_display =
                rootGetters["LookupManager/getterRiskLevelTotalsAsArrayByLc"](printjobInfoObj.lang)
                  .filter((el) => el.min <= expandedObj.affectedgroups.risk_level && el.max >= expandedObj.affectedgroups.risk_level)[0].display_name;

              expandedObj.affectedgroups.impact_level_display =
                  rootGetters["LookupManager/getterRiskLevelImpactsAsArrayByLc"](printjobInfoObj.lang)
                    .filter( (el) => el.level === expandedObj.affectedgroups.impact_level)[0].display_name;

              expandedObj.affectedgroups.propability_level_display =
                  rootGetters["LookupManager/getterRiskLevelPropabilitiesAsArrayByLc"](printjobInfoObj.lang)
                    .filter( (el) => el.level === expandedObj.affectedgroups.propability_level)[0].display_name;
            }
            else {
              expandedObj.affectedgroups.propability_level_display = "-";
              expandedObj.affectedgroups.propability_level = 0;

              expandedObj.affectedgroups.impact_level_display = "-";
              expandedObj.affectedgroups.impact_level = 0;

              expandedObj.affectedgroups.risk_level_display = "-";
              expandedObj.affectedgroups.risk_level = 0;
            }
          }

          // =========================================
          // Planned measures

          await dispatch("ProcedureManager/actionRetrieveInitProcedureDoc",false,{root: true});

          expandedObj.planned_measures.integrity_measures_display = [];
          expandedObj.planned_measures.availability_measures_display = [];
          expandedObj.planned_measures.confidentiality_measures_display = [];
          expandedObj.planned_measures.evaluation_measures_display = [];

          for(const item of expandedObj.planned_measures.integrity_measure_ids) {
            const measureeObj = rootGetters["ProcedureManager/getterProcedureMeasuresAsArrayByAspectByLc"]("ID_SECURITYASPECT_INTEGRITY", printjobInfoObj.lang)
              .filter(el => el.id == item)[0];

            // console.debug("actionGenerateDsfaReport - measureeObj", measureeObj);

            if(!CommonUtils.isObjectEmpty(measureeObj)) {
              const displayname = i18n.t("common.procedure") + " : " + measureeObj.display_name;

              expandedObj.planned_measures.integrity_measures_display.push({
                id: measureeObj.id,
                name: measureeObj.display_name,
                name_display: displayname
              });
            }
          }

          for(const item of expandedObj.planned_measures.availability_measure_ids) {
            const measureeObj = rootGetters["ProcedureManager/getterProcedureMeasuresAsArrayByAspectByLc"]("ID_SECURITYASPECT_AVAILABILITY_AND_RESILIENCE", printjobInfoObj.lang)
              .filter(el => el.id == item)[0];

            // console.debug("actionGenerateDsfaReport - measureeObj", measureeObj);

            if(!CommonUtils.isObjectEmpty(measureeObj)) {
              const displayname = i18n.t("common.procedure") + " : " + measureeObj.display_name;

              expandedObj.planned_measures.availability_measures_display.push({
                id: measureeObj.id,
                name: measureeObj.display_name,
                name_display: displayname
              });
            }
          }

          for(const item of expandedObj.planned_measures.confidentiality_measure_ids) {
            const measureeObj = rootGetters["ProcedureManager/getterProcedureMeasuresAsArrayByAspectByLc"]("ID_SECURITYASPECT_CONFIDENTIALITY", printjobInfoObj.lang)
              .filter(el => el.id == item)[0];

              // console.debug("actionGenerateDsfaReport - measureeObj", measureeObj);

            if(!CommonUtils.isObjectEmpty(measureeObj)) {
              const displayname = i18n.t("common.procedure") + " : " + measureeObj.display_name;

              expandedObj.planned_measures.confidentiality_measures_display.push({
                id: measureeObj.id,
                name: measureeObj.display_name,
                name_display: displayname
              });
            }
          }

          for(const item of expandedObj.planned_measures.evaluation_measure_ids) {
            const measureeObj = rootGetters["ProcedureManager/getterProcedureMeasuresAsArrayByAspectByLc"]("ID_SECURITYASPECT_PROCEDURES_FOR_PERIODIC_REVIEW_ASSESSMENT_AND_EVALUATION", printjobInfoObj.lang)
              .filter(el => el.id == item)[0];

            // console.debug("actionGenerateDsfaReport - measureeObj", measureeObj);

            if(!CommonUtils.isObjectEmpty(measureeObj)) {
              const displayname = i18n.t("common.procedure") + " : " + measureeObj.display_name;

              expandedObj.planned_measures.evaluation_measures_display.push({
                id: measureeObj.id,
                name: measureeObj.display_name,
                name_display: displayname
              });
            }
          }

          // =========================================
          // Risk - integrity

          expandedObj.risks.integrity_risk_ids_display = [];

          expandedObj.risks.integrity_propability_level_display = "";
          expandedObj.risks.integrity_propability_level = 0;

          expandedObj.risks.integrity_impact_level_display = "";
          expandedObj.risks.integrity_impact_level = 0;

          expandedObj.risks.integrity_risk_level_display = "";
          expandedObj.risks.integrity_risk_level = 0;

          for(const item of expandedObj.risks.integrity_risk_ids) {
            const riskObj = await dispatch("RiskManager/actionReceiveCurrentRiskDataById", item, {root: true});

            // console.debug("actionGenerateDsfaReport - integrity riskObj", riskObj);

            if(!CommonUtils.isObjectEmpty(riskObj)) {
              const displayname = i18n.t("common.risk") + " : " + riskObj.core.name + " (ID " + CommonUtils.extractNumberFromKeyId(riskObj.risk_id) + ")";

              expandedObj.risks.integrity_risk_ids_display.push(displayname);

              expandedObj.risks.integrity_propability_level += riskObj.mitigation.propability_level;
              expandedObj.risks.integrity_impact_level += riskObj.mitigation.impact_level;
            }

            // console.debug("actionGenerateDsfaReport - loop expandedObj.risks.integrity_propability_level",  expandedObj.risks.integrity_propability_level);
            // console.debug("actionGenerateDsfaReport - loop expandedObj.risks.integrity_impact_level",  expandedObj.risks.integrity_impact_level);
          }

          if(!CommonUtils.isArrayEmpty(expandedObj.risks.integrity_risk_ids_display)) {
            expandedObj.risks.integrity_propability_level = Math.round(expandedObj.risks.integrity_propability_level / expandedObj.risks.integrity_risk_ids_display.length);
            expandedObj.risks.integrity_impact_level = Math.round(expandedObj.risks.integrity_impact_level / expandedObj.risks.integrity_risk_ids_display.length);

            expandedObj.risks.integrity_risk_level = expandedObj.risks.integrity_propability_level * expandedObj.risks.integrity_impact_level;

            if(expandedObj.risks.integrity_risk_level > 0) {
              expandedObj.risks.integrity_risk_level_display =
              rootGetters["LookupManager/getterRiskLevelTotalsAsArrayByLc"](printjobInfoObj.lang)
                .filter((el) => el.min <= expandedObj.risks.integrity_risk_level && el.max >= expandedObj.risks.integrity_risk_level)[0].display_name;

              expandedObj.risks.integrity_impact_level_display =
                rootGetters["LookupManager/getterRiskLevelImpactsAsArrayByLc"](printjobInfoObj.lang)
                  .filter( (el) => el.level === expandedObj.risks.integrity_impact_level)[0].display_name;

              expandedObj.risks.integrity_propability_level_display =
                rootGetters["LookupManager/getterRiskLevelPropabilitiesAsArrayByLc"](printjobInfoObj.lang)
                  .filter( (el) => el.level === expandedObj.risks.integrity_propability_level)[0].display_name;
            }
            else {
              expandedObj.risks.integrity_propability_level_display = "-";
              expandedObj.risks.integrity_propability_level = 0;

              expandedObj.risks.integrity_impact_level_display = "-";
              expandedObj.risks.integrity_impact_level = 0;

              expandedObj.risks.integrity_risk_level_display = "-";
              expandedObj.risks.integrity_risk_level = 0;
            }
          }

          // =========================================
          // Risk - availability

          expandedObj.risks.availability_risk_ids_display = [];

          expandedObj.risks.availability_propability_level_display = "";
          expandedObj.risks.availability_propability_level = 0;

          expandedObj.risks.availability_impact_level_display = "";
          expandedObj.risks.availability_impact_level = 0;

          expandedObj.risks.availability_risk_level_display = "";
          expandedObj.risks.availability_risk_level = 0;

          for(const item of expandedObj.risks.availability_risk_ids) {
            const riskObj = await dispatch("RiskManager/actionReceiveCurrentRiskDataById", item, {root: true});

            // console.debug("actionGenerateDsfaReport - riskObj", riskObj)

            if(!CommonUtils.isObjectEmpty(riskObj)) {
              const displayname = i18n.t("common.risk") + " : " + riskObj.core.name + " (ID " + CommonUtils.extractNumberFromKeyId(riskObj.risk_id) + ")";

              expandedObj.risks.availability_risk_ids_display.push(displayname);

              expandedObj.risks.availability_propability_level += riskObj.mitigation.propability_level;
              expandedObj.risks.availability_impact_level += riskObj.mitigation.impact_level;
            }
          }

          if(!CommonUtils.isArrayEmpty(expandedObj.risks.availability_risk_ids_display)) {
            expandedObj.risks.availability_propability_level = Math.round(expandedObj.risks.availability_propability_level / expandedObj.risks.availability_risk_ids_display.length);
            expandedObj.risks.availability_impact_level = Math.round(expandedObj.risks.availability_impact_level / expandedObj.risks.availability_risk_ids_display.length);

            expandedObj.risks.availability_risk_level = expandedObj.risks.availability_propability_level * expandedObj.risks.availability_impact_level;

            if(expandedObj.risks.availability_risk_level > 0) {
              expandedObj.risks.availability_risk_level_display =
              rootGetters["LookupManager/getterRiskLevelTotalsAsArrayByLc"](printjobInfoObj.lang)
                .filter((el) => el.min <= expandedObj.risks.availability_risk_level && el.max >= expandedObj.risks.availability_risk_level)[0].display_name;

              expandedObj.risks.availability_impact_level_display =
                rootGetters["LookupManager/getterRiskLevelImpactsAsArrayByLc"](printjobInfoObj.lang)
                  .filter( (el) => el.level === expandedObj.risks.availability_impact_level)[0].display_name;

              expandedObj.risks.availability_propability_level_display =
                rootGetters["LookupManager/getterRiskLevelPropabilitiesAsArrayByLc"](printjobInfoObj.lang)
                  .filter( (el) => el.level === expandedObj.risks.availability_propability_level)[0].display_name;
            }
            else {
              expandedObj.risks.availability_propability_level_display = "-";
              expandedObj.risks.availability_propability_level = 0;

              expandedObj.risks.availability_impact_level_display = "-";
              expandedObj.risks.availability_impact_level = 0;

              expandedObj.risks.availability_risk_level_display = "-";
              expandedObj.risks.availability_risk_level = 0;
            }
          }

          // ========================================
          // Risk - confidentiality

          expandedObj.risks.confidentiality_risk_ids_display = [];

          expandedObj.risks.confidentiality_propability_level_display = "";
          expandedObj.risks.confidentiality_propability_level = 0;

          expandedObj.risks.confidentiality_impact_level_display = "";
          expandedObj.risks.confidentiality_impact_level = 0;

          expandedObj.risks.confidentiality_risk_level_display = "";
          expandedObj.risks.confidentiality_risk_level = 0;

          for(const item of expandedObj.risks.confidentiality_risk_ids) {
            const riskObj = await dispatch("RiskManager/actionReceiveCurrentRiskDataById", item, {root: true});

            // console.debug("actionGenerateDsfaReport - riskObj", riskObj)

            if(!CommonUtils.isObjectEmpty(riskObj)) {
              const displayname = i18n.t("common.risk") + " : " + riskObj.core.name + " (ID " + CommonUtils.extractNumberFromKeyId(riskObj.risk_id) + ")";

              expandedObj.risks.confidentiality_risk_ids_display.push(displayname);

              expandedObj.risks.confidentiality_propability_level += riskObj.mitigation.propability_level;
              expandedObj.risks.confidentiality_impact_level += riskObj.mitigation.impact_level;
            }
          }

          if(!CommonUtils.isArrayEmpty(expandedObj.risks.confidentiality_risk_ids_display)) {
            expandedObj.risks.confidentiality_propability_level = Math.round(expandedObj.risks.confidentiality_propability_level / expandedObj.risks.confidentiality_risk_ids_display.length);
            expandedObj.risks.confidentiality_impact_level = Math.round(expandedObj.risks.confidentiality_impact_level / expandedObj.risks.confidentiality_risk_ids_display.length);

            expandedObj.risks.confidentiality_risk_level = expandedObj.risks.confidentiality_propability_level * expandedObj.risks.confidentiality_impact_level;

            if(expandedObj.risks.confidentiality_risk_level > 0) {
              expandedObj.risks.confidentiality_risk_level_display =
              rootGetters["LookupManager/getterRiskLevelTotalsAsArrayByLc"](printjobInfoObj.lang)
                .filter((el) => el.min <= expandedObj.risks.confidentiality_risk_level && el.max >= expandedObj.risks.confidentiality_risk_level)[0].display_name;

              expandedObj.risks.confidentiality_impact_level_display =
                rootGetters["LookupManager/getterRiskLevelImpactsAsArrayByLc"](printjobInfoObj.lang)
                  .filter( (el) => el.level === expandedObj.risks.confidentiality_impact_level)[0].display_name;

              expandedObj.risks.confidentiality_propability_level_display =
                rootGetters["LookupManager/getterRiskLevelPropabilitiesAsArrayByLc"](printjobInfoObj.lang)
                  .filter( (el) => el.level === expandedObj.risks.confidentiality_propability_level)[0].display_name;
            }
            else {
              expandedObj.risks.confidentiality_propability_level_display = "-";
              expandedObj.risks.confidentiality_propability_level = 0;

              expandedObj.risks.confidentiality_impact_level_display = "-";
              expandedObj.risks.confidentiality_impact_level = 0;

              expandedObj.risks.confidentiality_risk_level_display = "-";
              expandedObj.risks.confidentiality_risk_level = 0;
            }
          }

          expandedObj.risks.hasRisks = !CommonUtils.isArrayEmpty(expandedObj.risks.integrity_risk_ids_display)
              || !CommonUtils.isArrayEmpty(expandedObj.risks.availability_risk_ids_display)
              || !CommonUtils.isArrayEmpty(expandedObj.risks.confidentiality_risk_ids_display);

          // ========================================
          // Attachments

          expandedObj.attachments_display = [];

          const documentarray = await dispatch("AttachmentManager/actionReadAttachmentsByReferenceAsArray", { reference: { scope: 'dsfa', refid: dsfaObj.dsfa_id } }, { root: true });
          // console.debug("actionGenerateDsfaReport - documentarray", documentarray);

          for (const index in documentarray) {
            const doctype = rootGetters["LookupManager/getterDocumentTypesAsArrayByLc"](printjobInfoObj.lang).filter(el => el.id === documentarray[index].doctype_id)[0].display_name;
            expandedObj.attachments_display.push(documentarray[index].name + " (" + documentarray[index].filename + ", " + doctype + ")");
          }

          expandedObj.hasAttachments = !CommonUtils.isArrayEmpty(expandedObj.attachments_display);

          printjobInfoObj.data.dsfa = { ...expandedObj};

          // console.debug("actionGenerateDsfaReport - printjobInfoObj", printjobInfoObj);

          return await dispatch("PrintManager/actionGeneratePrintDocument", printjobInfoObj, { root: true });
      } catch (err) {
        console.error("actionGenerateDsfaReport", err);
        return false;
      }
    },
    //assign dsfa
    async actionAssignDsfa(
      { state, getters, rootGetters, dispatch, commit },
      assigndataObj
    ) {
      try {
        // console.debug("actionAssignDsfa - assigndataObj", assigndataObj);
        var dsfaObj = await dispatch(
          "actionReceiveCurrentDsfaDataById",
          assigndataObj.key_id
        );

        if (dsfaObj === false) throw "Error reading dsfa by id";

        dsfaObj.assigned_to = assigndataObj.newassigned_to;
        dsfaObj.changenotes = assigndataObj.newnotes;
        dsfaObj.changetype_id = assigndataObj.changetype_id;

        const result = await dispatch("actionSaveDsfa", dsfaObj);

        if (result === false)
          throw "Error saving dsfa assignto change";

        commit("DSFA_ASSIGNED", dsfaObj);

        return result;
      } catch (err) {
        console.error("actionAssignDsfa - Error : ", err);
        return false;
      }
    },
  },
  getters: {
    getterEmptyDsfaRecord: (state) => {
      return { ...DSFA_RECORD };
    },
    getterDsfaStageDraft: (state) => {
      return DSFASTAGE_LIST["ID_DSFASTAGE_DRAFT"].stage;
    },
    getterDsfaStageReview: (state) => {
      return DSFASTAGE_LIST["ID_DSFASTAGE_REVIEW"].stage;
    },
    getterDsfaStageApproved: (state) => {
      return DSFASTAGE_LIST["ID_DSFASTAGE_APPROVED"].stage;
    },
    getterDsfaNextStageAllowed: (state, getters, rootState, rootGetters) => (dsfaDataObj) => {
      let ballowstate = false;

      try {
        if (dsfaDataObj === undefined) return ballowstate;

        // console.debug("getterDsfaNextStageAllowed", dsfaDataObj);
        const dsfaRequired = !dsfaDataObj.context.threshold.criteria.includes(getters.getterThresholdCriteriaIdOther);

        switch (dsfaDataObj.stage) {
          case 1: // ID_DSFASTAGE_DRAFT
          ballowstate = dsfaRequired ?
            (dsfaDataObj.core.valid &&
            dsfaDataObj.context.valid &&
            dsfaDataObj.exposition.valid &&
            dsfaDataObj.neccessity.valid) : (dsfaDataObj.core.valid && dsfaDataObj.context.valid);
            break;
          case 2: // ID_DSFASTAGE_REVIEW
            ballowstate = dsfaRequired ?
            (dsfaDataObj.core.valid &&
            dsfaDataObj.context.valid &&
            dsfaDataObj.exposition.valid &&
            dsfaDataObj.neccessity.valid &&
            dsfaDataObj.risks.valid &&
            dsfaDataObj.affectedgroups.valid &&
            dsfaDataObj.active_measures.valid &&
            dsfaDataObj.planned_measures.valid &&
            dsfaDataObj.consultation.valid &&
            dsfaDataObj.evaluation.valid) : (dsfaDataObj.core.valid && dsfaDataObj.context.valid);
            break;
          case 3: // ID_DSFASTAGE_APPROVED
          ballowstate = dsfaRequired ?
          (dsfaDataObj.core.valid &&
          dsfaDataObj.context.valid &&
          dsfaDataObj.exposition.valid &&
          dsfaDataObj.neccessity.valid &&
          dsfaDataObj.risks.valid &&
          dsfaDataObj.affectedgroups.valid &&
          dsfaDataObj.active_measures.valid &&
          dsfaDataObj.planned_measures.valid &&
          dsfaDataObj.consultation.valid &&
          dsfaDataObj.evaluation.valid &&
          dsfaDataObj.conclusion.valid) : (dsfaDataObj.core.valid && dsfaDataObj.context.valid && dsfaDataObj.conclusion.valid);
            break;
        }
      } catch (error) {
        console.error("getterDsfaNextStageAllowed - Error : ", error);
      }

      return ballowstate;
    },
    getterDsfaToNextStage:
      (state, getters, rootState, rootGetters) => (dsfaDataObj) => {
        try {
          if (dsfaDataObj === undefined) return undefined;

          dsfaDataObj.stagehistory.push({
            ...DSFA_STAGEHISTORY_RECORD,
            stage: dsfaDataObj.stage,
            revision: dsfaDataObj.revision,
            created_at: dsfaDataObj.updated_at,
            created_by: dsfaDataObj.updated_by,
            finished_at: +new Date(),
            finished_by: rootGetters["SessionManager/getterCurrentUsername"],
          });

          // Increase stage by one
          if (dsfaDataObj.stage <= getters.getterDsfaStageReview) {
            dsfaDataObj.stage++;
            dsfaDataObj.approved = false;
            dsfaDataObj.changetype_id =
              "ID_TIMELINE_CHANGETYPE_STAGECHANGED";
            dsfaDataObj.stagehistory[
              dsfaDataObj.stagehistory.length - 1
            ].changetype_id = dsfaDataObj.changetype_id;
          } else {
            dsfaDataObj.stage = getters.getterDsfaStageApproved;
            dsfaDataObj.approved = true;
            dsfaDataObj.changetype_id = "ID_TIMELINE_CHANGETYPE_CLOSED";
            dsfaDataObj.stagehistory[
              dsfaDataObj.stagehistory.length - 1
            ].changetype_id = dsfaDataObj.changetype_id;
          }
        } catch (error) {
          console.error(error);
          dsfaDataObj = undefined;
        }

        return dsfaDataObj;
      },
    getterDsfaIsApproved:
      (state, getters, rootState, rootGetters) => (dsfaDataObj) => {
        let result = false;

        // console.debug("getterDsfaIsApproved", dsfaDataObj);

        if (
          dsfaDataObj &&
          Object.prototype.hasOwnProperty.call(dsfaDataObj, "stage") &&
          Object.prototype.hasOwnProperty.call(dsfaDataObj, "approved")
        ) {
          if (
            dsfaDataObj.stage === getters.getterDsfaStageApproved &&
            dsfaDataObj.approved === true
          )
            result = true;
        }

        return result;
      },
    getterDsfaNeedsApproval:
      (state, getters, rootState, rootGetters) => (dsfaDataObj) => {
        let result = false;

        if (
          dsfaDataObj &&
          Object.prototype.hasOwnProperty.call(dsfaDataObj, "stage") &&
          Object.prototype.hasOwnProperty.call(dsfaDataObj, "approved")
        ) {
          if (
            dsfaDataObj.stage === getters.getterDsfaStageApproved &&
            dsfaDataObj.approved === false
          )
            result = true;
        }

        return result;
      },
      getterDsfaScopeText:
        (state, getters, rootState, rootGetters) => (type_id, target_id) => {
          // console.debug("getterDsfaScopeText - type_id, target_id",type_id, target_id)
          return getters.getterDsfaScopeTextByLc(
            type_id,
            target_id,
            rootGetters["TranslationManager/getterLanguageAlpha2Code"]
          );
        },
      getterDsfaScopeTextByLc:
        (state, getters, rootState, rootGetters) => (type_id, target_id, lc) => {
          try {

            // console.debug("getterDsfaScopeTextByLc- target_id", target_id);

            var scopetext = rootGetters[
              "LookupManager/getterDsfaTargetsAsArrayByLc"
            ](lc).filter((item) => item.id === type_id)[0].display_name;

            // console.debug("getterDsfaScopeTextByLc- scopetext", scopetext);

            switch (type_id) {
              case "ID_PROCEDURETARGET_AREA":
                scopetext =
                  scopetext +
                  " - " +
                  rootGetters["AreaManager/getterEntityAreaByIdByLc"](
                    target_id,
                    lc
                  ).display_name;
                break;
              case "ID_PROCEDURETARGET_PROCESS":
                scopetext =
                  scopetext +
                  " - " +
                  rootGetters[
                    "ProcessManager/getterEntityProcessByProcessIdByLc"
                  ](target_id, lc).display_name;
                break;
              case "ID_PROCEDURETARGET_ACTIVITYDOC":
                var array = rootGetters["ActivityDocManager/getterActivityDocsAsArray"].filter(item => item.activitydoc_id == target_id);
                if (array.length == 1) {
                  scopetext =
                    scopetext +
                    " - " +
                    array[0].name;
                }
                break;
              default:
                break;
            }
            return scopetext;
          } catch (error) {
            console.error("getterDsfaScopeTextByLc - Error : ", error);
            return "Unknown Target";
          }
        },
    getterDsfaReportData: (state, getters, rootState, rootGetters) => {
      const printjobInfoObj = {
        file: "dsfa_report.odt",
        convertTo: "pdf",
        lang: rootGetters["TranslationManager/lang"].lc,
      };

      return printjobInfoObj;
    },
    getterDsfaEditAllowed: (state, getters, rootState, rootGetters) => (dsfaDataObj) => {
        try {
          if (
            dsfaDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(dsfaDataObj, "stage") ||
            !Object.prototype.hasOwnProperty.call(dsfaDataObj, "assigned_to")
          )
            throw "Dsfa object is undefined";

          if (rootGetters["SessionManager/getterCurrentUsername"] != dsfaDataObj.assigned_to)
            return false;

          return (dsfaDataObj.stage <= getters.getterDsfaStageApproved);
        } catch (error) {
          console.error("getterDsfaEditAllowed - Error : ", error);
          return false;
        }
    },
    getterDsfaDeleteAllowed: (state, getters, rootState, rootGetters) => (dsfaDataObj) => {
        try {
          if (
            dsfaDataObj === undefined ||
            !Object.prototype.hasOwnProperty.call(dsfaDataObj, "assigned_to") ||
            !Object.prototype.hasOwnProperty.call(dsfaDataObj, "revision") ||
            !Object.prototype.hasOwnProperty.call(dsfaDataObj, "stage")
          )
            throw "Dsfa object is undefined";

          if (rootGetters["SessionManager/getterCurrentUsername"] != dsfaDataObj.assigned_to)
            return false;

          return (dsfaDataObj.stage <= getters.getterDsfaStageReview && dsfaDataObj.revision <= 1);
        } catch (error) {
          console.error("getterdsfaDeleteAllowed - Error : ", error);
          return false;
        }
    },
    getterExpandDsfaData: (state, getters, rootState, rootGetters) => (dsfaObj, lang, law_lang) => {
      try {
        var expandedObj = { ...dsfaObj };

        // console.debug("getterExpandDsfaData - expandedObj", expandedObj);

        if (expandedObj.dsfa_id) {
          expandedObj.dsfa_id = CommonUtils.extractNumberFromKeyId(expandedObj.dsfa_id);
        }

        if (expandedObj.core.type_id) {
          expandedObj.core.type_display = getters.getterDsfaScopeTextByLc(expandedObj.core.type_id, expandedObj.core.target_id, lang);
        }

        if (expandedObj.stage) {
          expandedObj.stage_display = rootGetters["LookupManager/getterDsfaStagesAsArrayByLc"](lang)
            .filter((item) => item.stage === expandedObj.stage)[0].display_name;
        }

        expandedObj.context.threshold.criteria_display = [];

        if (expandedObj.context.threshold.criteria.length) {
          expandedObj.context.threshold.criteria.forEach(function (item) {
            expandedObj.context.threshold.criteria_display.push({
                id: item,
                display_name: rootGetters["LookupManager/getterThresholdCriteriaAsArrayByLc"](lang)
                                .filter((el) => el.id === item)[0].display_name
              });
          });
        }

        expandedObj.active_measures.procedure_display = "";

        if(!CommonUtils.isStringEmpty(expandedObj.active_measures.procedure_id))
          expandedObj.active_measures.procedure_display = "TOM " + i18n.t("common.document") + " (ID " + CommonUtils.extractNumberFromKeyId(expandedObj.active_measures.procedure_id) + ")";

        expandedObj.consultation.experts.hasEntityContacts = (expandedObj.consultation.experts.entity_contacts.length > 0);
        expandedObj.consultation.experts.hasOtherContacts = (expandedObj.consultation.experts.other_contacts.length > 0);
        expandedObj.consultation.experts.entity_contacts_display = [];

        if(expandedObj.consultation.experts.entity_contacts.length) {
          expandedObj.consultation.experts.entity_contacts.forEach(function (item) {
            // console.debug("expandedObj.consultation.experts.entity_contacts - item",item);

            var role = "";

            try {
              role = rootGetters["LookupManager/getterOfficerTypesAsArrayByLc"](lang).filter(el => el.id = item.type_id)[0].display_name;
            } catch (error) {
              role = "- / -"
            }

            expandedObj.consultation.experts.entity_contacts_display.push({
                name: item.name,
                role: role
              }
            );
          });
        }

        // Evaluation

        // risk level
        expandedObj.evaluation.risk_level =
          expandedObj.evaluation.impact_level *
          expandedObj.evaluation.propability_level;

        // risk to text
        if (expandedObj.evaluation.risk_level > 0) {
          expandedObj.evaluation.risk_level_display =
            rootGetters["LookupManager/getterRiskLevelTotalsAsArrayByLc"](lang)
              .filter((el) => el.min <= expandedObj.evaluation.risk_level && el.max >= expandedObj.evaluation.risk_level)[0].display_name;

            expandedObj.evaluation.impact_level_display =
              rootGetters["LookupManager/getterRiskLevelImpactsAsArrayByLc"](lang)
                .filter( (el) => el.level === expandedObj.evaluation.impact_level)[0].display_name;

            expandedObj.evaluation.propability_level_display =
              rootGetters["LookupManager/getterRiskLevelPropabilitiesAsArrayByLc"](lang)
                .filter((el) =>el.level === expandedObj.evaluation.propability_level)[0].display_name;
        }
        else{
          expandedObj.evaluation.risk_level_display = "-";
          expandedObj.evaluation.impact_level_display = "-";
          expandedObj.evaluation.propability_level_display = "-";
        }

        // Stage History

        if (expandedObj.stagehistory.length) {
          expandedObj.stagehistory.forEach(function (item) {
            if (Object.prototype.hasOwnProperty.call(item, "changetype_id"))
              item.change_display = rootGetters["LookupManager/getterTimelineChangeTypesAsArrayByLc"](lang)
                .filter((el) => el.id === item.changetype_id)[0].display_name;
            else
              item.change_display = rootGetters["LookupManager/getterTimelineChangeTypesAsArrayByLc"](lang)
                .filter((el) => el.id === "ID_TIMELINE_CHANGETYPE_STAGECHANGED")[0].display_name;

            item.finished_by = rootGetters["AccountManager/getterUserFullnameFromUsername"](item.finished_by);

            item.stage_display = rootGetters["LookupManager/getterDsfaStagesAsArrayByLc"](lang)
              .filter((el) => el.stage === item.stage)[0].display_name;

            item.finished_at_display = rootGetters["HelperManager/getterFormatDate"](item.finished_at);
          });
        }

        expandedObj.created_at_display = rootGetters["HelperManager/getterFormatDate"](expandedObj.created_at);
        expandedObj.report_created_at_display = rootGetters["HelperManager/getterCurrentDate"];

        // console.debug("actionGenerateDsfaReport - expandedObj", expandedObj);

        return expandedObj;
      }
      catch(error)
      {
        console.error("getterExpandDsfaData - Error:", error);
        return false;
      }
    },
    getterDsfaUsageTextByLc: (state, getters, rootState, rootGetters) => (type_id, target_id, lc) => {
      let scopetext = rootGetters['LookupManager/getterDsfaTargetsAsArrayByLc'](lc).filter(item => item.id === type_id)[0].display_name;

      try{
        if(type_id)
        {
          switch(type_id) {
            case 'ID_DSFATARGET_ACTITIYDOC':
              scopetext = scopetext +
                          " : " +
                          rootGetters['ActivityDocManager/getterActivityDocByActivityDocId'].filter(item => item.id === target_id)[0].name;
            break;

            case 'ID_DSFATARGET_PROCESS':
              scopetext = scopetext +
                          " : " +
                          rootGetters['ProcessManager/getterEntityProcessesAsArrayByLc'](lc).filter(item => item.id === target_id)[0].display_name;
            break;

            case 'ID_DSFATARGET_AREA':
              scopetext = scopetext +
                          " : " +
                          rootGetters['AreaManager/getterEntityAreasAsArrayByLc'](lc).filter(item => item.id === target_id)[0].display_name;
            break;
            default:
            break;
          }
        }
      }
      catch(error)
      {
        console.error("getterDsfaUsageTextByLc - Error : ", error);
        scopetext = false;
      }

      return scopetext;
    },
    getterActivityDocsByScopeAsArray: (state, getters, rootState, rootGetters) => (type_id, target_id) => {
      var array = [];

      try {
        if (CommonUtils.isStringEmpty(type_id) || CommonUtils.isStringEmpty(target_id))
          return [];

        switch(type_id) {
          case 'ID_DSFATARGET_AREA':
            array = rootGetters["ActivityDocManager/getterActivityDocsAsArrayByAreaId"](target_id);
          break;
          case 'ID_DSFATARGET_PROCESS':
            array = rootGetters["ActivityDocManager/getterActivityDocsAsArrayByProcessId"](target_id);
          break;
          case 'ID_DSFATARGET_ACTIVITYDOC':
            array = rootGetters["ActivityDocManager/getterActivityDocsAsArrayByActivityDocId"](target_id);
          break;
          case 'ID_DSFATARGET_CUSTOM':
            target_id.forEach(el =>{
              array.push(rootGetters["ActivityDocManager/getterActivityDocByActivityDocId"](el));
            });
          break;
        }

      } catch (err) {
        console.error("getterActivityDocsByScopeAsArray - Error : ", err);
        array = [];
      }

      return array;
    },
  },
};
