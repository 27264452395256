
/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

 import axios from 'axios';
 import path from 'path';

 import { AuthService } from '@/services/auth.service';
 import { BACKENDAPI_HOST, BACKENDAPI_PATH } from '@/config/config.constants.js';
 
 export class Http {
   constructor (status) {
     this.isAuth = status && status.auth ? status.auth : false;
     this.instance = axios.create({
       baseURL: this.getBackendUrlString("")
     });
 
     return this.init();
   }
 
   init () {
     if (this.isAuth) {
       this.instance.interceptors.request.use(request => {
           
         request.headers.authorization = AuthService.getAccessToken();

         // if access token expired and refreshToken is exist >> go to API and get new access token
         if (AuthService.isAccessTokenExpired() && AuthService.hasRefreshToken()) {
           return AuthService.debounceRefreshTokens()
             .then(response => {
               AuthService.setAccessToken(response.data.token.access);
               request.headers.authorization = AuthService.getAccessToken();
               return request;
             })
             .catch(error => Promise.reject(error));
         } 
         else {
           return request;
         }
       }, error => {
         return Promise.reject(error);
       });
     }
 
     return this.instance;
   }

   getBackendUrlString(urlpath) {
    return new URL(path.join(BACKENDAPI_PATH, urlpath), BACKENDAPI_HOST).toString();
   }    
 }


 