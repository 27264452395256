<template>
  <v-app id="inspire">
    <!-- Header Bar -->
    <template v-if="getterIsAuthenticated == true && getterLoadingState == false">
      <template v-if="(getterIsFirstRun == true || getterCheckIsLicenseExpired() == true)">
        <simple-header-bar
          @performLogout="performLogout"
        />
      </template>
      <template v-else>
        <header-bar
          @performLogout="performLogout"
        />
      </template>
    </template>

    <!-- footer -->
    <footer-bar />

    <v-main app>
      <!-- Provides the application the proper gutter -->
      <transition name="fade" mode="out-in">
        <!-- sync waiting -->
        <template v-if="getterLoadingState">
          <v-container fill-height>
            <v-row fill-height class="full-height">
              <v-col cols="12">
                <h1 class="text-center" v-text="$t('app.loading_app')"></h1>
              </v-col>
              <v-col cols="12">
                <v-progress-linear indeterminate class="ma-10"/>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <!-- real actions -->
        <template v-else>
          <router-view />
        </template>
      </transition>
    </v-main>

    <AlertNotifyMessager
      :type="getterAlertMessage.type"
      :show="getterAlertMessage.active"
      @alertClosed="hideAlert()"
      :message="getterAlertMessage.message || 'Unkown Application Message'"
    />

    <template v-if="getterMessageOkDialog.active">
      <MessageOkDialog
        :show="getterMessageOkDialog.active"
        :title="getterMessageOkDialog.title"
        :message="getterMessageOkDialog.message"
        @buttonOkClick="actionClearMessageOkDialog"
      />
    </template>

    <template v-if="getterInProgressDialog.active">
      <ProgressModalDialog
        :show="getterInProgressDialog.active"
        :title="getterInProgressDialog.title"
        :message="getterInProgressDialog.message"
      />
    </template>

    <template v-if="getterMessageConfirmDialog.active">
      <ConfirmDialog
        :title="getterMessageConfirmDialog.title"
        :message="getterMessageConfirmDialog.message"
        :button_no="getterMessageConfirmDialog.label_no"
        :button_yes="getterMessageConfirmDialog.label_yes"
        :show="getterMessageConfirmDialog.active"
        @confirm="ConfirmDialogButtonClick">
      </ConfirmDialog>
    </template>
  </v-app>
</template>

<script>

import { AuthService } from '@/services/auth.service';

import { mapActions, mapGetters } from "vuex";

import { BACKENDAPI_STATE_CHECKINTERVALL } from "@/config/config.constants.js";

import FooterBar from "@/components/PageBars/FooterBar";
import HeaderBar from "@/components/PageBars/HeaderBar";
import SimpleHeaderBar from "@/components/PageBars/SimpleHeaderBar";

import MessageOkDialog from "@/components/_common/MessageOkDialog";
import ProgressModalDialog from "@/components/_common/ProgressModalDialog";
import AlertNotifyMessager from "@/components/_common/AlertNotifyMessager";
import ConfirmDialog from "@/components/_common/ConfirmDialog";

export default {
  name: "App",
  mixins: [],
  components: {
    HeaderBar,
    SimpleHeaderBar,
    FooterBar,
    MessageOkDialog,
    ProgressModalDialog,
    AlertNotifyMessager,
    ConfirmDialog
  },
  data: () => ({
    language: "",
  }),
  async beforeMount() {
    // console.debug("App - before_mounted");

    //Run every ms seconds
    async function delay(ms) {
        return new Promise((resolve, reject) => setTimeout(resolve, ms));
    }

    var isOnline = await AuthService.getBackendStatus();
    await this.actionSetIsBackendOnline(isOnline);

    (async function ticker (parent,seconds) {
      do {
        await delay(seconds*1000);
        // console.debug("App - statusPollingTimer before_mounted - isBackendOnline");
        var isOnline = await AuthService.getBackendStatus();
        await parent.actionSetIsBackendOnline(isOnline);
      } while (isOnline == isOnline);
    })(this, BACKENDAPI_STATE_CHECKINTERVALL);
  },
  mounted() {
    // console.debug("App - mounted");
  },
  methods: {
    ProcessClick: node => {},

    async performLogout() {
      try {
        await this.actionSetLoadingState(true);

        if(this.getterIsFirstRun == false && this.getterIsInitialDataComplete === false)
        {
          // console.debug("Home - performLogout getterIsInitialDataComplete", this.getterIsInitialDataComplete);

          // use just once the redirection to MyEntity in ComponentHandler mounted
          await this.actionSaveInitalDataComplete(true);
        }

        await AuthService.makeLogout();
        await this.actionDestroySession();

        this.$router.push({ name: "login" }).catch(() => {});
      }
      catch (error) {
        console.error("performLogout - Error", error);
        await AuthService.makeLogout();
        await this.actionDestroySession();
        window.onbeforeunload = null;

        location.reload();
      }
    },
    ConfirmDialogButtonClick(answer) {
      if (answer) {
        this.getterMessageConfirmDialog.confirmOperation();
      } else {
        this.getterMessageConfirmDialog.cancelOperation();
      }

      this.actionClearMessageConfirmDialog();
    },
    ...mapActions({
      actionSetLoadingState: "actionSetLoadingState",
      actionDestroySession: "SessionManager/actionDestroySession",
      actionSetIsBackendOnline: "actionSetIsBackendOnline",
      actionSaveInitalDataComplete: "actionSaveInitalDataComplete",
      actionClearMessageOkDialog: "actionClearMessageOkDialog",
      actionClearMessageConfirmDialog: "actionClearMessageConfirmDialog",
      actionClearInProgressDialog: "actionClearInProgressDialog",
    })
  },
  computed: {
    ...mapGetters({
      getterIsInitialDataComplete: "getterIsInitialDataComplete",
      getterLoadingState: "getterLoadingState",
      getterAlertMessage: "getterAlertMessage",
      getterMessageOkDialog: "getterMessageOkDialog",
      getterInProgressDialog: "getterInProgressDialog",
      getterMessageConfirmDialog: "getterMessageConfirmDialog",
      getterIsFirstRun: "getterIsFirstRun",
      getterIsAuthenticated: "SessionManager/getterIsAuthenticated",
      getterCheckIsLicenseExpired: "LicenseManager/getterCheckIsLicenseExpired",
    }),
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}
</style>