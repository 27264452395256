<template>
  <div>
    <v-app-bar app class="nav--mainmenu">

      <template v-if="getterIsDashboardActiveComponentName">
        <v-toolbar-title
          class="pointer app-title"
          @click="showPage('Dashboard')"
        >
          {{ $t("home.title_headerbar") }}
        </v-toolbar-title>
      </template>

      <!-- Show navigation symbols only when not on main dashboard -->
      <template v-if="!getterIsDashboardActiveComponentName">
        <nav class="nav--mainmenu-menu">

          <!-- HOME -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn  v-bind="attrs" v-on="on" class="btn-spacer-right" icon @click="showPage('Dashboard')"><v-icon>mdi-home</v-icon> </v-btn>
            </template>
            <span>{{$t('home.headerbar_dashboard_tooltip')}}</span>
          </v-tooltip>

          <!-- Incident -->
          <template v-if="getterCheckLicensedModuleIncident() && getterHasIncidentPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!getterHasIncidentPrivelege" v-bind="attrs" v-on="on" class="color--incident" icon @click="showPage('MyIncidents')"><v-icon>{{ getIncidentIcon }}</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_myincidents_tooltip')}}</span>
            </v-tooltip>
          </template>

          <!-- Request -->
          <template v-if="getterCheckLicensedModuleRequest() && getterHasRequestPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!getterHasRequestPrivelege" v-bind="attrs" v-on="on" class="color--request" icon @click="showPage('MyRequests')"><v-icon>{{getRequestIcon}}</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_myrequests_tooltip')}}</span>
            </v-tooltip>
          </template>

          <!-- Proof -->
          <template v-if="getterCheckLicensedModuleProof() && getterHasProofPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!getterHasProofPrivelege" v-bind="attrs" v-on="on" class="color--proof btn-spacer-right" icon @click="showPage('MyProofs')"><v-icon>{{getProofIcon}}</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_myproofs_tooltip')}}</span>
            </v-tooltip>
          </template>

          <!-- Usertask management -->
          <template v-if="getterCheckLicensedModuleTask() && getterHasTaskPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!getterHasTaskPrivelege"  v-bind="attrs" v-on="on" class="color--usertask" icon @click="showPage('MyUsertasks')"><v-icon>{{getTaskIcon}}</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_mytasks_tooltip')}}</span>
            </v-tooltip>
          </template>

          <!-- Document archive -->
          <template v-if="getterCheckLicensedModuleDocumentArchive() && getterHasDocumentArchivePrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!getterHasDocumentArchivePrivelege" v-bind="attrs" v-on="on" class="color--document" icon @click="showPage('MyDocuments')"><v-icon>{{getDocumentIcon}}</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_mydocuments_tooltip')}}</span>
            </v-tooltip>
          </template>

          <!-- Global ContactBook
          <template v-if="getterCheckLicensedModuleContactBook() && getterHasContactBookPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!getterHasContactBookPrivelege" v-bind="attrs" v-on="on" class="color--contactbook" icon @click="showPage('MyContacts')"><v-icon>{{getContactBookIcon}}</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_mycontacts_tooltip')}}</span>
            </v-tooltip>
          </template>
          -->

          <!-- Business partner contract management -->
          <template v-if="getterCheckLicensedModuleContractPartner() && getterHasContractPartnerPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!getterHasContractPartnerPrivelege"  v-bind="attrs" v-on="on" class="color--contractpartner btn-spacer-right" icon @click="showPage('MyContractPartners')"><v-icon>{{getContractpartnerIcon}}</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_mycontractpartners_tooltip')}}</span>
            </v-tooltip>
          </template>

          <!-- Risk -->
          <template v-if="getterCheckLicensedModuleRisk() && getterHasRiskPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn  :disabled="!getterHasRiskPrivelege" v-bind="attrs" v-on="on" class="color--risk" icon @click="showPage('MyRisks')"><v-icon>{{getRiskIcon}}</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_myrisks_tooltip')}}</span>
            </v-tooltip>
          </template>


          <!-- Dsfa -->
          <template v-if="getterCheckLicensedModuleDsfa() && getterHasDsfaPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn  :disabled="!getterHasDsfaPrivelege"  v-bind="attrs" v-on="on" class="color--dsfa " icon @click="showPage('MyDsfas')"><v-icon>{{getDsfaIcon}}</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_mydsfas_tooltip')}}</span>
            </v-tooltip>
          </template>


          <!-- Asset -->
          <template v-if="getterCheckLicensedModuleAsset() && getterHasAssetPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn  :disabled="!getterHasAssetPrivelege" v-bind="attrs" v-on="on" class="color--asset btn-spacer-right" icon @click="showPage('MyAssets')"><v-icon>{{getAssetIcon}}</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_myassets_tooltip')}}</span>
            </v-tooltip>
          </template>

          <!-- Procedure -->
          <template v-if="getterCheckLicensedModuleProcedure() && getterHasProcedurePrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn  :disabled="!getterHasProcedurePrivelege"  v-bind="attrs" v-on="on" class="color--procedure" icon @click="showPage('MyProcedures')"><v-icon>{{getProcedureIcon}}</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_myprocedures_tooltip')}}</span>
            </v-tooltip>
          </template>

          <!-- ActvityDoc -->
          <template v-if="getterCheckLicensedModuleActivityDoc() && getterHasActivityDocPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn  :disabled="!getterHasActivityDocPrivelege"  v-bind="attrs" v-on="on" class="color--activity" icon @click="showPage('MyActivityDocs')"><v-icon>mdi-content-save-cog-outline</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_myactivitydocs_tooltip')}}</span>
            </v-tooltip>
          </template>

          <!-- Process -->
          <template v-if="getterCheckLicensedModuleProcess() && getterHasProcessPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn  :disabled="!getterHasProcessPrivelege"  v-bind="attrs" v-on="on" class="color--process" icon @click="showPage('MyProcesses')"><v-icon>mdi-archive-outline</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_myprocesses_tooltip')}}</span>
            </v-tooltip>
          </template>

          <!-- Area -->
          <template v-if="getterCheckLicensedModuleArea() && getterHasAreaPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!getterHasAreaPrivelege"  v-bind="attrs" v-on="on" class="color--area btn-spacer-right" icon @click="showPage('MyAreas')"><v-icon>mdi-ungroup</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_myareas_tooltip')}}</span>
            </v-tooltip>
          </template>

          <!-- Entity -->
          <template v-if="getterCheckLicensedModuleEntity() && getterHasEntityPrivelege">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="!getterHasEntityPrivelege"  v-bind="attrs" v-on="on" class="color--entity" icon @click="showPage('MyEntity')"><v-icon>mdi-domain</v-icon> </v-btn>
              </template>
              <span>{{$t('home.headerbar_myentity_tooltip')}}</span>
            </v-tooltip>
          </template>
        </nav>
      </template>

      <v-spacer></v-spacer>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="toggleTheme">
            <v-icon>mdi-invert-colors</v-icon>
          </v-btn>
        </template>
        <span v-text="$t('home.headerbar_btn_theme_tooltip')"></span>
      </v-tooltip>

        <div class="mt-4 mx-2">
          <LanguageSelector/>
        </div>

        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <img class="menu-profile-icon mr-2 mt-2 pa-1" v-on="on" src="@/assets/settings.png" />
                </template>
                <span v-text="$t('home.headerbar_btn_basicdata_tooltip')"></span>
              </v-tooltip>
            </div>
          </template>

          <v-list>
            <v-list-item>{{$t('home.menu_title')}}</v-list-item>
<!--
            <template v-if="(getterCheckLicensedModuleContactBook() && getterHasContactBookPrivelege)">
              <v-list-item @click="showPage('MyContacts')">
                <v-list-item-icon>
                  <v-icon>{{ getContactBookIcon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('home.menuitem_addressbook')}}
                </v-list-item-title>
              </v-list-item>

              <v-divider></v-divider>
            </template>
-->

            <template v-if="canMangeUsers && getterCheckLicensedModuleUsrmgmt()">
              <v-list-item @click="showPage('AccountManagement')">
                <v-list-item-icon>
                  <v-icon>mdi-account-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{$t('home.menuitem_user_administration')}}</v-list-item-title>
              </v-list-item>

              <v-divider></v-divider>
            </template>

            <template v-if="!getterSecurityMeasureListLimit">
              <v-list-item @click="showPage('SecurityMeasures')">
                <v-list-item-icon>
                  <v-icon>mdi-shield-home-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('home.menuitem_securitymeasures')}}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-if="!getterArtefactListLimit">
              <v-list-item @click="showPage('Artefacts')">
                <v-list-item-icon>
                  <v-icon>mdi-file-document-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('home.menuitem_artefacts')}}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-if="!getterDataSubjectListLimit">
              <v-list-item @click="showPage('DataSubjects')">
                <v-list-item-icon>
                  <v-icon>mdi-folder-account-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('home.menuitem_datasubjects')}}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-if="!getterDataCategoryListLimit">
              <v-list-item @click="showPage('DataCategories')">
                <v-list-item-icon>
                  <v-icon>mdi-credit-card-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('home.menuitem_datacategories')}}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <img class="menu-profile-icon mr-2 mt-2 pa-1" v-on="on" src="@/assets/information.png" />
                </template>
                <span v-text="$t('home.headerbar_btn_gdpr_tooltip')"></span>
              </v-tooltip>
            </div>
          </template>

          <v-list>
            <v-list-item>{{$t('home.menu_title')}}</v-list-item>

            <template v-if="!getterRegulationListLimit">
              <v-list-item @click="showPage('Regulations')">
                <v-list-item-icon>
                  <v-icon>mdi-shield-search</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('home.menuitem_regulations')}}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-if="!getterAuthorityListLimit">
              <v-list-item @click="showPage('Authorities')">
                <v-list-item-icon>
                  <v-icon>mdi-bank</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('home.menuitem_authorities')}}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-if="!getterFineCalculatorLimit && getterIsCountryInGDPRJusticeArea(getterGdprData.lawbasis.countrycode)">
              <v-list-item @click="showPage('FineCalculator')">
                <v-list-item-icon>
                  <v-icon>mdi-calculator</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('home.menuitem_finecalculator')}}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-if="!getterRepositoryLimit">
              <v-list-item @click="showPage('Repository')">
                <v-list-item-icon>
                  <v-icon>mdi-text-box-search-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{$t('home.menuitem_doc_templates')}}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>

        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <img class="menu-profile-icon mt-1 ml-4" v-on="on" src="@/assets/profile.png" />
                </template>
                <span v-text="$t('home.headerbar_btn_profile_tooltip')"></span>
              </v-tooltip>
            </div>
          </template>

          <v-list>
            <v-list-item>{{$t('home.menu_title')}}</v-list-item>

            <v-list-item @click="showPage('MyProfile')">
              <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
              <v-list-item-content>
                <template v-if="getterCurrentUserDisplayname">
                  <v-list-item-title>{{getterCurrentUserDisplayname}}</v-list-item-title>
                  <v-list-item-subtitle>{{getterCurrentUsername}}</v-list-item-subtitle>
                </template>
                <template v-else>
                  <v-list-item-title>{{getterCurrentUserDisplayname}}</v-list-item-title>
                </template>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="showPage('MySupportInfo')">
              <v-list-item-icon>
                <v-icon>mdi-key-wireless</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{$t('home.menuitem_mysupportinfo')}}</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="$emit('performLogout')">
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-title>{{$t('home.menuitem_logout')}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

    </v-app-bar>

    <template v-if="!getterIsDashboardActiveComponentName">
      <v-toolbar class="breadcrumb--mainbar">
        <v-row  align="start" justify="center">
          <v-col cols="12" class="breadcrumb-mainbar--col">
            <template v-for="(bread, index) in getterBreadcrumbsAsArray">
              <span :key="index" @click="showPage(bread.component)">
                <template v-if="bread.component == 'Dashboard'">
                  <v-icon class="pointer">
                    mdi-home
                  </v-icon>
                </template>
                <template v-else>
                  <div class="bread-item">
                    <!-- {{ bread }} -->
                    <span>{{ $t(`breadbar.${bread.component}`) }}</span>
                  </div>
                  <template v-if="!isStringEmpty(bread.selection)">
                    <v-icon class="pr-1">mdi-slash-forward</v-icon>

                    <span>{{ bread.selection }}</span>
                  </template>

                  <template v-if="!isStringEmpty(bread.position)">
                    <v-icon class="pr-1">mdi-slash-forward</v-icon>

                    <span>{{ bread.position }}</span>
                  </template>
                </template>

                <v-icon class="pr-1" v-if="(index < getterBreadcrumbsAsArray.length - 1)"
                  >mdi-slash-forward</v-icon
                >
              </span>
            </template>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { ToggleTheme } from "@/mixins/toggleTheme";
import { AccessFunctions } from "@/mixins/accessFunctions";

import LanguageSelector from "@/components/_common/LanguageSelector";

export default {
  components: { LanguageSelector },
  mixins: [ToggleTheme, AccessFunctions],
  methods: {
    ...mapActions({
      actionShowComponent: "actionShowComponent",
    }),
    async showPage(name) {
      await this.actionShowComponent({component: name, payload: {}});
    },
  },
  computed: {
    canMangeUsers() {
      return this.getterCurrentUserCanManageUsers();
    },
    ...mapGetters({
      getterIsDashboardActiveComponentName: "getterIsDashboardActiveComponentName",

      getterBreadcrumbsAsArray: "getterBreadcrumbsAsArray",

      getterEntityData: "EntityManager/getterEntityData",
      getterGdprData: "GdprManager/getterGdprData",

      getterIsCountryInGDPRJusticeArea: "LawbasisGdprManager/getterIsCountryInGDPRJusticeArea",

      getterCurrentUsername: "SessionManager/getterCurrentUsername",
      getterCurrentUserDisplayname: "SessionManager/getterCurrentUserDisplayname",
    })
  }
};
</script>

<style lang="scss" scoped>

@import '@/scss/variables';

// App Main Menu - Header Bar

.nav--mainmenu .nav--mainmenu-menu {
  margin-left: 0;
  position: absolute;
  left: 5%;
  right: 15%;
  margin: 0 auto;
  text-align: center;
  min-width: 780px;
  z-index: 1;
}

.nav--mainmenu .nav--mainmenu-menu .v-btn {
    background: rgba(255,255,255,0.1);
    margin-right: 0.75rem;
    border: 2px solid;
}

.nav--mainmenu .nav--mainmenu-menu .btn-spacer-right {
  margin-right: 1.75rem;
}
.v-subheader {
  padding: 0 16px 0 0;
  text-align: left;
}


.bread-item {
  font-size: 14px;
  cursor: pointer;
  padding-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
}

.breadcrumb--mainbar {
  position: relative;
  top: calc(#{$font-size-root} * 4.5);
  height: calc(#{$font-size-root} * 2.5);
  line-height: calc(#{$font-size-root} * 2.5);
  padding: 0 1rem;
  text-transform: uppercase;
  background: transparent!important;
  box-shadow: 0 0 0 transparent!important;
}

.breadcrumb--mainbar .breadcrumb-mainbar--col > span:last-child .bread-item span {
  text-decoration: underline;
  font-weight: bold;
  text-decoration: underline;
  letter-spacing: .025rem;
}

.theme--dark .app-title {
  color: rgba(190,190,190,0.85);
}
</style>